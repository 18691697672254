/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const ClonedToPrint = ({ width = "22", height = "20" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 22 20">
    <rect width="22" height="20" fill="#7842B9" rx="2"></rect>
    <path
      fill="#fff"
      d="M7.998 14.5h-.997l-.003-1v-8h10v8q0 .412-.294.706a.96.96 0 0 1-.706.294zm0-1h3.5v-3h-3.5zm4.5 0h3.5v-1h-3.5zm0-2h3.5v-1h-3.5zm-4.5-2h8V8h-8z"></path>
    <path stroke="#fff" d="M5.5 8h2v5a1 1 0 1 1-2 0z"></path>
    <path fill="#fff" d="M6.5 13.5h1v1h-1z"></path>
  </svg>
);

export default ClonedToPrint;
