/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Checkbox from "components/checkbox/checkbox";
import styles from "./card-share.module.css";

import { t } from "i18n";

const CardShare = ({ cardShare, onMetadataChange, disabled }) => {
  return (
    <Checkbox
      id="card-share"
      checked={cardShare && cardShare["shareable"]}
      label={t("story-editor.inspector.enable-card-share")}
      onChange={(checked) => onMetadataChange("card-share", { shareable: checked })}
      disabled={disabled}
      classname={styles["cardshare-checkbox"]}
    />
  );
};

export default CardShare;
