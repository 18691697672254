/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Chip from "components/chip/chip";
import styles from "./breadcrumbs.module.css";
interface Crumb {
  id: number;
  "ancestor-ids": number[];
}

interface Props<CrumbType> {
  id: number;
  crumbs: Array<CrumbType>;
  getCrumbLabel: (crumb: CrumbType) => string;
}

/* Ideas:
    displayCurrent?
    displayCurrentLocation(first|last)
    linkFn
*/

class Breadcrumbs<CrumbType extends Crumb> extends React.Component<Props<CrumbType>> {
  render() {
    const { id, crumbs, getCrumbLabel } = this.props;
    const target = crumbs.find((crumb) => crumb.id === id);

    if (!target) {
      return null;
    }

    return (
      // TODO: Consider using normalizr
      <div className={styles["breadcrumbs"]}>
        {target["ancestor-ids"].map((ancestorId, index, array) => {
          const crumb = crumbs.find((crumb) => crumb.id === ancestorId);
          return crumb ? (
            <React.Fragment key={`crumb-${target.id}-ancestor-${ancestorId}`}>
              <Chip value={getCrumbLabel(crumb)} />
              {index !== array.length - 1 && <span className={styles["breadcrumb__level-indicator"]}>></span>}
            </React.Fragment>
          ) : null;
        })}
      </div>
    );
  }
}

export default Breadcrumbs;
