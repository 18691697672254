/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { CardId, CardVersionId, StoryElementId, UUID } from "api/primitive-types";
import {
  Card,
  CompositeStoryElement,
  StoryElementCompositeSubtype,
  StoryElementDataSubtype,
  StoryElementVideoSubtype,
  StoryElementFileSubtype,
  StoryElementPolltypeSubtype,
  StoryElementSubType,
  StoryElementTextSubtype,
  StoryElementType
} from "api/story";

interface StoryElementCard {
  "card-id": CardId;
  "card-version-id": CardVersionId | undefined;
}

interface Element extends StoryElementCard {
  id: StoryElementId;
  "client-id": string;
  metadata: Object;
}

interface TextElement extends Element {
  text: string;
  type: StoryElementType.Text;
  subtype: null;
}

interface TitleElement extends Element {
  type: StoryElementType.Title;
  title: string;
  subtype: null;
}

interface ImageElement extends Element {
  type: StoryElementType.Image;
  subtype: null;
  image: {
    caption: string;
    attribution: string;
    "alt-text": string;
  };
}

interface EmbedElement extends Element {
  type: StoryElementType.JSEmbed;
  subtype: null;
}

interface AttachmentElement extends Element {
  type: StoryElementType.File;
  subtype: StoryElementFileSubtype.Attachment;
}

interface QuoteElement extends Element {
  type: StoryElementType.Text;
  subtype: StoryElementTextSubtype.Quote;
}

interface CtaElement extends Element {
  type: StoryElementType.Text;
  subtype: StoryElementTextSubtype.Cta;
}

interface BlockQuoteElement extends Element {
  type: StoryElementType.Text;
  subtype: StoryElementTextSubtype.BlockQuote;
}

interface BigFactElement extends Element {
  type: StoryElementType.Text;
  subtype: StoryElementTextSubtype.BigFact;
}

interface BlurbElement extends Element {
  type: StoryElementType.Text;
  subtype: StoryElementTextSubtype.Blurb;
}

interface QuestionElement extends Element {
  type: StoryElementType.Text;
  subtype: StoryElementTextSubtype.Question;
}

interface AnswerElement extends Element {
  type: StoryElementType.Text;
  subtype: StoryElementTextSubtype.Answer;
}

interface SummaryElement extends Element {
  type: StoryElementType.Text;
  subtype: StoryElementTextSubtype.Summary;
}

interface OpinionPollElement extends Element {
  type: StoryElementType.Polltype;
  subtype: StoryElementPolltypeSubtype.OpinionPoll;
}

interface ReferencesElement extends Element {
  type: StoryElementType.Composite;
  subtype: StoryElementCompositeSubtype.References;
  tree: Array<StoryElementId>;
}

interface ImageGalleryElement extends CompositeStoryElement {
  type: StoryElementType.Composite;
  subtype: StoryElementCompositeSubtype.ImageGallery;
}

interface SlideShowElement extends CompositeStoryElement {
  type: StoryElementType.Composite;
  subtype: StoryElementCompositeSubtype.ImageGallery;
}

interface AlsoReadElement extends Element {
  type: StoryElementType.Text;
  subtype: StoryElementTextSubtype.AlsoRead;
}

interface TableElement extends Element {
  type: StoryElementType.Data;
  subtype: StoryElementDataSubtype.Table;
}

interface QAndAElement extends Element {
  type: StoryElementType.Text;
  subtype: StoryElementTextSubtype.QandA;
}

interface VideoClipElement extends Element {
  type: StoryElementType.Video;
  subtype: StoryElementVideoSubtype.VideoClip;
  "video-s3-key": string;
  provider: string;
  "external-video-id": string;
}

export type NewStoryElement =
  | SummaryElement
  | AnswerElement
  | QuestionElement
  | BigFactElement
  | BlurbElement
  | CtaElement
  | QuoteElement
  | BlockQuoteElement
  | EmbedElement
  | ImageElement
  | TitleElement
  | TextElement
  | AttachmentElement
  | OpinionPollElement
  | ReferencesElement
  | ImageGalleryElement
  | SlideShowElement
  | AlsoReadElement
  | TableElement
  | QAndAElement
  | VideoClipElement
  | never;

function cardDetailsForStoryElement(card: Card): StoryElementCard {
  return {
    "card-id": card["content-id"],
    "card-version-id": card["content-version-id"]
  };
}

function textElement(id: UUID, clientId: string, cardDetails: StoryElementCard): TextElement {
  return {
    id,
    "client-id": clientId,
    text: "<p></p>",
    type: StoryElementType.Text,
    subtype: null,
    metadata: {},
    ...cardDetails
  };
}

function titleElement(id: StoryElementId, clientId: string, cardDetails: StoryElementCard): TitleElement {
  return {
    id,
    "client-id": clientId,
    type: StoryElementType.Title,
    title: "",
    subtype: null,
    metadata: {},
    ...cardDetails
  };
}

function imageElement(id: StoryElementId, clientId: string, cardDetails: StoryElementCard): ImageElement {
  return {
    id,
    "client-id": clientId,
    type: StoryElementType.Image,
    subtype: null,
    image: {
      attribution: "",
      caption: "",
      "alt-text": ""
    },
    metadata: {},
    ...cardDetails
  };
}

function jsembedElement(id: StoryElementId, clientId: string, cardDetails: StoryElementCard): EmbedElement {
  return {
    id,
    "client-id": clientId,
    type: StoryElementType.JSEmbed,
    subtype: null,
    metadata: {},
    ...cardDetails
  };
}

function attachmentElement(id: StoryElementId, clientId: string, cardDetails: StoryElementCard): AttachmentElement {
  return {
    id,
    "client-id": clientId,
    type: StoryElementType.File,
    subtype: StoryElementFileSubtype.Attachment,
    metadata: {},
    ...cardDetails
  };
}

function tableElement(id: StoryElementId, clientId: string, cardDetails: StoryElementCard): TableElement {
  return {
    id,
    "client-id": clientId,
    type: StoryElementType.Data,
    subtype: StoryElementDataSubtype.Table,
    metadata: {},
    ...cardDetails
  };
}

function quoteElement(id: StoryElementId, clientId: string, cardDetails: StoryElementCard): QuoteElement {
  return {
    id,
    "client-id": clientId,
    subtype: StoryElementTextSubtype.Quote,
    type: StoryElementType.Text,
    metadata: {},
    ...cardDetails
  };
}

function ctaElement(id: StoryElementId, clientId: string, cardDetails: StoryElementCard): CtaElement {
  return {
    id,
    "client-id": clientId,
    subtype: StoryElementTextSubtype.Cta,
    type: StoryElementType.Text,
    metadata: {},
    ...cardDetails
  };
}

function blockquoteElement(id: StoryElementId, clientId: string, cardDetails: StoryElementCard): BlockQuoteElement {
  return {
    id,
    "client-id": clientId,
    subtype: StoryElementTextSubtype.BlockQuote,
    type: StoryElementType.Text,
    metadata: {},
    ...cardDetails
  };
}

function bigfactElement(id: StoryElementId, clientId: string, cardDetails: StoryElementCard): BigFactElement {
  return {
    id,
    "client-id": clientId,
    subtype: StoryElementTextSubtype.BigFact,
    type: StoryElementType.Text,
    metadata: {},
    ...cardDetails
  };
}

function blurbElement(id: StoryElementId, clientId: string, cardDetails: StoryElementCard): BlurbElement {
  return {
    id,
    "client-id": clientId,
    subtype: StoryElementTextSubtype.Blurb,
    type: StoryElementType.Text,
    metadata: {},
    ...cardDetails
  };
}

function summaryElement(id: StoryElementId, clientId: string, cardDetails: StoryElementCard): SummaryElement {
  return {
    id,
    "client-id": clientId,
    subtype: StoryElementTextSubtype.Summary,
    type: StoryElementType.Text,
    metadata: {},
    ...cardDetails
  };
}

function questionElement(id: StoryElementId, clientId: string, cardDetails: StoryElementCard): QuestionElement {
  return {
    id,
    "client-id": clientId,
    subtype: StoryElementTextSubtype.Question,
    type: StoryElementType.Text,
    metadata: {},
    ...cardDetails
  };
}

function answerElement(id: StoryElementId, clientId: string, cardDetails: StoryElementCard): AnswerElement {
  return {
    id,
    "client-id": clientId,
    subtype: StoryElementTextSubtype.Answer,
    type: StoryElementType.Text,
    metadata: {},
    ...cardDetails
  };
}

function opinionPollElement(id: StoryElementId, clientId: string, cardDetails: StoryElementCard): OpinionPollElement {
  return {
    id,
    "client-id": clientId,
    subtype: StoryElementPolltypeSubtype.OpinionPoll,
    type: StoryElementType.Polltype,
    metadata: {},
    ...cardDetails
  };
}

function referencesElement(id: StoryElementId, clientId: string, cardDetails: StoryElementCard): ReferencesElement {
  return {
    id,
    "client-id": clientId,
    subtype: StoryElementCompositeSubtype.References,
    type: StoryElementType.Composite,
    metadata: {},
    tree: [],
    ...cardDetails
  };
}

function videoClipElement(id: StoryElementId, clientId: string, cardDetails: StoryElementCard): VideoClipElement {
  return {
    id,
    "client-id": clientId,
    subtype: StoryElementVideoSubtype.VideoClip,
    type: StoryElementType.Video,
    metadata: {},
    ...cardDetails,
    "video-s3-key": "",
    provider: "",
    "external-video-id": ""
  };
}

const imageGalleryElement = (
  id: StoryElementId,
  clientId: string,
  cardDetails: StoryElementCard
): ImageGalleryElement => {
  return {
    id,
    "client-id": clientId,
    subtype: StoryElementCompositeSubtype.ImageGallery,
    type: StoryElementType.Composite,
    metadata: {
      type: "gallery"
    },
    tree: [],
    ...cardDetails
  };
};

const slideshowElement = (id: StoryElementId, clientId: string, cardDetails: StoryElementCard): ImageGalleryElement => {
  return {
    id,
    "client-id": clientId,
    subtype: StoryElementCompositeSubtype.ImageGallery,
    type: StoryElementType.Composite,
    metadata: {
      type: "slideshow"
    },
    tree: [],
    ...cardDetails
  };
};

function alsoReadElement(id: StoryElementId, clientId: string, cardDetails: StoryElementCard): AlsoReadElement {
  return {
    id,
    "client-id": clientId,
    subtype: StoryElementTextSubtype.AlsoRead,
    type: StoryElementType.Text,
    metadata: {},
    ...cardDetails
  };
}

function qAndAElement(id: StoryElementId, clientId: string, cardDetails: StoryElementCard): QAndAElement {
  return {
    id,
    "client-id": clientId,
    subtype: StoryElementTextSubtype.QandA,
    type: StoryElementType.Text,
    metadata: {},
    ...cardDetails
  };
}

export default function newStoryElement(
  card: Card,
  storyElementType:
    | StoryElementType
    | StoryElementTextSubtype
    | StoryElementFileSubtype
    | StoryElementSubType<StoryElementType>
    | StoryElementDataSubtype,
  subStoryElement?: string | undefined
): NewStoryElement {
  const id = "new-story-element-" + Math.random(),
    clientId = "client-story-element-" + Math.random(),
    cardDetails = cardDetailsForStoryElement(card);

  switch (storyElementType) {
    case "text": {
      return textElement(id, clientId, cardDetails);
    }
    case "title": {
      return titleElement(id, clientId, cardDetails);
    }
    case "summary": {
      return summaryElement(id, clientId, cardDetails);
    }
    case "question": {
      return questionElement(id, clientId, cardDetails);
    }
    case "answer": {
      return answerElement(id, clientId, cardDetails);
    }
    case "jsembed": {
      return jsembedElement(id, clientId, cardDetails);
    }
    case "image": {
      return imageElement(id, clientId, cardDetails);
    }
    case "attachment": {
      return attachmentElement(id, clientId, cardDetails);
    }
    case "video": {
      return videoClipElement(id, clientId, cardDetails);
    }
    case StoryElementTextSubtype.Cta: {
      return ctaElement(id, clientId, cardDetails);
    }
    case "quote": {
      return quoteElement(id, clientId, cardDetails);
    }
    case "blockquote": {
      return blockquoteElement(id, clientId, cardDetails);
    }
    case "bigfact": {
      return bigfactElement(id, clientId, cardDetails);
    }

    case "blurb": {
      return blurbElement(id, clientId, cardDetails);
    }

    case "opinion-poll": {
      return opinionPollElement(id, clientId, cardDetails);
    }
    case "references": {
      return referencesElement(id, clientId, cardDetails);
    }
    case "image-gallery": {
      if (subStoryElement) {
        return slideshowElement(id, clientId, cardDetails);
      } else {
        return imageGalleryElement(id, clientId, cardDetails);
      }
    }
    case "also-read": {
      return alsoReadElement(id, clientId, cardDetails);
    }
    case "table": {
      return tableElement(id, clientId, cardDetails);
    }
    case "q-and-a": {
      return qAndAElement(id, clientId, cardDetails);
    }

    default: {
      return textElement(id, clientId, cardDetails);
    }
  }
}
