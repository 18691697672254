/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ChildStoryElement, CompositeStoryElement, StoryElement } from "api/story";
import { EditorState } from "prosemirror-state";
import { findChildrenOfNode, findElementNP } from "../find";

export const getTextContent = (
  editorState: EditorState,
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement
): string | null => {
  const elementNP = findElementNP(editorState, storyElement);
  if (elementNP) {
    const textNodes = findChildrenOfNode(elementNP.node, "text");
    return textNodes.map((node) => node.text).join(" ");
  }
  return null;
};
