/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import FieldLabel from "components/field-label/field-label";
import styles from "./color-field.module.css";
import ErrorMessage from "components/error-message/error-message";
import { ChromePicker } from "react-color";
import OutsideClickHandler from "components/outside-click-handler/outside-click-handler";

interface ColorFieldProps {
  value: string | undefined;
  label: string;
  hint?: string;
  onChange: (color: any) => void;
  errorMessage?: string;
}

interface ColorFieldState {
  isPickerActive: boolean;
}

class ColorField extends React.Component<ColorFieldProps, ColorFieldState> {
  constructor(props: ColorFieldProps) {
    super(props);
    this.state = { isPickerActive: false };
    this.togglePicker = this.togglePicker.bind(this);
  }

  togglePicker() {
    this.setState((prevState) => ({
      isPickerActive: !prevState.isPickerActive
    }));
  }

  handleKeyPress(e: React.KeyboardEvent) {
    if (e.charCode === 13) {
      this.togglePicker();
    }
  }

  handleOnBlur(e: React.FocusEvent) {
    this.togglePicker();
  }

  render() {
    const { value, label, onChange, hint, errorMessage = "" } = this.props;
    const safeValue = value && value.length > 1 ? value : "#FFFFFF";

    const swatchStyle = { "--swatchColor": safeValue } as React.CSSProperties;
    return (
      <OutsideClickHandler onOutsideClick={() => this.setState({ isPickerActive: false })}>
        <div className={styles["color-field"]}>
          <FieldLabel htmlFor={`color-field-${label}`} label={label} />

          <div className={styles["color-field-swatch-container"]} onClick={() => this.togglePicker()}>
            <span className={styles["color-field-swatch"]} data-test-id="color-field-swatch" style={swatchStyle} />
            <input
              className={styles["color-field-input"]}
              data-test-id="color-field-input"
              type="text"
              value={safeValue}
              onChange={(e) => onChange(e.target.value)}
              onKeyPress={(e) => this.handleKeyPress(e)}
              onBlur={(e) => this.handleOnBlur(e)}
            />
          </div>

          {this.state.isPickerActive && (
            <div className={styles["color-field-picker"]} data-test-id="color-field-picker">
              <ChromePicker
                disableAlpha={true}
                color={safeValue}
                onChange={(color: any) => onChange(color.hex)}
                onChangeComplete={(color: any) => {
                  onChange(color.hex);
                }}
              />
            </div>
          )}
          {hint && <p className={styles["color-field-hint"]}>{hint}</p>}
          <ErrorMessage message={errorMessage} />
        </div>
      </OutsideClickHandler>
    );
  }
}

export default ColorField;
