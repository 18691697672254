/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { mapValues } from "lodash";
import { t } from "i18n";
import { toClientValidationError, validate } from "utils/validation.utils";
import { CurrentForm } from "store/form/editor";
import { headlineLimit, subheadlineLimit } from "pages/forms/editor/attributes/attributes";

export function validateForm(form: CurrentForm) {
  return validate(form, {
    headline: {
      presence: { allowEmpty: false, message: "^" + t("forms.editor.errors.empty-headline") },
      length: { maximum: headlineLimit, message: "^" + t("forms.editor.errors.max-headline-limit", { headlineLimit }) }
    },
    subheadline: {
      length: {
        maximum: subheadlineLimit,
        message: "^" + t("forms.editor.errors.max-subheadline-limit", { subheadlineLimit })
      }
    }
  });
}

export function getValidationErrors(form: CurrentForm) {
  const validationResult = validateForm(form);
  const errors = validationResult && toClientValidationError<CurrentForm>(validationResult).errors;
  return errors ? mapValues(errors, (errorDescription) => errorDescription && errorDescription[0]) : {};
}
