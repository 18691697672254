/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import * as ReactDOM from "react-dom";
import { Plugin, EditorState, PluginKey, Transaction } from "prosemirror-state";
import { Schema, Node } from "prosemirror-model";
import { DecorationSet, Decoration, EditorView } from "prosemirror-view";
import { findElementWithClientIdNP, findAllMatches, findChildrenOfNode, NodePosition } from "../operations/find";
import "./linter.css";
import { spellCheck } from "helpers/api";
import { StoryElement, CompositeStoryElement, ChildStoryElement } from "api/story";
import { store } from "store";
import { actions } from "../actions";
import { NOTIFICATION_INFO, NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from "containers/page/actions";
import { t } from "i18n";
import SuggestionDropdown from "./suggestion-dropdown";

(window as any).PMDecorations = DecorationSet.empty;

interface Suggestion {
  suggestions: string;
  word: string;
}

function linterDropDown(
  suggestions: Array<String>,
  word: string,
  from: number,
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement,
  opts: any
): HTMLElement {
  let div = document.createElement("div");
  div.className = "problem-suggestions";
  ReactDOM.render(
    React.createElement(SuggestionDropdown, {
      items: suggestions,
      onItemClick: (item: any) => {
        let editorState = store.getState().storyEditor.editorState,
          tr = editorState.tr,
          replaceWord = item,
          appliedState;
        (window as any).PMDecorations = DecorationSet.empty;
        tr.setMeta(pluginKey, { decorations: (window as any).PMDecorations });
        tr.insertText(replaceWord, from, from + word.length);
        appliedState = editorState.apply(tr);
        store.dispatch({
          type: actions.SET_EDITOR_STATE,
          payload: {
            editorState: appliedState
          }
        });
        createDecorationsForSuggestions(
          appliedState,
          opts.distinctSuggestions,
          opts.suggestions,
          findElementWithClientIdNP(appliedState, storyElement),
          storyElement
        );
      },
      getItemLabel: (item: any) => {
        return item;
      },
      word: word
    }),
    div
  );
  return div;
}

function createDecorationForMatch(
  editorState: EditorState<Schema<any, any>>,
  start: number,
  match: RegExpExecArray,
  suggestions: String[],
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement,
  opts: any
): void {
  const wordStart = start + match.index,
    wordEnd = start + match.index + match[0].length;
  (window as any).PMDecorations = (window as any).PMDecorations.add(editorState.doc, [
    Decoration.inline(
      wordStart,
      wordEnd,
      {
        class: "problem"
      },
      {
        inclusiveStart: true
      }
    ),
    Decoration.widget(wordEnd, linterDropDown(suggestions, match[0], wordStart, storyElement, opts))
  ]);
}

function createDecorationsForSuggestions(
  editorState: EditorState<Schema<any, any>>,
  distinctSuggestions: any,
  suggestions: Suggestion[],
  nodePosition: NodePosition,
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement
) {
  (window as any).PMDecorations = DecorationSet.empty;
  if (nodePosition) {
    distinctSuggestions.forEach((suggestion: string) => {
      const suggestionFromResult = suggestions.find((element: Suggestion) => element["word"] === suggestion);
      const regex = new RegExp("(?<=s|^|[^w\u0B80-\u0BFF])" + suggestion + "(?=s|$|[^w\u0B80-\u0BFF])");
      const matches = findAllMatches(regex, getTextFromNode(nodePosition.node));
      matches.forEach((match) => {
        const closestPara = nodePosition.node.childAfter(match.index),
          padding = closestPara.index > 1 ? closestPara.index + 1 : closestPara.index;
        createDecorationForMatch(
          editorState,
          closestPara.offset ? nodePosition.pos + 2 + padding : nodePosition.pos + 2,
          match,
          (suggestionFromResult && suggestionFromResult["suggestions"].split(",")) || [],
          storyElement,
          { distinctSuggestions, suggestions, nodePosition }
        );
      });
    });
  }
}

const getTextFromNode = (node: Node) => {
  const textNodes = findChildrenOfNode(node, "text");
  return textNodes.map((node) => node.text).join(" ");
};

export async function computeDecorations(storyElement: StoryElement | CompositeStoryElement | ChildStoryElement) {
  try {
    const editorState = store.getState().storyEditor.editorState;
    const closestNode = findElementWithClientIdNP(editorState, storyElement);
    const spellCheckService = store.getState().config["spell-checker-service"];
    if (closestNode) {
      if (!closestNode.node.textContent) {
        store.dispatch({
          type: NOTIFICATION_INFO,
          payload: {
            message: t("story-editor.story-element.spell-checker-no-text")
          }
        });
        return;
      }
      const config = store.getState().config;
      const { "spell-checker-language": spellCheckLanguage, "spell-checker-host": apiHost } = config,
        results = await spellCheck(apiHost, getTextFromNode(closestNode.node), spellCheckService, spellCheckLanguage),
        suggestions = results.filter((suggestion: Suggestion) => suggestion["suggestions"] !== "wrong"),
        editorStateAfterSpellCheck = store.getState().storyEditor.editorState,
        distinctSuggestions = [...new Set(suggestions.map((suggestion: Suggestion) => suggestion["word"]))];

      createDecorationsForSuggestions(
        editorStateAfterSpellCheck,
        distinctSuggestions,
        suggestions,
        closestNode,
        storyElement
      );
      if (suggestions.length === 0) {
        store.dispatch({
          type: NOTIFICATION_SUCCESS,
          payload: {
            message: t("story-editor.story-element.spell-checker-no-mistakes")
          }
        });
      } else if (suggestions[0] === "unsupported") {
        store.dispatch({
          type: NOTIFICATION_INFO,
          payload: {
            message: t("story-editor.story-element.spell-checker-not-available")
          }
        });
      } else {
        store.dispatch({
          type: NOTIFICATION_INFO,
          payload: {
            message: t("story-editor.story-element.spell-checker-end", { mistakeCount: suggestions.length })
          }
        });
      }
      store.dispatch({
        type: actions.SET_EDITOR_STATE,
        payload: {
          editorState: editorStateAfterSpellCheck.apply(
            editorStateAfterSpellCheck.tr.setMeta(pluginKey, { decorations: (window as any).PMDecorations })
          )
        }
      });
    }
  } catch {
    store.dispatch({
      type: NOTIFICATION_ERROR,
      payload: {
        message: t("story-editor.story-element.spell-checker-error")
      }
    });
  }
}

const pluginKey = new PluginKey("linter");
export default () => {
  return new Plugin({
    state: {
      init: (_, state: EditorState) => {
        return (window as any).PMDecorations;
      },
      apply: (tr: Transaction, value: any, oldState: EditorState, newState: EditorState) => {
        return (window as any).PMDecorations;
      }
    },
    props: {
      decorations: (state: EditorState<Schema<any, any>>): DecorationSet => {
        return (window as any).PMDecorations;
      },
      handleKeyPress: (view: EditorView, event: KeyboardEvent) => {
        if ((window as any).PMDecorations.children.length > 0) {
          (window as any).PMDecorations = DecorationSet.empty;
          store.dispatch({
            type: actions.SET_EDITOR_STATE,
            payload: {
              editorState: view.state.apply(
                view.state.tr.setMeta(pluginKey, { decorations: (window as any).PMDecorations })
              )
            }
          });
        }
        return false;
      }
    },
    key: pluginKey
  });
};
