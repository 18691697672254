/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const WEBHOOKS_PATH = "/settings/webhooks";
export const WEBHOOKS_ADD_PATH = "/settings/webhooks/new";
export const WEBHOOKS_EDIT_PATH = "/settings/webhooks/:id";
export const WEBHOOKS_LOG_PATH = "/settings/webhooks/logs";
