/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import styles from "./dropzone-message.module.css";
import Media from "components/icons/media";
import Plus from "components/icons/plus";

const cx = classnames.bind(styles);

export enum DropzoneMessageType {
  Image = "image",
  Attachment = "attachment",
  Video = "Video"
}

export enum DropzoneMessageVariant {
  Small = "small",
  Medium = "medium",
  Large = "large"
}

interface DropzoneMessageProps {
  message: string;
  classname?: string;
  label?: string;
  type?: DropzoneMessageType;
  variant?: DropzoneMessageVariant;
}

function getMessageStyle(variant?: DropzoneMessageVariant) {
  switch (variant) {
    case DropzoneMessageVariant.Small:
      return { iconSize: 22, messageClassName: styles["text-small"], labelClassName: styles["label-small"] };
    case DropzoneMessageVariant.Large:
      return { iconSize: 34, messageClassName: styles["text-large"], labelClassName: styles["label-large"] };
    default:
      return { iconSize: 28, messageClassName: styles["text-medium"], labelClassName: styles["label-medium"] };
  }
}

function getMessageIcon(iconSize: number, type?: DropzoneMessageType) {
  switch (type) {
    case DropzoneMessageType.Image:
      return <Media width={iconSize} height={iconSize} />;
    default:
      return <Plus width={iconSize} height={iconSize} fill={"var(--mono-4"} />;
  }
}

export const DropzoneMessage: React.FC<DropzoneMessageProps> = ({ classname, variant, message, type, label }) => {
  const { iconSize, messageClassName, labelClassName } = getMessageStyle(variant);
  const dropzoneIcon = getMessageIcon(iconSize, type);

  return (
    <div className={cx("dropzone-message", classname)}>
      {dropzoneIcon}
      <span className={cx("dropzone-message-text", messageClassName)}>{message}</span>
      <span className={cx("dropzone-message-label", labelClassName)}>{label}</span>
    </div>
  );
};

export default DropzoneMessage;
