/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { CustomField } from "api/custom-field";
import { LoaderState, INITIAL_LOADER_STATE } from "behaviors/loader/state";
import { StoryTemplateFields } from "./../../breaking-news/state";

export interface PartialAppState {
  customFields: State;
  settingsPage: {
    customStoryTemplates: Partial<StoryTemplateFields>;
  };
}

export enum InspectorType {
  Create = "create",
  Edit = "edit"
}

export interface State {
  customFields: Array<CustomField>;
  settingsPage: {
    customStoryTemplates: Partial<StoryTemplateFields>;
  };
  app: {
    customFieldToBeDeleted: CustomField | null;
    currentCustomField: CustomField | null;
  };
  ui: {
    main: LoaderState;
    save: LoaderState | null;
    delete: LoaderState | null;
    inspectorType: InspectorType | null;
  };
}

export const INITIAL_STATE: State = {
  customFields: [],
  settingsPage: {
    customStoryTemplates: {}
  },
  app: {
    customFieldToBeDeleted: null,
    currentCustomField: null
  },
  ui: {
    main: INITIAL_LOADER_STATE,
    save: null,
    delete: null,
    inspectorType: null
  }
};
