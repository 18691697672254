/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { ContributorRole } from "api/route-data/story-route-data";
import { Contribution } from "api/story";
import { Author } from "api/author";
import Select from "components/select/select";
import Authors from "./authors-ts";
import { replaceArrayItemByIndex } from "utils";
import RenderMultipleRow from "components/render-multiple-row/render-multiple-row";
import { compact } from "lodash";

import { t } from "i18n";
import "./contributions.css";
import { PartialAppState } from "pages/story-editor/state";
import { connect } from "react-redux";
import FieldLabel from "components/field-label/field-label";

interface ContributionsProps {
  contributorRoles: ContributorRole[];
  storyContributions: Contribution[];
  updateStoryContributions: (storyContributions: Contribution[]) => void;
  contributionsTitle?: string;
  disabled?: boolean;
}

export const getNotSelectedContributorRoles = (
  contributorRoles: ContributorRole[],
  storyContributions: Contribution[]
): ContributorRole[] => {
  if (storyContributions && storyContributions.length) {
    const storyContributionRoleIds = storyContributions.map((storyContribution) => storyContribution["role-id"]);
    return contributorRoles.filter((contributorRole) => !storyContributionRoleIds.includes(contributorRole.id));
  } else return contributorRoles;
};

const setContributorRole = (
  storyContributions: Contribution[],
  { id, name }: ContributorRole,
  index: number
): Contribution[] => {
  const updatedContribution = storyContributions[index]
    ? { ...storyContributions[index], "role-id": id, "role-name": name }
    : { "role-id": id, "role-name": name, authors: [] };

  return replaceArrayItemByIndex(storyContributions, index, updatedContribution);
};

const setAuthors = (storyContributions: Contribution[], authors: Author[], index: number): Contribution[] =>
  replaceArrayItemByIndex(storyContributions, index, { ...storyContributions[index], authors });

export const Contributions: React.SFC<ContributionsProps> = ({
  contributionsTitle,
  contributorRoles,
  storyContributions,
  updateStoryContributions,
  disabled
}) => {
  return (
    <div className="contributions-container">
      <FieldLabel label={contributionsTitle ? contributionsTitle : t("story-editor.inspector.contributions")} />
      <div className="contributions">
        <RenderMultipleRow
          columnLabels={[
            t("story-editor.inspector.contributions-role-title"),
            t("story-editor.inspector.contributors-title")
          ]}
          columnRenders={[
            (index: number) => {
              const storyContribution = storyContributions.length ? storyContributions[index] : null;

              return (
                <Select
                  onChange={(contributorRole: ContributorRole) =>
                    updateStoryContributions(setContributorRole(storyContributions, contributorRole, index))
                  }
                  value={
                    storyContribution
                      ? { id: storyContribution["role-id"], name: storyContribution["role-name"] }
                      : null
                  }
                  options={getNotSelectedContributorRoles(contributorRoles, storyContributions)}
                  getOptionLabel={(contributorRole) => contributorRole["name"]}
                  getOptionValue={(contributorRole) => contributorRole["id"].toString()}
                  hideError={true}
                  isDisabled={disabled}
                />
              );
            },
            (index: number) => {
              const storyContribution = storyContributions.length ? storyContributions[index] : null;
              return (
                <Authors
                  value={storyContribution && storyContribution.authors}
                  label={null}
                  onChange={(authors: Author[]) =>
                    updateStoryContributions(setAuthors(storyContributions, authors, index))
                  }
                  errors={null}
                  disabled={!storyContribution}
                  isDisabled={disabled}
                />
              );
            }
          ]}
          disabled={disabled}
          name="story-contributions"
          selectedLHSValues={storyContributions}
          onDelete={(storyContributions: Contribution[]) => {
            const storyContributionsWithoutNull = compact(storyContributions);
            updateStoryContributions(storyContributionsWithoutNull);
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: PartialAppState) => {
  return {
    contributorRoles: state.config.contributorRoles
  };
};

export default connect(
  mapStateToProps,
  {}
)(Contributions);
