/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, {
  ListRolesAction,
  ListRolesSuccessAction,
  ListRolesFailureAction,
  CancelSaveRolesAction,
  EditRoleAction,
  SwitchActionCategoryTabAction,
  UpdateRole,
  SaveRoleSuccessAction,
  AddNewRoleAction,
  DeleteRoleActionRequest,
  SaveRoleActionFailure,
  DeleteRoleActionFailure
} from "./actions";
import { RoleTypes, AnyRole } from "api/roles";

export function listRolesAction(): ListRolesAction {
  return { type: actions.LIST_ROLES };
}

export function listRolesSuccessAction(roles: RoleTypes[]): ListRolesSuccessAction {
  return { type: actions.LIST_ROLES_SUCCESS, payload: { roles } };
}

export function listRolesFailureAction(error: Error): ListRolesFailureAction {
  return { type: actions.LIST_ROLES_FAILURE, payload: { error } };
}

export function cancelSaveRolesAction(): CancelSaveRolesAction {
  return { type: actions.CANCEL_SAVE_ROLES_AND_CLEAR_CURRENT_ROLE_FROM_INSPECTOR };
}
export function editRoleAction(selectedRole: RoleTypes): EditRoleAction {
  return { type: actions.EDIT_ROLE, payload: { selectedRole } };
}

export function switchActionCategoryTabAction(selectedTab: string): SwitchActionCategoryTabAction {
  return { type: actions.SWITCH_ACTION_CATEGORY_TAB, payload: { selectedTab } };
}

export function updateRole(updatedRole: AnyRole): UpdateRole {
  return { type: actions.UPDATE_ROLE, payload: { updatedRole } };
}

export function saveRoleSuccessAction(): SaveRoleSuccessAction {
  return { type: actions.SAVE_ROLE_SUCCESS };
}

export function addNewRoleAction(): AddNewRoleAction {
  return { type: actions.ADD_NEW_ROLE };
}

export function saveRoleActionFailure(errors: Error): SaveRoleActionFailure {
  return { type: actions.SAVE_ROLE_FAILURE, payload: { errors } };
}

export function deleteRoleActionRequest(): DeleteRoleActionRequest {
  return { type: actions.DELETE_ROLE_REQUEST };
}
export function deleteRoleActionFailure(): DeleteRoleActionFailure {
  return { type: actions.DELETE_ROLE_FAILURE };
}
