/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import Button, { ButtonProps } from "./button";
import Spacer from "../spacer/spacer";
import styles from "./button-with-icon.module.css";
import classnames from "classnames/bind";

let cx = classnames.bind(styles);

interface IconProps {
  width?: number;
  height?: number;
  fill?: string;
}

interface ButtonWithIconProps extends ButtonProps {
  /** Render prop to render any icon to the right side of the button text */
  icon: React.ComponentType<IconProps>;
  /*To determine the direction of the icon */
  dir?: "left" | "right";
  /* To determine the color of the icon */
  fill?: string;
}
const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({
  id,
  type = "default",
  onClick,
  variant,
  disabled,
  testId,
  form,
  icon: Icon,
  children,
  dir = "left",
  fill
}) => {
  const className = cx(
    { "icon-on-left": dir === "left" },
    { "icon-on-right": dir === "right" },
    { disabled: disabled }
  );
  return (
    <Button
      id={id}
      type={type}
      form={form}
      onClick={onClick}
      variant={variant}
      disabled={disabled}
      greyed={disabled || false}
      testId={testId}>
      <span className={className}>
        {Icon && (
          <>
            {/* passing standard width and height of an icon in a button */}
            <Icon width={14} height={14} fill={fill} />
            <Spacer token="xs" />
          </>
        )}
        {children}
      </span>
    </Button>
  );
};

export default ButtonWithIcon;
export { ButtonWithIcon };
