/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Facebook = ({ width = 24, height = 24 }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}>
    <defs>
      <clipPath id="clip-path">
        <rect className="cls-1" x="25.14" y="0.32" width="23.52" height="23.52" rx="1.17" ry="1.17" />
      </clipPath>
      <clipPath id="clip-path-2">
        <path className="cls-1" d="M22.79-2.03h28.23V26.2H22.79z" />
      </clipPath>
      <clipPath id="clip-path-3">
        <path
          className="cls-2"
          d="M43.29 11.83a6.17 6.17 0 0 0-.11-1.16h-5.34v2.19h3a2.59 2.59 0 0 1-1.13 1.71V16h1.83a5.51 5.51 0 0 0 1.75-4.17z"
        />
      </clipPath>
      <clipPath id="clip-path-4">
        <path
          className="cls-2"
          d="M37.84 17.37A5.39 5.39 0 0 0 41.59 16l-1.83-1.43a3.35 3.35 0 0 1-1.92.55 3.39 3.39 0 0 1-3.18-2.34h-1.9v1.47a5.69 5.69 0 0 0 5.08 3.12z"
        />
      </clipPath>
      <clipPath id="clip-path-5">
        <path
          className="cls-2"
          d="M34.66 12.78a3.54 3.54 0 0 1-.18-1.08 3.49 3.49 0 0 1 .18-1.08V9.15h-1.9a5.7 5.7 0 0 0-.6 2.55 5.77 5.77 0 0 0 .6 2.55z"
        />
      </clipPath>
      <clipPath id="clip-path-6">
        <path
          className="cls-2"
          d="M37.84 8.28a3 3 0 0 1 2.16.85l1.63-1.63A5.41 5.41 0 0 0 37.84 6a5.68 5.68 0 0 0-5.08 3.13l1.9 1.47a3.39 3.39 0 0 1 3.18-2.32z"
        />
      </clipPath>
    </defs>
    <path
      d="M21.09 20a1 1 0 0 1-.28.7 1 1 0 0 1-.72.3H15.5v-7h2.34l.36-2.69h-2.7v-1.7a1.46 1.46 0 0 1 .25-1 1.34 1.34 0 0 1 1.08-.37h1.45V5.83c-.57 0-1.26-.08-2.09-.08a3.48 3.48 0 0 0-2.53.93 3.47 3.47 0 0 0-1 2.65v2h-2.3V14h2.33v7h-8.6a1 1 0 0 1-.72-.3 1 1 0 0 1-.28-.7V4a1 1 0 0 1 1-1h16a1 1 0 0 1 .7.28 1 1 0 0 1 .3.72z"
      fill="#3b5998"
    />
    <g clipPath="url(#clip-path)">
      <g clipPath="url(#clip-path-2)">
        <path fill="#fff" d="M22.2-2.62h29.41v29.41H22.2z" />
      </g>
    </g>
  </svg>
);

export default Facebook;
