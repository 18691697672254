/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import Users from "./users";
import AddUserProfile from "./add-user/profile";
import EditUserProfile from "./edit-user/profile";
import * as routes from "./routes";

export const Router = () => (
  <React.Fragment>
    <Switch>
      <Route exact path={routes.USERS_PATH} render={(props) => <Users {...props} />} />
      <Route exact path={routes.USERS_NEW_PATH} component={AddUserProfile} />
      <Route
        exact
        path={routes.USERS_NEW_PATH_ROLES}
        render={(props) => <AddUserProfile {...props} showInspector={true} />}
      />
      <Route exact path={routes.USERS_EDIT_PATH} component={EditUserProfile} />
      <Route
        exact
        path={routes.USERS_EDIT_PATH_ROLES}
        render={(props) => <EditUserProfile {...props} showInspector={true} showTeamsInspector={false} />}
      />
      <Route
        exact
        path={routes.USERS_EDIT_PATH_TEAMS}
        render={(props) => <EditUserProfile {...props} showTeamsInspector={true} showInspector={false} />}
      />
    </Switch>
  </React.Fragment>
);
