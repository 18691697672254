/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { validate } from "utils/validation.utils";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";

import actions from "../actions";
import Button from "components/button/button";
import Header from "components/header/header";
import Banner from "components/banner/banner";
import { createNewUser, closeBanner } from "../action-creators";
import { NewUser, PartialAppState, ValidationError, UserRole } from "../state";

const newUserConstraints = {
  name: { presence: { allowEmpty: false } },
  username: { presence: { allowEmpty: false } },
  password: { presence: { allowEmpty: false } },
  email: { presence: true, email: true },
  "communication-email": { email: true }
};

const newUserWithRoleConstraints = {
  name: { presence: { allowEmpty: false } },
  username: { presence: { allowEmpty: false } },
  password: { presence: { allowEmpty: false } },
  email: { presence: true, email: true },
  "communication-email": { email: true },
  roles: { presence: { allowEmpty: false } }
};

interface StateProps {
  newUser: NewUser;
  isUserProfileModified: boolean;
  bannerMessage: string;
  isSaving: boolean;
  defaultRole: UserRole;
}

interface DispatchProps {
  createNewUser: (newUser: NewUser) => void;
  setErrors: (errors: ValidationError) => void;
  closeDefaultRoleBanner: () => void;
}

type Props = StateProps & DispatchProps;

class AddUserHeader extends React.Component<Props> {
  validateNewUserData = (newUser: NewUser) => {
    let errors = validate(newUser, newUserConstraints);
    if (errors) {
      this.props.setErrors(errors);
    } else {
      this.props.createNewUser(newUser);
    }
  };

  validateNewAuthorData = (newUser: NewUser, defaultRole: UserRole) => {
    const {
      name,
      email,
      "communication-email": communicationEmail,
      bio,
      "avatar-url": avatarUrl,
      metadata,
      roles,
      social,
      "temp-s3-key": tempS3Key
    } = newUser;
    const newAuthor = {
      name,
      email,
      "communication-email": communicationEmail,
      "avatar-url": avatarUrl,
      bio,
      username: email,
      password: "Quintype_" + Math.floor(100000 * Math.random()),
      "can-login": false,
      metadata: metadata,
      social: social,
      roles: roles && roles.length > 0 ? roles : defaultRole ? [{ id: defaultRole.id, name: defaultRole.name }] : [],
      "temp-s3-key": tempS3Key
    };
    let errors = validate(newAuthor, newUserWithRoleConstraints);
    if (errors) {
      this.props.setErrors(errors);
    } else {
      this.props.createNewUser(newAuthor);
    }
  };

  validateData = (newUser: NewUser, defaultRole: UserRole) => {
    return newUser["can-login"] ? this.validateNewUserData(newUser) : this.validateNewAuthorData(newUser, defaultRole);
  };

  render() {
    const { isUserProfileModified, newUser, bannerMessage, closeDefaultRoleBanner, isSaving, defaultRole } = this.props;
    return (
      <React.Fragment>
        {bannerMessage && (
          <Banner type="error" message={bannerMessage} closable={true} onClose={() => closeDefaultRoleBanner()} />
        )}
        <Header>
          {isUserProfileModified && (
            <Button
              variant="success"
              type="primary"
              onClick={() => this.validateData(newUser, defaultRole)}
              disabled={isSaving}>
              {t("users-and-roles.save")}
            </Button>
          )}
        </Header>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState) => {
  return {
    newUser: state.usersAndRoles.usersPage.newUser,
    isUserProfileModified: state.usersAndRoles.usersPage.ui.isUserProfileModified,
    bannerMessage: state.usersAndRoles.usersPage.ui.banner && state.usersAndRoles.usersPage.ui.banner.message,
    isSaving: state.usersAndRoles.usersPage.ui.saving,
    defaultRole: state.config.defaultRole
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
  return {
    createNewUser: (newUser: NewUser) => {
      dispatch(createNewUser(newUser));
    },
    setErrors: (errors: ValidationError) => dispatch({ type: actions.SET_ERRORS_FOR_USER, payload: { errors } }),
    closeDefaultRoleBanner: () => dispatch(closeBanner())
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AddUserHeader);

export { AddUserHeader };
