/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export enum ConnectionState {
  INITIALIZED = "initialized",
  CONNECTING = "connecting",
  CONNECTED = "connected",
  DISCONNECTED = "disconnected",
  SUSPENDED = "suspended",
  CLOSING = "closing",
  CLOSED = "closed",
  FAILED = "failed"
}

export interface RealtimeMember {
  id?: string;
  name?: string;
  initials?: string;
  avatarImageUrl?: string | null;
  openedAt: number;
  isEditing: boolean;
  clientId?: string;
}
