/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ThunkDispatch } from "redux-thunk";
import {
  Asana,
  BridgekeeperConfig,
  Features,
  getRouteData,
  Member,
  Publisher,
  Section,
  FormsConfig,
  StorySlugConfig
} from "api/route-data/route-data";
import { Role } from "api/users";
import { CollectionMetadataMap, StoryTemplate } from "api/route-data/collection-route-data";
import { Attribute } from "api/story-attributes";
import { StoryTemplateFields } from "api/route-data/story-route-data";
import { EntityType } from "api/entity-type";
import { convertObjKeysKebabToCamel } from "utils";

export interface Source {
  categories: string[];
  name?: string;
  "display-name"?: string;
  label?: string;
  source?: string;
}

export interface GoogleMapConfig {
  "api-key": string;
  disabled: boolean;
}

export interface PhotoEditorConfig {
  "assets-base-url": string;
  license: string;
}

export interface FormioFileUploadConfig {
  minSize: string;
  maxSize: string;
  supportedExtensions: string;
  storage: string;
}

export interface AiContentGeneration {
  story: {
    "meta-description": boolean;
    "meta-title": boolean;
    "paraphrase-text": boolean;
    "push-notification-title": boolean;
    "push-notification-message": boolean;
  };
}

export interface WorkspaceSliderConfig {
  pollIntervalInSeconds: number;
  userInactivityThresholdInSeconds: number;
}

export type EmailListType = string[];

/*
   TODO This list is possibly incomplete, please update it as and when you can.
    Ideally should be a union of the common config and config needed per route.
*/

export interface ConfigState {
  publisher: Publisher | {};
  member: Member | {};
  sections?: Section[];
  publisherWideBannerMessage?: string | null;
  googleMaps?: GoogleMapConfig;
  defaultRole?: Role;
  sources?: Source[];
  storyAttributes?: Attribute[];
  storyTemplates?: StoryTemplate[];
  accessLevels?: string[];
  collectionMetadata?: CollectionMetadataMap;
  entityTypes?: EntityType[];
  enabledEntityTypes?: string[];
  bridgekeeper?: BridgekeeperConfig | {};
  templateFields?: StoryTemplateFields;
  asana?: Asana;
  "spell-checker-language"?: string;
  "spell-checker-host"?: string;
  "cdn-cname"?: string;
  "cdn-video"?: string;
  "photo-editor-sdk"?: PhotoEditorConfig;
  isBridgekeeperIntegrationEnabled?: boolean;
  forms?: FormsConfig | {};
  "story-slug"?: StorySlugConfig;
  "ai-content-generation"?: AiContentGeneration;
  help?: string;
  workspaceSlider?: WorkspaceSliderConfig;
}

export type FeatureState = Features;

export interface PartialAppState {
  config: ConfigState;
  features: FeatureState;
}

export const intialFormsConfig: FormsConfig = {
  defaultMailingList: [],
  fileUpload: {}
};

export const initialConfigState: ConfigState = {
  publisher: {},
  member: {},
  sections: [],
  storyAttributes: [],
  storyTemplates: [],
  sources: [],
  accessLevels: [],
  entityTypes: [],
  bridgekeeper: {},
  publisherWideBannerMessage: null,
  forms: intialFormsConfig
};

const convertAccessLevelsToNumber = (accessLevels, stateAccessLevels) => {
  if (accessLevels) {
    return accessLevels.filter((accessLevel) => !Number.isNaN(accessLevel)).map((accessLevel) => Number(accessLevel));
  }
  return stateAccessLevels;
};

const transformConfigs = (configs: ConfigState): ConfigState => {
  const configsToTransform = ["forms", "workspaceSlider"];
  for (const config of Object.keys(configs)) {
    if (configsToTransform.includes(config)) {
      convertObjKeysKebabToCamel(configs[config]);
    }
  }
  return configs;
};

export const {
  reducer: configReducer,
  actions: { setConfigState }
} = createSlice({
  initialState: initialConfigState,
  name: "config",
  reducers: {
    setConfigState: (state: ConfigState, action: PayloadAction<ConfigState>) => {
      return {
        ...state,
        ...action.payload,
        accessLevels: convertAccessLevelsToNumber(action.payload.accessLevels, state.accessLevels)
      };
    }
  }
});

export const initialFeatureState: FeatureState = {
  showPublicPreviewLink: false,
  accessLevelValue: false,
  devEncodeImageS3: false
};

export const {
  reducer: featuresReducer,
  actions: { setFeatureState }
} = createSlice({
  initialState: initialFeatureState,
  name: "features",
  reducers: {
    setFeatureState: (state: FeatureState, action: PayloadAction<FeatureState>) => {
      return { ...state, ...action.payload };
    }
  }
});

export function fetchRouteData(rawPath: string) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
    try {
      const path = rawPath.replace(/^\/x\//, "/");
      const { config, features } = await getRouteData(path);
      dispatch(setFeatureState(features));
      dispatch(setConfigState(transformConfigs(config)));
    } catch (e) {
      // TODO Show a fall back error component
      console.error("Failed to fetch route-data.json");
    }
  };
}
