/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import LeftArrow from "components/icons/left-arrow";
import styles from "./go-back.module.css";
import classnames from "classnames/bind";
const cx = classnames.bind(styles);

interface Props {
  clickAction?: () => void;
  message?: string;
  classname?: string;
}

const GoBack: React.SFC<Props> = ({ clickAction, message, classname }) => {
  return (
    <button onClick={clickAction} className={cx("go-back-to-main-menu", classname)}>
      {message && (
        <React.Fragment>
          <LeftArrow /> {message}
        </React.Fragment>
      )}
    </button>
  );
};

export default GoBack;
