/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";

const swapItems = (items, firstItem, afterItem, updateSwappedItems, updateSortedItems, initialSwappedItems) => {
  if (firstItem === afterItem) return;
  const swappedItems = [...items];
  const [movingItem] = swappedItems.splice(firstItem, 1);
  swappedItems.splice(afterItem, 0, movingItem);

  if (initialSwappedItems !== swappedItems) {
    updateSwappedItems(swappedItems);
    updateSortedItems(swappedItems);
  }
};

const ScrollableList = ({ items, updateSwappedItems, updateSortedItems, swappedItems, children }) => {
  const onDragEnd = (result) => {
    if (!result.destination) return;
    swapItems(
      items,
      result.source.index,
      result.destination.index,
      updateSwappedItems,
      updateSortedItems,
      swappedItems
    );
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={"droppable"}>
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {children}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default ScrollableList;
