/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { StoryElementId, CardId, UUID } from "api/primitive-types";
import {
  StoryElementTextSubtype,
  StoryElementEmbedSubtype,
  StoryElementExternalFileSubtype,
  StoryElementCompositeSubtype,
  StoryElementDataSubtype,
  StoryElementFileSubtype,
  StoryElementPolltypeSubtype,
  StoryElementVideoSubtype,
  StoryElementType,
  StoryElementSubType
} from "api/story";

export interface QuoteElement {}

export interface PMElement {
  type?: ElementType;
  subtype?:
    | StoryElementTextSubtype
    | StoryElementEmbedSubtype
    | StoryElementExternalFileSubtype
    | StoryElementCompositeSubtype
    | StoryElementDataSubtype
    | StoryElementFileSubtype
    | StoryElementPolltypeSubtype
    | StoryElementVideoSubtype
    | null;
  content?: PMElement[];
  attrs?: PMCardAttrs | PMStoryElementAttrs | PMStoryAttrs | PMStoryElementTextAttrs;
  text?: string;
}

export enum ElementType {
  Text = "text",
  StoryElementText = "story_element_text",
  StoryElementOthers = "story_element_others",
  Card = "card",
  Cta = "cta",
  CtaTitleHelper = "cta_title_helper",
  CtaTitle = "cta_title",
  CtaUrlHelper = "cta_url_helper",
  CtaUrl = "cta_url",
  CtaOptions = "cta_options",
  CtaTarget = "cta_target",
  CtaNoFollow = "cta_no_follow",
  Title = "title",
  TitleText = "title_text",
  Quote = "quote",
  QuoteText = "quote_text",
  QuoteHelper = "quote_helper",
  QuoteAttribution = "quote_attribution",
  Bigfact = "bigfact",
  BigfactTitle = "bigfact_title",
  BigfactHelper = "bigfact_helper",
  BigfactAttribution = "bigfact_description",
  Blurb = "blurb",
  BlurbText = "quote_text",
  QandA = "q_and_a_element",
  QandAQuestionHelper = "q_and_a_question_helper",
  QandAQuestion = "q_and_a_question",
  QandAQuestionAttributionHelper = "q_and_a_question_attribution_helper",
  QandAAnswerHelper = "q_and_a_answer_helper",
  QandAAnswer = "q_and_a_answer",
  QandAAnswerAttributionHelper = "q_and_a_answer_attribution_helper",
  Question = "question",
  QuestionHelper = "question_helper",
  QuestionAttributionHelper = "question_attribution_helper",
  QuestionText = "question_text",
  Answer = "answer",
  AnswerHelper = "answer_helper",
  AnswerAttributionHelper = "answer_attribution_helper",
  AnswerText = "answer_text",
  Story = "story"
}

interface PMCardAttrs {
  id: CardId;
  type: string;
  "content-version-id": UUID;
  isCardDisabled: boolean;
}

interface PMStoryElementAttrs {
  id: StoryElementId;
  type?: StoryElementType;
  subtype?: StoryElementSubType<StoryElementType>;
  "card-id"?: CardId;
  "client-id"?: UUID;
  "card-version-id"?: UUID;
  "family-id"?: UUID;
}

interface PMStoryAttrs {
  id: StoryElementId;
  type: string;
  "story-version-id": UUID;
}

interface PMStoryElementTextAttrs {
  placeholder?: string;
  helperName?: string;
  label?: string;
}
