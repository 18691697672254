/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect } from "react";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import { t } from "i18n";
import { ThunkDispatch } from "redux-thunk";
import classnames from "classnames/bind";
import { CONFIGURE_WATERMARK_IMAGES_PATH, CONFIGURE_WATERMARK_IMAGES_GALLERY_PATH } from "pages/settings/routes";
import { navigateFn } from "utils/routes.utils";
import ContentHeader from "../../../components/content-header/content-header";
import PageHeader from "./header";
import ImageField from "components/image-field/image-field";
import WrapPage from "containers/page/page";
import WatermarkImageInspector from "./inspector";
import { setWatermarkImage, getWatermarkImage } from "store/watermark-images";
import styles from "./watermark-image.module.css";
import { PartialAppState, State } from "./state";
import { AnyImage } from "api/search-media-image";
import { WatermarkImage } from "api/watermark-images";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import Loader from "./loader";

const cx = classnames.bind(styles);

interface StateProps {
  watermarkImage: WatermarkImage;
  defaultWatermarkImagePage: State;
}

interface DispatchProps {
  openGallery: () => void;
  setSelectedMedia: (image: AnyImage) => void;
  getWatermarkImage: () => void;
  deleteWatermarkImage: () => void;
  closeGallery: () => void;
}

interface Props extends StateProps, DispatchProps {
  showMediaGallery?: boolean;
}

export const WatermarkImageComponent: React.FC<Props> = ({
  openGallery,
  showMediaGallery = false,
  setSelectedMedia,
  watermarkImage,
  getWatermarkImage,
  defaultWatermarkImagePage,
  deleteWatermarkImage,
  closeGallery
}) => {
  useEffect(getWatermarkImage, []);
  let [replaceWatermarkImage, setReplaceWatermarkImage] = React.useState(false);
  return (
    <LoaderWrapper className={styles["loader-wrapper"]} component={Loader} loader={defaultWatermarkImagePage.ui.main}>
      <React.Fragment>
        <ContentHeader
          title={t("settings.watermark_images.title")}
          description={t("settings.watermark_images.description")}
        />
        <div className={cx("main-container")}>
          <h3 className={cx("main-label")} data-test-id="main-label">
            {t("settings.watermark_images.main_label")}
          </h3>
          <ImageField
            classname={styles["image-upload-container"]}
            value={watermarkImage.image}
            isPhotoEditor={false}
            openPhotoEditor={() => null}
            openGallery={openGallery}
            onDelete={() => deleteWatermarkImage()}
            onReplace={() => setReplaceWatermarkImage(true)}
            disableReplace={false}
            setSelectedMedia={(images) => {
              setReplaceWatermarkImage(false);
              setSelectedMedia(images[0]);
            }}
          />
        </div>
        <WatermarkImageInspector
          isActive={replaceWatermarkImage || showMediaGallery}
          closeGallery={() => {
            closeGallery();
            setReplaceWatermarkImage(false);
          }}
          setSelectedMedia={(images) => {
            setReplaceWatermarkImage(false);
            setSelectedMedia(images[0]);
          }}
        />
      </React.Fragment>
    </LoaderWrapper>
  );
};

function mapDispatchToProps(dispatch: ThunkDispatch<PartialAppState, any, AnyAction>) {
  const navigate = navigateFn(dispatch);
  return {
    openGallery: () => navigate(CONFIGURE_WATERMARK_IMAGES_GALLERY_PATH),
    setSelectedMedia: (image: AnyImage) => {
      dispatch(setWatermarkImage({ image }));
      navigate(CONFIGURE_WATERMARK_IMAGES_PATH);
    },
    getWatermarkImage: () => {
      dispatch(getWatermarkImage()).catch(() => null);
    },
    deleteWatermarkImage: () => {
      dispatch(setWatermarkImage({ image: null }));
    },
    closeGallery: () => navigate(CONFIGURE_WATERMARK_IMAGES_PATH)
  };
}

function mapStateToProps(state: PartialAppState) {
  return {
    watermarkImage: state.watermarkImage.data,
    defaultWatermarkImagePage: state.defaultWatermarkImagePage
  };
}

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  WrapPage({ isStoryPage: false, HeaderComponent: PageHeader })
)(WatermarkImageComponent);
