/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useState, useCallback } from "react";
import { ButtonWithLoader } from "components/button/button";
import Spacer from "components/spacer/spacer";
import Envelope from "components/icons/envelope";
import { generateUserReport } from "../../api";
import { TokenApiFn } from "../../middleware";
import { NotificationMessage, createNotification } from "../../notifications";
import styles from "./user-report-button.module.css";
import { FilterSet, buildSearchQuery } from "store/consumer/consumer";

interface UserReportProps {
  tokenApiFn: TokenApiFn;
  type?: "link" | "primary" | "secondary" | "default" | "transparent";
  label?: string;
  notificationFn?(notification: NotificationMessage): void;
  integrationId?: number;
  realmId?: number;
  currentFilterSet?: FilterSet;
  searchTerm?: string;
  isDesktopSizeViewport: boolean;
}

async function makeUserReportCall(
  tokenFn: TokenApiFn,
  currentFilterSet?: FilterSet,
  searchTerm?: string,
  integrationId?: number,
  realmId?: number
): Promise<NotificationMessage> {
  try {
    if (integrationId && realmId) {
      let query = {};
      if (currentFilterSet && searchTerm) {
        query = currentFilterSet && { ...buildSearchQuery(currentFilterSet), q: searchTerm };
      } else if (currentFilterSet) {
        query = { ...buildSearchQuery(currentFilterSet) };
      } else if (searchTerm) {
        query = { q: searchTerm };
      }
      const res = await generateUserReport(tokenFn, integrationId, realmId, query);
      return res ? createNotification("success", "generating_report") : createNotification("error", "no_token");
    }
    return createNotification("error", "no_token");
  } catch (e) {
    switch (e.status) {
      case 400:
        return createNotification("error", "no_token");
      case 401:
        return createNotification("error", "no_token");
      case 403:
        return createNotification("error", "wrong_realm");
      case 409:
        return createNotification("info", "already_generating_report");
      case 422:
        return createNotification("error", "no_recipients");
      default:
        return createNotification("error", "misc_error");
    }
  }
}

const UserReportButton: React.FC<UserReportProps> = ({
  tokenApiFn,
  realmId,
  integrationId,
  type,
  label,
  notificationFn,
  currentFilterSet,
  searchTerm,
  isDesktopSizeViewport
}) => {
  const [isReportCallInProgress, setReportCallInProgress] = useState(false);
  const onClickHandler = useCallback(async () => {
    setReportCallInProgress(true);
    const result = await makeUserReportCall(tokenApiFn, currentFilterSet, searchTerm, integrationId, realmId);
    notificationFn && notificationFn(result);
    setReportCallInProgress(false);
  }, [tokenApiFn, integrationId, realmId, notificationFn, currentFilterSet, searchTerm]);
  return (
    <div className={styles["button-wrapper"]}>
      {isDesktopSizeViewport ? (
        <ButtonWithLoader
          testId="send-report-btn"
          type={type || "primary"}
          loading={isReportCallInProgress}
          onClick={onClickHandler}
          classname={styles["send-report-button"]}>
          <Envelope /> <Spacer token="xs" /> {label || "Send Report"}
        </ButtonWithLoader>
      ) : (
        <ButtonWithLoader
          testId="send-report-btn"
          onClick={onClickHandler}
          type={type || "primary"}
          loading={isReportCallInProgress}
          classname={styles["send-report-button-mbl"]}>
          <Envelope color={"var(--mono-1)"} />
        </ButtonWithLoader>
      )}
    </div>
  );
};

export { UserReportButton };
