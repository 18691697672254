/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import i18n from "i18next";
import { reduce } from "lodash";
import { register } from "timeago.js";
import { en_US, fi, hi_IN, ar } from "timeago.js/lib/lang";
import enINTranslations from "locales/en-IN/translations.json";
import hiTranslations from "locales/hi/translations.json";
import fiTranslations from "locales/fi/translations.json";
import arTranslations from "locales/ar/translations.json";

let instance: any = null;

interface Options {
  throwOnMissingKey?: boolean;
}

export type Locales = "en-in" | "hi" | "fi" | "ar";

type LocalesMapping = {
  [key in Locales]: {
    translations: any;
    registerTimeago: VoidFunction;
  };
};

export const localesMapping: LocalesMapping = {
  "en-in": {
    translations: enINTranslations,
    registerTimeago: () => register("en-in", en_US)
  },
  hi: {
    translations: hiTranslations,
    registerTimeago: () => register("hi", hi_IN)
  },
  fi: {
    translations: fiTranslations,
    registerTimeago: () => register("fi", fi)
  },
  ar: {
    translations: arTranslations,
    registerTimeago: () => register("ar", ar)
  }
};

function i18nResources(localesMapping: LocalesMapping) {
  return reduce(
    localesMapping,
    (result, value, key) => ({ ...result, [key]: { translations: value.translations } }),
    {}
  );
}

export const initialize = (lang, opts: Options = {}) => {
  const config = {
    lng: (lang || "en-in").toLowerCase(),
    fallbackLng: "en-in",
    resources: i18nResources(localesMapping),
    ns: ["translations"],
    defaultNS: "translations",
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed"
    },
    lowerCaseLng: true
  };

  const throwOnMissingKeyConfig = {
    saveMissing: true,
    missingKeyHandler: function(lng: any, ns: any, key: any, fallbackValue: any) {
      throw new Error("[I18n] missing base translation for key: " + key);
    }
  };

  instance = i18n.init(Object.assign(config, opts.throwOnMissingKey && throwOnMissingKeyConfig));
  return instance;
};

export const t = (...args: any) => {
  if (!instance) {
    throw new Error("[I18n] translate function called on an uninitialized instance!");
  }
  return instance.t.apply(instance, args);
};

export default t;
