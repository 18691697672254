/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { StoryElementType, StoryElementSubType } from "./story";
import { client } from "api/client";

export interface OembedResponse {
  html: string;
  src: string;
  metadata: any;
  subtype: StoryElementSubType<StoryElementType>;
  type: StoryElementType;
}

export const getOEmbedFromUrl = (params: { "oembed-url": string; provider?: string }): Promise<OembedResponse> => {
  return client
    .url("/api/oembed")
    .query(params)
    .get()
    .json();
};
