/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import ReactSelect from "react-select";
import classnames from "classnames/bind";
import { hasErrors } from "utils";

import styles from "./select.module.css";
import { Props as SelectProps } from "react-select/lib/Select";
import { CustomDropdownIndicator } from "./custom-dropdown-indicator";
import FieldLabel from "components/field-label/field-label";
import ErrorMessage from "components/error-message/error-message";
import defaultStyles from "./default-styles";
import { t } from "i18n";
/*

THIS FILE IS DUPLICATED FOR EVERY SELECT COMPONENT - Select, Async, Creatable, AsyncCreatable.

IF YOU ARE CHANGING ONE, PLEASE CHANGE ALL OTHERS.

OTHERWISE, FIX TYPESCRIPT GENERICS.

*/

interface OwnProps {
  label?: string;
  helpText?: string;
  errorMessage?: string;
  classname?: string;
  hideError?: boolean;
  isClearable?: boolean;
  autoFocus?: boolean;
  onMenuOpen?: () => void;
  onMenuClose?: () => void;
}

const cx = classnames.bind(styles);

type DefaultProps<OptionType> = Pick<SelectProps<OptionType>, "components">;
type StateProps<OptionType> = Pick<SelectProps<OptionType>, "components" | "placeholder">;

type Props<OptionType> = DefaultProps<OptionType> & OwnProps & SelectProps<OptionType>;

export default class Select<OptionType> extends React.Component<Props<OptionType>, StateProps<OptionType>> {
  // TODO: defaultProps is untyped as of now.
  // See https://github.com/Microsoft/TypeScript/issues/24018
  // See https://github.com/Microsoft/TypeScript/issues/14600
  static defaultProps: DefaultProps<any> = {
    components: { DropdownIndicator: CustomDropdownIndicator }
  };

  constructor(props: Props<OptionType>) {
    super(props);
    this.state = {
      components: this.getComponents(),
      placeholder: this.getPlaceholder()
    };
  }

  componentDidUpdate(prevProps: Props<OptionType>) {
    if (prevProps.isDisabled !== this.props.isDisabled) {
      this.setState({
        components: this.getComponents(),
        placeholder: this.getPlaceholder()
      });
    }
  }

  getComponents() {
    if (this.props.isDisabled)
      return { MultiValueRemove: () => null, DropdownIndicator: () => null, IndicatorSeparator: () => null };
    return this.props.components;
  }

  getPlaceholder() {
    if (this.props.isDisabled) return " ";
    return this.props.placeholder || t("common.select-placeholder");
  }
  render() {
    const {
      label,
      helpText,
      errorMessage,
      components,
      hideError,
      isClearable = false,
      autoFocus = false,
      classname,
      ...rest
    } = this.props as Props<OptionType>;
    const selectClassNames = cx("select-container", "container", classname, {
      "has-error": errorMessage && hasErrors(errorMessage, this.props.value)
    });
    const message = errorMessage && hasErrors(errorMessage, this.props.value) ? errorMessage : "";
    return (
      <div className={selectClassNames} data-test-id="select-container">
        {label && <FieldLabel htmlFor={`react-select-wrapper-title-${label}`} label={label} />}
        <div data-test-id="react-select-wrapper">
          <ReactSelect
            value={this.props.value}
            id={`react-select-${label}`}
            {...rest}
            classNamePrefix="react-select"
            className={styles["select-field"]}
            components={this.state.components}
            isClearable={isClearable}
            autoFocus={autoFocus}
            styles={defaultStyles}
            placeholder={this.state.placeholder}
          />
        </div>
        {helpText && !errorMessage && (
          <span className={styles["react-select-wrapper-help-text"]} data-test-id="react-select-wrapper-help-text">
            {helpText}
          </span>
        )}
        {!hideError && (
          <div className={cx("error", classname)}>
            <ErrorMessage message={message} />
          </div>
        )}
      </div>
    );
  }
}
