/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { StorySlugSettingsState } from "pages/settings/initial-state";

enum actions {
  UPDATE_STORY_SLUG_SETTINGS = "@@settings/UPDATE_STORY_SLUG_SETTINGS",
  ENABLE_STORY_SLUG_SAVE_BUTTON = "@@settings/ENABLE_STORY_SLUG_SAVE_BUTTON",
  DISABLE_STORY_SLUG_SAVE_BUTTON = "@@settings/DISABLE_STORY_SLUG_SAVE_BUTTON"
}

export type UpdateStorySlugSettingsAction = {
  type: actions.UPDATE_STORY_SLUG_SETTINGS;
  payload: StorySlugSettingsState;
};

export type EnableStorySlugSaveButtonAction = {
  type: actions.ENABLE_STORY_SLUG_SAVE_BUTTON;
};

export type DisableStorySlugSaveButtonAction = {
  type: actions.DISABLE_STORY_SLUG_SAVE_BUTTON;
};

export type ActionTypes =
  | UpdateStorySlugSettingsAction
  | EnableStorySlugSaveButtonAction
  | DisableStorySlugSaveButtonAction;

export default actions;
