/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";
import * as api from "api/collection-config";
import * as entityTypeApi from "api/entity-type";
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from "containers/page/actions";
import { t } from "i18n";
import { loadCollectionsConfigActions, updateCollectionConfigAction, resetConfigAction } from "./action-creators";
import { PartialAppState } from "./state";

export const loadCollectionConfig = () => async (dispatch: ThunkDispatch<any, any, any>) => {
  try {
    const collectionsConfig = await api.getCollectionConfig();
    const allEntityTypesResponse = await entityTypeApi.listEntityTypes();
    const allEntityTypes = allEntityTypesResponse["entity-types"].map((entityType) => entityType.name);
    dispatch(loadCollectionsConfigActions(allEntityTypes, collectionsConfig));
  } catch (error) {
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: {
        message: t("settings.collections.messages.unknown-error")
      }
    });
  }
};

export const updateCollectionConfig = (config: {
  enabledEntityTypes?: api.CollectionEntityType[];
  storyLimit?: number;
}) => async (dispatch: ThunkDispatch<any, any, any>, getState: () => PartialAppState) => {
  const collectionsConfig = getState().collectionsConfig;
  dispatch(updateCollectionConfigAction({ ...collectionsConfig, ...config }));
};

export const resetConfig = () => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(resetConfigAction());
};

export const saveCollectionConfig = (collectionsConfig: api.CollectionsConfig) => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  try {
    await api.saveCollectionConfig({ ...collectionsConfig });
    dispatch(resetConfigAction());
    dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: { message: t("settings.collections.messages.update-success") }
    });
  } catch (error) {
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: {
        message: t("settings.collections.messages.unknown-error")
      }
    });
  }
};
