/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ThunkDispatch } from "redux-thunk";
import { isEmpty } from "lodash";
import { diffObjects } from "utils";
import * as FeatureAPI from "api/feature";
import { selectFeatureBySlug } from "store/feature/selectors";
import { PartialAppState } from "store/secret-mode/secret-mode";

export interface Feature {
  id: number;
  slug: string;
  name: string;
  description: string;
  toggle: boolean;
  config: { [key: string]: any } | null;
}

export type FeatureSettings = NormalizedObject<Feature>;

export interface State {
  features: NormalizedObject<Feature>;
}

const INITIAL_STATE: State = {
  features: {}
};

const { reducer, actions } = createSlice({
  name: "feature",
  initialState: INITIAL_STATE,
  reducers: {
    loadFeatures: (state: State, action: PayloadAction<FeatureSettings>) => {
      state.features = { ...state.features, ...action.payload };
    }
  }
});

export const { loadFeatures } = actions;

export function loadFeaturesBySlug(slugs: string[]) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
    const features = await FeatureAPI.getBySlugs(slugs);
    dispatch(loadFeatures(features));
  };
}

export function saveFeatureSettings(featureSettings: FeatureSettings) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>, getState: () => PartialAppState) => {
    try {
      const changedFeatures: any = [];
      for (const feature in featureSettings) {
        const initialFeature = selectFeatureBySlug(getState().feature, feature);
        const changedFeature = featureSettings[feature];
        const diffedFeature = diffObjects(initialFeature, changedFeature);
        !isEmpty(diffedFeature) && changedFeatures.push({ id: changedFeature.id, changes: diffedFeature });
      }
      await Promise.all(changedFeatures.map((changedFeature) => Promise.resolve(FeatureAPI.update(changedFeature))));
    } catch (e) {
      throw e;
    }
  };
}

export default reducer;
