/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Plugin } from "prosemirror-state";
import { handleEnter } from "../prosemirror/commands";

function isAndroid() {
  return /Android/i.test(navigator.userAgent);
}

function executeCommandsSequentially(...commands) {
  return (state, dispatch) => {
    for (let command of commands) {
      if (command(state, dispatch)) {
        return true;
      }
    }
    return false;
  };
}

export function customKeymapPlugin(schema) {
  const androidEnterHandler = (state, dispatch) => {
    if (isAndroid()) {
      return executeCommandsSequentially(() => {
        return handleEnter()(state, dispatch);
      })(state, dispatch);
    }
    return false;
  };

  let enterKeyPressed = false;

  return new Plugin({
    props: {
      handleKeyDown(view, event) {
        //Explicitly added keydown event, since prosemirror with Android doesn't recognize Enter keypress.
        //enterKeyPressed is added so that next time we press enter, prosemirror recognizes the keypress, otherwise it was hanging post one keypress detection
        //event.preventDefault(); was beign added to handle the conflicting behaviour of expicit key check and prosemirror behaviour
        if (isAndroid()) {
          document.addEventListener("keydown", (event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              console.log("Global Enter key event detected");
              if (!enterKeyPressed) {
                console.log("CUSTOM PLUGIN JACKED!");
                androidEnterHandler(view.state, view.dispatch);
                enterKeyPressed = true;
                setTimeout(() => {
                  enterKeyPressed = false;
                }, 0);
              }
              return true;
            }
            return false;
          });
        }
      }
    }
  });
}
