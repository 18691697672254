/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Rodal from "rodal";
import Button from "../button/button";
import styles from "./confirmation-modal.module.css";

interface Props {
  showConfirmationModal?: boolean;
  headerText: string;
  text?: string | React.ReactNode;
  confirmButtonLabel: string;
  cancelButtonLabel: string;
  confirmButtonVariant?: "success" | "danger" | "warning" | "none";
  onConfirm: () => void;
  onCancel: () => void;
  onClose?: () => void;
}

const ConfirmationModal: React.SFC<Props> = ({
  showConfirmationModal,
  headerText,
  text,
  confirmButtonLabel,
  cancelButtonLabel,
  onConfirm,
  onCancel,
  onClose = onCancel,
  confirmButtonVariant
}: Props) => {
  return (
    <React.Fragment>
      <Rodal
        className="rodal-container"
        visible={!!showConfirmationModal}
        onClose={onClose}
        data-test-id="rodal-container">
        <div className={styles["rodal-content"]} data-test-id="rodal-content">
          <div>
            <div className={styles["rodal-header"]} data-test-id="rodal-header">
              {headerText}
            </div>
            {text && (
              <div data-test-id="rodal-body" className={styles["body"]}>
                {text}
              </div>
            )}
            <div className={styles["confirmation-actions"]} data-test-id="confirmation-actions">
              <Button
                variant={confirmButtonVariant === "none" ? undefined : confirmButtonVariant || "danger"}
                type="primary"
                onClick={onConfirm}
                data-test-id="confirm-btn">
                {confirmButtonLabel}
              </Button>
              <Button type="default" onClick={onCancel} data-test-id="cancel-btn">
                {cancelButtonLabel}
              </Button>
            </div>
          </div>
        </div>
      </Rodal>
    </React.Fragment>
  );
};

export default ConfirmationModal;
