/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Button from "components/button/button";
import styles from "./content-header.module.css";
import { InfoCircle } from "@quintype/em/icons/info-circle";
import Tooltip from "../../../../components/tooltip/tooltip";

interface Props {
  title?: string;
  titleInfo?: string;
  handleActionButtonClick?: React.MouseEventHandler;
  description?: string;
  actionButtonLabel?: string;
  actionButtonType?: "primary" | "secondary" | "default";
  headerTitle?: React.ReactElement<any>;
  addNewDisabled?: boolean;
}

function showTitleInfo(titleInfo?: string) {
  if (!titleInfo) {
    return;
  }
  return (
    <>
      <span className={styles["content-title-info"]} data-test-id="content-title-info" data-for="data-fetch" data-tip>
        <InfoCircle label="" />
      </span>
      <Tooltip id="data-fetch" type="info" place="bottom" effect="solid" value={titleInfo} />
    </>
  );
}

const ContentHeader: React.SFC<Props> = ({
  title,
  description,
  actionButtonLabel,
  actionButtonType = "secondary",
  handleActionButtonClick,
  addNewDisabled,
  headerTitle,
  titleInfo,
  children
}) => {
  return (
    <div className={styles["content-header"]} data-test-id="content-header-container">
      <div className={styles["content-header-details"]}>
        {!headerTitle && (
          <div>
            <h2 className={styles["content-header-title"]} data-test-id="content-header-title">
              {title}
              {showTitleInfo(titleInfo)}
            </h2>
            {description && (
              <p className={styles["content-header-description"]} data-test-id="content-header-description">
                {description}
              </p>
            )}
          </div>
        )}
        {headerTitle}
      </div>
      <div className={styles["content-header-button-container"]} data-test-id="content-header-button-container">
        {actionButtonLabel && handleActionButtonClick && (
          <Button
            testId="content-header-button"
            type={actionButtonType}
            onClick={handleActionButtonClick}
            disabled={addNewDisabled}>
            {actionButtonLabel}
          </Button>
        )}
        {children && children}
      </div>
    </div>
  );
};
export default ContentHeader;
