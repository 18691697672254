/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { NewWebhook } from "api/webhooks";
import { t } from "i18n";
import { validate } from "utils/validation.utils";

export function validateWebhookChange(data: Partial<NewWebhook>) {
  return validate(data, {
    url: {
      urlEnforceLabelLimit: { message: t("settings.webhooks.validation.invalid_url") },
      length: {
        maximum: 255, // maximum url length in db column
        tooLong: t("settings.webhooks.validation.too_long_url")
      }
    }
  });
}
