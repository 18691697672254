/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import styles from "./stat.module.css";
import classnames from "classnames/bind";

interface StatsProps {
  total: number;
  statChange: number | null;
  statsLabel: string;
  showDelta?: boolean;
}

const cx = classnames.bind(styles);

const Stats: React.SFC<StatsProps> = ({ total, statChange, statsLabel, showDelta = true }) => {
  return (
    <div
      className={cx("stats-container", {
        "is-positive": statChange && statChange > 0,
        "is-negative": statChange && statChange < 0,
        "is-zero": statChange === 0,
        "is-new": statChange === null
      })}>
      <h5 className={styles["stats-container__label"]}>{statsLabel}</h5>
      <span className={styles["stats-container__total"]}>{total.toLocaleString("en-IN")}</span>
      {showDelta && (
        <React.Fragment>
          <span className={styles["stats-container__delta"]} />
          <span className={styles["stats-container__delta-value"]}>{!!statChange && `${statChange}%`}</span>
        </React.Fragment>
      )}
    </div>
  );
};

export default Stats;
