/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import { PartialAppState, StoryTemplateFields } from "./state";
import { initializeNewBreakingNews, updateStoryTemplateFields, setLinkType } from "./async-action-creators";
import { getAutoFilledStoryFieldsUsingDefaults } from "./utils";
import { AssociatedStoryDefaults } from "api/route-data/breaking-news-route-data";
import { LinkType, getAssociatedStoryLinkType } from "./constants";

import BreakingNews from "./breaking-news";

interface OwnProps {
  showAdvancedOptionsInspector: boolean;
}

interface StateProps {
  storyTemplateFields: StoryTemplateFields;
  associatedStoryDefaults: AssociatedStoryDefaults;
}

interface DispatchProps {
  initializeNewBreakingNews: () => void;
  setLinkType: (linkType: LinkType) => void;
  updateStoryTemplateFields: (storyTemplateFields: StoryTemplateFields) => void;
}

type NewBreakingNewsProps = OwnProps & StateProps & DispatchProps;

class NewBreakingNews extends React.Component<NewBreakingNewsProps> {
  componentDidMount() {
    this.props.initializeNewBreakingNews();
    if (this.props.showAdvancedOptionsInspector) {
      const autoFilledStoryFields = getAutoFilledStoryFieldsUsingDefaults(this.props.associatedStoryDefaults);

      this.props.setLinkType(getAssociatedStoryLinkType());
      this.props.updateStoryTemplateFields({ ...this.props.storyTemplateFields, ...autoFilledStoryFields });
    }
  }

  render() {
    return <BreakingNews showAdvancedOptionsInspector={this.props.showAdvancedOptionsInspector} />;
  }
}

const mapStateToProps = (state: PartialAppState) => ({
  storyTemplateFields: state.breakingNews.app.storyTemplateFields,
  associatedStoryDefaults: state.config["associated-story-defaults"]
});

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, any, AnyAction>) => ({
  initializeNewBreakingNews: () => dispatch(initializeNewBreakingNews()),
  setLinkType: (linkType: LinkType) => dispatch(setLinkType(linkType)),
  updateStoryTemplateFields: (storyTemplateFields: StoryTemplateFields) =>
    dispatch(updateStoryTemplateFields(storyTemplateFields))
});

export { NewBreakingNews };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewBreakingNews);
