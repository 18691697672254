/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import { Router as EntitiesRouter } from "./entities/router";
import { Router as SectionsRouter } from "./sections/router";
import { Router as MenusRouter } from "./menu/router";
import { Router as AttributesRouter } from "./attributes/router";
import { Router as CustomMetadataRouter } from "./custom-metadata/router";
import { Router as TagsRouter } from "./tags/router";
import { Router as CustomURLsRouter } from "./custom-urls/router";
import { Router as SitemapRouter } from "./sitemap/router";

export const Router = () => {
  return (
    <Switch>
      <Route path="/manage/entities" component={EntitiesRouter} />
      <Route path="/manage/sections" component={SectionsRouter} />
      <Route path="/manage/menu" component={MenusRouter} />
      <Route path="/manage/attributes" component={AttributesRouter} />
      <Route path="/manage/custom-metadata" component={CustomMetadataRouter} />
      <Route path="/manage/tags" component={TagsRouter} />
      <Route path="/manage/custom-urls" component={CustomURLsRouter} />
      <Route path="/manage/sitemap" component={SitemapRouter} />
    </Switch>
  );
};
