/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";

import { CustomField } from "api/custom-field";
import Button from "components/button/button";
import styles from "./custom-metadata-tree.module.css";
import RootCustomMetadataList from "./root-custom-metadata-list";

interface OwnProps {
  customMetadataFields: Array<CustomField>;
  openAddInspector: () => void;
  deleteCustomMetadataField: (customField: CustomField) => void;
  editCustomMetadataField: (CustomField: CustomField) => void;
}

type Props = OwnProps;

export const CustomMetadataTree: React.FC<Props> = ({
  customMetadataFields,
  openAddInspector,
  deleteCustomMetadataField,
  editCustomMetadataField
}) => {
  return (
    <React.Fragment>
      <div className={styles["custom-metadata"]}>
        <header className={styles["custom-metadata-header"]} data-test-id="custom-metadata-header">
          <h3 className={styles["custom-metadata-title"]} data-test-id="custom-metadata-title">
            {t("custom-metadata.title")}
          </h3>
          <Button type="secondary" onClick={openAddInspector} testId="custom-metadata-btn">
            {t("custom-metadata.cta.create")}
          </Button>
        </header>
        <RootCustomMetadataList
          customMetadataFields={customMetadataFields}
          deleteCustomMetadataField={(customField: CustomField) => deleteCustomMetadataField(customField)}
          editCustomMetadataField={(customField: CustomField) => editCustomMetadataField(customField)}
        />
      </div>
    </React.Fragment>
  );
};

export default CustomMetadataTree;
