/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import produce from "immer";
import INITIAL_STATE from "./initial-state";
import { actions } from "../actions";
import { Task } from "api/tasks";
import { TaskId } from "api/primitive-types";

export type ActionType =
  | { type: actions.LOAD_STORY_TASKS }
  | { type: actions.LOAD_STORY_TASKS_SUCCESS; payload: Task }
  | { type: actions.LOAD_STORY_TASKS_FAILURE; payload: Error }
  | { type: actions.CLOSE_TASK }
  | { type: actions.CLOSE_ALL_TASKS_SUCCESS }
  | { type: actions.CLOSE_TASK_SUCCESS; payload: { taskId: TaskId; task: Task } }
  | { type: actions.CLOSE_TASK_FAILURE };

export default function tasksReducer(state = INITIAL_STATE, action: ActionType) {
  return produce(state, (draft) => {
    switch (action.type) {
      case actions.LOAD_STORY_TASKS:
        draft.ui.taskUI.isLoading = true;
        draft.ui.taskUI.error = null;
        break;

      case actions.LOAD_STORY_TASKS_SUCCESS:
        draft.taskState.task = action.payload;
        draft.ui.taskUI.isLoading = false;
        draft.ui.taskUI.error = null;
        break;

      case actions.LOAD_STORY_TASKS_FAILURE:
        draft.ui.taskUI.isLoading = false;
        draft.ui.taskUI.error = action.payload;
        break;

      case actions.CLOSE_TASK:
        draft.ui.taskUI.isLoading = true;
        draft.ui.taskUI.error = null;
        break;

      case actions.CLOSE_TASK_SUCCESS:
        if (draft.taskState.task && draft.taskState.task.id === action.payload.taskId) {
          draft.taskState.task.completed = action.payload.task.completed;
        } else {
          const subtasks =
            state.taskState.task &&
            state.taskState.task.subtasks.map((subtask: Task) =>
              subtask.id === action.payload.taskId ? { ...subtask, completed: action.payload.task.completed } : subtask
            );

          if (draft.taskState.task) {
            draft.taskState.task.subtasks = subtasks ? subtasks : [];
          }
        }

        draft.ui.taskUI.isLoading = false;
        draft.ui.taskUI.error = null;
        break;

      case actions.CLOSE_TASK_FAILURE:
        draft.ui.taskUI.isLoading = false;
        draft.ui.taskUI.error = null;
        break;

      case actions.CLOSE_ALL_TASKS_SUCCESS:
        const closedSubtasks =
          state.taskState.task &&
          state.taskState.task.subtasks.map((subtask: Task) => ({ ...subtask, completed: true }));
        if (draft.taskState.task) {
          draft.taskState.task.subtasks = closedSubtasks ? closedSubtasks : [];
        }
        draft.ui.taskUI.isLoading = false;
        draft.ui.taskUI.error = null;
        break;

      default:
        break;
    }
  });
}
