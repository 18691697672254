/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { t } from "i18n";
import { WEBHOOKS_PATH } from "../../routes";
import { navigate } from "utils/routes.utils";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  PartialAppState,
  EventDetails,
  updateWebhook,
  setCurrentWebhook,
  ValidationError
} from "store/webhook/webhook";
import Inspector from "components/inspector/inspector";
import { WebhookDetails, WebhookPayload } from "api/webhooks";
import { SecretEntry, TriggerEvents, UrlEntry } from "../inspector-inputs/inspector-inputs";
import { get } from "lodash";

interface OwnProps {
  isActive: boolean;
}

interface StateProps {
  selectedWebhook: WebhookDetails | null;
  publisherTriggerEvents: EventDetails[];
  validationErrors: ValidationError | null;
}

interface DispatchProps {
  onClose: VoidFunction;
  onChange(data: Partial<WebhookPayload>): void;
  onSave(webhookId: number, data: Partial<WebhookPayload>): void;
}

type Props = OwnProps & StateProps & DispatchProps;

function EditInspector({
  selectedWebhook,
  publisherTriggerEvents,
  isActive,
  onClose,
  onChange,
  onSave,
  validationErrors
}: Props) {
  useEffect(() => {
    isActive && !selectedWebhook && onClose();
  }, [isActive, selectedWebhook, onClose]);

  const { url = "", secret = "", events = [] } = selectedWebhook || {};

  return (
    <Inspector
      title={t("settings.webhooks.inspector.edit_title")}
      actionButtonLabel={t("settings.webhooks.inspector.action_button_label")}
      onActionButtonClick={() => {
        selectedWebhook && onSave(selectedWebhook.id, { ...selectedWebhook });
      }}
      isActive={isActive && selectedWebhook}
      onClose={onClose}
      isActionButtonDisabled={!!validationErrors}
      isBackgroundActionDisabled={true}>
      <div>
        <UrlEntry
          url={url}
          handleUrlChange={(newUrl) => onChange({ url: newUrl })}
          error={get(validationErrors, "url", [])}
        />
        <SecretEntry secret={secret} handleSecretChange={(newSecret) => onChange({ secret: newSecret })} />
        <TriggerEvents
          publisherTriggerEvents={publisherTriggerEvents}
          selectedTriggerEventIds={events}
          handleEventToggle={(eventId, checked) => {
            if (selectedWebhook) {
              if (checked) {
                onChange({ events: [...selectedWebhook.events, eventId] });
              } else {
                onChange({ events: selectedWebhook.events.filter((event) => event !== eventId) });
              }
            }
          }}
        />
      </div>
    </Inspector>
  );
}

function mapStateToProps(state: PartialAppState): StateProps {
  return {
    selectedWebhook: state.webhooks.app.currentWebhook,
    publisherTriggerEvents: state.config["trigger-events"] || [],
    validationErrors: state.webhooks.app.error
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps {
  return {
    onClose: () => dispatch(navigate(WEBHOOKS_PATH)),
    onChange: (data) => dispatch(setCurrentWebhook(data)),
    onSave: (webhookId, data) => dispatch(updateWebhook(webhookId, data))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditInspector);
export { EditInspector };
