/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import Select from "components/select/select";
import "react-day-picker/lib/style.css";
import { startOfDay, endOfDay, endOfToday, startOfYesterday } from "date-fns";
import "./date-filter-select.css";
import {
  DateFilterTypes,
  DateOption,
  DATE_OPTIONS,
  DateFilter,
  RelativeDateFilter,
  CustomDateFilter
} from "components/date-filter/date-filter";

type OnChange = (filter: DateFilter | null) => void;

interface OwnProps {
  label?: string;
  value: DateFilter | null;
  onChange: OnChange;
  classname?: string;
}

export default class DateFilterSelect extends React.Component<OwnProps> {
  handleStartAndEndDate(startDate: Date, endDate: Date) {
    const formatStartDate = startDate && startOfDay(startDate);
    const formatEndDate = endDate && endOfDay(endDate);

    const newFilter: CustomDateFilter = {
      type: DateFilterTypes.Custom,
      from: formatStartDate.getTime(),
      to: formatEndDate.getTime()
    };

    this.props.onChange(newFilter as CustomDateFilter);
  }

  onChange(type: DateFilterTypes | null) {
    const defaultStartDate = startOfYesterday().getTime();
    const defaultEndDate = endOfToday().getTime();
    const filter = type
      ? type === DateFilterTypes.Custom
        ? ({
            type,
            from: defaultStartDate,
            to: defaultEndDate
          } as CustomDateFilter)
        : ({
            type
          } as RelativeDateFilter)
      : null;
    this.props.onChange(filter);
  }

  render() {
    const filter = this.props.value;
    return (
      <div className={this.props.classname}>
        <Select
          label={this.props.label}
          value={(filter && DATE_OPTIONS(t).find((option) => option.value === filter.type)) || null}
          options={DATE_OPTIONS(t)}
          onChange={(dateOption: DateOption | null) => this.onChange(dateOption ? dateOption.value : null)}
          classname="date-filter-error"
        />
      </div>
    );
  }
}
