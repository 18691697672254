/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import Tags from "./tags";
import { TAG_EDIT_PATH, TAGS_INDEX_PATH, TAGS_NEW_PATH } from "./routes";

export const Router = () => (
  <React.Fragment>
    <Switch>
      <Route exact path={TAGS_INDEX_PATH} component={Tags} />
      <Route exact path={TAGS_NEW_PATH} component={Tags} />
      <Route exact path={TAG_EDIT_PATH} component={Tags} />
    </Switch>
  </React.Fragment>
);
