/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const BILLING_PATH = "/billing";
export const BANDWIDTH_BILLING_PATH = "/billing/bandwidth";
export const SAGE_BILLING_PATH = "/billing/sage";
