/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import * as ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { store } from "../../../store";
import cx from "classnames";
import StoryElement from "../story-elements/story-element";
import StoryCard from "pages/story-editor/story-card/story-card";
import "./node-views.module.css";

import NodeViewSelect from "../components/node-view-select/node-view-select";
import TextAndSelect from "../components/text-and-select/text-and-select";
import CtaCheckboxOptions from "../components/cta-checkbox-options/cta-checkbox-options";
import { ElementType } from "./types";

class ReactNodeView {
  view: any;
  component: any;
  nodeTypeName: any;
  dom: any;
  contentDOM: any;
  constructor(node, view, component, { domAttributes = {} }, opts: any = {}) {
    this.view = view;
    this.component = component;
    this.nodeTypeName = node.type.name;
    this.dom = document.createElement("div");

    if (opts.useContentDOM) {
      this.contentDOM = document.createElement("div");
    }

    for (const key in domAttributes) {
      if (domAttributes.hasOwnProperty(key)) {
        this.dom.setAttribute(key, domAttributes[key]);
      }
    }

    this.render(node);
  }

  update(node) {
    const isValidUpdate = this.nodeTypeName === node.type.name;

    if (isValidUpdate) {
      this.render(node);
    }

    return isValidUpdate;
  }

  ignoreMutation = (mutationRecord) => mutationRecord.type !== "selection";

  stopEvent = () => true;

  destroy() {
    ReactDOM.unmountComponentAtNode(this.dom);
  }

  render(node) {
    const Component = this.component;
    ReactDOM.render(
      <React.Fragment>
        <Provider store={store}>
          {this.contentDOM ? (
            <Component id={node.attrs["client-id"]} node={node} contentDOM={this.contentDOM} view={this.view} />
          ) : (
            <Component node={node} view={this.view} />
          )}
        </Provider>
      </React.Fragment>,
      this.dom
    );
  }
}

const nodeViews = {
  story_element_others: (node, view) => {
    const classes = cx("story-element", {
      ["story-element-" + node.attrs.type]: true,
      ["story-element-" + node.attrs.subtype]: node.attrs.subtype
    });

    return new ReactNodeView(node, view, StoryElement, {
      domAttributes: { class: classes }
    });
  },
  story_element_text: (node, view) => {
    const classes = cx("story-element", {
      ["story-element-" + node.attrs.type]: true,
      ["story-element-" + node.attrs.subtype]: node.attrs.subtype
    });

    return new ReactNodeView(node, view, StoryElement, { domAttributes: { class: classes } }, { useContentDOM: true });
  },
  quote: (node, view) => {
    const classes = cx("story-element", {
      ["story-element-" + node.attrs.type]: true,
      ["story-element-" + node.attrs.subtype]: node.attrs.subtype
    });
    return new ReactNodeView(node, view, StoryElement, { domAttributes: { class: classes } }, { useContentDOM: true });
  },
  quote_attribution: (node, view) => {
    return new ReactNodeView(node, view, TextAndSelect, {}, { useContentDOM: true });
  },
  [ElementType.Cta]: (node, view) => {
    const classes = cx("story-element", {
      ["story-element-" + node.attrs.type]: true,
      ["story-element-" + node.attrs.subtype]: node.attrs.subtype
    });
    return new ReactNodeView(node, view, StoryElement, { domAttributes: { class: classes } }, { useContentDOM: true });
  },
  cta_options: (node, view) => {
    return new ReactNodeView(node, view, CtaCheckboxOptions, {}, { useContentDOM: true });
  },
  blurb: (node, view) => {
    const classes = cx("story-element", {
      ["story-element-" + node.attrs.type]: true,
      ["story-element-" + node.attrs.subtype]: node.attrs.subtype
    });
    return new ReactNodeView(node, view, StoryElement, { domAttributes: { class: classes } }, { useContentDOM: true });
  },
  title: (node, view) => {
    const classes = cx("story-element", {
      ["story-element-" + node.attrs.type]: true,
      ["story-element-" + node.attrs.subtype]: node.attrs.subtype
    });
    return new ReactNodeView(node, view, StoryElement, { domAttributes: { class: classes } }, { useContentDOM: true });
  },
  question: (node, view) => {
    const classes = cx("story-element", {
      ["story-element-" + node.attrs.type]: true,
      ["story-element-" + node.attrs.subtype]: node.attrs.subtype
    });
    return new ReactNodeView(node, view, StoryElement, { domAttributes: { class: classes } }, { useContentDOM: true });
  },
  answer: (node, view) => {
    const classes = cx("story-element", {
      ["story-element-" + node.attrs.type]: true,
      ["story-element-" + node.attrs.subtype]: node.attrs.subtype
    });
    return new ReactNodeView(node, view, StoryElement, { domAttributes: { class: classes } }, { useContentDOM: true });
  },
  bigfact: (node, view) => {
    const classes = cx("story-element", {
      ["story-element-" + node.attrs.type]: true,
      ["story-element-" + node.attrs.subtype]: node.attrs.subtype
    });
    return new ReactNodeView(node, view, StoryElement, { domAttributes: { class: classes } }, { useContentDOM: true });
  },
  q_and_a_element: (node, view) => {
    const classes = cx("story-element", {
      ["story-element-" + node.attrs.type]: true,
      ["story-element-" + node.attrs.subtype]: node.attrs.subtype
    });
    return new ReactNodeView(node, view, StoryElement, { domAttributes: { class: classes } }, { useContentDOM: true });
  },
  q_and_a_answer_attribution_helper: (node, view) => {
    return new ReactNodeView(node, view, NodeViewSelect, {}, { useContentDOM: true });
  },
  q_and_a_question_attribution_helper: (node, view) => {
    return new ReactNodeView(node, view, NodeViewSelect, {}, { useContentDOM: true });
  },
  card: (node, view) => new ReactNodeView(node, view, StoryCard, {}, { useContentDOM: true })
};

export default nodeViews;
