/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import * as ReactDOM from "react-dom";

import { unregister } from "./registerServiceWorker";

import "normalize.css/normalize.css";
import "./global.css";
import "./viewports.css";

import { store } from "./store";
import App from "./app";
import Router from "./router";

ReactDOM.render(
  <App store={store}>
    <Router />
  </App>,
  document.getElementById("root")
);

unregister();
