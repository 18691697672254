/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface Props {
  width?: number | string;
  height?: number | string;
  fill?: string;
}

const Plus = ({ width = "24", height = "24", fill = "var(--mono-3)" }: Props) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={fill}>
    <path fill="none" d="M.03.13h24v24h-24z" />
    <path d="M19.7 11a.3.3 0 0 1 .23.1.33.33 0 0 1 .11.23v1.37a.38.38 0 0 1-.34.34H13v6.66a.38.38 0 0 1-.34.34h-1.29a.33.33 0 0 1-.23-.11.3.3 0 0 1-.1-.23V13H4.37a.33.33 0 0 1-.23-.11.3.3 0 0 1-.14-.19v-1.33a.34.34 0 0 1 .37-.37H11V4.37a.34.34 0 0 1 .37-.37h1.33a.3.3 0 0 1 .23.1.33.33 0 0 1 .11.23V11z" />
  </svg>
);

export default Plus;
