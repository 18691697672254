/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import {
  STORY_EDITOR_TEMPLATE_PATH,
  STORY_EDITOR_HERO_IMAGE_GALLERY_PATH,
  STORY_EDITOR_IMAGE_INSPECTOR_PATH,
  STORY_EDITOR_IMAGE_ELEMENT_GALLERY_PATH,
  STORY_EDITOR_MANAGE_PATH,
  STORY_EDITOR_MANAGE_ADVANCED_METADATA_PATH,
  STORY_EDITOR_MANAGE_ADVANCED_ATTRIBUTES_PATH,
  STORY_EDITOR_MANAGE_ADVANCED_SOCIAL_NOTIFICATIONS_PATH,
  STORY_EDITOR_ALTERNATIVES_PATH,
  STORY_EDITOR_ALTERNATIVES_IMAGE_PATH,
  STORY_EDITOR_ALTERNATIVES_IMAGE_EDIT_PATH,
  STORY_EDITOR_CARD_SHARE,
  STORY_EDITOR_CARD_ATTRIBUTES,
  STORY_EDITOR_CARD_SHARE_IMAGE_PATH,
  STORY_EDITOR_POLL_EDIT,
  STORY_EDITOR_STORY_SEARCH_PATH,
  STORY_EDITOR_IMPORT_PATH,
  STORY_EDITOR_IMAGE_GALLERY_ELEMENT_GALLERY_PATH,
  STORY_EDITOR_IMAGE_GALLERY_ELEMENT_EDIT_PATH,
  STORY_EDITOR_PLAGIARISM_REPORT,
  STORY_EDITOR_NOTES_PATH,
  STORY_EDITOR_OEMBED_VIDEO_SELECTOR_PATH,
  STORY_EDITOR_STORY_IMAGE_PATH
} from "pages/story-editor/routes";
import { StoryId, CardId, ImageId, ClientId, StoryElementId } from "api/primitive-types";

import { t } from "i18n";

enum NavigationButton {
  Close = "close",
  Open = "open",
  Back = "back",
  CloseMultiMediaGallery = "closeMultiMediaGallery"
}

export interface InspectorDataParams {
  subtype?: string;
  storyElementId?: StoryElementId;
  storyElementClientId?: ClientId;
  imageId?: ImageId;
  id?: StoryId;
  cardId?: CardId;
  elementType?: string;
}

export interface InspectorData {
  active: boolean;
  title: string;
  actionButton?: string;
  navigationButton: NavigationButton | null;
  inspectorName: string;
  variant: "default" | "wide";
  selectedTab?: string;
  params: InspectorDataParams;
  level?: number;
}

const inspectorDataByPath: {
  [routePath: string]: Partial<InspectorData>;
} = {
  [STORY_EDITOR_TEMPLATE_PATH]: {
    title: "story-editor.inspector.set-template",
    inspectorName: "templateInspector"
  },
  [STORY_EDITOR_HERO_IMAGE_GALLERY_PATH]: {
    title: "story-editor.inspector.choose-image",
    inspectorName: "imageGalleryWithoutEditInspector",
    variant: "wide"
  },
  [STORY_EDITOR_IMAGE_INSPECTOR_PATH]: {
    title: "story-editor.inspector.edit-image",
    actionButton: "story-editor.inspector.choose",
    navigationButton: NavigationButton.CloseMultiMediaGallery,
    inspectorName: "StoryImageWithEditInspector",
    variant: "wide"
  },
  [STORY_EDITOR_STORY_IMAGE_PATH]: {
    title: "story-editor.inspector.edit-image",
    actionButton: "story-editor.inspector.choose",
    navigationButton: NavigationButton.Back,
    inspectorName: "publisherStoryImageWithoutEditInspector",
    variant: "wide"
  },
  [STORY_EDITOR_IMAGE_ELEMENT_GALLERY_PATH]: {
    title: "story-editor.inspector.choose-image",
    inspectorName: "imageGalleryWithoutEditInspector",
    variant: "wide"
  },
  [STORY_EDITOR_MANAGE_PATH]: {
    title: "story-editor.inspector.story-details",
    inspectorName: "mandatoryInspector"
  },
  [STORY_EDITOR_MANAGE_ADVANCED_METADATA_PATH]: {
    title: "story-editor.inspector.story-details",
    inspectorName: "advancedInspector",
    selectedTab: "metadata"
  },
  [STORY_EDITOR_MANAGE_ADVANCED_ATTRIBUTES_PATH]: {
    title: "story-editor.inspector.story-details",
    inspectorName: "advancedInspector",
    selectedTab: "attributes"
  },
  [STORY_EDITOR_MANAGE_ADVANCED_SOCIAL_NOTIFICATIONS_PATH]: {
    title: "story-editor.inspector.story-details",
    inspectorName: "advancedInspector",
    selectedTab: "social-notifications"
  },
  [STORY_EDITOR_ALTERNATIVES_PATH]: {
    title: "story-editor.inspector.alternatives",
    inspectorName: "alternativesInspector"
  },
  [STORY_EDITOR_ALTERNATIVES_IMAGE_PATH]: {
    title: "story-editor.inspector.choose-image",
    inspectorName: "imageGalleryWithEditInspector",
    variant: "wide",
    navigationButton: NavigationButton.Back
  },
  [STORY_EDITOR_ALTERNATIVES_IMAGE_EDIT_PATH]: {
    title: "story-editor.inspector.edit-image",
    actionButton: "story-editor.inspector.choose",
    navigationButton: NavigationButton.Back,
    inspectorName: "imageEditInspector",
    variant: "wide"
  },
  [STORY_EDITOR_CARD_SHARE]: {
    title: "story-editor.inspector.card-settings",
    inspectorName: "cardSettingsInspector",
    selectedTab: "share"
  },
  [STORY_EDITOR_CARD_ATTRIBUTES]: {
    title: "story-editor.inspector.card-settings",
    inspectorName: "cardSettingsInspector",
    selectedTab: "attributes"
  },
  [STORY_EDITOR_CARD_SHARE_IMAGE_PATH]: {
    title: "story-editor.inspector.choose-image",
    inspectorName: "cardImageGalleryInspector",
    variant: "wide"
  },
  [STORY_EDITOR_POLL_EDIT]: {
    title: "story-editor.inspector.edit-poll",
    actionButton: "story-editor.inspector.save",
    inspectorName: "opinionPollInspector"
  },
  [STORY_EDITOR_IMAGE_GALLERY_ELEMENT_GALLERY_PATH]: {
    title: "story-editor.inspector.choose-image",
    actionButton: "story-editor.inspector.choose",
    navigationButton: NavigationButton.CloseMultiMediaGallery,
    inspectorName: "imageGalleryMultiSelect",
    variant: "wide",
    level: 0
  },
  [STORY_EDITOR_IMAGE_GALLERY_ELEMENT_EDIT_PATH]: {
    title: "story-editor.inspector.edit-image",
    actionButton: "story-editor.inspector.choose",
    navigationButton: NavigationButton.Back,
    inspectorName: "imageEditInspector",
    variant: "wide"
  },
  [STORY_EDITOR_STORY_SEARCH_PATH]: {
    title: "story-editor.inspector.import-card",
    inspectorName: "storySearchInspector"
  },
  [STORY_EDITOR_IMPORT_PATH]: {
    title: "story-editor.inspector.back-to-results",
    navigationButton: NavigationButton.Back,
    inspectorName: "storyImportInspector"
  },
  [STORY_EDITOR_PLAGIARISM_REPORT]: {
    title: "story-editor.inspector.plagiarism-report",
    inspectorName: "storyPlagiarismReport"
  },
  [STORY_EDITOR_NOTES_PATH]: {
    title: "story-editor.inspector.editor-notes",
    inspectorName: "editorNotes"
  },
  [STORY_EDITOR_OEMBED_VIDEO_SELECTOR_PATH]: {
    title: "story-editor.inspector.oembed-video-selector",
    navigationButton: NavigationButton.Close,
    inspectorName: "oEmbedVideoSelector",
    variant: "default",
    level: 0
  }
};

function generateInspectorData(data: Partial<InspectorData>): InspectorData {
  return {
    title: "",
    active: true,
    navigationButton: NavigationButton.Close,
    inspectorName: "",
    variant: "default",
    params: {},
    ...data
  };
}

export default function generateInspectorDataByRoute(routePath: string, params: {}): InspectorData {
  let inspectorData = inspectorDataByPath[routePath];
  inspectorData = inspectorData
    ? {
        ...inspectorData,
        ...(inspectorData.title && { title: t(inspectorData.title) }),
        ...(inspectorData.actionButton && { actionButton: t(inspectorData.actionButton) })
      }
    : { active: false };
  return generateInspectorData({ ...inspectorData, params });
}
