/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import Spinner from "components/spinner/spinner";

const Loader = ({ component: Component, className = "", children, loader, ...rest }) => {
  const { loading } = loader;
  if (loading) {
    return <Spinner message={t("spinner.loading")} />;
  }
  return null;
};

export default Loader;
