/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export type StoryViewsResponse = {
  [key: string]: {
    count: number;
    delta: number;
  };
};

export const fetchPageViews = (storyIds: string): Promise<StoryViewsResponse> => {
  return client
    .url("/api/qlitics/stories/views")
    .query({ "story-content-ids": storyIds })
    .get()
    .json();
};
