/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import GeneralIntegration from "./components/general-integration";
import Wru from "./components/wru";
import Polltype from "./components/polltype";
import Mandrill from "./components/mandrill";
import Copyscape from "./components/copyscape";
import Brightcove from "./components/brightcove";
import OneSignal from "./components/one-signal";
import Fastly from "./components/fastly";
import Moengage from "./components/moengage";
import Fcm from "./components/fcm";
import { IntegrationConfig, Integrations } from "api/integrations";
import ApiKeyIntegration from "./components/api-key-integration";
import Vidible from "./components/vidible";
import GenericIntegration from "./components/generic-integration";
import DefaultProviders from "./components/default-providers/default-providers";
import Ga3Realtime from "./components/ga3-realtime";
import GoogleIndexing from "./components/google-indexing";
import Ga4Reporting from "./components/ga4-reporting";
import Izooto from "./components/izooto";

export function mapIntegrationToComponent(
  integration: IntegrationConfig,
  readOnly: boolean,
  updateIntegrationField: (key: string, changes: Object) => void,
  errors: Error | null,
  validateIntegration?: (selectedIntegration: IntegrationConfig) => void,
  isValidateInProgress?: boolean
) {
  let commonProps = { readOnly, updateIntegrationField, errors };
  switch (integration.key) {
    case Integrations.Brightcove:
      return <Brightcove {...commonProps} integration={integration} />;
    case Integrations.Mandrill:
      return <Mandrill {...commonProps} integration={integration} />;
    case Integrations.Wru:
      return <Wru {...commonProps} integration={integration} />;
    case Integrations.Polltype:
      return <Polltype {...commonProps} integration={integration} />;
    case Integrations.GoogleMaps:
    case Integrations.PushEngage:
      return <ApiKeyIntegration {...commonProps} integration={integration} />;
    case Integrations.Moengage:
      return <Moengage {...commonProps} integration={integration} />;
    case Integrations.Fastly:
      return <Fastly {...commonProps} integration={integration} />;
    case Integrations.Copyscape:
      return <Copyscape {...commonProps} integration={integration} />;
    case Integrations.OneSignal:
      return <OneSignal {...commonProps} integration={integration} />;
    case Integrations.Facebook:
    case Integrations.Twitter:
    case Integrations.Linkedin:
    case Integrations.Getty:
    case Integrations.Metype:
      return <GeneralIntegration {...commonProps} integration={integration} />;
    case Integrations.Fcm:
      return <Fcm {...commonProps} integration={integration} />;
    case Integrations.Vidible:
      return <Vidible {...commonProps} integration={integration} />;
    case Integrations.DefaultProviders:
      return <DefaultProviders {...commonProps} integration={integration} />;
    case Integrations.GoogleAnalytics3Realtime:
      return (
        <Ga3Realtime
          {...commonProps}
          integration={integration}
          validateIntegration={validateIntegration}
          isValidateInProgress={isValidateInProgress}
        />
      );
    case Integrations.GoogleIndexing:
      return (
        <GoogleIndexing
          {...commonProps}
          integration={integration}
          validateIntegration={validateIntegration}
          isValidateInProgress={isValidateInProgress}
        />
      );
    case Integrations.GoogleAnalytics4Reporting:
      return (
        <Ga4Reporting
          {...commonProps}
          integration={integration}
          validateIntegration={validateIntegration}
          isValidateInProgress={isValidateInProgress}
        />
      );
    case Integrations.Izooto:
      return <Izooto {...commonProps} integration={integration} />;
    default:
      return <GenericIntegration {...commonProps} integration={integration} />;
  }
}
