/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Sidebar from "components/sidebar/sidebar";
import { compose } from "redux";
import { connect } from "react-redux";
import Menu from "components/menu/menu";
import { PartialAppState } from "../initial-state";
import { match } from "react-router";
import { USERS_PATH } from "../pages/users-and-roles/users/routes";
import { ROLES_PATH } from "../pages/users-and-roles/roles/routes";
import GoBack from "components/go-back/go-back";
import { t } from "i18n";

interface MenuType {
  label: string;
  value: string;
  path: string;
}

interface StateProps {
  enableAdminLinksInSidebar?: boolean;
}

type Props = StateProps & {
  isBannerPresent: boolean;
  setActiveSidebar: (type: string) => void;
  match: match;
};

class UsersAndRolesSidebar extends React.Component<Props> {
  render() {
    const { setActiveSidebar, isBannerPresent, enableAdminLinksInSidebar, match } = this.props;
    const usersAndRolesMenu = enableAdminLinksInSidebar
      ? [
          { label: t("users-and-roles.users"), value: "users", path: USERS_PATH },
          { label: t("users-and-roles.roles"), value: "roles", path: ROLES_PATH }
        ]
      : [{ label: t("users-and-roles.users"), value: "users", path: USERS_PATH }];

    return (
      <Sidebar isBannerPresent={isBannerPresent}>
        <GoBack clickAction={() => setActiveSidebar("settings")} message={t("navbar.settings")} />
        <Menu
          items={usersAndRolesMenu}
          getItemLabel={(item: MenuType) => item.label}
          routeTo={(item) => item.path}
          isItemSelected={(item) => item.path === match.path}
          title={t("users-and-roles.title")}
        />
      </Sidebar>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    enableAdminLinksInSidebar: state.features.enableAdminLinksInSidebar
  };
};

export default compose(connect(mapStateToProps))(UsersAndRolesSidebar);

export { UsersAndRolesSidebar };
