/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface StrikeThroughIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const StrikeThrough: React.SFC<StrikeThroughIconProps> = ({ width = "24", height = "24", color = "currentColor" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 24 24">
    <path d="M5 11h13.8v1.8H5z" />
    <path
      fillRule="nonzero"
      d="M12.24 21.088c-1.248 0-2.444-.2-3.588-.6-1.144-.4-2.028-.928-2.652-1.584l.696-1.368c.608.608 1.416 1.1 2.424 1.476a8.857 8.857 0 0 0 3.12.564c1.504 0 2.632-.276 3.384-.828.752-.552 1.128-1.268 1.128-2.148 0-.672-.204-1.208-.612-1.608a4.131 4.131 0 0 0-1.5-.924c-.592-.216-1.416-.452-2.472-.708-1.264-.32-2.272-.628-3.024-.924a4.965 4.965 0 0 1-1.932-1.356c-.536-.608-.804-1.432-.804-2.472 0-.848.224-1.62.672-2.316.448-.696 1.136-1.252 2.064-1.668C10.072 4.208 11.224 4 12.6 4c.96 0 1.9.132 2.82.396.92.264 1.716.628 2.388 1.092l-.6 1.416a8.258 8.258 0 0 0-2.256-1.044 8.42 8.42 0 0 0-2.352-.348c-1.472 0-2.58.284-3.324.852-.744.568-1.116 1.3-1.116 2.196 0 .672.204 1.212.612 1.62.408.408.92.72 1.536.936.616.216 1.444.452 2.484.708 1.232.304 2.228.604 2.988.9.76.296 1.404.744 1.932 1.344.528.6.792 1.412.792 2.436 0 .848-.228 1.62-.684 2.316-.456.696-1.156 1.248-2.1 1.656-.944.408-2.104.612-3.48.612z"
    />
  </svg>
);

export default StrikeThrough;
