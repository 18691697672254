/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import ReactTable, { RowInfo } from "react-table";
import { t } from "i18n";
import { ThunkDispatch } from "redux-thunk";

import wrapPage from "containers/page/page";
import { fetchUsersData, searchUsersData, getUsersByPageAction, editUserProfile } from "./action-creators";
import SearchBar from "components/search-bar/search-bar";
import ContentHeader from "../../../components/content-header/content-header";
import { USERS_EDIT_PATH } from "./routes";
import { navigateFn } from "utils/routes.utils";
import TablePagination from "components/table-pagination/table-pagination";
import Spinner from "components/spinner/spinner";
import { Member, Role } from "api/users";
import { UsersPagination, PartialAppState } from "./state";
import styles from "./users.module.css";
import Button from "components/button/button";

interface StateProps {
  currentPage: number;
  searchTerm: string;
  isLoading: boolean;
  users: Member[];
  usersPagination: UsersPagination;
  usersPerPage: number;
  title: string;
  hasAdminPermission?: boolean;
  canAddAuthor?: boolean;
}

interface DispatchProps {
  loadUsersData: () => void;
  openAddUserProfile: () => void;
  setSearchTerm: (searchTerm: string) => void;
  getUsersByPage: (searchTerm: string, value: number) => void;
  editUserProfile: (user: Member, hasAdminPermission: boolean) => void;
}

type Props = StateProps & DispatchProps & { isBannerPresent: boolean };

class Users extends React.Component<Props> {
  componentDidMount() {
    this.props.loadUsersData();
  }
  render() {
    const {
      currentPage,
      searchTerm,
      openAddUserProfile,
      setSearchTerm,
      getUsersByPage,
      usersPagination,
      usersPerPage,
      users,
      editUserProfile,
      isLoading,
      hasAdminPermission,
      canAddAuthor
    } = this.props;
    return (
      <main>
        <ContentHeader title={t("users-and-roles.users")}>
          <div className={styles["user-add-buttons"]} data-test-id="user-add-buttons">
            {(canAddAuthor || hasAdminPermission) && (
              <Button
                type="primary"
                onClick={() => openAddUserProfile()}
                classname="user-add-buttons-button"
                testId="add-user-button">
                {t("users-and-roles.add_user")}
              </Button>
            )}
          </div>
        </ContentHeader>

        <div data-test-id="users-table-container" className={styles["users-table-container"]}>
          <div className={styles["users-table-pagination-top"]}>
            <div className={styles["users-searchbar"]}>
              <SearchBar
                placeholder={t("users-and-roles.search_user_placeholder")}
                value={searchTerm}
                onChange={(searchTerm) => setSearchTerm(searchTerm)}
              />
            </div>
            <TablePagination
              specifiedPage={currentPage}
              onPageChange={(pageNumber) => getUsersByPage(searchTerm, pageNumber)}
              totalCount={usersPagination.total}
              perPage={usersPerPage}
            />
          </div>
          <ReactTable
            showPaginationTop={false}
            showPaginationBottom={false}
            showPagination={false}
            showPageSizeOptions={false}
            defaultPageSize={1}
            pageSize={users.length > usersPerPage ? usersPerPage : users.length}
            sortable={false}
            data={users}
            columns={[
              {
                Header: "",
                accessor: "avatar-url",
                Cell: (row) => (
                  <div className={styles["users-table-avatar-container"]}>
                    {row.value && <img className={styles["users-table-avatar-image"]} src={row.value} alt={row.name} />}
                  </div>
                ),
                maxWidth: 50
              },
              {
                Header: t("users-and-roles.table.name"),
                accessor: "name",
                maxWidth: 250
              },
              {
                Header: t("users-and-roles.table.roles"),
                accessor: "roles",
                Cell: (row) => (
                  <div className={styles["users-table-roles"]}>
                    {row.value &&
                      row.value.map((role: Role) => (
                        <React.Fragment key={role.id}>
                          <span>{role.name}</span>
                          <span className={styles["users-table-roles-separator"]}>, </span>
                        </React.Fragment>
                      ))}
                  </div>
                )
              },
              {
                Header: t("users-and-roles.table.email"),
                accessor: "email",
                maxWidth: 250
              }
            ]}
            onPageChange={(pageNumber) => getUsersByPage(searchTerm, pageNumber)}
            pages={Math.ceil(usersPagination.total / usersPerPage)}
            getTrProps={(state: any, rowInfo: RowInfo) => ({
              onClick: () => {
                editUserProfile(rowInfo.original, hasAdminPermission!);
              }
            })}
            className="users-table"
            LoadingComponent={() =>
              isLoading ? (
                <div className={styles["users-table-loading"]}>
                  <Spinner classname={"users-spinner"} />
                </div>
              ) : null
            }
          />
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  const usersPage = state.usersAndRoles.usersPage;
  return {
    users: usersPage.users,
    usersPagination: usersPage.usersPagination,
    usersPerPage: usersPage.usersPerPage,
    currentPage: usersPage.ui.currentPage,
    isLoading: usersPage.ui.main.loading,
    searchTerm: usersPage.ui.searchTerm,
    title: "users",
    hasAdminPermission: state.features.hasAdminPermission,
    canAddAuthor: state.features.canAddAuthor
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, any, AnyAction>): DispatchProps => {
  const navigate = navigateFn(dispatch);
  return {
    loadUsersData: () => dispatch(fetchUsersData()),
    setSearchTerm: (searchTerm: string) => dispatch(searchUsersData(searchTerm)),
    openAddUserProfile: () => navigate(USERS_EDIT_PATH, { id: "new" }),
    editUserProfile: (user, hasAdminPermission) => dispatch(editUserProfile(user, hasAdminPermission)),
    getUsersByPage: (searchTerm, pageNumber) => dispatch(getUsersByPageAction(searchTerm, pageNumber))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), wrapPage())(Users);

export { Users };
