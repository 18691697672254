/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import Badge, { BadgeValue } from "components/badge/badge";
import Tooltip from "components/tooltip/tooltip";
import styles from "./tabs.module.css";

export interface Tab {
  value: string;
  label: string;
  badge?: BadgeValue;
  totalCount?: string;
}

const cx = classnames.bind(styles);

interface Props {
  tabs: Array<Tab>;
  value: string;
  className?: string;
  onChange: (value: string) => void;
}

const ScrollTabList: React.SFC<Props> = ({ tabs, value, onChange, className }) => {
  return (
    <ul role="tablist" className={cx("tab-list", className)} data-test-id="tab-list">
      {tabs.map((tab) => {
        const isActive = tab.value === value;
        const classes = cx("tab-list-item", {
          "is-active": isActive
        });
        return (
          <li
            role="tab"
            className={classes}
            key={`tab-${tab.value}`}
            onClick={() => onChange(tab.value)}
            data-test-id="tab-list-item">
            {tab.label}
            {tab.hasOwnProperty("badge") && (
              <Badge
                value={tab.badge}
                muted={!isActive}
                classname="tab-list-item-badge"
                data-test-id="tab-badge"
                dataFor={tab.value}>
                {tab.totalCount && (
                  <Tooltip
                    id={tab.value}
                    place="bottom"
                    effect="solid"
                    value={tab.totalCount}
                    classname={"scroll-tab-tool-tip"}
                  />
                )}
              </Badge>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default ScrollTabList;
