/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import styles from "./settings-container.module.css";

interface Props {
  children: Array<React.ReactNode> | React.ReactNode;
}

const SettingsContainer: React.FC<Props> = ({ children }) => {
  return <div className={styles["settings-container"]}>{children}</div>;
};

export default SettingsContainer;
