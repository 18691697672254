/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export type LoaderError = Error;

export type LoaderState = {
  loading: boolean;
  error: LoaderError | null;
};

export const INITIAL_LOADER_STATE: LoaderState = { loading: true, error: null };
