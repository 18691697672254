/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import ImagePreviewWithActions from "../image-preview-with-actions/image-preview-with-actions";
import ImageDetails from "../image-details/image-details";

const SingleImage: React.FC<any> = ({
  selectedImages,
  deleteMedia,
  updateImageData,
  isUploading,
  error,
  showFields = true,
  isLinkAuthorEnabled,
  enableEXIFLocation,
  isStoryImageHyperlinkEnabled
}) => {
  const image = get(selectedImages, ["0"], {});
  const shouldShowImageDetails = showFields && !isUploading && !isEmpty(image);
  const imageUploadFailed = image && image.success === false;
  const isEditable = image && image.key;

  return (
    <React.Fragment>
      <ImagePreviewWithActions
        image={image}
        imageIndex={0}
        isEditable={isEditable}
        deleteMedia={deleteMedia}
        isUploading={isUploading}
        updateImageData={updateImageData}
        imageUploadFailed={imageUploadFailed}
      />
      {shouldShowImageDetails && (
        <ImageDetails
          image={image}
          imageIndex={0}
          isEditable={isEditable}
          updateImageData={updateImageData}
          imageUploadFailed={imageUploadFailed}
          isLinkAuthorEnabled={isLinkAuthorEnabled}
          enableEXIFLocation={enableEXIFLocation}
          error={error}
          isStoryImageHyperlinkEnabled={isStoryImageHyperlinkEnabled}
        />
      )}
    </React.Fragment>
  );
};

export default SingleImage;
