/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, {
  LoadTaskAction,
  LoadAllTasksAction,
  LoadTaskSuccessAction,
  LoadAllTasksFailureAction,
  CloseTaskAction,
  CloseTaskSuccessAction,
  CloseTaskFailureAction,
  SetTaskImageAction,
  DeleteTaskImageAction,
  UpdateImageDataAction,
  UpdateImageUploadStatusAction,
  SaveImageValidationErrorsAction,
  TaskManagerLoginAction,
  LoadAllTasksSuccessAction,
  ShowInspectorAction,
  CancelLoadAllTasksAction,
  ClearSelectedMediaAction
} from "./actions";
import { Task, Image, MyTasks } from "api/tasks";
import { ImageError } from "./state";

export function loadTaskAction(): LoadTaskAction {
  return { type: actions.LOAD_TASK };
}

export function loadAllTasksAction(): LoadAllTasksAction {
  return { type: actions.LOAD_ALL_TASKS };
}
export function cancelLoadAllTasksAction(): CancelLoadAllTasksAction {
  return { type: actions.CANCEL_LOAD_ALL_TASKS };
}

export function loadAllTasksFailureAction(error: Error): LoadAllTasksFailureAction {
  return { type: actions.LOAD_ALL_TASKS_FAILURE, payload: error };
}

export function closeTaskAction(): CloseTaskAction {
  return { type: actions.CLOSE_TASK };
}

export function taskManagerLoginAction(): TaskManagerLoginAction {
  return { type: actions.TASK_MANAGER_LOGIN };
}

export function loadTaskSuccessAction(task: Task): LoadTaskSuccessAction {
  return { type: actions.LOAD_TASK_SUCCESS, payload: task };
}

export function loadAllTasksSuccessAction(tasks: MyTasks): LoadAllTasksSuccessAction {
  return { type: actions.LOAD_ALL_TASKS_SUCCESS, payload: tasks };
}

export function closeTaskSuccessAction(task: Task): CloseTaskSuccessAction {
  return { type: actions.CLOSE_TASK_SUCCESS, payload: task };
}

export function closeTaskFailureAction(): CloseTaskFailureAction {
  return { type: actions.CLOSE_TASK_FAILURE };
}

export function setTaskImageAction(images: Image[]): SetTaskImageAction {
  return { type: actions.SET_TASK_IMAGE, payload: images };
}

export function showInspectorAction(showInspector: boolean): ShowInspectorAction {
  return { type: actions.SHOW_INSPECTOR, payload: showInspector };
}

export function deleteTaskImageAction(): DeleteTaskImageAction {
  return { type: actions.DELETE_TASK_IMAGE };
}

export function updateImageUploadStatusAction(status: boolean): UpdateImageUploadStatusAction {
  return { type: actions.UPDATE_IMAGE_UPLOAD_STATUS, payload: status };
}

export function clearSelectedMediaAction(): ClearSelectedMediaAction {
  return { type: actions.CLEAR_SELECTED_MEDIA };
}

export function updateImageDataAction(
  key: String,
  value: string | number | null,
  imageIndex: number
): UpdateImageDataAction {
  return {
    type: actions.UPDATE_IMAGE_DATA,
    payload: {
      key,
      value,
      imageIndex
    }
  };
}

export function saveImageValidationFailureAction(error: ImageError[]): SaveImageValidationErrorsAction {
  return { type: actions.SAVE_IMAGE_VALIDATION_ERRORS, payload: error };
}
