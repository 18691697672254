/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { NodePosition } from "../../find";
import { EditorState, Transaction } from "prosemirror-state";
import { setTextSelectionToDocumentEnd } from "../../selection";
import { Node } from "prosemirror-model";
import { schema } from "pages/story-editor/prosemirror/schema";
import { newStoryElementNode } from "pages/story-editor/operations/nodes";
import { ChildStoryElement, CompositeStoryElement, StoryElement } from "api/story";
import { Direction } from "pages/story-editor/operations/card/move";

export function moveStoryElementToPosition(
  transaction: Transaction,
  editorState: EditorState,
  direction: Direction,
  elementNP: NodePosition,
  insertPosition: number,
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement
): Transaction {
  if (!elementNP) {
    return transaction;
  }
  const elementNode = elementNP.node;
  const deleteFrom = elementNP.pos;
  const deleteTo = elementNP.pos + elementNode.nodeSize;
  const newNode = Node.fromJSON(schema, newStoryElementNode(storyElement));

  if (direction === Direction.UP) {
    transaction.delete(deleteFrom, deleteTo);
    transaction.insert(insertPosition, newNode);
  } else {
    transaction.insert(insertPosition, newNode);
    transaction.delete(deleteFrom, deleteTo);
  }

  return setTextSelectionToDocumentEnd(transaction, editorState);
}
