/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useState } from "react";
import Carousel from "nuka-carousel";
import { t } from "i18n";

import { Image } from "api/search-media-image";
import { ImageError } from "../../state";
import ImagePreviewWithActions from "../image-preview-with-actions/image-preview-with-actions";
import ImageDetails from "../image-details/image-details";
import Chevron from "components/icons/chevron";
import classnames from "classnames/bind";
import styles from "./multiple-image.module.css";

const cx = classnames.bind(styles);

interface MultipleImageProps {
  isUploading: boolean;
  selectedImages: Image[] | null;
  updateImageData: (key: string, value: string | number | null, index: number) => void;
  deleteMedia: (imageIndex: number) => void;
  isLinkAuthorEnabled: boolean;
  errors?: ImageError[];
  enableEXIFLocation?: boolean;
  isStoryImageHyperlinkEnabled: boolean;
  fieldSpecs?: any;
}

const MultipleImage: React.SFC<MultipleImageProps> = ({
  isUploading,
  selectedImages,
  updateImageData,
  isLinkAuthorEnabled,
  errors,
  deleteMedia,
  enableEXIFLocation,
  isStoryImageHyperlinkEnabled
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const deleteHandler = (index) => {
    deleteMedia(index);
    if (selectedImages && selectedImages.length === currentSlide + 1) {
      setCurrentSlide(currentSlide - 1);
    }
  };
  return (
    <div className={styles["image-preview-carousel"]} data-test-id="image-preview-carousel">
      <Carousel
        slideIndex={currentSlide}
        renderCenterLeftControls={({ previousSlide, currentSlide }) => (
          <div
            data-test-id="image-arrow-left-btn"
            onClick={() => setCurrentSlide(currentSlide - 1)}
            className={cx("image-arrow-left", `${currentSlide === 0 ? "hide-arrow" : ""}`)}>
            <Chevron width={24} height={24} />
          </div>
        )}
        renderCenterRightControls={({ nextSlide, currentSlide }) => (
          <div
            data-test-id="image-arrow-right-btn"
            onClick={() => setCurrentSlide(currentSlide + 1)}
            className={cx(
              "image-arrow-right",
              `${selectedImages ? (currentSlide === selectedImages.length - 1 ? "hide-arrow" : "") : ""}`
            )}>
            <Chevron width={24} height={24} />
          </div>
        )}
        renderTopRightControls={({ currentSlide }) => {
          return (
            <span data-test-id="image-selected-info" className={styles["image-selected-info"]}>
              {currentSlide + 1}/{selectedImages && selectedImages.length}
            </span>
          );
        }}
        renderTopLeftControls={() => (
          <span data-test-id="image-upload-info" className={styles["image-upload-info"]}>
            {selectedImages && selectedImages.filter((image: Image) => image.success === true).length}{" "}
            {t("mediaLibrary.images_uploaded")}
          </span>
        )}>
        {selectedImages &&
          selectedImages.map((image: Image, index: number) => (
            <div key={`image-${image["temp-image-key"]}`} className={cx("image-preview")}>
              <ImagePreviewWithActions
                isUploading={isUploading}
                image={image}
                imageIndex={index}
                updateImageData={updateImageData}
                key={`image-${image["temp-image-key"]}`}
                deleteMedia={deleteHandler}
                imageUploadFailed={image.success === false}
              />
            </div>
          ))}
      </Carousel>
      {selectedImages && selectedImages[currentSlide] && (
        <ImageDetails
          image={selectedImages[currentSlide]}
          imageIndex={currentSlide}
          updateImageData={updateImageData}
          key={`image-details-${selectedImages[currentSlide]["temp-image-key"]}`}
          imageUploadFailed={selectedImages[currentSlide].success === false}
          isLinkAuthorEnabled={isLinkAuthorEnabled}
          error={errors && errors.find((error: ImageError) => error.id === currentSlide)}
          enableEXIFLocation={enableEXIFLocation}
          isStoryImageHyperlinkEnabled={isStoryImageHyperlinkEnabled}
        />
      )}
    </div>
  );
};

export { MultipleImage };
