/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";

import { saveAmpConfig } from "../async-action-creators";
import Button from "components/button/button";
import Header from "components/header/header";
import { PartialAppState } from "../state";
import { AmpConfig } from "api/amp-config";

interface StateProps {
  ampConfig: AmpConfig | null;
  isAmpConfigModified: boolean;
}

interface DispatchProps {
  saveAmpConfig: (ampConfig: AmpConfig) => void;
}

type Props = StateProps & DispatchProps;

class AmpHeader extends React.Component<Props> {
  render() {
    const { saveAmpConfig, ampConfig, isAmpConfigModified } = this.props;
    return (
      <React.Fragment>
        <Header>
          {ampConfig && isAmpConfigModified && (
            <Button variant="success" type="primary" onClick={() => saveAmpConfig(ampConfig)}>
              {t("save")}
            </Button>
          )}
        </Header>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    ampConfig: state.ampConfig.ampConfig,
    isAmpConfigModified: state.ampConfig.ui.isAmpConfigModified
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    saveAmpConfig: (ampConfig) => dispatch(saveAmpConfig(ampConfig))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AmpHeader);

export { AmpHeader };
