/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import NumberField from "components/number-field/number-field";
import UpdateManage from "pages/collections/components/update-manage/update-manage";

import styles from "./story-manage.module.css";
import { t } from "i18n";
import { StoryWrapper, AssociatedMetadata } from "api/story-collection";
import { Attribute } from "api/story-attributes";
import { flatten, isRange, convertRange } from "utils";
import Select from "components/select/select";

interface Props {
  item: StoryWrapper;
  updatePageViewsThreshold: (index: number) => void;
  changePageViewsThreshold: (pageViews: number) => void;
  index: number;
  updateStagingAssociatedMetadata: (name: string, val: string | number | boolean) => void;
  stagingAssociatedMetadata: AssociatedMetadata;
  updateAssociatedMetadata: (index: number, key: string, associatedMetadata: AssociatedMetadata) => void;
  attributes: Attribute[];
  cancelCardExpanded: (index: number, key: string, value: any) => void;
  threshold: number | null;
}

const getAttributeValues = (attribute: Attribute) => {
  const options = attribute.values;
  return flatten(
    options.map((option) => {
      return isRange(option) ? convertRange(option) : option;
    })
  );
};

const getCollectionAttributes = (allAttributes: Attribute[]) => {
  return allAttributes.filter((attribute: Attribute) => attribute.type === "collection");
};

const getThresholdValue = (item: StoryWrapper, threshold: number | null) => {
  const value = (threshold === undefined ? item.threshold : threshold) || 0;
  return value;
};
const StoryManage: React.SFC<Props> = ({
  item,
  updatePageViewsThreshold,
  changePageViewsThreshold,
  index,
  cancelCardExpanded,
  threshold,
  updateAssociatedMetadata,
  stagingAssociatedMetadata,
  updateStagingAssociatedMetadata,
  attributes
}) => {
  return (
    <div className={styles["collection-manage"]}>
      <div className={styles["set-page-views"]}>
        <NumberField
          value={getThresholdValue(item, threshold)}
          label={t("collections.set_page_views")}
          onChange={(value) => changePageViewsThreshold(Number(value))}
          placeholder={t("collections.enter_a_number")}
        />
      </div>

      {attributes &&
        getCollectionAttributes(attributes).map(
          (attribute: Attribute) =>
            attribute.values.length !== 0 && (
              <Select
                options={getAttributeValues(attribute)}
                key={attribute.name}
                value={stagingAssociatedMetadata && stagingAssociatedMetadata[`${attribute.name}`]}
                onChange={(val: string) => updateStagingAssociatedMetadata(attribute.name, val)}
                isMulti={true}
                label={attribute["display-name"]}
                getOptionLabel={(attribute: any) => attribute}
                getOptionValue={(attribute: any) => attribute}
              />
            )
        )}
      <UpdateManage
        item={item}
        updatePageViewsThreshold={updatePageViewsThreshold}
        index={index}
        cancelCardExpanded={cancelCardExpanded}
        threshold={threshold}
        stagingAssociatedMetadata={stagingAssociatedMetadata}
        updateAssociatedMetadata={updateAssociatedMetadata}
      />
    </div>
  );
};

export default StoryManage;
