/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import produce from "immer";
import actions, { ActionTypes } from "./actions";
import { INITIAL_SOCIAL_SHARE_STATE, InspectorType, SocialShareState } from "./state";

export default function socialShareReducer(state: SocialShareState = INITIAL_SOCIAL_SHARE_STATE, action: ActionTypes) {
  return produce(state, (draft) => {
    switch (action.type) {
      case actions.LIST_SOCIAL_ACCOUNTS_REQUEST:
        draft.ui.main = { loading: true, error: null };
        break;

      case actions.LIST_SOCIAL_ACCOUNTS_SUCCESS:
        draft.socialAccounts = action.payload.socialAccounts;
        draft.ui.main = { loading: false, error: null };
        break;
      case actions.LIST_SOCIAL_ACCOUNTS_FAILURE:
        draft.ui.main = { loading: false, error: action.payload.error };
        break;
      case actions.ADD_NEW_CONNECTION:
        draft.ui.inspector = InspectorType.Add;
        break;

      case actions.CANCEL_NEW_CONNECTION:
        draft.ui.inspector = null;
        break;

      case actions.SET_SELECTED_SERVICE:
        draft.ui.selectedService = action.payload.selectedService;
        break;

      default:
        break;
    }
  });
}
