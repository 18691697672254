/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import AsyncSelect from "components/select/async";
import pDebounce from "p-debounce";
import { listPersonEntities } from "api/entity";
import Text from "../text/text";

const debouncedListPersonEntities = pDebounce(
  (name) => listPersonEntities(name).then((response) => response["entities"]),
  250
);

const onChange = (storyElement, value, changefn) => {
  const keyChanged = storyElement.subtype === "question" ? "interviewer" : "interviewee";
  const changes = { ...storyElement, metadata: { ...storyElement.metadata, [keyChanged]: value } };
  changefn(storyElement.id, changes);
};

const Conversation = (props) => {
  return (
    <React.Fragment>
      <Text {...props} />
      <AsyncSelect
        placeholder={props.storyElement.subtype === "question" ? "Question Attribution" : "Answer Attribution"}
        value={
          props.storyElement.subtype === "question"
            ? props.storyElement.metadata.interviewer
            : props.storyElement.metadata.interviewee
        }
        loadOptions={debouncedListPersonEntities}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        onChange={(value) => onChange(props.storyElement, value, props.onChange)}
        hideSelectedOptions={true}
        menuPlacement={props.isLastElement ? "top" : "bottom"}
        variant="editor"
        components={{ DropdownIndicator: null }}
        isDisabled={props.isDisabled}
      />
    </React.Fragment>
  );
};

export default Conversation;
