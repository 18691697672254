/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { createSelector } from "@reduxjs/toolkit";
import { normalize } from "utils";
import { pick } from "lodash";
import { State } from "store/feature/feature";

const selectBySlug = (_: any, slug: string) => slug;
const selectBySlugs = (_: any, slugs: string[]) => slugs;
const selectFeatures = (state: State) => state.features;

export const selectAllFeatures = createSelector([selectFeatures], (features) => Object.values(features));
export const selectAllFeaturesNormalizedBySlug = createSelector([selectAllFeatures], (featuresArr) =>
  normalize(featuresArr, undefined, "slug")
);

export const selectFeatureBySlug = createSelector(
  [selectAllFeaturesNormalizedBySlug, selectBySlug],
  (features, slug) => features[slug]
);

export const selectFeaturesBySlug = createSelector(
  [selectAllFeaturesNormalizedBySlug, selectBySlugs],
  (features, slugs) => pick(features, [...slugs])
);
