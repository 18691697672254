/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { batch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { isEmpty, has, isEqual } from "lodash";
import { t } from "i18n";
import { diffObjects } from "utils";
import { navigate } from "utils/routes.utils";
import * as api from "api/forms";
import { FORMS_EDITOR_PATH_EDIT as formEditorPath, FORMS_PATH as formsDashboardPath } from "pages/forms/routes";
import { getValidationErrors } from "pages/forms/errors";
import { notificationSuccess, notificationError, notificationInfo } from "containers/page/actions";
import { ViewportState } from "store/viewport";
import { EmailListType, FeatureState } from "store/route-data";
import { showGenericErrorPage, showNotFoundPage } from "store/error-page";
import { ExportState, Form, Format } from "store/form/form";
import { FormsConfig } from "api/route-data/route-data";

export type CurrentForm = Partial<Form>;

export interface PartialAppState {
  config: {
    forms: FormsConfig;
  };
  forms: {
    editor: FormEditorState;
  };
  features: FeatureState;
  viewport: ViewportState;
}

export interface FormActionState {
  export: ExportState;
}

export interface FormEditorState {
  isLoading: boolean;
  isFormModified: boolean;
  isFormSaving: boolean;
  isFormPublishing: boolean;
  currentForm: CurrentForm;
  initialForm: CurrentForm;
  errors: FormValidationErrors;
  formActionState: FormActionState;
}

export interface FormValidationErrors {
  headline?: string;
  subheadline?: string;
  slug?: string;
}

enum APIError {
  InvalidSlug = "invalid-slug",
  NotFound = "not-found",
  Unknown = "unknown"
}

export const initialForm = {
  headline: "",
  subheadline: "",
  mailingList: [],
  formioData: {
    display: "form",
    components: []
  }
};

export const initialState: FormEditorState = {
  currentForm: initialForm,
  initialForm,
  isLoading: true,
  isFormModified: false,
  isFormSaving: false,
  isFormPublishing: false,
  errors: {},
  formActionState: { export: { isExportingCSV: false, isExportingJSON: false } }
};

const { reducer, actions } = createSlice({
  name: "form-editor",
  initialState,
  reducers: {
    setCurrentForm: (state: FormEditorState, action: PayloadAction<CurrentForm>) => {
      state.currentForm = { ...state.currentForm, ...action.payload };
    },
    setInitialForm: (state: FormEditorState, action: PayloadAction<CurrentForm>) => {
      state.initialForm = { ...state.initialForm, ...action.payload };
    },
    resetCurrentForm: (state: FormEditorState) => {
      state.currentForm = initialForm;
    },
    setIsLoading: (state: FormEditorState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsFormModified: (state: FormEditorState, action: PayloadAction<boolean>) => {
      state.isFormModified = action.payload;
    },
    setIsFormSaving: (state: FormEditorState, action: PayloadAction<boolean>) => {
      state.isFormSaving = action.payload;
    },
    setIsFormPublishing: (state: FormEditorState, action: PayloadAction<boolean>) => {
      state.isFormPublishing = action.payload;
    },
    setIsExportingJSON: (state: FormEditorState, action: PayloadAction<boolean>) => {
      state.formActionState.export = { ...state.formActionState.export, isExportingJSON: action.payload };
    },
    setIsExportingCSV: (state: FormEditorState, action: PayloadAction<boolean>) => {
      state.formActionState.export = { ...state.formActionState.export, isExportingCSV: action.payload };
    },
    setErrors: (state: FormEditorState, action: PayloadAction<FormValidationErrors>) => {
      state.errors = action.payload;
    },
    clearErrors: (state: FormEditorState) => {
      state.errors = {};
    },
    setMailingList: (state: FormEditorState, action: PayloadAction<EmailListType>) => {
      state.currentForm.mailingList = action.payload;
    }
  }
});

export const {
  setCurrentForm,
  setInitialForm,
  resetCurrentForm,
  setIsLoading,
  setIsFormModified,
  setIsFormSaving,
  setIsFormPublishing,
  setIsExportingJSON,
  setIsExportingCSV,
  setErrors,
  clearErrors,
  setMailingList
} = actions;

/* TODO Refactor this when we have error codes. Also type 'e' */
function getAPIError(e: any): APIError | null {
  try {
    const {
      error: { message }
    } = JSON.parse(e.text);
    if (e.status === 422 && message === "invalid-slug") {
      return APIError.InvalidSlug;
    } else if (e.status === 404) {
      return APIError.NotFound;
    }
    return null;
  } catch (e) {
    return APIError.Unknown;
  }
}

export function fetchForm(id: number) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
    dispatch(setIsLoading(true));
    try {
      const form = await api.getForm(id);
      batch(() => {
        dispatch(setCurrentForm(form));
        dispatch(setInitialForm(form));
        dispatch(setIsLoading(false));
      });
    } catch (e) {
      dispatch(setIsLoading(false));
      const error = getAPIError(e);
      if (error === APIError.NotFound) dispatch(showNotFoundPage());
      else dispatch(showGenericErrorPage());
    }
  };
}

export function saveForm() {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>, getState: () => PartialAppState) => {
    dispatch(setIsFormSaving(true));
    dispatch(clearErrors());
    const { currentForm, initialForm } = getState().forms.editor;
    const { headline, subheadline } = currentForm;
    const errors = getValidationErrors({ headline, subheadline });
    const diffedForm = diffObjects(initialForm, currentForm);
    // If only mailing list is changed, skip status update as "draft"
    let statusUpdateAsDraft = true;
    if (isEqual(Object.keys(diffedForm), ["mailingList"])) {
      statusUpdateAsDraft = false;
    }
    try {
      if (isEmpty(errors)) {
        const createdOrUpdatedForm = currentForm.id
          ? await api.update(currentForm.id, { ...diffedForm, ...(statusUpdateAsDraft && { status: "draft" }) })
          : await api.create(currentForm);
        batch(() => {
          dispatch(setIsFormSaving(false));
          dispatch(setIsFormModified(false));
          dispatch(navigate(formEditorPath, { formId: createdOrUpdatedForm.id }));
          dispatch(setCurrentForm(createdOrUpdatedForm));
          dispatch(setInitialForm(createdOrUpdatedForm));
        });
      } else {
        batch(() => {
          dispatch(setIsFormSaving(false));
          dispatch(setErrors(errors));
        });
      }
    } catch (e) {
      dispatch(setIsFormSaving(false));
      const error = getAPIError(e);
      if (error === APIError.InvalidSlug) {
        const slugErrorMessage = has(diffedForm, "slug")
          ? t("forms.editor.errors.invalid-input-slug")
          : t("forms.editor.errors.invalid-autogenerated-slug");
        dispatch(setErrors({ slug: slugErrorMessage }));
      } else dispatch(notificationError(t("forms.editor.messages.save-fail")));
    }
  };
}

export function publishForm() {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>, getState: () => PartialAppState) => {
    dispatch(setIsFormPublishing(true));
    const isFormModified = getState().forms.editor.isFormModified;
    isFormModified && (await dispatch(saveForm()));
    const formId = getState().forms.editor.currentForm.id;
    const errors = getState().forms.editor.errors;
    try {
      if (formId && isEmpty(errors)) {
        await api.update(formId, { status: "published" });
        dispatch(navigate(formsDashboardPath));
        dispatch(notificationSuccess(t("forms.editor.messages.publish-success")));
      }
      dispatch(setIsFormPublishing(false));
    } catch (e) {
      dispatch(setIsFormPublishing(false));
      dispatch(notificationError(t("forms.editor.messages.publish-fail")));
    }
  };
}

export function retractForm() {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>, getState: () => PartialAppState) => {
    const formId = getState().forms.editor.currentForm.id;
    try {
      const retractedForm = await api.update(formId!, { status: "draft" });
      dispatch(setCurrentForm(retractedForm));
      dispatch(notificationSuccess(t("forms.messages.retract-success")));
    } catch (e) {
      dispatch(notificationError(t("forms.messages.retract-fail")));
    }
  };
}

export function deleteForm() {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>, getState: () => PartialAppState) => {
    const formId = getState().forms.editor.currentForm.id;
    try {
      await api.deleteForm(formId!);
      dispatch(notificationSuccess(t("forms.messages.delete-success")));
      dispatch(navigate(formsDashboardPath));
    } catch (e) {
      dispatch(notificationError(t("forms.messages.delete-fail")));
    }
  };
}

export function exportSubmissions(format: Format) {
  return async (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>, getState: () => PartialAppState) => {
    const id = getState().forms.editor.currentForm.id!;
    const setIsExporting =
      format === Format.JSON
        ? (isExporting) => dispatch(setIsExportingJSON(isExporting))
        : (isExporting) => dispatch(setIsExportingCSV(isExporting));
    try {
      dispatch(notificationInfo(t("forms.messages.export-triggered")));
      setIsExporting(true);
      await api.exportSubmissions(id, format);
      setIsExporting(false);
      dispatch(notificationSuccess(t("forms.messages.export-success")));
    } catch (e) {
      setIsExporting(false);
      dispatch(notificationError(t("forms.messages.export-fail")));
    }
  };
}

export default reducer;
