/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import EditUserProfile from "pages/settings/pages/users-and-roles/users/edit-user/profile";

const Profile = (props) => <EditUserProfile {...props} canEditRoles={false} />;

export default Profile;
