/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "api/client";
import { StoryId } from "./primitive-types";
import { SocialCard } from "./social";

export interface SocialAccountsResponse {
  "social-accounts": SocialAccount[];
  "social-cards"?: SocialCard[];
}

export interface SocialAccount {
  id: number;
  "publisher-id": number;
  "account-id": string;
  "account-name": string;
  provider: string;
  metadata: Metadata;
  "is-active": boolean;
  "updated-at": number;
}

export interface Metadata {
  "parent-account"?: ParentAccount;
  "access-token"?: string;
  "avatar-url"?: string;
  email?: string;
  category?: string;
  perms?: string[];
}

export interface ParentAccount {
  uid: string;
  name: string;
}

export interface SocialAccountParams {
  "story-id"?: StoryId;
  needed?: string[];
}

export const getSocialAccounts = (params?: SocialAccountParams): Promise<SocialAccountsResponse> => {
  return client
    .url(`/api/social`)
    .json(params ? params : { needed: ["social-accounts"] })
    .post()
    .json();
};

export const deleteSocialAccount = (accountId: string) => {
  return client
    .url(`/api/social/account/${accountId}`)
    .delete()
    .res()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
};

export const toggleSocialAccount = (account: SocialAccount) => {
  return client
    .url(`/api/social/account/toggle`)
    .json({ account })
    .post()
    .res()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
};
