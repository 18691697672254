/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Image } from "api/search-media-image";
import { ImageError } from "../../state";
import SingleImage from "../single-image/single-image";
import { MultipleImage } from "../multiple-image/multiple-image";
import styles from "./image-upload.module.css";

interface ImageUploadProps {
  multipleSelected?: any;
  updateError?: any;
  isUploading: boolean;
  selectedImages: Image[] | null;
  updateImageData: (key: string, value: string | number | null, index: number) => void;
  isLinkAuthorEnabled: boolean;
  errors?: ImageError[];
  enableEXIFLocation?: boolean;
  setSelectedMedia?: any;
  onDeleteMedia?: any;
  showFields?: any;
  isStoryImageHyperlinkEnabled: boolean;
  fieldSpecs?: any;
}
class ImageUpload extends React.Component<ImageUploadProps, any> {
  updateMedia = (imageIndex) => {
    const updatedSelectedMedia =
      this.props.selectedImages && this.props.selectedImages.filter((item, index) => index !== imageIndex);
    this.props.setSelectedMedia(updatedSelectedMedia);
  };

  deleteMedia = (imageIndex) => {
    if (this.props.selectedImages && this.props.selectedImages.length <= 1) {
      this.props.onDeleteMedia();
    } else {
      this.updateMedia(imageIndex);
    }
  };

  render() {
    const { selectedImages, errors } = this.props;
    return (
      <div className={styles["image-upload-and-edit-container"]} data-test-id="image-upload-and-edit-container">
        {selectedImages && selectedImages.length > 1 ? (
          <MultipleImage {...this.props} errors={errors} deleteMedia={this.deleteMedia} />
        ) : (
          <SingleImage
            {...this.props}
            error={errors && errors.find((error) => error.id === 0)}
            deleteMedia={this.deleteMedia}
          />
        )}
      </div>
    );
  }
}

export default ImageUpload;
