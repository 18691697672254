/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AnyAction } from "redux";
import { connect } from "react-redux";
import * as React from "react";
import { ThunkDispatch } from "redux-thunk";

import BaseInspector from "components/inspector/inspector";
import TextField from "components/text-field/text-field";

import { t } from "i18n";
import { AnyContributorRole, PartialAppState, UnsavedContributorRole } from "./state";
import { ContributorRole } from "api/contributor-roles";
import {
  updateCurrentContributorRole,
  cancelSaveContributorRole,
  saveContributorRole,
  updateContributorRole,
  cancelUpdateContributorRole
} from "./async-action-creators";

interface StateProps {
  nameFieldErrorMessage: string;
}

interface DispatchProps {
  cancelSave: () => void;
  saveContributorRole: (contributorRole: UnsavedContributorRole) => void;
  cancelUpdate: () => void;
  updateCurrentContributorRole: (contributorRole: AnyContributorRole) => void;
  updateContributorRole: (contributorRole: ContributorRole) => void;
}

type Props = StateProps &
  DispatchProps & {
    isActive: boolean;
    currentContributorRole: AnyContributorRole;
  };

const Inspector: React.SFC<Props> = ({
  cancelSave,
  currentContributorRole,
  isActive,
  updateCurrentContributorRole,
  saveContributorRole,
  updateContributorRole,
  nameFieldErrorMessage,
  cancelUpdate
}) => {
  return (
    <BaseInspector
      title={
        currentContributorRole.id
          ? t("settings.contributor-roles.inspector-edit-title")
          : t("settings.contributor-roles.inspector-new-title")
      }
      onClose={currentContributorRole.id ? cancelUpdate : cancelSave}
      isActive={isActive}
      actionButtonLabel={t("save")}
      onActionButtonClick={() => {
        currentContributorRole.id
          ? updateContributorRole({ id: currentContributorRole.id, name: currentContributorRole.name })
          : saveContributorRole(currentContributorRole);
      }}
      isActionButtonDisabled={!currentContributorRole.name}>
      <TextField
        label={t("settings.contributor-roles.inspector-name-field-title")}
        placeholder={t("settings.contributor-roles.inspector-name-field-placeholder")}
        onChange={(name) => {
          updateCurrentContributorRole({ ...currentContributorRole, name });
        }}
        value={currentContributorRole.name}
        errorMessage={nameFieldErrorMessage}
      />
    </BaseInspector>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    nameFieldErrorMessage:
      state.contributorRoles.ui.errors["name-field-duplicate"] &&
      t("settings.contributor-roles.messages.name-field-duplicate")
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    updateCurrentContributorRole: (contributorRole) => {
      dispatch(updateCurrentContributorRole(contributorRole));
    },
    saveContributorRole: (contributorRole) => {
      dispatch(saveContributorRole(contributorRole));
    },
    cancelSave: () => {
      dispatch(cancelSaveContributorRole());
    },
    updateContributorRole: (contributorRole) => {
      dispatch(updateContributorRole(contributorRole));
    },
    cancelUpdate: () => {
      dispatch(cancelUpdateContributorRole());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Inspector);

export { Inspector };
