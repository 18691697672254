/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import { BadgeValue } from "components/badge/badge";

import StoryManage from "pages/collections/components/story-manage/story-manage";

import { StoryWrapper, AssociatedMetadata, ContentType } from "api/story-collection";
import { Attribute } from "api/story-attributes";
import { route } from "utils/routes.utils";
import { STORY_EDITOR_PATH } from "pages/story-editor/routes";
import { flatten } from "utils";
import { EDIT_BREAKING_NEWS } from "pages/breaking-news/routes";
import { PageViewFields } from "pages/collections/initial-state";
import TypeCardComponent, { CollectionItemLocation } from "../type-card/type-card";
import styles from "./story-type-card.module.css";

const cx = classnames.bind(styles);

export interface Props {
  item: StoryWrapper;
  isSelected: boolean;
  isDragging?: boolean;
  badge: BadgeValue;
  toggleCollectionItem: (item: StoryWrapper) => void;
  type: CollectionItemLocation;
  isCardExpanded: boolean;
  toggleCardExpanded: (index: number, item: StoryWrapper) => void;
  updatePageViewsThreshold: (index: number) => void;
  updateStagingAssociatedMetadata: (name: string, val: string | number | boolean) => void;
  stagingAssociatedMetadata: AssociatedMetadata;
  updateAssociatedMetadata: (index: number, key: string, associatedMetadata: AssociatedMetadata) => void;
  changePageViewsThreshold: (pageViews: number) => void;
  index: number;
  cancelCardExpanded: (index: number, key: string, value: any) => void;
  pageViews: PageViewFields | null | undefined;
  threshold: number | null;
  attributes: Attribute[];
}

const TypeCard = TypeCardComponent<StoryWrapper>();

const StoryTypeCard: React.SFC<Props> = ({
  item,
  pageViews,
  isSelected,
  isDragging,
  badge,
  toggleCollectionItem,
  type,
  changePageViewsThreshold,
  updatePageViewsThreshold,
  index,
  isCardExpanded,
  toggleCardExpanded,
  cancelCardExpanded,
  updateStagingAssociatedMetadata,
  stagingAssociatedMetadata,
  updateAssociatedMetadata,
  attributes,
  threshold
}) => {
  const cardItem = item.story || item["breaking-news"];
  const associatedMetadata = item["associated-metadata"];
  const headlineClassname = cx("collection-headline", {
    "threshold-reached":
      (type === "sorted" && item["disabled-at"]) ||
      (type === "sorted" && item.threshold && pageViews && pageViews.count && pageViews.count >= item.threshold)
  });
  const href =
    item.type === ContentType.BreakingNews
      ? route(EDIT_BREAKING_NEWS, { id: cardItem.id })
      : route(STORY_EDITOR_PATH, { id: cardItem.id });
  const selectedAttributes = associatedMetadata && flatten(Object.values(associatedMetadata));

  const renderManageComponent = () => {
    return (
      <StoryManage
        item={item}
        changePageViewsThreshold={changePageViewsThreshold}
        updatePageViewsThreshold={updatePageViewsThreshold}
        index={index}
        updateStagingAssociatedMetadata={updateStagingAssociatedMetadata}
        stagingAssociatedMetadata={stagingAssociatedMetadata}
        updateAssociatedMetadata={updateAssociatedMetadata}
        attributes={attributes}
        cancelCardExpanded={cancelCardExpanded}
        threshold={threshold}
      />
    );
  };

  return (
    <TypeCard
      item={item}
      cardItem={cardItem}
      isSelected={isSelected}
      isDragging={isDragging}
      badge={badge}
      template={cardItem["story-template"] || "text"}
      toggleCollectionItem={toggleCollectionItem}
      type={type}
      isCardExpanded={isCardExpanded}
      href={href}
      headline={cardItem.headline}
      headlineClassname={headlineClassname}
      pageViews={pageViews}
      selectedAttributes={selectedAttributes}
      onClickManageButton={() => toggleCardExpanded(index, item)}
      renderManageComponent={renderManageComponent}
      showStar
      showAnalytics
      showAuthors
      showPublic
      showStatus
      showSyndicated
      showManage={type === "sorted" && item.type !== ContentType.BreakingNews}
    />
  );
};

export default StoryTypeCard;
