/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import styles from "./dropzone.module.css";
import classnames from "classnames/bind";
import { MimeType, validateFileType } from "utils/file.utils";

/*
  Usage:
  * 'position: relative' CSS is required to be set on which ever component the dropzone needs to show overlay on.
     This was not added to the dropzone container since the area that the dropzone overlay encompasses should be
     customizable. For example: The dropzone can be active only on, say, component A but the overlay will cover component B
     which houses component A if 'position: relative' is set on component B.
  * Props
    * children: The dropzone will be active on this React node.
    * dropHandler: The function that will handle whatever has to be done with dropped files.
    * dragEnterMessage: The JSX element that will to be shown on drag enter. It is recommended to use the DropzoneMessage
      component for this.
    * displayError: The function that will handle displaying the error when there is one. Usually a function dispatching a
      redux action.
    * accept: Same format as the HTML accept attribute. Refer https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/accept.
    * maxFileSizeInKB: The maximum allowed file size in KB.
    * errorMapping: Contains a mapping of the error messages that will be passed as an argument to the displayError function
      when there is an error.
    * enableMultipleUploads: To enable uploading multiple files. False by default.
    * disable: If the dropzone needs to be disabled. False by default.
    * showOnDragAnywhere: When true it will display the overlay and dropzone message when the file is dragged anywhere
      on the screen.
*/

const cx = classnames.bind(styles);

export interface DropzoneErrorMapping {
  fileType?: string | null;
  fileSize?: string | null;
  fileCount?: string | null;
}

interface DropzoneProps {
  children: React.ReactNode;
  dropHandler: (files: File[]) => void;
  dragEnterMessage: JSX.Element;
  onError?: (error: string) => void;
  accept?: MimeType | string;
  maxFileSizeInKB?: number;
  errorMapping?: DropzoneErrorMapping;
  enableMultipleUploads?: boolean;
  disable?: boolean;
  showOnDragAnywhere?: boolean;
  classname?: string;
}

interface DropzoneOwnState {
  showOverlay: boolean;
}

/*
 TODO Architect Google analytics usage for non-click events
 TODO Solution dropzone to use pointer-events:none instead of
      the current dragCount implementation. On superficial investigation
      the pointer-events route did not work when the document event listeners
      are turned on by the showOnDragAnywhere prop.
 */
export class Dropzone extends React.Component<DropzoneProps, DropzoneOwnState> {
  dragCount: number;
  constructor(props: DropzoneProps) {
    super(props);
    this.state = {
      showOverlay: false
    };
    this.dragCount = 0;
  }

  componentDidMount() {
    this.dragCount = 0;
    if (this.props.showOnDragAnywhere) {
      document.addEventListener("dragenter", this.showOverlay);
      document.addEventListener("dragleave", this.removeOverlay);
      document.addEventListener("drop", this.removeOverlay);
    }
  }

  componentWillUnmount() {
    if (this.props.showOnDragAnywhere) {
      document.removeEventListener("dragenter", this.showOverlay);
      document.removeEventListener("dragleave", this.removeOverlay);
      document.removeEventListener("drop", this.removeOverlay);
    }
  }

  showOverlay = (e: DragEvent) => {
    e.preventDefault();

    this.dragCount++;
    if (e.dataTransfer && e.dataTransfer.types.includes("Files"))
      !this.state.showOverlay && this.setState({ showOverlay: true });
  };

  removeOverlay = (e: DragEvent) => {
    e.preventDefault();

    this.dragCount--;
    if (this.dragCount > 0) return;
    this.state.showOverlay && this.setState({ showOverlay: false });
  };

  handleDragEnter = (e: React.DragEvent) => {
    e.stopPropagation();
    this.showOverlay(e.nativeEvent);
  };

  handleDragLeave = (e: React.DragEvent) => {
    e.stopPropagation();
    this.removeOverlay(e.nativeEvent);
  };

  handleDragOver = (e: React.DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  handleDrop = (e: React.DragEvent) => {
    e.stopPropagation();

    const {
      accept,
      dropHandler,
      onError,
      enableMultipleUploads,
      maxFileSizeInKB: maxFileSize,
      errorMapping
    } = this.props;

    const files = Array.from(e.dataTransfer.files);
    const { fileType: fileTypeError = null, fileCount: fileCountError = null, fileSize: fileSizeError = null } =
      errorMapping || {};
    let error;

    if (accept && !validateFileType(files, accept)) {
      error = fileTypeError;
    } else if (!enableMultipleUploads && files.length > 1) {
      error = fileCountError;
    } else if (maxFileSize && files.some((file) => file.size > maxFileSize * 1024)) {
      error = fileSizeError;
    } else {
      dropHandler(files);
    }

    error && onError && onError(error);
    this.removeOverlay(e.nativeEvent);
  };

  render() {
    const { children, dragEnterMessage, disable, classname } = this.props;
    const { showOverlay } = this.state;

    return disable ? (
      children
    ) : (
      <div
        className={cx("dropzone-container", classname)}
        onDropCapture={this.handleDrop}
        onDragEnterCapture={this.handleDragEnter}
        onDragLeaveCapture={this.handleDragLeave}
        onDragOverCapture={this.handleDragOver}
        data-test-id="dropzone-container">
        {children}
        {showOverlay && (
          <div className={styles["dropzone-overlay"]} data-test-id="dropzone-overlay">
            {dragEnterMessage}
          </div>
        )}
      </div>
    );
  }
}

export default Dropzone;
