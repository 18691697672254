/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useState, createRef, useCallback } from "react";
import { connect } from "react-redux";
import Spinner from "components/spinner/spinner";
import {
  applyFilterAction,
  resetFilterAction,
  toggleCollectionItemAction,
  searchCollectionItemsAction
} from "../../async-action-creators";
import { actions } from "../../actions";
import Lane from "components/lane/lane";
import CollectionItemList from "pages/collections/components/collection-item-list/collection-item-list";
import SearchBar from "components/search-bar/search-bar";
import Filters from "pages/collections/components/filters/filters";
import Filter from "components/icons/filter";
import { Switch } from "components/switch/switch";
import { ScrollTabList, Panel } from "components/tabs/tabs";
import { t } from "i18n";
import { useIntersectionObserver, useOnLoad } from "custom-hooks/intersection-observer";

import { PartialAppState, FilterInput, LHSTab, SearchResults, PageViews } from "pages/collections/initial-state";
import { ThunkDispatch } from "redux-thunk";
import { CollectionItemWrapper, DataSource, AnyCollection } from "api/story-collection";
import { Section } from "api/route-data/route-data";
import { CollectionTemplateOptions } from "api/template-options";
import { Attribute } from "api/story-attributes";
import styles from "./all-items.module.css";
import Sync from "components/icons/sync";
import { useCompare } from "custom-hooks/previous";

interface StateProps {
  isAllItemsFiltersExpanded: boolean;
  filters: FilterInput;
  selectedCollection: AnyCollection;
  sections: Section[];
  selectedLaneTab: LHSTab;
  searchResults: SearchResults;
  templateOptions: CollectionTemplateOptions;
  isStoryScheduleWithCollectionEnabled: boolean;
  attributes: Attribute[];
  hasCollectionRankNestedCollectionPermission: boolean;
  storiesPageViews: PageViews | null;
  isRefreshingLHS: boolean;
}

interface DispatchProps {
  toggleAdvancedSearch: () => void;
  toggleCollectionItem: (item: CollectionItemWrapper) => void;
  applyFilter: () => void;
  updateFilter: (key: string, value: any) => void;
  resetFilter: () => void;
  searchCollectionItems: (offset?: number, isScheduledTab?: boolean, showNotification?: boolean) => void;
}

interface OwnProps {
  switchLaneTab: (tab: LHSTab) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

const createRefreshButton = (isRefreshingLHS: boolean, handleRefresh: () => void) => {
  return (
    <button
      disabled={isRefreshingLHS}
      data-test-id="collection-content-refresh-btn"
      className={styles["collection-content-refresh"]}
      onClick={handleRefresh}>
      <Sync width={"24px"} height={"24px"} color={isRefreshingLHS ? "var(--mono-3)" : "var(--brand-3)"} />
    </button>
  );
};

const opts = {
  rootMargin: "0px 0px 1600px"
};

const AllItems: React.SFC<Props> = ({
  isAllItemsFiltersExpanded,
  filters,
  updateFilter,
  selectedCollection,
  toggleAdvancedSearch,
  sections,
  applyFilter,
  selectedLaneTab,
  switchLaneTab,
  toggleCollectionItem,
  searchResults,
  resetFilter,
  searchCollectionItems,
  templateOptions,
  isStoryScheduleWithCollectionEnabled,
  attributes,
  hasCollectionRankNestedCollectionPermission,
  storiesPageViews,
  isRefreshingLHS
}) => {
  const [showScheduledStories, toggleShowScheduled] = useState(false);

  const [loadingMore, setLoadingMore] = useState(false);

  let tabState =
    showScheduledStories && selectedLaneTab === "story" ? searchResults["draft-story"] : searchResults[selectedLaneTab];

  const isTabStateChanged = useCompare(tabState.items.length);

  if (isTabStateChanged) {
    loadingMore && setLoadingMore(false);
  }
  const collectionLaneRef = createRef<HTMLDivElement>();

  const itemsRemainingToLoad = tabState.items.length < tabState.hits;

  const onIntersecting = useCallback(
    (entry) => {
      if (tabState.items.length > 0 && itemsRemainingToLoad) {
        setLoadingMore(true);
        searchCollectionItems(tabState.items.length, selectedLaneTab === "story" && showScheduledStories);
      }
    },
    [tabState, selectedLaneTab, showScheduledStories, searchCollectionItems, itemsRemainingToLoad]
  );
  const onLoad = useOnLoad(!loadingMore, onIntersecting);

  useIntersectionObserver<HTMLDivElement>(collectionLaneRef, onLoad, opts);

  return (
    <div className={styles["collection-lane"]}>
      <Lane
        title={t("collections.content_list")}
        attributes={() =>
          createRefreshButton(isRefreshingLHS, () => {
            searchCollectionItems(0, searchCollectionItems && showScheduledStories, true);
          })
        }>
        <div className={styles["collection-filters-actions"]}>
          <div className={styles["collection-search-actions"]}>
            <div className={styles["collection-search-bar"]} data-test-id="collection-search-bar">
              <SearchBar
                value={filters.term}
                onChange={(value: string) => {
                  updateFilter("term", value);
                  searchCollectionItems(0, searchCollectionItems && showScheduledStories);
                }}
                placeholder={t("collections.search_placeholder")}
                hasBorder={true}
              />
            </div>
            {selectedCollection["data-source"] !== DataSource.Automated && (
              <div
                className={styles["collections-toggle-advanced-search"]}
                data-test-id="collections-toggle-advanced-search-btn"
                onClick={toggleAdvancedSearch}>
                <Filter />
              </div>
            )}
          </div>
          <Filters
            filters={filters}
            updateFilter={updateFilter}
            showFilters={isAllItemsFiltersExpanded}
            sections={sections}
            applyFilter={applyFilter}
            resetFilter={resetFilter}
            storyAttributes={attributes.filter((attribute) => attribute.type === "story")}
          />
        </div>
        <div className={styles["collection-content-list-tabs"]} data-test-id="collection-content-list-tabs">
          <ScrollTabList
            tabs={
              selectedCollection["template"] === "series" && selectedCollection["data-source"] === "automated"
                ? [
                    {
                      label: t("collections.stories"),
                      value: "story",
                      badge: showScheduledStories ? searchResults["draft-story"].hits : searchResults["story"].hits
                    }
                  ]
                : [
                    {
                      label: t("collections.stories"),
                      value: "story",
                      badge: showScheduledStories ? searchResults["draft-story"].hits : searchResults["story"].hits
                    },
                    {
                      label: t("collections.collections"),
                      value: "collection",
                      badge: searchResults["collection"].hits
                    }
                  ]
            }
            value={selectedLaneTab}
            onChange={(tab: LHSTab) => switchLaneTab(tab)}
          />
          <Panel>
            {isStoryScheduleWithCollectionEnabled &&
              selectedLaneTab === "story" &&
              selectedCollection["template"] !== "breaking-news" && (
                <div
                  className={styles["collection-content-switch-container"]}
                  data-test-id="collection-content-switch-container">
                  <span
                    className={styles["collection-content-switch-label"]}
                    data-test-id="collection-content-switch-label">
                    {t("collections.show_scheduled_stories")}
                  </span>
                  <Switch
                    data-test-id="switch-id"
                    id="switch-id"
                    onChange={() => toggleShowScheduled(!showScheduledStories)}
                    checked={showScheduledStories}
                  />
                </div>
              )}
            <CollectionItemList
              items={tabState.items}
              storiesPageViews={storiesPageViews}
              selectedCollectionItems={selectedCollection.items}
              toggleCollectionItem={toggleCollectionItem}
              templateOptions={templateOptions}
              attributes={attributes}
              hasCollectionRankNestedCollectionPermission={hasCollectionRankNestedCollectionPermission}
            />
            <div ref={collectionLaneRef}>
              {itemsRemainingToLoad && <Spinner message={t("collections.loading-items")} />}
            </div>
          </Panel>
        </div>
      </Lane>
    </div>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    isAllItemsFiltersExpanded: state.collections.ui.isAllItemsFiltersExpanded,
    filters: state.collections.collectionPage.filters,
    sections: state.config.sections,
    selectedLaneTab: state.collections.ui.selectedLaneTab,
    searchResults: state.collections.collectionPage.searchResults,
    selectedCollection: state.collections.collectionPage.selectedCollection!,
    templateOptions: state.collections.collectionPage.templateOptions,
    isStoryScheduleWithCollectionEnabled: state.features.isStoryScheduleWithCollectionEnabled,
    attributes: state.config.storyAttributes,
    hasCollectionRankNestedCollectionPermission: state.features.hasCollectionRankNestedCollectionPermission,
    storiesPageViews: state.collections.storiesPageViews,
    isRefreshingLHS: state.collections.ui.isRefreshingLHS
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, any, any>): DispatchProps => {
  return {
    toggleCollectionItem: (item) => dispatch(toggleCollectionItemAction(item)),
    updateFilter: (key, value) => dispatch({ type: actions.UPDATE_FILTER, payload: { key, value } }),
    toggleAdvancedSearch: () => dispatch({ type: actions.TOGGLE_ADVANCED_SEARCH }),
    applyFilter: () => dispatch(applyFilterAction()),
    resetFilter: () => dispatch(resetFilterAction()),
    searchCollectionItems: (offset: number, isScheduledTab: boolean, showNotification: boolean) =>
      dispatch(searchCollectionItemsAction(offset, isScheduledTab, showNotification))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllItems);

export { AllItems };
