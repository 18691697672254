/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

type NotificationStatus = "success" | "error" | "info";

export interface NotificationMessage {
  status: NotificationStatus;
  messageCode: string;
}

export function createNotification(status: NotificationStatus, messageCode: string): NotificationMessage {
  return {
    status,
    messageCode
  };
}
