/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import TextField from "components/text-field/text-field";
import { t } from "i18n";
import { isClientValidationError } from "utils/validation.utils";
import { get } from "lodash";
import styles from "./forgot-password.module.css";

interface Props {
  setEmailToResetPassword: (value: string) => void;
  emailToResetPassword: string;
  error: Error;
}

interface ValidateEmail {
  email: string;
}

const ForgotPassword: React.SFC<Props> = ({ emailToResetPassword, error, setEmailToResetPassword }) => {
  return (
    <React.Fragment>
      <p className={styles["forgot-password-info"]}>{t("loginPage.messages.forgot_password_info")}</p>
      <TextField
        label={t("loginPage.email")}
        value={emailToResetPassword}
        onChange={(value) => setEmailToResetPassword(value)}
        errorMessage={isClientValidationError<ValidateEmail>(error) ? get(error, ["errors", "email", "0"]) : undefined}
      />
    </React.Fragment>
  );
};

export default ForgotPassword;
