/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Select from "components/select/select";
import _isEmpty from "lodash/isEmpty";
import { t } from "i18n";
import FieldLabel from "components/field-label/field-label";
import styles from "./source-filter.module.css";

const SourceFilter = ({ sources, value, onChange }) => {
  const categories =
    value && value.source && sources.length ? sources.find((source) => source.source === value.source).categories : [];

  return (
    <div className={styles["filter-source"]}>
      <FieldLabel label={t("workspace.filters_inspector.source")} />
      <FieldLabel label={t("workspace.filters_inspector.category")} />
      <Select
        value={!_isEmpty(value) && value}
        options={sources}
        getOptionLabel={(source) => t(`workspace.filter.sources.${source.source}`, source.source) || source.label}
        getOptionValue={(source) => source.source}
        onChange={(source) => onChange({ ...source, categories: [] })}
      />
      <Select
        options={categories}
        isMulti={true}
        onChange={(categories) => onChange({ ...value, categories })}
        value={value && value.categories}
        getOptionLabel={(category) => category}
        getOptionValue={(category) => category}
        isDisabled={!value || !categories.length}
      />
    </div>
  );
};
export default SourceFilter;
