/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { createSlice } from "@reduxjs/toolkit";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { getSitemapConfigData, saveSitemapConfigData } from "api/sitemap";
import { t } from "i18n";
import { notificationSuccess } from "containers/page/actions";
import { notificationError } from "action-creators/notification";

export interface SitemapState {
  loading: boolean;
  failure: boolean;
  sitemap: SitemapConfig | undefined;
  isSitemapConfigModified: boolean;
}

export const INITIAL_STATE: SitemapState = {
  loading: true,
  sitemap: undefined,
  failure: false,
  isSitemapConfigModified: false
};

export interface SitemapConfig {
  "filter-stories-by-days"?: number;
}

interface Payload<T> {
  payload: T;
  type: string;
}

const { reducer, actions } = createSlice({
  initialState: INITIAL_STATE,
  name: "sitemap",
  reducers: {
    loadSitemap: (state: SitemapState) => {
      state.loading = true;
    },
    loadSitemapSuccess: (state: SitemapState, sitemap: Payload<SitemapConfig>) => {
      state.loading = false;
      state.sitemap = sitemap.payload;
    },
    loadSitemapFailure: (state: SitemapState) => {
      state.loading = false;
      state.failure = true;
    },
    setSitemapConfig: (state: SitemapState, sitemap: Payload<SitemapConfig>) => {
      state.sitemap = sitemap.payload;
      state.isSitemapConfigModified = true;
    },
    saveSitemapSuccess: (state: SitemapState) => {
      state.isSitemapConfigModified = false;
    }
  }
});

export const { loadSitemap, loadSitemapSuccess, loadSitemapFailure, setSitemapConfig, saveSitemapSuccess } = actions;

export function fetchSitemapConfig() {
  return async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    dispatch(loadSitemap());
    try {
      dispatch(loadSitemapSuccess(await getSitemapConfigData()));
    } catch {
      dispatch(loadSitemapFailure());
    }
  };
}

export function saveSitemapConfig(sitemapConfig: SitemapConfig) {
  return async (dispatch: ThunkDispatch<any, any, AnyAction>) => {
    try {
      await saveSitemapConfigData(sitemapConfig);
      dispatch(saveSitemapSuccess());
      dispatch(notificationSuccess(t("sitemap.save_success")));
    } catch (e) {
      dispatch(notificationError(t("sitemap.save_failed")));
    }
  };
}

export default reducer;
