/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import produce from "immer";
import { INITIAL_ROLES_STATE, RolesPageInspectorType, NEW_ROLE, State } from "./state";
import actions, { ActionTypes } from "./actions";
import { CategoryName } from "api/roles";

export default function rolesReducer(state: State = INITIAL_ROLES_STATE, action: ActionTypes) {
  return produce(state, (draft) => {
    switch (action.type) {
      case actions.LIST_ROLES:
        draft.ui.main = { loading: true, error: null };
        break;

      case actions.LIST_ROLES_SUCCESS:
        draft.roles = action.payload.roles;
        draft.ui.main = { loading: false, error: null };
        draft.ui.inspector = null;
        break;

      case actions.LIST_ROLES_FAILURE:
        draft.ui.main = { loading: false, error: action.payload.error };
        break;

      case actions.CANCEL_SAVE_ROLES_AND_CLEAR_CURRENT_ROLE_FROM_INSPECTOR:
        draft.ui.inspector = null;
        draft.ui.saveErrors = null;
        draft.ui.selectedActionCategoryTab = CategoryName.Content;
        draft.app.currentRole = null;
        break;

      case actions.EDIT_ROLE:
        draft.app.currentRole = action.payload.selectedRole;
        draft.ui.inspector = RolesPageInspectorType.Edit;
        break;

      case actions.SWITCH_ACTION_CATEGORY_TAB:
        draft.ui.selectedActionCategoryTab = action.payload.selectedTab;
        break;

      case actions.UPDATE_ROLE:
        draft.app.currentRole = action.payload.updatedRole;
        break;

      case actions.SAVE_ROLE_SUCCESS:
        draft.ui.inspector = null;
        break;

      case actions.ADD_NEW_ROLE:
        draft.app.currentRole = NEW_ROLE;
        draft.ui.inspector = RolesPageInspectorType.Create;
        break;

      case actions.SAVE_ROLE_FAILURE:
        draft.ui.saveErrors = action.payload.errors;
        break;

      case actions.DELETE_ROLE_REQUEST:
        draft.ui.main = { loading: true, error: null };
        break;
      case actions.DELETE_ROLE_FAILURE:
        draft.ui.main = { loading: false, error: null };
        break;

      default:
        break;
    }
  });
}
