/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as routes from "./routes";
import { t } from "i18n";
import { INTEGRATIONS } from "./pages/integrations/routes";
import { SOCIAL_ACCOUNTS_PATH } from "./pages/social-share/routes";
import { WEBHOOKS_PATH } from "./pages/webhooks/routes";

const menuItems = ({ enableAdminLinksInSidebar, canAddAuthor }) => {
  let menu: any = [];

  if (canAddAuthor || enableAdminLinksInSidebar) {
    menu = [
      ...menu,
      {
        label: t("users-and-roles.title"),
        value: "users-and-roles",
        path: ""
      }
    ];
  }

  if (enableAdminLinksInSidebar) {
    menu = [
      { label: t("story-editor.inspector.general"), value: "general", path: routes.SETTINGS_GENERAL_PATH },
      {
        label: t("social_share.title"),
        value: "social-sharing",
        path: SOCIAL_ACCOUNTS_PATH
      },
      {
        label: t("integrations.title"),
        value: "integrations",
        path: INTEGRATIONS
      },
      {
        label: t("meta_title.webhooks"),
        value: "webhooks",
        path: WEBHOOKS_PATH
      },
      ...menu, //Did not want to change the order
      {
        label: t("settings.configure.title"),
        value: "configure",
        path: ""
      }
    ];
  }

  return menu;
};

export default menuItems;
