/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import { PartialAppState } from "../../../../initial-state";

import Button from "components/button/button";
import Header from "components/header/header";
import { saveContentAccessConfig } from "../../action-creator";
import { Configuration } from "api/select-features";

interface StateProps {
  accessLevel: Configuration | null;
  noOfVisibleCards: number;
  isAccessLevelConfigModified: boolean;
}

interface DispatchProps {
  saveConfig: (noOfVisibleCards: number, accessLevel: Configuration) => void;
}

type Props = StateProps & DispatchProps;

class AccessLevelHeader extends React.Component<Props> {
  render() {
    const { isAccessLevelConfigModified, accessLevel, noOfVisibleCards, saveConfig } = this.props;
    return (
      <React.Fragment>
        <Header>
          {isAccessLevelConfigModified && accessLevel && (
            <Button variant="success" type="primary" onClick={() => saveConfig(noOfVisibleCards, accessLevel)}>
              {t("save")}
            </Button>
          )}
        </Header>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    accessLevel: state.settingsPage.accessLevel,
    noOfVisibleCards: state.settingsPage.noOfVisibleCards,
    isAccessLevelConfigModified: state.settingsPage.ui.isAccessLevelConfigModified
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    saveConfig: (noOfVisibleCards, accessLevel) => dispatch(saveContentAccessConfig(noOfVisibleCards, accessLevel))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(AccessLevelHeader);

export { AccessLevelHeader };
