/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import MoreVertical from "components/icons/more-vertical";
import OutsideClickHandler from "components/outside-click-handler/outside-click-handler";
import classnames from "classnames/bind";

import { ContextMenuList } from "./context-menu-list";
import { ContextMenuItem } from "./context-menu-item";

import styles from "./context-menu.module.css";

const cx = classnames.bind(styles);

enum ContextMenuPosition {
  Left = "left",
  Right = "right"
}

interface ContextMenuProps {
  position: ContextMenuPosition;
  children: React.ReactNode;
}

interface ContextMenuState {
  menuVisible: boolean;
}

class ContextMenu extends React.Component<ContextMenuProps, ContextMenuState> {
  static defaultProps = {
    position: "right"
  };

  constructor(props: ContextMenuProps) {
    super(props);
    this.state = {
      menuVisible: false
    };
  }

  toggleMenuBox = (e: React.MouseEvent) => {
    e.stopPropagation();
    this.setState((prevState) => ({ menuVisible: !prevState.menuVisible }));
  };

  closeMenuBox = (e: MouseEvent | React.MouseEvent) => {
    e.stopPropagation();
    this.setState({ menuVisible: false });
  };

  render() {
    const contextMenuClasses = cx(
      "context-menu-container",
      { "is-aligned-left": this.props.position === "left" },
      { "is-aligned-right": this.props.position === "right" }
    );
    return (
      <OutsideClickHandler onOutsideClick={(e: MouseEvent) => this.closeMenuBox(e)}>
        <div className={contextMenuClasses} data-test-id="context-menu-container">
          <div
            data-test-id="context-menu-btn"
            className={styles["context-menu-icon"]}
            tabIndex={0}
            onClick={(e: React.MouseEvent) => {
              this.toggleMenuBox(e);
            }}>
            <MoreVertical />
          </div>
          {this.state.menuVisible && (
            <div
              data-test-id="context-menu-box"
              className={styles["context-menu-box"]}
              onClick={(e: React.MouseEvent) => this.closeMenuBox(e)}>
              {this.props.children}
            </div>
          )}
        </div>
      </OutsideClickHandler>
    );
  }
}

export { ContextMenu };
export { ContextMenuList };
export { ContextMenuItem };
