/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Attachment from "components/icons/story-elements/attachment";
import JsEmbed from "components/icons/story-elements/js-embed";
import Location from "components/icons/story-elements/location";
import AlsoRead from "components/icons/story-elements/also-read";
import Table from "components/icons/story-elements/table";
import Embed from "components/icons/story-elements/embed";
import ImageGallery from "components/icons/story-elements/image-gallery";
import Title from "components/icons/story-elements/title";
import Text from "components/icons/story-elements/text";
import Image from "components/icons/story-elements/image";
import Quote from "components/icons/story-elements/quote";
import Reference from "components/icons/story-elements/reference";
import Question from "components/icons/story-elements/question";
import Answer from "components/icons/story-elements/answer";
import Summary from "components/icons/story-elements/summary";
import BlockQuote from "components/icons/story-elements/block-quote";
import Blurb from "components/icons/story-elements/blurb";
import BigFact from "components/icons/story-elements/bigfact";
import Slideshow from "components/icons/story-elements/slideshow";
import Poll from "components/icons/story-elements/poll";
import QuestionAndAnswer from "components/icons/story-elements/question-and-answer";
import Cta from "components/icons/story-elements/cta";
import Video from "components/icons/story-elements/video-clip";

const getStoryElementIcon = (storyElement) => {
  const storyElementName = storyElement.subtype ? storyElement.subtype : storyElement.type;
  if (storyElementName === "image-gallery" && storyElement.metadata.type === "slideshow") {
    return <Slideshow />;
  }
  switch (storyElementName) {
    case "text":
      return <Text />;
    case "quote":
      return <Quote />;
    case "bigfact":
      return <BigFact />;
    case "blockquote":
      return <BlockQuote />;
    case "blurb":
      return <Blurb />;
    case "attachment":
      return <Attachment />;
    case "location":
      return <Location />;
    case "jsembed":
    case "jwplayer":
    case "brightcove-video":
    case "vod-video":
    case "dilmot-q-and-a":
      return <JsEmbed />;
    case "image":
      return <Image />;
    case "also-read":
      return <AlsoRead />;
    case "table":
      return <Table />;
    case "social-media":
    case "instagram":
    case "youtube-video":
    case "soundcloud-audio":
    case "tweet":
    case "dailymotion-video":
    case "bitgravity-video":
    case "vidible-video":
    case "facebook-post":
    case "facebook-video":
      return <Embed />;
    case "image-gallery":
      return <ImageGallery />;
    case "title":
      return <Title />;
    case "references":
      return <Reference />;
    case "cta":
      return <Cta />;
    case "question":
      return <Question />;
    case "answer":
      return <Answer />;
    case "summary":
      return <Summary />;
    case "q-and-a":
      return <QuestionAndAnswer />;
    case "opinion-poll":
      return <Poll />;
    case "video":
      return <Video />;
    default:
      return;
  }
};

export default getStoryElementIcon;
