/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Attribute } from "api/story-attributes";
import { t } from "i18n";
import NoContentCard from "components/no-content-card/no-content-card";
import File from "components/icons/file";
import styles from "./attributes-list.module.css";
import AttributeRow from "../attribute-row/attribute-row";

interface AttributesListProps {
  attributes: Attribute[];
  deleteAttribute?: (attribute: Attribute) => void;
  className?: string;
}

const AttributesList: React.SFC<AttributesListProps> = ({ attributes, deleteAttribute, className }) => {
  return (
    <React.Fragment>
      {attributes.length === 0 && (
        <div className={styles["attributes-no-content"]}>
          <NoContentCard>
            <File />
            <div className={styles["no-content-message"]}>{t("attributes.messages.no-attributes")}</div>
          </NoContentCard>
        </div>
      )}
      {attributes.length > 0 && (
        <ul className={className}>
          {attributes.map((attribute) => (
            <AttributeRow
              key={`attributes-list-attribute-${attribute.name}`}
              attribute={attribute}
              deleteAttribute={deleteAttribute}
            />
          ))}
        </ul>
      )}
    </React.Fragment>
  );
};

interface RootAttributesListProps {
  attributes: Attribute[];
  deleteAttribute: (attribute: Attribute) => void;
}

const RootAttributesList: React.SFC<RootAttributesListProps> = ({ attributes, deleteAttribute }) => {
  return (
    <AttributesList className={styles["attributes-list"]} attributes={attributes} deleteAttribute={deleteAttribute} />
  );
};

export default RootAttributesList;

export { AttributesList };
