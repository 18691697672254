/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useState, useEffect, useCallback } from "react";
import { AnyPushNotification, linkedStory, PushNotificationUrlType, Target } from "api/push-notification";
import Inspector from "components/inspector/inspector";
import DatePickerCustom from "components/date-picker/date-picker";
import debounce from "p-debounce";
import { getPublishedStories } from "helpers/api";
import AsyncSelect from "components/select/async";
import { t } from "i18n";
import RadioButton from "components/radio-button/radio-button";
import { format as formatDate, isAfter } from "date-fns";
import styles from "./inspector.module.css";
import { getErrorMessage, hasError } from "../../utils";
import { PushNotificationDateValidationError } from "../../errors";
import classnames from "classnames/bind";
import SubTitle from "components/icons/sub-title";
import TextLimitIndicator from "components/text-limit-indicator/text-limit-indicator";
import TextArea from "components/text-area/text-area";
import Text from "components/icons/story-elements/text";
import { updateCurrentPushNotification } from "../../action-creators";
import { connect } from "react-redux";
import PushNotificationTargets from "../push-notification-targets/push-notification-targets";
import CategoryTitle from "components/category-title/category-title";
import TextField from "components/text-field/text-field";
import { parse } from "querystring";
import { get } from "lodash";
import { LocationState } from "history";
import { PartialAppState } from "../../state";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  initNewPushNotification,
  loadPushNotification,
  savePushNotification,
  savePushNotificationFailure,
  updatePushNotification
} from "../../async-action-creators";
import { PushNotificationId } from "api/primitive-types";

const cx = classnames.bind(styles);

const loadPublishedStories = debounce((headline) => getPublishedStories({ headline, fields: "id,headline" }), 250);

interface Props {
  isInspectorActive: boolean;
  error: Error | null;

  navigateBackFromInspector: () => void;

  saveNewPushNotificationFailure: (error: Error) => void;
  updatePushNotification: (
    publishAt: number | null,
    pushNotification: AnyPushNotification,
    channelsEnabled: boolean
  ) => void;
  pushNotificationToStoryMapping?: {
    title: string | null;
    message: string | null;
  } | null;
  location?: LocationState;
  isNew: boolean;
  isEdit: boolean;
  pushNotificationId: number;
}

interface DispatchProps {
  onChangeOfPushNotification: (change: AnyPushNotification) => void;
  initNewPushNotification: () => void;
  loadPushNotification: (pushNotificationId: PushNotificationId) => void;
  saveNewPushNotification: (
    publishAt: number | null,
    pushNotification: AnyPushNotification,
    channelsEnabled: boolean
  ) => void;
  updatePushNotification: (
    publishAt: number | null,
    pushNotification: AnyPushNotification,
    channelsEnabled: boolean
  ) => void;
  saveNewPushNotificationFailure: (error: Error) => void;
}
interface StateProps {
  fieldLimit: number;
  channelsEnabled?: boolean;
  currentPushNotification: AnyPushNotification | null;
  pushNotificationToStoryMapping?: {
    title: string | null;
    message: string | null;
  } | null;
  publishDisable: boolean;
}

type PushNotificationFormProps = Props & DispatchProps & StateProps;

const PushNotificationInspector: React.FC<PushNotificationFormProps> = ({
  currentPushNotification,
  channelsEnabled,
  error,
  navigateBackFromInspector,
  saveNewPushNotification,
  saveNewPushNotificationFailure,
  updatePushNotification,
  pushNotificationToStoryMapping,
  location,
  fieldLimit,
  onChangeOfPushNotification,
  isNew,
  isEdit,
  initNewPushNotification,
  loadPushNotification,
  pushNotificationId,
  publishDisable
}) => {
  const [isPublishLater, setIsPublishLater] = useState<boolean>(
    !!(currentPushNotification && currentPushNotification["publish-at"])
  );

  useEffect(() => {
    if (isNew) {
      setIsPublishLater(false);
    }
    if (isEdit) {
      setIsPublishLater(true);
    }
  }, [isEdit, isNew, setIsPublishLater]);

  const fillStoryDataOnLoad = useCallback(
    async (storyId, mapping) => {
      try {
        const res = await getPublishedStories({ "story-ids": storyId, fields: "id,headline,subheadline" });
        const story = res[0];
        const newLinkedStory = { id: story.id, headline: story.headline };

        onChangeOfPushNotification({
          title: story[get(mapping, "title", "headline")] || "",
          "push-notification": story[get(mapping, "message", "subheadline")] || "",
          "story-content-id": newLinkedStory["id"],
          "linked-story": newLinkedStory,
          metadata: { type: PushNotificationUrlType.STORY },
          "publish-at": null,
          "target-categories": null
        });
      } catch (error) {
        console.error(error);
      }
    },
    [onChangeOfPushNotification]
  );

  useEffect(() => {
    if (isNew) {
      const searchParams = parse(location.search.slice(1));
      initNewPushNotification();
      if (searchParams["story-id"]) {
        const storyId = searchParams["story-id"];
        fillStoryDataOnLoad(storyId, pushNotificationToStoryMapping);
      }
    }
  }, [location.search, isNew, pushNotificationToStoryMapping, fillStoryDataOnLoad, initNewPushNotification]);

  useEffect(() => {
    if (isEdit && pushNotificationId) {
      loadPushNotification(pushNotificationId);
    }
  }, [loadPushNotification, isEdit, pushNotificationId]);

  const onChangePublishLater = (isPublishLater) => {
    setIsPublishLater(isPublishLater);
  };

  const validateDateField = (currentPushNotification) => {
    if (isPublishLater) {
      if (currentPushNotification["publish-at"] == null) {
        saveNewPushNotificationFailure(
          new PushNotificationDateValidationError(t("push-notification.messages.publish-date-missing"))
        );
        return false;
      } else if (isAfter(new Date(), currentPushNotification["publish-at"])) {
        saveNewPushNotificationFailure(
          new PushNotificationDateValidationError(t("push-notification.messages.publish-date-invalid"))
        );
        return false;
      }
    }
    return true;
  };

  const publishPushNotification = () => {
    if (validateDateField(currentPushNotification) && currentPushNotification) {
      const publishAtValue = isPublishLater ? currentPushNotification["publish-at"] : null;
      currentPushNotification["notification-id"]
        ? updatePushNotification(
            publishAtValue,
            currentPushNotification,
            channelsEnabled !== undefined ? channelsEnabled : false
          )
        : saveNewPushNotification(
            publishAtValue,
            currentPushNotification,
            channelsEnabled !== undefined ? channelsEnabled : false
          );
    }
  };

  const targetPlatforms =
    currentPushNotification && currentPushNotification["target-categories"]
      ? currentPushNotification["target-categories"]
      : [];

  const onStoryChange = (newLinkedStory, pushNotification) => {
    onChangeOfPushNotification({
      ...pushNotification,
      "story-content-id": newLinkedStory ? newLinkedStory["id"] : null,
      "linked-story": newLinkedStory
    });
  };

  return (
    <Inspector
      title={isNew ? t("push-notification.cta.create") : t("push-notification.cta.update")}
      onClose={() => navigateBackFromInspector()}
      isActive={isEdit || isNew}
      isActionButtonDisabled={publishDisable}
      actionButtonLabel={t("push-notification.cta.publish")}
      onActionButtonClick={publishPushNotification}>
      {currentPushNotification && (
        <div className={styles["push-notification-container"]}>
          <section className={styles["push-notification-header-wrapper"]}>
            <div className={styles["push-notification-header-container"]}>
              <div className={styles["push-notification-header"]}>
                <div
                  className={cx(
                    "push-notification-field-label",
                    "title",
                    `${hasError("title", error) ? "has-error" : ""}`
                  )}>
                  <div className={`title-icon ${hasError("title", error) ? "has-error" : ""}`}>
                    <SubTitle color="currentColor" />
                    <TextLimitIndicator text={currentPushNotification.title || ""} limit={fieldLimit} />
                  </div>
                </div>
                <div className={styles["push-notification-title"]} data-test-id="push-notification-title">
                  <TextArea
                    onChange={(title) =>
                      onChangeOfPushNotification({
                        ...currentPushNotification,
                        title
                      })
                    }
                    placeholder={t("push-notification.form.title_hint")}
                    value={currentPushNotification.title}
                    errorMessage={getErrorMessage("title", error)}
                    variant="plain"
                    size="jumbo"
                  />
                </div>
                <div
                  className={cx(
                    "push-notification-field-label",
                    "message",
                    `${hasError("push-notification", error) ? "has-error" : ""}`
                  )}>
                  <div className={`title-icon ${hasError("push-notification", error) ? "has-error" : ""}`}>
                    <Text color="currentColor" />
                    <TextLimitIndicator text={currentPushNotification["push-notification"] || ""} limit={fieldLimit} />
                  </div>
                </div>
                <div className={styles["push-notification-message"]} data-test-id="push-notification-message">
                  <TextArea
                    onChange={(message) =>
                      onChangeOfPushNotification({
                        ...currentPushNotification,
                        "push-notification": message
                      })
                    }
                    placeholder={t("push-notification.form.message_hint")}
                    value={currentPushNotification["push-notification"]}
                    errorMessage={getErrorMessage("push-notification", error)}
                    variant="plain"
                    size="extra-large"
                  />
                </div>

                <div className={styles["also-read-preview-container"]}>
                  <PushNotificationTargets
                    targets={targetPlatforms}
                    channelsEnabled={channelsEnabled}
                    onUpdate={(targets: Array<Target>) =>
                      onChangeOfPushNotification({
                        ...currentPushNotification,
                        "target-categories": targets
                      })
                    }
                  />
                  {error && (
                    <div className={styles["push-notification-targets-error-message"]}>
                      {getErrorMessage("target-categories", error)}
                    </div>
                  )}
                  <div className={styles["push-notification-inspector-seprator"]}></div>
                  <div className={styles["push-notification-inspector-radio-group"]}>
                    <RadioButton
                      id="radio-internal-story-type"
                      label={t("common.link-to-story")}
                      checked={currentPushNotification.metadata.type === PushNotificationUrlType.STORY}
                      name="link story"
                      onChange={() => {
                        onChangeOfPushNotification({
                          ...currentPushNotification,
                          metadata: { type: PushNotificationUrlType.STORY }
                        });
                      }}
                      classname={"push-notification-inspector-radio-button"}
                    />

                    <RadioButton
                      id="radio-no-story-type"
                      label={t("common.no-link")}
                      checked={currentPushNotification.metadata.type === PushNotificationUrlType.NO_LINK}
                      name="link story"
                      onChange={() => {
                        onChangeOfPushNotification({
                          ...currentPushNotification,
                          metadata: { type: PushNotificationUrlType.NO_LINK }
                        });
                      }}
                      classname={"push-notification-inspector-radio-button"}
                    />

                    <RadioButton
                      id="radio-external-story-type"
                      label={t("common.link-to-custom-url")}
                      checked={currentPushNotification.metadata.type === PushNotificationUrlType.CUSTOM_URL}
                      name="link story"
                      onChange={() => {
                        onChangeOfPushNotification({
                          ...currentPushNotification,
                          metadata: { type: PushNotificationUrlType.CUSTOM_URL }
                        });
                      }}
                      classname={"push-notification-inspector-radio-button"}
                    />
                  </div>

                  {currentPushNotification.metadata &&
                    currentPushNotification.metadata.type === PushNotificationUrlType.STORY && (
                      <AsyncSelect
                        label={t("push-notification.form.linked_story_label")}
                        placeholder={t("story-editor.inspector.search")}
                        loadOptions={loadPublishedStories}
                        value={currentPushNotification["linked-story"]}
                        errorMessage={getErrorMessage("story-content-id", error)}
                        defaultOptions={false}
                        hideError={!getErrorMessage("story-content-id", error)}
                        cacheOptions={null}
                        getOptionLabel={(story: linkedStory) => story.headline}
                        getOptionValue={(story: linkedStory) => story.id}
                        onChange={(newLinkedStory: linkedStory) =>
                          onStoryChange(newLinkedStory, currentPushNotification)
                        }
                        isClearable={true}
                      />
                    )}
                  {currentPushNotification.metadata.type === PushNotificationUrlType.CUSTOM_URL && (
                    <TextField
                      label={t("common.link-to-custom-url")}
                      value={currentPushNotification["metadata"]["external-url"] || ""}
                      errorEnabled={getErrorMessage("metadata", error)}
                      errorMessage={getErrorMessage("metadata", error)}
                      onChange={(external_url) => {
                        onChangeOfPushNotification({
                          ...currentPushNotification,
                          metadata: { ...currentPushNotification.metadata, "external-url": external_url }
                        });
                      }}
                    />
                  )}
                </div>
              </div>
              <div className={styles["push-notification-inspector-radio-group"]}>
                <CategoryTitle title={t("push-notification.cta.publish")} />

                <div className={styles["push-notification-inspector-radio-group"]}>
                  <RadioButton
                    id="radio-publish-now"
                    label={t("common.publish-now")}
                    checked={!isPublishLater}
                    name="publish"
                    onChange={(isPublishNow: boolean) => onChangePublishLater(!isPublishNow)}
                    classname={"push-notification-inspector-radio-button"}
                  />
                  <RadioButton
                    id="radio-publish-later"
                    label={t("common.publish-later")}
                    checked={isPublishLater}
                    name="publish"
                    onChange={(isPublishLater: boolean) => onChangePublishLater(isPublishLater)}
                    classname={"push-notification-inspector-radio-button"}
                  />
                </div>
                {isPublishLater && (
                  <div>
                    <h3>{t("push-notification.inspector.schedule-message")}</h3>
                    {currentPushNotification["publish-at"] && (
                      <p>
                        {t("push-notification.inspector.already-scheduled-message") +
                          formatDate(currentPushNotification["publish-at"], "dd/MM/yyyy hh:mm a")}
                      </p>
                    )}
                    {currentPushNotification && (
                      <div>
                        <DatePickerCustom
                          showTimeSelect
                          onChange={(publishAt) => {
                            onChangeOfPushNotification({
                              ...currentPushNotification,
                              "publish-at": publishAt
                            });
                          }}
                          selectedDate={currentPushNotification["publish-at"]}
                        />
                        <div className={styles["push-notification-targets-error-message"]}>
                          {getErrorMessage("publish-at", error)}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      )}
    </Inspector>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    fieldLimit: get(state, ["config", "fieldLimits", "push-notification", "limit"], 140),
    channelsEnabled: state.features.enablePushNotificationChannels,
    pushNotificationToStoryMapping: get(state, ["config", "push-notification-to-story-mapping"]),
    currentPushNotification: state.pushNotification.app.currentPushNotification,
    publishDisable: state.pushNotification.ui.save.loading
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    onChangeOfPushNotification: (change: AnyPushNotification) => {
      dispatch(updateCurrentPushNotification(change));
    },
    initNewPushNotification: () => dispatch(initNewPushNotification()),
    loadPushNotification: (pushNotificationId: PushNotificationId) =>
      dispatch(loadPushNotification(pushNotificationId)),
    saveNewPushNotification: (publishAt, pushNotification, channelsEnabled) =>
      dispatch(savePushNotification(publishAt, pushNotification, channelsEnabled)),
    saveNewPushNotificationFailure: (error: Error) => dispatch(savePushNotificationFailure(error)),
    updatePushNotification: (
      publishAt: number | null,
      pushNotification: AnyPushNotification,
      channelsEnabled: boolean
    ) => dispatch(updatePushNotification(publishAt, pushNotification, channelsEnabled))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PushNotificationInspector);
