/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import wrapPage from "containers/page/page";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";

import ContentHeader from "../../../components/content-header/content-header";
import Toggles from "../components/toggles/toggles";
import { fetchConfigureData, updateFeature } from "../action-creator";
import { Configuration } from "api/select-features";
import { PartialAppState } from "../../../initial-state";
import classnames from "classnames/bind";
import styles from "./features.module.css";

const cx = classnames.bind(styles);
interface StateProps {
  featuresToggles: Array<Configuration>;
}

interface DispatchProps {
  loadConfigureData: () => void;
  toggleFeature: (updatedFeature: Configuration) => void;
}

type Props = StateProps &
  DispatchProps & {
    showSubmenu: string;
    activeMenu: string;
    isBannerPresent: boolean;
  };

class Features extends React.Component<Props> {
  componentDidMount() {
    this.props.loadConfigureData();
  }

  render() {
    const { featuresToggles, toggleFeature } = this.props;
    return (
      <React.Fragment>
        <main className={cx("features-page-container", "configure-page")} data-test-id="features-page-container">
          <ContentHeader title={t("settings.features.title")} description={t("settings.features.description")} />
          <Toggles<Configuration>
            toggles={featuresToggles.filter((item) => !item.hidden)}
            onChange={(updatedFeature) => toggleFeature(updatedFeature)}
          />
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    featuresToggles: state.settingsPage.features
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    loadConfigureData: () => dispatch(fetchConfigureData()),
    toggleFeature: (updatedFeature) => dispatch(updateFeature(updatedFeature.id, { toggle: updatedFeature.toggle }))
  };
};

export default compose<any, any, any>(connect(mapStateToProps, mapDispatchToProps), wrapPage())(Features);
export { Features };
