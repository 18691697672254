/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import {
  STORY_EDITOR_PATH,
  STORY_EDITOR_READ_ONLY_PATH,
  STORY_EDITOR_MANAGE_PATH,
  STORY_EDITOR_HERO_IMAGE_GALLERY_PATH,
  STORY_EDITOR_IMAGE_INSPECTOR_PATH,
  STORY_EDITOR_IMAGE_ELEMENT_GALLERY_PATH,
  STORY_EDITOR_TEMPLATE_PATH,
  STORY_EDITOR_MANAGE_ADVANCED_METADATA_PATH,
  STORY_EDITOR_MANAGE_ADVANCED_ATTRIBUTES_PATH,
  STORY_EDITOR_MANAGE_ADVANCED_SOCIAL_NOTIFICATIONS_PATH,
  STORY_EDITOR_ALTERNATIVES_PATH,
  STORY_EDITOR_PUBLISH,
  STORY_EDITOR_CARD_SHARE,
  STORY_EDITOR_CARD_ATTRIBUTES,
  STORY_EDITOR_ALTERNATIVES_IMAGE_PATH,
  STORY_EDITOR_ALTERNATIVES_IMAGE_EDIT_PATH,
  STORY_EDITOR_CARD_SHARE_IMAGE_PATH,
  STORY_EDITOR_POLL_EDIT,
  STORY_EDITOR_IMAGE_GALLERY_ELEMENT_EDIT_PATH,
  STORY_EDITOR_IMAGE_GALLERY_ELEMENT_GALLERY_PATH,
  STORY_EDITOR_STORY_SEARCH_PATH,
  STORY_EDITOR_IMPORT_PATH,
  STORY_EDITOR_PLAGIARISM_REPORT,
  STORY_EDITOR_NOTES_PATH,
  STORY_EDITOR_OEMBED_VIDEO_SELECTOR_PATH,
  STORY_EDITOR_EMBARGO_PATH
} from "./routes";

import StoryEditor from "pages/story-editor/story-editor";

export const Router = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={STORY_EDITOR_PATH} render={(props) => <StoryEditor {...props} />} />

        <Route exact path={STORY_EDITOR_MANAGE_PATH} render={(props) => <StoryEditor {...props} />} />
        <Route exact path={STORY_EDITOR_MANAGE_ADVANCED_METADATA_PATH} render={(props) => <StoryEditor {...props} />} />
        <Route
          exact
          path={STORY_EDITOR_MANAGE_ADVANCED_ATTRIBUTES_PATH}
          render={(props) => <StoryEditor {...props} />}
        />
        <Route
          exact
          path={STORY_EDITOR_MANAGE_ADVANCED_SOCIAL_NOTIFICATIONS_PATH}
          render={(props) => <StoryEditor {...props} />}
        />
        <Route
          exact
          path={STORY_EDITOR_ALTERNATIVES_IMAGE_PATH}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />
        <Route
          exact
          path={STORY_EDITOR_ALTERNATIVES_PATH}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />
        <Route
          exact
          path={STORY_EDITOR_CARD_SHARE_IMAGE_PATH}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />
        <Route
          exact
          path={STORY_EDITOR_ALTERNATIVES_IMAGE_EDIT_PATH}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />

        <Route
          exact
          path={STORY_EDITOR_HERO_IMAGE_GALLERY_PATH}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />

        <Route
          exact
          path={STORY_EDITOR_IMAGE_INSPECTOR_PATH}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />

        <Route
          exact
          path={STORY_EDITOR_IMAGE_ELEMENT_GALLERY_PATH}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />

        <Route
          exact
          path={STORY_EDITOR_MANAGE_ADVANCED_METADATA_PATH}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />

        <Route
          exact
          path={STORY_EDITOR_TEMPLATE_PATH}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />

        <Route
          exact
          path={STORY_EDITOR_PUBLISH}
          render={(props) => <StoryEditor {...props} showPublishInspector={true} />}
        />

        <Route
          exact
          path={STORY_EDITOR_CARD_SHARE}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />
        <Route
          exact
          path={STORY_EDITOR_CARD_ATTRIBUTES}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />

        <Route
          exact
          path={STORY_EDITOR_READ_ONLY_PATH}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />

        <Route
          exact
          path={STORY_EDITOR_POLL_EDIT}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />

        <Route
          exact
          path={STORY_EDITOR_IMAGE_GALLERY_ELEMENT_GALLERY_PATH}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />
        <Route
          exact
          path={STORY_EDITOR_IMAGE_GALLERY_ELEMENT_EDIT_PATH}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />
        <Route
          exact
          path={STORY_EDITOR_STORY_SEARCH_PATH}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />
        <Route
          exact
          path={STORY_EDITOR_IMPORT_PATH}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />
        <Route
          exact
          path={STORY_EDITOR_PLAGIARISM_REPORT}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />
        <Route
          exact
          path={STORY_EDITOR_NOTES_PATH}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />
        <Route
          exact
          path={STORY_EDITOR_EMBARGO_PATH}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />
        <Route
          exact
          path={STORY_EDITOR_OEMBED_VIDEO_SELECTOR_PATH}
          render={(props) => <StoryEditor {...props} blockStorySave={true} />}
        />
      </Switch>
    </React.Fragment>
  );
};
