/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { AnyImage, Image, ImageMetadata } from "api/search-media-image";
import { Section } from "./route-data/story-route-data";

type BackendImage = {
  "image-metadata": ImageMetadata;
  "image-s3-key"?: string;
  title: string | null;
};

type UnsavedBackendImage = Omit<BackendImage, "image-s3-key"> & {
  "temp-image-key": string;
};

export interface WatermarkImage {
  "type-id"?: number | null;
  type?: string;
  "overlay-data"?: object;
  image: AnyImage | null;
  "publisher-id"?: number;
  id?: number;
  "created-at"?: number;
  "updated-at"?: number;
}

type BackendWatermarkImage = Omit<WatermarkImage, "image"> & {
  image: BackendImage | null;
};

type BackendWatermarkImageForSave = Omit<WatermarkImage, "image"> & {
  image: BackendImage | UnsavedBackendImage | null;
};

const transformImageForFrontend = (image: BackendImage | null, opts: { imageHost: string }): AnyImage | null => {
  if (!image) {
    return null;
  }

  return {
    metadata: image["image-metadata"],
    caption: image.title,
    attribution: null,
    key: image["image-s3-key"],
    url: opts.imageHost + image["image-s3-key"]
  };
};

const transformImageForBackend = (image: AnyImage | null): BackendImage | UnsavedBackendImage | null => {
  if (!image) {
    return null;
  }

  let imageForBackend = { "image-metadata": image.metadata, title: image.caption || "watermark-image" };
  if (image["temp-image-key"]) {
    return { ...imageForBackend, "temp-image-key": image["temp-image-key"] };
  }

  return { ...imageForBackend, "image-s3-key": (image as Image).key };
};

function put(watermark: BackendWatermarkImageForSave, opts: { imageHost: string }) {
  return client
    .url(`/api/watermark-images/${watermark.id}`)
    .put(watermark)
    .json()
    .then((watermarkImage) => {
      watermarkImage.image = transformImageForFrontend(watermarkImage.image, opts);
      return watermarkImage;
    });
}

function post(watermark: BackendWatermarkImageForSave, opts: { imageHost: string }) {
  return client
    .url(`/api/watermark-images`)
    .post(watermark)
    .json()
    .then((watermarkImage) => {
      watermarkImage.image = transformImageForFrontend(watermarkImage.image, opts);
      return watermarkImage;
    });
}

export function save(watermark: WatermarkImage, opts: { imageHost: string }) {
  const watermarkToSave = { ...watermark, image: transformImageForBackend(watermark.image) };
  if (watermark.id) {
    return put(watermarkToSave, opts);
  }

  return post(watermarkToSave, opts);
}

export function deleteWatermark(watermarkId: number) {
  return client
    .url(`/api/watermark-images/${watermarkId}`)
    .delete()
    .res();
}

export function get(imageHost: string, opts: { section: Section } | undefined): Promise<WatermarkImage> {
  const { section = null } = opts || {};
  let params = section ? { type: "section", "type-id": section.id } : { type: "default" };
  return client
    .url(`/api/watermark-images`)
    .query(params)
    .get()
    .json()
    .then((watermarkImage: BackendWatermarkImage) => {
      return { ...watermarkImage, image: transformImageForFrontend(watermarkImage.image, { imageHost }) };
    });
}
