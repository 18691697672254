/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect, useState } from "react";
import Inspector from "components/inspector/inspector";
import { get, set, unset, omitBy, cloneDeep, isEmpty, isArray, isEqual } from "lodash";
import { Metadata } from "api/static-pages";
import TextField, { TextFieldWithCount } from "components/text-field/text-field";
import CategoryTitle from "components/category-title/category-title";
import { t } from "i18n";
import { getValidationErrors, META_FIELDS_LIMIT } from "pages/content/static-pages/errors";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  metadata: Metadata;
  updateMetadata: (metadata: Metadata) => void;
}

const StaticPageManageInspector: React.FC<Props> = ({ isOpen, onClose, metadata, updateMetadata }) => {
  const [currentMetadata, setCurrentMetadata] = useState<Metadata>(metadata);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setCurrentMetadata(metadata);
  }, [isOpen, metadata]);

  useEffect(() => {
    if (currentMetadata && currentMetadata.seo) {
      setErrors(getValidationErrors(currentMetadata.seo));
    }
  }, [currentMetadata]);

  const updateSeoData = (key: string, value: string | { label: string; value: string }[]) => {
    const updatedMetadata = cloneDeep(currentMetadata);
    let updatedSeo = get(updatedMetadata, "seo", {});
    if (updatedSeo) {
      if (key === "meta-keywords" && isArray(value)) {
        const keywords = value.map((entry) => entry.value);
        set(updatedSeo, key, keywords);
      } else {
        set(updatedSeo, key, value);
      }
      updatedSeo = omitBy(updatedSeo, isEmpty);
    }
    if (isEmpty(updatedSeo)) {
      unset(updatedMetadata, "seo");
    } else {
      set(updatedMetadata, "seo", updatedSeo);
    }
    setCurrentMetadata(updatedMetadata);
  };

  return (
    <Inspector
      isActive={isOpen}
      title={t("staticPages.form.inspector.title")}
      showActionButton={true}
      actionButtonLabel={t("common.done")}
      isActionButtonDisabled={!isEmpty(errors) || isEqual(currentMetadata, metadata)}
      onActionButtonClick={() => {
        updateMetadata(currentMetadata);
        onClose();
      }}
      onClose={onClose}>
      {isOpen && (
        <>
          <CategoryTitle title={t("staticPages.form.inspector.seo.category-title")} />
          <TextFieldWithCount
            label={t("staticPages.form.inspector.seo.meta-title")}
            value={get(currentMetadata, ["seo", "meta-title"])}
            onChange={(value) => updateSeoData("meta-title", value)}
            maxValue={META_FIELDS_LIMIT}
            errorMessage={get(errors, "meta-title")}
          />
          <TextFieldWithCount
            label={t("staticPages.form.inspector.seo.meta-description")}
            value={get(currentMetadata, ["seo", "meta-description"])}
            onChange={(value) => updateSeoData("meta-description", value)}
            maxValue={META_FIELDS_LIMIT}
            errorMessage={get(errors, "meta-description")}
          />
          <TextField
            label={t("staticPages.form.inspector.seo.meta-keywords")}
            hint={t("staticPages.form.inspector.seo.meta-keywords-hint")}
            value={get(currentMetadata, ["seo", "meta-keywords"])}
            onChange={(value) => updateSeoData("meta-keywords", value)}
          />
        </>
      )}
    </Inspector>
  );
};

export default StaticPageManageInspector;
