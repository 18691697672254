/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, TooltipProps, ResponsiveContainer } from "recharts";
import { TimeSlice } from "api/analytics";
import { Timestamp } from "api/primitive-types";
import styles from "./graph.module.css";
import { formatNumberToMetricSystem } from "utils";
import { generateXAxisTicks, getNextPeriod } from "pages/analytics/utils";
import Delta from "../delta/delta";
import { CurrentFilter } from "../../state";

type CustomTooltipProps = TooltipProps & { currentFilter: CurrentFilter; data: TimeSlice[] };

const PreviousTooltip = ({ active, payload, currentFilter, data }: CustomTooltipProps) => {
  if (currentFilter.dateRange["name"] === "custom") {
    return null;
  }

  if (!active || !payload) {
    return null;
  }

  const prevRange = payload[1];
  return (
    <div className={styles["custom-tooltip__range-details"]}>
      <p className={styles["custom-tooltip__label"]}>
        {getNextPeriod(
          prevRange.payload["prev-timestamp"],
          data.map((item) => item["prev-timestamp"]),
          currentFilter.dateRange.name,
          "previous"
        )}
      </p>
      <span className={styles["custom-tooltip__total-value prev-range"]}>{prevRange.value}</span>
      <span className={styles["custom-tooltip__metric"]}>{t(`analytics.${currentFilter.metrics}`)}</span>
    </div>
  );
};

export const CustomTooltip = ({ active, payload, currentFilter, data }: CustomTooltipProps) => {
  if (!active || !payload) {
    return null;
  }

  const currentRange = payload[0];
  return (
    <div className={styles["custom-tooltip"]}>
      <div className={styles["custom-tooltip__range-details"]}>
        <p className={styles["custom-tooltip__label"]}>
          {getNextPeriod(
            currentRange.payload.timestamp,
            data.map((item) => item.timestamp),
            currentFilter.dateRange.name,
            "current"
          )}
        </p>
        <span className={styles["custom-tooltip__total-value current-range"]}>{currentRange.value}</span>
        <span className={styles["custom-tooltip__metric"]}>{t(`analytics.${currentFilter.metrics}`)}</span>
        <Delta value={currentRange.payload.change} variant="small" />
      </div>
      <PreviousTooltip {...{ active, payload, currentFilter, data }} />
    </div>
  );
};

interface Props {
  data: TimeSlice[];
  options: {
    xAxisLabelFormatter: (timestamp: Timestamp) => void;
    currentFilter: CurrentFilter;
  };
}

const tickStyle = {
  stroke: "#5f6978",
  fontSize: "1.2rem",
  strokeWidth: 0.5
};

const Graph: React.SFC<Props> = ({ data, options: { xAxisLabelFormatter, currentFilter } }) => {
  return (
    <div className={styles["analytics-graph"]}>
      <ResponsiveContainer>
        <LineChart data={data} margin={{ top: 5, right: 40, left: 0, bottom: 0 }}>
          <XAxis
            dataKey="timestamp"
            domain={["dataMin", "dataMax"]}
            name="Time"
            tickFormatter={(timestamp: Timestamp) => xAxisLabelFormatter && xAxisLabelFormatter(timestamp)}
            type="number"
            interval={0}
            ticks={generateXAxisTicks(currentFilter.dateRange.name, data.map((item) => item.timestamp))}
            tick={tickStyle}
            padding={{ left: 24, right: 0 }}
          />
          <YAxis
            scale="linear"
            axisLine={false}
            tickLine={false}
            tick={tickStyle}
            tickFormatter={(value) => formatNumberToMetricSystem(value)}
          />
          <CartesianGrid stroke="#f0f1f5" vertical={false} />

          <Tooltip content={<CustomTooltip currentFilter={currentFilter} data={data} />} active={true} />

          <Line type="monotone" dataKey="count" strokeWidth="2" stroke="#4860bc" activeDot={{ r: 5 }} dot={false} />

          {currentFilter.dateRange["name"] === "custom" ? null : (
            <Line
              type="monotone"
              dataKey="prev-count"
              stroke="#50e3c2"
              strokeDasharray="5 5"
              strokeWidth="2"
              dot={false}
              activeDot={{ r: 5 }}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Graph;
