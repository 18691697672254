/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import * as actions from "../../actions";
import Inspector from "components/inspector/inspector";
import { FixedTabList, Panel } from "components/tabs/tabs";
import { WORKSPACE_SAVED_FILTERS_PATH, WORKSPACE_FILTERS_PATH } from "../../routes";
import { FILTER_TAB_LIST } from "pages/workspace/tab-list";
import FiltersForm from "pages/workspace/components/filters-form/filters-form";
import SavedFilterList from "pages/workspace/components/saved-filter-list/saved-filter-list";
import { fetchWorkspaceData } from "pages/workspace/action-creators";
import { t } from "i18n";
import { PartialAppState, FilterSet, Source, StoryTemplate } from "api/workspace";
import { ThunkDispatch } from "redux-thunk";
import { Section } from "api/route-data/route-data";
import { StoryAttribute } from "api/route-data/story-route-data";
import { get } from "lodash";

interface FiltersInspectorStateProps {
  stagingFilterSet: FilterSet;
  sections: Section[];
  storyAttributes: StoryAttribute[];
  sources: Source[];
  showExternalSourcesFilter?: boolean;
  showAuthorFilter?: boolean;
  isPrintSelected: boolean;
}

interface FiltersInspectorDispatchProps {
  updateStagingFilter: (key: string, value: any) => void;
  applyFilter: (filterSet: FilterSet) => void;
}

interface FiltersInspectorOwnProps {
  selectedTabSlug: string;
  switchToTab: (selectedTabSlug: string) => void;
  navigate: (path: string, params?: { [key: string]: number | string | boolean }) => void;
  storyTemplates: StoryTemplate[];
  showFilterInspector: boolean;
  showSavedFilterList?: boolean;
  isStoryPrintEnabled?: boolean;
}

type FiltersInspectorProps = FiltersInspectorStateProps & FiltersInspectorDispatchProps & FiltersInspectorOwnProps;

const FiltersInspector = ({
  navigate,
  sections,
  storyAttributes,
  storyTemplates,
  selectedTabSlug,
  showFilterInspector,
  showSavedFilterList,
  sources,
  switchToTab,
  stagingFilterSet,
  updateStagingFilter,
  applyFilter,
  showAuthorFilter,
  showExternalSourcesFilter,
  isPrintSelected,
  isStoryPrintEnabled
}: FiltersInspectorProps) => {
  return (
    <Inspector
      title={t("workspace.filters_inspector.filters")}
      actionButtonLabel={t("workspace.filters_inspector.apply")}
      onActionButtonClick={() => applyFilter(stagingFilterSet)}
      isActive={showFilterInspector}
      onClose={() => switchToTab(selectedTabSlug)}>
      <FixedTabList
        tabs={FILTER_TAB_LIST(t)}
        value={showSavedFilterList ? "saved" : "options"}
        onChange={(value) =>
          navigate(value === "options" ? WORKSPACE_FILTERS_PATH : WORKSPACE_SAVED_FILTERS_PATH, {
            tabSlug: selectedTabSlug
          })
        }
      />
      <Panel>
        {!showSavedFilterList && (
          <FiltersForm
            sections={sections}
            storyTemplates={storyTemplates}
            storyAttributes={storyAttributes}
            sources={sources}
            filterSet={stagingFilterSet}
            updateFilter={updateStagingFilter}
            showAuthorFilter={showAuthorFilter}
            showExternalSourcesFilter={showExternalSourcesFilter}
            isPrintSelected={isPrintSelected}
            isStoryPrintEnabled={isStoryPrintEnabled}
          />
        )}
        {showSavedFilterList && <SavedFilterList />}
      </Panel>
    </Inspector>
  );
};

const mapStateToProps = (state: PartialAppState) => {
  const storyAttributes = state.config.storyAttributes.filter((attribute) => attribute.type === "story");
  return {
    stagingFilterSet: state.workspace.stagingFilterSet,
    isPrintSelected: get(state, ["workspace", "isPrintSelected"], false),
    sections: state.config.sections,
    storyAttributes,
    sources: state.config.sources,
    showExternalSourcesFilter: state.features.showExternalSourcesFilter,
    showAuthorFilter: state.features.showAuthorFilter,
    isStoryPrintEnabled: state.features.isStoryPrintEnabled
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>, ownProps: FiltersInspectorOwnProps) => {
  return {
    updateStagingFilter: (key: string, value: any) =>
      dispatch({ type: actions.WORKSPACE_UPDATE_STAGING_FILTER, payload: { key, value } }),
    applyFilter: (filterSet: FilterSet) => {
      dispatch({ type: actions.WORKSPACE_REPLACE_FILTER, payload: { filterSet } });
      ownProps.switchToTab(ownProps.selectedTabSlug);
      dispatch({ type: actions.WORKSPACE_RESET_STORIES });
      dispatch(fetchWorkspaceData(ownProps.selectedTabSlug));
    }
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(FiltersInspector);

export { FiltersInspector };
