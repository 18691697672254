/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { Collection } from "api/collection-items";

interface Filters {
  q?: string;
  limit?: number;
}

interface Response {
  data: Collection[];
  hits: number;
}

export const getPinnedCollections = (filters: Filters): Promise<Response> => {
  return client
    .url("/api/v1/pinned-collections")
    .query(filters)
    .get()
    .json();
};

export const pinCollection = (collectionId): Promise<void> => {
  return client
    .url("/api/v1/pinned-collections")
    .post({ "collection-id": collectionId })
    .res();
};

export const unpinCollection = (collectionId): Promise<void> => {
  return client
    .url(`/api/v1/pinned-collections/${collectionId}`)
    .delete()
    .res();
};
