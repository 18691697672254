/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useState } from "react";
import OutsideClickHandler from "components/outside-click-handler/outside-click-handler";
import classnames from "classnames/bind";
import { t } from "i18n";
import Chevron from "components/icons/chevron";
import styles from "./add-new-button.module.css";
import WithGAEvent from "helpers/ga-analytics/with-ga-event";

const cx = classnames.bind(styles);

enum AddNewButtonPosition {
  Left = "left",
  Right = "right"
}

interface AddNewButtonBaseProps {
  position?: AddNewButtonPosition;
  children: React.ReactNode;
  menu: React.ReactNode;
  title?: string;
  labelPadding?: number;
  hideAndCollapse?: boolean;
}

interface AddNewButtonProps {
  position?: AddNewButtonPosition;
  children: React.ReactNode;
  title?: string;
  labelPadding?: number;
}

const AddNewButtonBase: React.FC<AddNewButtonBaseProps> = (
  props = {
    position: AddNewButtonPosition.Right,
    children: [],
    menu: []
  }
) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenuBox = (e: MouseEvent | React.MouseEvent) => {
    e.stopPropagation();
    setMenuVisible(!menuVisible);
  };

  const closeMenuBox = (e: MouseEvent | React.MouseEvent) => {
    e.stopPropagation();
    setMenuVisible(false);
  };

  const addNewButtonContainerClasses = cx(
    "add-new-button-container",
    { "is-aligned-left": props.position === "right" },
    { "is-aligned-right": props.position === "left" }
  );

  return (
    <OutsideClickHandler onOutsideClick={closeMenuBox}>
      <div className={addNewButtonContainerClasses}>
        <button
          data-test-id="add-new-button"
          className={styles["add-new-button"]}
          onClick={(e: React.MouseEvent) => toggleMenuBox(e)}>
          {props.children}
        </button>
        {menuVisible && (
          <div
            data-test-id="add-new-button-box"
            className={styles["add-new-button-box"]}
            onClick={(e: React.MouseEvent) => closeMenuBox(e)}>
            {props.menu}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

const AddNewButtonDesktop: React.FC<AddNewButtonProps> = (props) => {
  const title = props.title || t("new");
  const styleOpts = props.labelPadding ? { style: { padding: `0 ${props.labelPadding}px` } } : {};
  return (
    <AddNewButtonBase position={props.position} menu={props.children}>
      <WithGAEvent label="header-add-new">
        <div className={styles["button-content-container"]}>
          <div className={styles["label"]} data-test-id="add-new-button-label" {...styleOpts}>
            {title}
          </div>
          <Chevron variant="down" fill="#fff" />
        </div>
      </WithGAEvent>
    </AddNewButtonBase>
  );
};

export { AddNewButtonDesktop as AddNewButton };
