/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { DOMParser } from "prosemirror-model";
import { htmlToPMNode } from "./html";
import { schema } from "../prosemirror/schema";
import {
  Card,
  ChildStoryElement,
  CompositeStoryElement,
  StoryElement,
  StoryElementSubType,
  StoryElementTextSubtype,
  StoryElementType
} from "api/story";
import { CardId, ClientId, StoryElementId } from "api/primitive-types";
import { ElementType } from "pages/story-editor/prosemirror/types";
import { buildCtaToPMNode } from "../prosemirror/story-to-prosemirror";
/*

All functions in this file should return ProseMirror nodes as JSON,
  so that they can be passed into require("prosemirror-model").Node.fromJSON(schema, json)

The reason we don't return the node itself, is to make it composable.
Node.fromJSON doesn't allow a mix of nested json and nodes, so we need to build a JSON tree first,
  and pass the whole thing to Node.fromJSON

*/

type NewStoryElementAttrs = {
  id: StoryElementId;
  "client-id"?: ClientId;
  "card-id": CardId;
  type: StoryElementType;
  subtype: StoryElementSubType<StoryElementType>;
};

interface NewStoryElement {
  attrs: NewStoryElementAttrs;
  type: ElementType;
  subtype?: StoryElementSubType<StoryElementType>;
  content?: {};
}

const parser = DOMParser.fromSchema(schema);

function newStoryElementTextNode(
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement
): NewStoryElement {
  /* ProseMirror, for some reason, requires a wrapper element.
  It can be anything, even something like <dummy></dummy> */

  const node = htmlToPMNode(parser, `<div>${storyElement.text ? storyElement.text : "<p></p>"}</div>`),
    json = node && node.toJSON();

  return {
    attrs: {
      id: storyElement.id,
      "client-id": storyElement["client-id"],
      "card-id": storyElement["card-id"],
      type: storyElement.type,
      subtype: storyElement.subtype
    },
    type: ElementType.StoryElementText,
    subtype: storyElement.subtype,
    content: json && json.content
  };
}

function newStoryElementQuestionNode(
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement
): NewStoryElement {
  /* ProseMirror, for some reason, requires a wrapper element.
  It can be anything, even something like <dummy></dummy> */

  const htmlForQuestionText = storyElement.text
      ? `<div class="question-text">${storyElement.text}</div>`
      : `<div class="question-text"><p></p></div>`,
    htmlForQuestionHelper = '<div class="question-helper-label" contenteditable="false">Question</div>',
    htmlForQuestionAttributeHelper =
      '<div class="question-attribution-helper-label" contenteditable="false">Attribution</div>';

  const node = htmlToPMNode(
      parser,
      `<div>${htmlForQuestionHelper + htmlForQuestionText + htmlForQuestionAttributeHelper}</div>`
    ),
    json = node && node.toJSON();

  return {
    attrs: {
      id: storyElement.id,
      "client-id": storyElement["client-id"],
      "card-id": storyElement["card-id"],
      type: storyElement.type,
      subtype: storyElement.subtype
    },
    type: ElementType.Question,
    content: json && json.content
  };
}

function newStoryElementAnswerNode(
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement
): NewStoryElement {
  /* ProseMirror, for some reason, requires a wrapper element.
  It can be anything, even something like <dummy></dummy> */

  const htmlForAnswerText = storyElement.text
      ? `<div class="answer-text">${storyElement.text}</div>`
      : `<div class="answer-text"><p></p></div>`,
    htmlForAnswerHelper = '<div class="answer-helper-label" contenteditable="false">Answer</div>',
    htmlForAnswerAttributeHelper =
      '<div class="answer-attribution-helper-label" contenteditable="false">Attribution</div>';

  const node = htmlToPMNode(
      parser,
      `<div>${htmlForAnswerHelper + htmlForAnswerText + htmlForAnswerAttributeHelper}</div>`
    ),
    json = node && node.toJSON();

  return {
    attrs: {
      id: storyElement.id,
      "client-id": storyElement["client-id"],
      "card-id": storyElement["card-id"],
      type: storyElement.type,
      subtype: storyElement.subtype
    },
    type: ElementType.Answer,
    content: json && json.content
  };
}

function newStoryElementTitleNode(
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement
): NewStoryElement {
  const html =
    storyElement && storyElement.text
      ? `<div><div class="title-text">${storyElement.text}</div></div>`
      : `<div><div class="title-text"></div></div>`;
  const node = htmlToPMNode(parser, html),
    json = node && node.toJSON();

  return {
    attrs: {
      id: storyElement.id,
      "client-id": storyElement["client-id"],
      "card-id": storyElement["card-id"],
      type: storyElement.type,
      subtype: storyElement.subtype
    },
    type: ElementType.Title,
    subtype: storyElement.subtype,
    content: json && json.content
  };
}

function newStoryElementBlurbNode(
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement
): NewStoryElement {
  const html =
    storyElement && storyElement.text ? `<div>${storyElement.text}</div>` : "<div><blockquote></blockquote></div>";
  const node = htmlToPMNode(parser, html),
    json = node && node.toJSON();

  return {
    attrs: {
      id: storyElement.id,
      "client-id": storyElement["client-id"],
      "card-id": storyElement["card-id"],
      type: storyElement.type,
      subtype: storyElement.subtype
    },
    type: ElementType.Blurb,
    subtype: storyElement.subtype,
    content: json && json.content
  };
}

function newStoryElementQuoteNode(
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement
): NewStoryElement {
  const node = htmlToPMNode(
    parser,
    (storyElement && storyElement.text) || '<div><blockquote></blockquote><span class="attribution"></span></div>'
  );
  const json = node && node.toJSON();
  const jsonContent = json && json.content;

  return {
    type: ElementType.Quote,
    attrs: {
      id: storyElement.id,
      "client-id": storyElement["client-id"],
      "card-id": storyElement["card-id"],
      type: storyElement.type,
      subtype: storyElement.subtype
    },
    content: [
      jsonContent[0],
      {
        type: ElementType.QuoteHelper
      },
      {
        attrs: {
          id: storyElement.id
        },
        content: jsonContent[1] && jsonContent.content,
        type: ElementType.QuoteAttribution
      }
    ]
  };
}

function newStoryElementCtaElementNode(storyElement: StoryElement): NewStoryElement {
  return buildCtaToPMNode(storyElement);
}

function newStoryElementBigfactNode(
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement
): NewStoryElement {
  const node = htmlToPMNode(
    parser,
    (storyElement && storyElement.text) ||
      '<div><div class="bigfact-title"></div><div class="bigfact-description"></div></div>'
  );
  const json = node && node.toJSON();
  const jsonContent = json && json.content;

  return {
    type: ElementType.Bigfact,
    attrs: {
      id: storyElement.id,
      "client-id": storyElement["client-id"],
      "card-id": storyElement["card-id"],
      type: storyElement.type,
      subtype: storyElement.subtype
    },
    content: [
      jsonContent[0],
      {
        type: ElementType.BigfactHelper
      },
      jsonContent[1]
    ]
  };
}

function newStoryElementQAndANode(
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement
): NewStoryElement {
  const htmlForQuestion =
      storyElement && storyElement.metadata.question
        ? `<div class="q-and-a-question">${storyElement.metadata.question}</div>`
        : '<div class="q-and-a-question"><p></p></div>',
    htmlForAnswer =
      storyElement && storyElement.metadata.answer
        ? `<div class="q-and-a-answer">${storyElement.metadata.answer}</div>`
        : '<div class="q-and-a-answer"><p></p></div>';

  const nodeQuestion = htmlToPMNode(parser, htmlForQuestion);
  const nodeAnswer = htmlToPMNode(parser, htmlForAnswer);

  const jsonQuestion = nodeQuestion && nodeQuestion.toJSON();
  const jsonAnswer = nodeAnswer && nodeAnswer.toJSON();

  return {
    attrs: {
      id: storyElement.id,
      "client-id": storyElement["client-id"],
      "card-id": storyElement["card-id"],
      type: storyElement.type,
      subtype: storyElement.subtype
    },
    type: ElementType.QandA,
    subtype: storyElement.subtype,
    content: [
      { type: ElementType.QandAQuestionHelper },
      {
        type: ElementType.QandAQuestion,
        content: jsonQuestion && jsonQuestion.content
      },
      {
        type: ElementType.QandAQuestionAttributionHelper,
        attrs: {
          id: storyElement && storyElement.id,
          helperName: "question"
        }
      },
      { type: ElementType.QandAAnswerHelper },
      {
        type: ElementType.QandAAnswer,
        content: jsonAnswer && jsonAnswer.content
      },
      {
        type: ElementType.QandAAnswerAttributionHelper,
        attrs: {
          id: storyElement && storyElement.id,
          helperName: "answer"
        }
      }
    ]
  };
}

function newStoryElementOthersNode(
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement
): NewStoryElement {
  return {
    attrs: {
      id: storyElement.id,
      "client-id": storyElement["client-id"],
      "card-id": storyElement["card-id"],
      type: storyElement.type,
      subtype: storyElement.subtype
    },
    type: ElementType.StoryElementOthers,
    subtype: storyElement.subtype
  };
}

export function newStoryElementNode(storyElement: StoryElement | CompositeStoryElement | ChildStoryElement) {
  if (storyElement.type === "text" && (storyElement.subtype === null || storyElement.subtype === "summary")) {
    return newStoryElementTextNode(storyElement);
  } else if (storyElement.type === "text" && storyElement.subtype === "question") {
    return newStoryElementQuestionNode(storyElement);
  } else if (storyElement.type === "text" && storyElement.subtype === "answer") {
    return newStoryElementAnswerNode(storyElement);
  } else if (
    storyElement.type === "text" &&
    (storyElement.subtype === "quote" || storyElement.subtype === "blockquote")
  ) {
    return newStoryElementQuoteNode(storyElement);
  } else if (storyElement.type === "text" && storyElement.subtype === "q-and-a") {
    return newStoryElementQAndANode(storyElement);
  } else if (storyElement.type === "text" && storyElement.subtype === StoryElementTextSubtype.Cta) {
    return newStoryElementCtaElementNode(storyElement as StoryElement);
  } else if (storyElement.type === "text" && storyElement.subtype === "bigfact") {
    return newStoryElementBigfactNode(storyElement);
  } else if (storyElement.type === "text" && storyElement.subtype === "blurb") {
    return newStoryElementBlurbNode(storyElement as StoryElement);
  } else if (storyElement.type === "title") {
    return newStoryElementTitleNode(storyElement as StoryElement);
  } else return newStoryElementOthersNode(storyElement);
}

export function newCardNode(
  card: Card,
  storyElements: Array<StoryElement | CompositeStoryElement | ChildStoryElement> = []
) {
  return {
    attrs: {
      id: card["content-id"],
      "content-version-id": card["content-version-id"],
      "client-id": card["client-id"]
    },
    type: "card",
    content: storyElements.map((storyElement) => newStoryElementNode(storyElement))
  };
}
