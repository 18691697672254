/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Button from "components/button/button";
import { t } from "i18n";
import { CollectionItemWrapper, AssociatedMetadata, ContentType } from "api/story-collection";
import styles from "./update-manage.module.css";

interface Props {
  item: CollectionItemWrapper;
  updatePageViewsThreshold: (index: number) => void;
  index: number;
  cancelCardExpanded: (index: number, key: string, value: any) => void;
  threshold: number | null;
  stagingAssociatedMetadata: AssociatedMetadata;
  updateAssociatedMetadata: (index: number, key: string, associatedMetadata: AssociatedMetadata) => void;
}

const UpdateManage: React.SFC<Props> = ({
  item,
  updatePageViewsThreshold,
  index,
  cancelCardExpanded,
  threshold,
  stagingAssociatedMetadata,
  updateAssociatedMetadata
}) => (
  <div className={styles["collection-filters-buttons"]}>
    <div className={styles["collection-filters-action-button"]}>
      <Button
        type="secondary"
        onClick={() => {
          if (item.type === ContentType.Story) {
            cancelCardExpanded(index, "threshold", item.threshold ? item.threshold : null);
          }
          cancelCardExpanded(
            index,
            "associated-metadata",
            item["associated-metadata"] ? item["associated-metadata"] : {}
          );
        }}>
        {t("collections.cancel")}
      </Button>
    </div>
    <div className={styles["collection-filters-action-button"]}>
      <Button
        type="primary"
        onClick={() => {
          if (item.type === ContentType.Story) {
            updatePageViewsThreshold(index);
          }
          updateAssociatedMetadata(index, "associated-metadata", stagingAssociatedMetadata);
        }}>
        {t("collections.update")}
      </Button>
    </div>
  </div>
);

export default UpdateManage;
