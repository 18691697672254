/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import styles from "./sidebar.module.css";

const cx = classnames.bind(styles);

interface SidebarProps {
  isBannerPresent?: boolean;
}

const Sidebar: React.SFC<SidebarProps> = ({ children, isBannerPresent }) => {
  const classes = cx("sidebar", { "with-banner": isBannerPresent });

  return (
    <aside className={classes}>
      <div className={styles["sidebar-content"]}>{children}</div>
    </aside>
  );
};

export default Sidebar;
