/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect } from "react";
import classnames from "classnames/bind";
import { AnyAction, compose } from "redux";
import wrapPage from "containers/page/page";
import { connect } from "react-redux";
import { match as Match } from "react-router";
import { PartialAppState, getWebhookLogs, EventDetails } from "store/webhook/webhook";
import { ThunkDispatch } from "redux-thunk";

import ReactTable from "react-table";
import { t } from "i18n";
import ContentHeader from "pages/settings/components/content-header/content-header";
import GoBack from "components/go-back/go-back";

import Spinner from "components/spinner/spinner";
import NoContentCard from "components/no-content-card/no-content-card";
import File from "components/icons/file";

import styles from "./logs.module.css";
import { PaginationOptions, WebhookLog } from "api/webhooks";
import { formattedDate } from "utils";
import { WEBHOOKS_PATH } from "../routes";
import { navigate } from "utils/routes.utils";
import TablePagination from "components/table-pagination/table-pagination";

const cx = classnames.bind(styles);

interface OwnProps {
  match: Match<{ id: string }>;
}
interface StateProps {
  webhookId: number;
  logs: WebhookLog[];
  pagination: PaginationOptions;
  publisherTriggerEvents: EventDetails[];
  areLogsLoading: boolean;
}
interface DispatchProps {
  loadLogs(webhookId: number, offset: number): void;
  goBack: VoidFunction;
}

type WebhooksProps = OwnProps & StateProps & DispatchProps;

function WebhookLogs({
  logs,
  webhookId,
  pagination,
  loadLogs,
  publisherTriggerEvents,
  areLogsLoading,
  goBack
}: WebhooksProps) {
  useEffect(() => {
    loadLogs(webhookId, 0);
  }, [webhookId, loadLogs]);

  const currentPage = Math.floor(pagination.offset! / pagination.limit!) + 1;

  return (
    <section>
      <main>
        <GoBack message="Back" classname={styles["logs-go-back"]} clickAction={goBack} />
        <ContentHeader
          title={t("settings.webhooks.logs.title")}
          description={t("settings.webhooks.logs.description")}
        />
        {logs.length ? (
          <div className={styles["webhook-logs-table-container"]} data-test-id="webhook-logs-table-container">
            <div className={styles["pagination-top"]} data-test-id="webhook-logs-pagination-top">
              <TablePagination
                specifiedPage={currentPage}
                onPageChange={(pageNumber) => {
                  loadLogs(webhookId, (pageNumber - 1) * pagination.limit!);
                }}
                totalCount={pagination.total!}
                perPage={pagination.limit!}
                classname={styles["webhooks-logs-pagination"]}
              />
            </div>
            <div data-test-id="webhook-logs-react-table">
              <ReactTable
                manual
                showPaginationTop={false}
                showPaginationBottom={false}
                showPagination={false}
                loading={false}
                showPageSizeOptions={false}
                sortable={false}
                pageSize={0}
                data={logs}
                columns={[
                  {
                    Header: t("settings.webhooks.logs.table_header.status"),
                    accessor: "status",
                    Cell: ({ value, original }) => {
                      const statusClasses = cx("log-status", {
                        "is-success": original.success
                      });
                      return (
                        <span className={styles["log-status-container"]} data-test-id="log-status-container">
                          <span className={statusClasses}>{value}</span>
                        </span>
                      );
                    }
                  },
                  {
                    Header: t("settings.webhooks.logs.table_header.id"),
                    accessor: "id",
                    Cell: ({ value }) => (
                      <span className={styles["log-id-container"]} data-test-id="log-id-container">
                        <span className={styles["log-id"]}>{value}</span>
                      </span>
                    )
                  },
                  {
                    Header: t("settings.webhooks.logs.table_header.resource_id"),
                    accessor: "resource-id",
                    Cell: ({ value }) => (
                      <span className={styles["log-resource-id-container"]} data-test-id="log-resource-id-container">
                        <span className={styles["log-resource-id"]}>{value}</span>
                      </span>
                    )
                  },
                  {
                    Header: t("settings.webhooks.logs.table_header.title"),
                    accessor: "title",
                    Cell: ({ value }) => (
                      <span className={styles["log-title-container"]} data-test-id="log-title-container">
                        <span className={styles["log-title"]}>{value}</span>
                      </span>
                    )
                  },
                  {
                    Header: t("settings.webhooks.logs.table_header.event"),
                    accessor: "event-id",
                    Cell: ({ value }) => (
                      <span className={styles["log-event-container"]} data-test-id="log-event-container">
                        <span className={styles["log-event"]}>
                          {t(
                            `settings.webhooks.inspector.events.${
                              publisherTriggerEvents.find((event) => event.id === value)!.slug
                            }`
                          )}
                        </span>
                      </span>
                    )
                  },
                  {
                    Header: t("settings.webhooks.logs.table_header.timestamp"),
                    accessor: "created-at",
                    Cell: ({ value }) => (
                      <span className={styles["log-timestamp-container"]} data-test-id="log-timestamp-container">
                        <span className={styles["log-timestamp"]}>{formattedDate(value)}</span>
                      </span>
                    )
                  }
                ]}
                className={styles["webhook-logs-table"]}
                LoadingComponent={() =>
                  areLogsLoading ? <Spinner message={t("settings.webhooks.logs.loading")} /> : null
                }
              />
            </div>
          </div>
        ) : (
          <NoContentCard>
            <File />
            <div className="no-content-message">{t("settings.webhooks.logs.no_logs")}</div>
          </NoContentCard>
        )}
      </main>
    </section>
  );
}

function mapStateToProps(state: PartialAppState, ownProps: OwnProps): StateProps {
  return {
    logs: state.webhooks.logData.logs,
    pagination: state.webhooks.logData.pagination,
    webhookId: parseInt(ownProps.match.params.id),
    publisherTriggerEvents: state.config["trigger-events"] || [],
    areLogsLoading: state.webhooks.app.loadingLogs
  };
}

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    loadLogs: (webhookId, offset) => dispatch(getWebhookLogs(webhookId, offset)),
    goBack: () => dispatch(navigate(WEBHOOKS_PATH))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), wrapPage({}))(WebhookLogs);
export { WebhookLogs };
