/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import Badge, { BadgeValue } from "components/badge/badge";
import Clock from "components/icons/clock";
import styles from "./menu.module.css";
import ToolTip from "components/tooltip/tooltip";
import { NavLink } from "react-router-dom";

const cx = classnames.bind(styles);

interface Props<ItemType> {
  title?: string;
  items: Array<ItemType>;
  getItemLabel: (item: ItemType) => string;
  onItemClick?: (item: ItemType) => void;
  routeTo?: (item: ItemType) => string;
  isItemSelected?: (item: ItemType) => boolean;
  getBadgeValue?: (item: ItemType) => BadgeValue;
  isScheduled?: (item: ItemType) => boolean;
  backButtonLabel?: string;
  backButtonAction?: () => void;
  truncateText?: boolean;
  classname?: string;
}

/* Unfortunately, stateless functional components cannot be typed using React.SFP,
 because the definition requires a concrete type.
 It can be fixed by duplicating the type definition and adding a generic type,
 but converting into a class like below is another option.
*/

class Menu<ItemType> extends React.Component<Props<ItemType>> {
  render() {
    const {
      title,
      items,
      getItemLabel,
      onItemClick,
      routeTo,
      isItemSelected,
      getBadgeValue,
      isScheduled,
      truncateText,
      classname
    } = this.props;
    const menuItems = items.map((item) => {
      const classes = cx("menu-item", { "is-selected": isItemSelected && isItemSelected(item) });
      const muteBadge = isItemSelected && !isItemSelected(item);
      const itemLabel = getItemLabel(item);
      const badgeValue = getBadgeValue && getBadgeValue(item);
      let renderTooltip = false;

      if (truncateText && itemLabel.length >= 20) {
        renderTooltip = true;
      }

      const content = (
        <>
          <span className={cx("menu-item-label", { "is-truncated": truncateText })}>{itemLabel}</span>
          <Badge value={badgeValue} muted={muteBadge} />
          {isScheduled && isScheduled(item) && (
            <span className={styles["schedule-icon"]}>
              <Clock />
            </span>
          )}
          {renderTooltip && (
            <ToolTip
              offset={{ top: 60, left: 100 }}
              id={`menu-item-${itemLabel}`}
              place="right"
              effect="solid"
              value={itemLabel}
            />
          )}
        </>
      );

      return (
        <li
          key={`menu-item-${itemLabel}`}
          className={classes}
          onClick={() => onItemClick && onItemClick(item)}
          data-test-id="sidebar-menu-group-item"
          {...(renderTooltip && { "data-for": `menu-item-${itemLabel}` })}
          {...(renderTooltip && { "data-tip": true })}>
          {routeTo && routeTo(item) ? (
            <NavLink className={styles["menu-item-link"]} to={routeTo(item)}>
              {content}
            </NavLink>
          ) : (
            content
          )}
        </li>
      );
    });

    return (
      <React.Fragment>
        {title && (
          <h3 className={cx("menu-title", "title", classname)} data-test-id="side-bar-menu-title">
            {title}
          </h3>
        )}
        <ul className={cx("menu", "menu-list", classname)} data-test-id="side-bar-menu-list">
          {menuItems}
        </ul>
      </React.Fragment>
    );
  }
}

export default Menu;
