/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export function convertStringToInteger(value: string) {
  return value.length === 0 ? null : parseInt(value);
}

export function convertNumberToString(value: number | null) {
  if (value) {
    return value.toString();
  } else {
    return "";
  }
}
