/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const ATTRIBUTES_INDEX_PATH = "/manage/attributes";
export const ATTRIBUTES_NEW_PATH = "/manage/attributes/new";
export const ATTRIBUTE_EDIT_PATH = "/manage/attributes/:name";
