/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { useId } from "react-id-generator";
import ForgotPassword from "./forgot-password";
import { t } from "i18n";
import { ThunkDispatch } from "redux-thunk";
import { parse } from "query-string";

import { LocationState } from "history";
import styles from "./login.module.css";
import Button from "components/button/button";
import { Publisher, getRouteData, RouteDataResponse } from "api/route-data/route-data";
import { initialize as initI18N } from "../../i18n";
import { I18nextProvider } from "react-i18next";
import { LOGIN_PATH } from "./routes";
import { PartialAppState } from "./state";
import {
  sendEmailForResetPassword,
  resetNewPassword,
  signIn,
  updateLoginData,
  redirectToForgotPassword,
  redirectToLogin,
  getLoginData,
  setEmailToResetPassword,
  updateResetPasswordFields,
  cancelFormSignIn,
  onRouteChanged,
  showLoginError,
  validateSession
} from "./async-action-creators";
import LeftArrow from "components/icons/left-arrow";
import Snackbar from "components/snackbar/snackbar";
import LoginFields from "./login-fields";
import LoginSocialAndSignInActions from "./login-social-and-signin-actions";
import ResetPasswordFields from "./reset-password-fields";
import EnableTwoFactorAuth from "./enable-two-factor-auth";
import TwoFactorAuth from "./two-factor-auth";
import WithGAEvent from "helpers/ga-analytics/with-ga-event";
import { setSessionRedirectUri, removeSessionRedirectUri } from "./utils";
import { navigate } from "utils/routes.utils";
import { WORKSPACE_PATH } from "pages/workspace/routes";

interface DispatchProps {
  getLoginData: (data: RouteDataResponse) => void;
  redirectToForgotPassword: VoidFunction;
  redirectToLogin: VoidFunction;
  onChange: (key: string, value: string) => void;
  sendEmailForResetPassword: VoidFunction;
  setEmailToResetPassword: (email: string) => void;
  onChangeResetPassword: (key: string, value: string) => void;
  showLoginError: (hash: string) => void;
  resetNewPassword: VoidFunction;
  cancelFormSignIn: VoidFunction;
  signIn: VoidFunction;
  onRouteChanged: VoidFunction;
  validateSession: (integrationId: number) => void;
  postTFASetupNavigation: VoidFunction;
}
interface StateProps {
  publisher: Publisher;
  username: string;
  password: string;
  forgotPassword: boolean;
  resetPassword: boolean;
  emailToResetPassword: string;
  error: Error;
  isSignInDisabled: boolean;
  isBridgekeeperIntegrationEnabled: boolean;
  ssoIntegrationId: number;
}
interface OwnProps {
  forgotPassword: boolean;
  resetPassword: boolean;
  enableTwoFactorAuth: boolean;
  twoFactorAuth: boolean;
  location: LocationState;
  title: string;
  hash?: string;
}

type LoginWrapperProps = DispatchProps & StateProps & OwnProps;
type LoginProps = Omit<LoginWrapperProps, "ssoIntegrationId">;

export const Login: React.SFC<LoginProps> = ({
  emailToResetPassword,
  forgotPassword,
  error,
  setEmailToResetPassword,
  username,
  password,
  onChange,
  onChangeResetPassword,
  resetPassword,
  resetNewPassword,
  signIn,
  cancelFormSignIn,
  isSignInDisabled,
  redirectToLogin,
  redirectToForgotPassword,
  sendEmailForResetPassword,
  publisher,
  isBridgekeeperIntegrationEnabled
}) => {
  const [loginFormId] = useId(1, "login-form-");
  return (
    <React.Fragment>
      <div className={styles["login-background-image"]}>
        <div className={styles["quote-box"]}>
          <h2 className={styles["title"]}>{t("loginPage.title")}</h2>
          <p className={styles["description"]}>{t("loginPage.description")}</p>
        </div>
        <div className={styles["people-image-container"]} />
      </div>
      <div className={styles["login-wrapper"]}>
        <div className={styles["login-container"]}>
          <div className={styles["publisher-logo"]} />
          <div className={styles["login-form"]}>
            {forgotPassword && (
              <ForgotPassword
                emailToResetPassword={emailToResetPassword}
                error={error}
                setEmailToResetPassword={setEmailToResetPassword}
              />
            )}
            {resetPassword && <ResetPasswordFields error={error} onChangeResetPassword={onChangeResetPassword} />}
            {!forgotPassword && !resetPassword && (
              <LoginFields
                formId={loginFormId}
                username={username}
                password={password}
                submitData={signIn}
                isSignInDisabled={isSignInDisabled}
                onChange={onChange}
                error={error}
              />
            )}
          </div>
          <div className={styles["action-container"]}>
            {forgotPassword && (
              <WithGAEvent label="forgot-password-send">
                <Button testId="send-button" type="primary" onClick={() => sendEmailForResetPassword()}>
                  {t("loginPage.send")}
                </Button>
              </WithGAEvent>
            )}
            {resetPassword && (
              <WithGAEvent label="forgot-password-send">
                <Button type="primary" onClick={() => resetNewPassword()}>
                  {t("loginPage.update")}
                </Button>
              </WithGAEvent>
            )}
            {!forgotPassword && !resetPassword && (
              <LoginSocialAndSignInActions
                formId={loginFormId}
                submitData={signIn}
                cancelFormSignIn={cancelFormSignIn}
                isSignInDisabled={isSignInDisabled}
                isSocialLoginDisabled={publisher["hide-social-login"]}
                enabledSocialLoginProviders={publisher["enable-social-login-providers"]}
                isBridgekeeperIntegrationEnabled={isBridgekeeperIntegrationEnabled}
              />
            )}
          </div>
          {forgotPassword && (
            <WithGAEvent label="forgot-password-back-to-login">
              <div onClick={() => redirectToLogin()} className={styles["action-link"]} data-test-id="back-to-login">
                <span className={styles["action-link--icon"]}>
                  <LeftArrow color="#4860bc" width="16" height="16" />
                </span>
                {t("loginPage.back_to_login")}
              </div>
            </WithGAEvent>
          )}
          {!forgotPassword && !resetPassword && (
            <WithGAEvent label="forgot-password">
              <div
                onClick={() => redirectToForgotPassword()}
                className={styles["action-link"]}
                data-test-id="forgot-password">
                {t("loginPage.forgot_password")}
              </div>
            </WithGAEvent>
          )}
        </div>
      </div>
      <Snackbar />
    </React.Fragment>
  );
};

export class LoginWrapper extends React.Component<LoginWrapperProps> {
  async componentDidMount() {
    const searchParams = parse(this.props.location.search);
    if (searchParams["redirect_uri"]) {
      setSessionRedirectUri(searchParams["redirect_uri"] as string);
    } else {
      removeSessionRedirectUri();
    }
    const routeData = await getRouteData(LOGIN_PATH);
    this.props.getLoginData(routeData);
    this.props.hash === "#no-roles-assigned" || this.props.validateSession(this.props.ssoIntegrationId);
    this.props.hash &&
      setTimeout(() => {
        this.props.showLoginError(this.props.hash!);
      }, 0);
  }

  componentDidUpdate(prevProps: LoginWrapperProps) {
    if (document.documentElement && this.props.publisher.i18n) {
      document.documentElement.lang = this.props.publisher.i18n.locale;
      document.documentElement.dir = this.props.publisher.i18n.dir || "ltr";
      document.title = t(`meta_title.${this.props.title}`);
    }
    if (this.props.location !== prevProps.location) {
      this.props.onRouteChanged();
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.publisher.i18n && (
          <I18nextProvider i18n={initI18N(this.props.publisher.i18n.locale)}>
            <React.Fragment>
              {this.props.enableTwoFactorAuth && (
                <div className={styles["tfa-setup-content"]}>
                  <EnableTwoFactorAuth postEnableHandler={this.props.postTFASetupNavigation} />
                </div>
              )}
              {this.props.twoFactorAuth && <TwoFactorAuth />}
              {!this.props.enableTwoFactorAuth && !this.props.twoFactorAuth && <Login {...this.props} />}
            </React.Fragment>
          </I18nextProvider>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState, ownProps: OwnProps) => {
  return {
    username: state.login.app.username,
    password: state.login.app.password,
    publisher: state.login.config.publisher,
    forgotPassword: ownProps.forgotPassword,
    resetPassword: ownProps.resetPassword,
    emailToResetPassword: state.login.app.emailToResetPassword,
    error: state.login.ui.error,
    isSignInDisabled: state.login.ui.isSignInDisabled,
    title: ownProps.title,
    isBridgekeeperIntegrationEnabled: state.login.features.isBridgekeeperIntegrationEnabled,
    hash: ownProps.location.hash,
    ssoIntegrationId:
      state.login.config.bridgekeeper &&
      state.login.config.bridgekeeper.sso &&
      state.login.config.bridgekeeper.sso["integration-id"]
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>, ownProps: OwnProps): DispatchProps => {
  return {
    onChange: (key: string, text: string) => dispatch(updateLoginData(key, text)),
    redirectToForgotPassword: () => dispatch(redirectToForgotPassword()),
    redirectToLogin: () => dispatch(redirectToLogin()),
    getLoginData: (data: RouteDataResponse) => dispatch(getLoginData(data)),
    sendEmailForResetPassword: () => dispatch(sendEmailForResetPassword()),
    setEmailToResetPassword: (email: string) => dispatch(setEmailToResetPassword(email)),
    onChangeResetPassword: (key: string, value: string) => dispatch(updateResetPasswordFields(key, value)),
    resetNewPassword: () => dispatch(resetNewPassword(ownProps.location.search)),
    cancelFormSignIn: () => dispatch(cancelFormSignIn()),
    signIn: () => dispatch(signIn()),
    showLoginError: (hash: string) => dispatch(showLoginError(hash)),
    onRouteChanged: () => dispatch(onRouteChanged()),
    validateSession: (ssoIntegrationId) => dispatch(validateSession(ssoIntegrationId)),
    postTFASetupNavigation: () => dispatch(navigate(WORKSPACE_PATH))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginWrapper);
