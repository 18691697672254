/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { TaskId } from "api/primitive-types";
import { Task } from "api/tasks";

export interface TaskUIState {
  isLoading: boolean;
  error: Error | null;
}

export interface TaskState {
  taskId: TaskId | null;
  task: Task | null;
}

export const INITIAL_STORY_TASK_STATE: TaskState = {
  taskId: null,
  task: null
};

export const INITIAL_TASK_UI_STATE: TaskUIState = {
  isLoading: true,
  error: null
};
