/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { BridgekeeperUser } from "api/users";
import { LoaderState, INITIAL_LOADER_STATE } from "behaviors/loader/state";
import { Features, Config } from "api/route-data/route-data";
import { DateFilter } from "components/date-filter/date-filter";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { t } from "i18n";
import { notificationSuccess, notificationInfo, notificationError } from "containers/page/actions";
import { NotificationMessage } from "swallow";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import debounce from "p-debounce";
import { fetchConsumers, deleteConsumerAPI } from "../../swallow/api";
import { subDays, subMonths } from "date-fns";
import { TokenApiFn } from "swallow/middleware";

export type ValidationError = {
  name?: string[];
  email?: string[];
};

export interface SelectedConsumer extends BridgekeeperUser {
  username: string;
  metadata: { [key: string]: string };
  settings: {
    bold?: boolean;
    "dark-mode"?: boolean;
  };
  social: {
    [key: string]: string;
  };
}

export interface ConsumersPagination {
  total: number;
  offset: number;
  limit: number;
}

export enum InspectorType {
  Add = "add"
}

export interface FilterSet {
  "created-at": DateFilter;
}
export interface State {
  consumers: SelectedConsumer[];
  consumersPagination: ConsumersPagination;
  consumersPerPage: number;
  stagingFilterSet: FilterSet;
  currentFilterSet: FilterSet;
  ui: {
    searchTerm: string;
    currentPage: number;
    errors: ValidationError | null;
    main: LoaderState;
    loadingAvatar: {};
  };
}

export interface PartialAppState {
  consumers: State;
  features: Features;
  config: Config;
  stagingFilterSet: FilterSet;
  currentFilterSet: FilterSet;
  viewport: {
    isDesktopSizeViewport: boolean;
  };
}

export interface ConsumersPagination {
  total: number;
  offset: number;
  limit: number;
}

const initialFilterSet = {
  "created-at": {
    type: null,
    from: null,
    to: null
  }
};

export const INITIAL_CONSUMER_STATE = {
  consumers: [],
  consumersPagination: {
    total: 0,
    offset: 0,
    limit: 20
  },
  consumersPerPage: 20,
  stagingFilterSet: initialFilterSet,
  currentFilterSet: initialFilterSet,
  ui: {
    searchTerm: "",
    currentPage: 1,
    errors: {},
    main: INITIAL_LOADER_STATE,
    inspector: INITIAL_LOADER_STATE,
    loadingAvatar: false
  }
};
interface UpdateCurrentPage {
  pageNumber: number;
}

interface UpdatePagination {
  offset: number;
}

interface SetSearchTerm {
  searchTerm: string;
}

interface UpdateStagingFilter {
  payload: {
    key: string;
    value: any;
  };
}

interface ReplaceFilter {
  filterSet: FilterSet;
}

const filterSetReducer = (filterSet, action) => {
  const { key, value } = action.payload;
  return { ...filterSet, [key]: value };
};
interface Data {
  data: SelectedConsumer[];
  "total-count": number;
}

interface SetLoading {
  isLoading: boolean;
}

const { reducer, actions } = createSlice({
  initialState: INITIAL_CONSUMER_STATE,
  name: "consumers",
  reducers: {
    loadConsumersData: (state: State, { payload }: PayloadAction<Data>) => {
      state.consumers = payload.data;
      state.consumersPagination = {
        ...state.consumersPagination,
        total: payload["total-count"]
      };
      state.ui = {
        ...state.ui,
        main: { loading: false, error: null }
      };
    },
    consumersUpdateCurrentPage: (state: State, { payload }: PayloadAction<UpdateCurrentPage>) => {
      state.ui = { ...state.ui, currentPage: payload.pageNumber, main: { loading: true, error: null } };
    },
    consumersUpdatePagination: (state: State, { payload }: PayloadAction<UpdatePagination>) => {
      state.consumersPagination = { ...state.consumersPagination, offset: payload.offset };
    },
    setSearchTerm: (state: State, { payload }: PayloadAction<SetSearchTerm>) => {
      state.ui = { ...state.ui, searchTerm: payload.searchTerm };
    },
    consumersUpdateStagingFilter: (state: State, action: PayloadAction<UpdateStagingFilter>) => {
      state.stagingFilterSet = filterSetReducer(state.stagingFilterSet, action.payload);
    },
    consumersReplaceFilter: (state: State, { payload }: PayloadAction<ReplaceFilter>) => {
      state.currentFilterSet = payload.filterSet;
    },
    consumersResetConsumersList: (state: State) => {
      state.consumers = [];
    },
    consumersResetFilter: (state: State) => {
      state.currentFilterSet = initialFilterSet;
      state.stagingFilterSet = initialFilterSet;
    },
    setConsumersLoading: (state: State, { payload }: PayloadAction<SetLoading>) => {
      state.ui = {
        ...state.ui,
        main: { ...state.ui.main, loading: payload.isLoading }
      };
    }
  }
});

export const {
  loadConsumersData,
  consumersUpdateCurrentPage,
  consumersUpdatePagination,
  setSearchTerm,
  consumersUpdateStagingFilter,
  consumersReplaceFilter,
  consumersResetConsumersList,
  consumersResetFilter,
  setConsumersLoading
} = actions;

const debouncedConsumerSearch = debounce(fetchConsumers, 250);

export function consumerReportNotificationAction(notificationMessage: NotificationMessage) {
  switch (notificationMessage.status) {
    case "success":
      return notificationSuccess(t("consumers.user_report.success"));
    case "info":
      return notificationInfo(t("consumers.user_report.in_progress"));
    case "error":
      if (notificationMessage.messageCode === "no_recipients")
        return notificationError(t("consumers.user_report.no_recipients"));
      return notificationError(t(`consumers.${notificationMessage.messageCode}`));
  }
}

const convertDateToUTC = (dateString) => Date.parse(dateString);

export const buildSearchQuery = (filterSet: FilterSet) => {
  let today = new Date().setHours(0, 0, 0, 0);
  let now = Date.now();
  let query = {};
  const datesFn = (dateFilter) => {
    switch (dateFilter.type) {
      case "today": {
        return [today, now];
      }
      case "this-week": {
        return [convertDateToUTC(subDays(today, 7)), now];
      }
      case "this-month": {
        return [convertDateToUTC(subMonths(today, 1)), now];
      }
      case "custom": {
        return [dateFilter.from, dateFilter.to];
      }
      default:
        throw new Error(t("consumers.errors.unknown_date_filter_type") + dateFilter.type);
    }
  };

  if (filterSet["created-at"]["type"]) [query["from"], query["to"]] = datesFn(filterSet["created-at"]);

  return query;
};

//Consumers listing page
export const fetchConsumersData = (tokenFn: TokenApiFn, integrationId: number, realmId: number) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  getState: () => PartialAppState
) => {
  const limit = getState().consumers.consumersPagination.limit;
  const offset = getState().consumers.consumersPagination.offset;
  const consumerSearchFn = debouncedConsumerSearch;
  const filterSet = getState().consumers.currentFilterSet;
  const query = { ...buildSearchQuery(filterSet), limit, offset };
  consumerSearchFn(tokenFn, integrationId, realmId, query).then((data) => {
    dispatch(consumersUpdateCurrentPage({ pageNumber: 1 }));
    dispatch(loadConsumersData({ data: data.users, "total-count": data["total-count"] }));
  });
};

export const deleteConsumer = (
  tokenFn: TokenApiFn,
  integrationId: number,
  realmId: number,
  consumerId: number
) => async (dispatch: ThunkDispatch<PartialAppState, any, AnyAction>) => {
  deleteConsumerAPI(tokenFn, integrationId, realmId, consumerId)
    .then((data) => {
      dispatch(notificationSuccess(t("consumers.delete.success_message")));
    })
    .catch(() => {
      dispatch(notificationError(t("consumers.delete.failure_message")));
    });
};

export const setConsumersLoadingStatus = ({ isLoading }) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>
) => {
  dispatch(setConsumersLoading({ isLoading }));
};

export const searchConsumersData = ({ tokenFn, integrationId, realmId, searchTerm }) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  getState: () => PartialAppState
) => {
  dispatch(setSearchTerm({ searchTerm }));
  const limit = getState().consumers.consumersPagination.limit;
  const consumerSearchFn = debouncedConsumerSearch;
  const params = searchTerm && searchTerm !== "" ? { limit, offset: 0, q: searchTerm } : { limit, offset: 0 };

  consumerSearchFn(tokenFn, integrationId, realmId, params).then((data) => {
    dispatch(consumersUpdateCurrentPage({ pageNumber: 1 }));
    dispatch(loadConsumersData({ data: data.users, "total-count": data["total-count"] }));
  });
};

export const getConsumersByPageAction = ({ tokenFn, integrationId, realmId, searchTerm, pageNumber }) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  getState: () => PartialAppState
) => {
  const limit = getState().consumers.consumersPagination.limit;
  const consumersPerPage = getState().consumers.consumersPerPage;

  const offset = (pageNumber - 1) * consumersPerPage;

  dispatch(consumersUpdateCurrentPage({ pageNumber }));
  dispatch(consumersUpdatePagination({ offset }));

  const consumerSearchFn = debouncedConsumerSearch;

  consumerSearchFn(tokenFn, integrationId, realmId, { limit, offset }).then((data) =>
    dispatch(loadConsumersData({ data: data.users, "total-count": data["total-count"] }))
  );
};

export default reducer;
