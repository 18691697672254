/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { batch, connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "@reduxjs/toolkit";
import { t } from "i18n";
import { formattedDate } from "utils";
import { Collection } from "api/collection-items";
import {
  PartialAppState,
  pinCollection,
  setCurrentlyDeleting,
  setShowDeleteModal,
  unpinCollection
} from "store/collection/collections-dashboard";
import { Link } from "react-router-dom";
import { COLLECTIONS_PATH } from "pages/collections/routes";
import Chip from "components/chip/chip";
import EmptyPin from "components/icons/empty-pin";
import SolidPin from "components/icons/solid-pin";
import Trash from "components/icons/trash";
import Clock from "components/icons/clock";
import Calendar from "components/icons/calendar";
import ToolTip from "components/tooltip/tooltip";
import styles from "./collection-card.module.css";

interface StateProps {
  hasCollectionEditPermission: boolean;
}

interface DispatchProps {
  onPin: (id) => void;
  onUnpin: (id) => void;
  onDelete: (id) => void;
}

interface OwnProps {
  collection: Collection;
}

type Props = StateProps & DispatchProps & OwnProps;

export const CollectionCard: React.FC<Props> = ({
  collection,
  hasCollectionEditPermission,
  onPin,
  onUnpin,
  onDelete
}) => {
  const {
    id,
    name,
    template,
    "data-source": source = "manual",
    "updated-at": updatedAt,
    "publish-at": scheduledAt,
    "is-pinned": isPinned
  } = collection;

  return (
    <div className={styles["card-container"]}>
      <div className={styles["card-header-container"]}>
        <div className={styles["card-header"]} data-test-id="card-header">
          <Link to={`${COLLECTIONS_PATH}/${id}`} className={styles["card-title"]}>
            {name}
          </Link>
          {isPinned === true && (
            <div
              className={styles["card-pin"]}
              onClick={() => onUnpin(id)}
              data-tip={true}
              data-for={"unpin-tooltip"}
              data-test-id="card-on-unpin">
              <SolidPin height={"18"} width={"18"} fill="var(--mono-4)" />
              <ToolTip
                value={t("common.unpin")}
                id={"unpin-tooltip"}
                place="bottom"
                effect="solid"
                classname={styles["card-time-tooltip"]}
              />
            </div>
          )}
          {isPinned === false && (
            <div
              className={styles["card-pin"]}
              onClick={() => onPin(id)}
              data-tip={true}
              data-for={"pin-tooltip"}
              data-test-id="card-on-pin">
              <EmptyPin height={"18"} width={"18"} fill="var(--mono-4)" />
              <ToolTip
                value={t("common.pin")}
                id={"pin-tooltip"}
                place="bottom"
                effect="solid"
                classname={styles["card-time-tooltip"]}
              />
            </div>
          )}
          {hasCollectionEditPermission && (
            <div
              className={styles["card-delete"]}
              onClick={() => onDelete(id)}
              data-tip={true}
              data-for={"delete-tooltip"}
              data-test-id="card-delete">
              <Trash height={18} width={18} color="var(--mono-4)" />
              <ToolTip
                value={t("common.delete")}
                id={"delete-tooltip"}
                place="bottom"
                effect="solid"
                classname={styles["card-time-tooltip"]}
              />
            </div>
          )}
        </div>
        <Chip classname={styles["card-info"]} value={source} invert={true} />
        <Chip classname={styles["card-info"]} value={`TEMPLATE: ${template}`} />
      </div>
      <div className={styles["card-time-container"]}>
        {updatedAt && (
          <>
            <div className={styles["card-time-wrapper"]} data-tip={true} data-for={"updated-time-tooltip"}>
              <Clock />
              <time className={styles["card-date"]} data-test-id="updated-card-date">
                {formattedDate(updatedAt)}
              </time>
            </div>
            <ToolTip
              value={t("common.last-updated")}
              id={"updated-time-tooltip"}
              place="bottom"
              effect="solid"
              classname={styles["card-time-tooltip"]}
            />
          </>
        )}
        {scheduledAt && (
          <>
            <div className={styles["card-time-wrapper"]} data-tip={true} data-for={"scheduled-time-tooltip"}>
              <Calendar width={16} height={16} />
              <time className={styles["card-date"]} data-test-id="scheduled-card-date">
                {formattedDate(scheduledAt)}
              </time>
            </div>
            <ToolTip
              value={t("common.scheduled")}
              id={"scheduled-time-tooltip"}
              place="bottom"
              effect="solid"
              classname={styles["card-time-tooltip"]}
            />
          </>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state: PartialAppState): StateProps {
  return {
    hasCollectionEditPermission: state.features.hasCollectionEditPermission
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps {
  return {
    onPin: (id) => dispatch(pinCollection(id)),
    onUnpin: (id) => dispatch(unpinCollection(id)),
    onDelete: (id) =>
      batch(() => {
        dispatch(setCurrentlyDeleting(id));
        dispatch(setShowDeleteModal(true));
      })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CollectionCard);
