/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import { get } from "lodash";

import Spinner from "components/spinner/spinner";
import {
  addPublisherLogoAction,
  addPublisherFaviconAction,
  updateGeneralSettingsAction
} from "./async-action-creators";
import TextField from "components/text-field/text-field";
import TextArea from "components/text-area/text-area";
import Select from "components/select/select";
import { GeneralSettings } from "api/settings";
import { PartialAppState } from "../../initial-state";
import Button from "components/button/button";
import AddNewFile from "components/add-new-file/add-new-file";
import FieldLabel from "components/field-label/field-label";
import styles from "./general.module.css";
import { notificationError } from "action-creators/notification";
import { MimeType } from "utils/file.utils";

const ANDROID_NOTIFICATION_STYLE = ["individual", "roll-up"];

interface StateProps {
  generalSettings: GeneralSettings | null;
  imageUploading: boolean;
  imageError: string;
}

interface DispatchProps {
  updateSettings: (key: string, changes?: object | string | null) => void;
  addPublisherLogo: (file: File) => void;
  addPublisherFavicon: (file: File) => void;
  uploadError: (error: string) => void;
}

type Props = StateProps & DispatchProps;

class General extends React.Component<Props> {
  render() {
    const { generalSettings, updateSettings, imageUploading, imageError, uploadError } = this.props;
    return (
      <React.Fragment>
        <TextField
          placeholder={t("settings.generalSettings.fields.publisher_title")}
          label={t("settings.generalSettings.fields.publisher_title")}
          value={(generalSettings && generalSettings["title"]) || ""}
          onChange={(value) => updateSettings("title", value)}
        />
        <TextField
          placeholder={t("settings.generalSettings.fields.description")}
          label={t("settings.generalSettings.fields.description")}
          value={(generalSettings && generalSettings["description"]) || ""}
          onChange={(value) => updateSettings("description", value)}
        />
        <TextField
          placeholder={t("settings.generalSettings.fields.copyright")}
          label={t("settings.generalSettings.fields.copyright")}
          value={(generalSettings && generalSettings["copyright"]) || ""}
          onChange={(value) => updateSettings("copyright", value)}
        />
        <Select
          label={t("settings.generalSettings.fields.android_notification_style")}
          value={(generalSettings && [generalSettings["android-notification-style"]]) || []}
          options={ANDROID_NOTIFICATION_STYLE}
          getOptionLabel={(item) => item}
          getOptionValue={(item) => item}
          isSearchable={false}
          onChange={(value) => updateSettings("android-notification-style", value)}
        />
        <TextField
          placeholder={t("settings.generalSettings.fields.instant_articles_ad_autoplaced")}
          label={t("settings.generalSettings.fields.instant_articles_ad_autoplaced")}
          value={(generalSettings && generalSettings["instant-articles-autoplaced-ad"]) || ""}
          onChange={(value) => updateSettings("instant-articles-autoplaced-ad", value)}
        />

        <TextField
          placeholder={t("settings.generalSettings.fields.instant_articles_ad_first")}
          label={t("settings.generalSettings.fields.instant_articles_ad_first")}
          value={(generalSettings && generalSettings["instant-articles-first-ad"]) || ""}
          onChange={(value) => updateSettings("instant-articles-first-ad", value)}
        />

        <TextArea
          label={t("settings.generalSettings.fields.additional_URLs_for_sitemap")}
          placeholder={t("settings.generalSettings.fields.additional_URLs_for_sitemap")}
          value={(generalSettings && generalSettings["additional-urls-for-sitemap"]) || ""}
          onChange={(value) => updateSettings("additional-urls-for-sitemap", value)}
        />

        <div>
          <FieldLabel label={t("settings.generalSettings.fields.publisher_logo")} />
          {(imageUploading || imageError) && (
            <span className={styles["loader"]}>
              {imageError && <span className={styles["error-message"]}>{imageError}</span>}
              {imageUploading && <Spinner />}
            </span>
          )}

          {generalSettings && generalSettings["publisher-logo"] && (
            <div
              className={styles["settings-publisher-logo-container"]}
              data-test-id="settings-publisher-logo-container">
              {!imageUploading && !imageError && (
                <img
                  className={styles["settings-publisher-logo-image"]}
                  src={generalSettings["publisher-logo"].url}
                  alt={get(generalSettings, ["publisher-logo", "metadata", "file-name"])}
                />
              )}
            </div>
          )}
          <div className={styles["replace-button"]}>
            <AddNewFile
              isIcon={false}
              accept={MimeType.Images}
              onError={uploadError}
              addNewText={
                imageUploading
                  ? t("settings.generalSettings.uploading")
                  : generalSettings && generalSettings["publisher-logo"]
                  ? t("settings.generalSettings.replace")
                  : t("settings.generalSettings.upload")
              }
              addNewFile={(files: File[]): void => this.props.addPublisherLogo(files[0])}
              classnames="upload-button"
              disableDropzone={true}
            />

            {generalSettings && generalSettings["publisher-logo"] && (
              <Button testId="general-settings-remove-btn" onClick={() => updateSettings("publisher-logo", null)}>
                {t("settings.generalSettings.remove")}
              </Button>
            )}
          </div>
          <p className={styles["upload-info"]}>{t("settings.generalSettings.fields.logo_info")}</p>
        </div>

        <div className={styles["publisher-assets-container"]}>
          <FieldLabel label={t("settings.generalSettings.fields.favicon")} />
          {generalSettings && generalSettings.favicon && (
            <p className={styles["upload-info"]}>{generalSettings.favicon.filename}</p>
          )}
          <div className={styles["general-upload-button"]}>
            <AddNewFile
              isIcon={false}
              accept="image/x-icon"
              onError={uploadError}
              addNewText={
                imageUploading ? t("settings.generalSettings.uploading") : t("settings.generalSettings.upload")
              }
              addNewFile={(files: File[]) => this.props.addPublisherFavicon(files[0])}
              classnames="upload-button"
              disableDropzone={true}
            />
          </div>
          <p className={styles["upload-info"]}>{t("settings.generalSettings.fields.favicon_info")}</p>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    generalSettings: state.settingsPage.generalSettings,
    imageUploading: state.settingsPage.ui.uploading,
    imageError: state.settingsPage.ui.imageError
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    updateSettings: (key, changes) => dispatch(updateGeneralSettingsAction(key, changes)),
    addPublisherLogo: (file) => dispatch(addPublisherLogoAction(file)),
    addPublisherFavicon: (file) => dispatch(addPublisherFaviconAction(file)),
    uploadError: (error) => dispatch(notificationError(error))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(General);

export { General };
