/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";

import Spinner from "components/spinner/spinner";
import Inspector from "components/inspector/inspector";
import TextField from "components/text-field/text-field";
import { PartialAppState, TagPageInspectorType } from "../../state";
import { AnyTag } from "api/tag";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction, compose } from "redux";
import { connect } from "react-redux";
import { cancelSaveTag, saveTag, setGeneratedSlug } from "../../async-action-creators";
import { replaceCurrentTagAction } from "../../action-creators";
import { isClientValidationError } from "utils/validation.utils";
import TextArea from "components/text-area/text-area";
import { isExistingTag } from "../../utils";
import { isHTTPError } from "api/errors";

interface StateProps {
  type: TagPageInspectorType | null;
  tag: AnyTag | null;
  saveError: Error | null;
  isLoading: boolean;
  hasTagEditPermission: boolean;
  hasTagCreatePermission: boolean;
}

interface DispatchProps {
  saveTag: (tag: AnyTag) => void;
  cancelSaveTag: (tag: AnyTag) => void;
  onChange: (tag: AnyTag) => void;
  setSlug: (tag: AnyTag) => void;
}

type Props = StateProps & DispatchProps;

function nameFieldErrorMessage(saveError: Error | null) {
  if (!saveError) {
    return undefined;
  }

  if (isClientValidationError<AnyTag>(saveError)) {
    return saveError.errors.name && saveError.errors.name[0];
  }

  // TODO: Fix this hack below, by making wretch return a proper HTTPError object,
  // instead of an Error with the stringified JSON response as the message parameter.

  if (isHTTPError(saveError) && saveError.status === 409) {
    return t("tags.inspector.tag-exists");
  }

  return undefined;
}

const TagInspector: React.SFC<Props> = ({
  tag,
  onChange,
  saveTag,
  cancelSaveTag,
  type,
  saveError,
  isLoading,
  setSlug,
  hasTagEditPermission,
  hasTagCreatePermission
}) => {
  const title = type
      ? type === TagPageInspectorType.Create
        ? t("tags.inspector.create-title")
        : t("tags.inspector.update-title")
      : "",
    actionButtonLabel = type === TagPageInspectorType.Create ? t("tags.cta.save") : t("tags.cta.update"),
    loaderMessage = type === TagPageInspectorType.Create ? t("tags.cta.saving") : t("tags.cta.updating");

  const disableInteractionWithUI = type
    ? type === TagPageInspectorType.Create
      ? !hasTagCreatePermission
      : !hasTagEditPermission
    : true;

  return (
    <Inspector
      title={title}
      onClose={() => cancelSaveTag(tag!)}
      isActive={!!type}
      actionButtonLabel={actionButtonLabel}
      onActionButtonClick={() => saveTag(tag!)}
      isBackgroundActionDisabled={true}
      isActionButtonDisabled={disableInteractionWithUI}>
      {tag && (
        <div className="tags-edit-container">
          <TextField
            value={tag.name}
            label={t("tags.inspector.name-label")}
            onChange={(name) => !isExistingTag(tag) && onChange({ ...tag, name })}
            onBlur={() => setSlug(tag)}
            readOnly={isExistingTag(tag)}
            hint={t("tags.inspector.name-hint")}
            errorMessage={nameFieldErrorMessage(saveError)}
            disabled={disableInteractionWithUI}
          />
          <TextField
            value={tag.slug || ""}
            label={t("tags.inspector.slug-label")}
            onBlur={() => setSlug(tag)}
            onChange={(slug) => onChange({ ...tag, slug })}
            hint={t("tags.inspector.slug-hint")}
            errorMessage={
              isClientValidationError<AnyTag>(saveError) ? saveError.errors.slug && saveError.errors.slug[0] : undefined
            }
            disabled={disableInteractionWithUI}
          />
          <TextArea
            value={tag["meta-title"] || ""}
            label={t("tags.inspector.meta-title-label")}
            onChange={(metaTitle) => onChange({ ...tag, "meta-title": metaTitle })}
            hint={t("tags.inspector.meta-title-hint")}
            errorMessage={
              isClientValidationError<AnyTag>(saveError)
                ? saveError.errors["meta-title"] && saveError.errors["meta-title"][0]
                : undefined
            }
            disabled={disableInteractionWithUI}
          />
          <TextArea
            value={tag["meta-description"] || ""}
            label={t("tags.inspector.meta-description-label")}
            onChange={(metaDescription) => onChange({ ...tag, "meta-description": metaDescription })}
            hint={t("tags.inspector.meta-description-hint")}
            errorMessage={
              isClientValidationError<AnyTag>(saveError)
                ? saveError.errors["meta-description"] && saveError.errors["meta-description"][0]
                : undefined
            }
            disabled={disableInteractionWithUI}
          />
          {isLoading && <Spinner message={loaderMessage} />}
        </div>
      )}
    </Inspector>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    tag: state.tags.app.currentTag,
    type: state.tags.ui.inspector,
    saveError: state.tags.ui.save.error,
    isLoading: state.tags.ui.save.loading,
    hasTagEditPermission: state.features.hasTagEditPermission,
    hasTagCreatePermission: state.features.hasTagCreatePermission
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    saveTag: (tag: AnyTag) => dispatch(saveTag(tag)),
    cancelSaveTag: (tag: AnyTag) => dispatch(cancelSaveTag(tag)),
    onChange: (tag: AnyTag) => dispatch(replaceCurrentTagAction(tag)),
    setSlug: (tag: AnyTag) => dispatch(setGeneratedSlug(tag))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TagInspector);

export { TagInspector };
