/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

//Library imports
import * as React from "react";
import { connect } from "react-redux";
import { Location } from "history";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import { parse } from "query-string";
//Types, State, Action creators, async-action creators, Utils and Constants Imports
import { LoaderState } from "behaviors/loader/state";
import { setCustomRange, setFilterOptions, toggleAnalyticsShare } from "./action-creators";
import { getDateRange, getDateRangeFromQueryParam } from "./utils";
import { navigate } from "utils/routes.utils";
import { AnalyticsConfig, DateRange } from "api/route-data/analytics-route-data";
import * as analyticsAPI from "api/analytics";
import { CurrentFilter, PartialAppState } from "./state";
import { getAnalytics } from "./async-action-creator";
import { TAB_LIST } from "./constants";
import { ANALYTICS_PATH } from "./routes";
import { Timestamp } from "api/primitive-types";
//UI Component Imports
import File from "components/icons/file";
import NoContentCard from "components/no-content-card/no-content-card";
import ContentHeader from "pages/settings/components/content-header/content-header";
import { FixedTabList, Panel } from "components/tabs/tabs";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import Loader from "./components/loader";
import AnalyticsGraph from "./components/analytics/analytics";
import DateWrapper from "./components/date-wrapper/date-wrapper";
import Spinner from "components/spinner/spinner";
import AnalyticsShare from "./components/analytics-share/analytics-share";
//CSS Imports
import styles from "./analytics.module.css";

interface OwnProps {
  match: {
    params: {
      dimension: string;
      metrics: string;
      period: string;
    };
    url: string;
  };
  location: Location;
  Component: typeof React.Component;
  title: string;
}

interface StateProps {
  canAccessAnalytics: boolean;
  analytics: analyticsAPI.Analytics | null;
  currentFilter: CurrentFilter;
  config: AnalyticsConfig;
  isNormanEnabled: boolean;
  loader: LoaderState;
  dateRangeComparison: string;
  isAnalyticsLoading: boolean;
  currentUrl: string;
  isAnalyticsShareVisible: boolean;
  customRange: {
    "range-1": analyticsAPI.RangeFilter;
    "range-2": analyticsAPI.RangeFilter;
  };
}

interface DispatchProps {
  getAnalytics: (config: AnalyticsConfig) => void;
  onChange: (value: DateRange, defaultPeriod: string) => void;
  onMetricsChange: (metrics: string) => void;
  toggleAnalyticsShare: () => void;
  onChangeCustomDate: (key: string, startDate: Timestamp, endDate: Timestamp) => void;
}

type Props = DispatchProps & StateProps & OwnProps;

class Analytics extends React.Component<Props> {
  componentDidMount() {
    this.props.getAnalytics(this.props.config);
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.location.pathname !== prevProps.location.pathname ||
      this.props.location.search !== prevProps.location.search
    ) {
      this.props.getAnalytics(this.props.config);
    }
  }

  showContainerHeader() {
    const { isNormanEnabled, currentFilter, onMetricsChange } = this.props;

    return isNormanEnabled ? (
      <h3 className={styles["analytics-container-header__title"]}>{t("analytics.view")}</h3>
    ) : (
      <FixedTabList
        tabs={TAB_LIST()}
        value={currentFilter.metrics}
        onChange={(metrics: string) => onMetricsChange(metrics)}
      />
    );
  }

  render() {
    const {
      title,
      canAccessAnalytics,
      analytics,
      currentFilter,
      config,
      loader,
      onChange,
      dateRangeComparison,
      isAnalyticsLoading,
      isNormanEnabled,
      currentUrl,
      toggleAnalyticsShare,
      isAnalyticsShareVisible,
      Component,
      onChangeCustomDate,
      customRange
    } = this.props;
    if (!canAccessAnalytics || !config) {
      return null;
    }
    const isDataAvailable = analytics && analytics["dim-count"] && analytics["metric-count"];
    const titleInfo = isNormanEnabled ? t("analytics.norman_source") : undefined;

    return (
      <React.Fragment>
        <LoaderWrapper
          component={Loader}
          className={"analytics-progress-main-area"}
          loader={loader}
          loadAnalytics={() => this.props.getAnalytics(config)}>
          {analytics && (
            <React.Fragment>
              <ContentHeader title={title} titleInfo={titleInfo} />
              <div className={styles["analytics-container"]} data-test-id="analytics-container">
                {this.showContainerHeader()}
                <Panel>
                  <DateWrapper
                    onChange={(period) => onChange(period, "days")}
                    dateRangeComparison={dateRangeComparison}
                    currentFilter={currentFilter}
                    config={config}
                    onChangeCustomDate={onChangeCustomDate}
                    customRange={customRange}
                    limitCustomInterval={!isNormanEnabled}
                  />
                  {isAnalyticsLoading ? (
                    <Spinner />
                  ) : isDataAvailable ? (
                    <React.Fragment>
                      <AnalyticsGraph analytics={analytics} currentFilter={currentFilter} />
                      <AnalyticsShare
                        currentUrl={currentUrl}
                        toggleAnalyticsShare={toggleAnalyticsShare}
                        isAnalyticsShareVisible={isAnalyticsShareVisible}
                      />
                    </React.Fragment>
                  ) : (
                    <NoContentCard>
                      <File />
                      {t("analytics.messages.no_data")}
                    </NoContentCard>
                  )}
                </Panel>
              </div>
              {!isAnalyticsLoading && isDataAvailable !== 0 && (
                <Component data={analytics.top} metrics={currentFilter.metrics} currentFilter={currentFilter} />
              )}
            </React.Fragment>
          )}
        </LoaderWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState, ownProps: OwnProps): StateProps => {
  return {
    canAccessAnalytics: state.features.canAccessAnalytics,
    isNormanEnabled: state.features.isNormanEnabled,
    analytics: state.analytics.analytics,
    currentFilter: state.analytics.app.currentFilter,
    config: state.config.analytics,
    loader: state.analytics.ui.main,
    dateRangeComparison: state.analytics.ui.dateRangeComparison,
    isAnalyticsLoading: state.analytics.ui.isAnalyticsLoading,
    currentUrl: `${ownProps.match.url}${ownProps.location.search}`,
    isAnalyticsShareVisible: state.analytics.ui.isAnalyticsShareVisible,
    customRange: state.analytics.app.customRange
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>, ownProps: OwnProps): DispatchProps => {
  const { dimension, metrics, period } = ownProps.match.params;
  const customRange: string = ownProps.location.search;

  return {
    getAnalytics: (config) => {
      const opts = customRange
        ? { dimension, metrics, period, customRange: parse(customRange) }
        : { dimension, metrics, period };
      dispatch(getAnalytics(config, opts));
    },
    onChange: (period, defaultPeriod) => {
      if (period.name === "custom") {
        const { "range-1": defaultRange1, "range-2": defaultRange2 } = getDateRange(defaultPeriod);
        dispatch(setCustomRange(getDateRange(defaultPeriod)));
        dispatch(
          navigate(
            ANALYTICS_PATH,
            { dimension, metrics, period: period.name },
            {
              "range-1": `${defaultRange1["start-ts"]}-${defaultRange1["end-ts"]}`,
              "range-2": `${defaultRange2["start-ts"]}-${defaultRange2["end-ts"]}`
            }
          )
        );
      } else {
        dispatch(navigate(ANALYTICS_PATH, { dimension, metrics, period: period.name }));
      }
    },
    onMetricsChange: (metrics) => {
      dispatch(setFilterOptions({ metrics }));
      dispatch(navigate(ANALYTICS_PATH, { dimension, metrics, period }));
    },
    toggleAnalyticsShare: () => dispatch(toggleAnalyticsShare()),
    onChangeCustomDate: (key, startDate, endDate) => {
      const queryParams = { ...parse(customRange), [key]: `${startDate}-${endDate}` };
      const dateRange = getDateRangeFromQueryParam(queryParams);
      dispatch(setCustomRange(dateRange));
      dispatch(navigate(ANALYTICS_PATH, { dimension, metrics, period: period }, queryParams));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);

export { Analytics };
