/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import * as actions from "../../actions";
import Inspector from "components/inspector/inspector";
import SaveFilters from "pages/workspace/components/save-filters/save-filters";
import Spinner from "components/spinner/spinner";
import { t } from "i18n";

import styles from "./save-filter-inspector.module.css";

const SaveFilterInspector = ({
  showSaveFilterInspector,
  onClose,
  saveFilters,
  updateFilterName,
  filterName,
  errors,
  addErrors,
  removeErrors,
  showLoadingIndicator
}: any) => {
  return (
    <Inspector
      title={t("workspace.save_filter_inspector.save_filter")}
      isActive={showSaveFilterInspector}
      onClose={onClose}>
      <div className={styles["save-filter-container"]}>
        <SaveFilters
          saveFilters={(filterName: any) => {
            if (filterName && filterName.length > 0) {
              saveFilters(filterName);
              removeErrors("filterName");
            } else {
              addErrors("filterName");
            }
          }}
          updateFilterName={updateFilterName}
          filterName={filterName}
          errorMessage={errors && errors["filterName"] ? t("workspace.save_filter_inspector.missing_filter_name") : ""}
          showLoadingIndicator={showLoadingIndicator}
        />
        {showLoadingIndicator && <Spinner message={t("workspace.save_filter_inspector.saving_filter")} />}
      </div>
    </Inspector>
  );
};

const mapStateToProps = (state: any) => {
  return {
    filterName: state.workspace.filterName,
    errors: state.workspace.errors
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    updateFilterName: (data: any) => dispatch({ type: actions.WORKSPACE_UPDATE_FILTER_NAME, payload: { data } }),
    addErrors: (key: any) => dispatch({ type: actions.WORKSPACE_ADD_ERRORS, payload: key }),
    removeErrors: (key: any) => dispatch({ type: actions.WORKSPACE_REMOVE_ERRORS, payload: key })
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SaveFilterInspector);

export { SaveFilterInspector };
