/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import AssociatedMetadataComponent from "pages/collections/components/associated-metadata/associated-metadata";
import UpdateManage from "pages/collections/components/update-manage/update-manage";

import styles from "./collection-manage.module.css";
import { CollectionItemWrapper, AssociatedMetadata } from "api/story-collection";
import { CollectionTemplateOptions, CollectionLayoutOptions } from "api/template-options";

type Props = {
  item: CollectionItemWrapper;
  updateStagingAssociatedMetadata: (name: string, val: string | number | boolean) => void;
  updatePageViewsThreshold: (index: number) => void;
  index: number;
  stagingAssociatedMetadata: AssociatedMetadata;
  changeAssociatedMetadataLayout: (name: string, options: CollectionLayoutOptions[]) => void;
  cancelCardExpanded: (index: number, key: string, value: any) => void;
  updateAssociatedMetadata: (index: number, key: string, associatedMetadata: AssociatedMetadata) => void;
  templateOptions: CollectionTemplateOptions;
};

const CollectionManage: React.SFC<Props> = ({
  item,
  updateStagingAssociatedMetadata,
  updatePageViewsThreshold,
  index,
  stagingAssociatedMetadata,
  changeAssociatedMetadataLayout,
  cancelCardExpanded,
  updateAssociatedMetadata,
  templateOptions
}) => (
  <div className={styles["collection-manage"]}>
    <AssociatedMetadataComponent
      updateStagingAssociatedMetadata={updateStagingAssociatedMetadata}
      collectionLayouts={templateOptions["collection-layouts"] ? templateOptions["collection-layouts"] : []}
      associatedMetadata={stagingAssociatedMetadata}
      changeAssociatedMetadataLayout={changeAssociatedMetadataLayout}
    />
    <UpdateManage
      item={item}
      stagingAssociatedMetadata={stagingAssociatedMetadata}
      updatePageViewsThreshold={updatePageViewsThreshold}
      index={index}
      threshold={null}
      cancelCardExpanded={cancelCardExpanded}
      updateAssociatedMetadata={updateAssociatedMetadata}
    />
  </div>
);

export default CollectionManage;
