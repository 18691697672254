/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

import { validationFn, CSV_CONTENT_TYPE } from "../utils";
import Plus from "../../../../components/icons/plus";
import Edit from "../../../../components/icons/edit";
import { formatBytesToHumanReadable } from "utils";
import Tooltip from "components/tooltip/tooltip";
import Checkbox from "components/checkbox/checkbox";
import classnames from "classnames/bind";

import styles from "./table.module.css";
import { t } from "i18n";

const fileReader = new FileReader();
const cx = classnames.bind(styles);

class Table extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      uploading: false,
      fileName: "",
      fileSize: ""
    };
  }

  fileReadComplete(element) {
    this.setState({ uploading: false });
    const elementId = element.id;
    const metadata = element.metadata || {};
    const metadataWithFileName = { ...metadata, "file-name": this.state.fileName, "file-size": this.state.fileSize };
    const changes = {
      data: {
        content: fileReader.result,
        "content-type": CSV_CONTENT_TYPE
      },
      metadata: metadataWithFileName
    };

    this.props.onChange(elementId, changes);
  }

  uploadFile(element, e) {
    const file = e.target.files[0];
    console.log(file);

    if (file && validationFn(file, "table")) {
      this.setState({ uploading: true });
      fileReader.onload = () => this.fileReadComplete(element);
      fileReader.readAsText(file);
      this.setState({ fileName: file.name, fileSize: file.size });
    } else {
      e.preventDefault();
      e.stopPropagation();
      if (file) {
        alert("Invalid File type or size. Please upload size upto 25MB");
        e.target.value = null;
      }
    }
  }

  toggleHeader(elementId) {
    const metadata = this.props.storyElement.metadata || {};
    const headerMetadata = { metadata: { ...metadata, "has-header": !metadata["has-header"] } };
    this.props.onChange(elementId, headerMetadata);
  }

  render() {
    const storyElement = this.props.storyElement;
    const fileName = storyElement && storyElement.metadata["file-name"];

    if (!storyElement) {
      return null;
    }
    const classes = cx("story-element-table-container", { "is-disabled": this.props.isDisabled });
    return (
      <div className={classes}>
        <input
          className={styles["table-input"]}
          id={`table-${storyElement.id}`}
          type="file"
          onChange={(e) => this.uploadFile(storyElement, e)}
          accept=".csv"
          disabled={this.state.uploading}
        />

        <div className={styles["table-input-label"]}>
          {!fileName && (
            <React.Fragment>
              <label className={styles["table-input-wrapper"]} htmlFor={`table-${storyElement.id}`} />
              <Plus height={16} width={16} fill="#5f6978" />
              <p>{t("story-editor.story-element.upload-csv")}</p>
              <p className={styles["info"]}>{t("story-editor.story-element.table-info")}</p>
            </React.Fragment>
          )}
          {fileName && (
            <React.Fragment>
              <div className={styles["table-details"]}>
                <p className={styles["table-details-filename"]}>{fileName}</p>
                {storyElement.metadata["file-size"] && (
                  <p className={styles["table-data"]}>{`(${formatBytesToHumanReadable(
                    storyElement.metadata["file-size"]
                  )})`}</p>
                )}
                <label
                  className={styles["table-data-edit"]}
                  htmlFor={`table-${storyElement.id}`}
                  data-tip
                  data-for={`table-edit-${storyElement.id}`}>
                  <Edit height={16} width={16} />
                  <Tooltip
                    id={`table-edit-${storyElement.id}`}
                    place="bottom"
                    effect="solid"
                    value={t("story-editor.story-element.replace")}
                  />
                </label>
              </div>
              <Checkbox
                onChange={() => this.toggleHeader(storyElement.id)}
                checked={storyElement.metadata["has-header"]}
                label={t("story-editor.story-element.header-text")}
                id={`table-checkbox-${storyElement.id}`}
                disabled={false}
              />
            </React.Fragment>
          )}
        </div>
        {/* <ErrorText errors="{this.props.errors && this.props.errors.data}" /> */}
      </div>
    );
  }
}

export default Table;
