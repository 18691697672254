/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { EditorView } from "prosemirror-view";
import { Transaction } from "prosemirror-state";
import { Slice, Fragment, Node } from "prosemirror-model";
import { Decoration } from "prosemirror-view";
import { findNodeClosestToPos, findParagraphNodesInSlice } from "../operations/find";
import { safeTextSelection } from "../operations/selection";
import { schema } from "./schema";
import { handleTextCut, parseFromClipboard } from "./commands";
import { isTextContentPasteableInStoryElement } from "./utils";
import { EditorState } from "prosemirror-state";
import { Schema } from "prosemirror-model";
import { getReduxActionLog } from "utils";
import { ItsmanWindow } from "containers/page/page";
import { get } from "lodash";

const w = window as ItsmanWindow;

interface Props {
  editorState: EditorState | null;
  classname: string;
  onEditorStateChange(editorState: EditorState): void;
  nodeViews?: {
    [name: string]: (node: Node<Schema>, view: any, getPos: (() => number) | boolean, decorations: Decoration[]) => any;
  } | null;
  readOnly?: boolean;
  skipHandlePaste?: boolean;
}
export default class ProseMirrorEditorView extends React.Component<Props> {
  _createEditorViewRef: React.RefObject<HTMLDivElement>;
  _editorView: EditorView | null = null;

  constructor(props: Props) {
    super(props);
    this._createEditorViewRef = React.createRef();
  }

  componentDidMount() {
    if (this._createEditorViewRef && this._createEditorViewRef.current) {
      this._createEditorView(this._createEditorViewRef.current);
    }
  }

  handleCut(event: ClipboardEvent): boolean {
    const eventElement = event.target as HTMLElement;
    const tagName = eventElement && eventElement.tagName;
    if (tagName === "TEXTAREA" || tagName === "INPUT") return true;
    return (
      (this._editorView &&
        handleTextCut()(this._editorView.state, this._editorView.dispatch, this._editorView, event)) ||
      false
    );
  }

  _createEditorView = (element: HTMLDivElement) => {
    if (element != null) {
      this._editorView = new EditorView(element, {
        state: this.props.editorState!,
        dispatchTransaction: this.dispatchTransaction,
        // @ts-ignore
        nodeViews: this.props.nodeViews,
        editable: this.isEditable
      });
      element.addEventListener("paste", this.handlePaste.bind(this));
      element.addEventListener("cut", this.handleCut.bind(this));
      element.addEventListener("focus", this.handleFocus.bind(this));
      // @ts-ignore
      this._editorView.props.handleDOMEvents = {
        cut: (view, event) => {
          return this.handleCut(event as ClipboardEvent);
        }
      };
    }
  };
  isEditable = () => !this.props.readOnly;

  //Explicity set focus when DOM focus event is trigged on PM node
  handleFocus = () => {
    this.focus();
  };

  handlePaste(event: ClipboardEvent) {
    if (!this._editorView || !this._editorView.editable) return;
    const eventElement = event.target as HTMLElement;
    const tagName = eventElement && eventElement.tagName;
    if (tagName === "TEXTAREA" || tagName === "INPUT") return;
    if (this.props.skipHandlePaste) {
      this.markEventAsConsumed(event);
      return;
    }
    const editorState = this._editorView.state;

    try {
      const { anchor, head } = editorState.selection,
        docSize = get(editorState.doc, ["content", "size"]),
        cursorPosition = head ? head : anchor;
      if (cursorPosition === 0 || cursorPosition === docSize) {
        this.markEventAsConsumed(event);
        return;
      }
      const tr = this._editorView.state.tr,
        destinationNode = findNodeClosestToPos(editorState),
        destinationNodeGroup = destinationNode && destinationNode.spec.group,
        slice = parseFromClipboard(
          this._editorView,
          event.clipboardData ? event.clipboardData.getData("text/plain") : "",
          event.clipboardData ? event.clipboardData.getData("text/html") : "",
          // @ts-ignore
          this._editorView.shiftKey,
          // shiftKey is not defined in editorView type as of @types/prosemirror-view 1.11.4
          this._editorView.state.selection.$from
        );

      // @ts-ignore
      if (slice && slice.content && isTextContentPasteableInStoryElement(slice.content.content[0])) {
        // content is not defined in Fragment type as of @types/prosemirror-model 1.7.2
        tr.replaceSelection(slice);
      } else if (slice && (slice.openStart > 1 || slice.openEnd > 1) && destinationNodeGroup === "block") {
        // This path is triggered when the slice is parsed as
        // one of the story_element  or card nodes from our PM schema

        // find returns a flattened version of the slice with all paragraph and text nodes
        const textNodes = findParagraphNodesInSlice(slice).map((nodeNP) => nodeNP && nodeNP.node);
        const paragraphedJSON = textNodes.map((textnode) => textnode && textnode.toJSON());
        const sliceFromJSON = new Slice(Fragment.fromJSON(schema, paragraphedJSON), 1, 1);
        tr.replaceSelection(sliceFromJSON);
      } else {
        const trAfterInsertingText = tr.insertText(
          event.clipboardData ? event.clipboardData.getData("text/plain") : "",
          anchor > head ? head : anchor,
          anchor < head ? head : anchor
        );

        tr.setSelection(
          safeTextSelection(
            trAfterInsertingText.doc,
            this.maxOf(trAfterInsertingText.selection.anchor, trAfterInsertingText.selection.head)
          )
        );
      }

      // Apply to the editor state with the right cursor position.
      const newEditorState = editorState.apply(tr);
      this._editorView.updateState(newEditorState);
      this.props.onEditorStateChange(newEditorState);
      this.markEventAsConsumed(event);
    } catch (error) {
      w.newrelic.noticeError(error, {
        errorType: "handle_paste",
        errorDescription: "Error in handle paste",
        errorInfo: JSON.stringify({ editorState }),
        reduxActionLog: JSON.stringify({ actions: getReduxActionLog() })
      });
      throw error;
    }
  }

  maxOf = (a, b) => (a > b ? a : b);

  private markEventAsConsumed(event: ClipboardEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  dispatchTransaction = (tx: Transaction<Schema>) => {
    if (this._editorView != null) {
      const editorState = this._editorView.state.apply(tx);
      this._editorView.updateState(editorState);
      editorState.doc.content.size !== 0 && this.props.onEditorStateChange(editorState);
    }
  };

  focus() {
    if (this._editorView) {
      this._editorView.focus();
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    if (this._editorView && this.props.editorState && nextProps.editorState) {
      if (
        !nextProps.editorState.doc.eq(this.props.editorState.doc) ||
        // @ts-ignore
        nextProps.editorState.linter$ !== this.props.editorState.linter$
        // linter$ is not defined in editorState type as of @types/prosemirror-state 1.2.4
      ) {
        this._editorView.updateState(nextProps.editorState);
      }
      const changeInCursorPosition = this.props.editorState.selection.anchor !== nextProps.editorState.selection.anchor;
      // @ts-ignore
      if (this._editorView.focused || changeInCursorPosition) {
        this._editorView.focus();
      }
      if (nextProps.readOnly !== this.props.readOnly) {
        this._editorView.setProps({ editable: () => !nextProps.readOnly });
      }
    }
  }

  componentWillUnmount() {
    if (this._editorView) {
      this._editorView.destroy();
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div
        className={this.props.classname}
        tabIndex={0}
        data-test-id="prosemirror-text-area"
        id="prosemirror-text-area"
        ref={this._createEditorViewRef}
      />
    );
  }
}
