/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface BulletListIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const BulletList: React.SFC<BulletListIconProps> = ({ width = "24", height = "24", color = "currentColor" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 24 24">
    <path d="M8 4h10.8v1.8H8zM5 4h1.8v1.8H5zM8 11.5h10.8v1.8H8zM5 11.5h1.8v1.8H5zM8 19h10.8v1.8H8zM5 19h1.8v1.8H5z" />
  </svg>
);

export default BulletList;
