/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { createSlice } from "@reduxjs/toolkit";
import { State, INITIAL_STATE } from "./state";
import {
  saveWatermarkImageRequest,
  saveWatermarkImageSuccess,
  saveWatermarkImageFailure,
  getWatermarkImageRequest,
  getWatermarkImageSuccess,
  getWatermarkImageFailure
} from "store/watermark-images";

const { reducer } = createSlice({
  name: "defaultWatermarkImagePage",
  reducers: {},
  extraReducers: {
    [getWatermarkImageRequest.type]: (state: State) => {
      state.ui.main.loading = true;
      state.ui.main.error = null;
    },
    [getWatermarkImageSuccess.type]: (state: State) => {
      state.ui.main.loading = false;
    },
    [getWatermarkImageFailure.type]: (state: State, { payload: { error } }: any) => {
      if (error) {
        state.ui.main.error = error;
      } else {
        state.ui.main.error = null;
      }
      state.ui.main.loading = false;
    },
    [saveWatermarkImageRequest.type]: (state: State) => {
      state.ui.saving = true;
      state.ui.main.loading = true;
      state.ui.main.error = null;
    },
    [saveWatermarkImageSuccess.type]: (state: State) => {
      state.ui.saving = false;
      state.ui.main.loading = false;
    },
    [saveWatermarkImageFailure.type]: (state: State, { payload: { error } }: any) => {
      state.ui.saving = false;
      state.ui.main.loading = false;
    }
  },
  initialState: INITIAL_STATE
});

export default reducer;
