/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Select from "components/select/select";
import AssociatedMetadataField from "pages/collections/components/associated-metadata-field/associated-metadata-field";
import { t } from "i18n";
import { CollectionLayout, CollectionLayoutOptions } from "api/template-options";
import { AssociatedMetadata as AssociatedMetadataType } from "api/story-collection";
import styles from "./associated-metadata.module.css";

const getTemplateOptions = (collectionLayout: Array<CollectionLayout>, associatedMetadata: AssociatedMetadataType) => {
  const foundTemplate = collectionLayout.find((layout) => layout.name === associatedMetadata.layout);
  return foundTemplate ? foundTemplate.options : [];
};

type Props = {
  collectionLayouts: Array<CollectionLayout>;
  updateStagingAssociatedMetadata: (name: string, val: string | number | boolean) => void;
  associatedMetadata: AssociatedMetadataType;
  changeAssociatedMetadataLayout: (name: string, options: CollectionLayoutOptions[]) => void;
};

const generateOptions = (collectionLayouts: any) => {
  return collectionLayouts.map((collectionLayout: any) => {
    return {
      name: collectionLayout.name,
      display: collectionLayout.display,
      layoutOptions: collectionLayout.options
    };
  });
};

const AssociatedMetadata: React.SFC<Props> = ({
  collectionLayouts,
  updateStagingAssociatedMetadata,
  associatedMetadata,
  changeAssociatedMetadataLayout
}) => (
  <div>
    <Select
      label={t("collections.template")}
      value={
        associatedMetadata.layout
          ? collectionLayouts.find((collectionLayout) => collectionLayout.name === associatedMetadata.layout)
          : null
      }
      onChange={(collectionLayout: CollectionLayout) =>
        changeAssociatedMetadataLayout(
          collectionLayout.name,
          getTemplateOptions(collectionLayouts, { layout: collectionLayout.name })
        )
      }
      options={generateOptions(collectionLayouts)}
      getOptionLabel={(collectionLayout: CollectionLayout) => collectionLayout.display}
      getOptionValue={(collectionLayout: CollectionLayout) => collectionLayout.name}
    />
    {associatedMetadata.layout && (
      <div className={styles["associated-metadata-fields"]}>
        {getTemplateOptions(collectionLayouts, associatedMetadata).map((option) => (
          <AssociatedMetadataField
            updateStagingAssociatedMetadata={updateStagingAssociatedMetadata}
            option={option}
            associatedMetadata={associatedMetadata}
            key={`associated-metadata-field-${option.name}`}
          />
        ))}
      </div>
    )}
  </div>
);

export default AssociatedMetadata;
