/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import MetaTitle from "pages/story-editor/components/manage-fields/meta-title";
import MetaDescription from "pages/story-editor/components/manage-fields/meta-description";
import MetaKeywords from "pages/story-editor/components/manage-fields/meta-keywords";

export const SeoFields = [
  { specPath: "meta-title", storyPath: "meta-title", component: MetaTitle },
  { specPath: "meta-description", storyPath: "meta-description", component: MetaDescription },
  { specPath: "meta-keywords", storyPath: "meta-keywords", component: MetaKeywords }
];
