/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { t } from "i18n";

import classnames from "classnames/bind";
import User from "components/icons/user";
import Clock from "components/icons/clock";
import Checkbox from "components/checkbox/checkbox";
import Chip from "components/chip/chip";
import Preview from "components/icons/preview";
import { formattedDate } from "utils";
import { STORY_EDITOR_PATH_REFRESH } from "../../../story-editor/routes";

import styles from "./story-card.module.css";
import Calendar from "components/icons/calendar";
import Tooltip from "components/tooltip/tooltip";
import Lock from "components/icons/lock";
import StoryCardMenu from "./story-card-menu";
import { NavLink } from "react-router-dom";
import ClonedToPrint from "components/icons/cloned-to-print";
import { get } from "lodash";

const getAuthorDelimiter = (numberOfAuthors, index) => {
  if (numberOfAuthors === 1) {
    return null;
  } else if (numberOfAuthors === 2) {
    return index === 0 && <span className={styles["author-delimiter-and"]}>&</span>;
  } else {
    return index === numberOfAuthors - 2 ? (
      <span className={styles["author-delimiter-and"]}>&</span>
    ) : (
      index !== numberOfAuthors - 1 && ","
    );
  }
};

const renderHighlightedHTML = (html) => {
  return {
    __html: html.replace(
      /<mark class="highlight">([^<]+)<\/mark>/g,
      '<mark class="highlight" style="background-color: #FDF3DC">$1</mark>'
    )
  };
};

const generateHighlightedTextBlock = (story, label, highlightedKey) => {
  if (story[highlightedKey] == null) {
    return null;
  }
  return (
    <div data-test-id={`story-${highlightedKey}-display`} className={styles["story-author"]}>
      <span>
        <strong>{label}</strong>
      </span>
      <span dangerouslySetInnerHTML={renderHighlightedHTML(story[highlightedKey])} />
    </div>
  );
};

const cx = classnames.bind(styles);

const Author = ({ author, delimiter, setStoriesOnFilterChange }) => (
  <div
    data-test-id="story-author-btn"
    className={styles["story-author"]}
    onClick={() => setStoriesOnFilterChange("authors", [{ id: author.id, name: author.name }])}>
    <span
      dangerouslySetInnerHTML={
        author["highlighted-name"] ? renderHighlightedHTML(author["highlighted-name"]) : { __html: author["name"] }
      }
    />
    {delimiter}
  </div>
);

const Authors = ({ story, setStoriesOnFilterChange }) => {
  const authorsList =
    story.authors && story.authors.length > 0
      ? story.authors
      : [{ id: story["author-id"], name: story["author-name"] }];

  return authorsList.map((author, index) => (
    <Author
      data-test-id="story-author"
      key={`story-author-${author.id}`}
      author={author}
      delimiter={getAuthorDelimiter(authorsList.length, index)}
      setStoriesOnFilterChange={setStoriesOnFilterChange}
    />
  ));
};

const getStoryTemplate = (storyTemplate, storyTemplates) =>
  storyTemplate
    ? [storyTemplates.find((template) => template.slug === storyTemplate)]
    : [{ name: "Text", slug: "text" }];

const StoryCard = ({
  story,
  showPublicPreviewLink,
  canDeleteStory,
  isBulkActionEnabled,
  checked,
  onChange,
  setStoriesOnFilterChange,
  switchToDeleteStoryRoute,
  isAllTabSelected,
  storyTemplates,
  navigate,
  features,
  isCurrentWindowIsInSlider,
  notificationError,
  isPrintSelected
}) => {
  const storyStatus = story["status"];
  const isEmbargoed = story["is-embargoed"];
  const storyTemplate = story["story-template"] || "text";
  const isClonedForPrint = get(story, "metadata.story-attributes.is-cloned-to-print[0]", false);

  const redirectToClonedStory = () => {
    const clonedStoryID = get(story, "metadata.story-attributes.print-story-ids[0]", null);
    if (clonedStoryID) {
      window.open(`/story/${clonedStoryID}`, "_blank");
    } else {
      notificationError(t("common.phrases.cloned-story-deleted-error"));
    }
  };

  return (
    <div className={cx("story-card", "workspace-storycard")} data-test-id="workspace-story-card">
      {isBulkActionEnabled && (
        <div className={styles["story-multi-select"]} data-test-id="story-multi-select">
          <Checkbox
            checked={checked}
            id={`bulk-checkbox-${story.id}`}
            onChange={onChange}
            classname={"story-card-checkbox"}
          />
        </div>
      )}
      <div className={styles["story-card-content"]} data-test-id="story-card-content">
        <div className={styles["story-chips"]} data-test-id="story-chips">
          {isAllTabSelected && storyStatus && (
            <Chip
              value={
                isPrintSelected && storyStatus === "pending-approval"
                  ? t(`workspace.tabs.printed`)
                  : t(`workspace.tabs.${storyStatus}`, storyStatus)
              }
              invert
              classname="story-card-chip"
            />
          )}
          <span
            className={styles["story-chip-wrapper"]}
            data-test-id="story-card-chip-wrapper-btn"
            onClick={() =>
              setStoriesOnFilterChange("story-templates", getStoryTemplate(story["story-template"], storyTemplates))
            }
            style={{ pointerEvents: isPrintSelected ? "none" : "auto" }}>
            <Chip value={t(`story-editor.story-template.${storyTemplate}`, storyTemplate)} />
            {isEmbargoed && <Chip value={t("common.embargoed")} classname={"story-card-embargo"} />}
          </span>
          {features.isStoryPrintEnabled && isClonedForPrint && (
            <div
              className={styles["cloned-to-print"]}
              data-test-id="cloned-to-print"
              data-for="cloned-to-print-tooltip"
              data-tip
              onClick={redirectToClonedStory}>
              <ClonedToPrint height={"18"} width={"18"} />
              <Tooltip
                id="cloned-to-print-tooltip"
                value={t("common.clonedToPrint")}
                place="top"
                effect="solid"
                classname={styles["card-time-tooltip"]}
              />
            </div>
          )}
        </div>

        <NavLink
          to={`${STORY_EDITOR_PATH_REFRESH}${story.id}`}
          className={styles["story-headline"]}
          data-test-id="story-headline"
          target={isCurrentWindowIsInSlider ? "_blank" : undefined}
          dangerouslySetInnerHTML={
            story["highlighted-headline"]
              ? renderHighlightedHTML(story["highlighted-headline"])
              : { __html: story.headline }
          }></NavLink>

        <div className={styles["story-byline"]} data-test-id="story-byline">
          <div className={styles["story-authors"]} data-test-id="story-authors">
            <User />
            <Authors story={story} setStoriesOnFilterChange={setStoriesOnFilterChange} />
          </div>
          <div className={styles["story-time-container"]}>
            <div className={styles["story-time-wrapper"]} data-test-id="story-time-wrapper">
              <Clock />
              <time
                className={styles["story-time"]}
                data-test-id="story-time"
                data-for="published-or-updated-timer-tooltip"
                data-tip>
                {story.isStoryPublished
                  ? formattedDate(story["last-published-at"])
                  : formattedDate(story["updated-at"])}
                <Tooltip
                  id={"published-or-updated-timer-tooltip"}
                  place="bottom"
                  effect="solid"
                  value={t("common.last-updated")}
                  classname={styles["story-time-tooltip"]}
                />
              </time>
            </div>
            {story.status === "scheduled" && (
              <div className={styles["story-time-wrapper"]} data-test-id="story-time-wrapper">
                <Calendar width={16} height={16} />
                <time
                  className={styles["story-time"]}
                  data-test-id="story-time"
                  data-for="scheduled-timer-tooltip"
                  data-tip>
                  {!story["publish-at"]
                    ? t("workspace.story_card.publish_with_collection")
                    : formattedDate(story["publish-at"])}
                  <Tooltip
                    id="scheduled-timer-tooltip"
                    place="bottom"
                    effect="solid"
                    value={t("common.scheduled")}
                    classname={styles["story-time-tooltip"]}
                  />
                </time>
              </div>
            )}
            {isEmbargoed && (
              <div className={styles["story-time-wrapper"]} data-test-id="story-embargoed-time">
                <Lock width={16} height={16} />
                <time
                  className={styles["story-time"]}
                  data-test-id="story-embargoed-time"
                  data-for="embargoed-tooltip"
                  data-tip>
                  {!story["embargoed-till"] ? t("common.embargoed") : formattedDate(story["embargoed-till"])}
                  <Tooltip
                    id="embargoed-tooltip"
                    place="bottom"
                    effect="solid"
                    value={t("common.embargoed")}
                    classname={styles["story-time-tooltip"]}
                  />
                </time>
              </div>
            )}
            {generateHighlightedTextBlock(story, "Public-Id: ", "highlighted-public-identifier")}
            {generateHighlightedTextBlock(story, "External-Id: ", "highlighted-external-id")}
          </div>
        </div>
        <div style={{ lineHeight: "30px", fontSize: "small" }}>
          {generateHighlightedTextBlock(story, "Slug: ", "highlighted-slug")}
        </div>
      </div>
      <div className={styles["story-card-actions"]} data-test-id="story-card-actions">
        {/* <div className={styles["story-submit-button"]}>
          <Button type="secondary"> Submit </Button>
        </div> */}
        {showPublicPreviewLink && (
          <a
            data-test-id="public-preview-url-btn"
            href={story["public-preview-url"]}
            target="_blank"
            rel="noopener noreferrer"
            className={styles["story-preview"]}>
            <Preview />
          </a>
        )}
        <StoryCardMenu
          story={story}
          storyStatus={storyStatus}
          canDeleteStory={canDeleteStory}
          switchToDeleteStoryRoute={switchToDeleteStoryRoute}
        />
      </div>
    </div>
  );
};

export default StoryCard;
