/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Attribute } from "api/story-attributes";

enum actions {
  LIST_ATTRIBUTES = "@@attributes/LIST_ATTRIBUTES",
  LIST_ATTRIBUTES_SUCCESS = "@@attributes/LIST_ATTRIBUTES_SUCCESS",
  LIST_ATTRIBUTES_FAILURE = "@@attributes/LIST_ATTRIBUTES_FAILURE",

  LIST_ENTITY_TYPE_SUCCESS = "@@attributes/LIST_ENTITY_TYPE_SUCCESS",
  LIST_ENTITY_TYPE_FAILURE = "@@attributes/LIST_ENTITY_TYPE_FAILURE",

  REPLACE_CURRENT_ATTRIBUTE = "@@attributes/REPLACE_CURRENT_ATTRIBUTE",

  INIT_NEW_ATTRIBUTE = "@@attributes/INIT_NEW_ATTRIBUTE",
  EDIT_ATTRIBUTE = "@@attributes/EDIT_ATTRIBUTE",
  EDIT_ATTRIBUTE_FAILURE = "@@attributes/EDIT_ATTRIBUTE_FAILURE",
  CANCEL_CREATE_OR_EDIT_ATTRIBUTE = "@@attributes/CANCEL_CREATE_OR_EDIT_ATTRIBUTE",

  SAVE_ATTRIBUTE = "@@attributes/SAVE_ATTRIBUTE",
  SAVE_ATTRIBUTE_SUCCESS = "@@attributes/SAVE_ATTRIBUTE_SUCCESS",
  SAVE_ATTRIBUTE_FAILURE = "@@attributes/SAVE_ATTRIBUTE_FAILURE",

  DELETE_ATTRIBUTE = "@@attributes/DELETE_ATTRIBUTE",
  DELETE_ATTRIBUTE_SUCCESS = "@@attributes/DELETE_ATTRIBUTE_SUCCESS",
  DELETE_ATTRIBUTE_FAILURE = "@@attributes/DELETE_ATTRIBUTE_FAILURE"
}

export type ListAttributesAction = { type: actions.LIST_ATTRIBUTES };
export type ListAttributesSuccessAction = {
  type: actions.LIST_ATTRIBUTES_SUCCESS;
  payload: { attributes: Attribute[] };
};
export type ListAttributesFailureAction = { type: actions.LIST_ATTRIBUTES_FAILURE; payload: { error: Error } };
export type ListEntityTypeSuccessAction = {
  type: actions.LIST_ENTITY_TYPE_SUCCESS;
  payload: { entityTypes: string[] };
};
export type ListEntityTypeFailureAction = { type: actions.LIST_ENTITY_TYPE_FAILURE; payload: { error: Error } };

export type ReplaceCurrentAttributeAction = {
  type: actions.REPLACE_CURRENT_ATTRIBUTE;
  payload: { attribute: Attribute | null };
};
export type InitNewAttributeAction = { type: actions.INIT_NEW_ATTRIBUTE };
export type EditAttributeAction = { type: actions.EDIT_ATTRIBUTE; payload: { attribute: Attribute } };
export type EditAttributeFailureAction = { type: actions.EDIT_ATTRIBUTE_FAILURE; payload: { error: Error } };
export type CancelCreateOrEditAttributeAction = {
  type: actions.CANCEL_CREATE_OR_EDIT_ATTRIBUTE;
  payload: { attribute: Attribute };
};

export type SaveAttributeAction = { type: actions.SAVE_ATTRIBUTE; payload: { attribute: Attribute } };
export type SaveAttributeSuccessAction = { type: actions.SAVE_ATTRIBUTE_SUCCESS; payload: { attribute: Attribute } };
export type SaveAttributeFailureAction = {
  type: actions.SAVE_ATTRIBUTE_FAILURE;
  payload: { attribute: Attribute; error: Error };
};

export type DeleteAttributeAction = { type: actions.DELETE_ATTRIBUTE; payload: { attribute: Attribute } };
export type DeleteAttributeSuccessAction = {
  type: actions.DELETE_ATTRIBUTE_SUCCESS;
  payload: { attribute: Attribute };
};
export type DeleteAttributeFailureAction = {
  type: actions.DELETE_ATTRIBUTE_FAILURE;
  payload: { attribute: Attribute; error: Error };
};

export type ActionTypes =
  | ListAttributesAction
  | ListAttributesSuccessAction
  | ListAttributesFailureAction
  | ListEntityTypeSuccessAction
  | ListEntityTypeFailureAction
  | ReplaceCurrentAttributeAction
  | InitNewAttributeAction
  | EditAttributeAction
  | EditAttributeFailureAction
  | CancelCreateOrEditAttributeAction
  | SaveAttributeAction
  | SaveAttributeSuccessAction
  | SaveAttributeFailureAction
  | DeleteAttributeAction
  | DeleteAttributeSuccessAction
  | DeleteAttributeFailureAction;

export default actions;
