/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { removeKeyFromObject } from "utils";
import * as actions from "./actions";
import { WorkspaceRealtimeConfig } from "api/workspace";

const initialFilterSet = {
  "story-templates": [],
  "updated-at": {
    type: null,
    from: undefined,
    to: undefined
  },
  "published-at": {
    type: null,
    from: undefined,
    to: undefined
  },
  term: "",
  sections: [],
  attributes: [],
  authors: [],
  limit: 20,
  source: { source: "all", categories: [] }
};

const initialSavedFilterList = {
  "my-filters": [],
  "shared-filters": []
};

export const initialSelectedSavedFilter = {
  "member-id": null,
  "publisher-id": null,
  name: "",
  filter: {},
  id: null,
  shared: false
};

export const InitialRealtimeConfig: WorkspaceRealtimeConfig = {
  data: {
    aggregations: {
      status: {
        all: 0,
        approved: 0,
        draft: 0,
        "needs-approval": 0,
        open: 0,
        "pending-approval": 0,
        failed: 0,
        "proof-reading-done": 0,
        published: 0,
        rejected: 0,
        scheduled: 0
      }
    },
    computedAt: 0,
    recentStories: [],
    filterHash: ""
  },
  ui: {
    showFetchWorkspaceUpdatePopup: false,
    isUserActiveInScreen: false,
    isUpdatesPending: false
  }
};

const initialState = {
  stories: [],
  aggregations: {
    status: {}
  },
  hits: 1,
  currentFilterSet: initialFilterSet,
  stagingFilterSet: initialFilterSet,
  bulkAccessLevels: [],
  bulkSelectedStoryIds: [],
  bulkSelected: false,
  bulkAccessLevelValue: null,
  isBulkActionEnabled: false,
  allStoriesSelected: false,
  showLoadingIndicator: false,
  filterName: "",
  errors: {},
  selectedSavedFilter: initialSelectedSavedFilter,
  savedFilterList: initialSavedFilterList,
  pageReferer: null,
  isPrintSelected: false,
  realtime: InitialRealtimeConfig
};

const filterSetReducer = (filterSet, action) => {
  const { key, value } = action.payload;
  return { ...filterSet, [key]: value };
};

const addOrRemoveStoryIds = (storyIds, id) =>
  storyIds.includes(id) ? storyIds.filter((storyId) => storyId !== id) : [...storyIds, id];

const addOrRemoveBulkStories = (stories, checked) => (checked ? stories.map((story) => story.id) : []);

const updateBulkSelected = (totalStoryCount, storyIds) => {
  if (totalStoryCount > 20) {
    return storyIds.length >= 20 ? true : false;
  }

  return totalStoryCount <= 20 && totalStoryCount === storyIds.length ? true : false;
};

const workspaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.WORKSPACE_FETCH_DATA:
    case actions.WORKSPACE_FETCH_SAVED_FILTERS: {
      return { ...state, showLoadingIndicator: true };
    }

    case actions.WORKSPACE_RESET_FILTER: {
      return {
        ...state,
        currentFilterSet: initialFilterSet,
        stagingFilterSet: initialFilterSet,
        selectedSavedFilter: initialSelectedSavedFilter,
        isPrintSelected: false,
        filterName: ""
      };
    }

    case actions.WORKSPACE_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload.data,
        showLoadingIndicator: false,
        bulkSelected: false,
        errors: { ...state.errors, fetchingStories: false }
      };
    }

    case actions.WORKSPACE_RESET_STORIES: {
      return {
        ...state,
        stories: []
      };
    }

    case actions.WORKSPACE_UPDATE_STAGING_FILTER: {
      return { ...state, stagingFilterSet: filterSetReducer(state.stagingFilterSet, action) };
    }

    case actions.WORKSPACE_UPDATE_FILTER: {
      return {
        ...state,
        currentFilterSet: filterSetReducer(state.currentFilterSet, action),
        stagingFilterSet: filterSetReducer(state.stagingFilterSet, action)
      };
    }

    case actions.WORKSPACE_PRINT_SOURCE_TOGGLE: {
      return {
        ...state,
        isPrintSelected: action.payload.isPrintSelected
      };
    }
    case actions.WORKSPACE_REPLACE_FILTER: {
      return { ...state, currentFilterSet: action.payload.filterSet };
    }

    case actions.WORKSPACE_FILTER_SAVE: {
      return {
        ...state,
        showLoadingIndicator: true
      };
    }

    case actions.WORKSPACE_UPDATE_ACCESS_LEVEL_VALUE: {
      return { ...state, bulkAccessLevelValue: action.bulkAccessLevelValue };
    }

    case actions.WORKSPACE_ENABLE_BULK_ACTION: {
      return { ...state, isBulkActionEnabled: true };
    }

    case actions.WORKSPACE_DISABLE_BULK_ACTION: {
      return { ...state, isBulkActionEnabled: false };
    }

    case actions.WORKSPACE_SELECT_ALL_STORIES: {
      return {
        ...state,
        allStoriesSelected: true,
        bulkSelectedStoryIds: []
      };
    }

    case actions.WORKSPACE_BULK_SELECT_STORIES: {
      return {
        ...state,
        bulkSelected: action.bulkSelected,
        bulkSelectedStoryIds: addOrRemoveBulkStories(state.stories, action.bulkSelected),
        allStoriesSelected: action.bulkSelected ? state.allStoriesSelected : false
      };
    }

    case actions.WORKSPACE_UPDATE_STORY_IDS: {
      return {
        ...state,
        bulkSelectedStoryIds: addOrRemoveStoryIds(state.bulkSelectedStoryIds, action.id)
      };
    }

    case actions.WORKSPACE_UPDATE_BULK_SELECTED: {
      return {
        ...state,
        bulkSelected: updateBulkSelected(action.tabCounts, state.bulkSelectedStoryIds)
      };
    }

    case actions.WORKSPACE_RESET_BULK_ACCESS_LEVEL_VALUE: {
      return {
        ...state,
        bulkSelectedStoryIds: [],
        bulkSelected: false,
        bulkAccessLevelValue: null,
        allStoriesSelected: false
      };
    }

    case actions.WORKSPACE_UPDATE_FILTER_NAME: {
      return {
        ...state,
        filterName: action.payload.data
      };
    }

    case actions.WORKSPACE_ADD_ERRORS: {
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.payload]: true
        },
        showLoadingIndicator: false
      };
    }

    case actions.WORKSPACE_REMOVE_ERRORS: {
      return {
        ...state,
        errors: removeKeyFromObject(action.payload, state.errors)
      };
    }

    case actions.WORKSPACE_SET_SAVED_FILTER_LIST: {
      return {
        ...state,
        savedFilterList: { ...state.savedFilterList, ...action.payload.data },
        showLoadingIndicator: false
      };
    }

    case actions.WORKSPACE_SET_SELECTED_SAVED_FILTER: {
      return {
        ...state,
        selectedSavedFilter: { ...state.selectedSavedFilter, ...action.payload.filter },
        showLoadingIndicator: false
      };
    }

    case actions.WORKSPACE_REMOVE_LOADER: {
      return {
        ...state,
        showLoadingIndicator: false
      };
    }

    case actions.WORKSPACE_UPDATE_PAGE_REFERER: {
      return {
        ...state,
        pageReferer: action.payload.referer
      };
    }

    case actions.UPDATE_STORY_WORKSPACE_REALTIME_UI: {
      return {
        ...state,
        realtime: {
          ...state.realtime,
          ui: {
            ...state.realtime.ui,
            ...action.payload
          }
        }
      };
    }

    case actions.UPDATE_STORY_WORKSPACE_REALTIME_DATA: {
      return {
        ...state,
        realtime: {
          ...state.realtime,
          data: {
            ...state.realtime.data,
            ...action.payload
          }
        }
      };
    }

    default:
      return state;
  }
};

export default workspaceReducer;
