/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const SETTINGS_SET_ACTIVE_MENU = "@@settings/SETTINGS_SET_ACTIVE_MENU";
export const SETTINGS_SET_SUBMENU = "@@settings/SETTINGS_SET_SUBMENU";

export const LOAD_SOCIAL_SHARE_DATA = "@@settings/LOAD_SOCIAL_SHARE_DATA";
// export const UPDATE_INTEGRATIONS_DATA = "@@settings/UPDATE_INTEGRATIONS_DATA";

// Configure Page
export const LOAD_CONFIGURE_DATA_INIT = "@@settings/LOAD_CONFIGURE_DATA_INIT";
export const LOAD_CONFIGURE_DATA = "@@settings/LOAD_CONFIGURE_DATA";
export const LOAD_CONFIGURE_DATA_FAILURE = "@@settings/LOAD_CONFIGURE_DATA_FAILURE";
export const OPEN_CONFIRMATION_MODEL = "@@settings/OPEN_CONFIRMATION_MODEL";
export const CLOSE_CONFIRMATION_MODEL = "@@settings/CLOSE_CONFIRMATION_MODEL";
export const UPDATE_ACCESS_LEVEL_VALUE = "@@settings/UPDATE_ACCESS_LEVEL_VALUE";
export const UPDATE_CARD_VISIBILITY = "@@settings/UPDATE_CARD_VISIBILITY";
export const RESET_CARD_VISIBILITY = "@@settings/RESET_CARD_VISIBILITY";
export const RESET_ACCESS_LEVEL_VALUE = "@@settings/RESET_ACCESS_LEVEL_VALUE";
export const SET_ACCESS_LEVEL_ERRORS = "@@settings/SET_ACCESS_LEVEL_ERRORS";

export const LOAD_CUSTOM_STORY_TEMPLATES = "@@settings/LOAD_CUSTOM_STORY_TEMPLATES";
export const SET_SELECTED_STORY_TEMPLATE = "@@settings/SET_SELECTED_STORY_TEMPLATE";
export const SET_NEW_STORY_TEMPLATE = "@@settings/SET_NEW_STORY_TEMPLATE";
export const UPDATE_SELECTED_TEMPLATE = "@@settings/UPDATE_SELECTED_TEMPLATE";
export const UPDATE_NEW_STORY_TEMPLATE = "@@settings/UPDATE_NEW_STORY_TEMPLATE";
export const SET_TEMPLATE_ERRORS = "@@settings/SET_TEMPLATE_ERRORS";
export const RESET_TEMPLATE_ERRORS = "@@settings/RESET_TEMPLATE_ERRORS";
export const TOGGLE_SAVE_BUTTON = "@settings/TOGGLE_SAVE_BUTTON";
