/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import formatDate from "date-fns/format";
import _isEqual from "lodash/isEqual";

import { modifySavedFilter } from "../../filter-conversion";

import styles from "./active-filters-list.module.css";
import { t } from "i18n";
import { SavedFilter, FilterSet } from "api/workspace";
import { DateFilter } from "components/date-filter/date-filter";

const getDateFilterDisplay = (dateFilter: DateFilter) => {
  switch (dateFilter.type) {
    case "today": {
      return "Today";
    }
    case "this-week": {
      return "This Week";
    }
    case "this-month": {
      return "This Month";
    }
    case "custom": {
      const { from, to } = dateFilter;
      if (from && to) {
        return `${formatDate(from, "dd/MM/yyyy")} - ${formatDate(to, "dd/MM/yyyy")}`;
      }
      return "";
    }
    default:
      return "";
  }
};

const generateActiveFilters = (filters: FilterSet) => {
  const filterLabels = {
    "story-templates": t("workspace.filter.story-templates"),
    sections: t("workspace.filter.sections"),
    authors: t("workspace.filter.authors"),
    term: t("workspace.filter.term"),
    "updated-at": t("workspace.filter.updated-at"),
    "published-at": t("workspace.filter.published-at"),
    "is-embargoed": t("workspace.filter.embargoed-stories"),
    "is-cloned-to-print": t("workspace.filter.cloned-to-print")
  };

  const activeFilters: any = [];
  let filterSource = filters.source.source;
  filters.term && activeFilters.push(`${filterLabels["term"]} : ${filters.term}`);
  filters["story-templates"].map((template) =>
    activeFilters.push(`${filterLabels["story-templates"]} : ${template.slug}`)
  );
  filters["updated-at"].type &&
    activeFilters.push(`${filterLabels["updated-at"]} : ${getDateFilterDisplay(filters["updated-at"])}`);
  filters["published-at"] && // required for new filter fields to be backward compatible
    filters["published-at"].type &&
    activeFilters.push(`${filterLabels["published-at"]} : ${getDateFilterDisplay(filters["published-at"])}`);
  filters.sections.length &&
    filters.sections.map((section) => activeFilters.push(`${filterLabels["sections"]} : ${section.name}`));

  filters.authors.length &&
    filters.authors.map((author) => activeFilters.push(`${filterLabels["authors"]} : ${author.name}`));

  /* add all attribute to active filters except "distribution-medium". Setting attributeDistributionMedium to true
  if attribute.name === "distribution-medium" so that it can be used to set Source Print.
  activeFilters is used for displaying applied filters on workspace search
  */
  filters["attributes"] &&
    filters["attributes"].forEach((attribute) => {
      if (attribute.name === "distribution-medium" && attribute["values"] && attribute["values"].length) {
        filterSource = "Print";
      } else {
        if (attribute.values.length) {
          activeFilters.push(`${attribute.name} : ${attribute.values.join(", ")}`);
        }
      }
    });

  filters["is-embargoed"] && activeFilters.push(`${filterLabels["is-embargoed"]} : ${filters["is-embargoed"]}`);

  filters["is-cloned-to-print"] &&
    activeFilters.push(`${filterLabels["is-cloned-to-print"]} : ${filters["is-cloned-to-print"]}`);

  filterSource &&
    activeFilters.push(
      filters.source.categories.length > 0
        ? `${filterSource} : ${filters.source.categories.join(", ")}`
        : t(`workspace.filter.sources.${filterSource}`, filterSource)
    );
  return activeFilters;
};

interface ActiveFiltersListProps {
  selectedSavedFilter: SavedFilter;
  filterSet: FilterSet;
  clearFilters?(): void;
  saveFilter(): void;
  updateFilter(): void;
  isDesktopSizeViewport: boolean;
}

const ActiveFiltersList: React.FC<ActiveFiltersListProps> = ({
  selectedSavedFilter,
  filterSet,
  clearFilters,
  saveFilter,
  updateFilter,
  isDesktopSizeViewport
}) => {
  const activeFilters = generateActiveFilters(filterSet);
  if (activeFilters.length) {
    return (
      <div
        className={styles["workspace-selected-filters-container"]}
        data-test-id="workspace-selected-filters-container">
        <div className={styles["workspace-selected-filters"]} data-test-id="workspace-selected-filters">
          {activeFilters.map((filter, index) => (
            <span className={styles["workspace-filter-label"]} data-test-id="workspace-filter-label" key={index}>
              {filter}
            </span>
          ))}
        </div>
        {isDesktopSizeViewport && (
          <>
            {!selectedSavedFilter.id && (
              <button
                data-test-id="save-selected-filters-btn"
                className={styles["save-selected-filters"]}
                onClick={saveFilter}>
                {t("workspace.active_filters_list.save_filter", { count: 1 })}
              </button>
            )}
            {selectedSavedFilter.id && !_isEqual(filterSet, modifySavedFilter(selectedSavedFilter).filter) && (
              <React.Fragment>
                <button
                  className={styles["update-selected-filters"]}
                  data-test-id="update-selected-filters-btn"
                  onClick={updateFilter}>
                  {t("workspace.active_filters_list.update_filter", { count: 1 })}
                </button>
                <button
                  className={styles["save-selected-filters"]}
                  data-test-id="save-selected-filters-btn"
                  onClick={saveFilter}>
                  {t("workspace.active_filters_list.save_filter_as")}
                </button>
              </React.Fragment>
            )}
          </>
        )}
        <button
          className={styles["clear-selected-filters"]}
          data-testid="clear-selected-filters-btn"
          onClick={clearFilters}>
          {t("workspace.active_filters_list.clear_filter", { count: 1 })}
        </button>
      </div>
    );
  } else {
    return null;
  }
};

export default ActiveFiltersList;
