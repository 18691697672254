/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import { Router as TasksRouter } from "./tasks/router";
import { USER_PROFILE_PATH } from "./routes";
import Profile from "./profile/profile";

export const Router = () => {
  return (
    <Switch>
      <Route path="/user/tasks" component={TasksRouter} />
      <Route exact path={USER_PROFILE_PATH} component={Profile} />
    </Switch>
  );
};
