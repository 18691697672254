/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import styles from "./render-multiple-row.module.css";
import Trash from "components/icons/trash";
import FieldLabel from "components/field-label/field-label";
import PlusCircle from "components/icons/plus-circle";
import classnames from "classnames/bind";

const cx = classnames.bind(styles);

interface Props {
  name: string;
  selectedLHSValues: Array<any>;
  maxRows?: number;
  columnRenders: Array<(index: number) => JSX.Element>;
  columnLabels: Array<string>;
  onDelete: (value: Row[]) => void;
  labelAdd?: string;
  disabled?: boolean;
  onAdd?: (value: Row[]) => void;
  hideAddButton?: boolean;
  disableDeleteButton?: boolean;
}

type Row = string | null | object;

interface State {
  rows: Row[];
}

const NEW_ROW = null;

class RenderMultipleRow extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      rows:
        !this.props.selectedLHSValues || (this.props.selectedLHSValues && this.props.selectedLHSValues.length === 0)
          ? [NEW_ROW]
          : this.props.selectedLHSValues && [...this.props.selectedLHSValues]
    };
  }

  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    if (nextProps.selectedLHSValues !== prevState.rows) {
      let rows;
      if (nextProps.selectedLHSValues.length > prevState.rows.length) {
        rows = nextProps.selectedLHSValues;
      } else if (
        nextProps.selectedLHSValues.length === prevState.rows.length &&
        prevState.rows[prevState.rows.length - 1] === null
      ) {
        rows = nextProps.selectedLHSValues;
      } else {
        rows = prevState.rows;
      }
      return { rows: rows };
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.selectedLHSValues !== this.props.selectedLHSValues) {
      this.setState({ rows: this.props.selectedLHSValues.length === 0 ? [NEW_ROW] : this.props.selectedLHSValues });
    }
  }

  createRow = () => {
    const newRows = [...this.state.rows, NEW_ROW];
    this.setState({ rows: newRows });
    this.props.onAdd && this.props.onAdd(newRows);
  };

  onDeleteAction = (index: number) => {
    const newRows = [...this.state.rows];
    newRows.splice(index, 1);
    this.props.onDelete(newRows);
  };

  render() {
    const {
      columnRenders,
      columnLabels,
      labelAdd,
      name,
      maxRows,
      disabled,
      hideAddButton,
      disableDeleteButton
    } = this.props;
    const totalNumberOfRows = this.state.rows.length;
    return (
      <div className={styles["multiple-row-container"]}>
        <div className={styles["multiple-row-container__header"]} data-test-id="multiple-row-container-header">
          {columnLabels.map((label, index) => (
            <FieldLabel label={label} id={`column-label-${index}`} />
          ))}
        </div>
        {this.state.rows.map((selectedLHS, index: number) => {
          return (
            <div
              key={`${name}-${index}`}
              className={styles["multiple-row-container__row"]}
              data-test-id="multiple-row-container-row">
              <span className={styles["multiple-row-container__row-inputs"]}>
                {columnRenders.map((renderer) => renderer(index))}
              </span>
              <span
                className={cx("multiple-row-container__trash-icon", {
                  "multiple-row-container__trash-icon--disabled": disableDeleteButton
                })}
                data-test-id="multiple-row-container-delete-btn"
                onClick={() => !disabled && !disableDeleteButton && this.onDeleteAction(index)}>
                {!disabled && <Trash width={20} height={20} />}
              </span>
            </div>
          );
        })}
        {!disabled && totalNumberOfRows !== maxRows && !hideAddButton && (
          <span
            data-test-id="multiple-row-container-add-btn"
            onClick={() => this.createRow()}
            className={styles["multiple-row-container__add"]}>
            <PlusCircle width={"24"} height={"24"} color={"var(--brand-3)"} />
            {labelAdd && (
              <span data-test-id="add-label" className={styles["multiple-row-container__add-label"]}>
                {labelAdd}
              </span>
            )}
          </span>
        )}
      </div>
    );
  }
}

export default RenderMultipleRow;
