/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { findElementNP } from "pages/story-editor/operations/find";
import { addIfNotExists } from "utils/array.utils";
import { setTextSelectionToDocumentEnd } from "pages/story-editor/operations/selection";
import { CardId } from "api/primitive-types";
import { AnyStory, StoryElement, CompositeStoryElement, ChildStoryElement } from "api/story";
import { EditorState } from "prosemirror-state";

function move(arr: Array<any>, from: number, to: number): Array<any> {
  let array = arr.slice();

  let element = array[from];
  array[from] = array[to];
  array[to] = element;

  return array;
}

function updateUpdatedCard(updatedCards: Array<String>, cardId: CardId): Array<String> {
  return cardId ? addIfNotExists(updatedCards, cardId) : updatedCards;
}

function moveStoryElementUpWithinCompositeElement(
  story: AnyStory,
  parentStoryElement: CompositeStoryElement,
  elementIndex: number
): AnyStory {
  const updatedCards = updateUpdatedCard(story["updated-cards"], parentStoryElement["card-id"]);
  const parentStoryElementTree = parentStoryElement.tree;

  return {
    ...story,
    "story-elements": {
      ...story["story-elements"],
      [parentStoryElement.id]: {
        ...story["story-elements"][parentStoryElement.id],
        tree: move(parentStoryElementTree, elementIndex, elementIndex - 1)
      }
    },
    "updated-cards": updatedCards
  };
}

function moveStoryElementUpWithinCompositeElementNode(
  editorState: EditorState,
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement,
  previousStoryElement: StoryElement | CompositeStoryElement | ChildStoryElement
): EditorState {
  const tr = editorState.tr;
  const elementNP = findElementNP(editorState, storyElement);
  const previousElementNP = findElementNP(editorState, previousStoryElement);
  if (elementNP && previousElementNP) {
    let elementNode = elementNP.node;

    tr.delete(elementNP.pos, elementNP.pos + elementNode.nodeSize);
    tr.insert(previousElementNP.pos, elementNode);
    const trWithFocus = setTextSelectionToDocumentEnd(tr, editorState);
    return editorState.apply(trWithFocus);
  }
  return editorState;
}

function moveStoryElementUpInCompositeElements(
  story: AnyStory,
  editorState: EditorState,
  storyElement: ChildStoryElement,
  parentStoryElement: CompositeStoryElement
): {
  story: AnyStory;
  editorState: EditorState;
} {
  const parentStoryElementTree = parentStoryElement.tree;

  const currentStoryElementIndex = parentStoryElementTree.findIndex(
    (storyElementId) => storyElementId === storyElement.id
  );

  const previousStoryElementId = parentStoryElementTree[currentStoryElementIndex - 1];
  const previousStoryElement = story["story-elements"][previousStoryElementId];

  const newStory = moveStoryElementUpWithinCompositeElement(story, parentStoryElement, currentStoryElementIndex);
  const newEditorState = moveStoryElementUpWithinCompositeElementNode(editorState, storyElement, previousStoryElement);

  return { story: newStory, editorState: newEditorState };
}

export default moveStoryElementUpInCompositeElements;
