/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";

import Inspector from "components/inspector/inspector";
import Select from "components/select/select";
import { PartialAppState, InspectorType } from "../state";
import { navigate } from "utils/routes.utils";
import { SOCIAL_ACCOUNTS_PATH } from "../routes";
import { cancelAddingConnection, onChangeOFService } from "../action-creators";
import styles from "./inspector.module.css";

interface DispatchProps {
  cancelAddingConnection: () => void;
  onChangeOFService: (value: string) => void;
}

interface StateProps {
  inspector: InspectorType | null;
  selectedService: string;
}

type Props = DispatchProps & StateProps;

export const SocialAccountInspector: React.SFC<Props> = ({
  inspector,
  cancelAddingConnection,
  onChangeOFService,
  selectedService
}) => {
  const socialLinks = {
    facebook: {
      label: t("settings.socialLinks.add-facebook"),
      link: "/admin/add-social-account?service=facebook&format=itsman-x"
    },
    twitter: {
      label: t("settings.socialLinks.add-twitter"),
      link: "/admin/add-social-account?service=twitter&format=itsman-x"
    },
    linkedin: {
      label: t("settings.socialLinks.add-linkedin"),
      link: "/admin/add-social-account?service=linkedin&format=itsman-x"
    }
  };
  const availableOptions = [
    { label: t("settings.socialLinks.facebook"), value: "facebook" },
    { label: t("settings.socialLinks.twitter"), value: "twitter" },
    { label: t("settings.socialLinks.linkedin"), value: "linkedin" }
  ];
  const selectedOption = availableOptions.find((o) => o.value === selectedService);
  return (
    <Inspector
      title={t("social_share.inspector_title")}
      isActive={!!inspector}
      onClose={() => cancelAddingConnection()}>
      <Select
        options={availableOptions}
        getOptionLabel={(item) => item.label}
        getOptionValue={(item) => item.value}
        value={selectedOption}
        onChange={(item: { label: string; value: string }) => onChangeOFService(item.value)}
        placeholder={t("common.select-placeholder")}
      />

      {selectedService && (
        <a className={styles["connect-to-social-account-link"]} href={socialLinks[selectedService].link}>
          {socialLinks[selectedService].label}
        </a>
      )}
    </Inspector>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    inspector: state.socialShare.ui.inspector,
    selectedService: state.socialShare.ui.selectedService
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchProps => {
  return {
    cancelAddingConnection: () => {
      dispatch(navigate(SOCIAL_ACCOUNTS_PATH));
      dispatch(cancelAddingConnection());
    },
    onChangeOFService: (value) => dispatch(onChangeOFService(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialAccountInspector);
