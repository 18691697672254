/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import CreatableSelect from "components/select/creatable";

type Values = Array<string>;

type Props = {
  attribute: {
    "display-name": string;
  };
  value: Values;
  onChange: (value: Values) => void;
  options: Array<string>;
  errorMessage?: string;
  disabled?: boolean;
};

type State = {
  inputValue: string;
};

export default class FreeTextAttributeInput extends React.Component<Props, State> {
  state = {
    inputValue: ""
  };

  handleInputChange = (inputValue: string) => {
    this.setState({ inputValue });
  };

  handleKeyDown = (event: React.KeyboardEvent) => {
    const { inputValue } = this.state;
    switch (event.key) {
      case "Enter":
      case "Tab":
        this.setState({
          inputValue: ""
        });
        this.props.onChange([...this.props.value, inputValue]);
        event.preventDefault();
        break;
      default:
    }
  };

  isValidNewOption = (inputValue: string, selectValue: any, selectOptions: Values) => {
    if (inputValue.trim().length === 0 || selectOptions.find((option) => option === inputValue)) {
      return false;
    }
    return true;
  };

  render() {
    const { inputValue } = this.state,
      { attribute, value, onChange, options, errorMessage, disabled } = this.props;
    return (
      <CreatableSelect
        options={options}
        inputValue={inputValue}
        getNewOptionData={(inputValue, optionLabel) => inputValue}
        value={value}
        onChange={onChange}
        onInputChange={this.handleInputChange}
        isMulti={true}
        onKeyDown={this.handleKeyDown}
        label={attribute["display-name"]}
        getOptionLabel={(tag) => tag}
        getOptionValue={(tag) => tag}
        isValidNewOption={this.isValidNewOption}
        errorMessage={errorMessage}
        isDisabled={disabled}
      />
    );
  }
}
