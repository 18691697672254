/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { INITIAL_STATE } from "pages/settings/initial-state";
import actions, { ActionTypes } from "./actions";

export default function storySlugSettingsReducer(state = INITIAL_STATE, action: ActionTypes) {
  switch (action.type) {
    case actions.UPDATE_STORY_SLUG_SETTINGS:
      return {
        ...state,
        storySlug: {
          ...state.storySlug,
          ...action.payload
        }
      };

    case actions.ENABLE_STORY_SLUG_SAVE_BUTTON:
      return {
        ...state,
        ui: {
          ...state.ui,
          storySlug: {
            ...state.ui.storySlug,
            isSaveButtonEnabled: true
          }
        }
      };

    case actions.DISABLE_STORY_SLUG_SAVE_BUTTON:
      return {
        ...state,
        ui: {
          ...state.ui,
          storySlug: {
            ...state.ui.storySlug,
            isSaveButtonEnabled: false
          }
        }
      };

    default:
      return state;
  }
}
