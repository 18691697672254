/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { t } from "i18n";

import { Contribution } from "api/story";
import { PartialAppState } from "pages/story-editor/state";
import { updateStoryAuthorContributions } from "../../async-action-creators";

import Contributions from "./contributions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

export interface AuthorWithRoleProps {
  storyAuthorContributions: Contribution[];
  updateStoryAuthorContributions: (contributions: Contribution[]) => void;
  disabled?: boolean;
}

export const AuthorsWithRoles: React.SFC<AuthorWithRoleProps> = ({
  storyAuthorContributions,
  updateStoryAuthorContributions,
  disabled
}) => {
  return (
    <Contributions
      contributionsTitle={t("story-editor.inspector.authors")}
      storyContributions={storyAuthorContributions}
      updateStoryContributions={updateStoryAuthorContributions}
      disabled={disabled}
    />
  );
};

const mapStateToProps = (state: PartialAppState) => {
  return {
    storyAuthorContributions: state.storyEditor.app.storyAuthorContributions
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
  return {
    updateStoryAuthorContributions: (contributions: Contribution[]) =>
      dispatch(updateStoryAuthorContributions(contributions))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthorsWithRoles);
