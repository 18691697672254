/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Checkbox from "components/checkbox/checkbox";
import DatePicker from "components/date-picker/date-picker";
import styles from "./social-card-schedule.module.css";

import { t } from "i18n";
import { SocialCard } from "api/social";

interface SocialCardScheduleProps {
  socialCard: SocialCard;
  updateSocialCard(cardId: string, key: string, value: string | boolean): void;
  isPublishedStory?: boolean;
  isStoryLocked: boolean;
}

const SocialCardSchedule = ({
  socialCard,
  updateSocialCard,
  isPublishedStory,
  isStoryLocked
}: SocialCardScheduleProps) => {
  return (
    <div className={styles["social-card-publish-container"]}>
      <Checkbox
        id={`social-account-${socialCard.cardId}`}
        disabled={isPublishedStory || isStoryLocked}
        label={t("story-editor.inspector.scheduled")}
        checked={socialCard.isScheduled}
        onChange={(checked) => updateSocialCard(socialCard.cardId, "isScheduled", checked)}
        classname={styles["social-card-schedule-checkbox"]}
      />

      {socialCard.isScheduled && (
        <DatePicker
          dateFormat={"dd/MM/yyyy h:mm a"}
          disabled={isPublishedStory || isStoryLocked}
          onChange={(day: string | boolean) => updateSocialCard(socialCard.cardId, "scheduleAt", day)}
          selectedDate={socialCard.scheduleAt}
          showTimeSelect
        />
      )}
    </div>
  );
};

export default SocialCardSchedule;
