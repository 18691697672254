/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import startCase from "lodash/startCase";

const generateSelectOptions = (options) =>
  options.map((option) => {
    return { label: startCase(option), value: option };
  });

export { generateSelectOptions };
