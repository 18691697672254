/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { t } from "i18n";
import { get } from "lodash";
import { WEBHOOKS_PATH } from "../../routes";
import { navigate } from "utils/routes.utils";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { PartialAppState, EventDetails, createWebhook, setNewWebhook, ValidationError } from "store/webhook/webhook";

import Inspector from "components/inspector/inspector";
import { NewWebhook } from "api/webhooks";
import { SecretEntry, TriggerEvents, UrlEntry } from "../inspector-inputs/inspector-inputs";

interface OwnProps {
  isActive: boolean;
}

interface StateProps {
  newWebhook: NewWebhook | null;
  publisherTriggerEvents: EventDetails[];
  validationErrors: ValidationError | null;
}

interface DispatchProps {
  onClose: VoidFunction;
  onChange(data: Partial<NewWebhook>): void;
  onSave(data: NewWebhook): void;
}

type Props = OwnProps & StateProps & DispatchProps;

function AddInspector({
  newWebhook,
  publisherTriggerEvents,
  isActive,
  onClose,
  onChange,
  onSave,
  validationErrors
}: Props) {
  useEffect(() => {
    isActive && !newWebhook && onClose();
  }, [isActive, newWebhook, onClose]);

  const { url = "", secret = "", events = [] } = newWebhook || {};

  return (
    <Inspector
      title={t("settings.webhooks.inspector.add_title")}
      actionButtonLabel={t("settings.webhooks.inspector.action_button_label")}
      onActionButtonClick={() => {
        newWebhook && onSave(newWebhook);
      }}
      isActive={isActive}
      onClose={onClose}
      isActionButtonDisabled={!!validationErrors || !url.length}
      isBackgroundActionDisabled={true}>
      <div>
        <UrlEntry
          url={url}
          handleUrlChange={(newUrl) => onChange({ url: newUrl })}
          error={get(validationErrors, "url", [])}
        />
        <SecretEntry secret={secret} handleSecretChange={(newSecret) => onChange({ secret: newSecret })} />
        <TriggerEvents
          publisherTriggerEvents={publisherTriggerEvents}
          selectedTriggerEventIds={events}
          handleEventToggle={(eventId, checked) => {
            if (newWebhook) {
              if (checked) {
                onChange({ events: [...newWebhook.events, eventId] });
              } else {
                onChange({ events: newWebhook.events.filter((event) => event !== eventId) });
              }
            }
          }}
        />
      </div>
    </Inspector>
  );
}

function mapStateToProps(state: PartialAppState): StateProps {
  return {
    newWebhook: state.webhooks.app.newWebhook,
    publisherTriggerEvents: state.config["trigger-events"] || [],
    validationErrors: state.webhooks.app.error
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps {
  return {
    onClose: () => dispatch(navigate(WEBHOOKS_PATH)),
    onChange: (data) => dispatch(setNewWebhook(data)),
    onSave: (data) => dispatch(createWebhook(data))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddInspector);
export { AddInspector };
