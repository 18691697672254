/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { createStore, combineReducers, applyMiddleware } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { createActionLog } from "redux-action-log";
import { composeWithDevTools } from "redux-devtools-extension";
import { configReducer, featuresReducer } from "./store/route-data";
import watermarkImagesReducer from "./store/watermark-images";
import pushNotificationChannelsReducer from "./store/push-notification-channels";
import notificationReducer from "./reducers/notification";
import workspaceReducer from "./pages/workspace/reducer";
import collectionsReducer from "pages/collections/reducer";
import mediaLibraryReducer from "./pages/media-library/reducer";
import entitiesReducer from "./pages/manage/entities/reducer";
import storyEditorReducer from "./pages/story-editor/reducer";
import advancedSettingsReducer from "./pages/advanced-settings/reducer";
import settingsReducer from "./pages/settings/reducer";
import sectionsReducer from "./pages/manage/sections/reducer";
import customURLsReducer from "./pages/manage/custom-urls/reducer";
import pushNotificationReducer from "./pages/content/push-notification/reducer";
import staticPagesReducer from "./pages/content/static-pages/reducer";
import menuReducer from "./pages/manage/menu/reducer";
import attributesReducer from "./pages/manage/attributes/reducer";
import customFieldsReducer from "./pages/manage/custom-fields/reducer";
import tagsReducer from "./pages/manage/tags/reducer";
import createHistory from "history/createBrowserHistory";
import integrationReducer from "./pages/settings/pages/integrations/integrations-reducer";
import ampReducer from "pages/settings/pages/configure/amp-config/amp-config-reducer";
import usersAndRolesReducer from "pages/settings/pages/users-and-roles/users-and-roles-reducer";
import socialShareReducer from "pages/settings/pages/social-share/social-share-reducer";
import promotionalMessagesReducer from "pages/settings/pages/configure/promotional-messages/promotional-messages-reducer";
import contributorRolesReducer from "pages/settings/pages/configure/contributor-roles/reducer";
import breakingNewsDefaultsReducer from "pages/settings/pages/configure/breaking-news/reducer";
import defaultWatermarkImagePageReducer from "pages/settings/pages/configure/watermark-image/reducer";
import breakingNewsReducer from "pages/breaking-news/reducer";
import tasksReducer from "pages/user/tasks/reducer";
import loginReducer from "pages/login/reducer";
import collectionsConfigureReducer from "pages/settings/pages/configure/collections/reducer";
import analyticsReducer from "pages/analytics/reducer";
import sitemapReducer from "./store/sitemap";
import secretModeReducer from "./store/secret-mode/secret-mode";
import viewportReducer from "./store/viewport";
import domainReducer from "./store/domain/domain";
import featureReducer from "./store/feature/feature";
import errorPageReducer from "./store/error-page";
import formsReducer from "./store/form/form";
import webhooksReducer from "./store/webhook/webhook";
import collectionsDashboardReducer from "./store/collection/collections-dashboard";
import consumersReducer from "./store/consumer/consumer";
import billingReducer from "./store/billing/billing";
import sliderReducer from "./store/slider/slider";

const history = createHistory({
    getUserConfirmation(message, callback) {
      const result = window.confirm(message);
      callback(result);
    }
  }),
  middleware = [thunk, routerMiddleware(history)];

const reduxActionLog = createActionLog({ limit: 10 });

const store = createNewStore();

function createNewStore(defaults?) {
  return createStore(
    combineReducers({
      config: configReducer,
      features: featuresReducer,
      notification: notificationReducer,
      workspace: workspaceReducer,
      collections: collectionsReducer,
      collectionsDashboard: collectionsDashboardReducer,
      mediaLibrary: mediaLibraryReducer,
      sections: sectionsReducer,
      pushNotification: pushNotificationReducer,
      staticPages: staticPagesReducer,
      menu: menuReducer,
      attributes: attributesReducer,
      customFields: customFieldsReducer,
      tags: tagsReducer,
      entities: entitiesReducer,
      storyEditor: storyEditorReducer,
      router: connectRouter(history),
      advancedSettings: advancedSettingsReducer,
      settingsPage: settingsReducer,
      integrations: integrationReducer,
      ampConfig: ampReducer,
      collectionsConfig: collectionsConfigureReducer,
      usersAndRoles: usersAndRolesReducer,
      consumers: consumersReducer,
      billing: billingReducer,
      socialShare: socialShareReducer,
      promotionalMessages: promotionalMessagesReducer,
      contributorRoles: contributorRolesReducer,
      breakingNewsDefaults: breakingNewsDefaultsReducer,
      watermarkImage: watermarkImagesReducer,
      pushNotificationChannels: pushNotificationChannelsReducer,
      defaultWatermarkImagePage: defaultWatermarkImagePageReducer,
      breakingNews: breakingNewsReducer,
      login: loginReducer,
      tasks: tasksReducer,
      customURLs: customURLsReducer,
      analytics: analyticsReducer,
      sitemap: sitemapReducer,
      secretMode: secretModeReducer,
      viewport: viewportReducer,
      domain: domainReducer,
      feature: featureReducer,
      errorPage: errorPageReducer,
      forms: formsReducer,
      webhooks: webhooksReducer,
      slider: sliderReducer
    }),
    defaults,
    composeWithDevTools(applyMiddleware(...middleware), reduxActionLog.enhancer)
  );
}

export { store, history, createNewStore as createStore, reduxActionLog };
