/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as actions from "../../actions";
import { INITIAL_STATE } from "../../initial-state";
import { sortBy } from "lodash";

export const getSelectedCategoryToggles = (configureData, categoryName) =>
  configureData && sortBy(configureData.filter((feature) => feature["category-name"] === categoryName), "name");

export const getAccessLevelFeature = (configureData) => {
  const AccessLevel = configureData && configureData.find((item) => item.slug === "access-level-values");
  return AccessLevel ? AccessLevel : null;
};
// const getPromotionalMessageFeature = (features) => {
//   const promotionalFeature = features.find((feature) => feature.slug === "promotional-message");
//   return promotionalFeature ? promotionalFeature : null;
// };

export default function configureReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actions.LOAD_CONFIGURE_DATA: {
      const accessLevelFeature = getAccessLevelFeature(action.payload.data);
      return {
        ...state,
        features: getSelectedCategoryToggles(action.payload.data, "Features"),
        storyElements: getSelectedCategoryToggles(action.payload.data, "Story Elements"),
        storyTemplates: getSelectedCategoryToggles(action.payload.data, "Story Templates"),
        accessLevel: accessLevelFeature,
        noOfVisibleCards: accessLevelFeature.config && accessLevelFeature.config["no-of-visible-cards"],
        configure: action.payload.data,
        ui: {
          ...state.ui,
          main: { loading: false, error: null }
        }
      };
    }

    case actions.LOAD_CONFIGURE_DATA_INIT: {
      return {
        ...state,
        ui: {
          ...state.ui,
          main: { loading: true, error: null }
        }
      };
    }

    case actions.LOAD_CONFIGURE_DATA_FAILURE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          main: { loading: false, error: action.payload }
        }
      };
    }

    case actions.UPDATE_ACCESS_LEVEL_VALUE: {
      return {
        ...state,
        accessLevelValue: action.payload
      };
    }

    case actions.UPDATE_CARD_VISIBILITY: {
      return {
        ...state,
        noOfVisibleCards: action.payload
      };
    }

    case actions.TOGGLE_SAVE_BUTTON: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isAccessLevelConfigModified: action.payload
        }
      };
    }

    case actions.RESET_ACCESS_LEVEL_VALUE: {
      return {
        ...state,
        accessLevelValue: "",
        ui: {
          ...state.ui,
          errors: {}
        }
      };
    }
    case actions.RESET_TEMPLATE_ERRORS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          errors: {}
        }
      };
    }

    case actions.LOAD_CUSTOM_STORY_TEMPLATES: {
      return {
        ...state,
        customStoryTemplates: action.payload.data.templates.story
        // defaultFieldsForAllTemplates: action.payload.data.templates.story.all.config.fields
      };
    }

    case actions.SET_SELECTED_STORY_TEMPLATE: {
      return {
        ...state,
        selectedStoryTemplate: action.payload.selectedTemplate
      };
    }

    case actions.SET_NEW_STORY_TEMPLATE: {
      return {
        ...state,
        newStoryTemplate: {
          ...state.customStoryTemplates["all"],
          name: ""
        }
      };
    }

    case actions.UPDATE_NEW_STORY_TEMPLATE: {
      if (action.payload.key === "name") {
        return {
          ...state,
          newStoryTemplate: {
            ...state.newStoryTemplate,
            name: action.payload.changes
          }
        };
      } else if (state.newStoryTemplate) {
        return {
          ...state,
          newStoryTemplate: {
            ...state.newStoryTemplate,
            config: {
              ...state.newStoryTemplate.config,
              fields: {
                ...state.newStoryTemplate.config.fields,
                [action.payload.key]: {
                  ...state.newStoryTemplate.config.fields[action.payload.key],
                  validations: {
                    ...state.newStoryTemplate.config.fields[action.payload.key].validations,
                    ...action.payload.changes
                  }
                }
              }
            }
          }
        };
      } else {
        return {
          ...state
        };
      }
    }

    case actions.UPDATE_SELECTED_TEMPLATE: {
      if (state.selectedStoryTemplate) {
        return {
          ...state,
          selectedStoryTemplate: {
            ...state.selectedStoryTemplate,
            config: {
              ...state.selectedStoryTemplate.config,
              fields: {
                ...state.selectedStoryTemplate.config.fields,
                [action.payload.key]: {
                  ...state.selectedStoryTemplate.config.fields[action.payload.key],
                  validations: {
                    ...state.selectedStoryTemplate.config.fields[action.payload.key].validations,
                    ...action.payload.changes
                  }
                }
              }
            }
          }
        };
      }
      return {
        ...state
      };
    }

    case actions.SET_TEMPLATE_ERRORS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          errors: action.payload.errors
        }
      };
    }

    case actions.SET_ACCESS_LEVEL_ERRORS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          errors: action.payload.errors
        }
      };
    }

    case actions.OPEN_CONFIRMATION_MODEL: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isConfirmationModelVisible: true,
          selectedAccessLevelValueIndexToBeDeleted: action.payload.selectedAccessLevelValueIndex
        }
      };
    }

    case actions.CLOSE_CONFIRMATION_MODEL: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isConfirmationModelVisible: false,
          selectedAccessLevelValueIndexToBeDeleted: null
        }
      };
    }

    default:
      return state;
  }
}
