/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const JsEmbed = ({ width = 24, height = 24, fill = "#c6ccd5" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    fill={fill}>
    <defs />
    <path d="M14.07 4.5L8.9 18.98l-.05.12 1.08.4L15.1 5.02l.04-.12-1.07-.4zM2.5 11.88l4.92 4.87.8-.82-4.09-4.05 4-3.96.09-.09-.8-.82-4.92 4.87zM15.78 7.83l4.09 4.05-4 3.96-.09.09.79.82 4.93-4.87-4.93-4.87-.79.82z" />
  </svg>
);

export default JsEmbed;
