/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "../../../store";
import { EditorView } from "prosemirror-view";
import { Schema } from "prosemirror-model";
import { EditorState } from "prosemirror-state";

export default class ReactPluginView {
  component: typeof React.Component;
  parent: (Node & ParentNode) | null;
  dom: HTMLElement;

  constructor(view: EditorView, component: typeof React.Component, { domAttributes = {} }) {
    this.component = component;
    this.parent = view.dom.parentNode;
    this.dom = document.createElement("div");
    for (const key in domAttributes) {
      if (domAttributes.hasOwnProperty(key)) {
        this.dom.setAttribute(key, domAttributes[key]);
      }
    }
    this.parent && this.parent.appendChild(this.dom);
    this.render(view);
  }

  update(view: EditorView, prevState: EditorState<Schema>) {
    if (view.state.doc === prevState.doc && view.state.selection === prevState.selection) {
      return;
    }

    this.render(view);
  }

  destroy() {
    return this.parent && this.parent.removeChild(this.dom);
  }

  render(view: EditorView) {
    const Component = this.component;
    ReactDOM.render(
      <React.Fragment>
        <Provider store={store}>
          <Component view={view} state={view.state} dispatch={view.dispatch} />
        </Provider>
      </React.Fragment>,
      this.dom
    );
  }
}
