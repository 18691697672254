/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, {
  ListCustomURLsAction,
  ListCustomURLsSuccessAction,
  ListCustomURLsFailureAction,
  UpdateCustomUrlsPagination,
  SetCustomUrlsSearchTerm,
  SetCurrentCustomURL,
  UpdateCurrentCustomUrl,
  CancelSaveCurrentCustomURL,
  SaveCustomURL,
  SaveCustomURLSuccess,
  SaveCustomURLFailure,
  DeleteCustomURL,
  DeleteCustomURLSuccess,
  DeleteCustomURLFailure,
  ToggleDeleteConfirmation
} from "./actions";
import { CustomURL, AnyCustomURL } from "api/custom-url";
import { CustomURLsPagination } from "./state";

export function listCustomURLsAction(): ListCustomURLsAction {
  return { type: actions.LIST_CUSTOM_URLS };
}

export function listCustomURLsSuccessAction(customURLsResponse: {
  "custom-urls": CustomURL[];
  pagination: number;
}): ListCustomURLsSuccessAction {
  return {
    type: actions.LIST_CUSTOM_URLS_SUCCESS,
    payload: { customURLs: customURLsResponse["custom-urls"], pagination: customURLsResponse.pagination }
  };
}

export function listCustomURLsFailureAction(error: Error): ListCustomURLsFailureAction {
  return { type: actions.LIST_CUSTOM_URLS_FAILURE, payload: { error } };
}

export function updateCustomUrlsPagination(pagination: CustomURLsPagination): UpdateCustomUrlsPagination {
  return {
    type: actions.UPDATE_CUSTOM_URLS_PAGINATION,
    payload: { pagination }
  };
}

export function setCustomUrlsSearchTerm(searchTerm: string): SetCustomUrlsSearchTerm {
  return {
    type: actions.SET_CUSTOM_URLS_SEARCH_TERM,
    payload: { searchTerm }
  };
}

export function setCurrentCustomURL(customURL: AnyCustomURL): SetCurrentCustomURL {
  return {
    type: actions.SET_CURRENT_CUSTOM_URL,
    payload: { customURL }
  };
}

export function updateCurrentCustomURLAction(customURL: AnyCustomURL): UpdateCurrentCustomUrl {
  return {
    type: actions.UPDATE_CURRENT_CUSTOM_URL,
    payload: { customURL }
  };
}

export function cancelSaveCurrentCustomURLAction(): CancelSaveCurrentCustomURL {
  return {
    type: actions.CANCEL_SAVE_CURRENT_CUSTOM_URL
  };
}

export function saveCustomURLAction(): SaveCustomURL {
  return {
    type: actions.SAVE_CUSTOM_URL
  };
}

export function saveCustomURLSuccess(): SaveCustomURLSuccess {
  return {
    type: actions.SAVE_CUSTOM_URL_SUCCESS
  };
}

export function saveCustomURLFailure(error: Error): SaveCustomURLFailure {
  return {
    type: actions.SAVE_CUSTOM_URL_FAILURE,
    payload: { error }
  };
}

export function deleteCustomURLAction(): DeleteCustomURL {
  return {
    type: actions.DELETE_CUSTOM_URL
  };
}

export function deleteCustomURLSuccess(): DeleteCustomURLSuccess {
  return {
    type: actions.DELETE_CUSTOM_URL_SUCCESS
  };
}

export function deleteCustomURLFailure(error: Error): DeleteCustomURLFailure {
  return {
    type: actions.DELETE_CUSTOM_URL_FAILURE,
    payload: { error }
  };
}

export function toggleDeleteConfirmationAction(): ToggleDeleteConfirmation {
  return {
    type: actions.TOGGLE_DELETE_CONFIRMATION
  };
}
