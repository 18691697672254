/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { connect } from "react-redux";
import { compose } from "redux";
import { PartialAppState } from "pages/story-editor/state";
import Spinner from "components/spinner/spinner";
import styles from "./plagiarism-report.module.css";

type Props = StateProps;
class PlagiarismReport extends React.Component<Props, { showResults: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showResults: false
    };
  }

  showResults() {
    this.setState({ showResults: true });
  }

  render() {
    const { message, loading, url } = this.props;
    if (loading) {
      return <Spinner message={t("spinner.loading")} />;
    }

    return (
      <React.Fragment>
        {message && url && (
          <React.Fragment>
            <div className={styles["plagiarism-report-message-container"]}>
              <h4 className={styles["plagiarism-report-message"]}>{message}</h4>
              <p className={styles["plagiarism-report-hint"]}>{t("story-editor.inspector.plagiarism-check")}</p>
            </div>
            <div className={styles["plagiarism-report-results-container"]}>
              {!this.state.showResults && <Spinner message={t("spinner.rendering")} />}
              <iframe
                title={t("story-editor.inspector.plagiarism-report")}
                onLoad={() => this.showResults()}
                className={styles["plagiarism-report-results"]}
                src={url}
              />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

interface StateProps {
  message: string | null;
  url: string | null;
  loading: boolean;
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    message: state.storyEditor.plagiarismCheck.message,
    url: state.storyEditor.plagiarismCheck.url,
    loading: state.storyEditor.plagiarismCheck.loading
  };
};

const mapDispatchToProps = () => {};

export default compose(connect(mapStateToProps, mapDispatchToProps))(PlagiarismReport);

export { PlagiarismReport };
