/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { get } from "lodash";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";

import BaseHeader from "components/header/header";
import Button from "components/button/button";
import { PartialAppState } from "./state";
import { saveWatermarkImage } from "store/watermark-images";

interface StateProps {
  isWatermarkImageModified: boolean;
  saving: boolean;
}

interface DispatchProps {
  saveWatermarkImage: () => void;
}

type HeaderProps = StateProps & DispatchProps;

const Header: React.FC<HeaderProps> = ({ isWatermarkImageModified, saveWatermarkImage, saving }) => {
  return (
    <BaseHeader>
      {isWatermarkImageModified && (
        <Button variant="success" type="primary" onClick={saveWatermarkImage} disabled={saving}>
          {t("settings.watermark_images.save-button-label")}
        </Button>
      )}
    </BaseHeader>
  );
};

const mapStateToProps = (state: PartialAppState) => {
  return {
    isWatermarkImageModified: get(state, ["watermarkImage", "ui", "isWatermarkImageModified"], false),
    saving: get(state, ["defaultWatermarkImagePage", "ui", "saving"], false)
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, any, any>) => ({
  // Not able to get rid of 'unknown' - sinu/akshay g.
  saveWatermarkImage: () => ((dispatch(saveWatermarkImage()) as unknown) as Promise<any>).catch(() => null)
});

export { Header };

export default connect(mapStateToProps, mapDispatchToProps)(Header);
