/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { t } from "i18n";
import { TASKS_INDEX_PATH } from "../tasks/routes";
import { USER_PROFILE_PATH } from "../routes";

const menuItems = (isTaskPlannerEnabled?: boolean) => {
  const items = [{ label: t("user-page.my_profile"), path: USER_PROFILE_PATH }];
  if (isTaskPlannerEnabled) {
    items.push({ label: t("tasks.my_tasks.title"), path: TASKS_INDEX_PATH });
  }
  return items;
};

export default menuItems;
