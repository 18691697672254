/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useState, useEffect, useCallback } from "react";
import { t } from "i18n";
import { get } from "lodash";
import TextField from "components/text-field/text-field";
import Button from "components/button/button";
import { connect } from "react-redux";
import { getTwoFactorAuthToken, validateOTPAndSaveSecret } from "./async-action-creators";
import { clearValidationErrorAction, onChangeOTP } from "./action-creators";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "./state";
import { QrData } from "api/tfa";
import Loader from "./loader";
import { isClientValidationError } from "utils/validation.utils";
import { LoaderState } from "behaviors/loader/state";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import styles from "./enable-two-factor-auth.module.css";
import Snackbar from "components/snackbar/snackbar";
import QRCode from "qrcode";

interface DispatchProps {
  getTwoFactorAuthToken: VoidFunction;
  onConfirm: (secretKey: string, otp: string, postEnableHandler: VoidFunction) => void;
  onChange: (otp: string) => void;
  clearValidationError: VoidFunction;
}

interface StateProps {
  token: QrData | null;
  otp: string;
  loader: LoaderState;
  error: Error | null;
}

interface ValidateOTP {
  otp: string;
}

interface OwnProps {
  postEnableHandler: VoidFunction;
}

type Props = DispatchProps & StateProps & OwnProps;

const EnableTwoFactorAuth: React.FC<Props> = ({
  token,
  otp,
  loader,
  error,
  postEnableHandler,
  getTwoFactorAuthToken,
  onConfirm,
  onChange,
  clearValidationError
}) => {
  const [qrCodeUrl, setQrCodeUrl] = useState<string | null>(null);

  const generateQR = useCallback(async (token) => {
    try {
      if (token) {
        const url = await QRCode.toDataURL(token["qr-code-text"]);
        setQrCodeUrl(url);
      }
    } catch (error) {
      console.error("Failed generating QR code:" + error);
    }
  }, []);

  useEffect(() => {
    getTwoFactorAuthToken();
    return () => {
      clearValidationError();
      onChange("");
    };
  }, [clearValidationError, getTwoFactorAuthToken, onChange]);

  useEffect(() => {
    generateQR(token);
  }, [token, generateQR]);

  return (
    <LoaderWrapper className="two-factor-auth-progress-main-area" component={Loader} loader={loader}>
      <div>
        <div className={styles["two-factor-auth-container"]} data-test-id="two-factor-auth-container">
          <p className={styles["two-factor-auth-container__info"]} data-test-id="two-factor-auth-container__info">
            <h4>{t("loginPage.two_factor_auth.scan_info")}</h4>
          </p>
          {qrCodeUrl && (
            <img className={styles["two-factor-auth-container__qr-code"]} src={qrCodeUrl} alt={"QR Code"} />
          )}
          <TextField
            label={t("loginPage.two_factor_auth.otp_label")}
            placeholder={t("loginPage.two_factor_auth.totp_placeholder")}
            value={otp}
            onChange={(value) => onChange(value)}
            errorMessage={isClientValidationError<ValidateOTP>(error) ? get(error, ["errors", "otp", "0"]) : undefined}
          />
          <Button type="primary" onClick={() => token && onConfirm(token["secret-key"], otp, postEnableHandler)}>
            {t("loginPage.two_factor_auth.confirm")}
          </Button>
          <Snackbar />
        </div>
      </div>
    </LoaderWrapper>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    token: state.login.twoFactorAuth.token,
    otp: state.login.twoFactorAuth.otp,
    loader: state.login.ui.loader,
    error: state.login.ui.error
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchProps => {
  return {
    getTwoFactorAuthToken: () => dispatch(getTwoFactorAuthToken()),
    onConfirm: (secretKey, otp, postEnableHandler) =>
      dispatch(validateOTPAndSaveSecret(secretKey, otp, postEnableHandler)),
    onChange: (otp) => dispatch(onChangeOTP(otp)),
    clearValidationError: () => dispatch(clearValidationErrorAction())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EnableTwoFactorAuth);

export { EnableTwoFactorAuth };
