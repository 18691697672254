/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export interface Settings {
  settings: AllSettings;
}

interface AllSettings {
  // features: Object;
  "social-links": SocialLinks;
  // "amp-config": AmpConfig;
  // "analytics-newsletters": Object;
  "general-settings": GeneralSettings;
  "enabled-story-elements": string[];
  // "breaking-news-sorters": Array<Object>;
  // "collection-metadata": Object;
  // "static-pages": Array<Object>;
}

export interface PartialSettings {
  "general-settings": GeneralSettings | null;
  "social-links": SocialLinks | null;
}

// interface SettingsRequest {
//   "general-settings"?: GeneralSettings | null;
//   "social-links"?: SocialLinks | null;
//   "amp-config"?: AmpConfig;
// }

export interface GeneralSettings {
  "publisher-logo": Image | null;
  title: string;
  description: string;
  copyright: string;
  "android-notification-style": string;
  "instant-articles-first-ad": string;
  "instant-articles-autoplaced-ad": string;
  "additional-urls-for-sitemap": string;
  favicon: Favicon | null;
}

export interface Favicon {
  filename: string;
  "content-type": string;
  size: number;
  key: string;
}

export interface SocialLinks {
  "facebook-url"?: string;
  "google-plus-url"?: string;
  "instagram-url"?: string;
  "twitter-url"?: string;
}

export interface Image {
  metadata: {
    "file-name"?: string;
    "file-size"?: number;
    height: number;
    "mime-type"?: string;
    width: number;
  };
  success?: boolean;
  "temp-image-key": string;
  url: string;
}

export const getSettingsData = (): Promise<Settings> => {
  return client
    .url("/api/settings?format=itsman-x")
    .get()
    .json();
};

export const saveSettingsData = (params: PartialSettings): Promise<Settings> => {
  return client
    .url("/api/settings?format=itsman-x")
    .json({ settings: params })
    .post()
    .json();
};
