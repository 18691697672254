/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import Menus from "./menu";
import {
  MENU_INDEX_PATH,
  MENU_NEW_PATH,
  MENU_ITEM_EDIT_PATH,
  MENU_ITEMS_INDEX_PATH,
  MENU_ITEM_NEW_PATH
} from "./routes";

export const Router = () => (
  <React.Fragment>
    <Switch>
      <Route exact path={MENU_INDEX_PATH} component={Menus} />
      <Route exact path={MENU_NEW_PATH} component={Menus} />
      <Route exact path={MENU_ITEMS_INDEX_PATH} component={Menus} />
      <Route exact path={MENU_ITEM_NEW_PATH} component={Menus} />
      <Route exact path={MENU_ITEM_EDIT_PATH} component={Menus} />
    </Switch>
  </React.Fragment>
);
