/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { UITag } from "../../state";
import AnimatedLoader from "components/icons/animated-loader";

interface StoryCountProps {
  uiTag: UITag;
}

const StoryCount: React.SFC<StoryCountProps> = ({ uiTag }) => {
  if (uiTag.loading) {
    return <AnimatedLoader />;
  }

  if (uiTag.error) {
    return null;
  }

  return <span>{uiTag.storyCount}</span>;
};

export default StoryCount;
