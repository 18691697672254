/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const MEDIA_LIBRARY_PATH = "/media-library";
export const MEDIA_LIBRARY_UPLOAD = `${MEDIA_LIBRARY_PATH}/upload/:mediaKey`;
export const MEDIA_LIBRARY_STORIES = `${MEDIA_LIBRARY_PATH}/stories/:mediaKey`;
