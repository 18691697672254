/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ChildStoryElement, StoryElementType, CompositeStoryElement } from "api/story";
import { Image } from "api/search-media-image";

const newTextStoryElement = (referenceElement: CompositeStoryElement): ChildStoryElement => {
  const id = "new-story-element-" + Math.random(),
    clientId = "client-story-element-" + Math.random(),
    referenceElementDetails = { "composite-element-id": referenceElement.id };

  return {
    id,
    "client-id": clientId,
    text: "",
    type: StoryElementType.Text,
    subtype: null,
    metadata: {},
    ...referenceElementDetails
  };
};

const newImageStoryElement = (imageGalleryElement: CompositeStoryElement, image: Image): ChildStoryElement => {
  const id = "new-story-element-" + Math.random(),
    clientId = "client-story-element-" + Math.random(),
    imageGalleryElementDetails = {
      "composite-element-id": imageGalleryElement.id,
      "card-id": imageGalleryElement["card-id"]
    };

  return {
    id,
    "client-id": clientId,
    type: StoryElementType.Image,
    metadata: {},
    subtype: null,
    image,
    ...imageGalleryElementDetails
  };
};

export const addImagesToImageGalleryElement = (imageGalleryElement: CompositeStoryElement, images: Image[]) => {
  return images.reduce(
    (acc, image) => {
      const newImageElement = newImageStoryElement(imageGalleryElement, image);
      return {
        imageStoryElements: { ...acc.imageStoryElements, [newImageElement.id]: newImageElement },
        imageGalleryTree: [...acc.imageGalleryTree, newImageElement.id]
      };
    },
    { imageStoryElements: {}, imageGalleryTree: [] }
  );
};
export const appendTextStoryElementToComposite = (
  compositeElement: CompositeStoryElement
): {
  textStoryElement: ChildStoryElement;
  compositeElementTree: {
    tree: Array<String>;
  };
} => {
  const textStoryElement = newTextStoryElement(compositeElement);
  return {
    textStoryElement,
    compositeElementTree: {
      tree: [...compositeElement.tree, textStoryElement.id]
    }
  };
};
