/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Inspector from "components/inspector/inspector";
import { t } from "i18n";
import { connect } from "react-redux";
import { AnyAction, compose } from "redux";
import { PartialAppState, ImageError } from "../state";

import { Image } from "api/tasks";
import { saveImageValidationFailureAction } from "../action-creators";
import {
  saveTaskImage,
  deleteTaskImage,
  updateImageData,
  setTaskImage,
  closeImageInspector
} from "../async-action-creators";

import { ThunkDispatch } from "redux-thunk";
import ImageUpload from "pages/media-library/components/image-upload/image-upload";
import { TaskId } from "api/primitive-types";

interface StateProps {
  isActive: boolean;
  imageUploading: boolean;
  selectedImages: Image[] | [];
  taskId: string | null;
  error: ImageError[];
}

interface DispatchProps {
  closeImageInspector: (id: TaskId) => void;
  saveTaskImage: (image: Image[], id: String | null) => void;
  deleteTaskImage: () => void;
  updateImageData: (key: String, value: string | number | null, imageIndex: number) => void;
  setSelectedImages: (images: Image[]) => void;
  updateError: (errors: ImageError[]) => void;
}

type Props = StateProps & DispatchProps;

export const TaskInspector: React.SFC<Props> = ({
  saveTaskImage,
  closeImageInspector,
  isActive,
  selectedImages,
  imageUploading,
  deleteTaskImage,
  setSelectedImages,
  updateError,
  updateImageData,
  taskId,
  error
}) => {
  return (
    <Inspector
      title={t("tasks.media.title")}
      actionButtonLabel={t("tasks.media.label")}
      onActionButtonClick={() => saveTaskImage(selectedImages, taskId)}
      isActive={isActive}
      onClose={() => taskId && closeImageInspector(taskId)}
      variant="wide"
      isActionButtonDisabled={imageUploading}
      isBackgroundActionDisabled={true}
      level={0}>
      <ImageUpload
        selectedImages={selectedImages}
        updateImageData={(key: string, value: string | number | null, imageIndex: number) =>
          updateImageData(key, value, imageIndex)
        }
        setSelectedMedia={(images: Image[]) => setSelectedImages(images)}
        isUploading={imageUploading}
        onDeleteMedia={closeImageInspector.bind(null, taskId)}
        errors={error}
        isLinkAuthorEnabled={false}
        updateError={updateError}
        isStoryImageHyperlinkEnabled={false}
      />
    </Inspector>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    isActive: state.tasks.ui.showInspector,
    imageUploading: state.tasks.app.media.isUploading,
    selectedImages: state.tasks.app.media.selectedMedia,
    error: state.tasks.app.media.error,
    taskId: state.tasks.app.currentTask && state.tasks.app.currentTask.id
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    closeImageInspector: (id: TaskId | null) => dispatch(closeImageInspector(id)),
    saveTaskImage: (images: Image[], id: String | null) => dispatch(saveTaskImage(images, id)),
    deleteTaskImage: () => dispatch(deleteTaskImage()),
    updateImageData: (key: string, value: string | number | null, imageIndex: number) =>
      dispatch(updateImageData(key, value, imageIndex)),
    setSelectedImages: (images: Image[]) => dispatch(setTaskImage(images)),
    updateError: (errors: ImageError[]) => dispatch(saveImageValidationFailureAction(errors))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(TaskInspector);
