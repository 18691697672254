/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { RouteComponentProps } from "react-router";
import { t } from "i18n";
import wrapPage from "containers/page/page";
import NumberField from "components/number-field/number-field";
import RadioButton from "components/radio-button/radio-button";
import Spinner from "components/spinner/spinner";
import ContentHeader from "pages/settings/components/content-header/content-header";
import SitemapHeader from "./components/sitemap-header";
import { SettingsCard } from "components/settings-card";
import { SitemapState, SitemapConfig, fetchSitemapConfig, setSitemapConfig } from "store/sitemap";
import styles from "./sitemap.module.css";

interface StateProps {
  isLoading: boolean;
  sitemap: SitemapConfig;
  isSitemapConfigModified: boolean;
  title: string;
}

interface DispatchProps {
  loadSitemapData: Function;
  setSitemapData(sitemapConfig: SitemapConfig): void;
}

type Props = RouteComponentProps<any> & StateProps & DispatchProps;

export class Sitemap extends React.Component<Props, { showSitemapFilters: boolean }> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showSitemapFilters: false
    };
    this.setDateFilter = this.setDateFilter.bind(this);
    this.clearAllFilters = this.clearAllFilters.bind(this);
  }

  componentDidMount() {
    this.props.loadSitemapData();
  }

  setDateFilter(days?: number) {
    this.props.setSitemapData({
      ...this.props.sitemap,
      "filter-stories-by-days": days
    });
  }

  clearAllFilters() {
    this.setState({ showSitemapFilters: false });
    this.props.setSitemapData({});
  }

  render() {
    let { isLoading, sitemap } = this.props;
    if (isLoading) {
      return <Spinner message={t("sitemap.loading_message")} />;
    }

    const showSitemapFilters = this.state.showSitemapFilters || !!sitemap["filter-stories-by-days"];

    return (
      <React.Fragment>
        <ContentHeader title={t("sitemap.title")} />
        <SettingsCard title={t("sitemap.included_stories")}>
          <RadioButton
            id="sitemap-all-stories"
            label={t("sitemap.all_stories")}
            checked={!showSitemapFilters}
            name="stories_filter"
            onChange={() => this.clearAllFilters()}
            isVertical={true}
          />
          <RadioButton
            id="sitemap-filter-stories"
            label={t("sitemap.filter_stories")}
            checked={showSitemapFilters}
            name="stories_filter"
            onChange={() => this.setState({ showSitemapFilters: true })}
            isVertical={true}
          />
          {showSitemapFilters && (
            <div className={styles["number-field"]}>
              <NumberField
                value={sitemap["filter-stories-by-days"]}
                label={t("sitemap.published_recently")}
                onChange={this.setDateFilter}
                min={0}
              />
            </div>
          )}
        </SettingsCard>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: { sitemap: SitemapState }): StateProps => {
  return {
    isLoading: state.sitemap.loading,
    sitemap: state.sitemap.sitemap || {},
    isSitemapConfigModified: state.sitemap.isSitemapConfigModified,
    title: "sitemap"
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>): DispatchProps => {
  return {
    loadSitemapData: () => dispatch(fetchSitemapConfig()),
    setSitemapData: (sitemapConfig) => dispatch(setSitemapConfig(sitemapConfig))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  wrapPage({ HeaderComponent: SitemapHeader })
)(Sitemap);
