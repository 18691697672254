/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { SectionTree, Section } from "api/section";
import SectionRow from "./sections-row";
import { t } from "i18n";
import classnames from "classnames/bind";
import NoContentCard from "components/no-content-card/no-content-card";
import File from "components/icons/file";
import styles from "./sections-list.module.css";

interface SectionsListProps {
  sections: SectionTree[];
  undeleteSection?: (section: Section) => void;
  deleteSectionWithConfirmation: (section: Section) => void;
  classname?: string;
}

const cx = classnames.bind(styles);

const SectionsList: React.SFC<SectionsListProps> = ({
  sections,
  undeleteSection,
  deleteSectionWithConfirmation,
  classname
}) => (
  <React.Fragment>
    {sections.length === 0 && (
      <div className={styles["sections-no-content"]}>
        <NoContentCard>
          <File />
          <div className={styles["section-list-no-content-message"]}>{t("sections.messages.no-sections")}</div>
        </NoContentCard>
      </div>
    )}
    {sections.length > 0 && (
      <ul className={cx("sections-list", classname)}>
        {sections.map((section) => (
          <SectionRow
            key={`section-list-section-${section.id}`}
            section={section}
            undeleteSection={undeleteSection}
            deleteSectionWithConfirmation={deleteSectionWithConfirmation}
          />
        ))}
      </ul>
    )}
  </React.Fragment>
);

export { SectionRow, SectionsList };
