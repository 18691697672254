/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { PartialAppState, Section, MenuGroup } from "store/domain/domain";
import { createSelector } from "reselect";
import { normalize } from "utils";
import { flatten, uniq, difference } from "lodash";

const selectDomains = (state: PartialAppState) => state.domain.app.domains;
const selectCurrentDomain = (state: PartialAppState) => state.domain.app.currentDomain;
const selectRawSections = (state: PartialAppState): Array<Section> => state.config.sections;
const selectRawMenuGroups = (state: PartialAppState): Array<MenuGroup> => state.menu.menuGroups;

const selectInitialSectionIds = createSelector([selectDomains, selectCurrentDomain], (domains, currentDomain) =>
  currentDomain.id ? domains[currentDomain.id]["section-ids"] : []
);

export const selectSections = createSelector(selectRawSections, (sections: Array<Section>) =>
  normalize(sections, ["name", "id"])
);

export const selectMenuGroups = createSelector(selectRawMenuGroups, (menuGroups: Array<MenuGroup>) =>
  normalize(menuGroups, ["name", "id"])
);

const selectSectionsInDomains = createSelector(selectDomains, (domains) => {
  const sectionIds = Object.values(domains).map((domain) => domain["section-ids"]);
  const flattened = flatten(sectionIds);
  return uniq(flattened);
});

export const selectSectionOpts = createSelector(
  [selectRawSections, selectSectionsInDomains, selectInitialSectionIds],
  (sections: Array<Section>, allDomainsSectionIds: Array<number>, currentSectionIds: Array<number>) => {
    return sections.map((section) => ({
      ...section,
      isDisabled: difference(allDomainsSectionIds, currentSectionIds).includes(section.id)
    }));
  }
);
