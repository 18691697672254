/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { SitemapConfig } from "store/sitemap";

export const getSitemapConfigData = (): Promise<SitemapConfig> => {
  return client
    .url(`/api/v1/internal-config`)
    .query({ fields: "sitemap" })
    .get()
    .json()
    .then((res) => res.sitemap);
};

export const saveSitemapConfigData = (sitemapConfig: SitemapConfig): Promise<void> => {
  return client
    .url(`/api/v1/internal-config`)
    .patch({
      sitemap: sitemapConfig
    })
    .res();
};
