/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, {
  ListTagsAction,
  ListTagsSuccessAction,
  ListTagsFailureAction,
  ReplaceCurrentTagAction,
  InitNewTagAction,
  SaveTagAction,
  SaveTagSuccessAction,
  SaveTagFailureAction,
  CancelCreateOrEditTagAction,
  EditTagAction,
  EditTagFailureAction,
  FetchStoryCountsAction,
  FetchStoryCountsSuccessAction,
  FetchStoryCountsFailureAction,
  ChangeSpecifiedPageAction,
  SetGeneratedSlugAction
} from "./actions";
import { AnyTag, Tag, TagListResponse } from "api/tag";
import { TagIdStoryCountMap } from "api/search";

export function listTagsAction(searchTerm?: string): ListTagsAction {
  return { type: actions.LIST_TAGS, payload: { searchTerm } };
}

export function listTagsSuccessAction(response: TagListResponse): ListTagsSuccessAction {
  return { type: actions.LIST_TAGS_SUCCESS, payload: { tags: response.tags, pagination: response.pagination } };
}

export function listTagsFailureAction(error: Error): ListTagsFailureAction {
  return { type: actions.LIST_TAGS_FAILURE, payload: { error } };
}

export function replaceCurrentTagAction(tag: AnyTag | null): ReplaceCurrentTagAction {
  return { type: actions.REPLACE_CURRENT_TAG, payload: { tag } };
}

export function initNewTagAction(): InitNewTagAction {
  return { type: actions.INIT_NEW_TAG };
}

export function editTagAction(tag: Tag): EditTagAction {
  return { type: actions.EDIT_TAG, payload: { tag } };
}

export function editTagFailureAction(error: Error): EditTagFailureAction {
  return { type: actions.EDIT_TAG_FAILURE, payload: { error } };
}

export function cancelCreateOrEditTagAction(tag: AnyTag): CancelCreateOrEditTagAction {
  return { type: actions.CANCEL_CREATE_OR_EDIT_TAG, payload: { tag } };
}

export function saveTagAction(tag: AnyTag): SaveTagAction {
  return { type: actions.SAVE_TAG, payload: { tag } };
}

export function saveTagSuccessAction(tag: Tag): SaveTagSuccessAction {
  return { type: actions.SAVE_TAG_SUCCESS, payload: { tag } };
}

export function saveTagFailureAction(tag: AnyTag, error: Error): SaveTagFailureAction {
  return { type: actions.SAVE_TAG_FAILURE, payload: { tag, error } };
}

export function fetchStoryCountsAction(tags: Tag[]): FetchStoryCountsAction {
  return { type: actions.FETCH_STORY_COUNTS, payload: { tags } };
}

export function fetchStoryCountsSuccessAction(
  tags: Tag[],
  storyCounts: TagIdStoryCountMap
): FetchStoryCountsSuccessAction {
  return { type: actions.FETCH_STORY_COUNTS_SUCCESS, payload: { tags, storyCounts } };
}

export function fetchStoryCountsFailureAction(tags: Tag[], error: Error): FetchStoryCountsFailureAction {
  return { type: actions.FETCH_STORY_COUNTS_FAILURE, payload: { tags, error } };
}

export function changeSpecifiedPage(pageNumber: number): ChangeSpecifiedPageAction {
  return {
    type: actions.CHANGE_SPECIFIED_PAGE,
    payload: { pageNumber }
  };
}

export function setGeneratedSlugAction(tag: AnyTag): SetGeneratedSlugAction {
  return { type: actions.SET_GENERATED_SLUG, payload: { tag } };
}
