/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export type Dimension = {
  width: number;
  height: number;
};

export type Coordinate = {
  x: number;
  y: number;
};

const getStylesForOverlay = (imageDimensions: Dimension, containerWidth: number, containerHeight: number) => {
  let width = containerWidth;
  let height = containerHeight;

  const fittedHeight = Math.round((imageDimensions.height * containerWidth) / imageDimensions.width);
  const fittedWidth = Math.round((imageDimensions.width * containerHeight) / imageDimensions.height);

  if (fittedHeight <= containerHeight) {
    height = fittedHeight;
  }

  if (fittedWidth <= containerWidth) {
    width = fittedWidth;
  }

  const positionFromTop = (containerHeight - height) / 2;
  const positionFromLeft = (containerWidth - width) / 2;

  const style = {
    width: `${width}px`,
    height: `${height}px`,
    position: "absolute",
    top: `${positionFromTop}px`,
    left: `${positionFromLeft}px`
  } as React.CSSProperties;

  const dimensions = { width, height };
  return { style, dimensions };
};

const translateOverlayPointToFocusPoint = (
  overlayDimensions: Dimension,
  imageDimensions: Dimension,
  point: Coordinate
) => {
  const x = Math.round((point.x * imageDimensions.width) / overlayDimensions.width);
  const y = Math.round((point.y * imageDimensions.height) / overlayDimensions.height);

  return { x, y };
};

const translateFocusPointToOverlayPoint = (
  overlayDimensions: Dimension,
  imageDimensions: Dimension,
  point: Coordinate
) => {
  const x = Math.round((point.x * overlayDimensions.width) / imageDimensions.width);
  const y = Math.round((point.y * overlayDimensions.height) / imageDimensions.height);

  return { x, y };
};

export { getStylesForOverlay, translateOverlayPointToFocusPoint, translateFocusPointToOverlayPoint };
