/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { useState, useEffect, RefObject } from "react";
import { getImageData, getMetadataWithDimensions } from "utils/image-metadata.utils";
import { AnyImage, Image as ImageType } from "api/search-media-image";
import {
  getStylesForOverlay,
  translateFocusPointToOverlayPoint,
  Dimension,
  Coordinate
} from "pages/media-library/components/image-preview-with-actions/utils";
import { useRefDimensionsOnResize } from "./resize";
import { CoverImage } from "api/story-collection";
import { SavedImage } from "components/image-field/types";

export function useImageMetadata(image: ImageType | undefined) {
  const [imageHeight, setImageHeight] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);

  async function setMetadata(image: ImageType) {
    const metadata = await getMetadataWithDimensions(image);
    setImageHeight(metadata.height);
    setImageWidth(metadata.width);
  }

  useEffect(() => {
    image && setMetadata(image);
  }, [image]);

  return { width: imageWidth, height: imageHeight };
}

export function useFocusPointMetadata(image: ImageType | undefined) {
  const [focusPoint, setFocusPoint] = useState<Coordinate | null>(null);

  useEffect(() => {
    if (image && image.metadata && image.metadata["focus-point"]) {
      const focusPoint = image.metadata["focus-point"];
      setFocusPoint({
        x: focusPoint[0],
        y: focusPoint[1]
      });
    } else {
      setFocusPoint(null);
    }
  }, [image]);
  return { focusPoint };
}

export function useOverlay(
  domReference: RefObject<HTMLDivElement>,
  imageDimensions: Dimension | null | undefined,
  focusPoint: Coordinate | null
) {
  const { width: containerWidth, height: containerHeight } = useRefDimensionsOnResize(domReference);
  const overlayProperties = imageDimensions && getStylesForOverlay(imageDimensions, containerWidth, containerHeight);
  const overlayPoint =
    focusPoint &&
    overlayProperties &&
    translateFocusPointToOverlayPoint(overlayProperties.dimensions, imageDimensions!, focusPoint);
  return { containerDimensions: { containerWidth, containerHeight }, overlayProperties, overlayPoint };
}

export function useImageData(image: ImageType | AnyImage | SavedImage | CoverImage | null) {
  const [focusPoint, setFocusPoint] = useState<Coordinate | null>(null);
  const [imageDimensions, setImageDimensions] = useState<{ width: number; height: number } | null>(null);

  useEffect(() => {
    const setImageData = async () => {
      const imageData = await getImageData(image);
      setFocusPoint(imageData.focusPoint);
      setImageDimensions(imageData.imageDimensions);
    };
    setImageData();
  }, [image]);

  return { focusPoint, imageDimensions };
}
