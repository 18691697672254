/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import styles from "./info-bar.module.css";

interface Props {
  content: string;
}

const InfoBar: React.SFC<Props> = ({ content }) => {
  return (
    <div className={styles["story-editor-info-bar"]} data-test-id="story-editor-info-bar">
      {content}
    </div>
  );
};

export default InfoBar;
