/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import produce from "immer";
import { State, INITIAL_ATTRIBUTES_STATE, NEW_ATTRIBUTE, AttributePageInspectorType } from "./state";
import actions, { ActionTypes } from "./actions";

function reducer(state: State = INITIAL_ATTRIBUTES_STATE, action: ActionTypes) {
  return produce(state, (draft) => {
    switch (action.type) {
      case actions.LIST_ATTRIBUTES:
        draft.ui.main = { loading: true, error: null };
        break;

      case actions.LIST_ATTRIBUTES_SUCCESS:
        draft.ui.main = { loading: false, error: null };
        draft.attributes = action.payload.attributes;
        break;

      case actions.LIST_ATTRIBUTES_FAILURE:
        draft.ui.main = { loading: false, error: action.payload.error };
        break;

      case actions.REPLACE_CURRENT_ATTRIBUTE:
        draft.app.currentAttribute = action.payload.attribute;
        break;

      case actions.INIT_NEW_ATTRIBUTE:
        draft.app.currentAttribute = NEW_ATTRIBUTE;
        draft.ui.inspector = AttributePageInspectorType.Create;
        break;

      case actions.EDIT_ATTRIBUTE:
        draft.app.currentAttribute = action.payload.attribute;
        draft.ui.inspector = AttributePageInspectorType.Edit;
        break;

      case actions.EDIT_ATTRIBUTE_FAILURE:
        draft.ui.main = { loading: false, error: action.payload.error };
        break;

      case actions.CANCEL_CREATE_OR_EDIT_ATTRIBUTE:
        draft.app.currentAttribute = null;
        draft.ui.inspector = null;
        draft.ui.save.error = null;
        break;

      case actions.SAVE_ATTRIBUTE:
        draft.ui.save = { loading: true, error: null };
        break;

      case actions.SAVE_ATTRIBUTE_SUCCESS:
        draft.ui.save = { loading: false, error: null };
        draft.app.currentAttribute = null;
        draft.ui.inspector = null;
        break;

      case actions.SAVE_ATTRIBUTE_FAILURE:
        draft.ui.save = { loading: false, error: action.payload.error };
        break;

      case actions.DELETE_ATTRIBUTE:
        draft.ui.attributes[action.payload.attribute.name] = { loading: true, error: null };
        break;

      case actions.DELETE_ATTRIBUTE_SUCCESS:
        draft.ui.attributes[action.payload.attribute.name] = { loading: false, error: null };
        break;

      case actions.DELETE_ATTRIBUTE_FAILURE:
        draft.ui.attributes[action.payload.attribute.name] = { loading: false, error: action.payload.error };
        break;

      case actions.LIST_ENTITY_TYPE_SUCCESS:
        draft.ui.entityTypes = action.payload.entityTypes;
        break;

      case actions.LIST_ENTITY_TYPE_FAILURE:
        draft.ui.entityTypesError = action.payload.error;
        break;

      default:
        break;
    }
  });
}

export default reducer;
