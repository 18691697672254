/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import appendCard from "./append";
import getTemplateCardAndElements from "../../data/template-card-and-elements";
import { EditorState } from "prosemirror-state";
import { AnyStory, EditorConfig, Card } from "api/story";

export default function appendTemplateCards(story: AnyStory, editorState: EditorState, editorConfig: EditorConfig) {
  const tree = (editorConfig && editorConfig.tree) || [];
  const cards = editorConfig && editorConfig.cards;
  const newCards: Card[] = [];

  return tree.reduce(
    (accumulator, element) => {
      const cardId = element["content-id"];
      const { card, storyElements } = getTemplateCardAndElements(
        element as Card,
        (cards && cards[cardId]["story-elements"]) || []
      );
      newCards.push(card);
      return { ...appendCard(accumulator.story, accumulator.editorState, card, storyElements), newCards };
    },
    { story, editorState, newCards }
  );
}
