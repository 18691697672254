/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";

import wrapPage from "containers/page/page";
import Trash from "components/icons/trash";
import ContentHeader from "../../../components/content-header/content-header";
import { navigateFn } from "utils/routes.utils";

import { fetchConfigureData, saveUniqueAccessLevel, deleteSelectedAccessLevel } from "../action-creator";

import { CONFIGURE_ADD_ACCESS_LEVEL_PATH, CONFIGURE_ACCESS_LEVELS_PATH } from "../../../routes";
import {
  UPDATE_ACCESS_LEVEL_VALUE,
  RESET_ACCESS_LEVEL_VALUE,
  SET_ACCESS_LEVEL_ERRORS,
  OPEN_CONFIRMATION_MODEL,
  CLOSE_CONFIRMATION_MODEL,
  UPDATE_CARD_VISIBILITY,
  TOGGLE_SAVE_BUTTON
} from "../../../actions";
import AddAccessLevelInspector from "./add-access-level-inspector";
import { PartialAppState } from "../../../initial-state";
import { Configuration } from "api/select-features";
import { AccessLevelErrors } from "./shared-types";

import styles from "./access-level.module.css";
import classnames from "classnames/bind";
import NoContentCard from "components/no-content-card/no-content-card";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import { LoaderState } from "behaviors/loader/state";
import Loader from "./loader";
import ConfirmationModal from "components/confirmation-modal/confirmation-modal";
import NumberField from "components/number-field/number-field";
import AccessLevelHeader from "./components/access-levels-header";

const cx = classnames.bind(styles);
interface StateProps {
  accessLevel: Configuration;
  accessLevelValue: string;
  noOfVisibleCards: number;
  errors: AccessLevelErrors;
  accessLevelValues: Array<string>;
  loader: LoaderState;
  isConfirmationModelVisible: boolean;
}

interface DispatchProps {
  loadConfigureData: () => void;
  openInspector: () => void;
  closeInspector: () => void;
  deleteAccessLevel: () => void;
  updateAccessLevelValue: (value: string) => void;
  addUniqAccessLevelValue: (
    accessLevelValue: string,
    accessLevelValues: Array<string>,
    accessLevel: Configuration
  ) => void;
  updateCardVisibility: (value: number) => void;
  toggleSaveButton: (value: boolean) => void;
  setErrors: (value: AccessLevelErrors) => void;
  openConfirmationModel: (index: number) => void;
  closeConfirmationModel: () => void;
}

type Props = StateProps &
  DispatchProps & {
    showSubmenu: string;
    activeMenu: string;
    showInspector: boolean;
    isBannerPresent: boolean;
  };

class AccessLevels extends React.Component<Props> {
  componentDidMount() {
    this.props.loadConfigureData();
  }

  render() {
    const {
      accessLevel,
      noOfVisibleCards,
      openInspector,
      closeInspector,
      showInspector,
      updateAccessLevelValue,
      accessLevelValue,
      addUniqAccessLevelValue,
      deleteAccessLevel,
      setErrors,
      errors,
      accessLevelValues,
      loader,
      openConfirmationModel,
      isConfirmationModelVisible,
      closeConfirmationModel,
      updateCardVisibility,
      toggleSaveButton
    } = this.props;

    return (
      <React.Fragment>
        <main
          className={cx("access-level-page-container", "configure-page")}
          data-test-id="access-level-page-container">
          <LoaderWrapper className="accesslevel-progress-main-area" loader={loader} component={Loader}>
            <ContentHeader
              headerTitle={
                <React.Fragment>
                  <h3 className={styles["access-levels-sub-title"]}>{t("settings.access_levels.title")}</h3>
                  <p className={styles["access-levels-description"]}>{t("settings.access_levels.description")}</p>
                </React.Fragment>
              }
              title={""}
              actionButtonLabel={t("settings.access_levels.add_access_level_button")}
              handleActionButtonClick={() => openInspector()}
            />
            <div className={styles["access-levels-container"]}>
              <div className={cx("access-level-row", "access-level-header")} data-test-id="access-level-row">
                <span>{t("settings.access_levels.access_level")}</span>
              </div>
              {accessLevelValues.length === 0 && (
                <NoContentCard>
                  {t("settings.access_levels.messages.no_access_levels")}
                  <button onClick={() => openInspector()} className={styles["create-new-link"]}>
                    {t("settings.access_levels.create_new")}
                  </button>
                </NoContentCard>
              )}

              {accessLevelValues.length > 0 &&
                accessLevelValues.map((value: string, index: number) => (
                  <div
                    className={styles["access-level-row"]}
                    key={`access-level-${index}`}
                    data-test-id="access-level-row">
                    <span>{value}</span>
                    <span
                      className={styles["access-level-action"]}
                      onClick={() => openConfirmationModel(index)}
                      data-test-id="access-level-action">
                      <Trash color="#5f6978" />
                    </span>
                  </div>
                ))}
            </div>
            <h3 className={styles["access-levels-sub-title"]}>
              {t("settings.access_levels.content_access_config.title")}
            </h3>
            <p className={styles["access-levels-description"]}>
              {t("settings.access_levels.content_access_config.description")}
            </p>
            <div className={styles["access-levels-container"]}>
              <div className={styles["access-levels-config"]}>
                <div className={styles["access-levels-category-details-container"]}>
                  <h3 className={styles["access-levels-sub-title"]}>
                    {t("settings.access_levels.content_access_config.cards_shown_title")}
                  </h3>
                  <p className={styles["access-levels-description"]}>
                    {t("settings.access_levels.content_access_config.cards_shown_description")}
                  </p>
                </div>
                <div className={styles["access-level-fields-container"]}>
                  <NumberField
                    label=""
                    onChange={(value: number) => {
                      if (value !== noOfVisibleCards && value >= 0) {
                        updateCardVisibility(value);
                        toggleSaveButton(true);
                      }
                    }}
                    value={noOfVisibleCards || 0}
                  />
                </div>
              </div>
            </div>
          </LoaderWrapper>
        </main>
        <AddAccessLevelInspector
          isActive={showInspector}
          onClose={closeInspector}
          onActionButtonClick={(accessLevelValue) =>
            addUniqAccessLevelValue(accessLevelValue, accessLevelValues, accessLevel)
          }
          updateAccessLevelValue={updateAccessLevelValue}
          accessLevelValue={accessLevelValue}
          setErrors={setErrors}
          errors={errors}
        />
        <ConfirmationModal
          headerText={t("settings.access_levels.messages.delete_confirmation_message")}
          confirmButtonLabel={t("settings.access_levels.delete")}
          cancelButtonLabel={t("settings.access_levels.cancel")}
          onConfirm={() => deleteAccessLevel()}
          onCancel={() => closeConfirmationModel()}
          onClose={() => closeConfirmationModel()}
          showConfirmationModal={isConfirmationModelVisible}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState) => {
  const accessLevel = state.settingsPage.accessLevel;
  const accessLevelValues =
    accessLevel && accessLevel.config && accessLevel.config.values ? accessLevel.config.values : [];
  return {
    accessLevel: accessLevel,
    accessLevelValue: state.settingsPage.accessLevelValue,
    noOfVisibleCards: state.settingsPage.noOfVisibleCards || 0,
    errors: state.settingsPage.ui.errors,
    accessLevelValues: accessLevelValues,
    loader: state.settingsPage.ui.main,
    isConfirmationModelVisible: state.settingsPage.ui.isConfirmationModelVisible
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  const navigate = navigateFn(dispatch);
  return {
    loadConfigureData: () => dispatch(fetchConfigureData()),
    openInspector: () => navigate(CONFIGURE_ADD_ACCESS_LEVEL_PATH),
    closeInspector: () => {
      dispatch({ type: RESET_ACCESS_LEVEL_VALUE });
      navigate(CONFIGURE_ACCESS_LEVELS_PATH);
    },
    deleteAccessLevel: () => dispatch(deleteSelectedAccessLevel()),
    updateAccessLevelValue: (value) => dispatch({ type: UPDATE_ACCESS_LEVEL_VALUE, payload: value }),
    addUniqAccessLevelValue: (accessLevelValue, accessLevelValues, accessLevel) =>
      dispatch(saveUniqueAccessLevel(accessLevelValue, accessLevelValues, accessLevel)),
    updateCardVisibility: (value) => dispatch({ type: UPDATE_CARD_VISIBILITY, payload: value || 0 }),
    toggleSaveButton: (value) => dispatch({ type: TOGGLE_SAVE_BUTTON, payload: value }),
    setErrors: (errors) => dispatch({ type: SET_ACCESS_LEVEL_ERRORS, payload: { errors } }),
    openConfirmationModel: (index) =>
      dispatch({ type: OPEN_CONFIRMATION_MODEL, payload: { selectedAccessLevelValueIndex: index } }),
    closeConfirmationModel: () => dispatch({ type: CLOSE_CONFIRMATION_MODEL })
  };
};

export default compose<any, any, any>(
  connect(mapStateToProps, mapDispatchToProps),
  wrapPage({ HeaderComponent: AccessLevelHeader })
)(AccessLevels);
export { AccessLevels };
