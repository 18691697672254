/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { MenuItem, MenuItemMoveDirection } from "api/menu-item";
import { t } from "i18n";
import classnames from "classnames/bind";
import NoContentCard from "components/no-content-card/no-content-card";
import File from "components/icons/file";
import { MenuGroup } from "api/menu-group";
import MenuItemRow from "./menu-items-row";
import styles from "./menu-items-list.module.css";

const cx = classnames.bind(styles);
interface MenuItemsListProps {
  selectedMenu: MenuGroup | null;
  menuItems: MenuItem[];
  allMenuItems: MenuItem[];
  moveMenuItem: (menuItem: MenuItem, direction: MenuItemMoveDirection) => void;
  deleteMenuItem?: (menuItem: MenuItem) => void;
  className?: string;
  addNewMenu?: () => void;
}

const MenuItemsList: React.SFC<MenuItemsListProps> = ({
  selectedMenu,
  menuItems,
  allMenuItems,
  moveMenuItem,
  deleteMenuItem,
  className,
  addNewMenu
}) => {
  const itemCount = menuItems.length;
  return (
    <React.Fragment>
      {menuItems.length === 0 && (
        <React.Fragment>
          {selectedMenu ? (
            <div className={styles["menu-items-no-content"]}>
              <NoContentCard>
                <File />
                <div className={styles["no-content-message"]}>{t("menu.messages.no-menu-items")}</div>
              </NoContentCard>
            </div>
          ) : (
            <div className={styles["no-menu-select"]} data-test-id="no-menu-select">
              <File />
              <div className={styles["menu-select-message"]} data-test-id="menu-select-message">
                <p className={styles["menu-select-message-text-body"]}>{t("menu.messages.no-menu-selected")}</p>
                <span>{t("menu.messages.or")}</span>
                <button onClick={addNewMenu} className={styles["text-link"]}>
                  {t("menu.messages.create-menu")}
                </button>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
      {menuItems.length > 0 && (
        <ul className={cx("menu-items-list", className)}>
          {menuItems.map((menuItem, index) => (
            <MenuItemRow
              key={`menu-items-list-menu-item-${menuItem.id}`}
              itemIndex={index}
              itemCount={itemCount}
              selectedMenu={selectedMenu}
              menuItem={menuItem}
              allMenuItems={allMenuItems}
              moveMenuItem={moveMenuItem}
              deleteMenuItem={deleteMenuItem}
            />
          ))}
        </ul>
      )}
    </React.Fragment>
  );
};

interface RootMenuItemsListProps {
  selectedMenu: MenuGroup | null;
  allMenuItems: MenuItem[];
  moveMenuItem: (menuItem: MenuItem, direction: MenuItemMoveDirection) => void;
  deleteMenuItem: (menuItem: MenuItem) => void;
  addNewMenu: () => void;
}

const RootMenuItemsList: React.SFC<RootMenuItemsListProps> = ({
  addNewMenu,
  allMenuItems,
  moveMenuItem,
  deleteMenuItem,
  selectedMenu
}) => {
  const rootMenuItems = allMenuItems.filter((menuItem) => menuItem["parent-id"] === null);
  return (
    <MenuItemsList
      className={styles["menu-items-list"]}
      selectedMenu={selectedMenu}
      menuItems={rootMenuItems}
      allMenuItems={allMenuItems}
      moveMenuItem={moveMenuItem}
      deleteMenuItem={deleteMenuItem}
      addNewMenu={addNewMenu}
    />
  );
};

export default RootMenuItemsList;

export { MenuItemsList, MenuItemRow };
