/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";

import Chevron from "components/icons/chevron";
import { DropdownIndicator, IndicatorProps } from "react-select/lib/components/indicators";
import styles from "./select.module.css";

const cx = classnames.bind(styles);

export class CustomDropdownIndicator<OptionType> extends React.Component<IndicatorProps<OptionType>> {
  render() {
    return (
      <DropdownIndicator {...this.props}>
        <span className={cx({ "react-select-rotate-chevron-down": !this.props.selectProps.menuIsOpen })}>
          <Chevron fill={this.props.selectProps.isDisabled ? "#c6ccd5" : "#5f6978"} />
        </span>
      </DropdownIndicator>
    );
  }
}
