/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useRef, useState } from "react";
import Picker from "react-month-picker";
import ButtonWithIcon from "components/button/button-with-icon";
import Caret from "components/icons/caret";
import "./month-picker.css";

interface MonthBoxProps {
  value: string;
  onClick: () => void;
}

const CaretDown = () => <Caret variant={"down"} width={14} height={14} />;

export const MonthBox: React.FC<MonthBoxProps> = ({ value, onClick }) => {
  return (
    <ButtonWithIcon type="secondary" onClick={onClick} icon={CaretDown} dir="right">
      {value}
    </ButtonWithIcon>
  );
};

interface PickerValue {
  year: number;
  month: number;
}

interface Props {
  onMonthChange: (v: PickerValue) => void;
  value: PickerValue;
  years?: {
    min?: PickerValue;
    max?: PickerValue;
  };
}

const pickerLang = {
  months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
};

const makeText = (m: PickerValue) => {
  return pickerLang.months[m.month - 1] + " " + m.year;
};

const MonthPicker: React.FC<Props> = ({ onMonthChange, value, years }) => {
  let [pickerValue, setPickerValue] = useState<PickerValue>(value);
  const pickerElementRef = useRef<any>(null);

  const handleClickMonthBox = () => {
    pickerElementRef.current.show();
  };

  const handleMonthChange = (year: number, month: number) => {
    const pickerValue = { year: year, month: month };
    setPickerValue(pickerValue);
    onMonthChange(pickerValue);
    pickerElementRef.current.dismiss();
  };

  return (
    <Picker
      ref={pickerElementRef}
      years={years}
      value={pickerValue}
      lang={pickerLang.months}
      theme="light"
      onChange={handleMonthChange}>
      <MonthBox value={makeText(pickerValue)} onClick={handleClickMonthBox} />
    </Picker>
  );
};

export default MonthPicker;
