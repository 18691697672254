/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import {
  actions,
  AddItemAction,
  ChangePageViewsAction,
  ChangeCollectionLayoutAction,
  CancelCardExpandAction,
  LoadLhsCollectionItemsAction,
  RefreshContentAction,
  LoadMoreLhsCollectionItemsAction,
  LoadTemplateOptionsAction,
  PublishedAction,
  PublishingAction,
  ResetFilterAction,
  ResetAction,
  ResetErrorsAction,
  ResetStagingSelectedCollectionAction,
  RemoveItemAction,
  RemoveCoverImageAction,
  RemoveFieldErrorAction,
  SetSelectedCollectionAction,
  SetCoverImagesAction,
  SetFieldEmptyErrorAction,
  SetStagingAssociatedMetadataAction,
  SwitchLaneTabAction,
  ToggleCoverImageUploadingAction,
  ToggleAdvancedSearchAction,
  CloseAdvancedSearchAction,
  ToggleCardExpandAction,
  UpdateStagingSelectedCollectionAction,
  UpdateSelectedCollectionAction,
  UpdateSwappedItemsAction,
  UpdateSortedItemsAction,
  UpdateFilterAction,
  UpdatePageViewsAction,
  UpdateStagingAssociatedMetadataAction,
  UpdateCollectionCardAction,
  UpdateCoverImageAction,
  UpdateCoverImageDataAction,
  UpdateStagingSelectedCollectionMetadataAction,
  UpdateStagingSelectedCollectionRulesAction,
  PdfUploadAction,
  PdfUploadSuccess,
  PdfUploadFailure,
  LhsLoadStartAction,
  LhsLoadEndAction,
  LhsLoadSuccessAction,
  LhsLoadFailureAction,
  SingleCollectionPageLoadingAction,
  SingleCollectionPageLoadSuccessAction,
  SingleCollectionPageLoadFailureAction,
  SetStoryCardPageViewsSuccess,
  SetStoryCardPageViewsError,
  setScheduleCollectionDateError,
  TogglePolygonSelectionAction,
  UpdateGeoPolygon,
  SetIsCollectionModifiedStateAction
} from "./actions";
import {
  ContentType,
  CollectionItemWrapper,
  Collection,
  AssociatedMetadata,
  AnyCollection,
  CoverImage,
  UnsavedAutomatedCollectionWithRules
} from "api/story-collection";
import { ListCollectionItemsResponse, Story } from "api/collection-items";
import { CollectionTemplateOptions } from "api/template-options";

export function addItemAction(item: CollectionItemWrapper): AddItemAction {
  return { type: actions.ADD_ITEM, payload: { item } };
}

export function changePageViewsAction(): ChangePageViewsAction {
  return { type: actions.CHANGE_PAGE_VIEWS };
}

export function changeCollectionLayoutAction(): ChangeCollectionLayoutAction {
  return { type: actions.CHANGE_COLLECTION_LAYOUT };
}

export function cancelCardExpandAction(): CancelCardExpandAction {
  return { type: actions.CANCEL_CARD_EXPAND };
}

export function loadLHSCollectionItemsAction(
  contentType: ContentType,
  response: ListCollectionItemsResponse
): LoadLhsCollectionItemsAction {
  return { type: actions.LOAD_LHS_COLLECTION_ITEMS, payload: { contentType, data: response } };
}

export function refreshContentAction(refreshingLHS: boolean): RefreshContentAction {
  return { type: actions.SET_LHS_REFRESH_STATE, payload: { refreshingLHS } };
}

export function loadMoreLHSCollectionItemsAction(
  contentType: ContentType,
  response: ListCollectionItemsResponse
): LoadMoreLhsCollectionItemsAction {
  return { type: actions.LOAD_MORE_LHS_COLLECTION_ITEMS, payload: { contentType, data: response } };
}

export function loadTemplateOptionsAction(templateOptions: CollectionTemplateOptions): LoadTemplateOptionsAction {
  return { type: actions.LOAD_TEMPLATE_OPTIONS, payload: { templateOptions } };
}

export function publishedAction(collection: Collection): PublishedAction {
  return { type: actions.PUBLISHED, payload: { collection } };
}

export function publishingAction(): PublishingAction {
  return { type: actions.PUBLISHING };
}

export function resetFilterAction(): ResetFilterAction {
  return { type: actions.RESET_FILTER };
}

export function resetAction(): ResetAction {
  return { type: actions.RESET };
}

export function togglePolygonSelectionAction(): TogglePolygonSelectionAction {
  return { type: actions.TOGGLE_POLYGON_SELECTION };
}

export function resetErrorsAction(): ResetErrorsAction {
  return { type: actions.RESET_ERRORS };
}

export function resetStagingSelectedCollectionAction(): ResetStagingSelectedCollectionAction {
  return { type: actions.RESET_STAGING_SELECTED_COLLECTION };
}

export function removeItemAction(item: CollectionItemWrapper): RemoveItemAction {
  return { type: actions.REMOVE_ITEM, payload: { item } };
}

export function removeCoverImageAction(): RemoveCoverImageAction {
  return { type: actions.REMOVE_COVER_IMAGE };
}

export function removeFieldErrorAction(field: string): RemoveFieldErrorAction {
  return { type: actions.REMOVE_FIELD_ERROR, payload: { field } };
}

export function setSelectedCollectionAction(
  collection: AnyCollection | UnsavedAutomatedCollectionWithRules
): SetSelectedCollectionAction {
  return { type: actions.SET_SELECTED_COLLECTION, payload: { collection } };
}

export function setCoverImagesAction(images: CoverImage[]): SetCoverImagesAction {
  return { type: actions.SET_COVER_IMAGES, payload: { images } };
}

export function setFieldEmptyErrorAction(field: string): SetFieldEmptyErrorAction {
  return { type: actions.SET_FIELD_EMPTY_ERROR, payload: { field } };
}

export function setStagingAssociatedMetadataAction(
  associatedMetadata: AssociatedMetadata
): SetStagingAssociatedMetadataAction {
  return { type: actions.SET_STAGING_ASSOCIATED_METADATA, payload: { associatedMetadata } };
}

export function switchLaneTabAction(): SwitchLaneTabAction {
  return { type: actions.SWITCH_LANE_TAB };
}

export function toggleCoverImageUploadingAction(): ToggleCoverImageUploadingAction {
  return { type: actions.TOGGLE_COVER_IMAGE_UPLOADING };
}

export function toggleAdvancedSearchAction(): ToggleAdvancedSearchAction {
  return { type: actions.TOGGLE_ADVANCED_SEARCH };
}

export function closeAdvancedSearchAction(): CloseAdvancedSearchAction {
  return { type: actions.CLOSE_ADVANCED_SEARCH };
}

export function toggleCardExpandAction(index: number): ToggleCardExpandAction {
  return { type: actions.TOGGLE_CARD_EXPAND, payload: { index } };
}

export function updateStagingSelectedCollectionAction(key: string, value: any): UpdateStagingSelectedCollectionAction {
  return {
    type: actions.UPDATE_STAGING_SELECTED_COLLECTION,
    payload: {
      key: key,
      value: value
    }
  };
}

export function updateSelectedCollectionAction(): UpdateSelectedCollectionAction {
  return { type: actions.UPDATE_SELECTED_COLLECTION };
}

export function updateSwappedItemsAction(): UpdateSwappedItemsAction {
  return { type: actions.UPDATE_SWAPPED_ITEMS };
}

export function updateSortedItemsAction(): UpdateSortedItemsAction {
  return { type: actions.UPDATE_SORTED_ITEMS };
}

export function updateFilterAction(): UpdateFilterAction {
  return { type: actions.UPDATE_FILTER };
}

export function updatePageViewsAction(): UpdatePageViewsAction {
  return { type: actions.UPDATE_PAGE_VIEWS };
}

export function updateStagingAssociatedMetadataAction(): UpdateStagingAssociatedMetadataAction {
  return { type: actions.UPDATE_STAGING_ASSOCIATED_METADATA };
}

export function updateCollectionCardAction(): UpdateCollectionCardAction {
  return { type: actions.UPDATE_COLLECTION_CARD };
}

export function updateCoverImageAction(): UpdateCoverImageAction {
  return { type: actions.UPDATE_COVER_IMAGE };
}

export function updateCoverImageDataAction(): UpdateCoverImageDataAction {
  return { type: actions.UPDATE_COVER_IMAGE_DATA };
}

export function updateStagingSelectedCollectionMetadataAction(): UpdateStagingSelectedCollectionMetadataAction {
  return { type: actions.UPDATE_STAGING_SELECTED_COLLECTION_METADATA };
}

export function updateStagingSelectedCollectionRulesAction(
  key: string,
  value: any
): UpdateStagingSelectedCollectionRulesAction {
  return { type: actions.UPDATE_STAGING_SELECTED_COLLECTION_RULES, payload: { key, value } };
}

export function pdfUploadAction(fileName: string): PdfUploadAction {
  return { type: actions.PDF_UPLOAD, payload: fileName };
}

export function pdfUploadSuccess(): PdfUploadSuccess {
  return {
    type: actions.PDF_UPLOAD_SUCCESS
  };
}
export function pdfUploadFailure(): PdfUploadFailure {
  return {
    type: actions.PDF_UPLOAD_FAILURE
  };
}

export function lhsLoadStartAction(): LhsLoadStartAction {
  return { type: actions.LHS_LOAD_START };
}

export function lhsLoadEndAction(): LhsLoadEndAction {
  return { type: actions.LHS_LOAD_END };
}

export function lhsLoadSuccessAction(): LhsLoadSuccessAction {
  return { type: actions.LHS_LOAD_SUCCESS };
}

export function lhsLoadFailureAction(): LhsLoadFailureAction {
  return { type: actions.LHS_LOAD_FAILURE };
}

export function singleCollectionPageLoadingAction(): SingleCollectionPageLoadingAction {
  return { type: actions.SINGLE_COLLECTION_PAGE_LOADING };
}

export function singleCollectionPageLoadSuccessAction(): SingleCollectionPageLoadSuccessAction {
  return { type: actions.SINGLE_COLLECTION_PAGE_LOAD_SUCCESS };
}

export function singleCollectionPageLoadFailureAction(error: Error): SingleCollectionPageLoadFailureAction {
  return { type: actions.SINGLE_COLLECTION_PAGE_LOAD_FAILURE, payload: { error } };
}

export function setStoryCardPageViewsSuccessAction(stories: Story[]): SetStoryCardPageViewsSuccess {
  return { type: actions.SET_STORY_CARD_PAGE_VIEWS_SUCCESS, payload: { stories } };
}

export function setStoryCardPageViewsFailureAction(error: Error): SetStoryCardPageViewsError {
  return { type: actions.SET_STORY_CARD_PAGE_VIEWS_ERROR, payload: { error } };
}

export function setScheduleCollectionDateErrorAction(field: string): setScheduleCollectionDateError {
  return { type: actions.SET_SCHEDULE_COLLECTION_DATE_ERROR, payload: { field } };
}

export function updateGeoPolygonAction(geoPolygon: Array<google.maps.LatLngLiteral>): UpdateGeoPolygon {
  return { type: actions.UPDATE_GEO_POLYGON, payload: { geoPolygon } };
}

export function setIsCollectionModifiedStateAction(isCollectionModified: boolean): SetIsCollectionModifiedStateAction {
  return { type: actions.SET_IS_COLLECTION_MODIFIED_STATE, payload: { isCollectionModified } };
}
