/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import Sections from "./sections";
import { SECTION_EDIT_PATH, SECTIONS_INDEX_PATH, SECTIONS_NEW_PATH } from "./routes";

export const Router = () => (
  <React.Fragment>
    <Switch>
      <Route exact path={SECTIONS_INDEX_PATH} render={(props) => <Sections {...props} />} />
      <Route exact path={SECTIONS_NEW_PATH} component={Sections} />
      <Route exact path={SECTION_EDIT_PATH} component={Sections} />
    </Switch>
  </React.Fragment>
);
