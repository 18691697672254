/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useState } from "react";
import { connect } from "react-redux";
import classnames from "classnames/bind";
import PlusCircle from "../../../components/icons/plus-circle";
import { addNewElementAction } from "../async-action-creators";
import styles from "./elements-toolbar.module.css";

import Attachment from "components/icons/story-elements/attachment";
import Embed from "components/icons/story-elements/embed";
import Image from "components/icons/story-elements/image";
import Video from "components/icons/story-elements/video-clip";
import Quote from "components/icons/story-elements/quote";
import Text from "components/icons/story-elements/text";
import Title from "components/icons/story-elements/title";
import BigFact from "components/icons/story-elements/bigfact";
import BlockQuote from "components/icons/story-elements/block-quote";
import Blurb from "components/icons/story-elements/blurb";
import Question from "components/icons/story-elements/question";
import Answer from "components/icons/story-elements/answer";
import Poll from "components/icons/story-elements/poll";
import ImageGallery from "components/icons/story-elements/image-gallery";
import Slideshow from "components/icons/story-elements/slideshow";
import Summary from "components/icons/story-elements/summary";

import Tooltip from "components/tooltip/tooltip";
import { t } from "i18n";
import { ThunkDispatch } from "redux-thunk";
import { CardId } from "api/primitive-types";
import { StoryElement } from "api/story";
import { StoryElementDirection } from "../operations/story-elements/add";
import Reference from "components/icons/story-elements/reference";
import AlsoRead from "components/icons/story-elements/also-read";
import Table from "components/icons/story-elements/table";
import QuestionAndAnswer from "components/icons/story-elements/question-and-answer";
import OutsideClickHandler from "components/outside-click-handler/outside-click-handler";
import { PartialAppState, StoryElementFeatures } from "api/route-data/story-editor";
import MoreVertical from "components/icons/more-vertical";
import { selectIsDesktopSizeViewport } from "store/viewport";
import Cta from "components/icons/story-elements/cta";

const cx = classnames.bind(styles);

const storyElementComponents = {
  Text: Text,
  Summary: Summary,
  Title: Title,
  Jsembed: Embed,
  Image: Image,
  Video: Video,
  AlsoRead: AlsoRead,
  Quote: Quote,
  BigFact: BigFact,
  Blockquote: BlockQuote,
  Blurb: Blurb,
  Attachment: Attachment,
  Table: Table,
  Question: Question,
  Answer: Answer,
  QAndA: QuestionAndAnswer,
  OpinionPoll: Poll,
  References: Reference,
  Cta: Cta,
  ImageGallery: ImageGallery
};

const createNewElement = (
  type: string,
  subElementType: string,
  onClickElement: (e: MouseEvent | React.MouseEvent) => void,
  addNewElement: (elementType: string, subElementType: string) => void,
  event: MouseEvent | React.MouseEvent
) => {
  addNewElement(type, subElementType);
  onClickElement(event);
};

const convertStoryElementNameToLowerCase = (storyElemIconName: string) => {
  return storyElemIconName
    .split(/(?=[A-Z])/)
    .map((name: string) => name.toLowerCase())
    .join("-");
};

interface StoryElementIconProps {
  storyElemIconName: string;
  features: StoryElementFeatures;
  addNewElement: (elementType: string, subElementType: string) => void;
  onClickElement: (e?: MouseEvent | React.MouseEvent) => void;
  storyTemplate?: string;
}

const StoryElementIcon: React.FC<StoryElementIconProps> = ({
  storyElemIconName,
  features,
  addNewElement,
  onClickElement,
  storyTemplate
}) => {
  const lowerCaseElement = convertStoryElementNameToLowerCase(storyElemIconName);
  const newElement = lowerCaseElement === "big-fact" ? "bigfact" : lowerCaseElement; // support existing subtypes api
  const Component = storyElementComponents[storyElemIconName];
  const displayStoryElement = (
    <React.Fragment>
      <div
        className={styles["elements-toolbar-element"]}
        data-tip
        data-for={`element-toolbar-${lowerCaseElement}`}
        data-test-id="element-toolbar-element"
        onClick={(event) => createNewElement(newElement, "", onClickElement, addNewElement, event)}>
        <Component color="currentColor" />
        <Tooltip
          id={`element-toolbar-${lowerCaseElement}`}
          place="bottom"
          effect="solid"
          value={t(`story-editor.story-element.${lowerCaseElement}`)}
        />
      </div>
      {storyElemIconName === "ImageGallery" && (
        <div
          className={styles["elements-toolbar-element"]}
          data-tip
          data-for="element-toolbar-slideshow"
          data-test-id="element-toolbar-element"
          onClick={(event) => createNewElement("image-gallery", "slideshow", onClickElement, addNewElement, event)}>
          <Slideshow color="currentColor" />
          <Tooltip
            id="element-toolbar-slideshow"
            place="bottom"
            effect="solid"
            value={t("story-editor.story-element.slideshow")}
          />
        </div>
      )}
    </React.Fragment>
  );
  if (storyElemIconName === "Video") {
    return features[`is${storyElemIconName}Enabled`] && storyTemplate === "visual-story" ? displayStoryElement : null;
  } else {
    return features[`is${storyElemIconName}Enabled`] ? displayStoryElement : null;
  }
};

interface FullyExpandedToolbarProps {
  close: (e?: MouseEvent | React.MouseEvent) => void;
  addNewElement: (elementType: string, subElementType: string) => void;
  features: StoryElementFeatures;
  storyTemplate?: string;
}

type PartiallyExpandedToolbarProps = FullyExpandedToolbarProps & {
  expand: (e?: MouseEvent | React.MouseEvent) => void;
};

const PartialExpandedToolbar: React.FC<PartiallyExpandedToolbarProps> = ({
  close,
  addNewElement,
  features,
  expand
}) => {
  return (
    <div className={styles["partial-expanded-toolbar-wrapper"]} data-test-id="partial-expanded-toolbar-wrapper">
      {Object.keys(storyElementComponents)
        .slice(0, 3)
        .map((element) => (
          <StoryElementIcon
            key={element}
            storyElemIconName={element}
            onClickElement={close}
            addNewElement={addNewElement}
            features={features}
          />
        ))}
      <div
        tabIndex={0}
        className={styles["vertical-dots"]}
        onClick={(e) => {
          e.preventDefault();
          expand();
        }}
        data-test-id="vertical-dots">
        <MoreVertical />
      </div>
    </div>
  );
};

const FullExpandedToolbar: React.FC<FullyExpandedToolbarProps> = ({
  close,
  addNewElement,
  features,
  storyTemplate
}) => {
  return (
    <div className={styles["fully-expanded-toolbar-wrapper"]}>
      {Object.keys(storyElementComponents).map((element) => (
        <StoryElementIcon
          key={element}
          storyElemIconName={element}
          onClickElement={close}
          addNewElement={addNewElement}
          features={features}
          storyTemplate={storyTemplate}
        />
      ))}
    </div>
  );
};

interface ElementsToolbarProps {
  addNewElementAt?: string;
  classname?: string;
  features: StoryElementFeatures;
  addNewElement: (elementType: string, subElementType: string) => void;
  isStoryLocked: boolean;
  isSyndicatedReadOnlyCard: boolean;
  storyTemplate?: string;
}

const ElementsToolbarDesktop: React.FunctionComponent<ElementsToolbarProps> = ({
  addNewElementAt,
  classname,
  features,
  addNewElement,
  isStoryLocked,
  isSyndicatedReadOnlyCard,
  storyTemplate
}) => {
  const [expanded, setExpanded] = useState(false);
  const elementsToolbarRef = React.createRef<HTMLDivElement>();

  const close = () => {
    expanded && setExpanded(false);
  };

  const toggleExpand = (e: MouseEvent | React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    setExpanded(!expanded);
  };

  const toolbarContainerClasses = cx(
    "elements-toolbar-container",
    {
      "elements-toolbar--is-expanded": expanded
    },
    `elements-toolbar--${addNewElementAt}`,
    classname
  );

  return (
    <OutsideClickHandler onOutsideClick={close}>
      <div
        data-test-id="elements-toolbar-container"
        className={toolbarContainerClasses}
        ref={elementsToolbarRef}
        contentEditable={false}>
        {!isStoryLocked && !isSyndicatedReadOnlyCard && (
          <div className={styles["elements-toolbar-plus"]} data-test-id="elements-toolbar-plus" onClick={toggleExpand}>
            <PlusCircle width={"32"} height={"32"} />
          </div>
        )}
        {expanded && (
          <div className={styles["elements-toolbar-wrapper"]} data-test-id="elements-toolbar-wrapper" tabIndex={0}>
            <FullExpandedToolbar
              close={close}
              addNewElement={addNewElement}
              features={features}
              storyTemplate={storyTemplate}
            />
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

enum ElementsToolbarExpandedStates {
  closed = "closed",
  partial = "partial",
  expanded = "expanded"
}

const ElementsToolbarMobile: React.FunctionComponent<ElementsToolbarProps> = ({
  addNewElementAt,
  classname,
  features,
  addNewElement,
  isStoryLocked,
  isSyndicatedReadOnlyCard,
  storyTemplate
}) => {
  const [expandedState, setExpandedState] = useState(ElementsToolbarExpandedStates.closed);
  const elementsToolbarRef = React.createRef<HTMLDivElement>();
  const plusButtonRef = React.createRef<HTMLDivElement>();

  const close = (e: MouseEvent | React.MouseEvent) => {
    if (e) {
      if (expandedState !== ElementsToolbarExpandedStates.closed)
        setExpandedState(ElementsToolbarExpandedStates.closed);
      plusButtonRef.current && plusButtonRef.current.focus();
    }
  };

  const closeAndGivePMFocus = (e: MouseEvent | React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    setExpandedState(ElementsToolbarExpandedStates.closed);
  };

  const partiallyExpand = (e: MouseEvent | React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    setExpandedState(ElementsToolbarExpandedStates.partial);
    elementsToolbarRef.current && elementsToolbarRef.current.focus();
  };

  const togglePartiallyExpand = (e: MouseEvent | React.MouseEvent) => {
    if (expandedState === ElementsToolbarExpandedStates.closed) {
      partiallyExpand(e);
    } else {
      close(e);
    }
  };

  const fullyExpand = (e: MouseEvent | React.MouseEvent) => {
    if (e) {
      e.preventDefault();
    }
    setExpandedState(ElementsToolbarExpandedStates.expanded);
  };

  const toolbarContainerClasses = cx(
    "elements-toolbar-container",
    {
      "elements-toolbar--is-expanded": expandedState !== ElementsToolbarExpandedStates.closed
    },
    `elements-toolbar--${addNewElementAt}`,
    classname
  );

  return (
    <OutsideClickHandler onOutsideClick={() => setExpandedState(ElementsToolbarExpandedStates.closed)}>
      <div className={toolbarContainerClasses} contentEditable={false} tabIndex={0}>
        {!isStoryLocked && !isSyndicatedReadOnlyCard && (
          <div
            ref={plusButtonRef}
            tabIndex={1}
            className={styles["elements-toolbar-plus"]}
            data-test-id="elements-toolbar-plus"
            onClick={togglePartiallyExpand}>
            <PlusCircle width={"32"} height={"32"} />
          </div>
        )}
        <div
          ref={elementsToolbarRef}
          tabIndex={2}
          className={styles["elements-toolbar-wrapper"]}
          data-test-id="elements-toolbar-wrapper">
          {expandedState === ElementsToolbarExpandedStates.partial && (
            <PartialExpandedToolbar
              close={closeAndGivePMFocus}
              features={features}
              addNewElement={addNewElement}
              expand={fullyExpand}
            />
          )}
          {expandedState === ElementsToolbarExpandedStates.expanded && (
            <FullExpandedToolbar
              close={closeAndGivePMFocus}
              features={features}
              addNewElement={addNewElement}
              storyTemplate={storyTemplate}
            />
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

interface ElementsToolbarContainerProps {
  addNewElementAt: string;
  features: StoryElementFeatures;
  addNewElement: (elementType: string, subElementType: string) => void;
  classname?: string;
  isDesktopSizeViewport: boolean;
  isStoryLocked: boolean;
  isSyndicatedReadOnlyCard: boolean;
  storyTemplate?: string;
}

const ElementsToolbar: React.FunctionComponent<ElementsToolbarContainerProps> = ({
  features,
  addNewElement,
  addNewElementAt,
  classname,
  isDesktopSizeViewport,
  isStoryLocked,
  isSyndicatedReadOnlyCard,
  storyTemplate
}) => {
  return isDesktopSizeViewport ? (
    <ElementsToolbarDesktop
      features={features}
      addNewElement={addNewElement}
      addNewElementAt={addNewElementAt}
      classname={classname}
      isStoryLocked={isStoryLocked}
      isSyndicatedReadOnlyCard={isSyndicatedReadOnlyCard}
      storyTemplate={storyTemplate}
    />
  ) : (
    <ElementsToolbarMobile
      features={features}
      addNewElement={addNewElement}
      addNewElementAt={addNewElementAt}
      classname={classname}
      isStoryLocked={isStoryLocked}
      isSyndicatedReadOnlyCard={isSyndicatedReadOnlyCard}
    />
  );
};

const mapStateToProps = (state: PartialAppState) => {
  return {
    features: state.features,
    isDesktopSizeViewport: selectIsDesktopSizeViewport(state),
    isStoryLocked: state.storyEditor.ui.isStoryLocked,
    storyTemplate: state.storyEditor.story["story-template"]
  };
};

interface ownProps {
  emptyCardId: CardId;
  currentStoryElement: StoryElement | null;
  addNewElementAt: StoryElementDirection;
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>, ownProps: ownProps) => {
  return {
    addNewElement: (elementType: any, subElementType: string) =>
      dispatch(
        addNewElementAction(
          elementType,
          ownProps.emptyCardId,
          ownProps.currentStoryElement,
          ownProps.addNewElementAt,
          subElementType
        )
      )
  };
};

ElementsToolbar.defaultProps = {
  addNewElementAt: "TOP"
};

export default connect(mapStateToProps, mapDispatchToProps)(ElementsToolbar);

export { ElementsToolbar, ElementsToolbarMobile, ElementsToolbarDesktop };
