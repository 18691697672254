/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import TextField from "components/text-field/text-field";
import { get } from "lodash";
import { AmpConfig, DoubleclickTypes } from "api/amp-config";
import { convertStringToInteger, convertNumberToString } from "../utils";
//TODO: move to I18n
//TODO: I18n translate server error message
import { isClientValidationError } from "utils/validation.utils";
import styles from "./advertisement-config.module.css";

interface Props {
  ampConfig: AmpConfig;
  saveErrors: Error | null;
  updateConfig: (key: string, changes: DoubleclickTypes) => void;
}

const AdvertisementConfig: React.SFC<Props> = ({ ampConfig, saveErrors, updateConfig }) => {
  return (
    <React.Fragment>
      <div className={styles["amp-config-category-details-container"]}>
        <h3 className={styles["amp-config-title"]}>{t("settings.amp.advertisements.body_ad.title")}</h3>
        <p className={styles["amp-config-description"]}>{t("settings.amp.advertisements.body_ad.description")}</p>
      </div>
      <div className={styles["amp-config-fields-container"]}>
        <TextField
          label={t("settings.amp.advertisements.body_ad.unit_path")}
          value={ampConfig["doubleclick"]["body-ad"]["unit-path"]}
          onChange={(value) =>
            updateConfig("doubleclick", {
              ...ampConfig.doubleclick,
              "body-ad": { ...ampConfig.doubleclick["body-ad"], "unit-path": value }
            })
          }
          hint={t("common.hint-eg", { value: "/124435/AMP_body" })}
          errorMessage={get(saveErrors, ["doubleclick", "body-ad", "unit-path", "code"])}
        />
        <TextField
          label={t("settings.amp.advertisements.body_ad.width")}
          value={convertNumberToString(ampConfig["doubleclick"]["body-ad"].width)}
          onChange={(value) =>
            updateConfig("doubleclick", {
              ...ampConfig.doubleclick,
              "body-ad": { ...ampConfig.doubleclick["body-ad"], width: convertStringToInteger(value) }
            })
          }
          hint={t("settings.amp.advertisements.size-hint", { size: "250" })}
          errorMessage={
            //TODO: move to I18n
            //TODO: I18n translate server error message
            isClientValidationError<AmpConfig>(saveErrors)
              ? get(saveErrors, ["errors", "doubleclick", "body-ad", "width", "0"])
              : get(saveErrors, ["doubleclick", "body-ad", "width", "code"])
          }
        />
        <TextField
          label={t("settings.amp.advertisements.body_ad.height")}
          value={convertNumberToString(ampConfig["doubleclick"]["body-ad"].height)}
          onChange={(value) =>
            updateConfig("doubleclick", {
              ...ampConfig.doubleclick,
              "body-ad": { ...ampConfig.doubleclick["body-ad"], height: convertStringToInteger(value) }
            })
          }
          hint={t("settings.amp.advertisements.size-hint", { size: "300" })}
          errorMessage={
            //TODO: move to I18n
            //TODO: I18n translate server error message
            isClientValidationError<AmpConfig>(saveErrors)
              ? get(saveErrors, ["errors", "doubleclick", "body-ad", "height", "0"])
              : get(saveErrors, ["doubleclick", "body-ad", "height", "code"])
          }
        />
      </div>
      <div className={styles["amp-config-category-details-container"]}>
        <h3 className={styles["amp-config-title"]}>{t("settings.amp.advertisements.top_ad.title")}</h3>
        <p className={styles["amp-config-description"]}>{t("settings.amp.advertisements.top_ad.description")}</p>
      </div>
      <div className={styles["amp-config-fields-container"]}>
        <TextField
          label={t("settings.amp.advertisements.top_ad.unit_path")}
          value={ampConfig["doubleclick"]["top-ad"]["unit-path"]}
          onChange={(value) =>
            updateConfig("doubleclick", {
              ...ampConfig.doubleclick,
              "top-ad": { ...ampConfig.doubleclick["top-ad"], "unit-path": value }
            })
          }
          hint={t("common.hint-eg", { value: "/124435/AMP_top" })}
          errorMessage={get(saveErrors, ["doubleclick", "top-ad", "unit-path", "code"])}
        />
        <TextField
          label={t("settings.amp.advertisements.top_ad.width")}
          value={convertNumberToString(ampConfig["doubleclick"]["top-ad"].width)}
          onChange={(value) =>
            updateConfig("doubleclick", {
              ...ampConfig.doubleclick,
              "top-ad": { ...ampConfig.doubleclick["top-ad"], width: convertStringToInteger(value) }
            })
          }
          hint={t("settings.amp.advertisements.size-hint", { size: "250" })}
          errorMessage={
            //TODO: move to I18n
            //TODO: I18n translate server error message
            isClientValidationError<AmpConfig>(saveErrors)
              ? get(saveErrors, ["errors", "doubleclick", "top-ad", "width", "0"])
              : get(saveErrors, ["doubleclick", "top-ad", "width", "code"])
          }
        />
        <TextField
          label={t("settings.amp.advertisements.top_ad.height")}
          value={convertNumberToString(ampConfig["doubleclick"]["top-ad"].height)}
          onChange={(value) =>
            updateConfig("doubleclick", {
              ...ampConfig.doubleclick,
              "top-ad": { ...ampConfig.doubleclick["top-ad"], height: convertStringToInteger(value) }
            })
          }
          hint={t("settings.amp.advertisements.size-hint", { size: "300" })}
          errorMessage={
            //TODO: move to I18n
            //TODO: I18n translate server error message
            isClientValidationError<AmpConfig>(saveErrors)
              ? get(saveErrors, ["errors", "doubleclick", "top-ad", "height", "0"])
              : get(saveErrors, ["doubleclick", "top-ad", "height", "code"])
          }
        />
      </div>
      <div className={styles["amp-config-category-details-container"]}>
        <h3 className={styles["amp-config-title"]}>{t("settings.amp.advertisements.bottom_ad.title")}</h3>
        <p className={styles["amp-config-description"]}>{t("settings.amp.advertisements.bottom_ad.description")}</p>
      </div>
      <div className={styles["amp-config-fields-container"]}>
        <TextField
          label={t("settings.amp.advertisements.bottom_ad.unit_path")}
          value={ampConfig["doubleclick"]["bottom-ad"]["unit-path"]}
          onChange={(value) =>
            updateConfig("doubleclick", {
              ...ampConfig.doubleclick,
              "bottom-ad": { ...ampConfig.doubleclick["bottom-ad"], "unit-path": value }
            })
          }
          hint={t("common.hint-eg", { value: "/124435/AMP_bottom" })}
          errorMessage={get(saveErrors, ["doubleclick", "bottom-ad", "unit-path", "code"])}
        />
        <TextField
          label={t("settings.amp.advertisements.bottom_ad.width")}
          value={convertNumberToString(ampConfig["doubleclick"]["bottom-ad"].width)}
          onChange={(value) =>
            updateConfig("doubleclick", {
              ...ampConfig.doubleclick,
              "bottom-ad": { ...ampConfig.doubleclick["bottom-ad"], width: convertStringToInteger(value) }
            })
          }
          hint={t("settings.amp.advertisements.size-hint", { size: "250" })}
          errorMessage={
            //TODO: move to I18n
            //TODO: I18n translate server error message
            isClientValidationError<AmpConfig>(saveErrors)
              ? get(saveErrors, ["errors", "doubleclick", "bottom-ad", "width", "0"])
              : get(saveErrors, ["doubleclick", "bottom-ad", "width", "code"])
          }
        />
        <TextField
          label={t("settings.amp.advertisements.bottom_ad.height")}
          value={convertNumberToString(ampConfig["doubleclick"]["bottom-ad"].height)}
          onChange={(value) =>
            updateConfig("doubleclick", {
              ...ampConfig.doubleclick,
              "bottom-ad": { ...ampConfig.doubleclick["bottom-ad"], height: convertStringToInteger(value) }
            })
          }
          hint={t("settings.amp.advertisements.size-hint", { size: "300" })}
          errorMessage={
            //TODO: move to I18n
            //TODO: I18n translate server error message
            isClientValidationError<AmpConfig>(saveErrors)
              ? get(saveErrors, ["errors", "doubleclick", "bottom-ad", "height", "0"])
              : get(saveErrors, ["doubleclick", "bottom-ad", "height", "code"])
          }
        />
      </div>
    </React.Fragment>
  );
};

export default AdvertisementConfig;
