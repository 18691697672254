/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect, useState } from "react";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import classnames from "classnames/bind";
import Spacer from "components/spacer/spacer";
import styles from "./consumers.module.css";
import { ThunkDispatch } from "redux-thunk";
import wrapPage from "containers/page/page";
import { UserReportButton } from "swallow/components/user-report-button";
import { NotificationMessage } from "swallow";
import {
  fetchConsumersData,
  searchConsumersData,
  consumerReportNotificationAction,
  getConsumersByPageAction,
  consumersUpdateStagingFilter,
  consumersResetFilter,
  consumersResetConsumersList,
  SelectedConsumer,
  setConsumersLoadingStatus,
  deleteConsumer
} from "store/consumer/consumer";
import { getAdminAccessToken as tokenFn } from "api/bridgekeeper";
import { SearchBar } from "@quintype/em/components/search-bar";
import { t } from "i18n";
import TablePagination from "components/table-pagination/table-pagination";
import { ConsumersPagination, PartialAppState, FilterSet } from "store/consumer/consumer";
import ReactTable from "react-table";
import Spinner from "components/spinner/spinner";
import { TokenApiFn } from "swallow/middleware";
import Filter from "components/icons/filter";
import { Button } from "@quintype/em/components/button";
import { navigate } from "../../../../utils/routes.utils";
import { CONSUMERS_FILTERS_PATH } from "../../routes";
import FiltersInspector from "../filters-inspector/filters-inspector";
import ActiveFiltersList from "../active-filters-list/active-filters-list";
import { selectIsDesktopSizeViewport } from "store/viewport";
import { formattedDate } from "utils";
import NoContentCard from "components/no-content-card/no-content-card";
import File from "components/icons/file";
import Trash from "components/icons/trash";
import DeleteConfirmationModal from "components/confirmation-modal/confirmation-modal";

const cx = classnames.bind(styles);
interface ConsumersStateProps {
  integrationId?: number;
  frontendRealmId?: number;
  searchTerm: string;
  consumers: SelectedConsumer[];
  currentPage: number;
  consumersPagination: ConsumersPagination;
  consumersPerPage: number;
  isLoading: boolean;
  currentFilterSet: FilterSet;
  isDesktopSizeViewport: boolean;
  title: string;
  canDeleteConsumer: boolean;
}

interface ConsumersDispatchProps {
  loadConsumersData: (tokenFn: TokenApiFn, integrationId: number, realmId: number) => void;
  setSearchTerm: ({ searchTerm, tokenFn, integrationId, realmId }) => void;
  getConsumersByPage: ({ tokenFn, integrationId, realmId, searchTerm, pageNumber }) => void;
  sendReportNotification(notificationMessage: NotificationMessage): void;
  toggleFiltersInspector: (tabSlug: string) => void;
  openFiltersInspector: () => void;
  deleteConsumer: (tokenFn: TokenApiFn, integrationId: number, realmId: number, consumerId: number) => void;
  updateStagingFilter: (key: string, value: any) => void;
  clearFilters: (tokenFn: TokenApiFn, integrationId: number, realmId: number, isLoading: boolean) => void;
}

interface OwnProps {
  showFiltersInspector: boolean;
}

type ConsumersProps = ConsumersStateProps & ConsumersDispatchProps & OwnProps;

const Consumers: React.FC<ConsumersProps> = ({
  currentPage,
  searchTerm,
  setSearchTerm,
  getConsumersByPage,
  consumersPagination,
  consumersPerPage,
  consumers,
  isLoading,
  integrationId,
  frontendRealmId: realmId,
  sendReportNotification,
  loadConsumersData,
  openFiltersInspector,
  showFiltersInspector,
  updateStagingFilter,
  currentFilterSet,
  clearFilters,
  isDesktopSizeViewport,
  deleteConsumer,
  canDeleteConsumer
}) => {
  useEffect(() => {
    integrationId && realmId && loadConsumersData(tokenFn, integrationId, realmId);
  }, [loadConsumersData, integrationId, realmId]);

  const [selectedConsumerId, setSelectedConsumerId] = useState<number | null>(null);
  const [isDeleteConfirmationModalOpen, setDeleteConfirmationModalOpen] = useState(false);
  return (
    <div className={cx("page-container consumers")} data-test-id="page-container-consumers">
      <h2>Consumers</h2>
      <Spacer token="xs" align="vertical" />
      {!(integrationId && realmId) ? (
        <NoContentCard>
          <File />
          <div className="no-content-message">{t("consumers.no_token")}</div>
        </NoContentCard>
      ) : (
        <>
          <div className={styles["consumers-actions-desc"]} data-test-id={"consumers-actions-desc"}>
            Click on the Send Report button to trigger generation of report which contains contact details and metadata
            of consumers listed in the table, and send email to all administrators of this domain.
          </div>
          <Spacer token="l" align="vertical" />
          <FiltersInspector
            isActive={showFiltersInspector}
            updateFilter={updateStagingFilter}
            integrationId={integrationId}
            realmId={realmId}
          />
          <div className={styles["consumers-table-container"]} data-test-id="consumers-table-container">
            {!isDesktopSizeViewport && (
              <div className={styles["consumers-table-pagination-top"]}>
                <div className={styles["search-filter-container"]}>
                  <div className={styles["consumers-searchbar"]}>
                    <SearchBar
                      placeholder={t("consumers.search_user_placeholder")}
                      value={searchTerm}
                      onChange={(searchTerm) => setSearchTerm({ searchTerm, tokenFn, integrationId, realmId })}
                    />
                  </div>
                  <div data-test-id="filter-button">
                    <Button testId="filter-button" id="filter-button" type="transparent" onClick={openFiltersInspector}>
                      <Filter />
                    </Button>
                  </div>
                </div>
                <div data-test-id="active-filters-list">
                  <ActiveFiltersList
                    filterSet={currentFilterSet}
                    clearFilters={() => {
                      clearFilters(tokenFn, integrationId, realmId, true);
                    }}
                  />
                </div>
                <div className={styles["consumer-table-pagination"]}>
                  <TablePagination
                    specifiedPage={currentPage}
                    onPageChange={(pageNumber) =>
                      getConsumersByPage({ tokenFn, integrationId, realmId, searchTerm, pageNumber })
                    }
                    totalCount={consumersPagination && consumersPagination.total}
                    perPage={consumersPerPage}
                    classname={styles["consumer-table-pagination"]}
                  />
                </div>
              </div>
            )}
            {isDesktopSizeViewport && (
              <>
                <div className={styles["consumers-table-pagination-top"]}>
                  <div className={styles["consumers-searchbar"]}>
                    <SearchBar
                      placeholder={t("consumers.search_user_placeholder")}
                      value={searchTerm}
                      onChange={(searchTerm) => setSearchTerm({ searchTerm, tokenFn, integrationId, realmId })}
                    />
                  </div>
                  <div className={styles["consumer-table-pagination"]}>
                    <TablePagination
                      specifiedPage={currentPage}
                      onPageChange={(pageNumber) =>
                        getConsumersByPage({ tokenFn, integrationId, realmId, searchTerm, pageNumber })
                      }
                      totalCount={consumersPagination && consumersPagination.total}
                      perPage={consumersPerPage}
                    />
                  </div>
                  <div className={styles["filter-button"]} data-test-id="filter-button">
                    <Button testId="filter-button" id="filter-button" type="transparent" onClick={openFiltersInspector}>
                      <Filter />
                    </Button>
                  </div>
                </div>
                <div data-test-id="active-filters-list">
                  <ActiveFiltersList
                    filterSet={currentFilterSet}
                    clearFilters={() => {
                      clearFilters(tokenFn, integrationId, realmId, true);
                    }}
                  />
                </div>
              </>
            )}
            <div className={styles["react-table"]}>
              <ReactTable
                showPaginationTop={false}
                showPaginationBottom={false}
                showPagination={false}
                showPageSizeOptions={false}
                defaultPageSize={1}
                pageSize={Math.min(consumers.length, consumersPerPage)}
                sortable={false}
                data={consumers}
                columns={[
                  {
                    Header: "",
                    accessor: "avatar-url",
                    Cell: (row) => (
                      <div className={styles["consumers-table-avatar-container"]}>
                        {row.value && (
                          <img className={styles["consumers-avatar-image"]} src={row.value} alt={row.name} />
                        )}
                      </div>
                    ),
                    maxWidth: 50
                  },
                  {
                    Header: t("consumers.table.name"),
                    accessor: "name",
                    Cell: (row) => (
                      <span className={styles["consumers-name-container"]} data-test-id="consumers-name-container">
                        {row.value}
                      </span>
                    )
                  },
                  {
                    Header: t("consumers.table.email"),
                    accessor: "email",
                    Cell: (row) => (
                      <span className={styles["consumers-name-container"]} data-test-id="consumers-name-container">
                        {row.value}
                      </span>
                    )
                  },
                  {
                    Header: t("consumers.table.phone"),
                    accessor: "phone-number",
                    Cell: (row) => {
                      return (
                        <span
                          className={styles["consumers-name-container-phone"]}
                          data-test-id="consumers-name-container">
                          {row.original["login-phone-number"] || row.original["phone-number"]}
                        </span>
                      );
                    }
                  },
                  {
                    Header: t("consumers.table.joined"),
                    accessor: "created-at",
                    Cell: (row) => (
                      <span className={styles["consumers-name-container"]} data-test-id="consumers-name-container">
                        {formattedDate(new Date(row.value).getTime())}
                      </span>
                    )
                  },
                  {
                    Cell: ({ original }) =>
                      canDeleteConsumer && (
                        <Button
                          type="link"
                          onClick={() => {
                            setSelectedConsumerId(original.id);
                            setDeleteConfirmationModalOpen(true);
                          }}>
                          <Trash color="var(--mono-4)" />
                        </Button>
                      )
                  }
                ]}
                onPageChange={(pageNumber) =>
                  getConsumersByPage({ tokenFn, integrationId, realmId, searchTerm, pageNumber })
                }
                pages={Math.ceil(consumersPagination && consumersPagination.total / consumersPerPage)}
                className="consumers-table"
                LoadingComponent={() =>
                  isLoading ? (
                    <div className={styles["consumers-table-loading"]}>
                      <Spinner classname={"consumers-spinner"} />
                    </div>
                  ) : null
                }
              />
            </div>
            <UserReportButton
              tokenApiFn={tokenFn}
              integrationId={integrationId}
              realmId={realmId && realmId}
              notificationFn={sendReportNotification}
              currentFilterSet={currentFilterSet}
              searchTerm={searchTerm}
              isDesktopSizeViewport={isDesktopSizeViewport}
            />
          </div>
        </>
      )}
      <DeleteConfirmationModal
        showConfirmationModal={isDeleteConfirmationModalOpen}
        headerText={t("consumers.delete_modal.title")}
        text={t("consumers.delete_modal.info")}
        confirmButtonLabel={t("common.delete")}
        cancelButtonLabel={t("common.cancel")}
        onConfirm={() => {
          integrationId &&
            realmId &&
            selectedConsumerId &&
            deleteConsumer(tokenFn, integrationId, realmId, selectedConsumerId);
          setDeleteConfirmationModalOpen(false);
          setSelectedConsumerId(null);
        }}
        onCancel={() => setDeleteConfirmationModalOpen(false)}
      />
    </div>
  );
};

const mapStateToProps = (state: PartialAppState): ConsumersStateProps => {
  const consumersPage = state.consumers;
  return {
    title: "consumers",
    consumers: consumersPage.consumers,
    canDeleteConsumer: !!state.features.canDeleteConsumer,
    consumersPagination: consumersPage.consumersPagination,
    consumersPerPage: consumersPage.consumersPerPage,
    currentPage: consumersPage.ui.currentPage,
    isLoading: consumersPage.ui.main.loading,
    integrationId:
      state.config.bridgekeeper &&
      state.config.bridgekeeper.swallow &&
      state.config.bridgekeeper.swallow["integration-id"],
    frontendRealmId: state.config.bridgekeeper && state.config.bridgekeeper["front-end-realm"],
    currentFilterSet: consumersPage.currentFilterSet,
    searchTerm: consumersPage.ui.searchTerm,
    isDesktopSizeViewport: selectIsDesktopSizeViewport(state)
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): ConsumersDispatchProps => {
  return {
    loadConsumersData: (tokenFn: TokenApiFn, integrationId: number, realmId: number) =>
      dispatch(fetchConsumersData(tokenFn, integrationId, realmId)),
    deleteConsumer: (tokenFn: TokenApiFn, integrationId: number, realmId: number, consumerId: number) => {
      dispatch(deleteConsumer(tokenFn, integrationId, realmId, consumerId));
      dispatch(fetchConsumersData(tokenFn, integrationId, realmId));
    },
    sendReportNotification: (notificationMessage: NotificationMessage) =>
      dispatch(consumerReportNotificationAction(notificationMessage)),
    setSearchTerm: ({ searchTerm, tokenFn, integrationId, realmId }) =>
      dispatch(searchConsumersData({ searchTerm, tokenFn, integrationId, realmId })),
    getConsumersByPage: ({ tokenFn, integrationId, realmId, searchTerm, pageNumber }) =>
      dispatch(getConsumersByPageAction({ tokenFn, integrationId, realmId, searchTerm, pageNumber })),
    toggleFiltersInspector: (tabSlug: string) => navigate(CONSUMERS_FILTERS_PATH, { tabSlug }),
    openFiltersInspector: () => dispatch(navigate(CONSUMERS_FILTERS_PATH)),
    updateStagingFilter: (key: string, value: any) =>
      dispatch(consumersUpdateStagingFilter({ payload: { key, value } })),
    clearFilters: (tokenFn: TokenApiFn, integrationId: number, realmId: number, isLoading: boolean) => {
      dispatch(consumersResetConsumersList());
      dispatch(consumersResetFilter());
      dispatch(setConsumersLoadingStatus({ isLoading }));
      dispatch(fetchConsumersData(tokenFn, integrationId, realmId));
    }
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), wrapPage())(Consumers);

export { Consumers };
