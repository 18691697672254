/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "../client";
import { CollectionId, SectionId, PublisherId, MemberId } from "api/primitive-types";
import { EmailListType, FormioFileUploadConfig, GoogleMapConfig } from "store/route-data";
import { Role } from "api/users";
import { StorySlugTypes } from "pages/settings/initial-state";
import { StoryTemplate } from "./collection-route-data";

export interface RouteDataResponse {
  httpStatusCode: number;
  path: string;
  appVersion: string;
  config: Config;
  features: Features;
}

interface Integration {
  "integration-id": number;
}

export interface BridgekeeperConfig {
  swallow: Integration;
  sso: Integration;
  "front-end-realm": number;
}

export interface FormsConfig {
  defaultMailingList: EmailListType;
  fileUpload: FormioFileUploadConfig | {};
}

export interface StorySlugConfig {
  type: StorySlugTypes | null;
  options?: string[];
}

export interface Config {
  publisher: Publisher;
  member: Member;
  sections: Array<Section>;
  publisherWideBannerMessage: string | null;
  asana?: Asana;
  googleMaps?: GoogleMapConfig;
  bridgekeeper?: BridgekeeperConfig;
  defaultRole?: Role;
  isBridgekeeperIntegrationEnabled?: boolean;
  forms?: FormsConfig;
  storySlug?: StorySlugConfig;
  mediaSources?: string[];
  "help-url": string;
  storyTemplates?: StoryTemplate[];
}

export interface Asana {
  "oauth-uri": string | null;
  "redirect-uri": string | null;
  "client-id": string | null;
}
export interface Member {
  id: MemberId;
  name: string;
  "avatar-url": string | null;
  settings?: { [key: string]: any } | null;
  roles?: Role[] | [];
}

type Direction = "ltr" | "rtl";

export interface Publisher {
  id: PublisherId;
  name: string;
  "website-url": string;
  "editor-url": string;
  i18n: {
    locale: string;
    "locale-display": string;
    dir: Direction;
  };
  "push-notification-to-story-mapping": {
    title: string;
    message: string;
  };
  theme: string;
  "story-slug-format": string;
  "section-url-format": string;
}

export interface Section {
  id: SectionId;
  "ancestor-ids": Array<SectionId>;
  "collection-id": CollectionId | null;
  "parent-id": SectionId | null;
  "tree-ids": Array<SectionId>;
  name: string;
  slug: string;
}

export interface Features {
  devEncodeImageS3?: boolean;
  enableWatermarkImages?: boolean;
  enableAnalyticsInNavBar?: boolean;
  enableContentIconInNavBar?: boolean;
  enableMediaLibraryIconInNavBar?: boolean;
  enableManageIconInNavBar?: boolean;
  enableSettingsIconInNavBar?: boolean;
  enableBillingIconInNavBar?: boolean;
  enableConsumerDashboardIconInNavBar?: boolean;
  enableCollectionsInSidebar?: boolean;
  enablePushNotificationInSidebar?: boolean;
  enableStaticPageInSidebar?: boolean;
  enableGettyImagesInSidebar?: boolean;
  enableAdminLinksInSidebar?: boolean;
  enableTagsInSidebar?: boolean;
  enableEntitiesInSidebar?: boolean;
  enableAccessLevelsInSidebar?: boolean;
  enablePromotionsInSidebar?: boolean;
  enableContributorRolesInSidebar?: boolean;
  enableAmpInSidebar?: boolean;
  enableBreakingNewsInSidebar?: boolean;
  enableCollectionsConfigureInSidebar?: boolean;
  isTeamsEnabled?: boolean;
  associatedMetadata?: boolean;
  showPublicPreviewLink?: boolean;
  accessLevelValue?: boolean;
  canDeleteStory?: boolean;
  isViewCounterViewEnabled?: boolean;
  isFreeTextAttributesEnabled?: boolean;
  isAccessLevelValuesEnabled?: boolean;
  isPromotionalMessageEnabled?: boolean;
  isTagRecommendationEnabled?: boolean;
  hasTagCreatePermission?: boolean;
  hasSocialMediaCheckPermission?: boolean;
  isStoryScheduleWithCollectionEnabled?: boolean;
  showAddNewButton?: boolean;
  canCreateBreakingNews?: boolean;
  canCreateCollections?: boolean;
  canCreateStory?: boolean;
  canAccessBilling?: boolean;
  isTaskPlannerEnabled?: boolean;
  canAddAuthor?: boolean;
  canCreatePushNotifications?: boolean;
  hasAdminPermission?: boolean;
  isUpdateNewStoryCreationEnabled?: boolean;
  enableEXIFLocation?: boolean;
  enablePushNotificationChannels?: boolean;
  isDarkModeEnabled?: boolean;
  isDefaultManageInspectorEnabled?: boolean;
  isBridgekeeperIntegrationEnabled?: boolean;
  isDomainManagerEnabled?: boolean;
  isTFAEnabled?: boolean;
  isFormsEnabled?: boolean;
  canCreateForms?: boolean;
  enableStoryImageHyperlink?: boolean;
  canAccessStorySlug?: boolean;
  canDeleteConsumer?: boolean;
  canManageEmbargo?: boolean;
  isAlternativeHeadlineAndImageEnabled?: boolean;
  enableHousekeeping?: boolean;
}

export const getRouteData = (path: string): Promise<RouteDataResponse> => {
  return client
    .url("/api/route-data.json")
    .query({ path })
    .get()
    .json();
};
