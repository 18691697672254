/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Section, AnySection, SectionTree, UnsavedSection } from "api/section";
import { SectionId } from "api/primitive-types";

export function isExistingSection(section: AnySection): section is Section {
  return !!(section as Section).id;
}

export function findSectionInTree(id: SectionId, sections: SectionTree[]): SectionTree | null {
  const match = sections.find((_section) => _section.id === id);

  if (match) {
    return match;
  } else {
    const ancestor = sections.find((_section) => _section["tree-ids"].includes(id));

    if (ancestor) {
      return findSectionInTree(id, ancestor.children);
    } else {
      return null;
    }
  }
}

export function sectionToUnsavedSection(section: Section): UnsavedSection {
  return {
    name: section.name,
    slug: section.slug,
    "display-name": section["display-name"],
    "parent-id": section["parent-id"],
    data: section.data,
    "seo-metadata": section["seo-metadata"]
  };
}
