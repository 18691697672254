/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";
import {
  listTagsAction,
  listTagsSuccessAction,
  listTagsFailureAction,
  saveTagAction,
  saveTagSuccessAction,
  saveTagFailureAction,
  initNewTagAction,
  cancelCreateOrEditTagAction,
  editTagAction,
  editTagFailureAction,
  fetchStoryCountsAction,
  fetchStoryCountsSuccessAction,
  fetchStoryCountsFailureAction,
  changeSpecifiedPage,
  setGeneratedSlugAction
} from "./action-creators";
import * as api from "api/tag";
import { generateSlug } from "api/slug";
import { navigate } from "utils/routes.utils";
import { TAGS_INDEX_PATH } from "./routes";
import { isExistingTag, tagToUnsavedTag } from "./utils";
import { validateTag } from "./validate";
import { toClientValidationError } from "utils/validation.utils";
import { PartialAppState } from "./state";
import { TagId } from "api/primitive-types";
import { TagNotFoundError } from "./errors";
import { t } from "i18n";
import * as searchAPI from "api/search";

export const loadTags = (searchTerm?: string) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(listTagsAction(searchTerm));

  try {
    const response = await api.listTags(searchTerm);
    dispatch(listTagsSuccessAction(response));
    dispatch(changeSpecifiedPage(1));

    dispatch(fetchStoryCounts(response.tags));
  } catch (error) {
    dispatch(listTagsFailureAction(error));
  }
};

export const saveTag = (tag: api.AnyTag) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(saveTagAction(tag));

  const errors = validateTag(tag);
  if (errors) {
    dispatch(saveTagFailureAction(tag, toClientValidationError<api.AnyTag>(errors)));
    return;
  }

  try {
    const response = isExistingTag(tag)
      ? await api.updateTag((tag as api.Tag).id, tagToUnsavedTag(tag as api.Tag))
      : await api.saveNewTag(tag);
    dispatch(saveTagSuccessAction(response["tag"]));
    dispatch(navigate(TAGS_INDEX_PATH));
    dispatch(loadTags());
  } catch (error) {
    dispatch(saveTagFailureAction(tag, error));
  }
};

export const addNewTag = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(initNewTagAction());
};

export const editTag = (tagIdParam: string | TagId) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const tagId = typeof tagIdParam === "string" ? parseInt(tagIdParam, 10) : tagIdParam;

  let tag: api.Tag | undefined, error: Error | undefined;

  if (!Number.isNaN(tagId)) {
    tag = getState().tags.tags.find((_tag) => _tag.id === tagId);
  }

  if (!tag) {
    try {
      tag = await api.fetchTag(tagId);
    } catch (e) {
      error = e;
    }
  }

  if (tag) {
    dispatch(editTagAction(tag));
  } else if (error && (error as any).status && (error as any).status !== 404) {
    dispatch(editTagFailureAction(error));
  } else {
    const idStr = tagIdParam.toString();
    dispatch(editTagFailureAction(new TagNotFoundError(idStr, t("tags.messages.not-found", { id: idStr }))));
  }
};

export const cancelSaveTag = (tag: api.AnyTag) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(cancelCreateOrEditTagAction(tag));
  dispatch(navigate(TAGS_INDEX_PATH));
};

export const fetchStoryCounts = (tags: api.Tag[]) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(fetchStoryCountsAction(tags));

  try {
    const storyCounts = await searchAPI.fetchStoryCountsForTags(tags);
    dispatch(fetchStoryCountsSuccessAction(tags, storyCounts));
  } catch (error) {
    dispatch(fetchStoryCountsFailureAction(tags, error));
  }
};

export const changeSpecifiedPageAction = (pageNumber: number) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  dispatch(changeSpecifiedPage(pageNumber));
  const offset = (pageNumber - 1) * 20;
  const searchTerm = getState().tags.app.searchTerm;

  try {
    const response = await api.listTags(searchTerm, offset);
    dispatch(listTagsSuccessAction(response));

    dispatch(fetchStoryCounts(response.tags));
  } catch (error) {
    dispatch(listTagsFailureAction(error));
  }
};

export const setGeneratedSlug = (tag: api.AnyTag) => async (dispatch: ThunkDispatch<any, any, any>) => {
  try {
    const response = await generateSlug(tag.name);
    if (response.slug) {
      dispatch(setGeneratedSlugAction({ ...tag, slug: response.slug }));
    }
  } catch (error) {
    console.error("Failed to generate slug");
  }
};
