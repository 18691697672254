/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";

import Button from "components/button/button";
import Header from "components/header/header";
import { PartialAppState } from "./state";
import { Config } from "api/promotional-messages";
import { savePromotionalMessages } from "./async-action-creators";

interface StateProps {
  isPromotionalMessagesModified: boolean;
  promotionalMessagesConfig: Config | null;
}

interface DispatchProps {
  savePromotionalMessages: (config: Config) => void;
}

type Props = StateProps & DispatchProps;

class PromotionalMessagesHeader extends React.Component<Props> {
  render() {
    const { isPromotionalMessagesModified, savePromotionalMessages, promotionalMessagesConfig } = this.props;
    return (
      <React.Fragment>
        <Header>
          {promotionalMessagesConfig && isPromotionalMessagesModified && (
            <Button variant="success" type="primary" onClick={() => savePromotionalMessages(promotionalMessagesConfig)}>
              {t("save")}
            </Button>
          )}
        </Header>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    isPromotionalMessagesModified: state.promotionalMessages.ui.isPromotionalMessagesModified,
    promotionalMessagesConfig: state.promotionalMessages.promotionalMessagesConfig
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    savePromotionalMessages: (promotionalMessagesConfig) => dispatch(savePromotionalMessages(promotionalMessagesConfig))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PromotionalMessagesHeader);

export { PromotionalMessagesHeader };
