/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import { StoryId } from "api/primitive-types";
import { Story } from "api/story";
import { closeStoryRestoreModal } from "pages/story-editor/action-creators";
import { restoreStoryVersionAction } from "pages/story-editor/async-action-creators";
import { PartialAppState } from "pages/story-editor/state";
import ConfirmationModal from "components/confirmation-modal/confirmation-modal";

interface StateProps {
  showStoryRestoreModal: boolean;
  restoreVersionId: string | null;
  story: Story;
}

interface DispatchProps {
  restoreStoryVersion(storyId: StoryId, storyVersionId: StoryId): void;
  closeStoryRestoreModal: VoidFunction;
}

type Props = StateProps & DispatchProps;

const RestoreStoryVersionModal: React.FC<Props> = ({
  showStoryRestoreModal,
  closeStoryRestoreModal,
  restoreVersionId,
  restoreStoryVersion,
  story
}) => {
  return (
    <ConfirmationModal
      showConfirmationModal={showStoryRestoreModal}
      headerText={t("story-editor.confirmation-modal.restore-confirmation")}
      text={t("story-editor.confirmation-modal.restore-message")}
      confirmButtonLabel={t("story-editor.confirmation-modal.restore")}
      cancelButtonLabel={t("story-editor.confirmation-modal.cancel")}
      onConfirm={() => restoreVersionId && restoreStoryVersion(story["story-content-id"], restoreVersionId)}
      onCancel={closeStoryRestoreModal}
      onClose={closeStoryRestoreModal}
    />
  );
};

export default connect(
  (state: PartialAppState): StateProps => {
    return {
      showStoryRestoreModal: state.storyEditor.ui.showStoryRestoreModal,
      restoreVersionId: state.storyEditor.ui.restoreVersionId,
      story: state.storyEditor.story as Story
    };
  },
  (dispatch: ThunkDispatch<PartialAppState, any, AnyAction>): DispatchProps => {
    return {
      restoreStoryVersion: (storyId, storyVersionId) => dispatch(restoreStoryVersionAction(storyId, storyVersionId)),
      closeStoryRestoreModal: () => dispatch(closeStoryRestoreModal())
    };
  }
)(RestoreStoryVersionModal);
