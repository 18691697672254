/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { createSlice } from "@reduxjs/toolkit";

export type PartialAppState = { viewport: ViewportState };

export interface ViewportState {
  isDesktopSizeViewport: boolean;
}

interface viewportAction {
  payload: {
    isDesktopSizeViewport: boolean;
  };
}

export const INITIAL_VIEWPORT_STATE: ViewportState = {
  isDesktopSizeViewport: window.matchMedia("(min-width: 1024px)").matches
};

const { reducer, actions } = createSlice({
  initialState: INITIAL_VIEWPORT_STATE,
  name: "viewport",
  reducers: {
    updateViewportSize: (state: ViewportState, { payload: { isDesktopSizeViewport } }: viewportAction) => {
      state.isDesktopSizeViewport = isDesktopSizeViewport;
    }
  }
});

export function selectIsDesktopSizeViewport(state: PartialAppState) {
  return state.viewport.isDesktopSizeViewport;
}

export const { updateViewportSize } = actions;

export default reducer;
