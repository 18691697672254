/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { useId } from "react-id-generator";
import classnames from "classnames/bind";
import styles from "./add-new-file.module.css";
import Plus from "components/icons/plus";
import Dropzone from "components/dropzone/dropzone";
import DropzoneMessage, { DropzoneMessageType, DropzoneMessageVariant } from "components/dropzone/dropzone-message";

const cx = classnames.bind(styles);

interface AddNewFilePlaceholderProps {
  isIcon: boolean;
  classname?: string;
  addNewText?: string;
}

interface AddNewFileProps {
  addNewFile: (files: File[]) => void;
  onError: (error: string) => void;
  accept: string;
  classnames?: string;
  addNewText?: string;
  id?: string;
  enableMultipleUploads?: boolean;
  isIcon?: boolean;
  disableDropzone?: boolean;
  showDropzoneOnDragAnywhere?: boolean;
  dropzoneMessage?: string;
}

const AddNewFilePlaceholder: React.FC<AddNewFilePlaceholderProps> = ({ classname, addNewText, isIcon }) => {
  const addNewPlaceholder = addNewText || t("components.add-new-file.add-new");
  return (
    <div className={cx("add-new-file", classname)} data-test-id="add-new-file-btn">
      {isIcon && (
        <span className={styles["plus-icon"]}>
          <Plus />
        </span>
      )}
      <span className={styles["add-new-text"]}>{addNewPlaceholder}</span>
    </div>
  );
};

export const AddNewFile: React.FC<AddNewFileProps> = ({
  classnames,
  addNewText,
  accept,
  dropzoneMessage,
  disableDropzone = false,
  showDropzoneOnDragAnywhere = true,
  isIcon = true,
  enableMultipleUploads = false,
  addNewFile,
  onError
}) => {
  const onChangeHandler = (e) => {
    e.target.files && addNewFile(Array.from(e.target.files));
    /*
    onchange does not detect changes when same file is uploaded again in media gallery.
    to enable for onchange to detect the change we are resetting current event target
    to empty object with below line
    */
    const { target = {} } = e || {};
    target.value = "";
  };

  const [inputId] = useId(1, "add-new-file-");
  return (
    <div className={styles["add-new-file-container"]}>
      <Dropzone
        dropHandler={addNewFile}
        accept={accept}
        onError={onError}
        enableMultipleUploads={enableMultipleUploads}
        disable={disableDropzone}
        showOnDragAnywhere={showDropzoneOnDragAnywhere}
        errorMapping={{
          fileType: t("components.dropzone.file-type-error"),
          fileCount: t("components.dropzone.file-count-error"),
          fileSize: t("components.dropzone.file-size-error")
        }}
        dragEnterMessage={
          <DropzoneMessage
            type={DropzoneMessageType.Image}
            variant={DropzoneMessageVariant.Small}
            message={dropzoneMessage || t("components.add-new-file.on-drag-message")}
          />
        }>
        {
          <input
            type="file"
            id={inputId}
            multiple={enableMultipleUploads}
            accept={accept}
            onChange={onChangeHandler}
            className={styles["file-input"]}
          />
        }
        <label htmlFor={inputId}>
          <AddNewFilePlaceholder addNewText={addNewText} classname={classnames} isIcon={isIcon} />
        </label>
      </Dropzone>
    </div>
  );
};

export default AddNewFile;
export { AddNewFilePlaceholder };
