/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export interface PromotionalMessages {
  description: null;
  slug: string;
  "default-config": DefaultConfig;
  disabled: boolean;
  "sort-order": number;
  config: Config;
  name: string;
  "default-value": boolean;
  "default-value-for-lite-publisher": boolean;
  "feature-id": number;
  "config-schema": null;
  toggle: boolean;
  "category-id": number;
  hidden: boolean;
  id: number;
  "category-name": string;
}

export interface Config {
  default: Default;
  sources: Sources;
}

export interface Default {
  text: string;
  toggle: boolean;
}

export interface Sources {
  ugc: Default;
  syndicated: Default;
}

export interface DefaultConfig {}

export const getPromotionalMessages = (): Promise<PromotionalMessages> => {
  return client
    .url(`/api/promotional-messages`)
    .get()
    .json();
};

export const savePromotionalMessages = (config: Config) => {
  return client
    .url(`/api/promotional-messages`)
    .json(config)
    .post()
    .res()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
};
