/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Checkbox from "components/checkbox/checkbox";
import styles from "./promotional-message.module.css";

import { t } from "i18n";

const PromotionalMessage = ({ promotionalMessage, onMetadataChange, disabled }) => {
  return (
    <React.Fragment>
      <Checkbox
        id="promotional-message"
        checked={promotionalMessage && promotionalMessage["is-disabled"]}
        label={t("story-editor.inspector.disable-promotional-message")}
        onChange={(checked) => onMetadataChange("promotional-message", { "is-disabled": checked })}
        disabled={disabled}
        classname={styles["promotional-message-checkbox"]}
      />
    </React.Fragment>
  );
};

export default PromotionalMessage;
