/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";

import { ContextMenu, ContextMenuList, ContextMenuItem } from "components/context-menu/context-menu";
import Chip from "components/chip/chip";
import { RoleTypes } from "api/roles";
import { PartialAppState } from "../state";
import { deleteRole } from "../async-action-creators";
import { ROLES_EDIT_PATH } from "../routes";
import { navigate } from "utils/routes.utils";
import { actionCategories } from "../action-categories";
import styles from "./role.module.css";

interface DispatchProps {
  deleteRole: (id: number) => void;
  editRole: (roleId: number) => void;
}

type Props = DispatchProps & {
  role: RoleTypes;
};

class Role extends React.Component<Props> {
  render() {
    const { role, deleteRole, editRole } = this.props;
    return (
      <article className={styles["role-card"]} data-test-id="role-card" key={`role-card-${role.id}`}>
        <div className={styles["role-name"]}>
          {role.name}
          <div id={`role-menu-${role.id}`} className={styles["user-role-more-actions"]}>
            <ContextMenu>
              <div id={`role-menu-${role.id}`} className={styles["roles-action-dropdown"]}>
                <ContextMenuList>
                  <ContextMenuItem
                    label={t("users-and-roles.remove_role")}
                    isDanger
                    onClick={() => deleteRole(role.id)}
                  />
                  <ContextMenuItem label={t("users-and-roles.edit_role")} onClick={() => editRole(role.id)} />
                </ContextMenuList>
              </div>
            </ContextMenu>
          </div>
        </div>
        {actionCategories.map((category, index) => (
          <div key={`category-${index}`} className={styles["category-container"]}>
            {role["action-categories"][category.value].length > 0 && (
              <React.Fragment>
                <div className={styles["category-name"]}>{category.label}</div>
                <div className={styles["role-actions"]}>
                  {role["action-categories"][category.value].map((action: string, index: number) => (
                    <Chip key={`action-${index}`} value={action} classname={"roles-chip"} />
                  ))}
                </div>
              </React.Fragment>
            )}
          </div>
        ))}
      </article>
    );
  }
}

const mapStateToProps = (state: PartialAppState) => ({});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchProps => {
  return {
    deleteRole: (roleId) => dispatch(deleteRole(roleId)),
    editRole: (roleId) => dispatch(navigate(ROLES_EDIT_PATH, { id: roleId }))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Role);

export { Role };
