/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import styles from "./realtime-members.module.css";
import { t } from "i18n";
import ButtonWithIcon from "components/button/button-with-icon";
import StopEditing from "components/icons/stop-editing";
import EditNow from "components/icons/edit-now";
import { Button } from "@quintype/em/components/button";
import { LockStoryUiState } from "pages/story-editor/lock-story-for-edit";

type Props = {
  isDesktopSizeViewport: boolean;
  toggleStoryEdit: (toggle: boolean) => void;
  uiState: LockStoryUiState;
};

const RealtimeMembers: React.FC<Props> = ({ isDesktopSizeViewport, toggleStoryEdit, uiState }) => {
  const iconFill = !uiState.buttonEnabled ? "var(--mono-3)" : undefined;
  return (
    <div className={styles["realtime-members"]} data-for="toggle-state" data-tip data-test-id="realtime-members">
      <span className={styles["edit-message"]} data-test-id="edit-message">
        {uiState.message}
      </span>
      {uiState.amIEditing &&
        (isDesktopSizeViewport ? (
          <ButtonWithIcon
            onClick={() => toggleStoryEdit(!uiState.switchToggled)}
            classname="realtime-members-button"
            testId="stop-edit-button"
            icon={StopEditing}
            dir="left"
            disabled={!uiState.buttonEnabled}
            fill={iconFill}>
            {t("story-editor.stop-editing")}
          </ButtonWithIcon>
        ) : (
          <Button
            testId="stop-edit-button"
            onClick={() => toggleStoryEdit(!uiState.switchToggled)}
            classname="realtime-members-button"
            disabled={!uiState.buttonEnabled}>
            <StopEditing />
          </Button>
        ))}

      {!uiState.amIEditing &&
        uiState.isSomeoneEditing &&
        (isDesktopSizeViewport ? (
          <ButtonWithIcon
            testId="edit-button"
            onClick={() => toggleStoryEdit(!uiState.switchToggled)}
            disabled={!uiState.buttonEnabled}
            classname="realtime-members-button"
            icon={EditNow}
            dir="left"
            fill={iconFill}>
            {t("story-editor.edit-now")}
          </ButtonWithIcon>
        ) : (
          <Button
            testId="edit-button"
            onClick={() => toggleStoryEdit(!uiState.switchToggled)}
            disabled={!uiState.buttonEnabled}
            classname="realtime-members-button">
            <EditNow fill={iconFill} />
          </Button>
        ))}
    </div>
  );
};

export default RealtimeMembers;

export { RealtimeMembers };
