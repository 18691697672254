/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { Route, Switch } from "react-router";
import { FORMS_EDITOR_PATH_EDIT, FORMS_PATH, FORMS_EDITOR_PATH_MANAGE } from "./routes";
import FormEditor from "./editor/editor";
import FormsDashboard from "./dashboard/dashboard";

export const Router = () => (
  <React.Fragment>
    <Switch>
      <Route exact path={FORMS_PATH} component={FormsDashboard} />
      <Route exact path={FORMS_EDITOR_PATH_EDIT} component={FormEditor} />
      <Route exact path={FORMS_EDITOR_PATH_MANAGE} component={FormEditor} />
    </Switch>
  </React.Fragment>
);
