/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { t } from "i18n";
import { Image } from "api/search-media-image";
import { SavedImage } from "../types";
import { CoverImage } from "api/story-collection";
import { ImageError } from "pages/media-library/state";
import RichTextField from "components/rich-text-field/rich-text-field";
import TextArea from "components/text-area/text-area";

interface ImageDetailsProps {
  image: Image | SavedImage | CoverImage;
  updateImage: (image: Image | SavedImage | CoverImage) => void;
  imageUploadFailed?: boolean;
  error?: ImageError;
  isStoryImageHyperlinkEnabled?: boolean;
  fieldSpecs?: any;
}

const ImageDetails: React.SFC<ImageDetailsProps> = ({
  image,
  updateImage,
  imageUploadFailed,
  error,
  isStoryImageHyperlinkEnabled = false,
  fieldSpecs = {}
}) => {
  function updateImageDetails(key: string, value: string) {
    updateImage({ ...image, [key]: value });
  }

  return (
    <React.Fragment>
      {!imageUploadFailed && (
        <React.Fragment>
          <RichTextField
            onChange={(value) => updateImageDetails("caption", value)}
            value={image.caption || ""}
            label={t("mediaLibrary.caption")}
            errorMessage={error && error.caption ? t("mediaLibrary.missing_caption") : ""}
          />
          <RichTextField
            value={image.attribution || ""}
            label={t("mediaLibrary.attribution")}
            onChange={(value) => updateImageDetails("attribution", value)}
            errorMessage={error && error.attribution ? t("mediaLibrary.missing_caption") : ""}
          />
          {!(fieldSpecs["image-alt-text"] && fieldSpecs["image-alt-text"].validations.hidden) && (
            <TextArea
              value={image["alt-text"] || ""}
              label={t("mediaLibrary.alt-text")}
              onChange={(value) => updateImageDetails("alt-text", value)}
              errorMessage={error && error["alt-text"] ? t("mediaLibrary.missing_caption") : ""}
            />
          )}
          {isStoryImageHyperlinkEnabled && (
            <TextArea
              value={image.hyperlink || ""}
              label={t("mediaLibrary.hyperlink")}
              onChange={(value) => updateImageDetails("hyperlink", value)}
              errorMessage={error && error.hyperlink ? t("mediaLibrary.invalid_hyperlink") : ""}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ImageDetails;
