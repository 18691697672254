/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RouteComponentProps } from "react-router";

import { ContributorRole } from "api/contributor-roles";
import {
  cancelDeleteContributorRole,
  deleteContributorRole,
  loadContributorRoles,
  updateCurrentContributorRole,
  maybeDeleteContributorRole,
  maybeEditContributorRole,
  maybeAddContributorRole
} from "./async-action-creators";
import { PartialAppState, AnyContributorRole } from "pages/settings/pages/configure/contributor-roles/state";
import { t } from "i18n";

import { navigate } from "utils/routes.utils";
import {
  NEW_CONTRIBUTOR_ROLES_PATH,
  CONFIGURE_CONTRIBUTOR_ROLES_PATH,
  EDIT_CONTRIBUTOR_ROLES_PATH
} from "pages/settings/routes";
import ContentHeader from "pages/settings/components/content-header/content-header";
import DeleteConfirmationModal from "components/confirmation-modal/confirmation-modal";
import Inspector from "./inspector";
import wrapPage from "containers/page/page";
import Trash from "components/icons/trash";
import { ContributorRoleId } from "api/primitive-types";

import "./contributor-roles.css";
import Spinner from "components/spinner/spinner";

interface StateProps {
  allContributorRoles: ContributorRole[];
  currentContributorRole: AnyContributorRole;
  showDeleteModal: boolean;
}

interface DispatchProps {
  maybeAddContributorRole: (shouldLoadList: boolean) => void;
  cancelDeleteContributorRole: () => void;
  deleteContributorRole: (contributorRole: AnyContributorRole) => void;
  loadContributorRoles: () => void;
  addContributorRole: () => void;
  updateCurrentContributorRole: (contributorRole: AnyContributorRole) => void;
  maybeEditContributorRole: (contributorRoleId: ContributorRoleId, shouldLoadList: boolean) => void;
  loadEditContributorRoleInspector: (contributorRole: ContributorRole) => void;
  maybeDeleteContributorRole: (contributorRole: ContributorRole) => void;
}

type ContributorRolesProps = RouteComponentProps<any> &
  DispatchProps &
  StateProps & {
    showInspector: boolean;
    isBannerPresent: boolean;
  };

const isSystemRole = (role: ContributorRole) => {
  return role.type === "system";
};

export class ContributorRoles extends React.Component<ContributorRolesProps> {
  componentDidMount() {
    this.onEnter();
  }

  componentDidUpdate(prevProps: ContributorRolesProps) {
    if (this.props.match.url !== prevProps.match.url) {
      this.onEnter(prevProps);
    }
  }

  onEnter(prevProps: ContributorRolesProps | null = null) {
    switch (this.props.match.path) {
      case CONFIGURE_CONTRIBUTOR_ROLES_PATH:
        this.props.loadContributorRoles();
        break;

      case NEW_CONTRIBUTOR_ROLES_PATH:
        this.props.maybeAddContributorRole(!prevProps);
        break;

      case EDIT_CONTRIBUTOR_ROLES_PATH:
        this.props.maybeEditContributorRole(this.props.match.params.id, !prevProps);
        break;

      default:
        break;
    }
  }

  render() {
    const {
      addContributorRole,
      cancelDeleteContributorRole,
      deleteContributorRole,
      showInspector,
      showDeleteModal,
      allContributorRoles,
      currentContributorRole,
      loadEditContributorRoleInspector,
      maybeDeleteContributorRole
    } = this.props;

    return (
      <React.Fragment>
        <main className="page-container contributor-roles">
          <ContentHeader
            title={t("settings.contributor-roles.page-title")}
            actionButtonLabel={t("settings.contributor-roles.add-button-label")}
            handleActionButtonClick={addContributorRole}
          />
          <div className="contributor-roles-container">
            <div className="contributor-roles-row contributor-roles-header">
              <span>{t("settings.contributor-roles.name-header")}</span>
            </div>
            {allContributorRoles.length > 0 ? (
              allContributorRoles.map((contributorRole: ContributorRole, index: number) => (
                <div className="contributor-roles-row" key={`contributor-role-${index}`}>
                  <span
                    className="contributor-role-edit-action"
                    data-test-id="contributor-role-edit-action"
                    onClick={() => {
                      !isSystemRole(contributorRole) && loadEditContributorRoleInspector(contributorRole);
                    }}>
                    {contributorRole.name}
                  </span>
                  {!isSystemRole(contributorRole) && (
                    <span
                      className="contributor-role-action"
                      data-test-id="contributor-role-action"
                      onClick={() => {
                        maybeDeleteContributorRole(contributorRole);
                      }}>
                      <Trash color="var(--mono-4)" />
                    </span>
                  )}
                </div>
              ))
            ) : (
              <Spinner />
            )}
          </div>
          <Inspector isActive={showInspector} currentContributorRole={currentContributorRole} />
        </main>
        <DeleteConfirmationModal
          showConfirmationModal={showDeleteModal}
          headerText={t("settings.contributor-roles.delete-modal.title")}
          text={t("settings.contributor-roles.delete-modal.info")}
          confirmButtonLabel={t("settings.contributor-roles.delete-modal.confirm-button-label")}
          cancelButtonLabel={t("settings.contributor-roles.delete-modal.cancel-button-label")}
          onConfirm={() => deleteContributorRole(currentContributorRole)}
          onCancel={cancelDeleteContributorRole}
          onClose={cancelDeleteContributorRole}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState) => {
  return {
    allContributorRoles: state.contributorRoles.allContributorRoles,
    currentContributorRole: state.contributorRoles.currentContributorRole,
    showDeleteModal: state.contributorRoles.ui.showDeleteModal
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    loadContributorRoles: () => {
      dispatch(loadContributorRoles());
    },
    maybeAddContributorRole: (shouldLoadList) => {
      dispatch(maybeAddContributorRole(shouldLoadList));
    },
    addContributorRole: () => {
      dispatch(navigate(NEW_CONTRIBUTOR_ROLES_PATH));
    },
    loadEditContributorRoleInspector: (contributorRole) => {
      dispatch(navigate(EDIT_CONTRIBUTOR_ROLES_PATH, { id: contributorRole.id }));
    },
    updateCurrentContributorRole: (contributorRole) => {
      dispatch(updateCurrentContributorRole(contributorRole));
    },
    maybeEditContributorRole: (contributorRoleId, shouldLoadList) => {
      dispatch(maybeEditContributorRole(contributorRoleId, shouldLoadList));
    },
    maybeDeleteContributorRole: (contributorRole) => {
      dispatch(maybeDeleteContributorRole(contributorRole));
    },
    cancelDeleteContributorRole: () => {
      dispatch(cancelDeleteContributorRole());
    },
    deleteContributorRole: (contributorRole) => {
      dispatch(deleteContributorRole(contributorRole as ContributorRole));
    }
  };
};

export default compose<any>(connect(mapStateToProps, mapDispatchToProps), wrapPage())(ContributorRoles);
