/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import styles from "./banner.module.css";
import Lock from "components/icons/lock";

interface Props {
  content: string;
}

const EmbargoBanner: React.SFC<Props> = ({ content }) => {
  return (
    <div className={styles["banner"]} data-test-id="story-embargo-banner">
      <Lock width={18} height={18} />
      <div className={styles["content"]}>{content}</div>
    </div>
  );
};

export default EmbargoBanner;
