/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Task, Image, MyTasks } from "api/tasks";
import { ImageError } from "./state";
enum actions {
  CLOSE_TASK = "@@tasks/CLOSE_TASK",
  CLOSE_TASK_SUCCESS = "@@tasks/CLOSE_TASK_SUCCESS",
  CLOSE_TASK_FAILURE = "@@tasks/CLOSE_TASK_FAILURE",
  CANCEL_LOAD_ALL_TASKS = "@@tasks/CANCEL_LOAD_ALL_TASKS",
  LOAD_TASK = "@@tasks/LOAD_TASK",
  LOAD_ALL_TASKS = "@@tasks/LOAD_ALL_TASKS",
  LOAD_TASK_SUCCESS = "@@tasks/LOAD_TASK_SUCCESS",
  LOAD_ALL_TASKS_SUCCESS = "@@tasks/LOAD_ALL_TASKS_SUCCESS",
  LOAD_ALL_TASKS_FAILURE = "@@tasks/LOAD_ALL_TASKS_FAILURE",
  LOAD_TASK_FAILURE = "@@tasks/LOAD_TASK_FAILURE",
  SET_TASK_IMAGE = "@@tasks/SET_TASK_IMAGE",
  SHOW_INSPECTOR = "@@tasks/SHOW_INSPECTOR",
  DELETE_TASK_IMAGE = "@@tasks/DELETE_TASK_IMAGE",
  UPDATE_IMAGE_DATA = "@@tasks/UPDATE_IMAGE_DATA",
  UPDATE_IMAGE_UPLOAD_STATUS = "@@tasks/UPDATE_IMAGE_UPLOAD_STATUS",
  SAVE_IMAGE_VALIDATION_ERRORS = "@@tasks/SAVE_IMAGE_VALIDATION_ERRORS",
  TASK_MANAGER_LOGIN = "@@tasks/TASK_MANAGER_LOGIN",
  CLEAR_SELECTED_MEDIA = "@@tasks/CLEAR_SELECTED_MEDIA"
}

export default actions;

export type LoadTaskAction = {
  type: actions.LOAD_TASK;
};

export type LoadAllTasksAction = {
  type: actions.LOAD_ALL_TASKS;
};

export type CancelLoadAllTasksAction = {
  type: actions.CANCEL_LOAD_ALL_TASKS;
};

export type TaskManagerLoginAction = {
  type: actions.TASK_MANAGER_LOGIN;
};

export type CloseTaskAction = {
  type: actions.CLOSE_TASK;
};

export type CloseTaskSuccessAction = {
  type: actions.CLOSE_TASK_SUCCESS;
  payload: Task;
};

export type CloseTaskFailureAction = {
  type: actions.CLOSE_TASK_FAILURE;
};

export type LoadTaskSuccessAction = {
  type: actions.LOAD_TASK_SUCCESS;
  payload: Task;
};

export type LoadAllTasksSuccessAction = {
  type: actions.LOAD_ALL_TASKS_SUCCESS;
  payload: MyTasks;
};

export type LoadAllTasksFailureAction = {
  type: actions.LOAD_ALL_TASKS_FAILURE;
  payload: Error;
};

export type SetTaskImageAction = {
  type: actions.SET_TASK_IMAGE;
  payload: Image[];
};

export type ShowInspectorAction = {
  type: actions.SHOW_INSPECTOR;
  payload: boolean;
};

export type DeleteTaskImageAction = {
  type: actions.DELETE_TASK_IMAGE;
};

export type UpdateImageUploadStatusAction = {
  type: actions.UPDATE_IMAGE_UPLOAD_STATUS;
  payload: boolean;
};
export type UpdateImageDataAction = {
  type: actions.UPDATE_IMAGE_DATA;
  payload: {
    key: String;
    value: string | number | null;
    imageIndex: number;
  };
};

export type SaveImageValidationErrorsAction = {
  type: actions.SAVE_IMAGE_VALIDATION_ERRORS;
  payload: ImageError[];
};

export type ClearSelectedMediaAction = {
  type: actions.CLEAR_SELECTED_MEDIA;
};

export type ActionTypes =
  | LoadTaskAction
  | LoadTaskSuccessAction
  | LoadAllTasksAction
  | CancelLoadAllTasksAction
  | LoadAllTasksSuccessAction
  | LoadAllTasksFailureAction
  | CloseTaskAction
  | CloseTaskSuccessAction
  | CloseTaskFailureAction
  | SetTaskImageAction
  | ShowInspectorAction
  | DeleteTaskImageAction
  | UpdateImageDataAction
  | UpdateImageUploadStatusAction
  | SaveImageValidationErrorsAction
  | TaskManagerLoginAction
  | ClearSelectedMediaAction;
