/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Clock = ({ width = "16", height = "16", color = "#5F6978" }) => (
  <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill={color}>
    <path d="M12.18 3.22a8.52 8.52 0 0 1 4.36 1.18 8.81 8.81 0 0 1 3.18 3.18 8.42 8.42 0 0 1 1.18 4.36 8.4 8.4 0 0 1-1.18 4.36 8.66 8.66 0 0 1-3.18 3.18 8.64 8.64 0 0 1-8.72 0 8.74 8.74 0 0 1-3.18-3.18 8.4 8.4 0 0 1-1.18-4.36 8.42 8.42 0 0 1 1.18-4.36A8.89 8.89 0 0 1 7.82 4.4a8.52 8.52 0 0 1 4.36-1.18zm7.59 8.72a7.4 7.4 0 0 0-1-3.81 7.61 7.61 0 0 0-6.59-3.78 7.35 7.35 0 0 0-3.79 1A7.64 7.64 0 0 0 4.59 12a7.39 7.39 0 0 0 1 3.8 7.61 7.61 0 0 0 6.59 3.78 7.39 7.39 0 0 0 3.8-1A7.66 7.66 0 0 0 19.75 12zM14.54 15a.44.44 0 0 0 .31.09.42.42 0 0 0 .28-.16l.29-.42a.43.43 0 0 0 .08-.3.35.35 0 0 0-.16-.27l-2.49-1.83V7a.41.41 0 0 0-.12-.3.44.44 0 0 0-.3-.12h-.5a.4.4 0 0 0-.29.12.38.38 0 0 0-.13.3v5.63a.33.33 0 0 0 .18.31z" />
  </svg>
);

export default Clock;
