/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Styles } from "react-select/lib/styles";
import { CSSProperties } from "react";

const appStyle = getComputedStyle(document.body);

const defaultStyles: Partial<Styles> = {
  control: (styles: CSSProperties, state) => {
    const controlStyles = {
      ...styles,
      fontSize: appStyle.getPropertyValue("--fs-s"),
      borderRadius: "0.3rem",
      backgroundColor: appStyle.getPropertyValue("--white"),
      border: `solid 1px ${appStyle.getPropertyValue("--mono-3")}`,
      minHeight: "3.3rem"
    };
    return state.isDisabled ? { ...controlStyles, background: appStyle.getPropertyValue("--mono-2") } : controlStyles;
  },
  placeholder: (styles: CSSProperties) => {
    return {
      ...styles,
      fontSize: appStyle.getPropertyValue("--fs-s"),
      fontFamily: appStyle.getPropertyValue("--brandType"),
      fontWeight: "normal",
      lineHeight: appStyle.getPropertyValue("--fs-s"),
      color: appStyle.getPropertyValue("--mono-5")
    };
  },
  dropdownIndicator: (styles: CSSProperties) => {
    return {
      ...styles,
      padding: "0.6rem 0.8rem"
    };
  },
  menu: (styles: CSSProperties) => {
    return {
      ...styles,
      fontSize: appStyle.getPropertyValue("--fs-s"),
      backgroundColor: appStyle.getPropertyValue("--white")
    };
  },
  multiValue: (base: CSSProperties, state: any) => {
    return state.data.isFixed ? { ...base, backgroundColor: "#e5e7ed", borderRadius: "0.3rem" } : base;
  },
  multiValueLabel: (base: CSSProperties, state: any) => {
    return state.data.isFixed ? { ...base, fontSize: "1rem", color: "#5f6978" } : base;
  },
  singleValue: (base: CSSProperties) => {
    return { ...base, overflow: "visible" };
  }
};

export default defaultStyles;
