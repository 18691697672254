/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { combineReducers } from "redux";

import usersReducer from "./users/users-reducer";
import rolesReducer from "./roles/roles-reducer";

export default combineReducers({ usersPage: usersReducer, rolesPage: rolesReducer });
