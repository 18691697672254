/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Quintype = () => (
  <svg id="Layer_1" width="48" height="48" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
    <defs />
    <path
      fill="#fff"
      d="M23.58,11.75a18.09,18.09,0,0,1,0,17.89Zm0,18.79a10.79,10.79,0,0,1-4.34.15c-2.62-.38-4.06-3.6-4.06-3.6l6.21-1.72v-16l2.19,2.23ZM14,20.91c0-3.16.22-7.12.22-7.12l2.18-.85V26.79L15,27.25A25.11,25.11,0,0,1,14,20.91Zm17.74,9.62-1.35,1.71a4.44,4.44,0,0,0-5.26-2s7-4.58,6.27-12.4A17,17,0,0,0,24.74,5.8c-.38-.24-5.52,5.64-10.84,7.14,0,0,.24-5.55,4.24-6V6.18S8,7.66,8,20.11c0,0,.39,10.85,10.79,11.31l4,.18s3.41-.18,4.1,4.75l.52.44,4.55-6Z"
    />
  </svg>
);
export default Quintype;
