/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import {
  CollectionId,
  Timestamp,
  PublisherId,
  StoryId,
  MemberId,
  SectionId,
  TagId,
  CommaSeparatedMemberIds,
  CommaSeparatedTagIds,
  CommaSeparatedSectionIds
} from "./primitive-types";
import { Author } from "./author";
import { EntityInCollection } from "./entity";
import { ImageMetadata } from "./search-media-image";

export enum CollectionType {
  Content = "content"
}

export enum ContentType {
  DraftStory = "draft-story",
  Story = "story",
  Collection = "collection",
  BreakingNews = "breaking-news"
}

export enum DataSource {
  Manual = "manual",
  Automated = "automated",
  Recommended = "recommended"
}

export enum RulesSortBy {
  UpdatedAt = "updated-at",
  PublishedAt = "published-at",
  CreatedAt = "created-at:desc"
}

export enum ExpandedRulesContentType {
  DraftStory = "draft-story",
  Story = "story",
  Collection = "collection"
}

export enum RulesSortAutomatedResultBy {
  LatestPublished = "latest-published",
  Trending = "trending"
}

interface Snapshot {
  body: string;
}

interface MetadataTag {
  id?: TagId;
  name: string;
  "meta-description"?: string | null;
}

export interface CollectionEntities {
  [key: string]: { [key: string]: EntityInCollection[] };
}

export interface Metadata {
  "cover-image"?: CoverImage | null;
  section?: MetadataSection[];
  snapshot?: Snapshot;
  tags?: MetadataTag[];
  entities?: CollectionEntities;
  "pdf-src-url"?: string;
  "temp-pdf-s3-key"?: string;
  "pdf-upload-timestamp"?: string;
}

interface MetadataSection {
  id: SectionId;
  name: string;
}

interface Rules {
  "section-id"?: CommaSeparatedSectionIds;
  sort?: RulesSortBy;
  "author-id"?: CommaSeparatedMemberIds;
  "collection-id"?: CollectionId;
  "content-type"?: ContentType.Story;
  fields?: string;
  "sort-automated-result"?: RulesSortAutomatedResultBy;
  "story-template"?: string;
  "tag-id"?: CommaSeparatedTagIds;
  "breaking-news-type"?: string;
  location?: {
    "is-enabled": boolean;
    geoPolygon: Array<google.maps.LatLngLiteral>;
  };
}

interface ExpandedRulesAuthor {
  id: MemberId;
  name: string;
}

interface ExpandedRulesTag {
  id: TagId;
  "meta-description": string | null;
  name: string;
  slug: string;
}

export interface ExpandedRules {
  "author-id": CommaSeparatedMemberIds;
  authors: ExpandedRulesAuthor[];
  "content-type"?: ExpandedRulesContentType;
  "collection-id"?: CollectionId;
  fields?: string;
  sort?: RulesSortBy;
  sections?: ExpandedRulesSection[];
  "sort-automated-result"?: RulesSortAutomatedResultBy;
  "story-template": string[];
  tags?: ExpandedRulesTag[];
  "story-attributes"?: { [key: string]: string[] };
}

interface ExpandedRulesSection {
  id: SectionId;
  name: string;
  "publisher-id": PublisherId;
  "updated-at": Timestamp;
  "deleted-at": Timestamp | null;
  slug: string;
  "display-name": string;
  "parent-id": SectionId | null;
  "collection-id": CollectionId;
}

export type AssociatedMetadata = { [key: string]: number | string | boolean };

interface CoverImageMetadata {
  width: number;
  height: number;
}

export interface CoverImage {
  "cover-image-url": string;
  "cover-image-metadata": CoverImageMetadata;
  "cover-image-s3-key": string;
  caption?: null;
  metadata?: ImageMetadata;
  attribution?: null;
  hyperlink?: null;
  "alt-text"?: string | null;
}

export interface CollectionInsideItem {
  id: CollectionId;
  name: string;
  template: string;
}

export interface StoryInsideItem {
  id: StoryId;
  headline: string;
  authors: Author[];
  "author-id": MemberId;
  "author-name": string;
  status?: string;
  "publish-at"?: string;
  metadata?: {
    "story-attributes": {
      syndicatedfrom: string;
    };
  };
}

export interface CollectionWrapper {
  "associated-metadata": AssociatedMetadata | null;
  collection: CollectionInsideItem;
  id: CollectionId;
  type: ContentType.Collection;
}

export interface StoryWrapper {
  "disabled-at": Timestamp | null;
  id: StoryId;
  threshold: number | null;
  type: ContentType.Story | ContentType.BreakingNews;
  story: StoryInsideItem;
  "associated-metadata": AssociatedMetadata | null;
}

export type CollectionItemWrapper = StoryWrapper | CollectionWrapper;

export interface Value {
  label: [string];
}

interface CommonCollectionType {
  id: CollectionId;
  "publisher-id": PublisherId;

  name: string;
  slug: string;
  type: CollectionType;
  status: string | null;
  "publish-at": Timestamp | null;

  "story-content-ids": Array<StoryId | CollectionId>;
  items: CollectionItemWrapper[];
  metadata: Metadata;
  template: string;

  summary: string | null;
  "story-count": number;
  "total-count": number;
  "price-amount": number | null;
  "price-currency": string | null;
  "author-id": MemberId | null;
  "collection-date": Timestamp | null;
  access: null;

  "published-at": Timestamp | null;
  "updated-at": Timestamp | null;
  "created-at": Timestamp | null;
  "deleted-at": Timestamp | null;
}

export type ManualCollection = CommonCollectionType & {
  "data-source": DataSource.Manual;
  rules?: Rules;
};

export type AutomatedCollection = CommonCollectionType & {
  automated: true;
  "data-source": DataSource.Automated;
  rules: Rules;
  "expanded-rules": ExpandedRules;
};

type RecommendedCollection = CommonCollectionType & {
  "data-source": DataSource.Recommended;
};

type UnsavedCollectionKeys =
  | "id"
  | "publisher-id"
  | "created-at"
  | "updated-at"
  | "deleted-at"
  | "published-at"
  | "story-count"
  | "total-count";

export type UnsavedManualCollection = Omit<ManualCollection, UnsavedCollectionKeys>;
export type UnsavedAutomatedCollectionWithRules = Omit<
  AutomatedCollection,
  | UnsavedCollectionKeys
  | "status"
  | "publish-at"
  | "price-amount"
  | "price-currency"
  | "collection-date"
  | "access"
  | "automated"
>;
export type UnsavedAutomatedCollection = Omit<AutomatedCollection, UnsavedCollectionKeys | "expanded-rules">;
export type UnsavedRecommendedCollection = Omit<RecommendedCollection, UnsavedCollectionKeys>;

export type UnsavedCollection = UnsavedManualCollection | UnsavedAutomatedCollection | UnsavedRecommendedCollection;
export type Collection = ManualCollection | AutomatedCollection | RecommendedCollection;

export type AnyManualCollection = ManualCollection | UnsavedManualCollection;
export type AnyAutomatedCollection = AutomatedCollection | UnsavedAutomatedCollection;
export type AnyRecommendedCollection = RecommendedCollection | UnsavedRecommendedCollection;
export type AnyCollection = Collection | UnsavedCollection;

export const fetchCollection = (id: CollectionId): Promise<Collection> => {
  return client
    .url(`/api/story-collection/${id}`)
    .get()
    .json();
};

export const updateExistingCollection = (id: CollectionId, collection: Collection): Promise<Collection> => {
  return client
    .url(`/api/story-collection/${id}`)
    .json(collection)
    .patch()
    .json();
};

export const saveNewCollection = (collection: UnsavedCollection): Promise<Collection> => {
  return client
    .url("/api/story-collection")
    .json(collection)
    .post()
    .json();
};

export const deleteCollection = (collectionId: CollectionId): Promise<void> => {
  return client
    .url(`/api/story-collection/${collectionId}`)
    .json({ type: "content", command: "delete" })
    .put()
    .res();
};

export const uploadFile = (file: File, encryptionKey: string): Promise<String> => {
  const formData = new FormData();
  formData.append("file", file, file.name);
  formData.append("encrypt-key", encryptionKey);
  return client
    .url("/api/file-upload")
    .body(formData)
    .post()
    .json();
};
