/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const SocialMediaURL = ({ width = 24, height = 24, fill = "#c6ccd5" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 18.91 20.36"
    width={width}
    height={height}
    fill={fill}>
    <defs />
    <path
      d="M955.74 261.26a3.21 3.21 0 0 1-4.43.39 3 3 0 0 1-1.11-2.07 3 3 0 0 1 .71-2.24l4.36-5.11a3.22 3.22 0 0 1 4.44-.38l.77.58.72-.92-.74-.56a4.41 4.41 0 0 0-6.1.54L950 256.6a4.07 4.07 0 0 0-1 3.08 4.14 4.14 0 0 0 1.53 2.86 4.41 4.41 0 0 0 6.1-.54l2.64-3.09-.91-.74z"
      transform="translate(-949 -243.16)"
    />
    <path
      d="M966.35 244.13a4.41 4.41 0 0 0-6.09.54l-2.78 3.14.9.76 2.79-3.15a3.2 3.2 0 0 1 4.42-.4 3 3 0 0 1 1.12 2.08 3 3 0 0 1-.71 2.24l-4.36 5.1a3.2 3.2 0 0 1-4.41.41l-.44-.38-.79.86.45.4a4.43 4.43 0 0 0 6.1-.54l4.36-5.11a4.08 4.08 0 0 0 1-3.08 4.14 4.14 0 0 0-1.54-2.87"
      transform="translate(-949 -243.16)"
    />
  </svg>
);

export default SocialMediaURL;
