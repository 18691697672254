/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Globe = ({ width = "24", height = "24", color = "#5f6978" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    className="public-icon"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    fill={color}>
    <path d="M7.33 11.88c0 .72 0 1.5.11 2.32H3.31a8.36 8.36 0 0 1 0-4.65h4.13c-.07.8-.11 1.58-.11 2.33zM9.4 3.24a11.92 11.92 0 0 0-1.81 5.15H3.67A9.25 9.25 0 0 1 6 5.18a9 9 0 0 1 3.4-1.94zM3.67 15.36h3.92a12.61 12.61 0 0 0 1.81 5.15A8.82 8.82 0 0 1 6 18.57a9.25 9.25 0 0 1-2.33-3.21zm4.82-3.48c0-.78 0-1.55.11-2.33h6.75a22.71 22.71 0 0 1 .11 2.33 22.65 22.65 0 0 1-.11 2.32H8.6c-.07-.78-.11-1.55-.11-2.32zm6.72-3.49H8.79a11.64 11.64 0 0 1 1.27-4c.58-1 1.22-1.52 1.92-1.52s1.34.5 1.92 1.52a12.53 12.53 0 0 1 1.31 4zm-6.42 7h6.38a11.86 11.86 0 0 1-1.27 4q-.9 1.49-1.9 1.49t-1.92-1.53a11.64 11.64 0 0 1-1.29-3.99zm11.5-7h-3.92a12.61 12.61 0 0 0-1.81-5.15A8.84 8.84 0 0 1 18 5.18a9.15 9.15 0 0 1 2.29 3.21zm-5.73 12.12a11.92 11.92 0 0 0 1.81-5.15h3.92A9.15 9.15 0 0 1 18 18.57a8.71 8.71 0 0 1-3.44 1.94zm6.09-11a8.36 8.36 0 0 1 0 4.65h-4.14a23.2 23.2 0 0 0 .11-2.32c0-.75 0-1.53-.11-2.33z" />
  </svg>
);

export default Globe;
