/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import Button from "components/button/button";
import wrapPage from "containers/page/page";
import {
  STATIC_PAGES_INDEX_PATH,
  STATIC_PAGES_EDIT_PATH,
  STATIC_PAGES_NEW_PATH,
  STATIC_PAGES_EDIT_MANAGE_PATH
} from "./routes";
import {
  loadStaticPagesAction,
  loadCurrentStaticPageAction,
  createNewStaticPageAction,
  deleteCurrentStaticPageAction
} from "./async-action-creators";
import StaticPagesList from "./components/static-pages-list/static-pages-list";
import StaticPagesHeader from "./components/header/header";
import ConfirmationModal from "components/confirmation-modal/confirmation-modal";

import styles from "./static-page.module.css";

import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import Loader from "./loader";
import { LoaderState } from "behaviors/loader/state";
import StaticPageForm from "./components/static-page-form/static-page-form";
import { navigate } from "utils/routes.utils";
import { closeDeleteConfirmation } from "./action-creators";
import { StaticPage } from "api/static-pages";
import { t } from "i18n";
import Controller from "components/icons/controller";
import ButtonWithIcon from "components/button/button-with-icon";

interface StaticPagesStateProps {
  mainLoader: LoaderState;
  showDeleteConfirmationModal: boolean;
  showStaticPagesList?: boolean;
  showCurrentStaticPage?: boolean;
  currentStaticPage: StaticPage;
  title: string;
  showCreateNew?: boolean;
  showManageBtn?: boolean;
}

interface StaticPagesDispatchProps {
  loadStaticPages: Function;
  loadCurrentStaticPage: Function;
  loadEmptyStaticPage: Function;
  switchToNewRoute: Function;
  deleteCurrentStaticPage: Function;
  closeConfirmationModal: Function;
  switchToManageRoute: (id) => void;
}

interface OwnProps {
  isBannerPresent: boolean;
  pageTitle?: string;
  isInspectorOpen?: boolean;
}

type StaticPagesProps = StaticPagesStateProps & StaticPagesDispatchProps & OwnProps;

const StaticPagesListWrapper = ({ mainLoader }: { mainLoader: LoaderState }) => (
  <LoaderWrapper className="static-page-progress-main-area" component={Loader} loader={mainLoader}>
    <StaticPagesList />
  </LoaderWrapper>
);

const CurrentStaticPageWrapper = ({
  mainLoader,
  isInspectorOpen
}: {
  mainLoader: LoaderState;
  isInspectorOpen?: boolean;
}) => (
  <LoaderWrapper className={"static-page-progress-main-area"} component={Loader} loader={mainLoader}>
    <StaticPageForm isInspectorOpen={isInspectorOpen} />
  </LoaderWrapper>
);

export const StaticPages: React.SFC<StaticPagesProps> = ({
  pageTitle,
  mainLoader,
  showStaticPagesList,
  showCurrentStaticPage,
  switchToNewRoute,
  deleteCurrentStaticPage,
  showDeleteConfirmationModal,
  closeConfirmationModal,
  showCreateNew,
  currentStaticPage,
  switchToManageRoute,
  isInspectorOpen,
  showManageBtn
}) => {
  return (
    <React.Fragment>
      <header className={styles["static-pages-header"]} data-test-id="static-pages-header">
        <h1 className={styles["static-pages-title"]} data-test-id="static-pages-title">
          {pageTitle}
        </h1>
        {showCreateNew && (
          <div className={styles["static-pages-action-button"]} data-test-id="static-pages-action-button">
            <Button type="secondary" onClick={() => switchToNewRoute()}>
              {t("staticPages.createNew")}
            </Button>
          </div>
        )}
        {showManageBtn && (
          <div className={styles["static-pages-action-button"]} data-test-id="static-pages-manage-button">
            <ButtonWithIcon
              testId="static-page-manage-btn"
              type="secondary"
              onClick={() => switchToManageRoute(currentStaticPage.id)}
              icon={Controller}
              dir="left">
              {t("common.manage")}
            </ButtonWithIcon>
          </div>
        )}
      </header>
      {showStaticPagesList && <StaticPagesListWrapper mainLoader={mainLoader} />}
      {showCurrentStaticPage && <CurrentStaticPageWrapper mainLoader={mainLoader} isInspectorOpen={isInspectorOpen} />}

      <ConfirmationModal
        showConfirmationModal={showDeleteConfirmationModal}
        headerText={t("staticPages.form.confirmation.text")}
        text={t("staticPages.form.confirmation.desc")}
        confirmButtonLabel={t("staticPages.form.confirmation.delete")}
        cancelButtonLabel={t("staticPages.form.confirmation.cancel")}
        onConfirm={() => deleteCurrentStaticPage()}
        onCancel={() => closeConfirmationModal()}
        onClose={() => closeConfirmationModal()}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: any): StaticPagesStateProps => {
  return {
    mainLoader: state.staticPages.ui.main,
    showDeleteConfirmationModal: state.staticPages.ui.showDeleteConfirmationModal,
    currentStaticPage: state.staticPages.app.currentStaticPage,
    title: "static_pages"
  };
};

const mapDispatchToProps = (dispatch: any): StaticPagesDispatchProps => {
  return {
    loadStaticPages: () => dispatch(loadStaticPagesAction()),
    loadCurrentStaticPage: (id: number) => dispatch(loadCurrentStaticPageAction(id)),
    loadEmptyStaticPage: () => dispatch(createNewStaticPageAction()),
    switchToNewRoute: () => dispatch(navigate(STATIC_PAGES_NEW_PATH)),
    deleteCurrentStaticPage: () => dispatch(deleteCurrentStaticPageAction()),
    closeConfirmationModal: () => dispatch(closeDeleteConfirmation()),
    switchToManageRoute: (id = "new") => dispatch(navigate(STATIC_PAGES_EDIT_MANAGE_PATH, { id }))
  };
};

interface StaticPageRouteProps {
  match: {
    url: string;
    path: string;
    params: any;
  };
}

type StaticPagesWithRoutesProps = StaticPagesStateProps &
  StaticPagesDispatchProps &
  StaticPageRouteProps & { isBannerPresent: boolean };

export class StaticPagesWithRoutes extends React.Component<StaticPagesWithRoutesProps> {
  async componentDidMount() {
    this.onEnter();
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.match.url !== prevProps.match.url) {
      this.onEnter();
    }
  }

  onEnter() {
    switch (this.props.match.path) {
      case STATIC_PAGES_INDEX_PATH:
        this.props.loadStaticPages();
        break;

      case STATIC_PAGES_EDIT_PATH:
      case STATIC_PAGES_EDIT_MANAGE_PATH:
        if (!this.props.currentStaticPage) {
          if (this.props.match.params.id === "new") {
            this.props.loadEmptyStaticPage();
          } else {
            this.props.loadCurrentStaticPage(this.props.match.params.id);
          }
        }
        break;
      default:
        break;
    }
  }

  render() {
    const isInspectorOpen = this.props.match.path === STATIC_PAGES_EDIT_MANAGE_PATH;
    switch (this.props.match.path) {
      case STATIC_PAGES_INDEX_PATH:
        return (
          <StaticPages
            {...this.props}
            showStaticPagesList={true}
            pageTitle={t("staticPages.title")}
            showCreateNew={true}
          />
        );
      case STATIC_PAGES_EDIT_PATH:
      case STATIC_PAGES_EDIT_MANAGE_PATH:
        const title =
          this.props.match.params.id === "new" ? t("staticPages.createANewPage") : t("staticPages.editPage");
        return (
          <StaticPages
            {...this.props}
            showCurrentStaticPage={true}
            pageTitle={title}
            showCreateNew={false}
            isInspectorOpen={isInspectorOpen}
            showManageBtn={true}
          />
        );
      default:
        return <StaticPages {...this.props} />;
    }
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  wrapPage({ HeaderComponent: StaticPagesHeader, isStoryPage: false })
)(StaticPagesWithRoutes);
