/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Tag, AnyTag, UnsavedTag } from "api/tag";
import { LoaderState, INITIAL_LOADER_STATE } from "behaviors/loader/state";

export interface PartialAppState {
  tags: State;
  features: {
    hasTagEditPermission: boolean;
    hasTagCreatePermission: boolean;
  };
}

export enum TagPageInspectorType {
  Create = "create",
  Edit = "edit"
}

export interface Pagination {
  "tags-count": number;
}

export type UITag = LoaderState & { storyCount: number | null };

export interface State {
  tags: Tag[];
  pagination: Pagination;
  app: {
    searchTerm: string;
    currentTag: AnyTag | null;
    error: Error | null;
  };
  ui: {
    inspector: TagPageInspectorType | null;
    main: LoaderState;
    save: LoaderState;
    tags: {
      [id: number]: UITag | undefined;
    }; // can't write [id: TagId] because TS1336UITag;
    specifiedPage: number;
  };
}

export const NEW_TAG: UnsavedTag = {
  name: "",
  slug: "",
  "meta-description": "",
  "meta-title": ""
};

export const INITIAL_TAGS_STATE: State = {
  tags: [],
  pagination: {
    "tags-count": 0
  },
  app: {
    searchTerm: "",
    currentTag: null,
    error: null
  },
  ui: {
    inspector: null,
    main: INITIAL_LOADER_STATE,
    save: { ...INITIAL_LOADER_STATE, loading: false },
    tags: {},
    specifiedPage: 1
  }
};
