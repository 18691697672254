/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import Button from "components/button/button";
import Header from "components/header/header";
import { SitemapState, SitemapConfig, saveSitemapConfig } from "store/sitemap";

interface StateProps {
  sitemap: SitemapConfig;
  isSitemapConfigModified: boolean;
}

interface DispatchProps {
  saveData(sitemapConfig: SitemapConfig): void;
}

type Props = StateProps & DispatchProps;

const mapStateToProps = (state: { sitemap: SitemapState }, ownProps: any): StateProps => {
  return {
    sitemap: state.sitemap.sitemap || {},
    isSitemapConfigModified: state.sitemap.isSitemapConfigModified
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, AnyAction>): DispatchProps => {
  return {
    saveData: (sitemapConfig) => dispatch(saveSitemapConfig(sitemapConfig))
  };
};

class SitemapHeader extends React.Component<Props> {
  render() {
    let { saveData, sitemap, isSitemapConfigModified } = this.props;

    return (
      <React.Fragment>
        <Header>
          {sitemap && isSitemapConfigModified && (
            <Button variant="success" type="primary" onClick={() => saveData(sitemap)}>
              {t("save")}
            </Button>
          )}
        </Header>
      </React.Fragment>
    );
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(SitemapHeader);

export { SitemapHeader };
