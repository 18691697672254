/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { useState, useEffect, useCallback } from "react";

function useCreateObserver(onLoad: IntersectionObserverCallback, opts: IntersectionObserverInit) {
  const [observer, setObserver] = useState<IntersectionObserver | null>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(onLoad, opts);

    setObserver(observer);
    return () => {
      observer && observer.disconnect();
    };
  }, [opts, onLoad]);
  return observer;
}

function useObserve<T extends Element>(observer: IntersectionObserver | null, ref: React.RefObject<T>) {
  useEffect(() => {
    const target = ref.current;
    observer && target && observer.observe(target);
    return () => {
      observer && target && observer.unobserve(target);
    };
  }, [observer, ref]);
}

export function useIntersectionObserver<T extends Element>(
  ref: React.RefObject<T>,
  onLoad: IntersectionObserverCallback,
  opts: IntersectionObserverInit
) {
  const observer = useCreateObserver(onLoad, opts);
  useObserve<T>(observer, ref);
}

export function useOnLoad(condition: boolean, onIntersecting: (entry: IntersectionObserverEntry) => void) {
  return useCallback(
    (entries: IntersectionObserverEntry[]) => {
      if (condition) {
        entries.forEach((entry: IntersectionObserverEntry) => {
          if (entry.isIntersecting) onIntersecting(entry);
        });
      }
    },
    [condition, onIntersecting]
  );
}
