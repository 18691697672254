/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Filter = () => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <path
        d="M21.583 5.571c.116 0 .214.047.295.141a.504.504 0 0 1 .122.342v.321c0 .134-.04.248-.122.342a.376.376 0 0 1-.295.14H7.556v1.607c0 .268-.081.496-.244.683a.753.753 0 0 1-.59.282H5.056a.753.753 0 0 1-.59-.282 1.009 1.009 0 0 1-.244-.683V6.857H2.417a.376.376 0 0 1-.295-.14A.504.504 0 0 1 2 6.375v-.321c0-.134.04-.248.122-.342a.376.376 0 0 1 .295-.14h1.805V3.963c0-.268.081-.495.243-.683A.753.753 0 0 1 5.055 3h1.667c.232 0 .428.094.59.281.163.188.244.415.244.683v1.607h14.027zM6.444 8.143V4.286h-1.11v3.857h1.11zm15.14 3.214c.115 0 .213.047.294.14a.504.504 0 0 1 .122.342v.322c0 .134-.04.247-.122.341a.376.376 0 0 1-.295.14h-2.916v1.608c0 .268-.081.496-.243.683a.753.753 0 0 1-.59.281h-1.667a.753.753 0 0 1-.59-.281 1.009 1.009 0 0 1-.244-.683v-1.607H2.417a.376.376 0 0 1-.295-.14A.504.504 0 0 1 2 12.16v-.322c0-.134.04-.247.122-.341a.376.376 0 0 1 .295-.14h12.916V9.75c0-.268.081-.496.243-.683a.753.753 0 0 1 .59-.281h1.667c.232 0 .429.093.59.281.163.187.244.415.244.683v1.607h2.916zm-4.028 2.572V10.07h-1.112v3.858h1.112zm4.027 3.214c.116 0 .214.047.295.14a.504.504 0 0 1 .122.342v.321c0 .134-.04.248-.122.342a.376.376 0 0 1-.295.14H12v1.608c0 .268-.081.495-.243.683a.753.753 0 0 1-.59.281H9.5a.753.753 0 0 1-.59-.281 1.009 1.009 0 0 1-.243-.683v-1.607h-6.25a.376.376 0 0 1-.295-.141.504.504 0 0 1-.122-.342v-.321c0-.134.04-.248.122-.342a.376.376 0 0 1 .295-.14h6.25v-1.607c0-.268.08-.496.243-.683a.753.753 0 0 1 .59-.282h1.667c.231 0 .428.094.59.282.162.187.243.415.243.683v1.607h9.583zM10.89 19.714v-3.857H9.778v3.857h1.11z"
        id="filter-a"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <mask id="filter-b" fill="#fff">
        <use xlinkHref="#filter-a" />
      </mask>
      <use fill="#5F6978" xlinkHref="#filter-a" />
      <g mask="url(#filter-b)" fill="#5F6978">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);

export default Filter;
