/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import AsyncSelect from "components/select/async";
import debounce from "p-debounce";
import { getErrorMessage } from "pages/story-editor/utils";
import { t } from "i18n";
import { Author, searchAuthors } from "api/author";
import { components } from "react-select";
const loadAuthorsData = debounce(searchAuthors, 250);

interface AuthorsProps {
  value: Author[] | null;
  label?: string | null;
  onChange: (authors: Author[]) => void;
  errors: { code: string; threshold?: number } | null;
  disabled?: boolean;
  isDisabled?: boolean;
}

const MultiValue = (props: any) => {
  const emailIndex = props.children.indexOf("[");
  const content = emailIndex === -1 ? props.children : props.children.substring(0, emailIndex);
  return <components.MultiValue {...props}>{content}...</components.MultiValue>;
};

const Authors: React.SFC<AuthorsProps> = ({ value, label, onChange, errors, disabled, isDisabled }) => (
  <AsyncSelect
    value={value}
    onChange={onChange}
    components={{ MultiValue }}
    getOptionLabel={(author) => author && `${author.name} ${author.email ? "[" + author.email + "]" : ""}`}
    getOptionValue={(author) => (author.id && author.id.toString()) || ""}
    loadOptions={loadAuthorsData}
    isMulti={true}
    label={label === null ? label : t("story-editor.inspector.authors")}
    defaultOptions={true}
    isDisabled={disabled || isDisabled}
    errorMessage={getErrorMessage(errors)}
    cacheOptions={null}
    hideError={true}
  />
);

export default Authors;
