/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect, useState } from "react";
import { Target } from "api/push-notification";
import { getChannels, PushNotificationChannel } from "api/push-notification-channels";
import RenderMultipleRow from "components/render-multiple-row/render-multiple-row";
import FieldLabel from "components/field-label/field-label";
import { t } from "i18n";
import PushNotificationChannelSelect from "pages/content/push-notification/components/push-notification-channel-select/push-notification-channel-select";
import PushNotificationPlatformSelect from "pages/content/push-notification/components/push-notification-platform-select/push-notification-platform-select";
import { AnyAction, compose } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "pages/content/push-notification/state";
import { notificationError } from "action-creators/notification";

interface DispatchProps {
  getChannels: () => Promise<PushNotificationChannel[]>;
}

export interface PushNotificationTargetGroupProps {
  targets: Target[];
  onUpdate: (targetGroups: Target[]) => void;
  disabled?: boolean;
  channelsEnabled?: boolean;
}

type Props = DispatchProps & PushNotificationTargetGroupProps;

const PushNotificationTargets: React.FC<Props> = ({ targets, channelsEnabled, onUpdate, disabled, getChannels }) => {
  const [channels, setChannels] = useState<PushNotificationChannel[]>([]);

  useEffect(() => {
    if (channelsEnabled) {
      (async () => {
        const channels = await getChannels();
        if (channels && channels.length > 0) {
          setChannels(channels);
        }
      })();
    }
  }, [channelsEnabled, getChannels]);

  const hideAddButton = () => {
    if (targets.length > 0) {
      const lastTargetAdded = targets[targets.length - 1];
      if (lastTargetAdded.channel) {
        return false;
      }
    }
    return true;
  };

  const hideDeleteButton = () => targets.length <= 1;

  return (
    <>
      {!channelsEnabled && (
        <>
          <FieldLabel label={t("push-notification.form.targets_platform_label")} />
          <PushNotificationPlatformSelect targets={targets} onUpdate={onUpdate} index={0} disabled={disabled} />
        </>
      )}
      {channelsEnabled && channels.length ? (
        <div data-test-id="push-notification-select">
          <RenderMultipleRow
            maxRows={channels.length}
            columnLabels={[
              t("push-notification.form.targets_label"),
              t("push-notification.form.targets_platform_label")
            ]}
            columnRenders={[
              (index: number) => {
                return (
                  <PushNotificationChannelSelect
                    targets={targets}
                    onUpdate={onUpdate}
                    index={index}
                    channels={channels}
                    disabled={disabled}
                  />
                );
              },
              (index: number) => {
                return (
                  <PushNotificationPlatformSelect
                    targets={targets}
                    onUpdate={onUpdate}
                    index={index}
                    defaultChannel={""}
                    disabled={disabled || !(targets && targets[index] && targets[index].channel)}
                  />
                );
              }
            ]}
            name="target-categories"
            selectedLHSValues={targets}
            onDelete={onUpdate}
            hideAddButton={hideAddButton()}
            disableDeleteButton={hideDeleteButton()}
            disabled={disabled}
            onAdd={() => {
              targets.push({ channel: "" });
              onUpdate(targets);
            }}
          />
        </div>
      ) : null}
    </>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    getChannels: async (): Promise<PushNotificationChannel[]> => {
      try {
        const channels = await getChannels();
        return channels;
      } catch (err) {
        dispatch(notificationError(t("push-notification.messages.unable-to-load-channels")));
        return [];
      }
    }
  };
};

export default compose(
  connect(
    () => {},
    mapDispatchToProps
  )
)(PushNotificationTargets);
export { PushNotificationTargets };
