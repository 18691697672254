/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { StoryId } from "api/primitive-types";
import { PartialAppState } from "../../state";

import { STORY_EDITOR_IMPORT_PATH } from "../../routes";
import { navigate } from "utils/routes.utils";
import { SearchedStory } from "api/search";
import { updateStorySearchTermAction, resetImportCardDisplayAction } from "../../async-action-creators";

import Searchbar from "components/search-bar/search-bar";
import StoryList from "pages/story-editor/components/story-list/story-list";
import Spinner from "components/spinner/spinner";

import { t } from "i18n";
import styles from "./story-search.module.css";
import { Story } from "api/story";

interface StateProps {
  storyId: StoryId;
  searchTerm: string;
  storyList: SearchedStory[];
  selectedStory: Story | null;
  loading: boolean;
}

interface DispatchProps {
  onChange: (value: string) => void;
  onStorySelect: (storyId: StoryId, importStoryId: StoryId) => void;
}

type Props = StateProps & DispatchProps;

const StorySearch: React.SFC<Props> = ({ storyId, searchTerm, onChange, loading, storyList, onStorySelect }) => (
  <div className={styles["story-search-container"]}>
    <Searchbar value={searchTerm} onChange={onChange} placeholder={t("story-editor.inspector.search")} />
    {loading && <Spinner message={t("story-editor.inspector.fetching-stories")} />}
    {!loading && (
      <StoryList storyList={storyList} searchTerm={searchTerm} onStorySelect={onStorySelect} storyId={storyId} />
    )}
  </div>
);

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    storyId: state.storyEditor.story["story-content-id"],
    searchTerm: state.storyEditor.app.importCard.storySearchTerm,
    storyList: state.storyEditor.app.importCard.storyList,
    selectedStory: state.storyEditor.app.importCard.selectedStory,
    loading: state.storyEditor.ui.loading
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    onChange: (value) => dispatch(updateStorySearchTermAction(value)),
    onStorySelect: (storyId, importStoryId) => {
      dispatch(resetImportCardDisplayAction());
      dispatch(navigate(STORY_EDITOR_IMPORT_PATH, { id: storyId, importStoryId }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StorySearch);

export { StorySearch };
