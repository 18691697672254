/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AnyCustomURL } from "api/custom-url";

export const hasSlashAtStart = (str: string) => str.split("")[0] === "/";

export const prependSlash = (str: string) => `/${str}`;

export const ensureSlashAtStart = (str: string) => (hasSlashAtStart(str) ? str : prependSlash(str));

export const isExistingCustomURL = (customURL: AnyCustomURL): boolean => "id" in customURL;
