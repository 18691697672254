/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import _get from "lodash/get";
import { FixedTabList, Panel } from "components/tabs/tabs";
import { navigateFn } from "utils/routes.utils";

import CardShare from "../card-share/card-share";
import CardAttributes from "pages/story-editor/components/attributes/attributes";

import { actions } from "../../actions";
import { PartialAppState, CardError } from "../../state";
import styles from "./card-settings-inspector.module.css";

import {
  uploadCardImageAction,
  deleteCardImageAction,
  getAttributesOfTypeEntityOptionsAction
} from "../../async-action-creators";

import {
  STORY_EDITOR_CARD_SHARE,
  STORY_EDITOR_CARD_ATTRIBUTES,
  STORY_EDITOR_CARD_SHARE_IMAGE_PATH
} from "../../routes";

import { t } from "i18n";
import { StoryId, CardId, ImageId } from "api/primitive-types";
import { TranslationFunction } from "i18next";
import { ThunkDispatch } from "redux-thunk";
import { Card, UnsavedStory, Story } from "api/story";
import { Image } from "api/search-media-image";
import { Entity } from "api/entity";
import { StoryAttribute } from "api/route-data/story-route-data";

const TABS = (t: TranslationFunction) => [
  {
    label: t("story-editor.cardSharing"),
    value: "share"
  },
  {
    label: t("story-editor.cardAttributes"),
    value: "attributes"
  }
];

const canShareCard = (story: Story | UnsavedStory, card: Card) => {
  var storyCardShareToggle = story.metadata["card-share"] ? story.metadata["card-share"].shareable : false;
  var cardCardShareToggle = _get(card, ["metadata", "social-share", "shareable"], storyCardShareToggle);
  return cardCardShareToggle || false;
};

const getCardAttributes = (attributes: Array<StoryAttribute>) =>
  attributes.filter((attribute: StoryAttribute) => attribute.type === "card");

const getCardAttributesErrors = (cardMetadataErrors?: CardError["metadata"]) => {
  if (cardMetadataErrors && cardMetadataErrors.hasOwnProperty("code")) {
    return { attributes: cardMetadataErrors };
  } else if (
    cardMetadataErrors &&
    cardMetadataErrors.attributes &&
    cardMetadataErrors.attributes.hasOwnProperty("code")
  ) {
    return cardMetadataErrors;
  } else {
    return cardMetadataErrors && cardMetadataErrors["attributes"];
  }
};

interface CardSettingsInspectorStateProps {
  story: PartialAppState["storyEditor"]["story"];
  card: PartialAppState["storyEditor"]["story"]["cards"]["cardId"];
  attributes: PartialAppState["config"]["storyAttributes"];
  storyId: StoryId;
  cardId: CardId;
  selectedTab: PartialAppState["storyEditor"]["ui"]["inspector"]["selectedTab"];
  errors?: Required<PartialAppState["storyEditor"]["ui"]["errors"]["editor"]>["cards"][string];
  isFreeTextAttributesEnabled: boolean;
}

interface CardSettingsInspectorDispatchProps {
  switchTab(value: string, storyId: StoryId, cardId: CardId): void;
  toggleCardShare(cardId: CardId, value: boolean): void;
  updateCardShareValues(cardId: CardId, key: string, value: string): void;
  updateCardAttributes(
    cardId: CardId,
    key: string,
    value: Array<string | Entity | { id: string; name: string; type: string }> | null
  ): void;
  onChooseImage(storyId: StoryId, cardId: CardId, image: Image): void;
  onReplaceImage(storyId: StoryId, cardId: CardId): void;
  onImageDelete(cardId: CardId): void;
  openPhotoEditor(image: Image, imageId: ImageId): void;
  getAttributesOfTypeEntityOptions(query: string, attributeValues: string[]): void;
  setSelectedMedia: (storyId: StoryId, cardId: CardId, image: Image) => void;
}

interface CardSettingsInspectorOwnProps {
  params: {
    id: StoryId;
    cardId: CardId;
  };
}

type CardSettingsInspectorProps = CardSettingsInspectorStateProps &
  CardSettingsInspectorDispatchProps &
  CardSettingsInspectorOwnProps;

const CardSettingsInspector: React.SFC<CardSettingsInspectorProps> = ({
  selectedTab,
  storyId,
  cardId,
  switchTab,
  card,
  story,
  toggleCardShare,
  updateCardShareValues,
  attributes,
  updateCardAttributes,
  onChooseImage,
  onReplaceImage,
  onImageDelete,
  openPhotoEditor,
  errors,
  isFreeTextAttributesEnabled,
  getAttributesOfTypeEntityOptions,
  setSelectedMedia
}) => {
  return (
    <div className={styles["manage-inspector-container"]}>
      <FixedTabList tabs={TABS(t)} value={selectedTab} onChange={(value) => switchTab(value, storyId, cardId)} />
      <Panel>
        {selectedTab === "share" ? (
          <CardShare
            cardId={cardId}
            toggleCardShare={toggleCardShare}
            card={card}
            updateCardShareValues={updateCardShareValues}
            canShareCard={canShareCard(story, card)}
            storyHeadline={story.headline}
            onChooseImage={(image: Image) => onChooseImage(storyId, cardId, image)}
            onReplaceImage={() => onReplaceImage(storyId, cardId)}
            onDelete={onImageDelete}
            openPhotoEditor={openPhotoEditor}
            setSelectedMedia={(image: Image[]) => cardId && setSelectedMedia(storyId, cardId, image[0])}
          />
        ) : (
          <CardAttributes
            attributes={getCardAttributes(attributes)}
            onAttributeChange={(key, value) => updateCardAttributes(cardId, key, value)}
            selectedAttributes={(card!.metadata && card.metadata.attributes) || []}
            isFreeTextAttributesEnabled={isFreeTextAttributesEnabled}
            getAttributesOfTypeEntityOptions={getAttributesOfTypeEntityOptions}
            errors={getCardAttributesErrors(errors && errors.metadata)}
          />
        )}
      </Panel>
    </div>
  );
};

const mapStateToProps = (
  state: PartialAppState,
  ownProps: CardSettingsInspectorOwnProps
): CardSettingsInspectorStateProps => {
  const cardId = ownProps.params.cardId;
  return {
    story: state.storyEditor.story,
    card: state.storyEditor.story.cards[cardId],
    attributes: state.config.storyAttributes,
    storyId: ownProps.params.id,
    cardId,
    selectedTab: state.storyEditor.ui.inspector.selectedTab,
    isFreeTextAttributesEnabled: state.features.isFreeTextAttributesEnabled,
    ...(state.storyEditor.ui.errors.editor.cards && { errors: state.storyEditor.ui.errors.editor.cards[cardId] })
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  ownProps: CardSettingsInspectorOwnProps
): CardSettingsInspectorDispatchProps => {
  const navigate = navigateFn(dispatch);
  return {
    switchTab: (value, storyId, cardId) =>
      value === "share"
        ? navigate(STORY_EDITOR_CARD_SHARE, { id: storyId, cardId })
        : navigate(STORY_EDITOR_CARD_ATTRIBUTES, { id: storyId, cardId }),
    toggleCardShare: (cardId, value) => dispatch({ type: actions.TOGGLE_CARD_SHARE, payload: { cardId, value } }),
    updateCardShareValues: (cardId, key, value) =>
      dispatch({ type: actions.CARD_SHARE_UPDATE, payload: { cardId, key, value } }),
    updateCardAttributes: (cardId, key, value) =>
      dispatch({ type: actions.CARD_ATTRIBUTES_UPDATE, payload: { cardId, key, value } }),
    onChooseImage: (storyId, cardId, image) => dispatch(uploadCardImageAction(storyId, cardId, image)),
    onReplaceImage: (storyId, cardId) => navigate(STORY_EDITOR_CARD_SHARE_IMAGE_PATH, { id: storyId, cardId }),
    onImageDelete: (cardId) => dispatch(deleteCardImageAction(cardId)),
    openPhotoEditor: (image, imageId = "") =>
      dispatch({ type: actions.OPEN_PHOTO_EDITOR, payload: { image, imageId, imageAs: { type: "card-share-image" } } }),
    getAttributesOfTypeEntityOptions: (query, attributeValues) =>
      getAttributesOfTypeEntityOptionsAction(query, attributeValues),
    setSelectedMedia: (storyId, cardId, image) => {
      const mappedImage = {
        url: image.url,
        metadata: image.metadata,
        "temp-s3-key": image["temp-image-key"],
        attribution: "",
        caption: ""
      };
      navigate(STORY_EDITOR_CARD_SHARE, { id: storyId, cardId });
      dispatch({ type: actions.CARD_SHARE_UPDATE, payload: { key: "image", value: mappedImage, cardId } });
    }
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CardSettingsInspector);
