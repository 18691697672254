/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { Feature } from "store/feature/feature";
import { normalize } from "utils";

export const getBySlugs = (slugs: string[]): Promise<NormalizedObject<Feature>> => {
  return client
    .url("/api/v1/features")
    .query({ slugs: slugs.join(",") })
    .get()
    .json()
    .then(({ data }: { data: Feature[] }) => normalize(data));
};

export const update = ({ id, changes }: { id: number; changes: Partial<Feature> }): Promise<void> => {
  return client
    .url(`/api/v1/features/${id}`)
    .patch(changes)
    .res();
};
