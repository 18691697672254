/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useCallback, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Button from "components/button/button";
import { loadPushNotifications, updateTab, updateSearchTerm, navigateToTab } from "./async-action-creators";
import Loader from "./loader";
import { LoaderState } from "behaviors/loader/state";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import PushNotificationList from "./components/push-notification-list/push-notification-list";
import Inspector from "./components/inspector/inspector";
import { PUSH_NOTIFICATION_NEW_PATH } from "./routes";
import wrapPage from "containers/page/page";
import { t } from "i18n";
import styles from "./push-notification.module.css";
import { PushNotificationId } from "api/primitive-types";
import TAB_LIST, { TabValue, getStringTabValue } from "./tab-list";
import { ScrollTabList, Panel } from "components/tabs/tabs";
import { Aggregations } from "api/workspace";
import SearchBar from "components/search-bar/search-bar";
import ContentHeader from "pages/settings/components/content-header/content-header";
import { LocationState } from "history";
import { navigate } from "utils/routes.utils";

interface StateProps {
  mainLoader: LoaderState;
  saveLoader: LoaderState;
  detailsLoader: LoaderState;
  currentTab: TabValue;
  term: string;
  aggregations: Aggregations;
  channelsEnabled?: boolean;
}

interface OwnProps {
  location?: LocationState;
  match: any;
  tabType: any;
  isNew?: boolean;
  isEdit?: boolean;
  pushNotificationId?: PushNotificationId;
}

interface DispatchProps {
  loadPushNotifications: (term: string, currentTab: TabValue) => void;
  addNewPushNotification: (currentTab: any) => void;
  updateTab: (tab: TabValue) => void;
  updateSearchTerm: (term: string, tab: TabValue) => void;
  navigateToTab: (tabValue: TabValue) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

export const getBadgeCount = (tabValue: TabValue, aggregations: Aggregations): number => {
  return aggregations.status[getStringTabValue(tabValue)];
};

export const PushNotificationWithRoutes: React.FC<Props> = ({
  updateTab,
  loadPushNotifications,
  addNewPushNotification,
  currentTab,
  term,
  updateSearchTerm,
  aggregations,
  mainLoader,
  detailsLoader,
  location,
  channelsEnabled,
  navigateToTab,
  tabType,
  isNew,
  isEdit,
  pushNotificationId
}) => {
  const initializeTab = useCallback(() => {
    updateTab(tabType as TabValue);
    loadPushNotifications("", tabType as TabValue);
  }, [tabType, updateTab, loadPushNotifications]);

  useEffect(() => {
    initializeTab();
  }, [initializeTab]);

  const handleCloseInspector = () => {
    navigateToTab(currentTab);
  };

  const handleTabChange = (tabValue: TabValue) => {
    updateTab(tabValue);
    navigateToTab(tabValue);
  };

  const tabs = TAB_LIST(t).map((tab) => ({
    ...tab,
    badge: getBadgeCount(tab.value, aggregations)
  }));

  return (
    <>
      <div className={styles["push-notification-list-container"]}>
        <ContentHeader title={t("push-notification.title")} />
        <header className={styles["push-notification-header"]}>
          <div className={styles["push-notification-search"]}>
            <SearchBar
              value={term}
              onChange={(changedTerm) => updateSearchTerm(changedTerm, currentTab)}
              placeholder={t("push-notification.search_placeholder")}
            />
          </div>
          <div className={styles["push-notification-action-button"]}>
            <Button
              data-testid="create-button"
              type="secondary"
              onClick={() => {
                addNewPushNotification(getStringTabValue(currentTab));
              }}>
              {t("push-notification.cta.create")}
            </Button>
          </div>
        </header>
        <ScrollTabList tabs={tabs} value={currentTab} onChange={(tabValue: TabValue) => handleTabChange(tabValue)} />
        <Panel>
          <LoaderWrapper component={Loader} loader={mainLoader}>
            <PushNotificationList />
          </LoaderWrapper>
        </Panel>
      </div>
      <Inspector
        error={detailsLoader.error}
        navigateBackFromInspector={handleCloseInspector}
        channelsEnabled={channelsEnabled}
        location={location}
        isNew={isNew}
        isEdit={isEdit}
        pushNotificationId={pushNotificationId}
      />
    </>
  );
};

const mapStateToProps = (state: any): StateProps & { title: string } => ({
  mainLoader: state.pushNotification.ui.main,
  saveLoader: state.pushNotification.ui.save,
  detailsLoader: state.pushNotification.ui.details,
  currentTab: state.pushNotification.ui.currentTab,
  term: state.pushNotification.ui.searchTerm,
  aggregations: state.pushNotification.aggregations,
  channelsEnabled: state.features.enablePushNotificationChannels,
  title: "push_notifications"
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, void, any>): DispatchProps => ({
  loadPushNotifications: (term: string, tabValue: TabValue) => dispatch(loadPushNotifications(term, tabValue)),
  addNewPushNotification: (currentTab) => dispatch(navigate(PUSH_NOTIFICATION_NEW_PATH, { type: currentTab })),
  updateTab: (tab: TabValue) => dispatch(updateTab(tab)),
  updateSearchTerm: (term: string, tab: TabValue) => dispatch(updateSearchTerm(term, tab)),
  navigateToTab: (tabValue: TabValue) => dispatch(navigateToTab(tabValue))
});

export default compose(connect(mapStateToProps, mapDispatchToProps), wrapPage())(PushNotificationWithRoutes);
