/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { BreakingNews, LinkedStory, AssociatedStory, BNSocialCard } from "api/breaking-news";
import { removeKeyFromObject } from "utils";
import { PartialStoryTemplateFields, StoryTemplateFields, SocialAccount } from "./state";
import { AssociatedStoryDefaults } from "api/route-data/breaking-news-route-data";
import { Fields as FieldsSpec } from "api/route-data/story-route-data";
import { SavedImage } from "api/breaking-news-defaults";
import { StoryStatus, Tree, StoryElement, StoryElementType, Card, StoryEntity, StoryTag } from "api/story";
import { Image } from "api/search-media-image";
import { EntityId } from "api/primitive-types";
import { flatten } from "lodash";
import { Entity } from "api/entity";
import { get } from "lodash";

export function addLinkedStory(breakingNews: BreakingNews, linkedStory: LinkedStory): BreakingNews {
  return {
    ...breakingNews,
    "breaking-news-linked-story-id": linkedStory.id,
    metadata: {
      ...breakingNews.metadata,
      "linked-story": {
        id: linkedStory.id,
        headline: linkedStory.headline,
        "story-content-id": linkedStory.id
      }
    }
  };
}

export function removeLinkedStory(breakingNews: BreakingNews): BreakingNews {
  return {
    ...removeKeyFromObject("breaking-news-linked-story-id", breakingNews),
    metadata: { ...removeKeyFromObject("linked-story", breakingNews.metadata) }
  } as BreakingNews;
}

export function getAutoFilledStoryFieldsUsingDefaults(
  associatedStoryDefaults: AssociatedStoryDefaults
): PartialStoryTemplateFields {
  let partialStoryTemplateFields: PartialStoryTemplateFields = {};
  if (associatedStoryDefaults) {
    if (associatedStoryDefaults["image-data"]) {
      const defaultHeroImageData = associatedStoryDefaults["image-data"] as SavedImage;

      partialStoryTemplateFields.heroImage = {
        key: defaultHeroImageData["image-s3-key"],
        url: defaultHeroImageData["image-url"],
        attribution: defaultHeroImageData["image-attribution"],
        caption: defaultHeroImageData["image-caption"],
        metadata: defaultHeroImageData["image-metadata"],
        "alt-text": defaultHeroImageData["alt-text"]
      };
    }

    partialStoryTemplateFields.cardContent = associatedStoryDefaults.text;
  }

  return partialStoryTemplateFields;
}

export function getAutoFilledStoryFieldsUsingBNHeadline(
  fieldsSpec: FieldsSpec,
  headline: string
): PartialStoryTemplateFields {
  const autoFillableFields = ["headline", "subheadline", "meta-description", "meta-title", "summary"];
  const mandatoryFields = autoFillableFields.filter((key) => fieldsSpec[key] && fieldsSpec[key].validations.mandatory);
  return mandatoryFields.reduce((acc, field) => ({ ...acc, [field]: headline }), {});
}

export function getAutoFilledStoryTemplateFields(
  fieldsSpec: FieldsSpec,
  storyTemplateFields: StoryTemplateFields,
  savedStoryTemplateFields: PartialStoryTemplateFields,
  associatedStoryDefaults: AssociatedStoryDefaults,
  BNHeadline: string
) {
  const autoFilledStoryFieldsWithDefaults = getAutoFilledStoryFieldsUsingDefaults(associatedStoryDefaults);

  const autoFilledStoryFieldsWithBNHeadline = getAutoFilledStoryFieldsUsingBNHeadline(fieldsSpec, BNHeadline);
  return {
    ...storyTemplateFields,
    ...autoFilledStoryFieldsWithDefaults,
    ...autoFilledStoryFieldsWithBNHeadline,
    ...savedStoryTemplateFields
  };
}

function getEntitiesFromTags(tags: Array<StoryTag | StoryEntity>) {
  const tagEntities = tags.filter((tag) => tag["tag-type"] === "Entity");
  const tagEntityIds = tagEntities.map((entity) => entity.id);
  return tagEntityIds;
}

function getEntityIdsFromStoryAttributes(storyAttribute: { [key: string]: Array<string | Entity> }) {
  const attributeValues = flatten(Object.values(storyAttribute));
  const entityAttributeValues = attributeValues.filter((attr: string | Entity) =>
    attr.hasOwnProperty("entity-type-id")
  );
  return entityAttributeValues.map((attr: Entity) => attr.id);
}

export function createAssociatedStory(storyTemplateFields: StoryTemplateFields): AssociatedStory {
  const {
    headline,
    subheadline,
    heroImage,
    "custom-url": customSlug,
    "canonical-url": canonicalURL,
    "meta-title": metaTitle,
    "meta-description": metaDescription,
    "meta-keywords": metaKeywords,
    authors,
    tags,
    sections,
    summary,
    metadata
  } = storyTemplateFields;

  const cardClientId = "client-card-" + Math.random();
  const cardId = "new-card-" + Math.random();

  const storyElementClientId = "client-story-element-" + Math.random();
  const storyElementId = "new-story-element-" + Math.random();
  const defaultStoryElement: StoryElement = {
    id: storyElementId,
    "client-id": storyElementClientId,
    type: StoryElementType.Text,
    text: storyTemplateFields.cardContent,
    metadata: {},
    subtype: null,
    "card-id": cardId
  };

  const defaultCard: Card = {
    id: cardId,
    "content-id": cardId,
    "client-id": cardClientId,
    "content-type": "card",
    tree: [storyElementId],
    metadata: {}
  };

  const defaultTree: Tree = {
    "client-id": cardClientId,
    "content-id": cardId,
    "content-type": "card"
  };

  const tagEntityIds: Array<EntityId> = getEntitiesFromTags(tags);
  const storyAttributeEntityIds = getEntityIdsFromStoryAttributes((metadata && metadata["story-attributes"]) || {});

  const story: AssociatedStory = {
    "story-content-id": "new",
    headline,
    subheadline,
    summary,
    "hero-image": heroImage as Image,
    cards: {
      [cardId]: defaultCard
    },
    tree: [defaultTree],
    "story-elements": {
      [storyElementId]: defaultStoryElement
    },
    status: StoryStatus.Approved,
    metadata: metadata || {},
    sections,
    "author-id": storyTemplateFields.authors[0].id,
    "author-name": storyTemplateFields.authors[0].name,
    authors,
    tags,
    entities: {
      tagEntities: tagEntityIds,
      storyAttributes: storyAttributeEntityIds
    },
    seo: {
      "meta-title": metaTitle,
      "meta-description": metaDescription,
      "meta-keywords": metaKeywords
    },
    "push-notification-targets": [],
    "custom-slug": customSlug,
    "canonical-url": canonicalURL,
    "updated-cards": [],
    "private-fields": { "editor-notes": "" },
    "publish-at": null
  };

  return story;
}

export function convertSocialAccountsForPublish(socialAccounts: SocialAccount[]) {
  return socialAccounts.map((account) => ({ id: account["account-id"], type: account.provider }));
}

export function createSocialCard(breakingNews: BreakingNews, socialAccounts: SocialAccount[]) {
  const cardId = "social-card-" + Math.random();
  const breakingNewsType = get(breakingNews.metadata, "breaking-news-type");
  const postMessage = breakingNewsType
    ? `${breakingNewsType.toUpperCase()} \n ${breakingNews.headline}`
    : breakingNews.headline;

  return {
    contentId: "new",
    cardId: cardId,
    postMessage,
    isPosted: false,
    isScheduled: false,
    selectedMedia: convertSocialAccountsForPublish(socialAccounts)
  } as BNSocialCard;
}

export function getSelectedSocialAccountsForPublish(socialAccounts: SocialAccount[]): SocialAccount[] {
  return socialAccounts.filter((account) => account.selected);
}
