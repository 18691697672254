/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Spinner from "components/spinner/spinner";
import styles from "./media-card.module.css";
import { Media } from "pages/media-library/state";
import Checkbox from "components/checkbox/checkbox";
import WAnalytics from "components/icons/white-gradiant-analytics";
import { useEffect, useState } from "react";
import { get } from "lodash";

const QUERY_PARAMS = "?w=230";

interface MediaCardProps {
  media?: Media;
  addThumbnailUrl?: boolean;
  canMultiSelect?: boolean;
  mediaForMultiSelect?: Array<Media>;
  editMedia?: (media: Media) => void;
  setMediaItemForMultiSelect?: (media: Media) => void;
}

const getThumbnailUrlFromUrl = (url: string) => url + QUERY_PARAMS;

const isMediaSelected = (mediaForMultiSelect: Array<Media>, media?: Media): boolean =>
  !!media && !!mediaForMultiSelect.find((m) => media && m.image.url === media.image.url);

const MediaCard: React.FC<MediaCardProps> = ({
  media,
  editMedia,
  addThumbnailUrl,
  canMultiSelect,
  setMediaItemForMultiSelect,
  mediaForMultiSelect = []
}) => {
  const [checked, setChecked] = useState<boolean>(isMediaSelected(mediaForMultiSelect, media));

  useEffect(() => {
    setChecked(isMediaSelected(mediaForMultiSelect, media));
  }, [media, mediaForMultiSelect]);

  const onClick = () =>
    canMultiSelect
      ? media && setMediaItemForMultiSelect && setMediaItemForMultiSelect(media)
      : media && editMedia && editMedia(media);
  const imageSource = get(media, ["image", "source"]);

  return (
    <div className={styles["media-card"]} data-test-id="media-card" onClick={onClick}>
      {canMultiSelect && (
        <div className={styles["media-card-checkbox-wrapper"]}>
          <Checkbox checked={checked} id={`media-${media && media.image.url}`} />
        </div>
      )}
      {media ? (
        <React.Fragment>
          {media.hasOwnProperty("count") && (
            <div className={styles["media-card-analytics-container"]} data-test-id="image-card-analytics">
              <WAnalytics />
              <span>{media.count}</span>
            </div>
          )}
          {imageSource && (
            <div className={styles["media-card-source"]} data-test-id="image-card-source">
              <span>{imageSource}</span>
            </div>
          )}
          <img
            src={addThumbnailUrl ? getThumbnailUrlFromUrl(media.image.url) : media.image.url}
            className={styles["media-card-image"]}
            alt={media.instances[0].caption}
            data-test-id="media-card-image"
          />
        </React.Fragment>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default MediaCard;
