/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";
import {
  listSectionsAction,
  listSectionsSuccessAction,
  listSectionsFailureAction,
  saveSectionAction,
  saveSectionSuccessAction,
  saveSectionFailureAction,
  deleteSectionAction,
  deleteSectionSuccessAction,
  deleteSectionFailureAction,
  undeleteSectionAction,
  undeleteSectionFailureAction,
  undeleteSectionSuccessAction,
  initNewSectionAction,
  cancelCreateOrEditSectionAction,
  editSectionAction,
  editSectionFailureAction,
  setGeneratedSlugAction
} from "./action-creators";
import { AnySection, Section } from "api/section";
import { generateSlug } from "api/slug";
import * as api from "api/section";
import { navigate } from "utils/routes.utils";
import { SECTIONS_INDEX_PATH } from "./routes";
import { isExistingSection, findSectionInTree, sectionToUnsavedSection } from "./utils";
import { validateSection } from "./validate";
import { toClientValidationError } from "utils/validation.utils";
import { PartialAppState } from "./state";
import { SectionId } from "api/primitive-types";
import { SectionNotFoundError } from "./errors";
import { t } from "i18n";
import { NOTIFICATION_ERROR } from "containers/page/actions";
import { saveWatermarkImage, getWatermarkImage, setWatermarkImageSection } from "store/watermark-images";

export const loadSections = () => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(listSectionsAction());

  try {
    const sections = await api.getSectionsTree(true);
    dispatch(listSectionsSuccessAction(sections));
  } catch (error) {
    dispatch(listSectionsFailureAction(error));
  }
};

export const saveSection = (section: AnySection) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(saveSectionAction(section));

  const errors = validateSection(section);
  if (errors) {
    dispatch(saveSectionFailureAction(section, toClientValidationError<AnySection>(errors)));
    return;
  }

  try {
    const response = isExistingSection(section)
      ? await api.updateSection(section.id, sectionToUnsavedSection(section))
      : await api.saveNewSection(section);

    if (!isExistingSection(section)) {
      dispatch(setWatermarkImageSection({ section: response.section }));
    }
    await dispatch(saveWatermarkImage({ fireErrorAction: false }));
    dispatch(saveSectionSuccessAction(response.section));
    dispatch(loadSections());
    dispatch(navigate(SECTIONS_INDEX_PATH));
  } catch (error) {
    const errorJson = JSON.parse(error.message);
    dispatch(saveSectionFailureAction(section, error));
    dispatch({ type: NOTIFICATION_ERROR, payload: { message: errorJson.error.message } });
  }
};

export const deleteSection = (section: Section) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(deleteSectionAction(section));

  try {
    await api.deleteSection(section);

    dispatch(deleteSectionSuccessAction(section));
    dispatch(loadSections());
  } catch (error) {
    dispatch(deleteSectionFailureAction(section, error));
  }
};

export const undeleteSection = (section: Section) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(undeleteSectionAction(section));

  try {
    await api.undeleteSection(section);

    dispatch(undeleteSectionSuccessAction(section));
    dispatch(loadSections());
  } catch (error) {
    dispatch(undeleteSectionFailureAction(section, error));
  }
};

export const addNewSection = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(initNewSectionAction());
};

export const editSection = (sectionIdParam: string | SectionId) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const sectionId = typeof sectionIdParam === "string" ? parseInt(sectionIdParam, 10) : sectionIdParam;
  const section = Number.isNaN(sectionId) ? undefined : findSectionInTree(sectionId, getState().sections.sections);

  if (section) {
    dispatch(editSectionAction(section));
    ((dispatch(getWatermarkImage({ section })) as unknown) as Promise<any>).catch(() => null);
  } else {
    const idStr = sectionIdParam.toString();
    dispatch(
      editSectionFailureAction(new SectionNotFoundError(idStr, t("sections.messages.not-found", { id: idStr })))
    );
  }
};

export const cancelSaveSection = (section: AnySection) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(cancelCreateOrEditSectionAction(section));
  dispatch(navigate(SECTIONS_INDEX_PATH));
};

export const setGeneratedSlug = (section: AnySection) => async (dispatch: ThunkDispatch<any, any, any>) => {
  if (!section.hasOwnProperty("id")) {
    try {
      const response = await generateSlug(section.name || section.slug);
      if (response.slug) {
        dispatch(setGeneratedSlugAction({ ...section, slug: response.slug }));
      }
    } catch (error) {
      console.error("Failed to generate slug");
    }
  }
};
