/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import { useId } from "react-id-generator";
import classnames from "classnames/bind";
import styles from "./add-new-media.module.css";
import { AnyImage } from "api/search-media-image";
import { MimeType } from "utils/file.utils";
import { PartialAppState } from "pages/media-library/state";
import { addNewMediaAction } from "pages/media-library/action-creators";
import { notificationError } from "action-creators/notification";
import { selectIsDesktopSizeViewport } from "store/viewport";
import Plus from "components/icons/plus";
import Media from "components/icons/media";
import Spinner from "components/spinner/spinner";
import Dropzone from "components/dropzone/dropzone";
import DropzoneMessage, { DropzoneMessageType, DropzoneMessageVariant } from "components/dropzone/dropzone-message";

const cx = classnames.bind(styles);

enum AddNewMediaButtonType {
  Upload = "upload",
  MediaGallery = "media-gallery"
}

interface AddNewMediaButtonProps {
  variant: AddNewMediaButtonType;
  uploadText?: string;
  handleClick?: React.MouseEventHandler;
}

interface OwnProps {
  openGallery: (e: React.MouseEvent) => void;
  setSelectedMedia: (images: AnyImage[]) => void;
  accept?: MimeType | string;
  uploadText?: string;
  enableMultipleUploads?: boolean;
  classname?: string;
  disableDropzone?: boolean;
  dropzoneMessage?: string;
  dropzoneMessageSize?: DropzoneMessageVariant;
  enableEXIFLocation?: boolean;
  showEditImage?: boolean;
  switchToUploadRoute?: (mediaKey: string) => void;
  updateImageUploadStatus?: (status: { uploading: boolean }) => void;
}

interface StateProps {
  isDesktopSizeViewport: boolean;
}

interface DispatchProps {
  onError: (error: string) => void;
  addNewMedia: (
    files: File[],
    updateImageUploadStatus: (status: { uploading: boolean }) => void,
    enableEXIFLocation?: boolean
  ) => void;
}

type AddNewMediaProps = DispatchProps & StateProps & OwnProps;

const AddNewMediaButton: React.FC<AddNewMediaButtonProps> = ({ variant, uploadText, handleClick }) => {
  const [buttonIcon, buttonText, dataTestId] =
    variant === AddNewMediaButtonType.Upload
      ? [<Plus width={14} height={14} fill={"var(--mono-5)"} />, uploadText, "add-new-media-upload"]
      : [<Media width={14} height={14} />, t("components.add-new-media.media-gallery"), "add-new-media-open-gallery"];

  return (
    <div className={styles["add-new-media-button-container"]} onClick={handleClick} data-test-id={dataTestId}>
      <span className={styles["add-new-media-button"]}>
        {buttonIcon}
        <span className={styles["add-new-media-button-text"]}>{buttonText}</span>
      </span>
    </div>
  );
};

export const AddNewMedia: React.FC<AddNewMediaProps> = ({
  uploadText,
  classname,
  disableDropzone,
  dropzoneMessage,
  enableEXIFLocation,
  isDesktopSizeViewport,
  enableMultipleUploads = false,
  accept = MimeType.Images,
  dropzoneMessageSize = DropzoneMessageVariant.Medium,
  openGallery,
  addNewMedia,
  onError,
  updateImageUploadStatus
}) => {
  const [uploading, setUploading] = useState(false);
  const [inputId] = useId(1, "add-new-media-");

  const uploadMedia = (files: File[]) =>
    addNewMedia(
      files,
      updateImageUploadStatus || (({ uploading }: { uploading: boolean }) => setUploading(uploading)),
      enableEXIFLocation
    );

  const uploadButtonText =
    uploadText ||
    (enableMultipleUploads
      ? t("components.add-new-media.upload-text-plural")
      : t("components.add-new-media.upload-text"));

  const dropzoneMessageText =
    dropzoneMessage ||
    (enableMultipleUploads
      ? t("components.add-new-media.on-drag-message-plural")
      : t("components.add-new-media.on-drag-message"));

  return (
    <div className={cx("add-new-media-container", classname)}>
      {uploading ? (
        <Spinner classname={styles["add-new-media-spinner"]} message={t("components.add-new-media.uploading")} />
      ) : (
        <Dropzone
          dropHandler={uploadMedia}
          accept={accept}
          onError={onError}
          enableMultipleUploads={enableMultipleUploads}
          disable={disableDropzone}
          showOnDragAnywhere={true}
          classname={styles["add-new-media-dropzone"]}
          errorMapping={{
            fileType: t("components.dropzone.file-type-error"),
            fileCount: t("components.dropzone.file-count-error"),
            fileSize: t("components.dropzone.file-size-error")
          }}
          dragEnterMessage={
            <DropzoneMessage
              type={DropzoneMessageType.Image}
              variant={dropzoneMessageSize}
              message={dropzoneMessageText}
            />
          }>
          <div className={styles["add-new-media-buttons-container"]}>
            {addNewMedia && (
              <input
                type="file"
                id={inputId}
                multiple={enableMultipleUploads}
                accept={accept}
                onChange={(e) => e.target.files && uploadMedia(Array.from(e.target.files))}
                className={styles["file-input"]}
                data-test-id={"add-new-media-file-input"}
              />
            )}
            <label htmlFor={inputId}>
              <AddNewMediaButton variant={AddNewMediaButtonType.Upload} uploadText={uploadButtonText} />
            </label>
            <AddNewMediaButton variant={AddNewMediaButtonType.MediaGallery} handleClick={openGallery} />
          </div>
          {isDesktopSizeViewport && <div className={styles["add-new-media-drop-here-text"]}>{dropzoneMessageText}</div>}
        </Dropzone>
      )}
    </div>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    isDesktopSizeViewport: selectIsDesktopSizeViewport(state)
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  rawOwnProps: OwnProps
): DispatchProps => {
  const ownProps = {
    ...rawOwnProps,
    showEditImage: rawOwnProps.showEditImage || false,
    switchToUploadRoute: rawOwnProps.switchToUploadRoute || (() => {})
  };

  return {
    onError: (error) => dispatch(notificationError(error)),
    addNewMedia: (files, updateImageUploadStatus, enableEXIFLocation) =>
      dispatch(addNewMediaAction(files, { enableEXIFLocation }, { ...ownProps, updateImageUploadStatus }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNewMedia);
