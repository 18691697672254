/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import styles from "./switch-setting.module.css";
import classnames from "classnames/bind";
import FieldLabel from "components/field-label/field-label";
import { Switch } from "components/switch/switch";

const cx = classnames.bind(styles);

interface Props {
  label: string;
  toggle: boolean;
  onChange: (checked: boolean) => void;
  hint?: string;
  classname?: string;
}

function SwitchSetting({ label, hint, toggle, onChange, classname }: Props) {
  return (
    <div className={cx("switch-setting-container", classname)}>
      <div className={styles["switch-setting-description"]}>
        <FieldLabel label={label} />
        {hint && <span className={styles["switch-setting-hint"]}>{hint}</span>}
      </div>
      <Switch checked={toggle} onChange={onChange} />
    </div>
  );
}

export default SwitchSetting;
