/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import RadioButton from "components/radio-button/radio-button";
import Select from "components/select/select";
import classnames from "classnames/bind";
import { t } from "i18n";
import FieldLabel from "components/field-label/field-label";
import styles from "./access-level.module.css";

const cx = classnames.bind(styles);

const SUBSCRIPTION = "subscription",
  LOGIN = "login",
  PUBLIC = "public";

const AccessLevel = ({
  access,
  accessLevelValue,
  onChangeOfAccessLevel,
  onChangeOfAccess,
  accessLevelValues,
  disabled
}) => {
  return (
    <div className={styles["access-level-container"]}>
      <FieldLabel label={t("story-editor.inspector.set-access-level")} classname="set-access-level-title" />
      <RadioButton
        id="story-editor-inspector-access-level-public"
        name="access"
        label={t("story-editor.inspector.public")}
        checked={access === PUBLIC}
        onChange={(val) => {
          onChangeOfAccessLevel(null);
          onChangeOfAccess(PUBLIC);
        }}
        isDisabled={disabled}
      />
      <RadioButton
        id="story-editor-inspector-access-level-login"
        name="access"
        label={t("story-editor.inspector.login")}
        checked={access === LOGIN}
        onChange={(val) => {
          onChangeOfAccessLevel(null);
          onChangeOfAccess(LOGIN);
        }}
        isDisabled={disabled}
      />
      <RadioButton
        id="story-editor-inspector-access-level-subscription"
        name="access"
        label={t("story-editor.inspector.subscription")}
        checked={access === SUBSCRIPTION}
        onChange={(val) => onChangeOfAccess(SUBSCRIPTION)}
        isDisabled={disabled}
      />
      <div className={cx("access-level-values", { "is-open": access === SUBSCRIPTION })}>
        <Select
          label={t("story-editor.inspector.access-level-value")}
          value={accessLevelValue}
          options={accessLevelValues}
          getOptionLabel={(accessLevel) => accessLevel}
          getOptionValue={(accessLevel) => accessLevel}
          placeholder={t("story-editor.inspector.select-access-level-value")}
          onChange={(val) => onChangeOfAccessLevel(val)}
          isDisabled={disabled}
        />
      </div>
    </div>
  );
};

export default AccessLevel;
