/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

const TYPES = {
  "image/jpeg": "image",
  "image/gif": "image",
  "image/png": "image",
  "image/svg+xml": "image",
  "image/webp": "image"
};

const mapForRequest = (image) => {
  const imageKey = image["temp-image-key"] ? "temp-image-key" : "image-s3-key";
  const mimeType = (image.metadata && image.metadata["mime-type"]) || "image/jpeg";
  const imageRequest = {
    "image-metadata": image.metadata,
    title: image.caption,
    "image-attribution": image.attribution,
    hyperlink: image.hyperlink,
    type: TYPES[mimeType],
    "creator-id": image["creator-id"],
    "extracted-data": image["extracted-data"],
    source: image.source,
    "alt-text": image["alt-text"]
  };
  imageRequest[imageKey] = image.key || image["temp-image-key"];
  return imageRequest;
};

const mapResponseForExternal = (image) => {
  return {
    "temp-image-key": image["key"],
    metadata: image["image-metadata"],
    caption: image["caption"],
    attribution: image["attribution"],
    url: image["url"],
    "alt-text": image["alt-text"]
  };
};

// const mapForResponse = (image) => {
//   return {
//     key: image["image-s3-key"],
//     metadata: image["image-metadata"],
//     caption: image["image-caption"],
//     attribution: image["image-attribution"]
//   }
// } not used anywhere.

const mapForCollectionRequest = (image) => {
  var imageRequest = {
    "cover-image-s3-key": image["cover-image-s3-key"] || image.key,
    "cover-image-metadata": image["cover-image-metadata"] || image.metadata,
    caption: image.caption,
    "cover-image-url": image["cover-image-url"] || image.url
  };
  if (image["temp-image-key"]) {
    imageRequest["temp-image-key"] = image["temp-image-key"];
  }
  return imageRequest;
};

const mapForCollectionResponse = (image) => {
  return {
    key: image["cover-image-s3-key"],
    metadata: image["cover-image-metadata"],
    caption: image.caption,
    url: image["cover-image-url"]
  };
};

export { mapForRequest, mapResponseForExternal, mapForCollectionRequest, mapForCollectionResponse };
