/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import { Router as PushNotificationRouter } from "./push-notification/router";
import { Router as CollectionsRouter } from "../collections/router";
import { Router as StaticPagesRouter } from "./static-pages/router";
import { Router as FormsRouter } from "pages/forms/router";
import { FORMS_PATH } from "pages/forms/routes";

export const Router = () => {
  return (
    <Switch>
      <Route path="/content/push-notifications" component={PushNotificationRouter} />
      <Route path="/content/collections" component={CollectionsRouter} />
      <Route path="/content/static-pages" component={StaticPagesRouter} />
      <Route path="/content/static-pages" component={StaticPagesRouter} />
      <Route path={FORMS_PATH} component={FormsRouter} />
    </Switch>
  );
};
