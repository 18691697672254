/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { CustomField } from "api/custom-field";

enum Actions {
  LOAD_CUSTOM_FIELDS = "@@custom-fields/LIST_CUSTOM_FIELDS",
  LOAD_CUSTOM_FIELDS_SUCCESS = "@@custom-fields/LIST_CUSTOM_FIELDS_SUCCESS",
  LOAD_CUSTOM_FIELDS_FAILURE = "@@custom-fields/LIST_CUSTOM_FIELDS_FAILURE",

  SAVE_CUSTOM_FIELD = "@@custom-fields/SAVE_CUSTOM_FIELD",
  SAVE_CUSTOM_FIELD_SUCCESS = "@@custom-fields/SAVE_CUSTOM_FIELD_SUCCESS",
  SAVE_CUSTOM_FIELD_FAILURE = "@@custom-fields/SAVE_CUSTOM_FIELD_FAILURE",

  DELETE_CUSTOM_FIELD = "@@custom-fields/DELETE_CUSTOM_FIELD",
  DELETE_CUSTOM_FIELD_SUCCESS = "@@custom-fields/DELETE_CUSTOM_FIELD_SUCCESS",
  DELETE_CUSTOM_FIELD_FAILURE = "@@custom-fields/DELETE_CUSTOM_FIELD_FAILURE",

  INIT_NEW_CUSTOM_FIELD = "@@custom-fields/INIT_NEW_CUSTOM_FIELD",
  EDIT_CUSTOM_FIELD = "@@custom-fields/EDIT_CUSTOM_FIELD",
  EDIT_CUSTOM_FIELD_FAILURE = "@@custom-fields/EDIT_CUSTOM_FIELD_FAILURE",
  CANCEL_CREATE_OR_EDIT_CUSTOM_FIELD = "@@custom-fields/CANCEL_CREATE_OR_EDIT_CUSTOM_FIELD",
  EDIT_CUSTOM_FIELD_SUCCESS = "@@custom-fields/EDIT_CUSTOM_FIELD_SUCCESS"
}

export type LOAD_CUSTOM_FIELDS_ACTION = { type: Actions.LOAD_CUSTOM_FIELDS };
export type LOAD_CUSTOM_FIELDS_SUCCESS_ACTION = {
  type: Actions.LOAD_CUSTOM_FIELDS_SUCCESS;
  payload: { customFields: Array<CustomField> };
};
export type LOAD_CUSTOM_FIELDS_FAILURE_ACTION = {
  type: Actions.LOAD_CUSTOM_FIELDS_FAILURE;
  payload: { error: Error };
};

export type SAVE_CUSTOM_FIELD_ACTION = { type: Actions.SAVE_CUSTOM_FIELD };
export type SAVE_CUSTOM_FIELD_SUCCESS_ACTION = { type: Actions.SAVE_CUSTOM_FIELD_SUCCESS };
export type SAVE_CUSTOM_FIELD_FAILURE_ACTION = { type: Actions.SAVE_CUSTOM_FIELD_FAILURE; payload: { error: Error } };

export type DELETE_CUSTOM_FIELD_ACTION = { type: Actions.DELETE_CUSTOM_FIELD; payload: { customField: CustomField } };
export type DELETE_CUSTOM_FIELD_SUCCESS_ACTION = { type: Actions.DELETE_CUSTOM_FIELD_SUCCESS };
export type DELETE_CUSTOM_FIELD_FAILURE_ACTION = {
  type: Actions.DELETE_CUSTOM_FIELD_FAILURE;
  payload: { error: Error };
};

export type INIT_NEW_CUSTOM_FIELD_ACTION = { type: Actions.INIT_NEW_CUSTOM_FIELD };
export type EDIT_CUSTOM_FIELD_ACTION = { type: Actions.EDIT_CUSTOM_FIELD; payload: { customField: CustomField } };
export type EDIT_CUSTOM_FIELD_FAILURE_ACTION = { type: Actions.EDIT_CUSTOM_FIELD_FAILURE; payload: { error: Error } };
export type CANCEL_CREATE_OR_EDIT_CUSTOM_FIELD_ACTION = { type: Actions.CANCEL_CREATE_OR_EDIT_CUSTOM_FIELD };
export type EDIT_CUSTOM_FIELD_SUCCESS_ACTION = { type: Actions.EDIT_CUSTOM_FIELD_SUCCESS };

export type ActionTypes =
  | LOAD_CUSTOM_FIELDS_ACTION
  | LOAD_CUSTOM_FIELDS_SUCCESS_ACTION
  | LOAD_CUSTOM_FIELDS_FAILURE_ACTION
  | SAVE_CUSTOM_FIELD_ACTION
  | SAVE_CUSTOM_FIELD_SUCCESS_ACTION
  | SAVE_CUSTOM_FIELD_FAILURE_ACTION
  | DELETE_CUSTOM_FIELD_ACTION
  | DELETE_CUSTOM_FIELD_SUCCESS_ACTION
  | DELETE_CUSTOM_FIELD_FAILURE_ACTION
  | INIT_NEW_CUSTOM_FIELD_ACTION
  | EDIT_CUSTOM_FIELD_ACTION
  | EDIT_CUSTOM_FIELD_FAILURE_ACTION
  | EDIT_CUSTOM_FIELD_SUCCESS_ACTION
  | CANCEL_CREATE_OR_EDIT_CUSTOM_FIELD_ACTION;

export default Actions;
