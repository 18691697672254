/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { FormioFileUploadConfig } from "store/route-data";
import { t } from "i18n";

export default (formioFileUploadConfig: FormioFileUploadConfig) => {
  return {
    builder: {
      premium: false,
      custom: {
        title: t("forms.editor.builder.upload"),
        weight: 100,
        components: {
          uploadFile: {
            title: t("forms.editor.builder.file"),
            key: "uploadFile",
            icon: "file",
            schema: {
              label: t("forms.editor.builder.file"),
              type: "file",
              key: "file",
              fileMinSize: formioFileUploadConfig.minSize,
              fileMaxSize: formioFileUploadConfig.maxSize,
              filePattern: formioFileUploadConfig.supportedExtensions,
              storage: formioFileUploadConfig.storage
            }
          }
        }
      }
    },
    editForm: {
      file: [
        {
          key: "file",
          components: [
            {
              key: "storage",
              input: true,
              ignore: true,
              label: t("forms.editor.builder.storage"),
              type: "select"
            },
            {
              key: "dir",
              ignore: true
            },
            {
              key: "fileNameTemplate",
              ignore: true
            }
          ]
        },
        {
          key: "api",
          ignore: true
        }
      ],
      textfield: [
        {
          key: "api",
          ignore: true
        }
      ],
      number: [
        {
          key: "api",
          ignore: true
        }
      ],
      password: [
        {
          key: "api",
          ignore: true
        }
      ],
      textarea: [
        {
          key: "api",
          ignore: true
        }
      ],
      checkbox: [
        {
          key: "api",
          ignore: true
        }
      ],
      time: [
        {
          key: "api",
          ignore: true
        }
      ],
      selectboxes: [
        {
          key: "api",
          ignore: true
        }
      ],
      select: [
        {
          key: "api",
          ignore: true
        }
      ],
      radio: [
        {
          key: "api",
          ignore: true
        }
      ],
      content: [
        {
          key: "api",
          ignore: true
        }
      ],
      button: [
        {
          key: "api",
          ignore: true
        }
      ],
      email: [
        {
          key: "api",
          ignore: true
        }
      ],
      url: [
        {
          key: "api",
          ignore: true
        }
      ],
      phoneNumber: [
        {
          key: "api",
          ignore: true
        }
      ],
      address: [
        {
          key: "api",
          ignore: true
        }
      ],
      datetime: [
        {
          key: "api",
          ignore: true
        }
      ],
      day: [
        {
          key: "api",
          ignore: true
        }
      ],
      tags: [
        {
          key: "api",
          ignore: true
        }
      ],
      currency: [
        {
          key: "api",
          ignore: true
        }
      ],
      signature: [
        {
          key: "api",
          ignore: true
        }
      ],
      survey: [
        {
          key: "api",
          ignore: true
        }
      ],
      location: [
        {
          key: "api",
          ignore: true
        }
      ],
      columns: [
        {
          key: "api",
          ignore: true
        }
      ],
      fieldset: [
        {
          key: "api",
          ignore: true
        }
      ],
      panel: [
        {
          key: "api",
          ignore: true
        }
      ],
      table: [
        {
          key: "api",
          ignore: true
        }
      ],
      tabs: [
        {
          key: "api",
          ignore: true
        }
      ],
      well: [
        {
          key: "api",
          ignore: true
        }
      ],
      hidden: [
        {
          key: "api",
          ignore: true
        }
      ],
      container: [
        {
          key: "api",
          ignore: true
        }
      ],
      datagrid: [
        {
          key: "api",
          ignore: true
        }
      ],
      datamap: [
        {
          key: "api",
          ignore: true
        }
      ],
      editgrid: [
        {
          key: "api",
          ignore: true
        }
      ]
    },
    noDefaultSubmitButton: false
  };
};
