/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

import { Section } from "api/route-data/route-data";
import { Tag } from "api/tag";
import { ValidationError } from "api/breaking-news";
import { Fields } from "api/route-data/story-route-data";
import { PartialStoryTemplateFields, StoryTemplateFields } from "./state";

import MandatoryFilter from "./mandatory-filter";
import Sections from "pages/story-editor/components/manage-fields/sections";
import Tags from "pages/story-editor/components/manage-fields/tags";
import MetaKeywords from "pages/story-editor/components/manage-fields/meta-keywords";
import CustomSlug from "pages/story-editor/components/manage-fields/custom-slug";
import CanonicalUrl from "pages/story-editor/components/manage-fields/canonical-url";
import Field from "./components/field";
import { Attribute } from "api/story-attributes";
import { Entity } from "api/entity";
import StoryAttributes from "pages/story-editor/components/attributes/attributes";

export interface NonAutoFillableStoryFieldsValidationErrors {
  sections: ValidationError | null;
  tags: ValidationError | null;
  "meta-keywords": ValidationError | null;
  "custom-url": ValidationError | null;
  "canonical-url": ValidationError | null;
  metadata: ValidationError | null;
}

type NonAutoFillableStoryFieldsProps = {
  fieldsSpec: Fields;
  storyTemplateFields: StoryTemplateFields;
  sections: Section[];
  onStoryTemplateFieldChange: (partialStoryFields: PartialStoryTemplateFields) => void;
  errors?: NonAutoFillableStoryFieldsValidationErrors | null;
  hasTagCreatePermission: boolean;
  storyAttributes: Attribute[];
  isFreeTextAttributesEnabled: boolean;
  getAttributesOfTypeEntityOptions: (query: string, attributeValues: any) => void;
  onAttributeChange: (key: string, value: (string | Entity)[]) => void;
};

const getMandatoryStoryAttributes = (attributes: Attribute[]) =>
  attributes && attributes.filter((attribute) => attribute["is-mandatory"] && attribute.type === "story");

const NonAutoFillableStoryFields: React.SFC<NonAutoFillableStoryFieldsProps> = ({
  fieldsSpec,
  storyTemplateFields,
  sections,
  onStoryTemplateFieldChange,
  errors,
  hasTagCreatePermission,
  storyAttributes,
  isFreeTextAttributesEnabled,
  getAttributesOfTypeEntityOptions,
  onAttributeChange
}) => (
  <React.Fragment>
    <MandatoryFilter fieldsSpec={fieldsSpec}>
      <Field
        fieldName="sections"
        render={() => (
          <Sections
            value={storyTemplateFields.sections}
            sections={sections}
            onChange={(sections: Section[]) => onStoryTemplateFieldChange({ sections })}
            errors={errors && errors.sections}
          />
        )}
      />
      <Field
        fieldName="tags"
        render={() => (
          <Tags
            value={storyTemplateFields.tags}
            onTagChange={(tags: Tag[]) => onStoryTemplateFieldChange({ tags })}
            errors={errors && errors.tags}
            hasTagCreatePermission={hasTagCreatePermission}
          />
        )}
      />
      <Field
        fieldName="meta-keywords"
        render={() => (
          <MetaKeywords
            value={storyTemplateFields["meta-keywords"]}
            onChange={(keywords: { label: string; value: string }[]) =>
              onStoryTemplateFieldChange({ "meta-keywords": keywords.map((keyword) => keyword.value) })
            }
            errors={errors && errors["meta-keywords"]}
          />
        )}
      />
      <Field
        fieldName="custom-url"
        render={() => (
          <CustomSlug
            value={storyTemplateFields["custom-url"]}
            onChange={(customURL: string) => onStoryTemplateFieldChange({ "custom-url": customURL })}
            errors={errors && errors["custom-slug"]}
          />
        )}
      />
      <Field
        fieldName="canonical-url"
        render={() => (
          <CanonicalUrl
            value={storyTemplateFields["canonical-url"]}
            onChange={(canonicalURL: string) => onStoryTemplateFieldChange({ "canonical-url": canonicalURL })}
            errors={errors && errors["canonical-url"]}
          />
        )}
      />
    </MandatoryFilter>
    <StoryAttributes
      attributes={getMandatoryStoryAttributes(storyAttributes)}
      onAttributeChange={onAttributeChange}
      selectedAttributes={
        (storyTemplateFields && storyTemplateFields.metadata && storyTemplateFields.metadata["story-attributes"]) || {}
      }
      isFreeTextAttributesEnabled={isFreeTextAttributesEnabled}
      getAttributesOfTypeEntityOptions={getAttributesOfTypeEntityOptions}
      errors={(errors && errors.metadata) || {}}
    />
  </React.Fragment>
);

export { NonAutoFillableStoryFields };
