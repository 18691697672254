/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import wretch from "wretch";

export const client = wretch()
  .headers({ "X-QT-CLIENT": "Itsman-X" })
  .options({ credentials: "include" });

export const clientWithoutCreds = wretch().headers({ "X-QT-CLIENT": "Itsman-X" });
