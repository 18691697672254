/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import AsyncSelect from "components/select/async";
import Select from "components/select/select";
import Button from "components/button/button";
import Breadcrumbs from "components/breadcrumbs/breadcrumbs";
import { loadAuthorsData } from "pages/collections/utils";
import DateFilter from "components/date-filter/date-filter";
import classnames from "classnames/bind";
import RenderMultipleRow from "components/render-multiple-row/render-multiple-row";
import { compact } from "lodash";
import { flatten, isRange, convertRange } from "utils";
import Tags from "pages/story-editor/components/manage-fields/tags";

import styles from "./filters.module.css";
import { t } from "i18n";

const cx = classnames.bind(styles);

const setAttributeValues = (selectedstoryAttributes, selectedAttribute, values) =>
  selectedstoryAttributes.map((storyAttribute) =>
    storyAttribute.name === selectedAttribute.name ? { ...storyAttribute, values } : storyAttribute
  );

const setAttribute = (selectedstoryAttributes, currentAttribute, storyAttribute) => {
  const newStoryAttribute = { ...storyAttribute, values: [] };

  if (!currentAttribute) {
    return selectedstoryAttributes.concat([newStoryAttribute]);
  }

  let found = false;

  const results = selectedstoryAttributes.map((selectedAttribute) => {
    if (selectedAttribute.name === currentAttribute.name) {
      found = true;
      return newStoryAttribute;
    } else {
      return selectedAttribute;
    }
  });

  return found ? results : selectedstoryAttributes.concat([newStoryAttribute]);
};

const getAttributeValues = (storyAttributes, selectedAttribute) => {
  const values = selectedAttribute
    ? storyAttributes.find((storyAttribute) => storyAttribute.name === selectedAttribute.name).values
    : [];

  return selectedAttribute && selectedAttribute["data-type"] === "entity"
    ? values
    : flatten(
        values.map((value) => {
          return isRange(value) ? convertRange(value) : value;
        })
      );
};

const Filters = ({ filters, updateFilter, showFilters, sections, applyFilter, resetFilter, storyAttributes }) => {
  const selectedAttributeNames = filters && filters.storyAttributes.map((storyAttribute) => storyAttribute.name);

  const filterClasses = cx("collection-filters", { "is-expanded": showFilters });
  const separateTagsAndEntitiesAndUpdateFilter = (tags) => {
    const [justTags, justEntities] = tags.reduce(
      ([justTags, justEntities], item) =>
        !item["entity-type-id"] ? [[...justTags, item], justEntities] : [justTags, [...justEntities, item]],
      [[], []]
    );
    updateFilter("tags", justTags);
    updateFilter("entities", justEntities);
  };
  const fetchTagSelectValue = (filters) => {
    return filters && filters.tags.concat(filters.entities);
  };

  return (
    <div className={filterClasses} data-test-id="filter-classes">
      <div className={styles["collection-filters-set"]} data-test-id="collection-filters-set">
        <div className={styles["collection-filter"]} data-test-id="date-filter">
          <DateFilter
            label={t("collections.show_results_from")}
            value={filters && filters.publishedAt}
            onChange={(value) => updateFilter("publishedAt", value)}
          />
        </div>
        <div className={styles["collection-filter"]} data-test-id="sections-filter">
          <Select
            name="sections"
            value={filters && filters.sections}
            options={sections}
            onChange={(sections) => updateFilter("sections", sections)}
            getOptionLabel={(section) => section.name}
            getOptionValue={(section) => section.id}
            label={t("collections.sections")}
            formatOptionLabel={(section) => (
              <React.Fragment>
                {section.name}
                <Breadcrumbs id={section.id} crumbs={sections} getCrumbLabel={(section: any) => section.name} />
              </React.Fragment>
            )}
            isMulti={true}
            hideError={true}
          />
        </div>
        <div className={styles["collection-filter"]} data-test-id="author-filter">
          <AsyncSelect
            data-test-id="async-select"
            value={filters && filters.authors}
            onChange={(author) => updateFilter("authors", author)}
            getOptionLabel={(author) => author.name}
            getOptionValue={(author) => author.id}
            loadOptions={loadAuthorsData}
            isMulti={true}
            label={t("collections.authors")}
            defaultOptions={true}
            hideError={true}
          />
        </div>
        <div className={styles["collection-filter"]} data-test-id="tags-filter">
          <Tags
            data-test-id="tags"
            value={fetchTagSelectValue(filters)}
            onTagChange={(tags) => separateTagsAndEntitiesAndUpdateFilter(tags)}
            errors={null}
            hasTagCreatePermission={false}
          />
        </div>
        <div className={styles["collection-filter"]}>
          <div
            className={styles["storyAttributes-and-value-container"]}
            data-test-id="storyAttributes-and-value-container">
            <RenderMultipleRow
              columnLabels={[t("workspace.filters_inspector.attribute"), t("workspace.filters_inspector.values")]}
              name="story-attribute"
              selectedLHSValues={filters && filters.storyAttributes}
              columnRenders={[
                (index) => {
                  let selectedAttribute =
                    filters && filters.storyAttributes.length > 0 ? filters.storyAttributes[index] : null;
                  return (
                    <Select
                      value={selectedAttribute}
                      onChange={(storyAttribute) =>
                        updateFilter(
                          "storyAttributes",
                          setAttribute(filters.storyAttributes, selectedAttribute, storyAttribute)
                        )
                      }
                      options={storyAttributes.filter(
                        (storyAttribute) =>
                          !selectedAttributeNames.includes(storyAttribute.name) &&
                          storyAttribute["data-type"] !== "entity"
                      )}
                      getOptionLabel={(storyAttribute) => storyAttribute["display-name"]}
                      getOptionValue={(storyAttribute) => storyAttribute["name"]}
                      hideError={true}
                    />
                  );
                },
                (index) => {
                  const selectedAttribute =
                    filters && filters.storyAttributes.length > 0 ? filters.storyAttributes[index] : null;
                  return (
                    <Select
                      data-test-id="select"
                      value={selectedAttribute && selectedAttribute.values}
                      onChange={(values) =>
                        updateFilter(
                          "storyAttributes",
                          setAttributeValues(filters.storyAttributes, selectedAttribute, values)
                        )
                      }
                      options={getAttributeValues(storyAttributes, selectedAttribute)}
                      getOptionLabel={(attribute) => attribute}
                      getOptionValue={(attribute) => attribute}
                      isMulti={true}
                      isDisabled={!filters.storyAttributes}
                      hideError={true}
                    />
                  );
                }
              ]}
              onDelete={(selectedstoryAttributes) => {
                const selectedstoryAttributesWithoutNull = compact(selectedstoryAttributes);
                updateFilter("storyAttributes", selectedstoryAttributesWithoutNull);
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles["collection-filters-buttons"]} data-test-id="collection-filters-buttons">
        <div className={styles["collection-filters-action-button"]} data-test-id="collection-filters-action-button">
          <Button type="secondary" onClick={() => resetFilter()}>
            {t("collections.reset")}
          </Button>
        </div>
        <div className={styles["collection-filters-action-button"]} data-test-id="collection-filter-buttons">
          <Button type="primary" onClick={applyFilter}>
            {t("collections.apply")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Filters;
