/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";

import { Switch } from "components/switch/switch";
import Facebook from "components/icons/facebook";
import Twitter from "components/icons/twitter";
import Linkedin from "components/icons/linkedin";
import { ContextMenu, ContextMenuList, ContextMenuItem } from "components/context-menu/context-menu";
import { SocialAccount } from "api/social-accounts";
import { PartialAppState } from "../state";
import { toggleSocialAccount, deleteSocialAccount } from "../async-action-creators";
import styles from "./social-accounts.module.css";
import { formattedDate } from "utils";
import classnames from "classnames/bind";
import { useRef } from "react";
import { get } from "lodash";

const cx = classnames.bind(styles);

const getSocialShareIcon = (socialShareProvider: string) => {
  switch (socialShareProvider) {
    case "fb-page":
    case "facebook":
      return <Facebook />;
    case "twitter":
      return <Twitter />;
    case "linkedin":
      return <Linkedin />;
    default:
      return;
  }
};

const addLinkedinAccountLink = "/admin/add-social-account?service=linkedin&format=itsman-x";

interface StateProps {
  socialAccounts: SocialAccount[];
}

interface DispatchProps {
  toggleSocialAccount: (account: SocialAccount) => void;
  deleteSocialAccount: (accountId: string) => void;
}

type Props = DispatchProps & StateProps;

export const SocialAccounts: React.SFC<Props> = ({ socialAccounts, toggleSocialAccount, deleteSocialAccount }) => {
  const linkedinAnchorEleRef = useRef<HTMLAnchorElement>(null);
  const linkedinReconnectHandler = () => {
    if (linkedinAnchorEleRef && linkedinAnchorEleRef.current) {
      linkedinAnchorEleRef.current.click();
    }
  };

  const showLinkedinAccountValidity = (socialAccount: SocialAccount) => {
    return socialAccount.provider === "linkedin" && get(socialAccount, ["metadata", "refresh-token-expires-at"]);
  };

  const isLinkedinAccountExpired = (socialAccount: SocialAccount) => {
    return Date.now() > get(socialAccount, ["metadata", "refresh-token-expires-at"]);
  };

  return (
    <ul className={styles["social-accounts"]}>
      {socialAccounts.map((socialAccount) => (
        <li key={`social-account${socialAccount.id}`} className={cx(["social-account"])}>
          <div className={styles["social-account-details"]}>
            <span className={styles["social-account-provider"]}>{getSocialShareIcon(socialAccount.provider)}</span>
            {t("social_share.connected_as")}
            <span className={styles["social-account-name"]}>{socialAccount["account-name"]}</span>
          </div>
          <div>
            {showLinkedinAccountValidity(socialAccount) && (
              <span>
                {isLinkedinAccountExpired(socialAccount) ? (
                  <>
                    <span>{t("social_share.linkedin.connection-expired")}&nbsp;</span>
                    <a href={addLinkedinAccountLink}>{t("social_share.linkedin.reconnect")}</a>
                  </>
                ) : (
                  <>
                    {t("social_share.linkedin.valid-till")} <br />
                    {formattedDate(socialAccount.metadata["refresh-token-expires-at"])}
                  </>
                )}
              </span>
            )}
          </div>
          <Switch
            id={socialAccount.id.toString()}
            checked={socialAccount["is-active"]}
            onChange={() => toggleSocialAccount(socialAccount)}
          />
          <div id={`social-account-menu-${socialAccount.id}`} className={styles["social-account-more-actions"]}>
            <ContextMenu>
              <div id={`social-account-menu-${socialAccount.id}`} className={styles["social-account-menu"]}>
                <ContextMenuList>
                  {socialAccount.provider === "linkedin" && (
                    <>
                      <a
                        className={styles["social-account-menu__linkedin-reconnect"]}
                        ref={linkedinAnchorEleRef}
                        href={addLinkedinAccountLink}>
                        Reconnect
                      </a>
                      <ContextMenuItem
                        label={t("social_share.linkedin.reconnect")}
                        onClick={linkedinReconnectHandler}
                      />
                    </>
                  )}
                  <ContextMenuItem
                    label={t("social_share.delete")}
                    onClick={() => deleteSocialAccount(socialAccount["account-id"])}
                  />
                </ContextMenuList>
              </div>
            </ContextMenu>
          </div>
        </li>
      ))}
    </ul>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    socialAccounts: state.socialShare.socialAccounts
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchProps => {
  return {
    deleteSocialAccount: (accountId) => dispatch(deleteSocialAccount(accountId)),
    toggleSocialAccount: (account) => dispatch(toggleSocialAccount(account))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialAccounts);
