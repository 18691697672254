/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";

import { Top } from "api/analytics";
import TopList from "../top-list/top-list";
import { DIMENSION } from "pages/analytics/constants";
import { ThunkDispatch } from "redux-thunk";
import { openSublist, closeSublist } from "pages/analytics/action-creators";
import { PartialAppState, CurrentFilter } from "pages/analytics/state";
import { AuthorId } from "api/primitive-types";
import { getAuthorStories } from "pages/analytics/async-action-creator";

interface Props {
  metrics: string;
  data: Top[];
  openSublist: (id: AuthorId) => void;
  isSublistOpen: boolean;
  authorStories: Top[];
  authorId: null | AuthorId;
  closeSublist: () => void;
  currentFilter: CurrentFilter;
}

const TopAuthors: React.SFC<Props> = ({
  data,
  metrics,
  openSublist,
  isSublistOpen,
  authorStories,
  authorId,
  closeSublist,
  currentFilter
}) => {
  const exceptDeletedAuthor = data.filter((author) => !author["is-deleted"]);
  return (
    <TopList
      dimension={DIMENSION.AUTHOR}
      metrics={metrics}
      list={exceptDeletedAuthor}
      openSublist={openSublist}
      isSublistOpen={isSublistOpen}
      subList={authorStories}
      openItemId={authorId}
      closeSublist={closeSublist}
      showDelta={currentFilter.dateRange["name"] !== "custom"}
    />
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
  return {
    openSublist: (authorId: AuthorId) => {
      dispatch(getAuthorStories(authorId));
      dispatch(openSublist(authorId));
    },
    closeSublist: () => dispatch(closeSublist())
  };
};

const mapStateToProps = (state: PartialAppState) => {
  return {
    isSublistOpen: state.analytics.ui.isSublistOpen,
    authorStories: state.analytics.authorStories,
    authorId: state.analytics.app.authorId
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopAuthors);

export { TopAuthors };
