/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useRef } from "react";

import ToolTip from "components/tooltip/tooltip";
import Download from "components/icons/download";
import EditNow from "components/icons/edit-now";
import Sync from "components/icons/sync";
import Trash from "components/icons/trash";
import SetFocus from "components/icons/set-focus";
import ClearFocus from "components/icons/clear-focus";
import classnames from "classnames/bind";
import { t } from "i18n";
import styles from "./image-action-bar.module.css";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "store/form/editor";
import { AnyAction } from "redux";
import { notificationError, notificationInfo } from "action-creators/notification";
import { connect } from "react-redux";
import { Image } from "api/search-media-image";
import { downloadFile } from "helpers/download-file";
import { getFileNameFromS3Key } from "utils/file.utils";
import { uniqueId } from "lodash";

const cx = classnames.bind(styles);

export class ImageAction {
  constructor(
    public type: ImageActionType,
    public onClickFn?: (e: React.MouseEvent) => void,
    public hide?: boolean,
    public disabled?: boolean,
    public error?: boolean
  ) {}
}

export enum ImageActionType {
  SetFocusPoint = "set-focus-point",
  ClearFocusPoint = "clear-focus-point",
  ReplaceImage = "replace-image",
  DeleteImage = "delete-image",
  EditImage = "photo-editor",
  DownloadImage = "download-image"
}

const getImageActionDataMap = () =>
  new Map([
    [
      ImageActionType.SetFocusPoint,
      {
        dataTestId: "set-focus-btn",
        hint: t("image_field.set_focus"),
        icon: <SetFocus width={"20"} height={"20"} color={"var(--white)"} />
      }
    ],
    [
      ImageActionType.ClearFocusPoint,
      {
        dataTestId: "clear-focus-btn",
        hint: t("image_field.clear_focus"),
        icon: <ClearFocus width={"20"} height={"20"} color={"var(--white)"} />
      }
    ],
    [
      ImageActionType.ReplaceImage,
      {
        dataTestId: "replace-btn",
        hint: t("image_field.replace"),
        icon: <Sync width={"20"} height={"20"} color={"var(--white)"} />
      }
    ],
    [
      ImageActionType.DeleteImage,
      {
        dataTestId: "delete-btn",
        hint: t("common.delete"),
        icon: <Trash width={20} height={20} color={"var(--white)"} />
      }
    ],
    [
      ImageActionType.EditImage,
      {
        dataTestId: "edit-btn",
        hint: t("image_field.photo-editor"),
        icon: <EditNow width={20} height={20} fill={"var(--white)"} />
      }
    ],
    [
      ImageActionType.DownloadImage,
      {
        dataTestId: "download-btn",
        hint: t("common.download"),
        icon: <Download width={"20"} height={"20"} color={"var(--white)"} />
      }
    ]
  ]);

type DispatchProps = {
  downloadImage: (image: Image) => Promise<void>;
};

type OwnProps = {
  actions: ImageAction[];
  classname?: string;
  image?: Image;
};

type Props = OwnProps & DispatchProps;

type ImageActionProps = {
  tabIndex: number;
  downloadImage: (image: Image) => Promise<void>;
  image?: Image;
} & ImageAction;

export const ImageActionButton: React.FC<ImageActionProps> = ({
  type,
  tabIndex,
  onClickFn,
  hide = false,
  disabled = false,
  downloadImage,
  image,
  error
}) => {
  const dataId = useRef(uniqueId());
  const actionId = `action-item-${type}-${dataId.current}`;
  const imageActionData = getImageActionDataMap().get(type);
  return (
    <>
      {!hide && (
        <button
          onClick={(e) => {
            e.stopPropagation();
            type === ImageActionType.DownloadImage && image && downloadImage(image);
            onClickFn && onClickFn(e);
          }}
          disabled={disabled}
          tabIndex={tabIndex}
          data-for={actionId}
          data-tip
          className={cx("image-action-button", { "image-action-button--error": error })}
          data-test-id={imageActionData && imageActionData.dataTestId}>
          {imageActionData && imageActionData.icon}
          <ToolTip
            id={actionId}
            place="top"
            effect="solid"
            value={`${imageActionData && imageActionData.hint}`}
            classname={styles["tooltip-image-action-button"]}
          />
        </button>
      )}
    </>
  );
};

export const ImageActionBar: React.FC<Props> = ({ actions, classname, image, downloadImage }) => {
  return (
    <div className={cx("image-action-bar", classname)}>
      {actions.map((action, index) => (
        <ImageActionButton key={action.type} {...action} tabIndex={index} image={image} downloadImage={downloadImage} />
      ))}
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => ({
  downloadImage: async (image: Image) => {
    try {
      dispatch(notificationInfo(t("common.phrases.file-download-triggered")));
      await downloadFile(
        image.url,
        getFileNameFromS3Key(image.key || (image.metadata && image.metadata["file-name"]) || "")
      );
    } catch (e) {
      dispatch(notificationError(t("common.phrases.file-download-error")));
    }
  }
});

export default connect(() => ({}), mapDispatchToProps)(ImageActionBar);
