/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Select from "components/select/select";
import { getErrorMessage } from "pages/story-editor/utils";
import Breadcrumbs from "components/breadcrumbs/breadcrumbs";

import { t } from "i18n";

const Sections = ({ value, sections, onChange, errors, disabled = false }) => (
  <Select
    label={t("story-editor.inspector.sections")}
    name="sections"
    value={value}
    options={sections}
    onChange={(value) => onChange(value)}
    getOptionLabel={(section) => section.name}
    getOptionValue={(section) => section.id}
    formatOptionLabel={(section) => (
      <React.Fragment>
        {section.name}
        <Breadcrumbs id={section.id} crumbs={sections} getCrumbLabel={(section: any) => section.name} />
      </React.Fragment>
    )}
    isMulti={true}
    errorMessage={getErrorMessage(errors)}
    isDisabled={disabled}
  />
);

export default Sections;
