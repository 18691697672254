/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { t } from "i18n";
import get from "lodash/get";

export type MediaCategory = {
  label: string;
  value: string;
  category: string;
  source?: string;
};

const getMediaCategories = (mediaSources: string[]): MediaCategory[] => {
  const allMediaCategory: MediaCategory = {
    label: t("common.all"),
    value: "media-library",
    category: "PUBLISHER_MEDIA_CATEGORIES"
  };
  const mediaCategories = [allMediaCategory];
  if (get(mediaSources, ["0"])) {
    const sourceToMediaCategory = (source: string): MediaCategory => ({
      label: source,
      value: "media-library",
      category: "PUBLISHER_MEDIA_CATEGORIES",
      source: source
    });
    const publisherMediaCategory: MediaCategory = {
      label: t("mediaLibrary.sidebar.source-filters.publisher"),
      value: "media-library",
      category: "PUBLISHER_MEDIA_CATEGORIES",
      source: "publisher"
    };
    return [...mediaCategories, publisherMediaCategory].concat(
      mediaSources.map((source) => sourceToMediaCategory(source))
    );
  }
  return mediaCategories;
};

const EXTERNAL_MEDIA_PROVIDERS = (t: Function): MediaCategory[] => [
  {
    label: t("mediaLibrary.getty"),
    value: "getty",
    category: "EXTERNAL_MEDIA_PROVIDERS"
  }
];

export { EXTERNAL_MEDIA_PROVIDERS, getMediaCategories };
