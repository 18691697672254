/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { NumberField as EmNumberField } from "@quintype/em/components/number-field";
interface NumberFieldProps {
  /** Value to render  */
  value?: number;
  /** Label for the text field */
  label: string;
  /** Secondary information  */
  hint?: string;
  /** onChange callback */
  onChange: (value?: number) => void;
  /** onBlur callback */
  onBlur?: () => void;
  /** Makes the text field read only */
  readOnly?: boolean;
  /** onKeyPress event callback */
  onKeyPress?: React.KeyboardEventHandler<any>;
  /** Placeholder for the text field */
  placeholder?: string;
  /** Error message to display */
  errorMessage?: string;
  /** Optional classname */
  classname?: string;
  /** React ref object */
  inputRef?: React.RefObject<HTMLInputElement>;
  /** Specifies the minimum value */
  min?: number;
}

const NumberField = (props: NumberFieldProps) => <EmNumberField {...props} />;
export default NumberField;
