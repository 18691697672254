/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Breadcrumbs from "components/breadcrumbs/breadcrumbs";
import { loadAuthorsData } from "pages/collections/utils";
import Select from "components/select/select";
import AsyncSelect from "components/select/async";
import RadioButton from "components/radio-button/radio-button";
import { t } from "i18n";
import { StoryTemplate } from "api/route-data/collection-route-data";
import { AnyAutomatedCollection, RulesSortAutomatedResultBy, AnyCollection } from "api/story-collection";
import StoryAttributeFilter from "pages/workspace/components/filters/story-attribute-filter/story-attribute-filter";
import { Section } from "api/route-data/route-data";
import { Attribute } from "api/story-attributes";
import FieldLabel from "components/field-label/field-label";
import Location from "components/location/location";
import { Switch } from "components/switch/switch";
import styles from "./rules.module.css";
import Tags from "pages/story-editor/components/manage-fields/tags";
import { Tag } from "api/tag";
import { Entity } from "api/entity";

interface SortedByOption {
  id: string;
  name: string;
}

const SORTED_BY_OPTIONS: SortedByOption[] = [
  {
    id: "latest-published",
    name: "Latest Published"
  },
  {
    id: "24",
    name: "Trending (24 hours)"
  }
];

const getSeriesAttribute = (storyAttributes: Attribute[]) => {
  return (
    storyAttributes &&
    storyAttributes.find((el) => {
      const attribute = el as Attribute;
      return attribute.name === "series";
    })
  );
};

const getSeriesAttributeValues = (storyAttributes: Attribute[]) => {
  const seriesAttribute = getSeriesAttribute(storyAttributes);
  return (seriesAttribute && seriesAttribute.values) || [];
};

const getMultiValueStoryAttributes = (storyAttributes: Attribute[]) => {
  return (
    storyAttributes &&
    storyAttributes.filter((attribute) => {
      return attribute.type === "story" && attribute["data-type"] === "multi-valued-strings";
    })
  );
};

const getSortedByValue = ({ rules }: AnyAutomatedCollection) => {
  if (rules) {
    return rules["sort-automated-result"] === RulesSortAutomatedResultBy.Trending
      ? SORTED_BY_OPTIONS.filter((option) => option.id === rules["trending-duration-hours"])
      : SORTED_BY_OPTIONS.filter((option) => option.id === (rules["sort-automated-result"] || "latest-published"));
  } else {
    return [];
  }
};

const selectedStoryAttributesAsArray = (stagingSelectedCollection: AnyCollection, allAttributes: Attribute[]) => {
  const selectedStoryAttributes =
    stagingSelectedCollection["expanded-rules"] && stagingSelectedCollection["expanded-rules"]["story-attributes"];
  return selectedStoryAttributes
    ? Object.keys(selectedStoryAttributes).reduce((attributesAsArray: Attribute[], attributeName: string) => {
        const attribute = allAttributes.find((attr) => attr.name === attributeName);
        if (attribute) {
          attributesAsArray.push({ ...attribute, values: selectedStoryAttributes[attributeName] });
        }
        return attributesAsArray;
      }, [])
    : [];
};

const separateTagsAndEntities = (updateStagingSelectedCollectionRules: (key: string, value: any) => void, tags: []) => {
  const [justTags, justEntities] = tags.reduce(
    ([justTags, justEntities], item) =>
      !item["entity-type-id"] ? [[...justTags, item], justEntities] : [justTags, [...justEntities, item]],
    [[], []]
  );
  updateStagingSelectedCollectionRules("tags", justTags);
  updateStagingSelectedCollectionRules("entities", justEntities);
};

const fetchTagSelectValue = (stagingSelectedCollection: AnyCollection): Array<Tag | Entity> => {
  const tags = stagingSelectedCollection["expanded-rules"] && stagingSelectedCollection["expanded-rules"].tags;
  const entities = stagingSelectedCollection["expanded-rules"] && stagingSelectedCollection["expanded-rules"].entities;
  if (tags && entities) {
    return tags.concat(entities);
  } else if (tags) {
    return tags;
  } else if (entities) {
    return entities;
  } else {
    return [];
  }
};

interface Props {
  stagingSelectedCollection: AnyCollection;
  updateStagingSelectedCollectionRules: (key: string, value: any) => void;
  updateStagingSelectedCollection: (key: string, value: any) => void;
  sections: Section[];
  storyAttributes: Attribute[];
  storyTemplates: StoryTemplate[];
  isRecommendedCollectionEnabled: boolean;
  isAnalyticsTrendingCollectionEnabled: boolean;
  isLocationForStoriesEnabled: boolean;
  googleMapsApiKey: string;
  togglePolygonSelection: () => void;
  updateLocationFilterToggleAction: (value: boolean) => void;
}

const Rules: React.SFC<Props> = ({
  stagingSelectedCollection,
  updateStagingSelectedCollectionRules,
  updateStagingSelectedCollection,
  sections,
  storyAttributes,
  storyTemplates,
  isRecommendedCollectionEnabled,
  isAnalyticsTrendingCollectionEnabled,
  isLocationForStoriesEnabled,
  googleMapsApiKey,
  togglePolygonSelection,
  updateLocationFilterToggleAction
}) => (
  <div className={styles["collections-rules-settings"]}>
    <FieldLabel label={t("collections.rules.type")} />
    <div className={styles["collection-type-group"]} data-test-id="collection-type-group">
      <div className={styles["collection-type"]}>
        <RadioButton
          id="collections-rules-automated"
          label={t("collections.rules.automated")}
          name="rules"
          checked={stagingSelectedCollection["data-source"] === "automated"}
          onChange={(value) => {
            updateStagingSelectedCollection("automated", value);
            value && updateStagingSelectedCollection("data-source", "automated");
          }}
        />
      </div>
      <div className={styles["collection-type"]}>
        <RadioButton
          id="collections-rules-manual"
          label={t("collections.rules.manual")}
          name="rules"
          checked={stagingSelectedCollection["data-source"] === "manual"}
          onChange={(value) => {
            updateStagingSelectedCollection("automated", false);
            stagingSelectedCollection["expanded-rules"] && updateStagingSelectedCollection("expanded-rules", {});
            updateStagingSelectedCollection("rules", {});
            value && updateStagingSelectedCollection("data-source", "manual");
          }}
        />
      </div>
      {isRecommendedCollectionEnabled && (
        <div className={styles["collection-type"]}>
          <RadioButton
            id="collections-rules-recommended"
            label={t("collections.rules.recommended")}
            name="rules"
            checked={stagingSelectedCollection["data-source"] === "recommended"}
            onChange={(value) => {
              updateStagingSelectedCollection("automated", false);
              value && updateStagingSelectedCollection("data-source", "recommended");
            }}
          />
        </div>
      )}
    </div>
    {stagingSelectedCollection["data-source"] === "automated" && (
      <React.Fragment>
        <h3 className={styles["collection-rules-title"]}>{t("collections.rules.collection_rules")}</h3>
        {stagingSelectedCollection["template"] !== "series" && (
          <div className={styles["collection-settings-rule"]}>
            <Tags
              value={fetchTagSelectValue(stagingSelectedCollection)}
              onTagChange={(tags: any) => {
                separateTagsAndEntities(updateStagingSelectedCollectionRules, tags);
              }}
              errors={null}
              hasTagCreatePermission={false}
            />
          </div>
        )}
        {stagingSelectedCollection["template"] !== "series" && (
          <div className={styles["collection-settings-rule"]}>
            <Select
              name="sections"
              value={
                stagingSelectedCollection["expanded-rules"] && stagingSelectedCollection["expanded-rules"].sections
              }
              options={sections}
              onChange={(sections) => updateStagingSelectedCollectionRules("sections", sections)}
              getOptionLabel={(section) => section.name}
              getOptionValue={(section) => section.id.toString()}
              label={t("collections.rules.sections")}
              formatOptionLabel={(section) => (
                <React.Fragment>
                  {section.name}
                  <Breadcrumbs id={section.id} crumbs={sections} getCrumbLabel={(section) => section.name} />
                </React.Fragment>
              )}
              isMulti={true}
            />
          </div>
        )}
        {stagingSelectedCollection["template"] !== "series" && (
          <div className={styles["collection-settings-rule"]}>
            <Select
              name="exclude-sections"
              value={
                stagingSelectedCollection["expanded-rules"] &&
                stagingSelectedCollection["expanded-rules"]["exclude-sections"]
              }
              options={sections}
              onChange={(sections) => updateStagingSelectedCollectionRules("exclude-sections", sections)}
              getOptionLabel={(section) => section.name}
              getOptionValue={(section) => section.id.toString()}
              label={t("collections.rules.exclude-sections")}
              formatOptionLabel={(section) => (
                <React.Fragment>
                  {section.name}
                  <Breadcrumbs id={section.id} crumbs={sections} getCrumbLabel={(section) => section.name} />
                </React.Fragment>
              )}
              isMulti={true}
            />
          </div>
        )}
        {stagingSelectedCollection["template"] === "series" && (
          <div className={styles["collection-settings-rule"]}>
            <Select
              name="series"
              value={
                stagingSelectedCollection["expanded-rules"] &&
                stagingSelectedCollection["expanded-rules"]["story-attributes"] &&
                stagingSelectedCollection["expanded-rules"]["story-attributes"]["series"]
              }
              options={getSeriesAttributeValues(storyAttributes)}
              onChange={(series) => {
                const seriesAttribute = getSeriesAttribute(storyAttributes);
                updateStagingSelectedCollectionRules("story-attributes", [{ ...seriesAttribute, values: [series] }]);
              }}
              getOptionLabel={(seriesEl) => seriesEl}
              getOptionValue={(seriesEl) => seriesEl}
              label={t("collections.rules.story_attributes.series")}
              isMulti={false}
            />
          </div>
        )}
        {stagingSelectedCollection["template"] !== "series" && (
          <div className={styles["collection-settings-rule"]}>
            <Select
              value={
                stagingSelectedCollection["expanded-rules"] &&
                stagingSelectedCollection["expanded-rules"]["story-template"]
              }
              options={storyTemplates.map((template) => template.slug)}
              onChange={(storyTemplate) => updateStagingSelectedCollectionRules("story-template", storyTemplate)}
              getOptionLabel={(storyTemplate) => storyTemplate}
              getOptionValue={(storyTemplate) => storyTemplate}
              label={t("collections.rules.story_templates")}
              isMulti={true}
            />
          </div>
        )}
        {stagingSelectedCollection["template"] !== "series" && (
          <div className={styles["collection-settings-rule"]}>
            <AsyncSelect
              value={stagingSelectedCollection["expanded-rules"] && stagingSelectedCollection["expanded-rules"].authors}
              onChange={(author) => updateStagingSelectedCollectionRules("authors", author)}
              getOptionLabel={(author) => author.name}
              getOptionValue={(author) => author.id}
              loadOptions={loadAuthorsData}
              isMulti={true}
              label={t("collections.rules.authors")}
              defaultOptions={true}
              cacheOptions={true}
            />
          </div>
        )}

        {stagingSelectedCollection["template"] !== "series" && storyAttributes.length > 0 && (
          <StoryAttributeFilter
            value={selectedStoryAttributesAsArray(stagingSelectedCollection, storyAttributes)}
            storyAttributes={getMultiValueStoryAttributes(storyAttributes)}
            onChange={(attributes: Attribute[]) => {
              updateStagingSelectedCollectionRules("story-attributes", attributes);
            }}
          />
        )}

        {isAnalyticsTrendingCollectionEnabled && stagingSelectedCollection["template"] !== "series" && (
          <div className={styles["collection-settings-rule"]}>
            <Select
              label={t("collections.rules.sorted_by")}
              onChange={(option: SortedByOption) => {
                if (option.id === "latest-published") {
                  updateStagingSelectedCollectionRules("sort-automated-result", "latest-published");
                  updateStagingSelectedCollectionRules("trending-duration-hours", null);
                } else {
                  updateStagingSelectedCollectionRules("sort-automated-result", "trending");
                  updateStagingSelectedCollectionRules("trending-duration-hours", option.id);
                }
              }}
              value={getSortedByValue(stagingSelectedCollection)}
              options={SORTED_BY_OPTIONS}
              getOptionLabel={(s: SortedByOption) => s.name}
              getOptionValue={(s: SortedByOption) => s.id}
            />
          </div>
        )}
        {isLocationForStoriesEnabled && (
          <div data-test-id="geo-location-rule">
            <FieldLabel label={t("common.location")} />
            <div className={styles["location-toggle-container"]} data-test-id="geo-location-rule-toggle-container">
              <p className={styles["toggle-label"]}>{t("collections.rules.filter_enable")}</p>
              <Switch
                checked={
                  (stagingSelectedCollection.rules &&
                    stagingSelectedCollection.rules.location &&
                    stagingSelectedCollection.rules.location["is-enabled"]) ||
                  false
                }
                onChange={(value: boolean) => updateLocationFilterToggleAction(value)}
              />
            </div>
            {stagingSelectedCollection.rules &&
              stagingSelectedCollection.rules.location &&
              stagingSelectedCollection.rules.location["is-enabled"] && (
                <React.Fragment>
                  <Location
                    className={styles["rule-location-container"]}
                    polygonVertices={stagingSelectedCollection.rules.location["geo-polygon"]}
                    enableSearchBox={false}
                    enableMarkerCreation={false}
                  />
                  <div
                    className={styles["collection-settings-rule-button-container"]}
                    data-test-id="set-polygon-boundary-button">
                    <span
                      className={styles["collection-settings-rule-location-button"]}
                      onClick={togglePolygonSelection}>
                      {t("collections.rules.set_boundary")}
                    </span>
                  </div>
                </React.Fragment>
              )}
          </div>
        )}
      </React.Fragment>
    )}
  </div>
);

export default Rules;
