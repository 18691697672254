/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";

import Inspector from "components/inspector/inspector";
import MediaGallery from "pages/media-library/components/media-gallery/media-gallery";

interface Props {
  isActive: boolean;
  closeGallery: () => void;
  setSelectedMedia: (image: any) => void;
}

const BreakingNewsDefaultsInspector: React.SFC<Props> = ({ isActive, setSelectedMedia, closeGallery }) => (
  <Inspector
    title={t("settings.breaking-news-defaults.media-gallery-title")}
    onClose={closeGallery}
    isActive={isActive}
    isActionButtonDisabled={true}
    variant="wide">
    {isActive && (
      <MediaGallery
        showSelectMediaProvider={true}
        setSelectedMedia={setSelectedMedia}
        scrollableTarget="inspector-container"
      />
    )}
  </Inspector>
);

export default BreakingNewsDefaultsInspector;
