/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

import styles from "./settings-card.module.css";

type Props = {
  title: string;
  subtitle?: string;
  children: Array<React.ReactNode> | React.ReactNode;
};

export const SettingsCard: React.SFC<Props> = ({ title, children }) => (
  <section className={styles["container"]}>
    <div>
      <h3 className={styles["title"]} data-test-id="settings-card-title">
        {title}
      </h3>
    </div>
    <div>{children}</div>
  </section>
);
