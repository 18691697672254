/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { PublisherId, MenuGroupId } from "./primitive-types";
import { MenuItemsListResponse } from "./menu-item";

export interface MenuGroup {
  id: MenuGroupId;
  "publisher-id": PublisherId;
  name: string;
  slug: string;
}

export interface UnsavedMenuGroup {
  name: string;
  slug: string;
}

export interface SavedMenuGroup {
  id: MenuGroupId;
  name: string;
  slug: string;
}

export type MenuGroupList = Array<MenuGroup> | [];

export const getMenuGroupList = (): Promise<MenuGroupList> => {
  return client
    .url("/api/menu-group")
    .get()
    .json();
};

export const saveMenuGroup = (menu: UnsavedMenuGroup): Promise<SavedMenuGroup> => {
  return client
    .url("/api/menu-group")
    .json(menu)
    .post()
    .json();
};

export const getMenuItems = (menuId: MenuGroupId): Promise<MenuItemsListResponse> => {
  return client
    .url(`/api/menu-groups/${menuId}/menu-items`)
    .get()
    .json();
};
