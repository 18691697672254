/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const ImageGallery = ({ width = 24, height = 24, color = "#c6ccd5" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} fill={color}>
    <path
      d="M4.84,18.29A1.2,1.2,0,0,0,6,19.48H16.86v1H6a2.15,2.15,0,0,1-2.15-2.15V10.14h1ZM18.21,4.13A1.9,1.9,0,0,1,20.12,6V16A1.9,1.9,0,0,1,18.21,18h-10A1.91,1.91,0,0,1,6.29,16V6A1.91,1.91,0,0,1,8.21,4.13ZM7.78,16.87l2.43-3.14L11.81,15l.48-.58-2.2-1.79L7.3,16.25a1.3,1.3,0,0,1,0-.21V6a1,1,0,0,1,1-1h10a1,1,0,0,1,.95,1v7.18l-2.67-3L10.92,17H8.21a.89.89,0,0,1-.43-.12M18.21,17H11.89l4.62-5.6,2.6,2.91.05,0V16a1,1,0,0,1-.95.95"
      fillRule="evenodd"
    />
  </svg>
);

export default ImageGallery;
