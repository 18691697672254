/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ExtendableError } from "./error.utils";
import * as validateJS from "validate.js";
import { set, chain } from "lodash";
import { t } from "i18n";

type ValidationErrors<T> = T extends object ? { [K in keyof T]?: ValidationErrors<T[K]> } : string[];

export type ValidationResult<T> = undefined | ValidationErrors<T>;

export class ClientValidationError<T> extends ExtendableError {
  errors: ValidationErrors<T>;

  constructor(errors: ValidationErrors<T>) {
    super();
    this.errors = errors;
  }
}

export function toClientValidationError<T>(errors: ValidationErrors<T>) {
  return new ClientValidationError(errors);
}

export function isClientValidationError<T>(error: Error | null): error is ClientValidationError<T> {
  return error instanceof ClientValidationError;
}

export class ServerValidationError<T> extends ExtendableError {
  errors: ValidationErrors<T>;

  constructor(errors: ValidationErrors<T>) {
    super();
    this.errors = errors;
  }
}

export function toServerValidationError<T>(errors: ValidationErrors<T>) {
  return new ServerValidationError(errors);
}

validateJS.validators.emptiness = function(value: any) {
  if (value === null || value === undefined) {
    return undefined;
  }
  return t("errors.validations.not-empty");
};

validateJS.validators.urlAllowEmpty = function(value: string, options: any, attribute: any, attributes: any) {
  if (validateJS.isEmpty(value)) {
    return;
  }
  return validateJS.validators.url(value, options, attribute, attributes);
};

validateJS.validators.urlEnforceLabelLimit = function(value: string, options: any, attribute: any, attributes: any) {
  // validateJs does not properly follow the spec (https://gist.github.com/dperini/729294) its URL validation is based on
  // label lengths should be capped at 63 characters
  const invalidResourceNameParts = chain(value)
    .split("//")
    .last()
    .split("/")
    .head()
    .split(".")
    .value()
    .filter((str) => str.length > 63 || str.length < 2);
  if (invalidResourceNameParts.length > 0) return options.message || "is not a valid url";
  return validateJS.validators.url(value, options, attribute, attributes);
};

/*
Wrapper over validate.js, which does a few things:

1. Type safety - validate.js returns an <any> type, which won't work for us.
2. Handling nested keys - validate.js requires nested keys in the constraints to be defined
    with the dot notation, and returns errors in the same format. We need to transform it to
    the structure of the attributes.

*/
export function validate<T extends object>(
  attributes: T,
  constraints: object,
  options?: validateJS.ValidateOption
): ValidationResult<T> {
  const rawResult = validateJS.validate(attributes, constraints, options);

  if (rawResult === undefined) {
    return undefined;
  }

  let result = {};

  for (const key in rawResult) {
    if (rawResult.hasOwnProperty(key)) {
      set(result, key, rawResult[key]);
    }
  }

  return result as ValidationResult<T>;
}
