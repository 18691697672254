/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { BridgekeeperConfig, Publisher } from "api/route-data/route-data";
import { QrData } from "api/tfa";
import { INITIAL_LOADER_STATE, LoaderState } from "behaviors/loader/state";
export interface PartialAppState {
  login: State;
}

export interface State {
  app: {
    username: string;
    password: string;
    emailToResetPassword: string;
    newPassword: string;
    confirmPassword: string;
  };
  twoFactorAuth: {
    token: QrData | null;
    otp: string;
    totp: string;
  };
  config: { publisher: Publisher | {}; bridgekeeper?: BridgekeeperConfig; publisherWideBannerMessage: string | null };
  features: { isBridgekeeperIntegrationEnabled?: boolean };
  ui: {
    isFormLogin: boolean;
    error: Error | null;
    isSignInDisabled: boolean;
    loader: LoaderState;
  };
}

export const INITIAL_LOGIN_STATE: State = {
  app: {
    username: "",
    password: "",
    emailToResetPassword: "",
    newPassword: "",
    confirmPassword: ""
  },
  twoFactorAuth: {
    token: null,
    otp: "",
    totp: ""
  },
  config: { publisher: {}, publisherWideBannerMessage: null },
  features: { isBridgekeeperIntegrationEnabled: false },
  ui: {
    isFormLogin: false,
    error: null,
    isSignInDisabled: false,
    loader: INITIAL_LOADER_STATE
  }
};
