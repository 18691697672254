/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const QuestionAndAnswer = ({ width = "24", height = "24", color = "#c6ccd5" }) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    fill={color}>
    <path d="M9.19 6.72a1.3 1.3 0 0 1 1-.39 1.31 1.31 0 0 1 .94.33 1.1 1.1 0 0 1 .36.85.88.88 0 0 1-.13.51 3.37 3.37 0 0 1-.53.57 1.6 1.6 0 0 0-.37.47 1.33 1.33 0 0 0-.09.56h-.53a2.08 2.08 0 0 1 .1-.68 1.88 1.88 0 0 1 .45-.59l.24-.24a1.18 1.18 0 0 0 .18-.21.76.76 0 0 0-.06-.9.71.71 0 0 0-.58-.21.72.72 0 0 0-.7.36 1.49 1.49 0 0 0-.12.59h-.53a1.39 1.39 0 0 1 .37-1.02zm.64 4h.59v-.62h-.59z" />
    <path d="M3 17.43V4.82A1.83 1.83 0 0 1 4.83 3H15.6a1.83 1.83 0 0 1 1.83 1.82v7.41a1.83 1.83 0 0 1-1.83 1.83h-8a2.85 2.85 0 0 0-2 .84zM4.83 4a.87.87 0 0 0-.83.82v10.3l.9-.9a3.8 3.8 0 0 1 2.7-1.12h8a.87.87 0 0 0 .87-.87V4.82A.87.87 0 0 0 15.6 4z" />
    <path d="M21 20.62l-2.53-2.53a2.85 2.85 0 0 0-2-.84H9.59v-1h6.85a3.8 3.8 0 0 1 2.7 1.12l.9.9V9.21h1z" />
  </svg>
);

export default QuestionAndAnswer;
