/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Panel: React.SFC = ({ children }) => <React.Fragment>{children}</React.Fragment>;

export default Panel;
