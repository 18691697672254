/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { t } from "i18n";
import { ConnectionState } from "helpers/ably/types";

const MESSAGES = (t) => ({
  CONNECTING: t("story-editor.lock_story_messages.connecting"),
  SAVE_CHANGES: t("story-editor.lock_story_messages.save_changes"),
  VERSION_MISMATCH: t("story-editor.lock_story_messages.version_mismatch"),
  IM_EDITING: t("story-editor.lock_story_messages.i_am_editing"),
  START_EDITING: t("story-editor.lock_story_messages.start_editing"),
  SOMEONE_ELSE_IS_EDITING: (member) => `${member.name} ${t("story-editor.lock_story_messages.is_editing")}`
});

function lockStoryForEdit(
  isCurrentStateReadOnly,
  shouldNextStateBeReadOnly,
  isStoryModified,
  isCurrentStoryVersionTheLatest
) {
  if (isCurrentStateReadOnly === shouldNextStateBeReadOnly) {
    return { readOnly: isCurrentStateReadOnly, useVersion: "current" };
  }

  if (!isCurrentStateReadOnly && shouldNextStateBeReadOnly && isStoryModified && isCurrentStoryVersionTheLatest) {
    return { readOnly: shouldNextStateBeReadOnly, useVersion: "current" };
  }

  if (isStoryModified && !isCurrentStoryVersionTheLatest) {
    return { readOnly: isCurrentStateReadOnly, message: MESSAGES(t).VERSION_MISMATCH, useVersion: "current" };
  }

  if (!isStoryModified && !isCurrentStoryVersionTheLatest) {
    return { readOnly: shouldNextStateBeReadOnly, useVersion: "latest" };
  }

  return { readOnly: shouldNextStateBeReadOnly, useVersion: "current" };
}

export type LockStoryUiState = {
  lockColor: "black" | "green";
  lockStatus: "open" | "closed";
  switchToggled: boolean;
  buttonEnabled: boolean;
  message: string;
  amIEditing: boolean;
  isSomeoneEditing: boolean;
  connectionState: ConnectionState;
};

function lockStoryForEditUI(
  members,
  currentMember,
  isUnsavedStory,
  connectivityState: ConnectionState
): LockStoryUiState {
  const didIJustOpenTheStory = members.length === 0,
    whoIsEditing = members.filter((member) => member.isEditing)[0],
    isSomeoneEditing = !!whoIsEditing || !!isUnsavedStory,
    amIEditing = !!(currentMember && currentMember.isEditing) || !!isUnsavedStory,
    unnamedCondition = !isSomeoneEditing || amIEditing;

  if (didIJustOpenTheStory && !isUnsavedStory) {
    return {
      lockColor: "black",
      lockStatus: "closed",
      message: MESSAGES(t).CONNECTING,
      buttonEnabled: false,
      switchToggled: false,
      amIEditing: false,
      isSomeoneEditing: false,
      connectionState: ConnectionState.CONNECTING
    };
  }

  return {
    lockColor: isSomeoneEditing ? "black" : "green",
    lockStatus: amIEditing ? "open" : "closed",
    switchToggled: amIEditing,
    amIEditing,
    isSomeoneEditing: !amIEditing,
    buttonEnabled: unnamedCondition && !isUnsavedStory && connectivityState === ConnectionState.CONNECTED,
    message: isSomeoneEditing
      ? amIEditing
        ? MESSAGES(t).IM_EDITING
        : MESSAGES(t).SOMEONE_ELSE_IS_EDITING(whoIsEditing)
      : MESSAGES(t).START_EDITING,
    connectionState: connectivityState
  };
}

function updateCurrentMemberIsEditing(action, { members, currentMember }, storyStatus) {
  if (!currentMember) {
    return;
  }

  if (action === "enter") {
    // I'm the only person in the story, and I'm not editing it, so make me the editor.
    if (members && members.length === 1 && members[0].clientId === currentMember.clientId && !currentMember.isEditing) {
      if (storyStatus === "published" || storyStatus === "scheduled") {
        return { ...currentMember, isEditing: false };
      } else {
        return { ...currentMember, isEditing: true };
      }
    }
  }

  if (members.length > 1 && members.filter((member) => member.isEditing).length > 1 && currentMember.isEditing) {
    // If at any point there are more than one editors in member list
    // update the isEditing of currentMember to false
    return { ...currentMember, isEditing: false };
  }

  return currentMember;
}

const sortMembersByOpenedAt = (memberA, memberB) => memberA.openedAt - memberB.openedAt;
const putEditorFirstAndSortMembersByOpenedAt = (memberA, memberB) => {
  if (memberA.isEditing) {
    return -1;
  } else if (memberB.isEditing) {
    return 1;
  } else {
    return sortMembersByOpenedAt(memberA, memberB);
  }
};

function connectionStateBannerUI(connectionState: ConnectionState) {
  return connectionState === ConnectionState.CONNECTED
    ? {
        disableWorkflowButtons: false,
        banner: {
          type: "success",
          message: t("story-editor.lock_story_messages.connectivity-restored"),
          closable: true,
          autoClose: true
        }
      }
    : {
        disableWorkflowButtons: true,
        banner: {
          type: "error",
          message: t("story-editor.lock_story_messages.no-connectivity"),
          closable: false,
          autoClose: false
        }
      };
}

export {
  lockStoryForEdit,
  lockStoryForEditUI,
  updateCurrentMemberIsEditing,
  MESSAGES,
  connectionStateBannerUI,
  sortMembersByOpenedAt,
  putEditorFirstAndSortMembersByOpenedAt
};
