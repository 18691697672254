/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import Checkbox from "components/checkbox/checkbox";
import Button from "components/button/button";
import { t } from "i18n";
import { validate } from "utils/validation.utils";

import styles from "./link.module.css";

const cx = classnames.bind(styles);
interface Props {
  setLink: (href: string, isNoFollow?: boolean) => void;
  attrs: {
    href?: string;
    rel?: boolean;
  };
}

interface State {
  url: string;
  error: boolean;
  isNoFollow: boolean;
}

class Link extends React.Component<Props, State> {
  linkInput: React.RefObject<HTMLInputElement>;
  linkContainer: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      url: props.attrs.href ? props.attrs.href : "",
      error: false,
      isNoFollow: props.attrs.rel ? props.attrs.rel : false
    };

    this.linkInput = React.createRef();
    this.linkContainer = React.createRef();
  }

  componentDidMount() {
    this.linkInput.current && this.linkInput.current.focus();
  }

  getValidURL = (url: string): string | null => {
    const URLHasSpaces = /\s/g.test(url);
    if (!url || URLHasSpaces) return null;

    let link;
    link = url.trim();

    link = url.replace(/^http:/i, "http:");
    link = url.replace(/^https:/i, "https:");

    let protocolsInURL = link.split(":")[0];

    if (protocolsInURL === "https" || protocolsInURL === "http") {
      return this.isValidURL(link) ? link : null;
    } else {
      let modifiedLink = "https://" + link;
      this.setState({
        url: modifiedLink
      });
      return this.isValidURL(modifiedLink) ? modifiedLink : null;
    }
  };

  isValidURL = (url: string): boolean => {
    try {
      const validationErrors = validate({ link: url }, { link: { url: true } });
      return !validationErrors;
    } catch (err) {
      return false;
    }
  };

  validateAndSubmitLink = () => {
    if (this.state.url.length > 0) {
      const validURL = this.getValidURL(this.state.url);
      if (validURL) {
        this.setState({ error: false });
        this.props.setLink(validURL, this.state.isNoFollow);
      } else {
        this.setState({ error: true });
      }
    } else {
      this.props.setLink("");
    }
  };

  cancelLink = () => {
    this.props.setLink("");
  };

  handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.charCode === 13) {
      this.validateAndSubmitLink();
    }
    if (e.charCode === 27) {
      this.cancelLink();
    }
  };

  handleURLChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ url: e.target.value });
    this.setState({ error: false });
  };
  toggleNoFollow = () => this.setState((prevState) => ({ isNoFollow: !prevState.isNoFollow }));

  preventEventFlowToLinkContainer = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    var inputClasses = cx("link-tag-input", { "link-tag-input--error": this.state.error });

    return (
      <div
        ref={this.linkContainer}
        className={styles["link-tag-container"]}
        tabIndex={0}
        onClick={() => {
          if (this.linkContainer.current) {
            this.linkContainer.current.focus();
          }
        }}>
        <div className={styles["link-tag-wrapper"]}>
          <input
            onClick={this.preventEventFlowToLinkContainer}
            className={inputClasses}
            type="text"
            value={this.state.url}
            onKeyPress={this.handleKeyPress}
            ref={this.linkInput}
            onChange={this.handleURLChange}
            placeholder={t("link.url-placeholder")}
          />
        </div>
        <div className={styles["link-tag-actions"]}>
          <div>
            <Checkbox
              label={t("link.no-follow")}
              checked={this.state.isNoFollow}
              onChange={this.toggleNoFollow}
              id="link-tag-checkbox--no-follow"
            />
          </div>
          <div className={styles["link-tag-button-container"]}>
            <div className={styles["link-tag-action-button"]}>
              <Button type="secondary" onClick={() => this.cancelLink()}>
                {this.props.attrs.href && this.props.attrs.href.length > 1 ? t("common.reset") : t("common.cancel")}
              </Button>
            </div>
            <div className={styles["link-tag-action-button"]}>
              <Button type="primary" onClick={() => this.validateAndSubmitLink()}>
                {t("common.ok")}
              </Button>
            </div>
          </div>
        </div>
        {this.state.error && <div className={styles["link-tag-error"]}>{t("story-editor.url")}</div>}
      </div>
    );
  }
}

export default Link;
