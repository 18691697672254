/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AssociatedStoryFeature, AssociatedStoryDefaults, ValidationErrors } from "api/breaking-news-defaults";

export enum actions {
  FETCH_ASSOCIATED_STORY_DEFAULTS = "@@settings/FETCH_ASSOCIATED_STORY_DEFAULTS",
  ASSOCIATED_STORY_DEFAULTS_FETCH_SUCCESS = "@@settings/ASSOCIATED_STORY_DEFAULTS_FETCH_SUCCESS",
  ASSOCIATED_STORY_DEFAULTS_FETCH_ERROR = "@@settings/ASSOCIATED_STORY_DEFAULTS_FETCH_ERROR",
  UPDATE_ASSOCIATED_STORY_DEFAULTS = "@@settings/UPDATE_ASSOCIATED_STORY_DEFAULTS",
  ASSOCIATED_STORY_DEFAULT_IMAGE_UPLOAD = "@@settings/ASSOCIATED_STORY_DEFAULT_IMAGE_UPLOAD",
  SAVE_ASSOCIATED_STORY_DEFAULTS = "@@settings/SAVE_ASSOCIATED_STORY_DEFAULTS",
  ASSOCIATED_STORY_DEFAULTS_SAVE_SUCCESS = "@@settings/ASSOCIATED_STORY_DEFAULTS_SAVE_SUCCESS",
  ASSOCIATED_STORY_DEFAULTS_SAVE_ERROR = "@@setting/ASSOCIATED_STORY_DEFAULTS_SAVE_ERROR"
}

export type fetchAssociatedStoryDefaults = {
  type: actions.FETCH_ASSOCIATED_STORY_DEFAULTS;
};

export type associatedStoryDefaultsFetchSuccess = {
  type: actions.ASSOCIATED_STORY_DEFAULTS_FETCH_SUCCESS;
  payload: {
    associatedStoryFeature: AssociatedStoryFeature;
  };
};

export type associatedStoryDefaultsFetchError = {
  type: actions.ASSOCIATED_STORY_DEFAULTS_FETCH_ERROR;
};

export type updateAssociatedStoryDefaults = {
  type: actions.UPDATE_ASSOCIATED_STORY_DEFAULTS;
  payload: {
    updatedAssociatedStoryDefaults: AssociatedStoryDefaults;
  };
};

export type saveAssociatedStoryDefaults = {
  type: actions.SAVE_ASSOCIATED_STORY_DEFAULTS;
};

export type associatedStoryDefaultsSaveSuccess = {
  type: actions.ASSOCIATED_STORY_DEFAULTS_SAVE_SUCCESS;
};

export type associatedStoryDefaultsSaveError = {
  type: actions.ASSOCIATED_STORY_DEFAULTS_SAVE_ERROR;
  payload: {
    errors: ValidationErrors | null;
  };
};

export type ActionTypes =
  | fetchAssociatedStoryDefaults
  | associatedStoryDefaultsFetchSuccess
  | associatedStoryDefaultsFetchError
  | updateAssociatedStoryDefaults
  | saveAssociatedStoryDefaults
  | associatedStoryDefaultsSaveSuccess
  | associatedStoryDefaultsSaveError;

export default actions;
