/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import { Key } from "ts-key-enum";
import paginate, { Pager } from "simple-pagination";
import Chevron from "components/icons/chevron";
import { t } from "i18n";
import styles from "./table-pagination.module.css";

interface Props {
  specifiedPage: number;
  perPage: number;
  totalCount: number;
  onPageChange: (page: number, direction: string) => void;
  disableTotalCounts?: boolean;
  disableInput?: boolean;
  classname?: string;
}

interface State {
  initialProps: Props;
  pager: Pager;
}

const cx = classnames.bind(styles);

export default class TablePagination extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const { totalCount, perPage, specifiedPage } = props;
    this.state = {
      initialProps: props,
      pager: paginate(totalCount, perPage, specifiedPage)
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.specifiedPage !== this.props.specifiedPage) {
      this.setState({ pager: paginate(this.props.totalCount, this.props.perPage, this.props.specifiedPage) });
    }

    if (prevProps.totalCount !== this.props.totalCount) {
      this.setState({ pager: paginate(this.props.totalCount, this.props.perPage, this.props.specifiedPage) });
    }
  }

  onPageChange(pager: Pager, direction: string = "input") {
    const currentPage = pager.currentPage;
    if (currentPage && Number.isSafeInteger(currentPage)) {
      this.props.onPageChange(currentPage, direction);
      this.setState({ pager: { ...pager, specifiedPage: currentPage } });
    }
  }

  updatePager(specifiedPage: number, direction: string = "input") {
    if (this.props.specifiedPage !== specifiedPage) {
      const pager = paginate(this.props.totalCount, this.props.perPage, specifiedPage);
      this.setState({ pager }, () => {
        this.onPageChange(pager, direction);
      });
    }
  }

  applyPage() {
    this.updatePager(this.state.pager.specifiedPage);
  }

  onChange(specifiedPage: string) {
    if (specifiedPage !== "") {
      const pager = paginate(this.props.totalCount, this.props.perPage, parseInt(specifiedPage, 10));
      this.setState({ pager });
    }
  }

  render() {
    const pageDirection = document.documentElement && document.documentElement.dir;
    const { isFirstPage, isLastPage, specifiedPage, currentPage, pageCount } = this.state.pager;
    return (
      <div className={cx("-pagination", this.props.classname)} data-test-id="pagination">
        {!this.props.disableTotalCounts && (
          <bdi className={styles["-totalCount"]} data-test-id="total-count">
            {t("entities.number_of_item", { count: this.props.totalCount })}
          </bdi>
        )}
        <div className={styles["-previous"]}>
          <div
            className={cx("-prevButton", { disabled: isFirstPage })}
            data-test-id="previous-btn"
            onClick={() => currentPage && !isFirstPage && this.updatePager(currentPage - 1, "prev")}>
            <Chevron variant={pageDirection && pageDirection === "rtl" ? "right" : "left"} />
          </div>
        </div>
        <div className={styles["-center"]}>
          <span className={styles["-pageInfo"]}>
            <div className={styles["-pageJump"]}>
              <input
                className={styles["-pageJumpInput"]}
                data-test-id="page-jump-input"
                type="number"
                onChange={(e) => this.onChange(e.target.value)}
                value={specifiedPage}
                onBlur={() => this.applyPage()}
                onKeyPress={(e) => (e.key === Key.Enter || e.key === Key.Tab) && this.applyPage()}
                disabled={this.props.disableInput}
              />
            </div>
            {t("entities.pagination.of")}
            <span className={styles["-totalPages"]} data-test-id="total-pages">
              {pageCount || 1}
            </span>
          </span>
        </div>
        <div className={styles["-next"]}>
          <div
            className={cx("-nextButton", { disabled: isLastPage })}
            data-test-id="next-btn"
            onClick={() => currentPage && !isLastPage && this.updatePager(currentPage + 1, "next")}>
            <Chevron variant={pageDirection && pageDirection === "rtl" ? "left" : "right"} />
          </div>
        </div>
      </div>
    );
  }
}
