/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Slideshow = ({ width = 24, height = 24, color = "#c6ccd5" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} fill={color}>
    <path
      fillRule="evenodd"
      d="M17.06,5A1.94,1.94,0,0,1,19,6.92V17.05A1.94,1.94,0,0,1,17.06,19H6.93A1.94,1.94,0,0,1,5,17.05V6.92A1.94,1.94,0,0,1,6.93,5ZM6.49,17.88,9,14.71,10.58,16l.48-.58L8.84,13.63,6,17.26a1.55,1.55,0,0,1,0-.21V6.92a1,1,0,0,1,1-1H17.06a1,1,0,0,1,1,1v7.27l-2.7-3L9.68,18H6.93a.93.93,0,0,1-.44-.12M17.06,18h-6.4l4.67-5.66L18,15.28l0,0v1.81a1,1,0,0,1-1,.95"
    />
    <polygon points="3.3 14.98 0.99 12.48 3.3 9.98 3.99 10.73 2.38 12.48 3.99 14.23 3.3 14.98" />
    <polygon points="20.68 14.98 22.99 12.48 20.68 9.98 19.99 10.73 21.61 12.48 19.99 14.23 20.68 14.98" />
  </svg>
);

export default Slideshow;
