/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const DownArrowCircle = ({ width = 24, height = 24, fill = "#5f6978" }) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    fill={fill}>
    <path d="M12 3a8.74 8.74 0 0 1 4.5 1.22 9 9 0 0 1 3.28 3.28 8.91 8.91 0 0 1 0 9 9 9 0 0 1-3.28 3.28A8.64 8.64 0 0 1 12 21a8.64 8.64 0 0 1-4.5-1.22 9 9 0 0 1-3.28-3.28A8.64 8.64 0 0 1 3 12a8.64 8.64 0 0 1 1.22-4.5 9 9 0 0 1 3.24-3.3A8.74 8.74 0 0 1 12 3zm7.84 9a7.67 7.67 0 0 0-1.09-4 8 8 0 0 0-2.87-2.85A7.88 7.88 0 0 0 8 5.19a7.84 7.84 0 0 0-2.83 2.87A7.81 7.81 0 0 0 8 18.77a7.88 7.88 0 0 0 7.86 0 7.92 7.92 0 0 0 2.85-2.87A7.7 7.7 0 0 0 19.8 12zm-3.34-.18a.38.38 0 0 1 .11.29.42.42 0 0 1-.11.29l-4.17 4.17a.45.45 0 0 1-.33.14.33.33 0 0 1-.29-.14L7.5 12.42a.35.35 0 0 1-.15-.29.44.44 0 0 1 .15-.33l.21-.25a.51.51 0 0 1 .29-.11.45.45 0 0 1 .33.14l3 3.09v-6.9a.42.42 0 0 1 .13-.31.43.43 0 0 1 .31-.12h.36a.45.45 0 0 1 .31.12.46.46 0 0 1 .13.31v6.9l3-3.09a.33.33 0 0 1 .29-.14.45.45 0 0 1 .33.14z" />
  </svg>
);

export default DownArrowCircle;
