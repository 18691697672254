/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { push } from "connected-react-router";
import { getEntities, createEntity, updateEntity, deleteEntity } from "helpers/api";
import { ENTITIES, ENTITIES_EDIT, ENTITIES_ADD_NEW } from "./routes";
import { route } from "../../../utils/routes.utils";
import { hasValidationErrors } from "utils";
import * as actions from "./actions";
import { NOTIFICATION_ERROR } from "containers/page/actions";
import { t } from "i18n";

export const loadEntities = (params?) => (dispatch, getState) => {
  const parameters = params ? params : { limit: getState().entities.entitiesPage.entitiesPerPage };
  dispatch({ type: actions.ENTITIES_LOADING });

  getEntities(parameters).then((data) => dispatch({ type: actions.ENTITIES_SET_DATA, payload: { data } }));
};

export const getEntitiesByPageAction = (pageNumber) => (dispatch, getState) => {
  const limit = getState().entities.entitiesPage.pagination.limit;
  const entitiesPerPage = getState().entities.entitiesPage.entitiesPerPage;

  const offset = (pageNumber - 1) * entitiesPerPage;

  const searchTerm = getState().entities.ui.searchTerm;

  dispatch({ type: actions.ENTITIES_UPDATE_CURRENT_PAGE, payload: { pageNumber } });
  dispatch({ type: actions.ENTITIES_UPDATE_PAGINATION, payload: { offset } });
  const params = searchTerm ? { limit, offset, q: searchTerm } : { limit, offset };
  getEntities(params).then(({ entities, pagination }) =>
    dispatch({
      type: actions.ENTITIES_SET_DATA,
      payload: { data: { entities, pagination } }
    })
  );
};

export const getSelectedTemplateOptionsAction = (selectedEntity) => (dispatch) => {
  if (!selectedEntity) {
    return;
  }

  dispatch({ type: actions.ENTITIES_FETCH_SELECTED_TEMPLATE_OPTIONS });
  const fields = selectedEntity.template.properties;
  fields.forEach((field) => {
    let entityProperty = field.key;
    if (field.type === "entity") {
      getEntities({ type: field["entity-type"] }).then((data) =>
        dispatch({ type: actions.ENTITIES_SET_SELECTED_TEMPLATE_OPTIONS, payload: { data, entityProperty } })
      );
    }
    if (field["array-element-type"] === "entity") {
      getEntities({ type: field["array-element-entity-type"] }).then((data) =>
        dispatch({ type: actions.ENTITIES_SET_SELECTED_TEMPLATE_OPTIONS, payload: { data, entityProperty } })
      );
    }
  });
};

export const createOrUpdateEntityAction = () => (dispatch, getState) => {
  const mandatoryFields = ["name"];
  const selectedEntityData = getState().entities.selectedEntityData;

  mandatoryFields.forEach((field) => {
    if (!selectedEntityData[field] || selectedEntityData[field] === "") {
      dispatch({ type: actions.ENTITIES_SET_FIELD_EMPTY_ERROR, payload: { field } });
    } else {
      dispatch({ type: actions.ENTITIES_REMOVE_FIELD_ERROR, payload: { field } });
    }
  });

  const fieldErrors = getState().entities.ui.errors;
  const currentPageNumber = getState().entities.ui.currentPage;
  dispatch({ type: actions.ENTITIES_LOADING });

  if (Object.keys(fieldErrors).length === 0) {
    const params = { entity: selectedEntityData };
    if (selectedEntityData.id) {
      updateEntity(selectedEntityData.id, params).then(() => dispatch(getEntitiesByPageAction(currentPageNumber)));
    } else {
      createEntity(params).then(() => dispatch(loadEntities()));
      dispatch({ type: actions.ENTITIES_UPDATE_CURRENT_PAGE, payload: { pageNumber: 1 } });
    }
    dispatch(push(route(ENTITIES)));
    dispatch({ type: actions.ENTITIES_RESET_SELECTED_ENTITY });
  }
};

export const setSelectedEntityTypeAction = (entityType) => (dispatch) => {
  const decentEntity = entityType;

  dispatch({ type: actions.ENTITIES_SET_SELECTED_ENTITY, payload: { entityType: decentEntity } });
  if (decentEntity) {
    dispatch({ type: actions.ENTITIES_UPDATE_SELECTED_ENTITY, payload: { key: "type", value: decentEntity.name } });
    dispatch(getSelectedTemplateOptionsAction(decentEntity));
  }
};

export const editEntityAction = (entity) => (dispatch, getState) => {
  const entityTypes = getState().config.entityTypes;
  const selectedEntityType = entityTypes.find((entityType) => entityType["id"] === entity["entity-type-id"]);
  const entityDataKeys = selectedEntityType.template.properties.map((property) => property.key);

  dispatch(push(route(ENTITIES_EDIT, { id: entity.id })));
  dispatch(setSelectedEntityTypeAction(selectedEntityType));
  dispatch({ type: actions.ENTITIES_UPDATE_SELECTED_ENTITY, payload: { key: "id", value: entity.id } });
  entityDataKeys.forEach((entityDataKey) => {
    dispatch({
      type: actions.ENTITIES_UPDATE_SELECTED_ENTITY,
      payload: { key: entityDataKey, value: entity[entityDataKey] }
    });
    if (entityDataKey === "photo") {
      dispatch({ type: actions.ENTITIES_SET_SELECTED_ENTITY_IMAGES, payload: { images: entity["photo"] } });
    }
  });
};

export const deleteEntityAction = (id) => (dispatch, getState) => {
  const currentPageNumber = getState().entities.ui.currentPage;
  dispatch({ type: actions.ENTITIES_LOADING });
  deleteEntity(id).then(() => dispatch(getEntitiesByPageAction(currentPageNumber)));
  dispatch({ type: actions.ENTITIES_RESET_SELECTED_ENTITY });
  dispatch(push(route(ENTITIES)));
};

export const setEntityImageAction = () => (dispatch, getState) => {
  const selectedEntityImages = getState().entities.entitiesPage.selectedEntityImages;
  const errorFields = hasValidationErrors(selectedEntityImages);
  if (errorFields && errorFields.length > 0) {
    dispatch({ type: actions.ENTITIES_UPDATE_FIELD_IMAGE_ERROR, payload: { errorFields } });
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: { message: t("mediaLibrary.unable_processing_image", { count: errorFields.length }) }
    });
  } else {
    dispatch({ type: actions.ENTITIES_REMOVE_FIELD_ERROR, payload: { field: "image" } });
    const selectedEntityID = getState().entities.selectedEntityData.id;
    dispatch({ type: actions.ENTITIES_UPDATE_SELECTED_ENTITY, payload: { key: "photo", value: selectedEntityImages } });
    dispatch({ type: actions.ENTITIES_SET_SELECTED_ENTITY_IMAGES, payload: { images: [] } });
    selectedEntityID
      ? dispatch(push(route(ENTITIES_EDIT, { id: selectedEntityID })))
      : dispatch(push(route(ENTITIES_ADD_NEW)));
  }
};

export const setSearchTermAction = (searchTerm) => (dispatch, getState) => {
  const limit = getState().entities.entitiesPage.entitiesPerPage;
  const validSearchTerm = searchTerm !== "";

  dispatch({ type: actions.ENTITIES_UPDATE_SEARCH_TERM, payload: { searchTerm } });
  validSearchTerm ? dispatch(loadEntities({ q: searchTerm })) : dispatch(loadEntities({ limit }));
  dispatch({ type: actions.ENTITIES_UPDATE_CURRENT_PAGE, payload: { pageNumber: 1 } });
};
