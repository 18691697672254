/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";

import styles from "./password-field.module.css";
import FieldLabel from "components/field-label/field-label";
import ErrorMessage from "components/error-message/error-message";

const cx = classnames.bind(styles);

interface PasswordFieldProps {
  label: string;
  onChange: (value: string) => void;
  placeholder?: string;
  errorMessage?: string;
  classname?: string;
  value?: string;
}

const PasswordField: React.SFC<PasswordFieldProps> = ({
  label,
  onChange,
  placeholder = "",
  errorMessage = "",
  classname = "",
  value
}) => {
  const classnames = cx("password-field", classname, {
    "has-error": errorMessage.length > 0
  });
  return (
    <div className={classnames}>
      <FieldLabel htmlFor={`password-field-${label}`} label={label} />
      <input
        className={styles["password-field-input"]}
        data-test-id="password-field-input"
        id={`password-field-${label}`}
        type="password"
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        value={value}
      />
      <ErrorMessage message={errorMessage} />
    </div>
  );
};

export default PasswordField;
