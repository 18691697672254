/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Sidebar from "components/sidebar/sidebar";
import { t } from "i18n";
import styles from "./sidebar.module.css";

type StoryEditorSidebarProps = {
  isBannerPresent: boolean;
  isDesktopSizeViewport: boolean;
};

const StoryEditorSidebar: React.SFC<StoryEditorSidebarProps> = ({ isBannerPresent, isDesktopSizeViewport }) => {
  if (isDesktopSizeViewport) return null;
  return (
    <div>
      <Sidebar isBannerPresent={isBannerPresent}>
        <h3 className={styles["sidebar-title"]}>{t("story-editor.sidebar.title")}</h3>
      </Sidebar>
    </div>
  );
};

export default StoryEditorSidebar;
