/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Select from "components/select/select";
import { t } from "i18n";

function translateTemplateNameOrDefault(storyTemplate) {
  return t(`story-editor.story-template.${storyTemplate.slug}`, storyTemplate.name);
}

const StoryTemplateFilter = ({ storyTemplates, value, onChange }) => (
  <Select
    label={t("workspace.filters_inspector.story_templates")}
    value={value}
    options={storyTemplates}
    onChange={(storyTemplate) => onChange(storyTemplate)}
    getOptionLabel={translateTemplateNameOrDefault}
    getOptionValue={(storyTemplate) => storyTemplate.slug}
    isMulti={true}
  />
);

export default StoryTemplateFilter;
