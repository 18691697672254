/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Location = ({ width = 24, height = 24, fill = "#c6ccd5" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    data-name="Layer 2"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    fill={fill}>
    <defs />
    <path d="M12 16.73l-.37-.41C11.44 16.11 7 11.2 7 7.71a5 5 0 0 1 10 0c0 3.49-4.48 8.4-4.67 8.61zm0-13a4 4 0 0 0-4 4c0 2.57 3 6.28 4 7.53 1.06-1.25 4-5 4-7.53a4 4 0 0 0-4-3.98zm0 6.12a1.73 1.73 0 1 1 1.76-1.73A1.75 1.75 0 0 1 12 9.87zm0-2.45a.74.74 0 1 0 .76.74.75.75 0 0 0-.76-.74z" />
    <path d="M19.56 21.24H4.44A1.5 1.5 0 0 1 3 19.69v-7.36h4.41v1H4v6.38a.51.51 0 0 0 .44.56h15.12a.51.51 0 0 0 .44-.56v-6.4h-3.3v-1H21v7.36a1.5 1.5 0 0 1-1.44 1.55" />
  </svg>
);

export default Location;
