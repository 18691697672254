/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ExtendableError } from "utils/error.utils";

export class CustomURLNotFoundError extends ExtendableError {
  id: string;

  constructor(id: string, message: string) {
    super(message);
    this.id = id;
  }
}

export class CustomURLServerValidationError extends ExtendableError {
  error: Error;
  constructor(message: string, body: Error) {
    super(message);
    this.error = body;
  }
}
