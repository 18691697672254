/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { getCtaElementLevelErrors, getStoryElementLevelErrors, isCtaElementError } from "pages/story-editor/utils";
import ErrorMessage from "components/error-message/error-message";

interface Props {
  errors: {
    code: string;
    content: { code: string };
    "vidible-video-id": { code: string };
    "linked-story-id": { code: string };
    question: { code: string };
    answer: { code: string };
    "cta-url": { code: string };
    "cta-title": { code: string };
    text: { code: string };
  };
}

const StoryElementErrors = ({ errors }: Props) => {
  return (
    <div className="has-error">
      {errors.code && <ErrorMessage message={getStoryElementLevelErrors(errors.code)} />}
      {errors.content && <ErrorMessage message={getStoryElementLevelErrors(errors.content.code)} />}
      {errors["vidible-video-id"] && (
        <ErrorMessage message={getStoryElementLevelErrors(errors["vidible-video-id"].code)} />
      )}
      {errors["linked-story-id"] && (
        <ErrorMessage message={getStoryElementLevelErrors(errors["linked-story-id"].code)} />
      )}
      {errors.question && <ErrorMessage message={getStoryElementLevelErrors(errors.question.code)} />}
      {errors.answer && !errors.question && <ErrorMessage message={getStoryElementLevelErrors(errors.answer.code)} />}
      {errors.text && <ErrorMessage message={getStoryElementLevelErrors(errors.text.code)} />}
      {isCtaElementError(errors) && <ErrorMessage message={getCtaElementLevelErrors(errors)} />}
    </div>
  );
};

export default StoryElementErrors;
