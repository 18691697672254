/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { t } from "i18n";
import { navigate } from "utils/routes.utils";
import classnames from "classnames/bind";
import { getStoryImages, storyContent } from "utils/story.utils";
import { Story } from "api/story";
import { getValidatorStats, SeoStats } from "api/validator";
import { Validator } from "api/route-data/story-route-data";
import { STORY_EDITOR_PATH } from "pages/story-editor/routes";
import { setStoryTransitionStatus, toggleStorySeoChecked } from "pages/story-editor/action-creators";
import { TextFieldWithButton } from "components/text-field/text-field";
import Close from "components/icons/close";
import Checkbox from "components/checkbox/checkbox";
import SeoGroup from "./seo-group";
import styles from "./seo-score.module.css";
import { get } from "lodash";

const cx = classnames.bind(styles);

interface StateProps {
  story: Story;
  isSeoChecked: boolean;
  isSeoCheckMandatory: boolean;
  bannerMessage: string | null;
  validatorCreds: Validator;
  isSliderWindowOpen: boolean;
}

interface DispatchProps {
  closeSeoScore: (storyId) => void;
  toggleSeoChecked: VoidFunction;
}

interface State {
  focus: string;
  seoStats?: SeoStats;
  isKeywordSubmitted: boolean;
}

type Props = StateProps & DispatchProps;

export class SeoScore extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      focus: "",
      isKeywordSubmitted: false
    };
  }

  componentDidMount() {
    this.fetchSeoScore();
  }

  buildSeoStory = (story: Story) => {
    return {
      title: (story.seo && story.seo["meta-title"]) || story.headline || "",
      "meta-description": (story.seo && story.seo["meta-description"]) || "",
      content: storyContent(story) || "",
      images: getStoryImages(story)
    };
  };

  fetchSeoScore = async () => {
    const seoStats = await getValidatorStats(
      this.buildSeoStory(this.props.story),
      this.state.focus,
      this.props.validatorCreds["api-host"]
    );

    this.setState({ seoStats, isKeywordSubmitted: !!this.state.focus });
  };

  render() {
    const seoStatKeys = this.state.seoStats && Object.keys(this.state.seoStats);
    const containerClass = cx("seo-stats-container", {
      "with-banner": this.props.bannerMessage,
      "with-slider-open": this.props.isSliderWindowOpen
    });
    const { story, isSeoCheckMandatory, isSeoChecked, toggleSeoChecked } = this.props;
    const { "story-content-id": storyId } = story;
    return (
      <div className={containerClass} data-test-id="seo-stats-container">
        <div className={styles["seo-stats-header"]} data-test-id="seo-stats-header">
          <div
            className={styles["seo-stats-close"]}
            data-test-id="seo-stats-close-btn"
            onClick={() => {
              this.props.closeSeoScore(storyId);
            }}>
            <Close />
          </div>
          <h3 className={styles["seo-stats-title"]} data-test-id="seo-stats-title">
            {t("story-editor.seo-stats.title")}
          </h3>
        </div>
        <div className={styles["seo-action"]} data-test-id="seo-actions">
          <TextFieldWithButton
            label={t("story-editor.seo-stats.focus-keyword")}
            value={this.state.focus}
            onChange={(value: any) => {
              this.setState({ focus: value });
            }}
            readOnly={false}
            buttonLabel={t("story-editor.seo-stats.focus-submit")}
            onButtonClick={this.fetchSeoScore}
          />
        </div>
        {isSeoCheckMandatory && !isSeoChecked && (
          <p data-test-id="seo-check-help" className={styles["seo-check-help"]}>
            {t("story-editor.seo-stats.seo-check-help")}
          </p>
        )}
        {seoStatKeys && (
          <div data-test-id="seo-stats">
            {seoStatKeys.map((group) => (
              <SeoGroup group={group} seoStatItem={this.state.seoStats && this.state.seoStats[group]} />
            ))}
          </div>
        )}
        {isSeoCheckMandatory && this.state.isKeywordSubmitted && story.status === "draft" && (
          <div data-test-id="seo-checkbox" className="seo-checkbox">
            <Checkbox
              label={t("story-editor.inspector.seo-verified")}
              id="SEO"
              checked={isSeoChecked}
              onChange={toggleSeoChecked}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state): StateProps {
  return {
    story: state.storyEditor.story,
    isSeoChecked: state.storyEditor.ui.seoChecked,
    isSeoCheckMandatory: state.features.isSeoCheckMandatory,
    bannerMessage:
      state.config.publisherWideBannerMessage || (state.storyEditor.ui.banner && state.storyEditor.ui.banner.message),
    validatorCreds: state.config.validator,
    isSliderWindowOpen: get(state, ["slider", "sliderWindow", "open"], false)
  };
}

function mapDispatchToProps(dispatch): DispatchProps {
  return {
    closeSeoScore: (storyId) => {
      dispatch(setStoryTransitionStatus(false));
      dispatch(navigate(STORY_EDITOR_PATH, { id: storyId }));
    },
    toggleSeoChecked: () => {
      dispatch(toggleStorySeoChecked());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SeoScore);
