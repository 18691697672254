/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";

const Lock = ({ width = 24, height = 24, color = "currentColor" }) => (
  <svg width={width} height={height} viewBox="0 0 24 24" version="1.1">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Publish-after-Embargo" transform="translate(-485.428571, -163.714286)">
        <g id="Group-16" transform="translate(482.000000, 162.000000)">
          <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
          <g id="Group" transform="translate(3.428571, 1.714286)" fill={color} fill-rule="nonzero">
            <path
              d="M2,19 L2,9 L14,9 L14,10 C14.7,10 15.37,10.1 16,10.29 L16,9 C16,7.9 15.1,7 14,7 L13,7 L13,5 C13,2.24 10.76,0 8,0 C5.24,0 3,2.24 3,5 L3,7 L2,7 C0.9,7 0,7.9 0,9 L0,19 C0,20.1 0.9,21 2,21 L8.26,21 C7.84,20.4 7.51,19.72 7.29,19 L2,19 Z M5,5 C5,3.34 6.34,2 8,2 C9.66,2 11,3.34 11,5 L11,7 L5,7 L5,5 Z"
              id="Shape"></path>
            <path
              d="M14,12 C11.24,12 9,14.24 9,17 C9,19.76 11.24,22 14,22 C16.76,22 19,19.76 19,17 C19,14.24 16.76,12 14,12 Z M15.65,19.35 L13.5,17.2 L13.5,14 L14.5,14 L14.5,16.79 L16.35,18.64 L15.65,19.35 Z"
              id="Shape"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Lock;
