/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface H3IconProps {
  width?: string;
  height?: string;
  color?: string;
}

const H3: React.SFC<H3IconProps> = ({ width = "24", height = "24", color = "currentColor" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 24 24">
    <path d="M21.15,12.63a4.07,4.07,0,0,0-2.4-1.16l2.69-3.06V6.63H13.11V8.84h5.06L15.73,11.6v1.82H17c1.48,0,2.22.49,2.22,1.46A1.28,1.28,0,0,1,18.62,16a2.87,2.87,0,0,1-1.63.4,5.93,5.93,0,0,1-1.83-.28,5.15,5.15,0,0,1-1.57-.79l-1.07,2.11a6.22,6.22,0,0,0,2.06.94,9.42,9.42,0,0,0,2.47.33,6.21,6.21,0,0,0,2.73-.53,3.8,3.8,0,0,0,1.66-1.4A3.56,3.56,0,0,0,22,14.88,3.24,3.24,0,0,0,21.15,12.63Z" />
    <path d="M7.58,9.24a4.82,4.82,0,0,0-1.64.28,3.64,3.64,0,0,0-1.27.81V5.91H2V18.52H4.67V14a2.53,2.53,0,0,1,.6-1.84,2.06,2.06,0,0,1,1.59-.62,1.74,1.74,0,0,1,1.38.53,2.43,2.43,0,0,1,.47,1.62v4.83h2.65V13.29a4.08,4.08,0,0,0-1-3A3.76,3.76,0,0,0,7.58,9.24Z" />
  </svg>
);

export default H3;
