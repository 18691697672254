/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { replace } from "connected-react-router";
import { NavLink } from "react-router-dom";

import { navigateFn, route, locationHasTruthyQueryParam } from "../../../../utils/routes.utils";
import { hasEmptyStoryElementsOrCards } from "../../utils";
import styles from "./header.module.css";
import Button from "../../../../components/button/button";
import PreviewIcon from "../../../../components/icons/preview";
import Save from "components/icons/save";
import {
  STORY_EDITOR_PATH,
  STORY_EDITOR_READ_ONLY_PATH,
  STORY_EDITOR_ALTERNATIVES_PATH,
  STORY_EDITOR_NOTES_PATH,
  STORY_EDITOR_EMBARGO_PATH
} from "../../routes";
import WorkflowButtons from "../workflow-buttons/workflow-buttons";
import {
  updateStatusAction,
  saveStoryAction,
  toggleStoryCleanUpConfirmationAction,
  setBannerAction,
  getPlagiarismReport,
  cancelPublishAndSaveAction
} from "../../async-action-creators";
import Header from "components/header/header";
import Banner from "components/banner/banner";
import { ContextMenu, ContextMenuList, ContextMenuItem } from "components/context-menu/context-menu";
import { t } from "i18n";
import { selectIsDesktopSizeViewport } from "store/viewport";
import LastUpdatedTimer from "pages/story-editor/components/last-updated-timer/last-updated-timer";
import { setStoryTransitionStatus } from "pages/story-editor/action-creators";

class StoryEditorHeader extends React.Component<any, any> {
  updateStoryStatus = (storyId, storyVersionId, currentStatus, action) => {
    if (hasEmptyStoryElementsOrCards(this.props.story)) {
      return this.props.toggleStoryCleanUpConfirmation(action);
    }
    if (!this.props.isStoryModified) {
      return this.props.updateStoryStatus(storyId, storyVersionId, action);
    }
    if (currentStatus === "scheduled") {
      return this.props.cancelPublishAndSave();
    }
    this.props.saveStory(this.props.updateStoryStatus, action);
  };

  render() {
    const {
        story,
        workflowTransitions,
        isStoryLocked,
        openTimeline,
        openAlternativesInspector,
        bannerMessage,
        banner,
        isDesktopSizeViewport,
        closeBanner,
        isStorySaving,
        location,
        openPlagiarismReport,
        openValidatorReport,
        isValidatorEnabled,
        isStatusTransitionInProgress,
        openEditorNotes,
        locale,
        lastUpdatedBy,
        currentMember,
        openEmbargo,
        canManageEmbargo,
        isStoryScheduleWithCollectionEnabled,
        canStoryPublish,
        isCurrentStoryPrint
      } = this.props,
      isPreviewActive = locationHasTruthyQueryParam(location, "preview"),
      isTimelineActive = locationHasTruthyQueryParam(location, "timeline"),
      isValidatorActive = locationHasTruthyQueryParam(location, "validator");
    const successWorkflowActions = workflowTransitions.filter(
      (transition) =>
        transition.action === "story-submit" ||
        transition.action === "story-proof-reading-done" ||
        transition.action === "story-publish" ||
        (transition.action === "story-schedule-modify" && story["publish-at"]) ||
        transition.action === "story-approve" ||
        transition.action === "story-qa" ||
        transition.action === "story-edited" ||
        (!canStoryPublish &&
          isStoryScheduleWithCollectionEnabled &&
          transition.action === "story-schedule-with-collection")
    );
    const showPlagiarismCheck =
      workflowTransitions.filter((transition) => transition.action !== "story-submit").length > 1;

    const hideLastUpdatedTimer = !isDesktopSizeViewport || isStorySaving;
    const lastUpdatedAuthorName =
      lastUpdatedBy && (currentMember.id === lastUpdatedBy["member-id"] ? null : lastUpdatedBy["member-name"]);
    const showEmbargoMenuItem = canManageEmbargo && !story["first-published-at"] && !story["publish-at"];

    // Function to determine the label for the workflow button based on the transition action
    const getWorkflowBtnLabel = (transition) => {
      // Extract the action from the transition object
      const currentTransitionAction = transition["action"];
      // Check if the story template is 'print' and if the transition action is either 'story-submit' or 'story-approve'
      if (isCurrentStoryPrint && currentTransitionAction === "story-submit") {
        // Return the label for print story template actions (localized)
        return t("story-editor.header.print");
      }
      // If it's a normal story template, return the label for the current action (localized)
      return t(`story-editor.header.${currentTransitionAction}`);
    };

    // Function to update the story's status and transition details
    const updateStoryStatusBasedOnTransition = (action) => {
      this.updateStoryStatus(story["story-content-id"], story["story-version-id"], story["status"], action);
    };

    return (
      <React.Fragment>
        {banner && (
          <Banner
            message={bannerMessage}
            closable={banner && banner.closable}
            onClose={() => closeBanner()}
            type={banner && banner.type}
            autoClose={banner && banner.autoClose}
            timestamp={banner && banner.timestamp}
          />
        )}
        <Header showAvatar={isDesktopSizeViewport} hideAddNewButton={!isDesktopSizeViewport}>
          <ul className={styles["masthead-story-buttons"]}>
            <li className={styles["masthead-story-button"]}>
              <LastUpdatedTimer
                lastUpdatedTime={story["updated-at"]}
                locale={locale}
                authorName={lastUpdatedAuthorName}
                onClick={openTimeline}
                hide={hideLastUpdatedTimer}
              />
            </li>
            <li className={styles["masthead-story-button"]} data-test-id="masthead-story-buttons">
              {isStorySaving && <span className={styles["story-saving-text"]}>{t("story-editor.header.saving")}</span>}
              <Button
                testId="story-buttons"
                type="transparent"
                onClick={() =>
                  story["status"] === "scheduled" ? this.props.cancelPublishAndSave() : this.props.saveStory()
                }
                disabled={
                  !this.props.isStoryModified || isStorySaving || isStoryLocked || isStatusTransitionInProgress
                }>
                <Save color={!this.props.isStoryModified || isStorySaving ? "#c6ccd5" : "#132437"} />
              </Button>
            </li>
            {story["story-content-id"] !== "new" &&
              !isStorySaving &&
              (!this.props.isStoryModified || story["status"] !== "scheduled") && (
                <React.Fragment>
                  {successWorkflowActions.map((transition) => (
                    <li
                      className={`${
                        isCurrentStoryPrint && transition["action"] !== "story-submit" ? styles["hide-button"] : ""
                      } ${styles["masthead-story-button"]}`}>
                      <Button
                        testId="publish-flow-btn"
                        type="primary"
                        variant="success"
                        disabled={isStorySaving || isStoryLocked || isTimelineActive || isStatusTransitionInProgress}
                        onClick={() => {
                          // Update the story status based on the current transition action
                          updateStoryStatusBasedOnTransition(transition["action"]);
                        }}>
                        {getWorkflowBtnLabel(transition)}
                      </Button>
                    </li>
                  ))}
                  {isDesktopSizeViewport && (
                    <li className={styles["masthead-story-button"]} data-test-id="preview-icon">
                      <NavLink
                        to={route(
                          STORY_EDITOR_PATH,
                          { id: story["story-content-id"] },
                          { preview: !isPreviewActive || undefined }
                        )}
                        activeClassName="is-selected"
                        isActive={() => isPreviewActive}>
                        <PreviewIcon />
                      </NavLink>
                    </li>
                  )}
                </React.Fragment>
              )}
            {story["story-content-id"] !== "new" && (
              <li>
                <ContextMenu>
                  <ContextMenuList>
                    {!isStoryLocked && (
                      <ContextMenuItem
                        label={t("story-editor.header.add-alternatives")}
                        onClick={() => openAlternativesInspector(story["story-content-id"])}
                      />
                    )}
                    {!isStoryLocked && (
                      <ContextMenuItem
                        label={t("story-editor.header.editor-notes")}
                        onClick={() => openEditorNotes(story["story-content-id"])}
                      />
                    )}
                    <ContextMenuItem
                      label={t("story-editor.header.timeline")}
                      onClick={openTimeline}
                      isActive={isTimelineActive}
                    />

                    {showEmbargoMenuItem && !isStoryLocked && (
                      <ContextMenuItem
                        label={t("common.embargo")}
                        onClick={() => openEmbargo(story["story-content-id"])}
                      />
                    )}

                    {showPlagiarismCheck && (
                      <ContextMenuItem
                        label={t("story-editor.header.check-plagiarism")}
                        onClick={() => openPlagiarismReport(story["story-content-id"], story["story-version-id"])}
                      />
                    )}
                    {isValidatorEnabled && (
                      <ContextMenuItem
                        label={t("story-editor.header.validator")}
                        onClick={() => openValidatorReport(story["story-content-id"], true)}
                        isActive={isValidatorActive}
                      />
                    )}

                    <div className={styles["story-header-menu"]}>
                      <WorkflowButtons
                        isStoryLocked={isStoryLocked}
                        isStatusTransitionInProgress={isStatusTransitionInProgress}
                        workflowTransitions={workflowTransitions}
                        isCurrentStoryPrint={isCurrentStoryPrint}
                        currentStoryAction={story["status"]}
                        updateStoryStatus={(action) =>
                          this.updateStoryStatus(
                            story["story-content-id"],
                            story["story-version-id"],
                            story["status"],
                            action
                          )
                        }
                      />
                    </div>
                  </ContextMenuList>
                </ContextMenu>
              </li>
            )}
          </ul>
        </Header>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    story: state.storyEditor.story,
    editorState: state.storyEditor.editorState,
    isStoryModified: state.storyEditor.ui.isStoryModified,
    workflowTransitions: state.storyEditor.ui.workflowTransitions,
    isStoryLocked: state.storyEditor.ui.isStoryLocked,
    isCurrentStoryPrint: state.storyEditor.ui.isCurrentStoryPrint,
    bannerMessage:
      (state.storyEditor.ui.banner && state.storyEditor.ui.banner.message) || state.config.publisherWideBannerMessage,
    banner: state.storyEditor.ui.banner,
    isStorySaving: state.storyEditor.ui.isStorySaving,
    isDesktopSizeViewport: selectIsDesktopSizeViewport(state),
    location: ownProps.location,
    isValidatorEnabled: state.features.isValidatorEnabled,
    isStatusTransitionInProgress: state.storyEditor.ui.isStatusTransitionInProgress,
    locale: state.config.publisher.i18n.locale,
    lastUpdatedBy: state.storyEditor.story["last-updated-by"],
    currentMember: state.config.member,
    canManageEmbargo: !!state.features.canManageEmbargo,
    isStoryScheduleWithCollectionEnabled: state.features.isStoryScheduleWithCollectionEnabled,
    canStoryPublish: state.config.canStoryPublish
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const navigate = navigateFn(dispatch);

  return {
    navigate,
    replaceRoute: (id) => dispatch(replace(route(STORY_EDITOR_PATH, { id }))),
    toggleStoryCleanUpConfirmation: (action) => dispatch(toggleStoryCleanUpConfirmationAction(action)),
    saveStory: (callback, action) => dispatch(saveStoryAction(callback, action)),
    updateStoryStatus: (storyId, storyVersionId, transition) =>
      dispatch(updateStatusAction(storyId, storyVersionId, transition)),
    openTimeline: () => {
      const navigateToTimeLine = (id, versionId) =>
        navigate(STORY_EDITOR_READ_ONLY_PATH, { id, versionId }, { timeline: true });
      dispatch(saveStoryAction(navigateToTimeLine));
    },
    openEmbargo: (id) => navigate(STORY_EDITOR_EMBARGO_PATH, { id }),
    openAlternativesInspector: (id) => navigate(STORY_EDITOR_ALTERNATIVES_PATH, { id }),
    openValidatorReport: (id, validator) => navigate(STORY_EDITOR_PATH, { id }, { validator }),
    openPlagiarismReport: (id, versionId) => dispatch(getPlagiarismReport(id, versionId)),
    closeBanner: () => dispatch(setBannerAction(null)),
    setStoryTransitionStatus: (isTransitionInProgress: boolean) =>
      dispatch(setStoryTransitionStatus(isTransitionInProgress)),
    cancelPublishAndSave: (callback, action) => dispatch(cancelPublishAndSaveAction(callback, action)),
    openEditorNotes: (id) => navigate(STORY_EDITOR_NOTES_PATH, { id })
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoryEditorHeader);
export { Header };
