/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import styles from "./badge.module.css";

const cx = classnames.bind(styles);

export type BadgeValue = number | string | undefined | null;

interface Props {
  value: BadgeValue;
  muted?: boolean;
  classname?: string;
  dataFor?: string;
}

const Badge: React.SFC<Props> = ({ value, muted = false, classname, children, dataFor }) => {
  const classes = cx("badge", { "is-muted": muted }, classname);
  return value || value === 0 ? (
    <span data-test-id="badge" className={classes} data-for={dataFor} data-tip>
      {value}
      {children || null}
    </span>
  ) : null;
};

export default Badge;
