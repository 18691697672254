/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { some } from "lodash";
import { t } from "i18n";
import CategoryTitle from "components/category-title/category-title";

const showHeaderText = (seoFields, fieldSpecs) => {
  const fields = seoFields.map(({ specPath }) => fieldSpecs[specPath] && fieldSpecs[specPath].validations);
  return some(fields, ["hidden", false]);
};

const Seo = ({ fields, onSEOChange, story, fieldSpecs, errors = {}, disabled }) => (
  <div>
    {showHeaderText(fields, fieldSpecs) && <CategoryTitle title={t("story-editor.inspector.seo")} />}
    {fields.map(({ component: Component, specPath, storyPath }) => (
      <React.Fragment key={storyPath}>
        {fieldSpecs[specPath] && (
          <Component
            value={story.seo[storyPath]}
            onChange={onSEOChange(storyPath)}
            spec={fieldSpecs[specPath]}
            errors={errors[specPath]}
            disabled={disabled}
          />
        )}
      </React.Fragment>
    ))}
  </div>
);

export default Seo;
