/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { RoleTypes, AnyRole } from "api/roles";

enum actions {
  LIST_ROLES = "@@settings/LIST_ROLES",
  LIST_ROLES_SUCCESS = "@@settings/LIST_ROLES_SUCCESS",
  LIST_ROLES_FAILURE = "@@settings/LIST_ROLES_FAILURE",

  CANCEL_SAVE_ROLES_AND_CLEAR_CURRENT_ROLE_FROM_INSPECTOR = "@@settings/CANCEL_SAVE_ROLES_AND_CLEAR_CURRENT_ROLE_FROM_INSPECTOR",
  SWITCH_ACTION_CATEGORY_TAB = "@@settings/SWITCH_ACTION_CATEGORY_TAB",
  UPDATE_ROLE = "@@settings/UPDATE_ROLE",

  SAVE_ROLE_SUCCESS = "@@settings/SAVE_ROLE_SUCCESS",
  SAVE_ROLE_FAILURE = "@@settings/SAVE_ROLE_FAILURE",

  EDIT_ROLE = "@@settings/EDIT_ROLE",
  ADD_NEW_ROLE = "@@settings/ADD_NEW_ROLE",
  DELETE_ROLE_REQUEST = "@@settings/DELETE_ROLE_REQUEST",
  DELETE_ROLE_FAILURE = "@@settings/DELETE_ROLE_FAILURE"
}

export default actions;

export type ListRolesAction = {
  type: actions.LIST_ROLES;
};

export type ListRolesSuccessAction = {
  type: actions.LIST_ROLES_SUCCESS;
  payload: { roles: RoleTypes[] };
};

export type ListRolesFailureAction = {
  type: actions.LIST_ROLES_FAILURE;
  payload: { error: Error };
};
export type CancelSaveRolesAction = {
  type: actions.CANCEL_SAVE_ROLES_AND_CLEAR_CURRENT_ROLE_FROM_INSPECTOR;
};
export type EditRoleAction = {
  type: actions.EDIT_ROLE;
  payload: { selectedRole: RoleTypes };
};
export type SwitchActionCategoryTabAction = {
  type: actions.SWITCH_ACTION_CATEGORY_TAB;
  payload: { selectedTab: string };
};
export type UpdateRole = {
  type: actions.UPDATE_ROLE;
  payload: { updatedRole: AnyRole };
};
export type SaveRoleSuccessAction = {
  type: actions.SAVE_ROLE_SUCCESS;
};
export type AddNewRoleAction = {
  type: actions.ADD_NEW_ROLE;
};

export type SaveRoleActionFailure = {
  type: actions.SAVE_ROLE_FAILURE;
  payload: { errors: Error };
};

export type DeleteRoleActionRequest = {
  type: actions.DELETE_ROLE_REQUEST;
};
export type DeleteRoleActionFailure = {
  type: actions.DELETE_ROLE_FAILURE;
};

export type ActionTypes =
  | ListRolesAction
  | ListRolesSuccessAction
  | ListRolesFailureAction
  | CancelSaveRolesAction
  | EditRoleAction
  | SwitchActionCategoryTabAction
  | UpdateRole
  | SaveRoleSuccessAction
  | AddNewRoleAction
  | SaveRoleActionFailure
  | DeleteRoleActionRequest
  | DeleteRoleActionFailure;
