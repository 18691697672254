/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const File = ({ width = "40", height = "40" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="#C6CCD5"
    viewBox="0 0 24 24"
    data-name="Layer 1">
    <path d="M13.17 7.78a.81.81 0 0 0 .26.6.86.86 0 0 0 .62.24H19v11.54a.78.78 0 0 1-.25.59.84.84 0 0 1-.62.25H5.88a.84.84 0 0 1-.62-.25.79.79 0 0 1-.26-.59V3.84a.79.79 0 0 1 .26-.59.84.84 0 0 1 .62-.25h7.29zM19 7.29a.8.8 0 0 0-.25-.6l-3.57-3.44a.84.84 0 0 0-.62-.25h-.22v4.5H19z" />
  </svg>
);

export default File;
