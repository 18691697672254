/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { validate } from "utils/validation.utils";
import { AnySection, Section, UnsavedSection } from "api/section";
import { ValidationResult } from "utils/validation.utils";
import { t } from "i18n";

export function validateSection(section: UnsavedSection): ValidationResult<UnsavedSection>;
export function validateSection(section: Section): ValidationResult<Section>;
export function validateSection(section: AnySection): ValidationResult<AnySection> {
  return validate(section, {
    name: {
      presence: { allowEmpty: false }
    },
    slug: {
      presence: { allowEmpty: false }
    },
    "data.color": {
      presence: false,
      format: { pattern: /^#([a-f0-9]{6})\b|^$/i, message: "^" + t("sections.messages.unrecognized-pattern") }
    }
  });
}
