/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import styles from "./external-image.module.css";
import { StoryElement } from "api/story";
import classnames from "classnames/bind";

interface ExternalImageProps {
  storyElement: StoryElement;
  isDisabled?: boolean;
}

const cx = classnames.bind(styles);

const ExternalImage: React.FunctionComponent<ExternalImageProps> = ({ storyElement, isDisabled }) => {
  const classes = cx("image-element-container", { "is-disabled": isDisabled });
  return (
    <div className={classes}>
      {/* External images don't have a description so alt text is made static, we need to fix this in product level on how to handle images */}
      <img src={storyElement.url} alt="external source" className={styles["image-preview"]} />
    </div>
  );
};

export default ExternalImage;
