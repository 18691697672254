/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import produce from "immer";
import { State, INITIAL_CUSTOM_URLS_STATE } from "./state";
import actions, { ActionTypes } from "./actions";

function reducer(state: State = INITIAL_CUSTOM_URLS_STATE, action: ActionTypes) {
  return produce(state, (draft) => {
    switch (action.type) {
      case actions.LIST_CUSTOM_URLS:
        draft.ui.main = { loading: true, error: null };
        break;

      case actions.LIST_CUSTOM_URLS_SUCCESS:
        draft.ui.main = { loading: false, error: null };
        draft.customURLs = action.payload.customURLs;
        draft.pagination.total = action.payload.pagination;
        break;

      case actions.LIST_CUSTOM_URLS_FAILURE:
        draft.ui.main = { loading: false, error: action.payload.error };
        break;

      case actions.UPDATE_CUSTOM_URLS_PAGINATION:
        draft.pagination = action.payload.pagination;
        break;

      case actions.SET_CUSTOM_URLS_SEARCH_TERM:
        draft.app.searchTerm = action.payload.searchTerm;
        break;

      case actions.SET_CURRENT_CUSTOM_URL:
        draft.app.currentCustomURL = action.payload.customURL;
        draft.ui.save = { loading: false, error: null };
        break;

      case actions.UPDATE_CURRENT_CUSTOM_URL:
        draft.app.currentCustomURL = action.payload.customURL;
        break;

      case actions.CANCEL_SAVE_CURRENT_CUSTOM_URL:
        draft.app.currentCustomURL = null;
        break;

      case actions.SAVE_CUSTOM_URL:
        draft.ui.save = { loading: true, error: null };
        break;

      case actions.SAVE_CUSTOM_URL_SUCCESS:
        draft.ui.save = { loading: false, error: null };
        break;

      case actions.SAVE_CUSTOM_URL_FAILURE:
        draft.ui.save = { loading: false, error: action.payload.error };
        break;

      case actions.DELETE_CUSTOM_URL:
        draft.ui.save = { loading: true, error: null };
        break;

      case actions.DELETE_CUSTOM_URL_SUCCESS:
        draft.ui.save = { loading: false, error: null };
        break;

      case actions.DELETE_CUSTOM_URL_FAILURE:
        draft.ui.save = { loading: false, error: action.payload.error };
        break;

      case actions.TOGGLE_DELETE_CONFIRMATION:
        draft.ui.showDeleteConfirmation = !draft.ui.showDeleteConfirmation;
        break;

      default:
        break;
    }
  });
}

export default reducer;
