/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { t } from "i18n";
import { SECTIONS_INDEX_PATH } from "../sections/routes";
import { MENU_INDEX_PATH } from "../menu/routes";
import { TAGS_INDEX_PATH } from "../tags/routes";
import { ATTRIBUTES_INDEX_PATH } from "../attributes/routes";
import { CUSTOM_METADATA_INDEX_PATH } from "../custom-metadata/routes";
import { ENTITIES } from "../entities/routes";
import { CUSTOM_URL_INDEX_PATH } from "../custom-urls/routes";
import { SITEMAP_PATH } from "../sitemap/routes";

const menuItems = ({
  enableAdminLinksInSidebar,
  enableTagsInSidebar,
  enableEntitiesInSidebar
}: {
  enableAdminLinksInSidebar?: boolean;
  enableTagsInSidebar?: boolean;
  enableEntitiesInSidebar?: boolean;
}) => {
  let items: Array<{ label: string; path: string } | any> = [];
  if (enableAdminLinksInSidebar) {
    items = [
      ...items,
      { label: t("sections.title"), path: SECTIONS_INDEX_PATH },
      { label: t("menu.title"), path: MENU_INDEX_PATH },
      { label: t("attributes.title"), path: ATTRIBUTES_INDEX_PATH },
      { label: t("custom-metadata.title"), path: CUSTOM_METADATA_INDEX_PATH },
      { label: t("customURLs.customURL_plural"), path: CUSTOM_URL_INDEX_PATH },
      { label: t("sitemap.title"), path: SITEMAP_PATH }
    ];
  }
  if (enableTagsInSidebar) {
    items = [...items, { label: t("tags.title"), path: TAGS_INDEX_PATH }];
  }
  if (enableEntitiesInSidebar) {
    items = [...items, { label: t("entities.entities"), path: ENTITIES }];
  }

  return items;
};

export default menuItems;
