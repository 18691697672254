/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import Sidebar from "components/sidebar/sidebar";
import { compose } from "redux";
import { connect } from "react-redux";
import Menu from "components/menu/menu";
import menuItems from "../menu-items";
import { PartialAppState } from "../initial-state";
import { match } from "react-router";

interface MenuType {
  label: string;
  value: string;
  path: string;
}

interface StateProps {
  enableAdminLinksInSidebar?: boolean;
  canAddAuthor?: boolean;
}

type Props = StateProps & {
  isBannerPresent: boolean;
  setActiveSidebar: (type: string) => void;
  match: match;
};

class SettingsSidebar extends React.Component<Props> {
  handleRoute = (item: MenuType) => {
    if (item.value === "configure") {
      this.props.setActiveSidebar("settings-configure");
    } else if (item.value === "users-and-roles") {
      this.props.setActiveSidebar("settings-usersAndRoles");
    }
  };

  render() {
    const { isBannerPresent, enableAdminLinksInSidebar, match, canAddAuthor } = this.props;

    const settingsMenu = menuItems({ enableAdminLinksInSidebar, canAddAuthor });

    return (
      <Sidebar isBannerPresent={isBannerPresent}>
        <Menu
          items={settingsMenu}
          getItemLabel={(item: MenuType) => item.label}
          onItemClick={(item) => this.handleRoute(item)}
          routeTo={(item) => item.path}
          isItemSelected={(item) => item.path === match.path}
          title={t("navbar.settings")}
        />
      </Sidebar>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    enableAdminLinksInSidebar: state.features.enableAdminLinksInSidebar,
    canAddAuthor: state.features.canAddAuthor
  };
};

export default compose(connect(mapStateToProps))(SettingsSidebar);

export { SettingsSidebar };
