/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const UserCircle = ({ width = "24", height = "24" }) => (
  <svg width={width} height={height} fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M18 20v-2.92a2.73 2.73 0 0 0-.61-1.8A3 3 0 0 0 14 14.19a6.6 6.6 0 0 1-4.06 0 3.12 3.12 0 0 0-.78-.14 3.21 3.21 0 0 0-2.58 1.23 2.73 2.73 0 0 0-.58 1.8V20H5v-2.83a3.86 3.86 0 0 1 .35-1.71 4.1 4.1 0 0 1 1.74-1.83 4.36 4.36 0 0 1 2.1-.52c.27 0 1.59.34 1.78.38a5.49 5.49 0 0 0 1 .09 5.41 5.41 0 0 0 1-.09l.72-.18a4 4 0 0 1 1.06-.2 4.36 4.36 0 0 1 2.1.52 4.05 4.05 0 0 1 1.74 1.83 3.86 3.86 0 0 1 .41 1.71V20z" />
    <path d="M12 2A10 10 0 1 1 2 12 10 10 0 0 1 12 2m0-1a11 11 0 1 0 11 11A11 11 0 0 0 12 1z" />
    <path d="M12 5a3 3 0 1 1-3 3 3 3 0 0 1 3-3m0-1a4 4 0 1 0 4 4 4 4 0 0 0-4-4z" />
  </svg>
);

export default UserCircle;
