/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export function convertToUTC(date: string) {
  try {
    if (date) {
      let dateObj = new Date(date);
      return Date.UTC(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate(), 0, 0, 0);
    }
    return null;
  } catch (_) {
    return NaN;
  }
}
