/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ContributorRole } from "api/contributor-roles";

export interface PartialAppState {
  contributorRoles: State;
}

export type UnsavedContributorRole = {
  id?: number;
  name: string;
  type?: string;
};

export type AnyContributorRole = ContributorRole | UnsavedContributorRole;

export interface uiState {
  showDeleteModal: boolean;
  errors: errorState;
}

export interface errorState {
  [key: string]: boolean;
}

export interface State {
  allContributorRoles: ContributorRole[];
  currentContributorRole: AnyContributorRole;
  ui: uiState;
}

export const INITIAL_ERROR_STATE: errorState = {};

export const INITIAL_UI_STATE: uiState = {
  errors: INITIAL_ERROR_STATE,
  showDeleteModal: false
};

export const INITIAL_CONTRIBUTOR_ROLE: UnsavedContributorRole = {
  name: ""
};

export const INITIAL_CONTRIBUTOR_ROLES_STATE: State = {
  allContributorRoles: [],
  currentContributorRole: INITIAL_CONTRIBUTOR_ROLE,
  ui: INITIAL_UI_STATE
};
