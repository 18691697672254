/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";

import {
  BreakingNews,
  saveBreakingNews as APISaveBreakingNews,
  ValidationErrors,
  BreakingNewsValidationFields,
  BNSocialCard,
  saveBreakingNewsStory
} from "api/breaking-news";
import {
  updateBreakingNewsAction,
  initializeNewBreakingNewsAction,
  setLinkTypeAction,
  updateStoryTemplateFieldsAction,
  cleanUpBreakingNewsStateAction,
  saveStoryTemplateFieldsAction,
  saveBreakingNewsAction,
  enableBreakingNewsPublishAction,
  breakingNewsSaveErrorAction,
  breakingNewsSaveSuccessAction,
  fetchSocialAccountsAction,
  showSocialAccountsAction,
  updateSocialAccountsAction,
  loadBreakingNewsStoryAction,
  breakingNewsStoryLoadErrorAction,
  breakingNewsStoryLoadSuccessAction
} from "./action-creators";
import {
  PartialAppState,
  INITIAL_BREAKING_NEWS,
  StoryTemplateFields,
  INITIAL_BREAKING_NEWS_STATE,
  PartialStoryTemplateFields,
  SocialAccount
} from "./state";
import {
  DEFAULT_BREAKING_NEWS_TYPE_SLUG,
  BreakingNewsType,
  DEFAULT_BREAKING_NEWS_TYPE_NAME
} from "api/route-data/breaking-news-route-data";
import { getDefaultLinkType, LinkType, linkTypeValues, getExistingStoryLinkType } from "./constants";
import {
  createAssociatedStory,
  getAutoFilledStoryTemplateFields,
  createSocialCard,
  getSelectedSocialAccountsForPublish,
  convertSocialAccountsForPublish
} from "./utils";
import { navigate } from "utils/routes.utils";
import { BREAKING_NEWS_ADVANCED_OPTIONS } from "./routes";
import { SocialAccountsResponse, getSocialAccounts, SocialAccountParams } from "api/social-accounts";
import { NOTIFICATION_ERROR, NOTIFICATION_SUCCESS } from "containers/page/actions";
import { t } from "i18n";
import { WORKSPACE_PATH } from "pages/workspace/routes";
import { UUID } from "api/primitive-types";
import { getStory, GetResponse, Story } from "api/story";
import { BreakingNewsNotFound } from "./errors";
import { SelectedMedia } from "api/social";
import { Entity } from "api/entity";

export const initializeNewBreakingNews = () => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const state = getState();

  let defaultBreakingNewsType = state.config["breaking-news-types"].find(
    (breakingNewsType) => breakingNewsType["story-group"] === DEFAULT_BREAKING_NEWS_TYPE_SLUG
  ) as BreakingNewsType;

  if (!defaultBreakingNewsType) {
    defaultBreakingNewsType = { "story-group": DEFAULT_BREAKING_NEWS_TYPE_SLUG, name: DEFAULT_BREAKING_NEWS_TYPE_NAME };
  }

  const newBreakingNewsInitialData: BreakingNews = {
    ...INITIAL_BREAKING_NEWS,
    "story-content-id": "new",
    metadata: {
      ...INITIAL_BREAKING_NEWS.metadata,
      "story-group": defaultBreakingNewsType["story-group"],
      "breaking-news-type": defaultBreakingNewsType.name,
      "collection-id": defaultBreakingNewsType["collection-id"]
    }
  };

  dispatch(
    initializeNewBreakingNewsAction({ breakingNews: newBreakingNewsInitialData, linkType: getDefaultLinkType() })
  );
};

export const fetchSocialAccounts = (params?: SocialAccountParams) => async (dispatch: ThunkDispatch<any, any, any>) => {
  try {
    const {
      "social-accounts": allSocialAccounts,
      "social-cards": socialCards
    }: SocialAccountsResponse = await getSocialAccounts(params);

    const socialAccounts = allSocialAccounts
      .filter((account) => account["is-active"])
      .map(
        (account) =>
          ({
            "account-id": account["account-id"],
            "account-name": account["account-name"],
            provider: account.provider,
            selected: false
          } as SocialAccount)
      );
    dispatch(
      fetchSocialAccountsAction({
        socialAccounts,
        isSocialAccountsAvailable: socialAccounts.length > 0
      })
    );

    if (params && params["story-id"] && socialCards) {
      dispatch(updateBreakingNewsAction({ ...INITIAL_BREAKING_NEWS, "social-publish": socialCards as BNSocialCard[] }));
    }
  } catch (e) {
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: {
        message: t("breaking-news.social-accounts-fetch-failure")
      }
    });
  }
};

export const updateSocialAccounts = (socialAccounts: SocialAccount[]) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(updateSocialAccountsAction(socialAccounts));
};

export const updateBreakingNews = (breakingNews: BreakingNews) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(updateBreakingNewsAction(breakingNews));
};

export const setLinkType = (linkType: LinkType) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const state = getState();
  const currentBreakingNewsState = state.breakingNews.breakingNews;

  const breakingNews = {
    headline: currentBreakingNewsState.headline,
    "story-content-id": currentBreakingNewsState["story-content-id"],
    "social-publish": currentBreakingNewsState["social-publish"] ? currentBreakingNewsState["social-publish"] : [],
    metadata: {
      "story-group": currentBreakingNewsState.metadata["story-group"],
      "breaking-news-type": currentBreakingNewsState.metadata["breaking-news-type"],
      "collection-id": currentBreakingNewsState.metadata["collection-id"]
    }
  } as BreakingNews;

  dispatch(setLinkTypeAction(linkType, breakingNews));

  //Select component triggers onChange event when same option is selected again
  //Adding this condition will make sure changes made to associated story will not be lost.
  if (linkType.value === linkTypeValues.CREATE_ASSOCIATED_STORY) {
    const autoFilledStoryTemplateFields = getAutoFilledStoryTemplateFields(
      state.config["template-fields"].story.all.config.fields,
      state.breakingNews.app.storyTemplateFields,
      state.breakingNews.app.savedStoryTemplateFields,
      state.config["associated-story-defaults"],
      state.breakingNews.breakingNews.headline
    );

    dispatch(updateStoryTemplateFields(autoFilledStoryTemplateFields));
  } else {
    dispatch(updateStoryTemplateFields(INITIAL_BREAKING_NEWS_STATE.app.storyTemplateFields));
    dispatch(saveStoryTemplateFieldsAction({}));
  }
};

export const showSocialAccounts = (showSocialAccounts: boolean) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(showSocialAccountsAction(showSocialAccounts));
};

export const updateStoryTemplateFields = (storyTemplateFields: StoryTemplateFields) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(updateStoryTemplateFieldsAction(storyTemplateFields));
};

export const saveStoryTemplateFields = (storyTemplateFields: PartialStoryTemplateFields) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(saveStoryTemplateFieldsAction(storyTemplateFields));
};

export const enableBreakingNewsPublish = (flag: boolean) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(enableBreakingNewsPublishAction(flag));
};

export const saveBreakingNews = () => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  dispatch(saveBreakingNewsAction());

  const state: PartialAppState = getState();
  let storyTemplateFields: StoryTemplateFields | null = null;

  try {
    const linkType = state.breakingNews.ui.linkType;

    let breakingNews = state.breakingNews.breakingNews;

    if (linkType && linkType.value === linkTypeValues.CREATE_ASSOCIATED_STORY) {
      storyTemplateFields = getAutoFilledStoryTemplateFields(
        state.config["template-fields"].story.all.config.fields,
        state.breakingNews.app.storyTemplateFields,
        state.breakingNews.app.savedStoryTemplateFields,
        state.config["associated-story-defaults"],
        state.breakingNews.breakingNews.headline
      );

      if (!storyTemplateFields.authors.length) {
        storyTemplateFields = {
          ...storyTemplateFields,
          authors: [{ id: state.config.member.id, name: state.config.member.name }]
        };
      }

      const associatedStory = createAssociatedStory(storyTemplateFields);
      breakingNews = { ...breakingNews, "associated-story": associatedStory };
    }

    const selectedAccountsForSocialPublish = getSelectedSocialAccountsForPublish(state.breakingNews.socialAccounts);
    if (selectedAccountsForSocialPublish.length > 0) {
      breakingNews = {
        ...breakingNews,
        "social-publish": [createSocialCard(breakingNews, selectedAccountsForSocialPublish)]
      };
    }

    await APISaveBreakingNews(breakingNews);
    dispatch(breakingNewsSaveSuccessAction());

    dispatch(navigate(`${WORKSPACE_PATH}/published`));

    dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: {
        message: t("breaking-news.publish-success")
      }
    });
  } catch (e) {
    if (e.status === 422) {
      try {
        const errorResponse = JSON.parse(e.message);
        const errors = errorResponse.error.errors;

        if (errors && errors["associated-story"]) {
          if (errors["associated-story"]["cards"]) {
            //prettier-ignore
            const [{"story-elements": [{ text: defaultContentError }]}] = errors["associated-story"]["cards"];
            errors["associated-story"]["cardContent"] = defaultContentError;
          }

          if (errors["associated-story"]["seo"]) {
            errors["associated-story"]["meta-title"] = errors["associated-story"]["seo"]["meta-title"];
            errors["associated-story"]["meta-keywords"] = errors["associated-story"]["seo"]["meta-keywords"];
            errors["associated-story"]["meta-description"] = errors["associated-story"]["seo"]["meta-description"];
          }
          const storyAttributesErrors = errors["associated-story"]["metadata"]["story-attributes"];
          if (storyAttributesErrors && !storyAttributesErrors.hasOwnProperty("code")) {
            errors["associated-story"]["metadata"] = errors["associated-story"]["metadata"]["story-attributes"];
          }
        }

        const validationErrors: ValidationErrors<BreakingNewsValidationFields> = errors;

        if (storyTemplateFields) {
          dispatch(updateStoryTemplateFields(storyTemplateFields));
        }

        dispatch(breakingNewsSaveErrorAction(validationErrors));
        const isErrorPresentInAdvancedFields = state.breakingNews.app.advancedStoryTemplateFields.some(
          (key) => validationErrors && validationErrors["associated-story"] && validationErrors["associated-story"][key]
        );

        if (isErrorPresentInAdvancedFields) {
          dispatch(navigate(BREAKING_NEWS_ADVANCED_OPTIONS));
        }

        dispatch({
          type: NOTIFICATION_ERROR,
          payload: {
            message: t("breaking-news.validation-errors")
          }
        });
      } catch (e) {
        dispatch({
          type: NOTIFICATION_ERROR,
          payload: {
            message: t("breaking-news.server-error")
          }
        });
      }
    } else {
      dispatch({
        type: NOTIFICATION_ERROR,
        payload: {
          message: t("breaking-news.server-error")
        }
      });
    }
  }
};

export const loadBreakingNews = (id: UUID) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  dispatch(loadBreakingNewsStoryAction());

  try {
    const { story }: GetResponse = await getStory(id, {});
    const breakingNewsState = getState().breakingNews;

    let breakingNews: BreakingNews = {
      ...breakingNewsState.breakingNews,
      "story-content-id": id,
      headline: story.headline,
      metadata: story.metadata
    };

    if (story["breaking-news-linked-story-id"]) {
      breakingNews = {
        ...breakingNews,
        "breaking-news-linked-story-id": story["breaking-news-linked-story-id"]
      };
    }

    const socialPublish = breakingNewsState.breakingNews["social-publish"];
    if (socialPublish && socialPublish.length && socialPublish[0].selectedMedia.length) {
      const publishedAccounts = socialPublish[0].selectedMedia as SelectedMedia[];

      const findMatchedAccount = (socialAccount: SocialAccount) =>
        publishedAccounts.find(({ id, type }) => id === socialAccount["account-id"] && type === socialAccount.provider);

      const socialAccounts = breakingNewsState.socialAccounts.map((socialAccount) => {
        const matchedAccount = findMatchedAccount(socialAccount);

        if (matchedAccount) {
          return { ...socialAccount, selected: true, status: matchedAccount.status };
        }

        return socialAccount;
      });

      dispatch(showSocialAccountsAction(true));
      dispatch(updateSocialAccountsAction(socialAccounts));
    }

    dispatch(setLinkTypeAction(getExistingStoryLinkType(), breakingNews));
    dispatch(breakingNewsStoryLoadSuccessAction(story, breakingNews));
    dispatch(enableBreakingNewsPublish(true));
  } catch (e) {
    if (e.status === 404) {
      dispatch(breakingNewsStoryLoadErrorAction(new BreakingNewsNotFound(t("breaking-news.not-found"), id)));
    } else {
      dispatch(breakingNewsStoryLoadErrorAction(null));
    }
  }
};

export const saveExistingBreakingNews = () => async (
  dispatch: ThunkDispatch<PartialAppState, any, any>,
  getState: () => PartialAppState
) => {
  dispatch(saveBreakingNewsAction());

  const breakingNewsState = getState().breakingNews;
  const breakingNews = breakingNewsState.breakingNews;
  const selectedSocialAccounts = getSelectedSocialAccountsForPublish(breakingNewsState.socialAccounts);

  let breakingNewsStory = breakingNewsState.breakingNewsStory as Story;

  breakingNewsStory = { ...breakingNewsStory, headline: breakingNews.headline, metadata: breakingNews.metadata };

  if (breakingNews["breaking-news-linked-story-id"]) {
    breakingNewsStory = {
      ...breakingNewsStory,
      "breaking-news-linked-story-id": breakingNews["breaking-news-linked-story-id"]
    };
  }

  const socialPublish = breakingNews["social-publish"];

  if (selectedSocialAccounts.length && socialPublish && socialPublish.length) {
    let socialCard = socialPublish[0];
    socialCard = { ...socialCard, selectedMedia: convertSocialAccountsForPublish(selectedSocialAccounts) };
    breakingNewsStory = { ...breakingNewsStory, "social-publish": [socialCard] };
  }

  try {
    await saveBreakingNewsStory(breakingNewsStory);

    dispatch(breakingNewsSaveSuccessAction());
    dispatch(navigate(`${WORKSPACE_PATH}/published`));

    dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: {
        message: t("breaking-news.publish-success")
      }
    });
  } catch (error) {
    dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: {
        message: t("breaking-news.server-error")
      }
    });
  }
};

export const cleanUpBreakingNewsState = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(cleanUpBreakingNewsStateAction());
};

export const updateMandatoryStoryAttributes = (key: string, value: Array<Entity | string>) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const storyTemplateFields = getState().breakingNews.app.storyTemplateFields;
  const metadata = storyTemplateFields.metadata;
  const storyAttribute =
    metadata && metadata["story-attributes"]
      ? { ...metadata["story-attributes"], ...{ [key]: value } }
      : { [key]: value };

  const changedMetadata = {
    ...metadata,
    "story-attributes": storyAttribute
  };
  const newStoryTemplateFields: StoryTemplateFields = { ...storyTemplateFields, ...{ metadata: changedMetadata } };

  dispatch(updateStoryTemplateFields(newStoryTemplateFields));
};
