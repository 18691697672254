/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Download = ({ width = "24", height = "24", color = "#5f6978" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill={color} width={width} height={height}>
    <path d="M15,12.5 L15,15 L5,15 L5,12.5 L3.33333333,12.5 L3.33333333,15 C3.33333333,15.9166667 4.08333333,16.6666667 5,16.6666667 L15,16.6666667 C15.9166667,16.6666667 16.6666667,15.9166667 16.6666667,15 L16.6666667,12.5 L15,12.5 Z M14.1666667,9.16666667 L12.9916667,7.99166667 L10.8333333,10.1416667 L10.8333333,3.33333333 L9.16666667,3.33333333 L9.16666667,10.1416667 L7.00833333,7.99166667 L5.83333333,9.16666667 L10,13.3333333 L14.1666667,9.16666667 Z"></path>
  </svg>
);

export default Download;
