/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { CustomField } from "api/route-data/story-route-data";
import { get, has, isEmpty } from "lodash";
import { StoryTemplateFields } from "pages/breaking-news/state";
interface TemplateMapping {
  name: string;
  slug: string;
  id: number;
  "ancestor-ids": never[];
}

export function getTemplateCustomMetadata(
  customMetadataFields: CustomField[],
  template: string,
  storyTemplates: Partial<StoryTemplateFields>
): CustomField[] {
  if (isEmpty(customMetadataFields) || !template) return [];

  return customMetadataFields.filter(({ config }: CustomField) => {
    const enabledForAllTemplates =
      !has(config, "enabled-for-all-templates") || get(config, "enabled-for-all-templates");

    const enabledTemplates = get(config, "content-templates", []) as string[];
    const isActiveTemplate = template in storyTemplates && storyTemplates[template].toggle;

    return enabledForAllTemplates || (enabledTemplates.includes(template) && isActiveTemplate);
  });
}

export function getSelectedTemplateMapping(currentCustomField, storyTemplates) {
  if (!currentCustomField) return [];
  const enabledTemplates = get(currentCustomField, ["config", "content-templates"], []);

  return Object.keys(storyTemplates)
    .filter((template) => enabledTemplates.includes(template))
    .reduce((acc: TemplateMapping[], template) => {
      acc.push({
        name: storyTemplates[template].name,
        slug: storyTemplates[template].slug,
        id: storyTemplates[template].id,
        "ancestor-ids": []
      });
      return acc;
    }, []);
}
