/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import AuthorComponent from "../author/author";
import { Author } from "api/author";
import { MemberId } from "api/primitive-types";

interface Props {
  story: {
    authors: Author[] | null;
    "author-id": MemberId;
    "author-name": string;
  };
}

const Authors: React.SFC<Props> = ({ story }) => {
  const firstAuthor =
    story.authors && story.authors.length > 0 && story.authors[0]
      ? story.authors[0]
      : { id: story["author-id"], name: story["author-name"] };
  const totalAuthors = story.authors && (story.authors.length <= 1 ? null : story.authors.length - 1);
  return (
    <React.Fragment>
      <AuthorComponent key={`story-author-${firstAuthor.id}`} author={firstAuthor} />
      {totalAuthors && <span>{` +${totalAuthors}`}</span>}
    </React.Fragment>
  );
};

export default Authors;
