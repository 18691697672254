/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AnyRole } from "api/roles";

export function validateRole(roleWithAPICompatibleFormat: AnyRole) {
  const namePresenceError = roleWithAPICompatibleFormat.name === "" ? ["Name is required"] : undefined;
  const actionsPresenceError =
    roleWithAPICompatibleFormat.actions && roleWithAPICompatibleFormat.actions.length === 0
      ? ["Atleast one action is required"]
      : undefined;
  if (namePresenceError || actionsPresenceError) {
    const errors = {
      name: namePresenceError,
      actions: actionsPresenceError
    };
    return errors;
  } else {
    return undefined;
  }
}
