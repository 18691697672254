/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import cx from "classnames";

type FieldProps = {
  fieldName: string;
  bypassFilter?: boolean;
  classes?: string[];
  render: () => React.ReactElement<any>;
};

const Field: React.SFC<FieldProps> = ({ classes = [], render }) => (
  <div className={cx("breaking-news-form-field-container", classes)}>{render()}</div>
);

export default Field;
