/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, { ActionTypes } from "./actions";
import { INITIAL_INTEGRATIONS_STATE, State } from "./state";
import { convertAPIDataToUICompatibleFormat, getIntegrationsAvailableToAdd } from "./utils";

export default function integrationReducer(state: State = INITIAL_INTEGRATIONS_STATE, action: ActionTypes) {
  switch (action.type) {
    case actions.LIST_PUBLISHER_INTEGRATIONS_SUCCESS: {
      return {
        ...state,
        publisherIntegrationsAPIData: action.payload.publisherIntegrationsAPIData,
        app: {
          ...state.app,
          publisherIntegrations: convertAPIDataToUICompatibleFormat(action.payload.publisherIntegrationsAPIData)
        }
      };
    }

    case actions.LIST_PUBLISHER_INTEGRATIONS_FAILURE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          main: { loading: false, error: action.payload.error }
        }
      };
    }

    case actions.LIST_SUPPORTED_INTEGRATIONS_SUCCESS: {
      return {
        ...state,
        supportedIntegrations: action.payload.supportedIntegrations,
        app: {
          ...state.app,
          listIntegrationsAvailableToAdd:
            state.publisherIntegrationsAPIData &&
            getIntegrationsAvailableToAdd(
              state.publisherIntegrationsAPIData,
              action.payload.supportedIntegrations,
              state.app.integrationType
            )
        }
      };
    }

    case actions.LIST_SUPPORTED_INTEGRATIONS_FAILURE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          main: { loading: false, error: action.payload.error }
        }
      };
    }

    //on selecting integration from inspector
    case actions.SET_SELECTED_INTEGRATION: {
      return {
        ...state,
        app: {
          ...state.app,
          selectedIntegration: {
            key: action.payload.selectedIntegrationName,
            value: state.supportedIntegrations && state.supportedIntegrations[action.payload.selectedIntegrationName]
          }
        }
      };
    }
    //on update of reach field from inspect
    case actions.UPDATE_SELECTED_INTEGRATION_FIELD: {
      return {
        ...state,
        app: {
          ...state.app,
          selectedIntegration: state.app.selectedIntegration && {
            ...state.app.selectedIntegration,
            value: { ...state.app.selectedIntegration.value, ...action.payload.changes }
          }
        }
      };
    }

    case actions.START_LOADING_WITH_NO_ERROR: {
      return {
        ...state,
        ui: {
          ...state.ui,
          main: { loading: true, error: null }
        }
      };
    }
    case actions.STOP_LOADING_WITH_NO_ERROR: {
      return {
        ...state,
        ui: {
          ...state.ui,
          main: { loading: false, error: null }
        }
      };
    }

    case actions.EDIT_INTEGRATION: {
      return {
        ...state,
        app: {
          ...state.app,
          selectedIntegration: {
            key: action.payload.integrationName,
            value:
              state.publisherIntegrationsAPIData && state.publisherIntegrationsAPIData[action.payload.integrationName]
          }
        }
      };
    }
    case actions.CANCEL_INTEGRATION: {
      return {
        ...state,
        app: {
          ...state.app,
          selectedIntegration: {}
        }
      };
    }
    case actions.SET_INTEGRATION_SAVE_ERRORS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          saveErrors: { loading: false, error: action.payload.errors }
        }
      };
    }
    case actions.SET_INTEGRATION_VALIDATE_RESULT: {
      return {
        ...state,
        app: {
          ...state.app,
          selectedIntegration: {
            ...state.app.selectedIntegration,
            isValid: action.payload.isValid
          }
        }
      };
    }
    case actions.SET_INTEGRATION_VALIDATE_IN_PROGRESS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isValidateInProgress: action.payload.inProgress
        }
      };
    }
    case actions.UPDATE_PUBLISHER_SETTINGS: {
      return {
        ...state,
        app: {
          ...state.app,
          publisher: action.payload
        }
      };
    }
    case actions.UPDATE_INTEGRATION_LIST_BY_TYPE: {
      return {
        ...state,
        app: {
          ...state.app,
          integrationListByType: action.payload
        }
      };
    }
    case actions.SET_INTEGRATION_TYPE: {
      return {
        ...state,
        app: {
          ...state.app,
          integrationType: action.payload
        }
      };
    }
    default:
      return state;
  }
}
