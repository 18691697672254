/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";

import { saveSettingsPageDataAction } from "./async-action-creators";
import Button from "components/button/button";
import Header from "components/header/header";
import { PartialAppState } from "../../initial-state";
import { SocialLinks, GeneralSettings } from "api/settings";
import { SEOMetadata } from "api/seo-metadata";

interface StateProps {
  generalSettings: GeneralSettings | null;
  socialLinks: SocialLinks | null;
  seoMetadata: SEOMetadata | null;
  showSaveButton: boolean;
}

interface DispatchProps {
  saveSettings: (
    generalSettings: GeneralSettings | null,
    socialLinks: SocialLinks | null,
    seoMetadata: SEOMetadata | null
  ) => void;
}

type Props = StateProps & DispatchProps;

class SettingsHeader extends React.Component<Props> {
  render() {
    const { showSaveButton, saveSettings, generalSettings, socialLinks, seoMetadata } = this.props;
    return (
      <React.Fragment>
        <Header>
          {showSaveButton && (
            <Button
              variant="success"
              type="primary"
              onClick={() => saveSettings(generalSettings, socialLinks, seoMetadata)}>
              {t("save")}
            </Button>
          )}
        </Header>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    generalSettings: state.settingsPage.generalSettings,
    socialLinks: state.settingsPage.socialLinks,
    seoMetadata: state.settingsPage.seoMetadata,
    showSaveButton: state.settingsPage.ui.isSettingsModified
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    saveSettings: (generalSettings, socialLinks, seoMetadata) =>
      dispatch(saveSettingsPageDataAction(generalSettings, socialLinks, seoMetadata))
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(SettingsHeader);
