/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface SuperScriptIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const SuperScript: React.SFC<SuperScriptIconProps> = ({ width = "24", height = "24", color = "currentColor" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 24 24">
    <path
      fillRule="nonzero"
      d="M8.472 7c1.648 0 2.912.412 3.792 1.236.88.824 1.32 2.044 1.32 3.66v7.824h-1.632v-1.968c-.384.656-.948 1.168-1.692 1.536-.744.368-1.628.552-2.652.552-1.408 0-2.528-.336-3.36-1.008C3.416 18.16 3 17.272 3 16.168c0-1.072.388-1.936 1.164-2.592.776-.656 2.012-.984 3.708-.984h4.008v-.768c0-1.088-.304-1.916-.912-2.484-.608-.568-1.496-.852-2.664-.852-.8 0-1.568.132-2.304.396a5.806 5.806 0 0 0-1.896 1.092l-.768-1.272c.64-.544 1.408-.964 2.304-1.26A8.97 8.97 0 0 1 8.472 7zm-.6 11.496c.96 0 1.784-.22 2.472-.66.688-.44 1.2-1.076 1.536-1.908v-2.064H7.92c-2.16 0-3.24.752-3.24 2.256 0 .736.28 1.316.84 1.74.56.424 1.344.636 2.352.636zM17.408 10.564c-.648 0-1.232-.14-1.752-.42-.52-.28-.926-.67-1.218-1.17A3.304 3.304 0 0 1 14 7.276c0-.632.146-1.196.438-1.692a3.075 3.075 0 0 1 1.218-1.164c.52-.28 1.104-.42 1.752-.42.656 0 1.244.14 1.764.42.52.28.926.668 1.218 1.164.292.496.438 1.06.438 1.692 0 .632-.146 1.198-.438 1.698-.292.5-.698.89-1.218 1.17-.52.28-1.108.42-1.764.42zm0-1.284c.552 0 1.008-.184 1.368-.552.36-.368.54-.852.54-1.452s-.18-1.084-.54-1.452c-.36-.368-.816-.552-1.368-.552-.552 0-1.006.184-1.362.552-.356.368-.534.852-.534 1.452s.178 1.084.534 1.452c.356.368.81.552 1.362.552z"
    />
  </svg>
);

export default SuperScript;
