/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import {
  actions,
  SetWorkflowTransitions,
  UpdateTemplateFields,
  UpdateEditorConfig,
  AddTemplateCards,
  DeleteDefaultCard,
  LoadSocialAccountsAndCards,
  AddSocialNotificationsHistory,
  DeleteStorySocialCard,
  SetOembedUrl,
  ToggleStoryElementLoading,
  SetStoryElementError,
  UpdateOembedUrl,
  RemoveStoryElementError,
  InitEditorState,
  LoadStoryData,
  LoadRecommendedTags,
  UpdateStoryAttribute,
  UpdateEntitiesStoryAttribute,
  UpdateStoryTag,
  StoryEditorAddStoryElement,
  StoryEditorDeleteStoryElement,
  SetPublishInspectorValidationErrors,
  SetStory,
  SetIsStoryModifiedState,
  SetStoryValidationErrors,
  UpdateStoryStatus,
  SetSelectedHeroImage,
  UpdateImageElement,
  UpdateAlternative,
  SetImageForInspector,
  CardShareUpdate,
  CardShareImageDelete,
  DeleteStoryEditorCard,
  UpdateInspectorData,
  SetTimelineEvent,
  ResetTimeline,
  OpenStoryRestoreModal,
  CloseStoryRestoreModal,
  UpdateUIContributions,
  LoadAuthorContributions,
  UpdateAuthorContributions,
  FetchOpinionPoll,
  SetOpinionPoll,
  UpdateOpinionPoll,
  SaveOpinionPoll,
  SetImagesForImageGalleryElement,
  SetOpinionPollId,
  SetOpinionPollSuccess,
  SetOpinionPollInit,
  UpdateOpinionPollImageUploadStatus,
  UpdateStorySearchTerm,
  SetStoryList,
  SetSelectedStory,
  UpdateImportCardId,
  ImportCard,
  ResetImportCard,
  ToggleStoryCleanUpConfirmation,
  ResetStoryEditorState,
  SetBanner,
  StartSavingStory,
  StopSavingStory,
  StoryEditorSplitStoryElement,
  PublishingStoryInit,
  PublishingStoryFailure,
  PlagiarismCheckInit,
  PlagiarismCheckSuccess,
  PlagiarismCheckFailure,
  ResetImportCardDisplay,
  UpdateSelectedPlace,
  ToggleStorySeoChecked,
  UpdateStoryElementEntities,
  SetFormattingToolbarActive,
  SetFormattingToolbarInActive,
  UpdateEditorNotes,
  StoryChangeInProgress,
  VideoOEmbedSearchTermChange,
  VideoOEmbedSearchSuccess,
  ToggleOEmbedInspectorSearchFilter,
  VideoOEmbedSearchFiltersChange,
  VideoOEmbedSearchReset,
  SetStoryTransitionInProgress,
  SetIsViewingReadOnlyVersion,
  UpdateStory,
  UpdateStoryFeatures,
  ReapplyEditorState,
  SetEditorState,
  SetTextParaphrasingStatus
} from "./actions";
import { Fields, VideoOEmbedFilter } from "api/route-data/story-route-data";
import {
  AnyContribution,
  Story,
  EditorConfig,
  StoryElement,
  WorkflowTransitions,
  StoryStatus,
  CompositeStoryElement,
  ChildStoryElement,
  Contribution,
  Card,
  StoryTag,
  StoryEntity,
  UnsavedStory
} from "api/story";
import { SocialAccount, SocialCard } from "api/social";
import { CardId, StoryElementId, StoryId, PolltypeId } from "api/primitive-types";
import { Image, ImageOrNewImage } from "api/search-media-image";
import { StoryElementDirection } from "./operations/story-elements/add";
import { InspectorData } from "./components/inspector/inspector-data";
import { Poll, UnsavedPoll } from "api/polls";
import { TimelineEvent } from "api/activity-log";
import { SearchedStory } from "api/search";
import { Entity } from "api/entity";
import { Video as PralineVideo } from "api/praline";
import { EditorState } from "prosemirror-state";

export function updateTemplateFields(fields: Fields): UpdateTemplateFields {
  return {
    type: actions.UPDATE_TEMPLATE_FIELDS,
    payload: {
      storyTemplateFields: fields
    }
  };
}

export function updateEditorConfig(editorConfig: EditorConfig): UpdateEditorConfig {
  return {
    type: actions.UPDATE_EDITOR_CONFIG,
    payload: { editorConfig }
  };
}

export function addTemplateCards(): AddTemplateCards {
  return { type: actions.ADD_TEMPLATE_CARDS };
}

export function deleteDefaultCard(): DeleteDefaultCard {
  return { type: actions.DELETE_DEFAULT_CARD };
}

export function loadSocialAccountsAndCards(
  socialAccounts: SocialAccount[],
  socialCards: SocialCard[]
): LoadSocialAccountsAndCards {
  return {
    type: actions.LOAD_SOCIAL_ACCOUNTS_AND_CARDS,
    payload: { socialCards, socialAccounts }
  };
}

export function addSocialNotificationsHistory(
  socialAccounts: SocialAccount[],
  socialCards: SocialCard[],
  socialCardsHistory: SocialCard[]
): AddSocialNotificationsHistory {
  return {
    type: actions.ADD_SOCIAL_NOTIFICATIONS_HISTORY,
    payload: { socialCards, socialAccounts, socialCardsHistory }
  };
}

export function deleteStorySocialCard(cardId: CardId): DeleteStorySocialCard {
  return { type: actions.DELETE_STORY_SOCIAL_CARD, payload: { cardId } };
}

export function setOembedUrl(storyElementID: StoryElementId, changes: any): SetOembedUrl {
  return { type: actions.SET_OEMBED_URL, payload: { id: storyElementID, changes } };
}

export function toggleStoryElementLoading(storyElementID: StoryElementId): ToggleStoryElementLoading {
  return { type: actions.TOGGLE_STORY_ELEMENT_LOADING, payload: { id: storyElementID } };
}

export function setStoryElementError(storyElementID: StoryElementId, error: string | {}): SetStoryElementError {
  return {
    type: actions.SET_STORY_ELEMENT_ERROR,
    payload: { id: storyElementID, error: error }
  };
}

export function updateOembedUrl(id: StoryElementId, url: string): UpdateOembedUrl {
  return {
    type: actions.UPDATE_OEMBED_URL,
    payload: { id, url }
  };
}

export function removeStoryElementError(id: StoryElementId): RemoveStoryElementError {
  return {
    type: actions.REMOVE_STORY_ELEMENT_ERROR,
    payload: { id }
  };
}

export function initEditorState(story: Story | UnsavedStory, opts: { [key: string]: any }): InitEditorState {
  return { type: actions.INIT_EDITOR_STATE, payload: { story, opts } };
}

export function loadStoryData(story: Story | UnsavedStory): LoadStoryData {
  return { type: actions.LOAD_STORY_DATA, payload: { story } };
}

export function loadRecommendedTags(tags: any): LoadRecommendedTags {
  return { type: actions.LOAD_RECOMMENDED_TAGS, payload: { tags } };
}

export function updateStory(key: string, value: any): UpdateStory {
  return { type: actions.UPDATE_STORY, payload: { key, value } };
}

export function updateStoryFeatures(feature: string, changes: any): UpdateStoryFeatures {
  return { type: actions.UPDATE_STORY_FEATURES, payload: { feature, changes } };
}

export function updateStoryAttribute(key: string, value: Array<string | Entity>): UpdateStoryAttribute {
  return { type: actions.UPDATE_STORY_ATTRIBUTE, payload: { key, value } };
}

export function updateEntitiesStoryAttribute(key: string, value: Array<Entity>): UpdateEntitiesStoryAttribute {
  return { type: actions.UPDATE_ENTITIES_STORY_ATTRIBUTE, payload: { key, value } };
}

export function updateStoryElementEntitiesAction(
  storyElementId: StoryElementId,
  newEntityAttribution: Entity | null
): UpdateStoryElementEntities {
  return {
    type: actions.UPDATE_STORY_ELEMENT_ENTITIES,
    payload: { storyElementId, newEntityAttribution }
  };
}

export function updateStoryTag(
  tags: Array<StoryTag | StoryEntity>,
  tagEntities: Array<StoryEntity>,
  updatedKeywords: string[] | undefined
): UpdateStoryTag {
  return { type: actions.UPDATE_STORY_TAG, payload: { tags, tagEntities, updatedKeywords } };
}

// export function storyEditorAddStoryElement(element: StoryElement): StoryEditorAddStoryElement {
//   return { type: actions.STORY_EDITOR_ADD_STORY_ELEMENT, payload: { element } };
// }
export function setPublishInspectorValidationErrors(message: string): SetPublishInspectorValidationErrors {
  return {
    type: actions.SET_PUBLISH_INSPECTOR_VALIDATION_ERRORS,
    payload: { message }
  };
}

export function setStory(story: Story, workflowTransitions: WorkflowTransitions): SetStory {
  return {
    type: actions.SET_STORY,
    payload: { story, workflowTransitions }
  };
}

export function setIsStoryModifiedState(isStoryModified: boolean): SetIsStoryModifiedState {
  return { type: actions.SET_IS_STORY_MODIFIED_STATE, payload: { isStoryModified: isStoryModified } };
}

export function setStoryValidationErrors(errors: any): SetStoryValidationErrors {
  return { type: actions.SET_STORY_VALIDATION_ERRORS, payload: { errors } };
}

export function updateStoryStatusAction(
  workflowTransitions: WorkflowTransitions,
  status: StoryStatus,
  taskId: string
): UpdateStoryStatus {
  return {
    type: actions.UPDATE_STORY_STATUS,
    payload: { workflowTransitions, status, taskId }
  };
}

export function setWorkflowTransitions(workflowTransitions: WorkflowTransitions): SetWorkflowTransitions {
  return { type: actions.SET_WORKFLOW_TRANSITIONS, payload: { workflowTransitions } };
}

export function setSelectedHeroImage(image: ImageOrNewImage): SetSelectedHeroImage {
  return { type: actions.SET_SELECTED_HERO_IMAGE, payload: image };
}

export function updateImageElement(id: StoryElementId, changes: Image): UpdateImageElement {
  return { type: actions.UPDATE_IMAGE_ELEMENT, payload: { id, changes } };
}

export function updateAlternative(location: string, key: string, value: ImageOrNewImage | string): UpdateAlternative {
  return { type: actions.UPDATE_ALTERNATIVE, payload: { location, key, value } };
}

export function setImageForInspector(image: Image | {}): SetImageForInspector {
  return { type: actions.SET_IMAGE_FOR_INSPECTOR, payload: { image: [image] } };
}

export function cardShareUpdate(key: string, value: Image | string, cardId: CardId): CardShareUpdate {
  return { type: actions.CARD_SHARE_UPDATE, payload: { key, value, cardId } };
}

export function cardShareImageDelete(cardId: CardId): CardShareImageDelete {
  return { type: actions.CARD_SHARE_IMAGE_DELETE, payload: { cardId } };
}

export function updateContributionsAction(contributions: AnyContribution[]): UpdateUIContributions {
  return { type: actions.UPDATE_UI_CONTRIBUTIONS, payload: contributions };
}

export function loadAuthorContributionsAction(contributions: Contribution[]): LoadAuthorContributions {
  return { type: actions.LOAD_AUTHOR_CONTRIBUTIONS, payload: contributions };
}

export function updateAuthorContributionsAction(contributions: Contribution[]): UpdateAuthorContributions {
  return { type: actions.UPDATE_AUTHOR_CONTRIBUTIONS, payload: contributions };
}

export function storyEditorAddStoryElement(
  newStoryElement: StoryElement | CompositeStoryElement | ChildStoryElement,
  currentStoryElement: StoryElement | null,
  addNewElementAt: StoryElementDirection
): StoryEditorAddStoryElement {
  return {
    type: actions.STORY_EDITOR_ADD_STORY_ELEMENT,
    payload: {
      newStoryElement,
      currentStoryElement,
      addNewElementAt
    }
  };
}

export function storyEditorSplitStoryElement(
  newStoryElement: StoryElement | CompositeStoryElement | ChildStoryElement,
  currentStoryElement: StoryElement,
  newTextElement: StoryElement | CompositeStoryElement | ChildStoryElement,
  addNewElementAt: number
): StoryEditorSplitStoryElement {
  return {
    type: actions.STORY_EDITOR_SPLIT_STORY_ELEMENT,
    payload: {
      newStoryElement,
      currentStoryElement,
      newTextElement,
      addNewElementAt
    }
  };
}

export function storyEditorDeleteStoryElement(
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement
): StoryEditorDeleteStoryElement {
  return { type: actions.STORY_EDITOR_DELETE_STORY_ELEMENT, payload: { storyElement } };
}

export function deleteStoryEditorCard(cardId: CardId): DeleteStoryEditorCard {
  return { type: actions.DELETE_STORY_EDITOR_CARD, payload: { cardId } };
}

export function addNewCard() {
  return { type: actions.ADD_NEW_CARD };
}

export function updateInspectorData(data: InspectorData): UpdateInspectorData {
  return { type: actions.UPDATE_INSPECTOR_DATA, payload: { data } };
}

export function openStoryRestoreModal(value: boolean, versionId: StoryId): OpenStoryRestoreModal {
  return { type: actions.OPEN_STORY_RESTORE_MODAL, payload: { value, versionId } };
}

export function closeStoryRestoreModal(): CloseStoryRestoreModal {
  return { type: actions.CLOSE_STORY_RESTORE_MODAL };
}

export function setTimelineEvent(event: TimelineEvent): SetTimelineEvent {
  return { type: actions.SET_TIMELINE_EVENT, payload: { event } };
}

export function resetTimeline(): ResetTimeline {
  return { type: actions.RESET_TIMELINE };
}

export function updateEmbargo(timestamp: number | null) {
  return { type: actions.UPDATE_EMBARGO, payload: { embargoedTill: timestamp } };
}

export function setIsViewingReadOnlyVersion(isViewingReadOnlyVersion): SetIsViewingReadOnlyVersion {
  return { type: actions.SET_IS_VIEWING_READONLY_VERSION, payload: { isViewingReadOnlyVersion } };
}

export function fetchOpinionPoll(): FetchOpinionPoll {
  return {
    type: actions.FETCH_OPINION_POLL
  };
}

export function setOpinionPoll(storyElementId: StoryElementId, poll: Poll | UnsavedPoll): SetOpinionPoll {
  return {
    type: actions.SET_OPINION_POLL,
    payload: { storyElementId, poll }
  };
}

export function updateOpinionPoll(storyElementId: StoryElementId, key: string, value: any): UpdateOpinionPoll {
  return {
    type: actions.UPDATE_OPINION_POLL,
    payload: { storyElementId, key, value }
  };
}

export function saveOpinionPoll(storyElementId: StoryElementId): SaveOpinionPoll {
  return {
    type: actions.SAVE_OPINION_POLL,
    payload: { storyElementId }
  };
}

export function updateOpinionPollImageUploadStatus(uploading: boolean): UpdateOpinionPollImageUploadStatus {
  return {
    type: actions.UPDATE_OPINION_POLL_IMAGE_UPLOAD_STATUS,
    payload: { uploading }
  };
}

export function setImagesForImageGalleryElement(
  storyElementId: StoryElementId,
  images: Image[]
): SetImagesForImageGalleryElement {
  return {
    type: actions.SET_IMAGES_FOR_IMAGE_GALLERY_ELEMENT,
    payload: { storyElementId, images }
  };
}

export function setOpinionPollId(storyElementId: StoryElementId, pollId: PolltypeId): SetOpinionPollId {
  return {
    type: actions.SET_OPINION_POLL_ID,
    payload: { storyElementId, pollId }
  };
}

export function setOpinionPollInit(storyElementId: StoryElementId): SetOpinionPollInit {
  return {
    type: actions.SET_OPINION_POLL_INIT,
    payload: {
      storyElementId
    }
  };
}

export function setOpinionPollSuccess(storyElementId: StoryElementId): SetOpinionPollSuccess {
  return {
    type: actions.SET_OPINION_POLL_SUCCESS,
    payload: {
      storyElementId
    }
  };
}

export function updateStorySearchTerm(term: string): UpdateStorySearchTerm {
  return {
    type: actions.UPDATE_STORY_SEARCH_TERM,
    payload: { term }
  };
}
export function setStoryList(stories: SearchedStory[]): SetStoryList {
  return {
    type: actions.SET_STORY_LIST,
    payload: { storyList: stories }
  };
}

export function setSelectedStory(selectedStory: Story): SetSelectedStory {
  return {
    type: actions.SET_SELECTED_STORY,
    payload: { selectedStory }
  };
}
export function updateImportCardId(cardId: CardId): UpdateImportCardId {
  return {
    type: actions.UPDATE_IMPORT_CARD_ID,
    payload: { cardId }
  };
}
export function importCard(
  card: Card,
  storyElements: Array<StoryElement | CompositeStoryElement | ChildStoryElement>
): ImportCard {
  return {
    type: actions.IMPORT_CARD,
    payload: { card, storyElements }
  };
}
export function resetImportCard(): ResetImportCard {
  return {
    type: actions.RESET_IMPORT_CARD
  };
}
export function resetImportCardDisplay(): ResetImportCardDisplay {
  return {
    type: actions.RESET_IMPORT_CARD_DISPLAY
  };
}
export function toggleStoryCleanUpConfirmation(action?: any): ToggleStoryCleanUpConfirmation {
  return {
    type: actions.TOGGLE_STORY_CLEAN_UP_CONFIRMATION,
    payload: action
  };
}
export function toggleStorySeoChecked(): ToggleStorySeoChecked {
  return {
    type: actions.TOGGLE_STORY_SEO_CHECKED
  };
}
export function resetStoryEditorState(): ResetStoryEditorState {
  return {
    type: actions.RESET_STORY_EDITOR_STATE
  };
}
export function setBanner(banner: {} | null): SetBanner {
  return {
    type: actions.SET_BANNER,
    payload: { banner }
  };
}

export function startSavingStory(): StartSavingStory {
  return {
    type: actions.START_SAVING_STORY
  };
}
export function stopSavingStory(): StopSavingStory {
  return {
    type: actions.STOP_SAVING_STORY
  };
}

export function storyChangeInProgress(): StoryChangeInProgress {
  return {
    type: actions.STORY_CHANGE_IN_PROGRESS
  };
}

export function publishStoryInit(): PublishingStoryInit {
  return {
    type: actions.PUBLISHING_STORY_INIT
  };
}

export function publishStoryFailure(): PublishingStoryFailure {
  return {
    type: actions.PUBLISHING_STORY_FAILURE
  };
}

export function plagiarismCheckInit(): PlagiarismCheckInit {
  return {
    type: actions.PLAGIARISM_CHECK_INIT
  };
}

export function plagiarismCheckSuccess(message: string, url: string): PlagiarismCheckSuccess {
  return {
    type: actions.PLAGIARISM_CHECK_SUCCESS,
    payload: {
      message,
      url
    }
  };
}

export function plagiarismCheckFailure(error: Error): PlagiarismCheckFailure {
  return {
    type: actions.PLAGIARISM_CHECK_FAILURE,
    payload: {
      error
    }
  };
}

export function updateSelectedPlace(place: string): UpdateSelectedPlace {
  return {
    type: actions.UPDATE_SELECTED_PLACE,
    payload: {
      place
    }
  };
}

export function setStoryTransitionStatus(isTransitionInProgress: boolean): SetStoryTransitionInProgress {
  return { type: actions.SET_STORY_TRANSITION_STATUS, payload: { isTransitionInProgress } };
}

export function setFormattingToolbarActive(): SetFormattingToolbarActive {
  return { type: actions.SET_FORMATTING_TOOLBAR_ACTIVE };
}

export function setFormattingToolbarInActive(): SetFormattingToolbarInActive {
  return { type: actions.SET_FORMATTING_TOOLBAR_INACTIVE };
}

export function updateEditorNotes(editorNotes: string): UpdateEditorNotes {
  return { type: actions.UPDATE_EDITOR_NOTES, payload: { editorNotes } };
}

export function videoOEmbedSearchTermChange(term: string): VideoOEmbedSearchTermChange {
  return { type: actions.VIDEO_OEMBED_SEARCH_TERM_CHANGE, payload: { term } };
}

export function videoOEmbedSearchSuccess(searchData: {
  videos: PralineVideo[];
  total: number;
  page?: number;
  token?: {
    prev: string | null;
    next: string | null;
  };
}): VideoOEmbedSearchSuccess {
  return { type: actions.VIDEO_OEMBED_SEARCH_SUCCESS, payload: searchData };
}

export function toggleOEmbedInspectorSearchFilter(): ToggleOEmbedInspectorSearchFilter {
  return { type: actions.TOGGLE_OEMBED_INSPECTOR_SEARCH_FILTER };
}

export function videoOEmbedSearchFiltersChange(selectedFilters: {
  [key: string]: VideoOEmbedFilter;
}): VideoOEmbedSearchFiltersChange {
  return { type: actions.VIDEO_OEMBED_SEARCH_FILTERS_CHANGE, payload: selectedFilters };
}

export function videoOEmbedSearchReset(): VideoOEmbedSearchReset {
  return { type: actions.VIDEO_OEMBED_SEARCH_RESET };
}

export function reapplyEditorState(): ReapplyEditorState {
  return { type: actions.REAPPLY_EDITOR_STATE };
}

export function setEditorState(editorState: EditorState): SetEditorState {
  return { type: actions.SET_EDITOR_STATE, payload: { editorState } };
}

export function setTextParaphrasingStatus(status: boolean): SetTextParaphrasingStatus {
  return { type: actions.SET_TEXT_PARAPHRASING_STATUS, payload: status };
}
