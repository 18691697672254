/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { SectionTree, AnySection, UnsavedSection, Section } from "api/section";
import { LoaderState, INITIAL_LOADER_STATE } from "behaviors/loader/state";
import { State as WatermarkImageState } from "store/watermark-images";

export interface PartialAppState {
  sections: State;
  watermarkImage: WatermarkImageState;
  config: {
    "cdn-cname": string;
  };
  features: {
    isSubsectionEnabled: boolean;
    enableWatermarkImages: boolean;
  };
}

export enum SectionPageInspectorType {
  Create = "create",
  Edit = "edit"
}

export enum TabList {
  Details = "details",
  Watermark = "watermark"
}

export interface State {
  sections: SectionTree[];
  app: {
    currentSection: AnySection | null;
    sectionToBeDeleted: Section | null;
  };
  ui: {
    inspector: SectionPageInspectorType | null;
    main: LoaderState;
    save: LoaderState;
    sections: { [id: number]: LoaderState | undefined }; // can't write [id: SectionId] because TS1336
    showConfirmationModel: boolean;
    currentTab: TabList;
  };
}

export const NEW_SECTION: UnsavedSection = {
  "parent-id": null,
  "display-name": "",
  name: "",
  slug: "",
  data: null,
  "seo-metadata": null
};

export const INITIAL_SECTIONS_STATE: State = {
  sections: [],
  app: {
    currentSection: null,
    sectionToBeDeleted: null
  },
  ui: {
    inspector: null,
    main: INITIAL_LOADER_STATE,
    save: INITIAL_LOADER_STATE,
    sections: {},
    showConfirmationModel: false,
    currentTab: TabList.Details
  }
};
