/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { EntityId, PublisherId, Timestamp } from "./primitive-types";

interface Pagination {
  "entity-count": number;
}
export interface EntityInCollection {
  id: number;
  name: string;
  type: string;
}
export interface Entity {
  "updated-at": Timestamp;
  slug: string;
  "publisher-id": PublisherId;
  name: string;
  type: string;
  "entity-type-id": 2;
  "deleted-at": null | Timestamp;
  "created-by": number;
  id: EntityId;
  "last-updated-by": number;
  "created-at": Timestamp;
}

export type UnsavedEntity = Pick<Entity, "name">;

export type AnyEntity = Entity | UnsavedEntity;

export interface EntityListResponse {
  entities: Entity[];
  pagination: Pagination;
}

export const listEntities = (q: string = "", offset: number = 0, limit: number = 20): Promise<EntityListResponse> => {
  return client
    .url("/api/entity")
    .query({ q, "order-by": "name", offset, limit })
    .get()
    .json();
};

export const listPersonEntities = (name: string): Promise<EntityListResponse> => {
  return client
    .url("/api/entity")
    .query({ q: name, limit: 20, type: "person", members: true })
    .get()
    .json();
};
