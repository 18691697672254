/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import styles from "./field-label.module.css";
import classnames from "classnames/bind";

interface Props {
  label: string;
  htmlFor?: string;
  isLabelUpperCase?: boolean;
  classname?: string;
  id?: string;
}

const cx = classnames.bind(styles);

const FieldLabel: React.SFC<Props> = ({ label, htmlFor, isLabelUpperCase, classname, id }) => {
  const classes = cx("field-label", classname, { "field-label--uppercase": isLabelUpperCase });
  return (
    <label htmlFor={htmlFor} className={classes} data-test-id="field-label" id={id}>
      {label}
    </label>
  );
};

export default FieldLabel;
