/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { t } from "i18n";

export enum linkTypeValues {
  "NO_LINK" = "no-hyper-link",
  "LINK_TO_EXISTING_STORY" = "link-to-existing-story",
  "CREATE_ASSOCIATED_STORY" = "create-associated-story"
}

export interface LinkType {
  label: string;
  value: linkTypeValues;
}

export function getDefaultLinkType() {
  return {
    label: t("breaking-news.hyperlink-options.no-hyperlink"),
    value: linkTypeValues.NO_LINK
  };
}

export function getAssociatedStoryLinkType() {
  return {
    label: t("breaking-news.hyperlink-options.associated-story"),
    value: linkTypeValues.CREATE_ASSOCIATED_STORY
  };
}

export function getExistingStoryLinkType() {
  return {
    label: t("breaking-news.hyperlink-options.link-existing-story"),
    value: linkTypeValues.LINK_TO_EXISTING_STORY
  };
}

export function getLinkTypes(): LinkType[] {
  return [getDefaultLinkType(), getAssociatedStoryLinkType(), getExistingStoryLinkType()];
}
