/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect, useState } from "react";
import { t } from "i18n";

import Inspector from "components/inspector/inspector";
import Form, { FormConfig } from "pages/manage/custom-fields/form";
import {
  AllowedFieldTypes,
  AllowedFieldSubTypes,
  AllowedValueTypes,
  UnsavedCustomField,
  CustomField
} from "api/custom-field";
import { get, isEmpty, map, omit, pickBy } from "lodash";
import { StoryTemplateSelector } from "pages/manage/menu/components/inspector/inspector";
import Checkbox from "components/checkbox/checkbox";
import { getSelectedTemplateMapping } from "pages/story-editor/header-card/utils";
import { StoryTemplateFields } from "pages/breaking-news/state";

interface OwnProps {
  active: boolean;
  onActionButtonClick: (customField: UnsavedCustomField) => void;
  cancelSave: () => void;
  storyTemplates: Partial<StoryTemplateFields>;
  actionButtonLabel: string;
  title: string;
  currentCustomField?: CustomField | null | undefined;
}

type Props = OwnProps;

const CustomMetadataInspector: React.SFC<Props> = ({
  active,
  onActionButtonClick,
  cancelSave,
  storyTemplates,
  actionButtonLabel,
  title,
  currentCustomField
}) => {
  const initialCustomField: UnsavedCustomField = {
    name: "",
    "field-type": AllowedFieldTypes.METADATA,
    "field-sub-type": AllowedFieldSubTypes.STORY,
    "is-mandatory": false,
    "value-type": AllowedValueTypes.DESCRIPTION,
    config: (currentCustomField && currentCustomField.config) || null
  };

  useEffect(() => {
    if (currentCustomField) {
      setCustomField(currentCustomField);
    }
  }, [currentCustomField]);

  const isEnabledForAllTemplates = get(currentCustomField, ["config", "enabled-for-all-templates"], true);
  const defaultSelectedTemplates = getSelectedTemplateMapping(currentCustomField, storyTemplates);

  const [customField, setCustomField] = useState(initialCustomField);
  const [selectedTemplate, setSelectedTemplate] = useState(defaultSelectedTemplates);
  const [enableForAllTemplates, setEnableForAllTemplates] = useState(isEnabledForAllTemplates);

  const customFieldFormConfig: FormConfig = {
    customField,
    allowedDataTypes: [{ displayName: "Text Area", type: AllowedValueTypes.DESCRIPTION }]
  };

  const onChangeHandler = (partialCustomField: Partial<UnsavedCustomField>) => {
    setCustomField({ ...customField, ...partialCustomField });
  };

  const setTemplate = (customField) => {
    if (isEmpty(selectedTemplate) || enableForAllTemplates) {
      return { ...customField, config: { "enabled-for-all-templates": true } };
    }
    const contentTemplates = selectedTemplate.map((item) => item["slug"]);
    return {
      ...customField,
      config: {
        "enabled-for-all-templates": enableForAllTemplates,
        "content-templates": contentTemplates
      }
    };
  };

  const getTemplatesForDropdown = (storyTemplates) => {
    const templates = omit(storyTemplates, "all");
    const activeTemplates = pickBy(templates, ({ toggle }) => toggle);
    return map(activeTemplates, ({ name, slug, id }) => ({
      name,
      slug,
      id,
      "ancestor-ids": []
    }));
  };

  const onCloseInspector = () => {
    setCustomField(initialCustomField);
    cancelSave();
    setSelectedTemplate([]);
    setEnableForAllTemplates(true);
  };

  return (
    <Inspector
      title={title}
      onClose={onCloseInspector}
      isActive={active}
      actionButtonLabel={actionButtonLabel}
      isActionButtonDisabled={!customField.name}
      onActionButtonClick={() => {
        onActionButtonClick(setTemplate(customField));
      }}>
      <Form config={customFieldFormConfig} onChange={onChangeHandler} />
      <Checkbox
        data-test-id="custom-metadata-checkbox"
        id="custom-metadata-checkbox"
        checked={enableForAllTemplates}
        label={t("custom-metadata.inspector.story-templates-checkbox-label")}
        onChange={(checked) => setEnableForAllTemplates(checked)}
        disabled={false}
      />
      <br />
      {!enableForAllTemplates && (
        <StoryTemplateSelector
          data-test-id="story-templates-selector"
          value={selectedTemplate}
          storyTemplates={getTemplatesForDropdown(storyTemplates || [])}
          onChange={(value: any) => setSelectedTemplate(value)}
        />
      )}
    </Inspector>
  );
};

export default CustomMetadataInspector;
