/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";

import { PartialAppState } from "../../state";
import { navigateFn } from "utils/routes.utils";
import { loadSections, undeleteSection, deleteSection } from "../../async-action-creators";
import { SectionTree, Section } from "api/section";
import Button from "components/button/button";
import { SectionsList } from "../sections-list/sections-list";
import { SECTIONS_NEW_PATH } from "../../routes";
import ConfirmationModal from "components/confirmation-modal/confirmation-modal";
import actions from "../../actions";
import styles from "./section-tree.module.css";

interface StateProps {
  sections: SectionTree[];
  sectionToBeDeleted: Section | null;
  isConfirmationModelVisible: boolean;
}

interface DispatchProps {
  loadSections: () => void;
  addNewSection: () => void;
  undeleteSection: (section: Section) => void;
  deleteSection: (section: Section) => void;
  showConfirmationModel: () => void;
  closeConfirmationModel: () => void;
  setSectionToBeDeleted: (section: Section) => void;
}

type Props = StateProps & DispatchProps;

const SectionsTree: React.SFC<Props> = ({
  sections,
  addNewSection,
  undeleteSection,
  deleteSection,
  showConfirmationModel,
  sectionToBeDeleted,
  closeConfirmationModel,
  isConfirmationModelVisible,
  setSectionToBeDeleted
}) => (
  <React.Fragment>
    <header className={styles["section-header"]} data-test-id="section-header">
      <h3 className={styles["section-title"]}>{t("sections.title")}</h3>
      <Button type="secondary" onClick={addNewSection}>
        {t("sections.cta.create")}
      </Button>
    </header>
    <SectionsList
      sections={sections}
      undeleteSection={undeleteSection}
      deleteSectionWithConfirmation={(section) => {
        setSectionToBeDeleted(section);
        showConfirmationModel();
      }}
    />
    <ConfirmationModal
      showConfirmationModal={isConfirmationModelVisible}
      headerText={t("sections.deactivate.header-text")}
      confirmButtonLabel={t("sections.deactivate.deactivate")}
      cancelButtonLabel={t("sections.deactivate.cancel")}
      onConfirm={() => sectionToBeDeleted && deleteSection(sectionToBeDeleted)}
      onCancel={() => closeConfirmationModel()}
      onClose={() => closeConfirmationModel()}
    />
  </React.Fragment>
);

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    sections: state.sections.sections,
    sectionToBeDeleted: state.sections.app.sectionToBeDeleted,
    isConfirmationModelVisible: state.sections.ui.showConfirmationModel
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  const navigate = dispatch(navigateFn);

  return {
    loadSections: () => dispatch(loadSections()),
    addNewSection: () => navigate(SECTIONS_NEW_PATH),
    undeleteSection: (section) => dispatch(undeleteSection(section)),
    deleteSection: (section) => dispatch(deleteSection(section)),
    showConfirmationModel: () => dispatch({ type: actions.SHOW_CONFIRMATION_MODEL }),
    closeConfirmationModel: () => dispatch({ type: actions.CLOSE_CONFIRMATION_MODEL_AND_RESET_DATA }),
    setSectionToBeDeleted: (section) => dispatch({ type: actions.SET_SECTION_TO_BE_DELETED, payload: { section } })
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SectionsTree);

export { SectionsTree };
