/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { get } from "lodash";
import TextField from "components/text-field/text-field";
import Button from "components/button/button";
import { connect } from "react-redux";
import { onConfirmTOTP } from "./async-action-creators";
import { onChangeTOTP } from "./action-creators";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "./state";
import { isClientValidationError } from "utils/validation.utils";
import Snackbar from "components/snackbar/snackbar";
import styles from "./two-factor-auth.module.css";

interface DispatchProps {
  onConfirmTOTP: (totp: string) => void;
  onChange: (totp: string) => void;
}

interface StateProps {
  totp: string;
  error: Error | null;
}

interface ValidateOTP {
  totp: string;
}

type Props = DispatchProps & StateProps;

class TwoFactorAuth extends React.Component<Props> {

  render() {
    const { totp, onConfirmTOTP, onChange, error } = this.props;
    return (
      <div className={styles["two-factor-auth-container"]}>
        <div className={styles["publisher-logo"]} />
        <TextField
          label={t("loginPage.two_factor_auth.totp_label")}
          placeholder={t("loginPage.two_factor_auth.totp_placeholder")}
          value={totp}
          onChange={(value) => onChange(value)}
          errorMessage={isClientValidationError<ValidateOTP>(error) ? get(error, ["errors", "totp", "0"]) : undefined}
        />
        <Button type="primary" onClick={() => onConfirmTOTP(totp)}>
          {t("loginPage.two_factor_auth.login")}
        </Button>
        <Snackbar />
      </div>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    totp: state.login.twoFactorAuth.totp,
    error: state.login.ui.error
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
  return {
    onConfirmTOTP: (totp: string) => dispatch(onConfirmTOTP(totp)),
    onChange: (totp: string) => dispatch(onChangeTOTP(totp))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuth);

export { TwoFactorAuth };
