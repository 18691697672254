/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { TaskId, StoryId } from "./primitive-types";
import { client } from "./client";

export interface Assignee {
  id: string;
  name: string;
}

export interface Task {
  id: TaskId;
  name: string;
  subtasks: Task[];
  assignee: Assignee | null;
  "due-on": string | null;
  completed: boolean | null;
  "story-id"?: StoryId | null;
  "my-subtask-count"?: Number;
  "total-subtask-count"?: Number;
  "earliest-due-on"?: number | null;
}
export interface TaskErrors {
  status: Number;
}

export interface Image {
  metadata: {
    "file-name"?: string;
    "file-size"?: number;
    height: number;
    "mime-type"?: string;
    width: number;
  };
  success?: boolean;
  caption?: string;
  attribution?: string;
  "temp-image-key": string;
  url: string;
  "alt-text"?: string;
}

export interface MyTasks {
  overdue?: Task[];
  today?: Task[];
  tomorrow?: Task[];
  later?: Task[];
  message?: string;
}

export const fetchTask = (id: TaskId): Promise<Task> => {
  return client
    .url(`/api/tasks/${id}`)
    .get()
    .json();
};

export const fetchAllTasks = (): Promise<MyTasks> => {
  return client
    .url(`/api/tasks`)
    .get()
    .json();
};

export const closeTask = (id: TaskId): Promise<Task> => {
  return client
    .url(`/api/tasks/${id}`)
    .put()
    .json();
};
