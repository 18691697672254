/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { INITIAL_LOADER_STATE, LoaderState } from "./state";
import { LoaderActionTypes } from "./action-creators";
import { AnyAction } from "redux";

type GlobalState = {};

export default function loaderReducer(
  actionNames: LoaderActionTypes,
  getLoaderState: (state: GlobalState) => LoaderState,
  setLoaderState: (state: GlobalState, loaderState: LoaderState) => void
) {
  function reducer(state: LoaderState = INITIAL_LOADER_STATE, action: AnyAction) {
    switch (action.type) {
      case actionNames.BEGIN:
        return { ...state, ...INITIAL_LOADER_STATE };
      case actionNames.SUCCESS:
        return { ...state, loading: false, error: null };
      case actionNames.FAILURE:
        return { ...state, loading: false, error: action.payload.error };
      default:
        return state;
    }
  }

  return function(state: GlobalState, action: AnyAction) {
    return setLoaderState(state, reducer(getLoaderState(state), action));
  };
}
