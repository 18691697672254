/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AnyStory, Card } from "api/story";
import { CardId } from "api/primitive-types";
import { addIfNotExists } from "utils/array.utils";
import produce from "immer";

export function pinCard(story: AnyStory, cardId: CardId): AnyStory {
  const existingPinnedCard = Object.values(story.cards).find(
    (card: Card) => card.metadata && card.metadata.attributes && card.metadata.attributes["is-pinned?"]
  );

  const updatedCards = produce(story.cards, (cards: { [key: string]: Card }) => {
    if (existingPinnedCard && existingPinnedCard.id !== cardId) {
      const cardDetails = cards[existingPinnedCard.id];
      const newMetadata = cardDetails.metadata
        ? {
            ...cardDetails.metadata,
            attributes: {
              ...cardDetails.metadata.attributes,
              "is-pinned?": false
            }
          }
        : cardDetails.metadata;

      cards[existingPinnedCard.id] = { ...cardDetails, metadata: newMetadata };
    }
  });
  const newUpdatedCards = addIfNotExists(story["updated-cards"], existingPinnedCard && existingPinnedCard.id);

  const cardDetails = updatedCards[cardId];
  const newMetadata = cardDetails.metadata
    ? {
        ...cardDetails.metadata,
        attributes: {
          ...cardDetails.metadata.attributes,
          "is-pinned?": cardDetails.metadata.attributes && !cardDetails.metadata.attributes["is-pinned?"]
        }
      }
    : { attributes: { "is-pinned?": true } };

  const newStory = {
    ...story,
    cards: { ...updatedCards, [cardId]: { ...updatedCards[cardId], metadata: newMetadata } },
    "updated-cards": addIfNotExists(newUpdatedCards, cardId)
  };

  return newStory;
}

export function addKeyEvent(story: AnyStory, cardId: CardId): AnyStory {
  const cardDetails = story.cards[cardId];
  const newMetadata =
    cardDetails && cardDetails.metadata
      ? {
          ...cardDetails.metadata,
          attributes: {
            ...cardDetails.metadata.attributes,
            "key-event": cardDetails.metadata.attributes && !cardDetails.metadata.attributes["key-event"]
          }
        }
      : { attributes: { "key-event": true } };

  const newStory = {
    ...story,
    cards: { ...story.cards, [cardId]: { ...story.cards[cardId], metadata: newMetadata } },
    "updated-cards": addIfNotExists(story["updated-cards"], cardId)
  };

  return newStory;
}
