/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { LoaderError } from "behaviors/loader/state";
import { UserTeam, SelectedUser, UserRole, ValidationError } from "./state";

enum actions {
  // Users listing page
  LOAD_USERS_DATA = "@@settings/LOAD_USERS_DATA",
  USERS_UPDATE_CURRENT_PAGE = "@@settings/USERS_UPDATE_CURRENT_PAGE",
  USERS_UPDATE_PAGINATION = "@@settings/USERS_UPDATE_PAGINATION",

  SET_SEARCH_TERM = "@@settings/SET_SEARCH_TERM",

  //Edit user page
  UPDATE_USER_PROFILE = "@@settings/UPDATE_USER_PROFILE",
  UPDATE_USER_SETTINGS = "@@settings/UPDATE_USER_SETTINGS",
  SET_ROLES_FOR_SELECTED_USER = "@@settings/SET_ROLES_FOR_SELECTED_USER",
  SET_TEAMS_FOR_SELECTED_USER = "@@settings/SET_TEAMS_FOR_SELECTED_USER",
  UPDATE_CAN_LOGIN_FOR_SELECTED_USER = "@settings/UPDATE_CAN_LOGIN_FOR_SELECTED_USER",

  LOAD_USER_DATA = "@@settings/LOAD_USER_DATA",
  LOAD_USER_DATA_SUCCESS = "@@settings/LOAD_USER_DATA_SUCCESS",
  LOAD_USER_DATA_FAILURE = "@@settings/LOAD_USER_DATA_FAILURE",

  //Add user page
  SAVE_NEW_USER_DATA = "@@settings/SAVE_NEW_USER_DATA",
  RESET_ADD_NEW_MEMBER = "@@settings/RESET_ADD_NEW_MEMBER",
  SET_ROLES_FOR_NEW_USER = "@@settings/SET_ROLES_FOR_NEW_USER",
  UPDATE_CAN_LOGIN_FOR_NEW_USER = "@settings/UPDATE_CAN_LOGIN_FOR_NEW_USER",

  //Edit and Add user page
  UPDATE_AVATAR_UPLOAD_STATUS = "@@settings/UPDATE_AVATAR_UPLOAD_STATUS",
  SET_ERRORS_FOR_USER = "@@settings/SET_ERRORS_FOR_USER",

  LOAD_ALL_ROLES = "@@settings/LOAD_ALL_ROLES",
  LOAD_ALL_ROLES_SUCCESS = "@@settings/LOAD_ALL_ROLES_SUCCESS",
  LOAD_ALL_ROLES_FAILURE = "@@settings/LOAD_ALL_ROLES_FAILURE",

  LOAD_ALL_TEAMS = "@@settings/LOAD_ALL_TEAMS",
  LOAD_ALL_TEAMS_SUCCESS = "@@settings/LOAD_ALL_TEAMS_SUCCESS",
  LOAD_ALL_TEAMS_FAILURE = "@@settings/LOAD_ALL_TEAMS_FAILURE",

  RESET_SELECTED_USER = "@@settings/RESET_SELECTED_USER",
  SAVE_UPDATED_USER_REQUEST = "@@settings/SAVE_UPDATED_USER_REQUEST",
  SAVE_UPDATED_USER_FAILURE = "@@settings/SAVE_UPDATED_USER_FAILURE",

  //Confirmation Modal

  CLOSE_DARK_MODE_CONFIRMATION_MODAL = "@@settings/CLOSE_DARK_MODE_CONFIRMATION_MODAL",
  SHOW_DARK_MODE_CONFIRMATION_MODAL = "@@settings/SHOW_DARK_MODE_CONFIRMATION_MODAL",
  CLOSE_MANAGE_STORY_INSPECTOR_CONFIRMATION_MODAL = "@@settings/CLOSE_MANAGE_STORY_INSPECTOR_CONFIRMATION_MODAL",
  SHOW_MANAGE_STORY_INSPECTOR_CONFIRMATION_MODAL = "@@settings/SHOW_MANAGE_STORY_INSPECTOR_CONFIRMATION_MODAL",
  CLOSE_TOGGLE_LOGIN_CONFIRMATION_MODAL = "@@settings/CLOSE_TOGGLE_LOGIN_CONFIRMATION_MODAL",
  SHOW_TOGGLE_LOGIN_CONFIRMATION_MODAL = "@settings/SHOW_TOGGLE_LOGIN_CONFIRMATION_MODAL",
  SWITCH_TO_CLASSIC_UI = "@@settings/SWITCH_TO_CLASSIC_UI",
  SET_BANNER = "@@settings/SET_BANNER",
  CLOSE_BANNER = "@@settings/CLOSE_BANNER",
  SAVING_USER = "@@settings/SAVING_USER",
  TOGGLE_USER_LANGUAGE_CHANGE_CONFIRMATION_MODAL = "@@settings/TOGGLE_USER_LANGUAGE_CHANGE_CONFIRMATION_MODAL"
}

export default actions;

export type LoadUsersData = {
  type: actions.LOAD_USERS_DATA;
  payload: { data: { members: SelectedUser[]; "total-count": number } };
};
export type UsersUpdateCurrentPage = {
  type: actions.USERS_UPDATE_CURRENT_PAGE;
  payload: { pageNumber: number };
};
export type UsersUpdatePagination = {
  type: actions.USERS_UPDATE_PAGINATION;
  payload: { offset: number };
};
export type SetSearchTerm = {
  type: actions.SET_SEARCH_TERM;
  payload: { searchTerm: string };
};

export type UpdateUserProfile = {
  type: actions.UPDATE_USER_PROFILE;
  payload: { key: string; changes: string };
};
export type UpdateUserSettings = {
  type: actions.UPDATE_USER_SETTINGS;
  payload: { key: string; changes: string };
};
export type SetRolesForSelectedUser = {
  type: actions.SET_ROLES_FOR_SELECTED_USER;
};
export type SetTeamsForSelectedUser = {
  type: actions.SET_TEAMS_FOR_SELECTED_USER;
};
export type UpdateCanLoginForSelectedUser = {
  type: actions.UPDATE_CAN_LOGIN_FOR_SELECTED_USER;
  payload: { canLogin: boolean };
};
export type LoadUserData = {
  type: actions.LOAD_USER_DATA;
};
export type LoadUserDataSuccess = {
  type: actions.LOAD_USER_DATA_SUCCESS;
  payload: { data: SelectedUser };
};
export type LoadUserDataFailure = {
  type: actions.LOAD_USER_DATA_FAILURE;
  payload: { error: LoaderError };
};

export type SaveNewUserData = {
  type: actions.SAVE_NEW_USER_DATA;
  payload: { key: string; changes: string };
};
export type ResetAddNewMember = {
  type: actions.RESET_ADD_NEW_MEMBER;
};
export type SetRolesForNewUser = {
  type: actions.SET_ROLES_FOR_NEW_USER;
};
export type UpdateCanLoginForNewUser = {
  type: actions.UPDATE_CAN_LOGIN_FOR_NEW_USER;
  payload: { canLogin: boolean };
};

export type UpdateAvatarUploadStatus = {
  type: actions.UPDATE_AVATAR_UPLOAD_STATUS;
  payload: { uploading: boolean };
};
export type SetErrorsForUser = {
  type: actions.SET_ERRORS_FOR_USER;
  payload: { errors: ValidationError };
};
export type LoadAllRoles = {
  type: actions.LOAD_ALL_ROLES;
};
export type LoadAllRolesSuccess = {
  type: actions.LOAD_ALL_ROLES_SUCCESS;
  payload: { roles: UserRole[] };
};
export type LoadAllRolesFailure = {
  type: actions.LOAD_ALL_ROLES_FAILURE;
  payload: { error: LoaderError };
};
export type LoadAllTeams = {
  type: actions.LOAD_ALL_TEAMS;
};
export type LoadAllTeamsSuccess = {
  type: actions.LOAD_ALL_TEAMS_SUCCESS;
  payload: { teams: UserTeam[] };
};
export type LoadAllTeamsFailure = {
  type: actions.LOAD_ALL_TEAMS_FAILURE;
  payload: { error: LoaderError };
};
export type ResetSelectedUser = {
  type: actions.RESET_SELECTED_USER;
};
export type SaveUpdatedUserRequest = {
  type: actions.SAVE_UPDATED_USER_REQUEST;
};
export type SaveUpdatedUserFailure = {
  type: actions.SAVE_UPDATED_USER_FAILURE;
  payload: { error: LoaderError };
};

export type CloseDarkModeConfirmationModal = {
  type: actions.CLOSE_DARK_MODE_CONFIRMATION_MODAL;
};
export type ShowDarkModeConfirmationModal = {
  type: actions.SHOW_DARK_MODE_CONFIRMATION_MODAL;
};
export type CloseManageInspectorConfirmationModal = {
  type: actions.CLOSE_MANAGE_STORY_INSPECTOR_CONFIRMATION_MODAL;
};
export type TogglelanguageChangeConfirmationModal = {
  type: actions.TOGGLE_USER_LANGUAGE_CHANGE_CONFIRMATION_MODAL;
};
export type ShowManageInspectorConfirmationModal = {
  type: actions.SHOW_MANAGE_STORY_INSPECTOR_CONFIRMATION_MODAL;
};
export type CloseToggleLoginConfirmationModal = {
  type: actions.CLOSE_TOGGLE_LOGIN_CONFIRMATION_MODAL;
};
export type ShowToggleLoginConfirmationModal = {
  type: actions.SHOW_TOGGLE_LOGIN_CONFIRMATION_MODAL;
};
export type SwitchToClassicUi = {
  type: actions.SWITCH_TO_CLASSIC_UI;
};
export type SetBanner = {
  type: actions.SET_BANNER;
  payload: { message: string };
};
export type CloseBanner = {
  type: actions.CLOSE_BANNER;
};
export type SavingUser = {
  type: actions.SAVING_USER;
  payload: { saving: boolean };
};

export type ActionTypes =
  | LoadUsersData
  | UsersUpdateCurrentPage
  | UsersUpdatePagination
  | SetSearchTerm
  | UpdateUserProfile
  | UpdateUserSettings
  | SetRolesForSelectedUser
  | SetTeamsForSelectedUser
  | UpdateCanLoginForSelectedUser
  | LoadUserData
  | LoadUserDataSuccess
  | LoadUserDataFailure
  | SaveNewUserData
  | ResetAddNewMember
  | SetRolesForNewUser
  | UpdateCanLoginForNewUser
  | UpdateAvatarUploadStatus
  | SetErrorsForUser
  | LoadAllRoles
  | LoadAllRolesSuccess
  | LoadAllRolesFailure
  | LoadAllTeams
  | LoadAllTeamsSuccess
  | LoadAllTeamsFailure
  | ResetSelectedUser
  | SaveUpdatedUserRequest
  | SaveUpdatedUserFailure
  | CloseDarkModeConfirmationModal
  | ShowDarkModeConfirmationModal
  | CloseManageInspectorConfirmationModal
  | ShowManageInspectorConfirmationModal
  | TogglelanguageChangeConfirmationModal
  | SwitchToClassicUi
  | SetBanner
  | CloseBanner
  | SavingUser
  | ShowToggleLoginConfirmationModal
  | CloseToggleLoginConfirmationModal;
