/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { AnyAction, compose } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { cloneDeep, isEqual } from "lodash";
import classnames from "classnames/bind";
import { FormBuilder as FormioBuilder } from "react-formio";
import { PartialAppState, CurrentForm, setCurrentForm, setIsFormModified } from "store/form/editor";
import FormEditorGrid from "pages/forms/editor/grid/grid";
import "assets/stylesheets/bootstrap-iso.scss";
import "formiojs/dist/formio.full.min.css";
import "font-awesome/css/font-awesome.min.css";
import styles from "./builder.module.css";
import getFormBuilderOptions from "./form-builder-options";
import { FormioFileUploadConfig } from "store/route-data";

const cx = classnames.bind(styles);

/*
    TODO code split this component. Tried this with React lazy and suspense but
    it didn't work in the production build most probably due to how webpack does
    chunking and minifying.
*/

interface StateProps {
  formioData: any;
  status?: string;
  formioFileUploadConfig: FormioFileUploadConfig;
}

interface DispatchProps {
  updateForm: (form: CurrentForm, isFormModified: boolean) => void;
}

type FormBuilderProps = StateProps & DispatchProps;

export class FormBuilder extends React.Component<FormBuilderProps> {
  currentSchema: any;
  formBuilderInitialized: boolean;

  constructor(props: FormBuilderProps) {
    super(props);
    this.currentSchema = props.formioData;
    this.formBuilderInitialized = false;
  }

  shouldComponentUpdate(prevProps: FormBuilderProps) {
    return prevProps.status !== this.props.status;
  }

  render() {
    const currentSchema = this.currentSchema;
    const { status, updateForm, formioFileUploadConfig } = this.props;
    const isDisabled = status === "published";

    const onChange = (schema: any) => {
      const newSchema = cloneDeep(schema);
      const isSchemaModified = !isEqual(currentSchema, newSchema);
      isSchemaModified && updateForm({ formioData: newSchema }, this.formBuilderInitialized);
      this.currentSchema = newSchema;
      this.formBuilderInitialized = true;
    };

    return (
      <FormEditorGrid>
        <div className={`bootstrap-iso ${cx("form-builder-container", { disabled: isDisabled })}`}>
          <FormioBuilder
            form={cloneDeep(currentSchema)}
            options={getFormBuilderOptions(formioFileUploadConfig)}
            onChange={onChange}
          />
        </div>
      </FormEditorGrid>
    );
  }
}

const mapStateToProps = ({ forms: { editor }, config }: PartialAppState): StateProps => {
  return {
    formioData: editor.currentForm.formioData,
    status: editor.currentForm.status,
    formioFileUploadConfig: config.forms.fileUpload as FormioFileUploadConfig
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    updateForm: (form, isFormModified = true) => {
      dispatch(setCurrentForm(form));
      dispatch(setIsFormModified(isFormModified));
    }
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(FormBuilder);
