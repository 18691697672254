/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { SocialAccount } from "api/social-accounts";

enum actions {
  LIST_SOCIAL_ACCOUNTS_REQUEST = "@@settings/LIST_SOCIAL_ACCOUNTS_REQUEST",
  LIST_SOCIAL_ACCOUNTS_SUCCESS = "@@settings/LIST_SOCIAL_ACCOUNTS_SUCCESS",
  LIST_SOCIAL_ACCOUNTS_FAILURE = "@@settings/LIST_SOCIAL_ACCOUNTS_FAILURE",

  ADD_NEW_CONNECTION = "@@settings/ADD_NEW_CONNECTION",
  CANCEL_NEW_CONNECTION = "@@settings/CANCEL_NEW_CONNECTION",

  SET_SELECTED_SERVICE = "@@settings/SET_SELECTED_SERVICE"
}

export default actions;

export type SocialAccountsRequestAction = {
  type: actions.LIST_SOCIAL_ACCOUNTS_REQUEST;
};

export type SocialAccountsSuccessAction = {
  type: actions.LIST_SOCIAL_ACCOUNTS_SUCCESS;
  payload: { socialAccounts: SocialAccount[] };
};

export type SocialAccountsFailureAction = {
  type: actions.LIST_SOCIAL_ACCOUNTS_FAILURE;
  payload: { error: Error };
};

export type AddNewConnection = {
  type: actions.ADD_NEW_CONNECTION;
};

export type CancelAddingConnection = {
  type: actions.CANCEL_NEW_CONNECTION;
};

export type OnChangeOFService = {
  type: actions.SET_SELECTED_SERVICE;
  payload: { selectedService: string };
};

export type ActionTypes =
  | SocialAccountsRequestAction
  | SocialAccountsSuccessAction
  | SocialAccountsFailureAction
  | AddNewConnection
  | CancelAddingConnection
  | OnChangeOFService;
