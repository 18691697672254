/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { validate } from "utils/validation.utils";
import { t } from "i18n";

import Inspector from "components/inspector/inspector";
import TemplateFields from "./template-fields/template-fields";
import { TextFieldWithCount } from "components/text-field/text-field";
import { isEmptyObject } from "utils/object.utils";
import { Template } from "api/content-templates";
import { TemplateFieldErrors } from "./shared-types";
import { constraints } from "./validate";

interface Props {
  onClose: () => void;
  isActive: boolean;
  addTemplate: (value: Template) => void;
  newStoryTemplate: Template;
  onFieldChange: (key: string, value: string | Object) => void;
  setErrors: (value: TemplateFieldErrors) => void;
  errors: TemplateFieldErrors;
}

const AddTemplateInspector: React.SFC<Props> = ({
  onClose,
  isActive,
  addTemplate,
  newStoryTemplate,
  onFieldChange,
  setErrors,
  errors
}) => {
  const newStoryTemplateConfig = newStoryTemplate.config;
  if (isEmptyObject(newStoryTemplate) || isEmptyObject(newStoryTemplateConfig)) {
    return null;
  }
  const validateNewTemplateData = () => {
    let templateErrors = validate(newStoryTemplate, constraints(t, newStoryTemplateConfig));
    if (templateErrors) {
      setErrors(templateErrors);
    } else {
      addTemplate(newStoryTemplate);
    }
  };
  const errorMessage = !isEmptyObject(errors) && errors.name ? errors.name[0] : "";
  return (
    <Inspector
      title={t("settings.storyTemplates.add_inspector_title")}
      onClose={onClose}
      isActive={isActive}
      actionButtonLabel={t("new")}
      onActionButtonClick={() => validateNewTemplateData()}>
      <TextFieldWithCount
        label={t("settings.storyTemplates.template_name")}
        value={newStoryTemplate.name}
        maxValue={60}
        onChange={(value) => onFieldChange("name", value)}
        errorMessage={errorMessage}
      />
      <TemplateFields
        fields={newStoryTemplate.config.fields}
        onFieldChange={(key, value) => onFieldChange(key, value)}
        setErrors={setErrors}
        errors={errors}
      />
    </Inspector>
  );
};

export default AddTemplateInspector;
