/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { t } from "i18n";
import { MEDIA_LIBRARY_PATH } from "routes";
import {
  MEDIA_LIBRARY_UPDATE_SELECTED_MEDIA_DATA,
  MEDIA_LIBRARY_SET_PROVIDER,
  MEDIA_LIBRARY_UPDATE_MEDIA_FOR_MULTI_SELECT,
  MEDIA_LIBRARY_RESET_DATA
} from "../../actions";
import {
  loadMediaDataAction,
  addNewMediaAction,
  editMediaAction,
  setMediaOnProviderChangeAction,
  editExternalMediaAction,
  toggleShowAdvancedSearch
} from "pages/media-library/action-creators";
import { notificationError } from "action-creators/notification";

import SearchBar from "components/search-bar/search-bar";
import AdvancedSearch from "pages/media-library/components/advanced-search/advanced-search";
import PublisherMedia from "pages/media-library/components/publisher-media/publisher-media";
import ExternalMedia from "pages/media-library/components/external-media/external-media";
import Select from "components/select/select";
import { getMediaCategories, EXTERNAL_MEDIA_PROVIDERS } from "pages/media-library/media-list";
import styles from "./media-gallery.module.css";
import Spinner from "components/spinner/spinner";
import Button from "components/button/button";
import {
  Media,
  Provider,
  Providers,
  CurrentProvider,
  ExistingOpts,
  AdvancedSearchOpts,
  PartialAppState
} from "pages/media-library/state";
import { AnyImage } from "api/search-media-image";
import { Image as TaskImage } from "api/tasks";

interface StateProps {
  providers: Providers;
  currentMediaProvider: CurrentProvider;
  mediaForMultiSelect: Media[];
  advancedSearchOpts: AdvancedSearchOpts;
  searchTerm: string;
  loading: boolean;
  showAdvancedSearch: boolean;
  enableGettyImagesInSidebar?: boolean;
  enableEXIFLocation?: boolean;
  mediaSources: string[];
  source?: string;
  isArchivedLibrary?: boolean;
}

interface DispatchProps {
  loadData: (opts: ExistingOpts) => void;
  switchToMediaLibrary: () => void;
  updateSelectedMediaData: (key: string, value: any, mediaIndex: number) => void;
  switchProvider: (provider: CurrentProvider, opts: ExistingOpts | AdvancedSearchOpts) => void;
  setMediaItemsOnSearch: (searchTerm: string, opts: ExistingOpts | AdvancedSearchOpts) => void;
  setMediaOnProviderChange: () => Provider;
  addNewMedia: (files: File[], enableEXIFLocation?: boolean) => void;
  editMedia: (media: Media) => void;
  editExternalMedia: (media: Media) => void;
  setMediaItemForMultiSelect: (media: Media) => void;
  reset: () => void;
  toggleShowAdvancedSearchAction: () => void;
  displayError: (error: string) => void;
}

interface OwnProps {
  setSelectedMedia: (images: AnyImage[] | TaskImage[]) => void;
  opts?: ExistingOpts;
  scrollableTarget?: string;
  initialSearchTerm?: string;
  showEditImage?: boolean;
  hideMediaGalleryActions?: boolean;
  showSelectMediaProvider?: boolean;
  enableMultipleUploads?: boolean;
  canMultiSelect?: boolean;
  isArchivedLibrary?: boolean;
  switchToUploadRoute?: (mediaKey: string) => void;
  updateImageUploadStatus?: (status: { uploading: boolean }) => void;
}

type MediaGalleryProps = StateProps & DispatchProps & OwnProps;

class MediaGallery extends React.Component<MediaGalleryProps> {
  componentDidMount() {
    this.props.loadData &&
      this.props.loadData({
        ...this.props.opts,
        searchTerm: this.props.initialSearchTerm,
        source: this.props.source,
        archived: this.props.isArchivedLibrary
      });
  }

  // Invoked when redirect from Media Gallery to Archived Gallery and vice-versa
  componentDidUpdate(prevProps: Readonly<MediaGalleryProps>): void {
    if (prevProps.isArchivedLibrary !== this.props.isArchivedLibrary) {
      this.props.loadData &&
        this.props.loadData({
          ...this.props.opts,
          searchTerm: this.props.initialSearchTerm,
          source: this.props.source,
          archived: this.props.isArchivedLibrary
        });
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const {
      loading,
      editMedia,
      loadData,
      showEditImage,
      displayError,
      opts,
      scrollableTarget,
      setMediaItemForMultiSelect,
      canMultiSelect,
      editExternalMedia,
      showAdvancedSearch,
      enableMultipleUploads,
      searchTerm,
      setMediaOnProviderChange,
      hideMediaGalleryActions,
      addNewMedia,
      mediaForMultiSelect,
      enableEXIFLocation,
      switchProvider,
      advancedSearchOpts,
      currentMediaProvider,
      enableGettyImagesInSidebar,
      setMediaItemsOnSearch,
      showSelectMediaProvider,
      toggleShowAdvancedSearchAction,
      mediaSources,
      isArchivedLibrary
    } = this.props;

    const mediaProvider = setMediaOnProviderChange();
    const mediaProviders = getMediaCategories(mediaSources);
    if (enableGettyImagesInSidebar) {
      mediaProviders.push(...EXTERNAL_MEDIA_PROVIDERS(t));
    }

    const onSearchTermChange = (searchTerm: string) => {
      setMediaItemsOnSearch(searchTerm, {
        ...opts,
        ...advancedSearchOpts,
        source: currentMediaProvider.source,
        archived: this.props.isArchivedLibrary
      });
    };

    const onProviderChange = (provider: CurrentProvider) => {
      switchProvider(provider, { ...opts, ...advancedSearchOpts, searchTerm, archived: this.props.isArchivedLibrary });
    };

    const onAdvancedSearchChange = (advancedSearchOpts: AdvancedSearchOpts) => {
      loadData({
        ...opts,
        ...advancedSearchOpts,
        searchTerm,
        source: currentMediaProvider.source,
        archived: this.props.isArchivedLibrary
      });
    };

    return (
      <section>
        {!hideMediaGalleryActions && (
          <div className={styles["media-gallery-actions"]}>
            <div className={styles["media-gallery-search"]} data-test-id="searchBar">
              <SearchBar
                value={searchTerm}
                onChange={onSearchTermChange}
                placeholder={t("mediaLibrary.search_placeholder")}
              />
              <div>
                <Button
                  testId="media-gallery-advanced-btn"
                  type="secondary"
                  onClick={() => toggleShowAdvancedSearchAction()}>
                  {t("common.advanced")}
                </Button>
              </div>
            </div>
            {showAdvancedSearch && <AdvancedSearch setMediaItemsOnSearch={onAdvancedSearchChange} />}
            {showSelectMediaProvider && (
              <div className={styles["media-gallery-select-provider"]}>
                <Select
                  value={currentMediaProvider}
                  onChange={onProviderChange}
                  getOptionLabel={(provider) => t(provider.label)}
                  getOptionValue={(provider) => (provider.source ? provider.source : provider.value)}
                  options={mediaProviders}
                  label={t("mediaLibrary.sources")}
                />
              </div>
            )}
          </div>
        )}
        {loading && (
          <div>
            <Spinner message={t("mediaLibrary.loading")} />
          </div>
        )}
        {!loading && currentMediaProvider && (
          <div className={styles["media-gallery-content"]} data-test-id="media-gallery-content">
            {currentMediaProvider.category === "PUBLISHER_MEDIA_CATEGORIES" && (
              <PublisherMedia
                addNewMedia={(files) => addNewMedia(files, enableEXIFLocation)}
                mediaItems={mediaProvider.media}
                editMedia={editMedia}
                enableMultipleUploads={enableMultipleUploads}
                canMultiSelect={canMultiSelect}
                setMediaItemForMultiSelect={setMediaItemForMultiSelect}
                mediaForMultiSelect={mediaForMultiSelect}
                searchTerm={searchTerm}
                advancedSearchOpts={advancedSearchOpts}
                loadDataOptions={{ ...opts, source: currentMediaProvider.source }}
                loadNextData={loadData}
                totalCount={mediaProvider.page.total}
                scrollableTarget={scrollableTarget}
                displayError={displayError}
                isArchivedLibrary={isArchivedLibrary}
              />
            )}

            {currentMediaProvider.category === "EXTERNAL_MEDIA_PROVIDERS" && (
              <ExternalMedia
                mediaItems={mediaProvider.media}
                showSearchMediaText={searchTerm === ""}
                editMedia={editExternalMedia}
                showEditImage={showEditImage}
                loadDataOptions={opts}
                loadNextData={loadData}
                searchTerm={searchTerm}
                totalCount={mediaProvider.page.total}
                scrollableTarget={scrollableTarget}
              />
            )}
          </div>
        )}
      </section>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    providers: state.mediaLibrary.providers,
    currentMediaProvider: state.mediaLibrary.currentMediaProvider,
    searchTerm: state.mediaLibrary.searchTerm,
    mediaForMultiSelect: state.mediaLibrary.ui.mediaForMultiSelect,
    loading: state.mediaLibrary.loading,
    enableGettyImagesInSidebar: state.features.enableGettyImagesInSidebar,
    enableEXIFLocation: state.features.enableEXIFLocation,
    showAdvancedSearch: state.mediaLibrary.ui.showAdvancedSearch,
    advancedSearchOpts: state.mediaLibrary.advancedSearchOpts,
    mediaSources: state.config["media-sources"],
    source: state.mediaLibrary.currentMediaProvider.source
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  rawOwnProps: OwnProps
): DispatchProps => {
  const ownProps = {
    ...rawOwnProps,
    showEditImage: rawOwnProps.showEditImage || false,
    setSelectedMedia: rawOwnProps.setSelectedMedia || (() => {}),
    updateImageUploadStatus: rawOwnProps.updateImageUploadStatus || (() => {}),
    switchToUploadRoute: rawOwnProps.switchToUploadRoute || (() => {})
  };

  return {
    loadData: (opts) => dispatch(loadMediaDataAction(opts)),
    switchToMediaLibrary: () => dispatch(push(MEDIA_LIBRARY_PATH)),
    updateSelectedMediaData: (key, value, mediaIndex) =>
      dispatch({ type: MEDIA_LIBRARY_UPDATE_SELECTED_MEDIA_DATA, payload: { key, value, mediaIndex } }),
    switchProvider: (provider, opts) => {
      dispatch({ type: MEDIA_LIBRARY_SET_PROVIDER, payload: { provider } });
      dispatch(loadMediaDataAction({ ...opts, source: provider.source }));
    },
    setMediaItemsOnSearch: (searchTerm, opts) => dispatch(loadMediaDataAction({ ...opts, searchTerm })),
    setMediaOnProviderChange: () => dispatch(setMediaOnProviderChangeAction()),
    addNewMedia: (files, enableEXIFLocation) => dispatch(addNewMediaAction(files, { enableEXIFLocation }, ownProps)),
    editMedia: (media) => dispatch(editMediaAction(media, ownProps)),
    editExternalMedia: (media) => dispatch(editExternalMediaAction(media, ownProps)),
    setMediaItemForMultiSelect: (media) =>
      dispatch({ type: MEDIA_LIBRARY_UPDATE_MEDIA_FOR_MULTI_SELECT, payload: { media } }),
    reset: () => dispatch({ type: MEDIA_LIBRARY_RESET_DATA }),
    toggleShowAdvancedSearchAction: () => dispatch(toggleShowAdvancedSearch()),
    displayError: (error) => dispatch(notificationError(error))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(MediaGallery);

export { MediaGallery };
