/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { PublisherId, CustomFieldId, Timestamp, MemberId } from "./primitive-types";

export interface CustomField {
  id: CustomFieldId;
  "publisher-id": PublisherId;
  name: string;
  "key-name": string;
  "field-type": AllowedFieldTypes;
  "field-sub-type": AllowedFieldSubTypes;
  "value-type": AllowedValueTypes;
  config: { [key: string]: any } | null;
  "is-mandatory": boolean;
  "created-at": Timestamp;
  "created-by": MemberId;
  "updated-at": Timestamp;
  "updated-by": MemberId;
}

export type UnsavedCustomField = Pick<
  CustomField,
  "name" | "field-type" | "field-sub-type" | "value-type" | "config" | "is-mandatory"
>;

export enum AllowedFieldTypes {
  METADATA = "metadata",
  ATTRIBUTE = "attribute"
}

export enum AllowedFieldSubTypes {
  STORY = "story",
  CARD = "card",
  COLLECTION = "collection",
  MEMBER = "member"
}

export enum AllowedValueTypes {
  DESCRIPTION = "description"
}

export const getCustomFields = (
  options: { "field-type": AllowedFieldTypes } | {} = {}
): Promise<Array<CustomField>> => {
  return client
    .url("/api/custom-fields")
    .query(options)
    .get()
    .json();
};

export const createCustomField = (data: UnsavedCustomField): Promise<CustomField> => {
  return client
    .url("/api/custom-fields")
    .json(data)
    .post()
    .json();
};

export const deleteCustomField = (customFieldId: CustomFieldId): Promise<void> => {
  return client
    .url(`/api/custom-fields/${customFieldId}`)
    .delete()
    .res();
};

export const editCustomField = (customFieldId: CustomFieldId, data: Partial<CustomField>): Promise<void> => {
  return client
    .url(`/api/custom-fields/${customFieldId}`)
    .json(data)
    .patch()
    .res();
};
