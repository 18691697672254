/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import SecretMode from "./secret-mode";

import { SECRET_MODE, SECRET_MODE_TABS_PATH } from "./routes";

const Router = () => {
  return (
    <Switch>
      <Route exact path={SECRET_MODE} component={SecretMode} />
      <Route exact path={SECRET_MODE_TABS_PATH} component={SecretMode} />
    </Switch>
  );
};

export { Router };
