/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import produce from "immer";

import actions, { ActionTypes } from "./actions";
import { INITIAL_COLLECTIONS_CONFIG_STATE } from "./state";

export default function collectionsConfigureReducer(state = INITIAL_COLLECTIONS_CONFIG_STATE, action: ActionTypes) {
  return produce(state, (draft) => {
    switch (action.type) {
      case actions.LOAD_COLLECTIONS_CONFIG:
        draft.allEntityTypes = action.payload.allEntityTypes;
        draft.enabledEntityTypes = action.payload.collectionsConfig.enabledEntityTypes;
        draft.isCollectionConfigModified = false;
        draft.storyLimit = action.payload.collectionsConfig["story-limit"];
        break;
      case actions.UPDATE_COLLECTIONS_CONFIG:
        draft.enabledEntityTypes = action.payload.collectionsConfig.enabledEntityTypes;
        draft.isCollectionConfigModified = true;
        draft.storyLimit = action.payload.collectionsConfig.storyLimit;
        break;
      case actions.RESET_COLLECTIONS_CONFIG:
        draft.isCollectionConfigModified = false;
        break;
      default:
        break;
    }
  });
}
