/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import {
  initializeGoogleMaps,
  attachSearchBox,
  addDefaultMarker,
  createMapWithMarkers,
  addDefaultPolygon,
  initializeDrawer
} from "utils/google-maps.utils";
import LocationSearchBox from "./location-search-box";
import styles from "./location.module.css";
import classnames from "classnames/bind";
import { t } from "i18n";

const cx = classnames.bind(styles);

interface StateProps {
  googleMapApiKey: string;
}

interface OwnProps {
  enableSearchBox?: boolean;
  searchBoxLabel?: string;
  className?: string;
  enableMarkerCreation?: boolean;

  latitude?: number;
  longitude?: number;
  onChange: (val: { lat: number; lon: number }) => void;
  selectedPlace?: string;
  updateSelectedPlace: (place: string) => void;
  polygonVertices?: Array<google.maps.LatLngLiteral>;
  enablePolygonSelection?: boolean;
  showMapInterface?: boolean;
  onPolygonComplete?: (polygonVertices: Array<google.maps.LatLngLiteral>) => void;
  disabled?: boolean;
}

const identity = (val: {}) => {};

type Props = StateProps & OwnProps;

class Location extends React.Component<Props> {
  map: React.RefObject<HTMLDivElement>;
  searchBox: React.RefObject<HTMLInputElement>;

  static defaultProps: Pick<
    Props,
    "enableSearchBox" | "onChange" | "updateSelectedPlace" | "enableMarkerCreation" | "showMapInterface"
  > = {
    enableSearchBox: true,
    onChange: identity,
    updateSelectedPlace: identity,
    enableMarkerCreation: true,
    showMapInterface: true
  };

  constructor(props: Props) {
    super(props);
    this.map = React.createRef();
    this.searchBox = React.createRef();
    this.createGoogleMap = this.createGoogleMap.bind(this);
  }

  componentDidMount() {
    initializeGoogleMaps(this.props.googleMapApiKey, () => this.createGoogleMap());
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.polygonVertices !== this.props.polygonVertices) {
      this.createGoogleMap();
    }
  }

  createGoogleMap() {
    const {
      latitude,
      longitude,
      onChange,
      updateSelectedPlace,
      polygonVertices,
      enablePolygonSelection,
      onPolygonComplete,
      enableSearchBox,
      enableMarkerCreation
    } = this.props;
    const mapOpts = latitude && longitude ? { center: { lat: latitude, lng: longitude } } : null;

    const mapWithMetadata = createMapWithMarkers(this.map.current, mapOpts);
    enableSearchBox &&
      attachSearchBox(mapWithMetadata, this.searchBox.current, onChange, updateSelectedPlace, {
        zoom: 13
      });

    if (longitude && latitude) {
      addDefaultMarker(mapWithMetadata, { lat: latitude, lng: longitude }, updateSelectedPlace, {
        zoom: 13
      });
    }
    if (polygonVertices && polygonVertices.length !== 0) {
      addDefaultPolygon(mapWithMetadata, polygonVertices);
    }
    if (enablePolygonSelection && onPolygonComplete) {
      initializeDrawer(mapWithMetadata, onPolygonComplete);
    }

    if (enableMarkerCreation) {
      mapWithMetadata.map.addListener("click", (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        onChange({ lat, lon: lng });
        addDefaultMarker(mapWithMetadata, { lat, lng }, updateSelectedPlace, { zoom: 13 });
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.enableSearchBox && this.props.searchBoxLabel && (
          <LocationSearchBox
            placeholder={this.props.disabled ? " " : t("story-editor.inspector.location-placeholder")}
            label={this.props.searchBoxLabel}
            ref={this.searchBox}
            disabled={this.props.disabled}
          />
        )}
        <div className={cx(`${this.props.className}`, { "hide-map": !this.props.showMapInterface })}>
          <div
            ref={this.map}
            className={this.props.disabled ? styles["map-disabled"] : styles["map"]}
            data-test-id="google-map-iframe-container"
          />
        </div>
        {this.props.selectedPlace && (
          <p className={styles["location-selected"]} data-test-id="location-selected">
            {this.props.selectedPlace}
          </p>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any): StateProps => {
  return { googleMapApiKey: state.config.googleMaps && state.config.googleMaps["api-key"] };
};

export { Location };

export default compose(connect(mapStateToProps))(Location);
