/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";

import { PartialAppState } from "pages/story-editor/state";
import { Author } from "api/author";

import Authors from "./authors-ts";
import AuthorsWithRoles from "./authors-with-roles";

interface AuthorsWrapperProps {
  isContributorRolesEnabled: boolean;
  onChange: (authors: Author[]) => void;
  value: Author[];
  hasSetAssignmentPermission: boolean;
  disabled?: boolean;
}

const AuthorsWrapper = ({
  isContributorRolesEnabled,
  value,
  onChange,
  hasSetAssignmentPermission,
  disabled
}: AuthorsWrapperProps) => {
  return hasSetAssignmentPermission ? (
    isContributorRolesEnabled ? (
      <AuthorsWithRoles disabled={disabled} />
    ) : (
      <Authors value={value} onChange={onChange} errors={null} isDisabled={disabled} />
    )
  ) : null;
};

const mapStateToProps = (state: PartialAppState) => {
  return {
    isContributorRolesEnabled: state.features.isContributorRolesEnabled,
    hasSetAssignmentPermission: state.features.hasSetAssignmentPermission
  };
};

export default connect(
  mapStateToProps,
  {}
)(AuthorsWrapper);

export { AuthorsWrapper };
