/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { useState } from "react";
import { trim, uniq } from "lodash";
import { isEmail } from "utils";
import { EmailListType } from "store/route-data";
import CreatableSelect from "components/select/creatable";
import { connect } from "react-redux";
import { notificationError } from "action-creators/notification";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "store/form/editor";
import { AnyAction } from "redux";
import { t } from "i18n";
import styles from "./email-list.module.css";
import classnames from "classnames/bind";
import { MultiValueProps } from "react-select/lib/components/MultiValue";

const cx = classnames.bind(styles);

interface DispatchProps {
  invalidEmailError: (message) => void;
}

interface changeValue {
  all: EmailListType;
  valid: EmailListType;
  invalid: EmailListType;
}

interface OwnProps {
  label: string;
  hint?: string;
  value: EmailListType;
  onChange: (value: changeValue) => void;
  onInputChange?: (input: string) => void;
}

type Props = OwnProps & DispatchProps;

export const EmailList: React.FC<Props> = ({ value = [], onChange, onInputChange, label, hint, invalidEmailError }) => {
  const [invalidEmailList, setInvalidEmailList] = useState<EmailListType>([]);
  const handleOnChange = (emails: { label: string; value: string }[], { action }) => {
    const formattedEmails: string[] = uniq(emails.map((email) => trim(email.value)));
    let lastModifiedEmail: string = "";
    if (action === "create-option") {
      lastModifiedEmail = formattedEmails[formattedEmails.length - 1];
    }
    if (formattedEmails.includes(lastModifiedEmail) && !isEmail(lastModifiedEmail)) {
      invalidEmailError(t("forms.editor.errors.invalid-email-address"));
    }
    const invalidEmails = formattedEmails.filter((email) => !isEmail(email));
    const validEmails = formattedEmails.filter((email) => isEmail(email));
    setInvalidEmailList(invalidEmails);
    onChange({ all: formattedEmails, valid: validEmails, invalid: invalidEmails });
  };

  const customComponents = {
    MultiValueContainer: (props: MultiValueProps<any>) => {
      const { children, data } = props;
      const invalidEmail = invalidEmailList.includes(data.value);
      return <div className={cx("multi-value", { "multi-value--invalid": invalidEmail })}>{children}</div>;
    },
    MultiValueLabel: (props) => {
      const { children, data } = props;
      const invalidEmail = invalidEmailList.includes(data.value);
      return <span className={cx("multi-label", { "multi-label--invalid": invalidEmail })}>{children}</span>;
    }
  };
  return (
    <CreatableSelect
      value={value.map((entry) => ({ label: entry, value: entry }))}
      onChange={handleOnChange}
      isMulti={true}
      label={label}
      createLabel={t("common.add")}
      onInputChange={onInputChange}
      components={customComponents}
      hideOptions={true}
      hideDropdownIndicator={true}
      placeholder={""}
      helpText={hint}
    />
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => ({
  invalidEmailError: (message) => dispatch(notificationError(message))
});

export default connect(() => ({}), mapDispatchToProps)(EmailList);
