/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Google = ({ width = 24, height = 24 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox="0 0 24 24">
    <defs>
      <rect id="b" width="21" height="21" x="2" y="1" rx="3" />
      <filter id="a" width="114.3%" height="114.3%" x="-7.1%" y="-7.1%" filterUnits="objectBoundingBox">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="0.5" />
        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1" />
        <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 1)">
        <use fill="#000" filter="url(#a)" xlinkHref="#b" />
        <use fill="#FFF" fillOpacity="0.1" xlinkHref="#b" />
      </g>
      <path
        fill="#4285F4"
        d="M20.66 12.693c0-.603-.054-1.182-.155-1.738H12.5v3.287h4.575a3.91 3.91 0 0 1-1.697 2.566v2.133h2.747c1.608-1.48 2.535-3.66 2.535-6.248z"
      />
      <path
        fill="#34A853"
        d="M12.5 21c2.295 0 4.22-.761 5.625-2.06l-2.747-2.132c-.76.51-1.734.811-2.878.811-2.214 0-4.088-1.495-4.756-3.504h-2.84v2.202A8.497 8.497 0 0 0 12.5 21z"
      />
      <path
        fill="#FBBC05"
        d="M7.744 14.115a5.11 5.11 0 0 1-.267-1.615c0-.56.097-1.105.267-1.615V8.683h-2.84A8.497 8.497 0 0 0 4 12.5c0 1.372.328 2.67.904 3.817l2.84-2.202z"
      />
      <path
        fill="#EA4335"
        d="M12.5 7.38c1.248 0 2.368.43 3.25 1.272l2.437-2.438C16.715 4.842 14.791 4 12.5 4a8.497 8.497 0 0 0-7.596 4.683l2.84 2.202c.668-2.01 2.542-3.504 4.756-3.504z"
      />
      <path d="M4 4h17v17H4z" />
    </g>
  </svg>
);

export default Google;
