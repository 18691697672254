/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import DropDown from "components/dropdown/dropdown";
import styles from "./suggestion-dropdown.module.css";

interface SuggestionDropdownProps {
  items: Array<String>;
  getItemLabel: (item: any) => string;
  onItemClick?: (element: any, event: React.MouseEvent) => void;
  word: string;
}

const SuggestionDropdown = ({ items, onItemClick, getItemLabel, word }: SuggestionDropdownProps) => {
  return (
    <React.Fragment>
      <span className={styles["problem-inside-suggestion"]}>{word}</span>
      <DropDown items={items} onItemClick={onItemClick} getItemLabel={getItemLabel} />
    </React.Fragment>
  );
};

export default SuggestionDropdown;
