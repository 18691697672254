/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { capitalize } from "lodash";
import { SearchBar } from "@quintype/em/components/search-bar";
import { searchOEmbedVideo, getPralineOEmbedData } from "pages/story-editor/async-action-creators";
import { connect } from "react-redux";
import { VideoOEmbedFilter, VideoOEmbedFilters } from "api/route-data/story-route-data";
import { PartialAppState, VideoOembedSearchPages, VideoOEmbedSelectedSearchFilters } from "pages/story-editor/state";
import { Video as PralineVideo } from "api/praline";
import styles from "./oembed-video-selector.module.css";
import FilterIcon from "components/icons/filter";
import { toggleOEmbedInspectorSearchFilter, videoOEmbedSearchFiltersChange } from "pages/story-editor/action-creators";
import SearchFilters from "./search-filters";
import { formattedDate } from "utils";
import TablePagination from "components/table-pagination/table-pagination";
import { Chip } from "@quintype/em/components/chip";
import File from "components/icons/file";
import { t } from "i18n";

interface VideoListProps {
  videos: PralineVideo[];
  provider: VideoOEmbedFilter;
  selectVideo: (provider: string, video: any, storyElementClientId: string) => void;
  storyElementClientId: string;
}

const formatDate = (dateString: string) => {
  if (!dateString) return;
  const date = new Date(dateString).getTime();
  const format = "dd-MM-yyyy";
  return formattedDate(date, format);
};

const VideoList: React.FC<VideoListProps> = ({ videos, selectVideo, provider, storyElementClientId }) => {
  return (
    <React.Fragment>
      {videos.map((video: PralineVideo) => (
        <div
          className={styles["video-list-element"]}
          onClick={() => selectVideo(provider.id, video, storyElementClientId)}>
          <img src={video.thumbnail} alt={video.title} />
          <div>
            <p>{video.title}</p>
            <div className={styles["video-list-element-fineprint"]}>
              <p>
                {[
                  video.channel && video.channel.title,
                  provider && capitalize(provider.name),
                  formatDate(video.publishedAt)
                ]
                  .filter(Boolean)
                  .join(" | ")}
              </p>
            </div>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

interface OwnProps {
  storyElementClientId: string;
}
interface StateProps {
  searchTerm: string;
  videos: PralineVideo[];
  showFilters: boolean;
  selectedFilters: VideoOEmbedSelectedSearchFilters;
  filters: VideoOEmbedFilters;
  pages: VideoOembedSearchPages;
}

interface DispatchProps {
  selectVideo: (provider: string, video: any, storyElementClientId: string) => void;
  search: (provider: string, term: string, direction: string | null, opts?: VideoOembedSearchPages) => void;
  toggleSearchFilters: () => void;
  onSearchFiltersChange: (filterKey: string, filterValue: VideoOEmbedFilter) => void;
}

type OEmbedVideoSelectorProps = DispatchProps & StateProps & OwnProps;

const OEmbedVideoSelector: React.FC<OEmbedVideoSelectorProps> = ({
  pages,
  search,
  searchTerm,
  videos,
  selectVideo,
  storyElementClientId,
  toggleSearchFilters,
  filters,
  showFilters,
  selectedFilters,
  onSearchFiltersChange
}) => {
  return (
    <React.Fragment>
      <div className={styles["oembed-video-selector-wrapper"]}>
        <div className={styles["oembed-inspector-search-and-filter"]}>
          <div className={styles["oembed-inspector-search"]}>
            <SearchBar
              value={searchTerm}
              onChange={(term: string) => search(selectedFilters.provider.id, term, null)}
              placeholder={t("story-editor.video-oembed-selector.search-placeholder")}
            />
          </div>
          <div className={styles["oembed-inspector-filter-button"]} onClick={toggleSearchFilters}>
            <FilterIcon />
          </div>
        </div>
        <div className={styles["oembed-inspector-filter-chips"]}>
          {Object.keys(selectedFilters).map((f) => (
            <Chip value={`${f}: ${selectedFilters[f].name}`} />
          ))}
        </div>
        {showFilters && (
          <SearchFilters filters={filters} selectedFilters={selectedFilters} onChange={onSearchFiltersChange} />
        )}
        {!!videos.length ? (
          <VideoList
            videos={videos}
            provider={selectedFilters.provider}
            selectVideo={selectVideo}
            storyElementClientId={storyElementClientId}
          />
        ) : (
          <div className={styles["no-videos-to-show"]}>
            <File />
            <p>{t("story-editor.video-oembed-selector.no-videos-to-show")}</p>
          </div>
        )}
        {!!videos.length && (
          <div className={styles["video-list-pagination"]}>
            <TablePagination
              classname={styles[`video-paginate-${pages.total > 999 ? "medium" : "small"}`]}
              specifiedPage={pages.page || 1}
              totalCount={pages.total}
              perPage={pages.limit}
              disableTotalCounts={true}
              onPageChange={(page, direction) =>
                search(selectedFilters.provider.id, searchTerm, direction, { ...pages, page })
              }
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    searchTerm: state.storyEditor.videoOEmbedSelector.app.term,
    videos: state.storyEditor.videoOEmbedSelector.app.videos,
    showFilters: state.storyEditor.videoOEmbedSelector.ui.showSearchFilters,
    selectedFilters: state.storyEditor.videoOEmbedSelector.app.selectedFilters,
    filters: state.config.videoOEmbedConfig,
    pages: state.storyEditor.videoOEmbedSelector.ui.pages
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>): DispatchProps => {
  return {
    search: (provider, term, direction, opts) => dispatch(searchOEmbedVideo(provider, term, direction, opts)),
    selectVideo: (provider, video, storyElementClientId) =>
      dispatch(getPralineOEmbedData(provider, video, storyElementClientId)),
    toggleSearchFilters: () => dispatch(toggleOEmbedInspectorSearchFilter()),
    onSearchFiltersChange: (filterKey, filterValue) =>
      dispatch(videoOEmbedSearchFiltersChange({ [filterKey]: filterValue }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OEmbedVideoSelector);
