/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface H4IconProps {
  width?: string;
  height?: string;
  color?: string;
}

const H4: React.SFC<H4IconProps> = ({ width = "24", height = "24", color = "currentColor" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 24 24">
    <polygon points="20.23 13.73 20.23 11.72 17.88 11.72 17.88 13.73 14.99 13.73 19.69 7.26 17.08 7.26 12.01 14.09 12.01 15.76 17.8 15.76 17.8 18.02 20.23 18.02 20.23 15.76 21.98 15.76 21.98 13.73 20.23 13.73" />
    <path d="M7.05,9.63a4.14,4.14,0,0,0-1.48.25,3.33,3.33,0,0,0-1.15.73v-4H2V18h2.4V13.93A2.33,2.33,0,0,1,5,12.26,1.92,1.92,0,0,1,6.4,11.7a1.54,1.54,0,0,1,1.24.49,2.12,2.12,0,0,1,.44,1.46V18h2.39V13.29a3.67,3.67,0,0,0-.94-2.74A3.42,3.42,0,0,0,7.05,9.63Z" />
  </svg>
);

export default H4;
