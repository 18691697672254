/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";

import Checkbox from "components/checkbox/checkbox";
import TextField from "components/text-field/text-field";
import { getErrorMessage } from "pages/story-editor/utils";

import { t } from "i18n";
import styles from "./sponsored-content.module.css";

const cx = classnames.bind(styles);

const SponsoredContent = ({
  value,
  showSponsoredContent,
  toggleSponsoredContent,
  onMetadataChange,
  errors,
  disabled
}) => {
  const sponsoredContent = value;
  const errorMessage = getErrorMessage(errors);

  return (
    <div className={cx("sponsored-content-container", { "is-sponsored-content": showSponsoredContent })}>
      <Checkbox
        id="sponsored-content"
        checked={showSponsoredContent}
        label={t("story-editor.inspector.this-story-is-sponsored-content")}
        onChange={(checked) => toggleSponsoredContent(checked)}
        disabled={disabled}
      />

      <div className={cx("sponsored-by", { "is-open": showSponsoredContent, "has-error": errorMessage })}>
        <TextField
          label={t("story-editor.inspector.sponsored-by")}
          onChange={(value) => onMetadataChange("sponsored-by", value)}
          placeholder={t("story-editor.inspector.enter-sponsored-content")}
          value={sponsoredContent}
          errorMessage={errorMessage}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default SponsoredContent;
