/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const ROLES_PATH = "/settings/users-and-roles/roles";
export const ROLES_EDIT_PATH = "/settings/users-and-roles/roles/:id";
export const ROLES_NEW_PATH = "/settings/users-and-roles/roles/new";
