/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

interface Channel {
  id?: string;
  title: string;
}

interface OEmbedMetadata {
  title: string;
  width: number;
  height: number;
  "thumbnail-url": string;
}

export interface Video {
  id: string;
  title: string;
  description: string;
  thumbnail: string; // maybe add a Url primitive types
  channel?: Channel;
  url: string;
  publishedAt: string;
}

interface SearchResponse {
  total: number;
  limit: number;
  provider: string;
  items: Video[];
  page?: number;
  "next-page-token"?: string;
  "prev-page-token"?: string;
  error?: {
    message: string;
  };
}

interface OEmbedResponse {
  html: string;
  "oembed-type": string;
  "oembed-src": string;
  "provider-name": string;
  "provider-base": string;
  metadata: OEmbedMetadata;
  error?: {
    message: string;
  };
}

export interface SearchOpts {
  limit?: number;
  offset?: number;
  page?: number | null;
  token?: string | null;
}

export const search = async (
  provider: string,
  term: string | null = "",
  opts: SearchOpts
): Promise<SearchResponse | null> => {
  if (!provider) return null;

  const path = `/api/praline/${provider.toLowerCase()}/search`;
  let { limit, offset, token, page } = opts;
  limit = limit || 10;
  offset = offset || 0;
  token = token || "";
  page = page || 0;

  return client
    .url(`${path}?q=${term}&page=${page}&limit=${limit}&offset=${offset}&token=${token}`)
    .get()
    .json();
};

export const getOEmbed = async (
  provider: string,
  videoId: string,
  playerId: string
): Promise<OEmbedResponse | null> => {
  if (!videoId || !provider) return null;
  const queryParams = {
    "video-id": videoId,
    ...(playerId ? { "player-id": playerId } : {})
  }
  return client
    .url(`/api/praline/${provider.toLowerCase()}/oembed`)
    .query(queryParams)
    .get()
    .json();
};
