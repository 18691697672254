/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const BigFact = ({ width = 24, height = 24, color = "#c6ccd5" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} fill={color}>
    <path
      d="M18.56 3.12H5.23a2.4 2.4 0 0 0-2.42 2.4v13.2a2.41 2.41 0 0 0 2.42 2.4h13.33a2.41 2.41 0 0 0 2.44-2.4V5.52a2.4 2.4 0 0 0-2.44-2.4zM20 18.72a1.38 1.38 0 0 1-1.39 1.38H5.23a1.38 1.38 0 0 1-1.39-1.38V5.52a1.38 1.38 0 0 1 1.39-1.38h13.33A1.38 1.38 0 0 1 20 5.52z"
      fillRule="evenodd"
    />
    <path
      d="M12.56 16.57a.55.55 0 0 1-.29.08c-.08 0-.29 0-.29-.39v-.18l.79-5.49-.19-.09-2.09.3-.06.35.77.35-.38 2.67c-.17 1.19-.28 2-.35 2.56a.53.53 0 0 0 .22.46 1.14 1.14 0 0 0 .69.17 1.64 1.64 0 0 0 .71-.15 2.36 2.36 0 0 0 .55-.34c.13-.12.23-.22.28-.22l-.12-.27-.27.19m.29-8.94a.94.94 0 0 0-.9 0 1 1 0 0 0-.35.34 1 1 0 0 0-.12.48.65.65 0 0 0 .1.36.86.86 0 0 0 .28.29.79.79 0 0 0 .37.1 1.12 1.12 0 0 0 .53-.13.84.84 0 0 0 .34-.36 1.06 1.06 0 0 0 .12-.5.64.64 0 0 0-.1-.35.62.62 0 0 0-.27-.26"
      fillRule="evenodd"
    />
  </svg>
);

export default BigFact;
