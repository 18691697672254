/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { get } from "lodash";

import { StoryTemplateFields, PartialStoryTemplateFields } from "../state";
import { Fields } from "api/route-data/story-route-data";
import { AssociatedStoryDefaults } from "api/route-data/breaking-news-route-data";
import { Image } from "api/search-media-image";

import Inspector from "components/inspector/inspector";
import MandatoryFilter from "../mandatory-filter";
import Field from "./field";
import ImageField from "components/image-field/image-field";
import TextAreaWithCount from "components/text-area/text-area-with-count";
import Authors from "pages/story-editor/components/manage-fields/authors-ts";
import TextArea from "components/text-area/text-area";
import Summary from "pages/story-editor/components/manage-fields/summary";
import ProseMirrorTextArea from "components/prosemirror-text-area/prosemirror-text-area";
import { ValidationErrors, StoryValidationFields } from "api/breaking-news";
import { getErrorMessage } from "pages/story-editor/utils";
import RichTextField from "components/rich-text-field/rich-text-field";

interface OwnProps {
  isActive: boolean;
  fieldsSpec: Fields;
  storyTemplateFields: StoryTemplateFields;
  associatedStoryDefaults: AssociatedStoryDefaults;
  errors?: ValidationErrors<StoryValidationFields>;
  storyTemplateFieldChangeHandler: (partialStoryTemplateFields: PartialStoryTemplateFields) => void;
  saveStoryTemplateFields: (storyTemplateFields: PartialStoryTemplateFields) => void;
  cancelAdvancedOptionsSave: () => void;
}

type AdvancedOptionsInspectorProps = OwnProps;

export const AdvancedOptionsInspector: React.SFC<AdvancedOptionsInspectorProps> = ({
  isActive,
  fieldsSpec,
  storyTemplateFields,
  associatedStoryDefaults,
  errors,
  storyTemplateFieldChangeHandler,
  saveStoryTemplateFields,
  cancelAdvancedOptionsSave
}) => {
  const {
    headline,
    subheadline,
    heroImage,
    "meta-title": metaTitle,
    "meta-description": metaDescription,
    cardContent,
    authors,
    summary
  } = storyTemplateFields;
  return (
    <Inspector
      title={t("breaking-news.advanced-options-inspector.title")}
      onClose={cancelAdvancedOptionsSave}
      isActionButtonDisabled={false}
      isBackgroundActionDisabled={true}
      onActionButtonClick={() =>
        saveStoryTemplateFields({
          headline,
          subheadline,
          heroImage,
          "meta-title": metaTitle,
          "meta-description": metaDescription,
          cardContent,
          authors,
          summary
        })
      }
      actionButtonLabel={t("breaking-news.advanced-options-inspector.save")}
      isActive={isActive}
      variant="default">
      <MandatoryFilter fieldsSpec={fieldsSpec}>
        <Field
          fieldName="hero-image-s3-key"
          bypassFilter={associatedStoryDefaults && associatedStoryDefaults["image-data"] ? true : false}
          render={() => (
            <ImageField
              value={
                heroImage && {
                  url: heroImage.url,
                  caption: heroImage.caption || ""
                }
              }
              openGallery={() => {}}
              disableReplace={true}
              onReplace={() => {}}
              disableDelete={true}
              onDelete={() => {}}
              isPhotoEditor={false}
              openPhotoEditor={() => {}}
            />
          )}
        />
        <Field
          fieldName="hero-image-attribution"
          bypassFilter={!!get(associatedStoryDefaults, ["image-data", "image-attribution"])}
          render={() => (
            <RichTextField
              label={t("breaking-news.associated-story.hero-image-attribution")}
              value={heroImage && heroImage.attribution ? heroImage.attribution : ""}
              onChange={(heroImageAttribution: string) =>
                storyTemplateFieldChangeHandler({
                  heroImage: { ...heroImage, attribution: heroImageAttribution } as Image
                })
              }
              errorMessage={
                errors && errors["hero-image-attribution"] ? getErrorMessage(errors["hero-image-attribution"]) : ""
              }
            />
          )}
        />
        <Field
          fieldName="hero-image-caption"
          bypassFilter={
            associatedStoryDefaults && associatedStoryDefaults["image-data"]["image-caption"] ? true : false
          }
          render={() => (
            <RichTextField
              label={t("breaking-news.associated-story.hero-image-caption")}
              value={heroImage && heroImage.caption ? heroImage.caption : ""}
              onChange={(heroImageCaption: string) =>
                storyTemplateFieldChangeHandler({
                  heroImage: { ...heroImage, caption: heroImageCaption } as Image
                })
              }
              errorMessage={errors && errors["hero-image-caption"] ? getErrorMessage(errors["hero-image-caption"]) : ""}
            />
          )}
        />
        <Field
          fieldName="headline"
          render={() =>
            fieldsSpec.headline.validations["hard-limit"] ? (
              <TextAreaWithCount
                label={t("story-editor.header-card.title")}
                value={storyTemplateFields.headline}
                onChange={(headline: string) => storyTemplateFieldChangeHandler({ headline })}
                maxValue={fieldsSpec.headline.validations["hard-limit"]}
                errorMessage={errors && errors.headline ? getErrorMessage(errors.headline) : ""}
              />
            ) : (
              <TextArea
                label={t("story-editor.header-card.title")}
                value={storyTemplateFields.headline}
                onChange={(headline: string) => storyTemplateFieldChangeHandler({ headline })}
                errorMessage={errors && errors.headline ? getErrorMessage(errors.headline) : ""}
              />
            )
          }
        />
        <Field
          fieldName="subheadline"
          render={() =>
            fieldsSpec.subheadline.validations["hard-limit"] ? (
              <TextAreaWithCount
                label={t("story-editor.header-card.subtitle")}
                value={storyTemplateFields.subheadline}
                onChange={(subheadline: string) => storyTemplateFieldChangeHandler({ subheadline })}
                maxValue={fieldsSpec.subheadline.validations["hard-limit"]}
                errorMessage={errors && errors.subheadline ? getErrorMessage(errors.subheadline) : ""}
              />
            ) : (
              <TextArea
                label={t("story-editor.header-card.subtitle")}
                value={storyTemplateFields.subheadline}
                onChange={(subheadline: string) => storyTemplateFieldChangeHandler({ subheadline })}
                errorMessage={errors && errors.subheadline ? getErrorMessage(errors.subheadline) : ""}
              />
            )
          }
        />
        <Field
          fieldName="content"
          bypassFilter={true}
          render={() => (
            <ProseMirrorTextArea
              onChange={(htmlString: string) => storyTemplateFieldChangeHandler({ cardContent: htmlString })}
              value={storyTemplateFields.cardContent}
              label={t("breaking-news.associated-story.content")}
              errorMessage={errors && errors.cardContent ? getErrorMessage(errors.cardContent) : ""}
            />
          )}
        />
        <Field
          fieldName="summary"
          render={() => (
            <Summary
              value={storyTemplateFields["summary"]}
              onChange={(summary: string) => storyTemplateFieldChangeHandler({ summary })}
              spec={fieldsSpec.summary}
              errors={errors && errors.summary}
            />
          )}
        />
        <Field
          fieldName="author"
          render={() => (
            <Authors
              label={t("story-editor.inspector.author")}
              value={storyTemplateFields.authors}
              onChange={(authors) => storyTemplateFieldChangeHandler({ authors })}
              errors={errors && errors.authors ? errors.authors : null}
            />
          )}
        />
        <Field
          fieldName="meta-title"
          render={() =>
            fieldsSpec["meta-title"].validations["hard-limit"] ? (
              <TextAreaWithCount
                label={t("story-editor.inspector.meta-title")}
                value={storyTemplateFields["meta-title"]}
                onChange={(metaTitle: string) => storyTemplateFieldChangeHandler({ "meta-title": metaTitle })}
                maxValue={fieldsSpec["meta-title"].validations["hard-limit"]}
                errorMessage={errors && errors["meta-title"] ? getErrorMessage(errors["meta-title"]) : ""}
              />
            ) : (
              <TextArea
                label={t("story-editor.inspector.meta-title")}
                value={storyTemplateFields["meta-title"]}
                onChange={(metaTitle: string) => storyTemplateFieldChangeHandler({ "meta-title": metaTitle })}
                errorMessage={errors && errors["meta-title"] ? getErrorMessage(errors["meta-title"]) : ""}
              />
            )
          }
        />
        <Field
          fieldName="meta-description"
          render={() =>
            fieldsSpec["meta-description"].validations["hard-limit"] ? (
              <TextAreaWithCount
                label={t("story-editor.inspector.meta-description")}
                value={storyTemplateFields["meta-description"]}
                onChange={(metaDescription) => storyTemplateFieldChangeHandler({ "meta-description": metaDescription })}
                maxValue={fieldsSpec["meta-description"].validations["hard-limit"]}
                errorMessage={errors && errors["meta-description"] ? getErrorMessage(errors["meta-description"]) : ""}
              />
            ) : (
              <TextArea
                label={t("story-editor.inspector.meta-description")}
                value={storyTemplateFields["meta-description"]}
                onChange={(metaDescription) => storyTemplateFieldChangeHandler({ "meta-description": metaDescription })}
                errorMessage={errors && errors["meta-description"] ? getErrorMessage(errors["meta-description"]) : ""}
              />
            )
          }
        />
      </MandatoryFilter>
    </Inspector>
  );
};
