/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";

const Calendar = ({ width = 24, height = 24, color = "var(--mono-4)" }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    fill={color}>
    <title>calendar</title>
    <path d="M18.23,5.07a1.71,1.71,0,0,1,1.71,1.71V19.35a1.71,1.71,0,0,1-1.71,1.72H5.66a1.71,1.71,0,0,1-1.72-1.72V6.78A1.71,1.71,0,0,1,5.66,5.07H7.37V3.21a.38.38,0,0,1,.13-.3.38.38,0,0,1,.3-.13h.29a.38.38,0,0,1,.3.13.42.42,0,0,1,.13.3V5.07h6.85V3.21a.38.38,0,0,1,.13-.3.38.38,0,0,1,.3-.13h.29a.38.38,0,0,1,.3.13.42.42,0,0,1,.13.3V5.07ZM5.66,6.21a.54.54,0,0,0-.41.16.56.56,0,0,0-.16.41V8.5H18.8V6.78a.56.56,0,0,0-.16-.41.54.54,0,0,0-.41-.16ZM18.23,19.92a.54.54,0,0,0,.41-.16.53.53,0,0,0,.16-.41V9.64H5.09v9.71a.53.53,0,0,0,.16.41.54.54,0,0,0,.41.16Z" />
  </svg>
);

export default Calendar;
