/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

import { StoryId, Timestamp, SectionId, AuthorId } from "./primitive-types";

export interface RangeFilter {
  "start-ts": Timestamp;
  "end-ts": Timestamp;
}

export interface Filters {
  tags: string[];
  "author-ids": Array<number>;
  sections: string[];
  "assignee-ids": string[];
  "story-templates": string[];
  devices: string[];
  "referrer-ids": string[];
}

export interface Top {
  id: StoryId | SectionId | AuthorId;
  name?: string;
  count: number;
  change: number | null;
}

export interface TimeSlice {
  timestamp: Timestamp;
  count: number;
  index: number;
  change: number | null;
  "prev-timestamp": Timestamp;
  "prev-count": number;
}

export interface Analytics {
  "dim-count": number;
  "dim-change": number | null;
  "metric-count": number;
  "metric-change": number | null;
  "time-slices": TimeSlice[];
  top: Top[];
  //TODO: Not sure about the type for 'published-stories'. Have kept it array of strings for now
  "published-stories": string[];
}

interface FiltersWithDateRange extends Filters {
  "range-2": RangeFilter;
  "range-1": RangeFilter;
}

export const getAnalytics = (
  dim: string,
  metric: string,
  filters: FiltersWithDateRange,
  options: Object = {}
): Promise<Analytics> => {
  return client
    .url(`/api/qlitics/story/${dim}/${metric}`)
    .query({ filters: JSON.stringify(filters) })
    .query(options)
    .get()
    .json();
};
