/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import produce from "immer";
import { INITIAL_CONTRIBUTOR_ROLES_STATE, INITIAL_CONTRIBUTOR_ROLE, INITIAL_ERROR_STATE } from "./state";
import actions, { ActionTypes } from "./actions";

export default function contributorRolesReducer(state = INITIAL_CONTRIBUTOR_ROLES_STATE, action: ActionTypes) {
  return produce(state, (draft) => {
    switch (action.type) {
      case actions.LOAD_CONTRIBUTOR_ROLES:
        draft.allContributorRoles = action.payload;
        break;

      case actions.MAYBE_ADD_CONTRIBUTOR_ROLE:
        draft.currentContributorRole = INITIAL_CONTRIBUTOR_ROLE;
        draft.ui.errors = INITIAL_ERROR_STATE;
        if (action.payload.allContributorRoles) {
          draft.allContributorRoles = action.payload.allContributorRoles;
        }
        break;

      case actions.SAVE_CONTRIBUTOR_ROLE_FAILURE:
        draft.ui.errors["name-field-duplicate"] = true;
        break;

      case actions.CANCEL_SAVE_CONTRIBUTOR_ROLE:
        draft.currentContributorRole = INITIAL_CONTRIBUTOR_ROLE;
        draft.ui.errors = INITIAL_ERROR_STATE;
        break;

      case actions.UPDATE_CURRENT_CONTRIBUTOR_ROLE:
        draft.currentContributorRole = action.payload;
        break;

      case actions.UPDATE_CURRENT_CONTRIBUTOR_ROLE_FAILURE:
        draft.ui.errors["name-field-duplicate"] = true;
        break;

      case actions.MAYBE_EDIT_CONTRIBUTOR_ROLE:
        draft.ui.errors = INITIAL_ERROR_STATE;
        draft.currentContributorRole = action.payload.currentContributorRole;
        break;

      case actions.CANCEL_UPDATE_CONTRIBUTOR_ROLE:
        draft.currentContributorRole = INITIAL_CONTRIBUTOR_ROLE;
        draft.ui.errors = INITIAL_ERROR_STATE;
        break;

      case actions.MAYBE_DELETE_CONTRIBUTOR_ROLE:
        draft.currentContributorRole = action.payload;
        draft.ui.showDeleteModal = true;
        break;

      case actions.DELETE_CONTRIBUTOR_ROLE_SUCCESS:
        draft.currentContributorRole = INITIAL_CONTRIBUTOR_ROLE;
        draft.ui.showDeleteModal = false;
        break;

      case actions.CANCEL_DELETE_CONTRIBUTOR_ROLE:
        draft.currentContributorRole = INITIAL_CONTRIBUTOR_ROLE;
        draft.ui.showDeleteModal = false;
        break;

      default:
        break;
    }
  });
}
