/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { client } from "api/client";
import { Features } from "api/route-data/route-data";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface State {
  content: string;
}

export interface PartialAppState {
  features: Features;
  billing: State;
}

const INITIAL_BILLING_STATE = {
  content: ""
};

interface Data {
  content: string;
}

const { reducer, actions } = createSlice({
  initialState: INITIAL_BILLING_STATE,
  name: "billing",
  reducers: {
    setReport: (state: State, { payload }: PayloadAction<Data>) => {
      state.content = payload.content;
    }
  }
});

const getBandwidthReport = (duration: string): Promise<string> => {
  return client
    .url(`/api/v1/billing-report?duration=${duration}`)
    .get()
    .text();
};

export const fetchBandwidthReport = (duration: string) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>
) => {
  getBandwidthReport(duration).then((content) => {
    dispatch(setReport({ content }));
  });
};

const getSageTokensReport = (duration: string): Promise<string> => {
  return client
    .url(`/api/v1/sage-billing-report?duration=${duration}`)
    .get()
    .text();
};

export const fetchSageTokensReport = (duration: string) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>
) => {
  getSageTokensReport(duration).then((content) => {
    dispatch(setReport({ content }));
  });
};

export const { setReport } = actions;

export default reducer;
