/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import { compose } from "redux";
import { connect } from "react-redux";
import * as actions from "../../actions";
import {
  getSavedFilters,
  deleteSavedFilter,
  setDefaultFilter,
  removeDefaultFilter,
  shareUnshareFilter
} from "helpers/api";
import Globe from "components/icons/globe";
import { ContextMenu, ContextMenuList, ContextMenuItem } from "components/context-menu/context-menu";
import { setSavedFilterAction } from "../../action-creators";
import { modifySavedFilter } from "../../filter-conversion";

import styles from "./saved-filter-list.module.css";
import { t } from "i18n";
import Spinner from "components/spinner/spinner";
import { initialSelectedSavedFilter } from "../../reducer";

const cx = classnames.bind(styles);

const SavedFilterMenu = ({ filter, filterActions, isMyFilter, selectedSavedFilter, canShareFilter }: any) => {
  const defaultActions = filter.default ? (
    <ContextMenuItem
      label={t("workspace.filters_inspector.remove_default")}
      onClick={() => filterActions.removeDefault(filter.id)}
    />
  ) : (
    <ContextMenuItem
      label={t("workspace.filters_inspector.set_as_default")}
      onClick={() => filterActions.setDefault(filter.id)}
    />
  );

  const shareActions = filter.shared ? (
    <ContextMenuItem
      label={t("workspace.filters_inspector.make_private")}
      onClick={() => filterActions.unshare(filter.id)}
    />
  ) : (
    canShareFilter && (
      <ContextMenuItem
        label={t("workspace.filters_inspector.make_public")}
        onClick={() => filterActions.share(filter.id)}
      />
    )
  );

  const deleteAction = (
    <ContextMenuItem
      label={t("workspace.filters_inspector.delete")}
      onClick={() => filterActions.delete(filter.id, selectedSavedFilter.id)}
    />
  );

  return (
    <div>
      {isMyFilter && shareActions}
      {isMyFilter && deleteAction}
      {defaultActions}
    </div>
  );
};

const DisplayFilterListItem = ({
  filter,
  filterActions,
  isMyFilter,
  setSavedFilter,
  selectedSavedFilter,
  canShareFilter
}: any) => {
  const classes = cx("filter-list-item", {
    "is-selected": selectedSavedFilter && selectedSavedFilter.id === filter.id
  });

  return (
    <li data-testid="filter-list-item" className={classes}>
      <div
        className={styles["filter-list-item-content"]}
        data-testid="filter-list-item-content"
        onClick={() => setSavedFilter(filter)}>
        <span data-testid="filter-name" className={styles["filter-name"]}>
          {filter.name}
        </span>
        {filter.default && (
          <span data-testid="default-filter" className={styles["default-filter"]}>{`( ${t(
            "workspace.filters_inspector.default"
          )} )`}</span>
        )}
        {filter.shared && isMyFilter && <Globe />}
      </div>
      <div className={styles["context-menu"]} data-testid="context-menu" id={`workspace-filter-menu-${filter.id}`}>
        <ContextMenu>
          <div className={styles["saved-filter-dropdown"]}>
            <ContextMenuList>
              <SavedFilterMenu
                filter={filter}
                filterActions={filterActions}
                isMyFilter={isMyFilter}
                selectedSavedFilter={selectedSavedFilter}
                canShareFilter={canShareFilter}
              />
            </ContextMenuList>
          </div>
        </ContextMenu>
      </div>
    </li>
  );
};

class SavedFilterList extends React.Component<any, any> {
  componentDidMount() {
    this.props.loadData();
  }

  render() {
    const { "my-filters": myFilters, "shared-filters": sharedFilters } = this.props.savedFiltersList;

    return (
      <div className={styles["saved-list-filter-container"]}>
        <h4 className={styles["saved-list-filter-title"]}>{t("workspace.filters_inspector.public_filters")}</h4>
        {this.props.showLoadingIndicator && (
          <Spinner message={t("workspace.filters_inspector.loading_public_filters")} />
        )}
        {!this.props.showLoadingIndicator && sharedFilters.length !== 0 && (
          <ul className={styles["saved-list-filter"]} data-testid="public-filters">
            {sharedFilters.map((filter: any) => (
              <DisplayFilterListItem
                key={`filter-${filter.id}`}
                filter={filter}
                filterActions={this.props.filterActions}
                setSavedFilter={this.props.setSavedFilter}
                selectedSavedFilter={this.props.selectedSavedFilter}
                canShareFilter={this.props.canShareFilter}
              />
            ))}
          </ul>
        )}
        {!this.props.showLoadingIndicator && !sharedFilters.length && (
          <span className={styles["no-content-message"]}>{t("workspace.filters_inspector.no_public_filters")}</span>
        )}

        <h4 className={styles["saved-list-filter-title"]}>{t("workspace.filters_inspector.my_filters")}</h4>
        {this.props.showLoadingIndicator && <Spinner message={t("workspace.filters_inspector.loading_my_filters")} />}
        {!this.props.showLoadingIndicator && myFilters.length > 0 && (
          <ul className={styles["saved-list-filter"]} data-testid="saved-filters">
            {myFilters.map((filter: any) => (
              <DisplayFilterListItem
                key={`filter-${filter.id}`}
                filter={filter}
                filterActions={this.props.filterActions}
                setSavedFilter={this.props.setSavedFilter}
                isMyFilter={true}
                selectedSavedFilter={this.props.selectedSavedFilter}
                canShareFilter={this.props.canShareFilter}
              />
            ))}
          </ul>
        )}
        {!this.props.showLoadingIndicator && myFilters.length === 0 && (
          <span className={styles["no-content-message"]}>{t("workspace.filters_inspector.no_my_filters")}</span>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    savedFiltersList: state.workspace.savedFilterList,
    selectedSavedFilter: state.workspace.selectedSavedFilter,
    showLoadingIndicator: state.workspace.showLoadingIndicator,
    canShareFilter: state.features.canShareFilter
  };
};

const loadData = (dispatch: any) => () => {
  dispatch({ type: actions.WORKSPACE_FETCH_SAVED_FILTERS });
  getSavedFilters().then((data) => dispatch({ type: actions.WORKSPACE_SET_SAVED_FILTER_LIST, payload: { data } }));
};

const filterActions = (dispatch: any) => {
  const dispatchLoadData = loadData(dispatch);

  return {
    share: (filterId: any) => {
      dispatch({ type: actions.WORKSPACE_SHARE_SAVED_FILTER, payload: { filterId } });
      shareUnshareFilter(filterId, "share").then(dispatchLoadData);
    },
    unshare: (filterId: any) => {
      dispatch({ type: actions.WORKSPACE_UNSHARE_SAVED_FILTER, payload: { filterId } });
      shareUnshareFilter(filterId, "unshare").then(dispatchLoadData);
    },
    setDefault: (filterId: any) => {
      dispatch({ type: actions.WORKSPACE_SET_DEFAULT_SAVED_FILTER, payload: { filterId } });
      setDefaultFilter(filterId).then(dispatchLoadData);
    },
    removeDefault: (filterId: any) => {
      dispatch({ type: actions.WORKSPACE_REMOVE_DEFAULT_SAVED_FILTER, payload: { currentDefaultFilterId: filterId } });
      removeDefaultFilter().then(dispatchLoadData);
    },
    delete: (filterId: any, selectedSavedFilterId: any) => {
      dispatch({ type: actions.WORKSPACE_DELETE_SAVED_FILTER, payload: { filterId } });
      if (selectedSavedFilterId === filterId) {
        dispatch({
          type: actions.WORKSPACE_SET_SELECTED_SAVED_FILTER,
          payload: { filter: initialSelectedSavedFilter }
        });
      }
      deleteSavedFilter(filterId).then(dispatchLoadData);
    }
  };
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => {
  return {
    loadData: loadData(dispatch),
    filterActions: filterActions(dispatch),
    setSavedFilter: (filter: any) => dispatch(setSavedFilterAction(filter, modifySavedFilter(filter)))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SavedFilterList);

export { SavedFilterList, SavedFilterMenu };
