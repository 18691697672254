/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Pages = ({ width = "24", height = "24" }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox="0 0 24 24">
    <defs>
      <path
        id="a-pages"
        d="M4.307 5.648c-.731 0-1.324.596-1.324 1.326v12.719c0 .73.593 1.326 1.324 1.326h12.718c.73 0 1.326-.596 1.326-1.326V6.974c0-.73-.596-1.326-1.326-1.326H4.307zM17.025 22H4.307A2.31 2.31 0 0 1 2 19.693V6.974a2.31 2.31 0 0 1 2.307-2.307h12.718a2.311 2.311 0 0 1 2.308 2.307v12.719A2.311 2.311 0 0 1 17.025 22zM4.667 9.846h12v-1.18h-12v1.18zm0 4h12v-1.18h-12v1.18zm0 4H10v-1.18H4.667v1.18zM19.673 2H6.855c-1.016 0-1.873.66-2.188 1.568h1.117c.243-.34.62-.58 1.07-.58h12.82c.737 0 1.336.6 1.336 1.337v12.814c0 .458-.246.842-.598 1.083v1.111A2.32 2.32 0 0 0 22 17.14V4.325A2.328 2.328 0 0 0 19.673 2"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="b-pages" fill="currentColor">
        <use xlinkHref="#a-pages" />
      </mask>
      <use fill="currentColor" stroke="currentColor" strokeWidth="0.5" xlinkHref="#a-pages" />
      <g fill="currentColor" mask="url(#b-pages)">
        <path d="M0 0h24v24H0z" />
      </g>
      <path d="M0 0h24v24H0z" mask="url(#b-pages)" />
    </g>
  </svg>
);

export default Pages;
