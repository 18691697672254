/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { Story, Tree, UnsavedStory } from "api/story";
import { get } from "lodash";

export enum AllowedStoryFields {
  META_TITLE = "meta-title",
  META_DESC = "meta-description",
  PUSH_NOTIFICATION_TITLE = "push-notification-title",
  PUSH_NOTIFICATION_MESSAGE = "push-notification-message",
  HEADLINE = "headline",
  SUBHEADLINE = "subheadline",
  SUMMARY = "summary",
  SOCIAL_SHARE = "social-share",
  SUMMARY_STORY_ELEMENT = "summary-story-element"
}

const stripHtmlTags = (text: string): string => {
  return text.replace(/(<([^>]+)>)/gi, "");
};

const createSuggestionPayload = (story: Story | UnsavedStory) => {
  const tree: Tree[] = get(story, "tree", []);
  let textContent: string[] = [];
  if (tree) {
    const cardIds = tree.map((card) => card["content-id"]);
    for (const cardId of cardIds) {
      const card = story.cards[cardId];
      if (card) {
        const cardTree = card.tree;
        for (const storyElementId of cardTree) {
          const storyElement = story["story-elements"][storyElementId];
          const { title, description, text } = storyElement;
          textContent.push(stripHtmlTags([title, description, text].join(" ")).trim());
        }
      }
    }
  }
  return { headline: story.headline, "text-content": textContent.join("\n") };
};

export const getSuggestion = (
  story: Story | UnsavedStory,
  field: AllowedStoryFields,
  maxLength?: number,
  language?: string,
  count?: number
) => {
  let url = `/arthur/api/v1/story/suggestion/${field}`;
  const queryParams = {
    ...(maxLength ? { "max-length": maxLength } : {}),
    ...(language ? { language } : {}),
    ...(count ? { count } : {})
  };
  return client
    .url(url)
    .query(queryParams)
    .json(createSuggestionPayload(story))
    .post()
    .json();
};

export const paraphraseText = (text, language: string): Promise<{ "paraphrased-text": string }> => {
  const queryParams = {
    ...(language ? { language } : {})
  };
  return client
    .url(`/arthur/api/v1/text/paraphrase`)
    .query(queryParams)
    .json({ text: text })
    .post()
    .json();
};
