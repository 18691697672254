/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { LoaderState, INITIAL_LOADER_STATE } from "behaviors/loader/state";
import { IntegrationsResponse, IntegrationConfig, SupportedIntegrationsResponse, Integrations } from "api/integrations";
import * as PublisherAPI from "api/publisher";

export interface PartialAppState {
  integrations: State;
  viewport: {
    isDesktopSizeViewport: boolean;
  };
}

export enum IntegrationsPageInspectorType {
  Create = "create",
  Edit = "edit",
  INTEGRATION_SETTINGS = "integration-settings"
}

export enum IntegrationType {
  ALL = "all",
  SOCIAL = "social",
  PUSH_NOTIFICATION = "push-notification",
  MEDIA = "media",
  TASK_MANAGEMENT = "task-management",
  ANALYTICS = "analytics",
  OTHERS = "others"
}

export type Tab = {
  label: string;
  value: IntegrationType;
};

export type Publisher = Partial<PublisherAPI.Publisher>;

export type IntegrationListByType = { [key in Integrations]: IntegrationConfig[] } | {};

export interface State {
  publisherIntegrationsAPIData: IntegrationsResponse | null;
  supportedIntegrations: SupportedIntegrationsResponse | null;

  app: {
    selectedIntegration: IntegrationConfig | null;
    listIntegrationsAvailableToAdd: Integrations[];
    publisherIntegrations: Array<IntegrationConfig>;
    publisher: Publisher;
    integrationListByType: IntegrationListByType;
    integrationType: IntegrationType;
  };
  ui: {
    main: LoaderState;
    saveErrors: LoaderState;
    isValidateInProgress?: boolean;
  };
}

export const INITIAL_INTEGRATIONS_STATE: State = {
  publisherIntegrationsAPIData: null,
  supportedIntegrations: null,
  app: {
    selectedIntegration: null,
    listIntegrationsAvailableToAdd: [],
    publisherIntegrations: [],
    publisher: {},
    integrationListByType: {},
    integrationType: IntegrationType.ALL
  },
  ui: {
    main: INITIAL_LOADER_STATE,
    isValidateInProgress: false,
    saveErrors: INITIAL_LOADER_STATE
  }
};
