/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { findCardNP } from "../find";
import { removeKeyFromObject } from "utils";
import { setTextSelectionToDocumentEnd } from "../selection";
import { AnyStory, Card, CompositeStoryElement } from "api/story";
import { EditorState } from "prosemirror-state";
import { flatten } from "lodash";

function deleteCardFromStory(story: AnyStory, card: Card) {
  const cardTree = story.cards[card["content-id"]].tree;

  const cardStoryElements = cardTree.map((id) => story["story-elements"][id]);
  const childStoryElementIds = cardStoryElements.map((ele) => {
    if (ele.hasOwnProperty("tree")) {
      const compositeEle = ele as CompositeStoryElement;
      return compositeEle.tree;
    }
    return [];
  });
  const allIds = flatten([...cardTree, ...childStoryElementIds]);

  const storyElements = allIds.reduce((newStoryElements, seID) => {
    return removeKeyFromObject(seID, newStoryElements);
  }, story["story-elements"]);

  const entities = allIds.reduce((newStoryElements, seID) => {
    let entityPath = `storyElement_${seID}`;
    return removeKeyFromObject(entityPath, newStoryElements);
  }, story.entities);

  return {
    ...story,
    tree: story.tree.filter((treeCard) => treeCard["content-id"] !== card["content-id"]),
    cards: removeKeyFromObject(card["content-id"], story.cards),
    "story-elements": storyElements,
    entities
  };
}

function deleteCardFromEditorState(editorState: EditorState, card: Card) {
  const tr = editorState.tr.setMeta("userAction", "cardDelete");
  const cardNP = findCardNP(editorState, card);

  if (cardNP) {
    // Delete card and apply transaction
    tr.delete(cardNP.pos, cardNP.pos + cardNP.node.nodeSize);
    const updatedEditorState = editorState.apply(tr);

    // Set cursor to document end
    const trWithFocus = setTextSelectionToDocumentEnd(updatedEditorState.tr, updatedEditorState);
    return updatedEditorState.apply(trWithFocus.setMeta("addToHistory", false));
  }

  return editorState.apply(tr);
}

function deleteCard(story: AnyStory, editorState: EditorState, card: Card) {
  const newStory = deleteCardFromStory(story, card);
  const newEditorState = deleteCardFromEditorState(editorState, card);

  return { story: newStory, editorState: newEditorState };
}

export default deleteCard;
