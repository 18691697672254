/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Card } from "api/story";
import { CardId } from "api/primitive-types";

const newCardIdPrefix = "new-card-";

export function newCard(): Card {
  const id = newCardIdPrefix + Math.random();

  return {
    id,
    "content-id": id,
    "client-id": "client-card-" + Math.random(),
    "content-type": "card",
    tree: [],
    metadata: {
      "social-share": {},
      attributes: {}
    }
  };
}

export function isNewCardId(cardId: CardId) {
  return cardId.startsWith(newCardIdPrefix);
}
