/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import TextFieldWithCopy from "components/text-field/text-field-with-copy";
import Button from "components/button/button";
import Share from "components/icons/share";
import styles from "./analytics-share.module.css";

interface Props {
  currentUrl: string;
  toggleAnalyticsShare: () => void;
  isAnalyticsShareVisible: boolean;
}

const cx = classnames.bind(styles);
class AnalyticsShare extends React.Component<Props> {
  private analyticsShare: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.analyticsShare = React.createRef();
  }
  toggleAnalyticsShare() {
    const node = this.analyticsShare.current;
    if (node) {
      node.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    this.props.toggleAnalyticsShare();
  }
  render() {
    const { currentUrl, isAnalyticsShareVisible } = this.props;
    return (
      <div ref={this.analyticsShare} className={styles["analytics-share-container"]}>
        <Button type="transparent" onClick={() => this.toggleAnalyticsShare()} testId="analytics-share-btn">
          <Share />
        </Button>
        <div className={cx("analytics-share", { "is-active": isAnalyticsShareVisible })}>
          <TextFieldWithCopy value={`${window.location.origin}${currentUrl}`} />
        </div>
      </div>
    );
  }
}

export default AnalyticsShare;
