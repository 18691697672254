/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

import Inspector from "components/inspector/inspector";
import { PartialAppState, CustomURLsInspectorType } from "../../state";
import { startCase } from "lodash";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction, compose } from "redux";
import { connect } from "react-redux";

import styles from "./inspector.module.css";
import { AnyCustomURL, CustomURLType } from "api/custom-url";
import TextField from "components/text-field/text-field";
import Select from "components/select/select";

import { updateCurrentCustomURL, cancelSaveCurrentCustomURL, saveCurrentCustomURL } from "../../async-action-creators";
import { isClientValidationError } from "utils/validation.utils";

import { LoaderState } from "behaviors/loader/state";

import Spinner from "components/spinner/spinner";
import Button from "components/button/button";
import { isExistingCustomURL } from "../../utils";
import { t } from "i18n";

import { toggleDeleteConfirmationAction } from "../../action-creators";

interface StateProps {
  currentCustomURL: AnyCustomURL | null;
  saveLoader: LoaderState;
}

interface DispatchProps {
  saveCustomURLItem: (customURLItem: any) => void;
  cancelSaveCustomURLItem: () => void;
  toggleDeleteConfirmation: () => void;
  onChange: (customURLItem: any) => void;
}

interface OwnProps {
  inspectorType: CustomURLsInspectorType;
}

type Props = StateProps & DispatchProps & OwnProps;

const CustomURLsInspector: React.SFC<Props> = ({
  currentCustomURL,
  onChange,
  saveCustomURLItem,
  cancelSaveCustomURLItem,
  inspectorType,
  saveLoader,
  toggleDeleteConfirmation
}) => {
  const title =
      inspectorType === CustomURLsInspectorType.Create
        ? t("customURLs.inspector.addNewItem")
        : t("customURLs.inspector.edit"),
    actionButtonLabel =
      inspectorType === CustomURLsInspectorType.Create
        ? t("customURLs.inspector.save")
        : t("customURLs.inspector.update");
  const { error: saveError } = saveLoader;
  return (
    <Inspector
      title={title}
      onClose={() => cancelSaveCustomURLItem()}
      isActive={!!inspectorType}
      actionButtonLabel={actionButtonLabel}
      onActionButtonClick={() => saveCustomURLItem(currentCustomURL)}>
      {saveLoader.loading && <Spinner message={t("customURLs.loading")} />}
      {currentCustomURL && !saveLoader.loading && (
        <React.Fragment>
          <div>
            <TextField
              value={currentCustomURL["source-path"]}
              label={t("customURLs.inspector.sourcePath")}
              onChange={(sourcePath) => onChange({ ...currentCustomURL, "source-path": sourcePath })}
              hint={t("customURLs.inspector.hints.sourcePath")}
              errorMessage={
                isClientValidationError<AnyCustomURL>(saveError)
                  ? saveError.errors["source-path"] && saveError.errors["source-path"][0]
                  : undefined
              }
            />
          </div>
          <div>
            <TextField
              value={currentCustomURL["destination-path"]}
              label={t("customURLs.inspector.destinationPath")}
              onChange={(destinationPath) => onChange({ ...currentCustomURL, "destination-path": destinationPath })}
              hint={t("customURLs.inspector.hints.destinationPath")}
            />
          </div>
          <div>
            <Select
              label={t("customURLs.inspector.type")}
              value={currentCustomURL.type && [startCase(currentCustomURL.type)]}
              options={Object.keys(CustomURLType)}
              getOptionLabel={(type) => type}
              getOptionValue={(type) => type}
              onChange={(type) => onChange({ ...currentCustomURL, type: type && (type as String).toLowerCase() })}
            />
          </div>
          <div>
            <Select
              label={t("customURLs.inspector.statusCode")}
              value={currentCustomURL["status-code"] ? [String(currentCustomURL["status-code"])] : null}
              options={["301", "302"]}
              onChange={(statusCode) => onChange({ ...currentCustomURL, "status-code": statusCode })}
              getOptionLabel={(statusCode) => statusCode}
              getOptionValue={(statusCode) => statusCode}
            />
          </div>

          {isExistingCustomURL(currentCustomURL) && (
            <React.Fragment>
              <div className={styles["custom-url-delete-action-button"]}>
                <Button
                  testId="custom-url-delete-btn"
                  type="secondary"
                  variant="danger"
                  onClick={() => toggleDeleteConfirmation()}>
                  {t("customURLs.inspector.delete")}
                </Button>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Inspector>
  );
};
const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    currentCustomURL: state.customURLs.app.currentCustomURL,
    saveLoader: state.customURLs.ui.save
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    saveCustomURLItem: (customURLItem: any) => dispatch(saveCurrentCustomURL(customURLItem)),
    cancelSaveCustomURLItem: () => dispatch(cancelSaveCurrentCustomURL()),
    toggleDeleteConfirmation: () => dispatch(toggleDeleteConfirmationAction()),
    onChange: (customURLItem: any) => dispatch(updateCurrentCustomURL(customURLItem))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CustomURLsInspector);

export { CustomURLsInspector };
