/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch, RouteComponentProps } from "react-router";

import { MEDIA_LIBRARY_PATH, MEDIA_LIBRARY_UPLOAD, MEDIA_LIBRARY_STORIES } from "./routes";
import { ARCHIVED_LIBRARY_PATH, ARCHIVED_LIBRARY_UPLOAD, ARCHIVED_LIBRARY_STORIES } from "./archived_routes";

import MediaLibrary from "./media-library";

// Define the props expected by MediaLibrary
interface MediaLibraryProps extends RouteComponentProps {
  showMediaLibraryInspector?: boolean;
}

// Helper function to render the MediaLibrary component
// This function abstracts the rendering logic to avoid duplication
const renderMediaLibrary = (props: MediaLibraryProps, showInspector: boolean, title: string) => (
  <MediaLibrary {...props} showMediaLibraryInspector={showInspector} title={title} />
);

export const Router = () => {
  return (
    <React.Fragment>
      <Switch>
        {/* Route for the main media library */}
        <Route exact path={MEDIA_LIBRARY_PATH} render={(props) => renderMediaLibrary(props, false, "media_library")} />

        {/* Route for uploading media in the media library */}
        <Route exact path={MEDIA_LIBRARY_UPLOAD} render={(props) => renderMediaLibrary(props, true, "media_library")} />

        {/* Route for viewing stories in the media library */}
        <Route
          exact
          path={MEDIA_LIBRARY_STORIES}
          render={(props) => renderMediaLibrary(props, true, "media_library")}
        />

        {/* Route for the main archived media gallery */}
        <Route
          exact
          path={ARCHIVED_LIBRARY_PATH}
          render={(props) => renderMediaLibrary(props, false, "archived_library")}
        />

        {/* Route for uploading media in the archived library */}
        <Route
          exact
          path={ARCHIVED_LIBRARY_UPLOAD}
          render={(props) => renderMediaLibrary(props, true, "archived_library")}
        />

        {/* Route for viewing stories in the archived library */}
        <Route
          exact
          path={ARCHIVED_LIBRARY_STORIES}
          render={(props) => renderMediaLibrary(props, true, "archived_library")}
        />
      </Switch>
    </React.Fragment>
  );
};
