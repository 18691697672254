/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ExtendableError } from "utils/error.utils";
import { PushNotificationValidationError } from "./state";
import { PushNotificationId } from "api/primitive-types";

export class PushNotificationNotFoundError extends ExtendableError {
  id: PushNotificationId;

  constructor(id: PushNotificationId, message: string) {
    super(message);
    this.id = id;
  }
}

export class PushNotificationServerValidationError extends ExtendableError {
  error: PushNotificationValidationError;
  constructor(message: string, body: PushNotificationValidationError) {
    super(message);
    this.error = body;
  }
}

export class PushNotificationDateValidationError extends ExtendableError {
  error: string;
  constructor(message: string) {
    super(message);
    this.error = message;
  }
}
