/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import copy from "copy-to-clipboard";

const copyToClipboard = (stringText, htmlText?, e?, options?) => {
  if (e && e.clipboardData) {
    e.clipboardData.clearData();
    e.clipboardData.setData("text/plain", stringText);
    e.clipboardData.setData("text/html", htmlText);
    e.preventDefault();
    e.stopPropagation();
  } else {
    copy(stringText, options);
  }
};

export default copyToClipboard;
