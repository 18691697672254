/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import reduceReducers from "reduce-reducers";
import storyEditorReducer from "./reducers/story-editor-reducer";
import storyContentReducer from "./reducers/story-content-reducer";
import inspectorReducer from "./reducers/inspector-reducer";
import timelineReducer from "./reducers/timeline-reducer";
import tasksReducer from "./reducers/tasks-reducer";
import { get } from "lodash";

/* The functions(updateStory, updateStoryAndEditorState) are meant to be used when there is any change in story content due to user input. -- Madhuparna*/

export const updateStory = (state, changes = {}, uiOpts) => {
  return {
    ...state,
    story: { ...state.story, ...changes },
    ui: { ...state.ui, isStoryModified: true, lastUpdatedTime: Date.now(), ...uiOpts }
  };
};

export const updateStoryFeatures = (state, feature, changes = {}) => {
  return {
    ...state,
    story: {
      ...state.story,
      "story-features": {
        ...get(state, ["story", "story-features"], {}),
        [feature]: {
          ...get(state, ["story", "story-features", feature], {}),
          ...changes
        }
      }
    },
    ui: { ...state.ui, isStoryModified: true, lastUpdatedTime: Date.now() }
  };
};

export const updateStoryAndEditorState = (state, story, editorState, uiOpts) => {
  return {
    ...state,
    story,
    editorState,
    initialEditorState: editorState,
    ui: { ...state.ui, ...uiOpts, isStoryModified: true, lastUpdatedTime: Date.now() }
  };
};

export default reduceReducers(storyEditorReducer, storyContentReducer, inspectorReducer, timelineReducer, tasksReducer);
