/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { sendGAEvent } from "helpers/ga-analytics/helpers";

interface WithGAEventProps {
  children: React.ReactNode;
  label: string;
}

export default function WithGAEvent({ label, children }: WithGAEventProps) {
  return <span onClick={() => sendGAEvent(label)}>{children}</span>;
}
