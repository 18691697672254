/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { saveCollectionPreview } from "api/collection-config";

export const loadCollectionPreview = async (previewId, collection) => {
  try {
    const selectedCollection = (collection && collection.items) || [];
    const formattedCollection = selectedCollection.map((item) => ({
      id: item.type === "story" ? item.id : Number(item.id),
      type: item.type,
      "associated-metadata": item["associated-metadata"]
    }));

    const collectionConfig: any = Object.keys(collection)
      .filter(
        (key) =>
          !["job-id", "deleted-at", "expanded-rules", "items", "story-content-ids", "story-count", "type"].includes(key)
      )
      .reduce((acc, key) => {
        acc[key] = collection[key];
        return acc;
      }, {});

    collectionConfig.slug = collectionConfig.slug || "new";
    await saveCollectionPreview(previewId, { "manual-items": formattedCollection, config: collectionConfig });
  } catch (error) {
    console.error("Error updating collection config in redis:", error);
  }
};
