/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { Button } from "components/button/button";
import MagicPencil from "components/icons/magic-pencil";
import AnimatedLoader from "components/icons/animated-loader";

type Props = {
  disabled: boolean;
  loading: boolean;
  onClick: () => void;
  classname?: string;
};

export const AiGenerateButton = ({ disabled, loading, onClick, classname }: Props) => {
  return (
    <Button classname={classname} disabled={disabled} type="primary" onClick={onClick}>
      <span>
        {loading ? (
          <AnimatedLoader color="var(--white)" width={"16"} height={"16"} />
        ) : (
          <MagicPencil color="var(--white)" width={"16"} height={"16"} />
        )}
      </span>
    </Button>
  );
};
