/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import _startCase from "lodash/startCase";

function convertSourceToObject(array, value) {
  const obj = array[0];
  const source = obj.name === "default" ? "all" : obj.name;
  return { source, categories: value };
}

function convertSourceToArray(object) {
  const { source } = object;
  const newSource = source === "all" ? "default" : source;
  return [{ name: newSource, "display-name": _startCase(newSource) }];
}

function convertAttributesToArray(object) {
  const keys = Object.keys(object);
  return keys.map((key) => {
    return { name: key, values: object[key], "display-name": _startCase(key) };
  });
}

function convertAttributesToObject(array) {
  return array.reduce((acc, item) => {
    return { ...acc, [item.name]: item.values };
  }, {});
}

export function modifySavedFilter(savedFilter) {
  let filter = savedFilter.filter;
  const attributesFilter = filter && (filter.attributes || []);
  const sourceFilter = filter && (filter.sources || filter.source || {});
  const storyTemplateFilter = filter && (filter["story-template"] || filter["story-templates"]);

  const { categories, sources, ...allFilters } = filter;

  const newSourceFilter =
    sourceFilter && sourceFilter.constructor === Object
      ? sourceFilter
      : convertSourceToObject(sourceFilter, categories);

  const newAttributesFilter =
    attributesFilter && attributesFilter.constructor === Array
      ? attributesFilter
      : convertAttributesToArray(attributesFilter);

  const newStoryTemplates = storyTemplateFilter
    ? storyTemplateFilter.map((temp) => {
        return temp.constructor === Object ? temp : { name: _startCase(temp), slug: temp };
      })
    : [];

  return {
    ...savedFilter,
    filter: {
      ...allFilters,
      attributes: newAttributesFilter,
      source: newSourceFilter,
      "story-templates": newStoryTemplates
    }
  };
}

export function modifyForApi(apiFilter) {
  const filter = apiFilter.filter;
  const storyTemplates = filter && filter["story-templates"];
  const { source, ...allFilters } = filter;
  const attributesFilter = filter.attributes;
  const sourceFilter = filter.source;

  const newSourceFilter = convertSourceToArray(sourceFilter);
  const newAttributesFilter = convertAttributesToObject(attributesFilter);
  const newStoryTemplate = storyTemplates.length === 0 ? [] : storyTemplates.map((temp) => temp.slug);

  return {
    ...apiFilter,
    filter: {
      ...allFilters,
      attributes: newAttributesFilter,
      sources: newSourceFilter,
      categories: sourceFilter.categories,
      "story-template": newStoryTemplate
    }
  };
}
