/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import TextField from "components/text-field/text-field";
import { getErrorMessage } from "pages/story-editor/utils";

import { t } from "i18n";

const CanonicalUrl = ({ value, onChange, errors, disabled = false }) => (
  <TextField
    label={t("story-editor.inspector.canonical-url")}
    value={value}
    onChange={onChange}
    errorMessage={getErrorMessage(errors)}
    disabled={disabled}
  />
);

export default CanonicalUrl;
