/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { Section } from "./route-data/route-data";

export interface PushNotificationChannel {
  id: number;
  name: string;
  "resource-id": number | null;
  "resource-type": string;
}
export interface AnyChannel {
  type?: string | null;
  name?: string | null;
  section?: Section | null;
}

export const getChannels = (): Promise<PushNotificationChannel[]> => {
  return client
    .url("/api/v1/push_channels")
    .get()
    .json();
};

export const deleteChannel = (channel: PushNotificationChannel): Promise<null> => {
  return client
    .url(`/api/v1/push_channels/${channel.id}`)
    .delete()
    .res();
};

export const saveChannel = (channel: AnyChannel): Promise<PushNotificationChannel> => {
  const data = {
    name: channel.name,
    "resource-type": channel.type,
    "resource-id": channel.section && channel.section.id
  };
  return client
    .url("/api/v1/push_channels")
    .json(data)
    .post()
    .json();
};
