/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const EmptyStar = () => (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24}>
    <path d="M20.87,9.39a1,1,0,0,1,.69.4,1.16,1.16,0,0,1,.24.75,1,1,0,0,1-.32.71l-3.6,3.5.85,4.95a1.05,1.05,0,0,1-.15.77,1,1,0,0,1-.63.45,1,1,0,0,1-.78-.1l-4.44-2.3-4.45,2.3a1,1,0,0,1-.78.1,1,1,0,0,1-.63-.45,1.05,1.05,0,0,1-.15-.77l.85-4.95L4,11.25a1,1,0,0,1-.32-.71,1.16,1.16,0,0,1,.24-.75,1,1,0,0,1,.69-.4l5-.72,2.2-4.48a1,1,0,0,1,.59-.54,1.19,1.19,0,0,1,.79,0,1,1,0,0,1,.59.54l2.2,4.48Zm-4.72,4.78,3.4-3.32-4.72-.68-2.1-4.28-2.11,4.28-4.72.68,3.4,3.32-.78,4.72,4.21-2.24,4.2,2.24Z" />
  </svg>
);

export default EmptyStar;
