/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import Actions, {
  LOAD_CUSTOM_FIELDS_ACTION,
  LOAD_CUSTOM_FIELDS_SUCCESS_ACTION,
  LOAD_CUSTOM_FIELDS_FAILURE_ACTION,
  SAVE_CUSTOM_FIELD_ACTION,
  SAVE_CUSTOM_FIELD_SUCCESS_ACTION,
  SAVE_CUSTOM_FIELD_FAILURE_ACTION,
  DELETE_CUSTOM_FIELD_ACTION,
  DELETE_CUSTOM_FIELD_SUCCESS_ACTION,
  DELETE_CUSTOM_FIELD_FAILURE_ACTION,
  INIT_NEW_CUSTOM_FIELD_ACTION,
  EDIT_CUSTOM_FIELD_ACTION,
  EDIT_CUSTOM_FIELD_FAILURE_ACTION,
  CANCEL_CREATE_OR_EDIT_CUSTOM_FIELD_ACTION,
  EDIT_CUSTOM_FIELD_SUCCESS_ACTION
} from "./actions";
import { CustomField } from "api/custom-field";

export const loadCustomFieldsAction = (): LOAD_CUSTOM_FIELDS_ACTION => ({ type: Actions.LOAD_CUSTOM_FIELDS });

export const loadCustomFieldsSuccessAction = (customFields: Array<CustomField>): LOAD_CUSTOM_FIELDS_SUCCESS_ACTION => ({
  type: Actions.LOAD_CUSTOM_FIELDS_SUCCESS,
  payload: { customFields }
});

export const loadCustomFieldsFailureAction = (e: Error): LOAD_CUSTOM_FIELDS_FAILURE_ACTION => ({
  type: Actions.LOAD_CUSTOM_FIELDS_FAILURE,
  payload: { error: e }
});

export const saveCustomFieldAction = (): SAVE_CUSTOM_FIELD_ACTION => ({ type: Actions.SAVE_CUSTOM_FIELD });

export const saveCustomFieldSuccessAction = (): SAVE_CUSTOM_FIELD_SUCCESS_ACTION => ({
  type: Actions.SAVE_CUSTOM_FIELD_SUCCESS
});

export const saveCustomFieldFailureAction = (e: Error): SAVE_CUSTOM_FIELD_FAILURE_ACTION => ({
  type: Actions.SAVE_CUSTOM_FIELD_FAILURE,
  payload: { error: e }
});

export const deleteCustomFieldAction = (customField: CustomField): DELETE_CUSTOM_FIELD_ACTION => ({
  type: Actions.DELETE_CUSTOM_FIELD,
  payload: { customField }
});

export const deleteCustomFieldSuccessAction = (): DELETE_CUSTOM_FIELD_SUCCESS_ACTION => ({
  type: Actions.DELETE_CUSTOM_FIELD_SUCCESS
});

export const deleteCustomFieldFailureAction = (e: Error): DELETE_CUSTOM_FIELD_FAILURE_ACTION => ({
  type: Actions.DELETE_CUSTOM_FIELD_FAILURE,
  payload: { error: e }
});

export function initNewCustomFieldAction(): INIT_NEW_CUSTOM_FIELD_ACTION {
  return { type: Actions.INIT_NEW_CUSTOM_FIELD };
}

export function editCustomFieldAction(customField: CustomField): EDIT_CUSTOM_FIELD_ACTION {
  return { type: Actions.EDIT_CUSTOM_FIELD, payload: { customField } };
}

export function editCustomFieldSuccessAction(): EDIT_CUSTOM_FIELD_SUCCESS_ACTION {
  return { type: Actions.EDIT_CUSTOM_FIELD_SUCCESS };
}

export function editCustomFieldFailureAction(error: Error): EDIT_CUSTOM_FIELD_FAILURE_ACTION {
  return { type: Actions.EDIT_CUSTOM_FIELD_FAILURE, payload: { error } };
}

export function cancelCreateOrEditCustomFieldAction(): CANCEL_CREATE_OR_EDIT_CUSTOM_FIELD_ACTION {
  return { type: Actions.CANCEL_CREATE_OR_EDIT_CUSTOM_FIELD };
}
