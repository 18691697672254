/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import Summary from "pages/story-editor/components/manage-fields/summary";
import AuthorsWrapper from "pages/story-editor/components/manage-fields/authors-wrapper";
import Sections from "pages/story-editor/components/manage-fields/sections";
import Tags from "pages/story-editor/components/manage-fields/tags";
import CustomSlug from "pages/story-editor/components/manage-fields/custom-slug";
import CanonicalUrl from "pages/story-editor/components/manage-fields/canonical-url";
import SponsoredContent from "pages/story-editor/components/manage-fields/sponsored-content";
import SlugPreview from "./manage-fields/slug-preview";

export const MetadataFields = [
  { specPath: "sections", storyPath: "sections", component: Sections, categoryName: "general" },
  { specPath: "tags", storyPath: "tags", component: Tags, categoryName: "general" },
  { specPath: "author", storyPath: "authors", component: AuthorsWrapper, categoryName: "general" },
  { specPath: "custom-url", storyPath: "custom-slug", component: CustomSlug, categoryName: "general" },
  {
    specPath: "slug-preview",
    storyPath: "slug-preview",
    component: SlugPreview,
    categoryName: "general"
  },
  { specPath: "canonical-url", storyPath: "canonical-url", component: CanonicalUrl, categoryName: "general" },
  { specPath: "summary", storyPath: "summary", component: Summary }, // this will go inside Social tab Social share category in advanced inspector. This is there reason there is categoryName defined here
  {
    specPath: "sponsored-by",
    storyPath: "metadata['sponsored-by']",
    component: SponsoredContent,
    categoryName: "story-feature"
  }
];
