/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

/*
Contains various functions to do the same thing - convert a string to a slug.
You opened this file, hence it's now your responsibility to unify them.
*/

export function slugify(str: string) {
  return str
    .toLowerCase()
    .replace(/_|([^\w -]*)/g, "")
    .trim()
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");
}

export const slugifyString = (string: string | null) =>
  string &&
  string
    .replace(/[\W_]/g, " ")
    .replace(/\s+/g, " ")
    .trim()
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-")
    .toLowerCase();
