/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const User = ({ width = 16, height = 16 }) => (
  <svg
    data-test-id="user"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    fill="#5f6978"
    height={height}
    viewBox="0 0 24 24">
    <path fill="transparent" d="M.03.13h24v24h-24z" />
    <path d="M18.18 14.27A3.69 3.69 0 0 1 20.43 16a3.91 3.91 0 0 1 .57 2v1a2.15 2.15 0 0 1-.67 1.58 2.17 2.17 0 0 1-1.58.67H5.28a2.17 2.17 0 0 1-1.58-.65A2.15 2.15 0 0 1 3 19v-1a4.13 4.13 0 0 1 .6-2.11 3.74 3.74 0 0 1 2.25-1.65l1.72-.49a6.2 6.2 0 0 1-1.48-2.5 6.45 6.45 0 0 1-.28-1.83A6.15 6.15 0 0 1 12 3.27a6.16 6.16 0 0 1 6.19 6.18 6.45 6.45 0 0 1-.28 1.83 6.09 6.09 0 0 1-1.48 2.5zM19.91 19v-1a2.72 2.72 0 0 0-.57-1.67 2.76 2.76 0 0 0-1.47-1l-2.46-.71a6.09 6.09 0 0 1-6.75 0l-2.46.71a2.79 2.79 0 0 0-1.48 1A2.71 2.71 0 0 0 4.16 18v1a1.13 1.13 0 0 0 1.12 1.12h13.5a1.07 1.07 0 0 0 .79-.33 1.1 1.1 0 0 0 .34-.79zM12 4.39a4.86 4.86 0 0 0-2.53.69 5.18 5.18 0 0 0-1.82 1.84 5.07 5.07 0 0 0 0 5.07 5.26 5.26 0 0 0 1.85 1.84 5 5 0 0 0 5.06 0A5.18 5.18 0 0 0 16.41 12a4.89 4.89 0 0 0 .68-2.54 4.88 4.88 0 0 0-.68-2.53 5.1 5.1 0 0 0-1.85-1.84 4.83 4.83 0 0 0-2.56-.7z" />
  </svg>
);

export default User;
