/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { validate } from "utils/validation.utils";
import { t } from "i18n";

import Inspector from "components/inspector/inspector";
import TextField from "components/text-field/text-field";
import { AccessLevelErrors } from "./shared-types";

interface Props {
  onClose: () => void;
  isActive: boolean;
  onActionButtonClick: (value: string) => void;
  updateAccessLevelValue: (value: string) => void;
  accessLevelValue: string;
  setErrors: (value: AccessLevelErrors) => void;
  errors: AccessLevelErrors;
}

const AddAccessLevelInspector: React.SFC<Props> = ({
  onClose,
  isActive,
  onActionButtonClick,
  updateAccessLevelValue,
  accessLevelValue,
  setErrors,
  errors
}) => {
  const validateAccessLevelValue = () => {
    const errors = validate(
      { accessLevelValue },
      { accessLevelValue: { presence: { allowEmpty: false }, numericality: { greaterThan: 0, onlyInteger: true } } }
    );
    if (errors) {
      setErrors(errors);
    } else {
      onActionButtonClick(accessLevelValue);
    }
  };
  const errorMessage = errors.accessLevelValue && errors.accessLevelValue[0];
  return (
    <Inspector
      title={t("settings.access_levels.add_access_level_button")}
      onClose={() => onClose()}
      isActive={isActive}
      actionButtonLabel={t("save")}
      onActionButtonClick={validateAccessLevelValue}>
      <TextField
        label={t("settings.access_levels.access_level")}
        placeholder={t("settings.access_levels.placeholder_access_level")}
        onChange={updateAccessLevelValue}
        value={accessLevelValue}
        errorMessage={errorMessage}
      />
    </Inspector>
  );
};

export default AddAccessLevelInspector;
