/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "pages/settings/initial-state";
import Button from "components/button/button";
import Header from "components/header/header";
import { updateStorySlugSettings } from "../async-action-creators";

interface StateProps {
  isSaveButtonEnabled: boolean;
  canAccessStorySlug: boolean;
}

interface DispatchProps {
  saveSettings: () => void;
}

type StorySlugHeaderProps = StateProps & DispatchProps;

export const StorySlugHeader: React.FC<StorySlugHeaderProps> = ({
  saveSettings,
  isSaveButtonEnabled,
  canAccessStorySlug
}) => {
  return (
    <Header>
      {canAccessStorySlug && (
        <Button variant="success" type="primary" disabled={!isSaveButtonEnabled} onClick={saveSettings}>
          {t("save")}
        </Button>
      )}
    </Header>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => ({
  isSaveButtonEnabled: state.settingsPage.ui.storySlug.isSaveButtonEnabled,
  canAccessStorySlug: !!state.features.canAccessStorySlug
});

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => ({
  saveSettings: () => dispatch(updateStorySlugSettings())
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(StorySlugHeader);
