/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Manage = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="24"
    height="24"
    viewBox="0 0 24 24">
    <defs>
      <path
        id="a-manage"
        d="M19.88 21.81h-1.31l-3.53-3.53a5.668 5.668 0 0 1-6.03-1.268 5.604 5.604 0 0 1-1.65-3.987c0-.802.175-1.612.506-2.343l.355-.784 3.843 3.843 1.664-1.663-3.842-3.844.782-.354a5.674 5.674 0 0 1 6.33 1.143 5.601 5.601 0 0 1 1.651 3.987c0 .697-.132 1.397-.382 2.046L22 18.788V20.1l-4.836-4.837.133-.292c.278-.609.424-1.288.425-1.962 0-1.259-.49-2.44-1.379-3.33a4.75 4.75 0 0 0-4.835-1.134l3.531 3.533-2.975 2.974-3.531-3.532a4.777 4.777 0 0 0-.246 1.505c0 1.258.49 2.442 1.378 3.33a4.74 4.74 0 0 0 5.292.955l.293-.132 4.63 4.632zm-3.783-1.123l1.123 1.123H4.663C3.194 21.81 2 20.626 2 19.17V4.637C2 3.183 3.194 2 4.663 2h14.673C20.805 2 22 3.183 22 4.637V17.41l-1.133-1.134V4.637c0-.834-.687-1.514-1.531-1.514H4.663c-.843 0-1.53.68-1.53 1.514v14.534c0 .836.687 1.516 1.53 1.516h11.434z"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <mask id="b-manage" fill="currentColor">
        <use xlinkHref="#a-manage" />
      </mask>
      <use fill="currentColor" xlinkHref="#a-manage" />
    </g>
  </svg>
);

export default Manage;
