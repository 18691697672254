/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { Team } from "./users";

export const getTeams = (): Promise<Team[]> => {
  return client
    .url(`/api/teams`)
    .get()
    .json();
};
