/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { validate } from "utils/validation.utils";

import Inspector from "components/inspector/inspector";
import TemplateFields from "./template-fields/template-fields";
import { TextFieldWithCount } from "components/text-field/text-field";
import { isEmptyObject } from "utils/object.utils";
import { Template } from "api/content-templates";
import { TemplateFieldErrors } from "./shared-types";
import { constraints } from "./validate";

interface Props {
  isActive: boolean;
  onClose: () => void;
  updateTemplate: (value: Template) => void;
  selectedStoryTemplate: Template;
  onFieldChange: (key: string, value: Object) => void;
  setErrors: (errors: TemplateFieldErrors) => void;
  errors: TemplateFieldErrors;
}

const EditTemplateInspector: React.SFC<Props> = ({
  onClose,
  isActive,
  updateTemplate,
  selectedStoryTemplate,
  onFieldChange,
  setErrors,
  errors
}) => {
  if (isEmptyObject(selectedStoryTemplate)) {
    return null;
  }
  const validateTemplateData = () => {
    let templateErrors = validate(selectedStoryTemplate, constraints(t, selectedStoryTemplate.config));
    if (templateErrors) {
      setErrors(templateErrors);
    } else {
      updateTemplate(selectedStoryTemplate);
    }
  };
  return (
    <Inspector
      title={t("settings.storyTemplates.edit_inspector_title")}
      onClose={onClose}
      isActive={isActive}
      actionButtonLabel={t("update")}
      onActionButtonClick={() => validateTemplateData()}>
      <TextFieldWithCount
        label={t("settings.storyTemplates.template_name")}
        value={selectedStoryTemplate.name}
        disabled={true}
        maxValue={60}
        onChange={() => null}
      />
      <TemplateFields
        fields={selectedStoryTemplate.config.fields}
        onFieldChange={onFieldChange}
        setErrors={setErrors}
        errors={errors}
      />
    </Inspector>
  );
};

export default EditTemplateInspector;
