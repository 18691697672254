/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";

import TextArea from "components/text-area/text-area";
import Select from "components/select/select";
import { getErrorMessage } from "pages/story-editor/utils";
import { t } from "i18n";
import styles from "./review-header-fields.module.css";

const RATINGS = [
  {
    label: "0.5",
    value: "0.5"
  },
  {
    label: "1",
    value: "1"
  },
  {
    label: "1.5",
    value: "1.5"
  },
  {
    label: "2",
    value: "2"
  },
  {
    label: "2.5",
    value: "2.5"
  },
  {
    label: "3",
    value: "3"
  },
  {
    label: "3.5",
    value: "3.5"
  },
  {
    label: "4",
    value: "4"
  },
  {
    label: "4.5",
    value: "4.5"
  },
  {
    label: "5",
    value: "5"
  }
];

const ReviewFields = ({ reviewData, updateMetadata, errors }) => {
  return (
    <div className={styles["story-review-container"]}>
      <div className={styles["story-review-title"]}>
        <TextArea
          placeholder={t("story-editor.header-card.review-title")}
          value={(reviewData && reviewData["review-title"]) || ""}
          onChange={(value) => updateMetadata("review-title", value)}
          errorMessage={getErrorMessage(errors && errors["review-title"])}
        />
      </div>
      <Select
        label={t("story-editor.header-card.rating")}
        value={reviewData && reviewData["review-rating"]}
        options={RATINGS}
        onChange={(value) => updateMetadata("review-rating", value)}
        getOptionLabel={(rating) => rating.label}
        getOptionValue={(rating) => rating.value}
        errorMessage={getErrorMessage(errors && errors["review-rating"])}
      />
    </div>
  );
};

export default ReviewFields;
