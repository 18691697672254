/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { includes, last } from "lodash";

const MB = 1024 * 1024;
const ALLOWED_FILE_TYPES = ["pdf", "doc", "docx", "mp3"];
const ALLOWED_VIDEO_UPLOAD_TYPES = ["mp4"];
const MAX_ALLOWED_TABLE_SIZE = 1024 * 1024 * 25; //25 MB
export const CSV_CONTENT_TYPE = "csv";

export const getFileType = (fileName: String) => {
  return last(fileName.split(".")); //file.type wont work in windows so not using
};

export function validationFn(file: File, element: "table"): boolean;
export function validationFn(file: File, element: "attachment", maxAllowedAttachmentSize: number): boolean;
export function validationFn(file: File, element: "video", maxAllowedAttachmentSize: number): boolean;
export function validationFn(file: File, element: string, maxAllowedAttachmentSize = 0): boolean {
  const fileType = getFileType(file.name);

  switch (element) {
    case "attachment": {
      return file.size <= maxAllowedAttachmentSize * MB && includes(ALLOWED_FILE_TYPES, fileType);
    }
    case "table": {
      return file.size <= MAX_ALLOWED_TABLE_SIZE && fileType === CSV_CONTENT_TYPE;
    }
    case "video": {
      return file.size <= maxAllowedAttachmentSize * MB && includes(ALLOWED_VIDEO_UPLOAD_TYPES, fileType);
    }
  }
  return false;
}

export function isUrl(s: string): boolean {
  var regexp = /^(f|ht)tps?:\/\//;
  return regexp.test(s);
}

export function isId(s: string): boolean {
  const hasSpecialCharacters = s.match(/[^(\w|\-)]/);
  return hasSpecialCharacters ? false : true;
}
