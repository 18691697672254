/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface H2IconProps {
  width?: string;
  height?: string;
  color?: string;
}

const H2: React.SFC<H2IconProps> = ({ width = "24", height = "24", color = "currentColor" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 24 24">
    <path d="M16.83,16.3l2.68-2.53a6.93,6.93,0,0,0,1.55-1.9,4,4,0,0,0,.4-1.79,3.1,3.1,0,0,0-.54-1.82,3.46,3.46,0,0,0-1.52-1.2,5.71,5.71,0,0,0-2.27-.43,6.32,6.32,0,0,0-2.75.58A4.62,4.62,0,0,0,12.49,8.8l2,1.27a2.72,2.72,0,0,1,1-.88,3.26,3.26,0,0,1,1.39-.29,2.21,2.21,0,0,1,1.39.38,1.29,1.29,0,0,1,.47,1.07,2.13,2.13,0,0,1-.25,1,4.78,4.78,0,0,1-1,1.17L13,16.75V18.5h8.81V16.3Z" />
    <path d="M7.61,9.4A4.37,4.37,0,0,0,6,9.68a3.3,3.3,0,0,0-1.25.79V6.13H2.16V18.5h2.6V14.07a2.52,2.52,0,0,1,.58-1.81,2.06,2.06,0,0,1,1.57-.61,1.71,1.71,0,0,1,1.35.53,2.34,2.34,0,0,1,.46,1.59V18.5h2.6V13.37a4,4,0,0,0-1-3A3.67,3.67,0,0,0,7.61,9.4Z" />
  </svg>
);

export default H2;
