/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import produce from "immer";
import actions, { ActionTypes } from "./actions";
import { INITIAL_LOGIN_STATE, State } from "./state";

export default function loginReducer(state: State = INITIAL_LOGIN_STATE, action: ActionTypes) {
  return produce(state, (draft) => {
    switch (action.type) {
      case actions.UPDATE_LOGIN_DATA:
        draft.app[action.payload.key] = action.payload.value;
        if (draft.app.username.length === 0) {
          draft.ui.isFormLogin = false;
        } else {
          draft.ui.isFormLogin = true;
          draft.ui.isSignInDisabled = false;
        }
        break;

      case actions.GET_LOGIN_DATA:
        draft.config = action.payload.data.config;
        draft.features.isBridgekeeperIntegrationEnabled = action.payload.data.features.isBridgekeeperIntegrationEnabled;
        break;

      case actions.SET_EMAIL_TO_RESET_PASSWORD:
        draft.app.emailToResetPassword = action.payload.email;
        break;

      case actions.UPDATE_RESET_PASSWORD_FIELDS:
        draft.app[action.payload.key] = action.payload.value;
        break;

      case actions.SET_VALIDATION_ERROR:
        draft.ui.error = action.payload.error;
        break;

      case actions.CLEAR_VALIDATION_ERROR:
        draft.ui.error = null;
        break;

      case actions.CANCEL_FORM_SIGN_IN:
        draft.app.username = "";
        draft.app.password = "";
        draft.ui.isFormLogin = false;
        draft.ui.isSignInDisabled = false;
        break;

      case actions.INITIALIZE_SIGN_IN:
        draft.ui.isSignInDisabled = true;
        break;

      case actions.SIGN_IN_SUCCESS:
        draft.ui.isSignInDisabled = false;
        break;

      case actions.ON_ROUTE_CHANGED:
        draft.app.username = "";
        draft.app.password = "";
        draft.app.emailToResetPassword = "";
        break;

      case actions.AUTH_TOKEN_SUCCESS:
        draft.twoFactorAuth.token = action.payload.token;
        draft.ui.loader = { loading: false, error: null };
        break;
      case actions.AUTH_TOKEN_FAILURE:
        draft.ui.loader = { loading: false, error: action.payload.error };
        break;
      case actions.SET_OTP:
        draft.twoFactorAuth.otp = action.payload.otp;
        break;
      case actions.LOAD_TWO_FACTOR_AUTH:
        draft.ui.loader = { loading: true, error: null };
        break;
      case actions.SET_TOTP:
        draft.twoFactorAuth.totp = action.payload.totp;
        break;
      default:
        break;
    }
  });
}
