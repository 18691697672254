/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import * as actions from "../../actions";
import ActionBar from "components/action-bar/action-bar";
import Menu from "components/menu/menu";
import get from "lodash/get";
import { getMediaCategories, EXTERNAL_MEDIA_PROVIDERS, MediaCategory } from "pages/media-library/media-list";
import { t } from "i18n";
import { ThunkDispatch } from "redux-thunk";
import {
  PartialAppState,
  Providers,
  CurrentProvider,
  ExistingOpts,
  AdvancedSearchOpts,
  MatchProps
} from "pages/media-library/state";
import { loadMediaDataAction } from "pages/media-library/action-creators";
import isEmpty from "lodash/isEmpty";

export interface MediaActionBarProps {
  switchProvider: (provider: CurrentProvider, opts: ExistingOpts | AdvancedSearchOpts) => void;
  currentMediaProvider: CurrentProvider;
  providers: Providers;
  isBannerPresent: boolean;
  isDesktopSizeViewport: boolean;
  enableGettyImagesInSidebar: boolean;
  mediaSources: string[];
  searchTerm: string;
  advancedSearchOpts: ExistingOpts | AdvancedSearchOpts;
  match: MatchProps;
}

const getMediaProviderCount = (providers: Providers, mediaCategory: MediaCategory) => {
  if (mediaCategory.value === "getty") {
    return get(providers, ["getty", "page", "total"], 0);
  } else if (mediaCategory.source) {
    return get(providers, ["media-library", "aggregations", "source", mediaCategory.source], 0);
  } else {
    return get(providers, ["media-library", "aggregations", "total-count"], 0);
  }
};

const isMenuSelected = (mediaCategory: MediaCategory, currentMediaProvider: MediaCategory) => {
  return currentMediaProvider.source
    ? mediaCategory.source === currentMediaProvider.source
    : isEmpty(mediaCategory.source) && mediaCategory.value === currentMediaProvider.value;
};

const MediaActionBar = ({
  switchProvider,
  currentMediaProvider,
  providers,
  isBannerPresent,
  isDesktopSizeViewport,
  enableGettyImagesInSidebar,
  mediaSources,
  searchTerm,
  advancedSearchOpts,
  match
}: MediaActionBarProps) => {
  const mediaCategories = getMediaCategories(mediaSources);
  /**
   * Handles changes to the selected provider and triggers the search with updated options.
   * It checks whether the current URL includes "/archived-library" to determine if the library is archived.
   * The function then calls `switchProvider` with the current provider and updated search options, including
   * the archived status.
   *
   * @param provider - The selected provider to be used in the search.
   */
  const onProviderChange = (provider: CurrentProvider) => {
    // Determine if the current URL indicates an archived library.
    const isArchivedLibrary = (match.url && match.url.includes("/archived-library")) || false;
    switchProvider(provider, {
      ...advancedSearchOpts,
      searchTerm,
      archived: isArchivedLibrary
    });
  };
  return (
    <ActionBar
      isBannerPresent={isBannerPresent}
      isDesktopSizeViewport={isDesktopSizeViewport}
      renderContents={(onItemClickCallback) => (
        <React.Fragment>
          <Menu
            title={t("mediaLibrary.sources")}
            items={mediaCategories}
            onItemClick={(item) => {
              onProviderChange(item);
              onItemClickCallback();
            }}
            getItemLabel={(item) => item.label}
            isItemSelected={(item) => isMenuSelected(item, currentMediaProvider)}
            getBadgeValue={(item) => getMediaProviderCount(providers, item)}
          />
          {enableGettyImagesInSidebar && (
            <Menu
              title={t("mediaLibrary.others")}
              items={EXTERNAL_MEDIA_PROVIDERS(t)}
              onItemClick={(item) => {
                onProviderChange(item);
                onItemClickCallback();
              }}
              getItemLabel={(item) => item.label}
              isItemSelected={(item) => isMenuSelected(item, currentMediaProvider)}
              getBadgeValue={(item) => getMediaProviderCount(providers, item)}
            />
          )}
        </React.Fragment>
      )}
    />
  );
};

const mapStateToProps = (state: PartialAppState) => {
  return {
    currentMediaProvider: state.mediaLibrary.currentMediaProvider,
    providers: state.mediaLibrary.providers,
    enableGettyImagesInSidebar: state.features.enableGettyImagesInSidebar,
    mediaSources: state.config["media-sources"],
    advancedSearchOpts: state.mediaLibrary.advancedSearchOpts,
    searchTerm: state.mediaLibrary.searchTerm
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => {
  return {
    switchProvider: (provider: CurrentProvider, opts) => {
      dispatch({ type: actions.MEDIA_LIBRARY_SET_PROVIDER, payload: { provider } });
      dispatch(loadMediaDataAction({ source: provider.source, ...opts }));
    }
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(MediaActionBar);

export { MediaActionBar };
