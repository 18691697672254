/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { t } from "i18n";

import FieldLabel from "components/field-label/field-label";
import { Panel } from "components/tabs/tabs";
import Checkbox from "components/checkbox/checkbox";
import TextField from "components/text-field/text-field";
import { EventDetails } from "store/webhook/webhook";
import styles from "./inspector-inputs.module.css";

interface UrlEntryProps {
  url: string;
  handleUrlChange(url: string): void;
  error: string[];
}

export function UrlEntry({ url, handleUrlChange, error }: UrlEntryProps) {
  return (
    <>
      <FieldLabel label={t("settings.webhooks.inspector.url_field_title")} />
      <TextField labelId="webhook-url-field" value={url} onChange={handleUrlChange} errorMessage={error[0]} />
    </>
  );
}

interface SecretEntryProps {
  secret: string | null;
  handleSecretChange(secret: string): void;
}

export function SecretEntry({ secret, handleSecretChange }: SecretEntryProps) {
  return (
    <>
      <FieldLabel label={t("settings.webhooks.inspector.secret_field_title")} />
      <TextField labelId="webhook-url-field" value={secret || ""} onChange={handleSecretChange} />
    </>
  );
}

interface TriggerEventsProps {
  publisherTriggerEvents: EventDetails[];
  selectedTriggerEventIds: number[];
  handleEventToggle(eventId: number, checked: boolean): void;
}

export function TriggerEvents({
  publisherTriggerEvents,
  selectedTriggerEventIds,
  handleEventToggle
}: TriggerEventsProps) {
  return (
    <>
      <FieldLabel label={t("settings.webhooks.inspector.events.title")} />
      <Panel>
        <div className={styles["trigger-events-container"]} data-test-id="trigger-events-container">
          {publisherTriggerEvents.map(({ id, slug }) => (
            <Checkbox
              label={t(`settings.webhooks.inspector.events.${slug}`)}
              id={id.toString()}
              key={id}
              checked={selectedTriggerEventIds && selectedTriggerEventIds.includes(id)}
              onChange={(checked) => handleEventToggle(id, checked)}
            />
          ))}
        </div>
      </Panel>
    </>
  );
}
