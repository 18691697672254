/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

import * as actions from "../../actions";
import ActionBar from "components/action-bar/action-bar";
import Menu from "components/menu/menu";
import { compose } from "redux";
import { connect } from "react-redux";
import { t } from "i18n";
import { fetchWorkspaceData, togglePrintSourceFilters } from "../../action-creators";
import { PartialAppState, FilterSet, Source } from "api/workspace";
import { ThunkDispatch } from "redux-thunk";
import { match } from "react-router";
import { get } from "lodash";

interface WorkspaceActionBarProps {
  sources: Source[];
  currentFilterSet: FilterSet;
  setStoriesOnFilterChange: (key: string, value: any) => void;
  isBannerPresent: boolean;
  isDesktopSizeViewport: boolean;
  resetStories: () => void;
  match: match<{ tabSlug: string }>;
  togglePrintSourceFilters: (enabled: boolean) => void;
  isPrintSelected: boolean;
  isStoryPrintEnabled: boolean;
  isCurrentWindowIsInSlider: boolean;
}

const generateSourceItemsForSidebar = (sources: Source[], selectedSource: Source) =>
  sources.length > 0
    ? sources.map(({ source }) => {
        return {
          source: source,
          isSelected: selectedSource.source === source
        };
      })
    : [];

const generatePrintItemsForSidebar = (isPrintSelected) => [
  {
    source: "print",
    isSelected: isPrintSelected
  }
];

const WorkspaceActionBar = ({
  sources,
  currentFilterSet,
  setStoriesOnFilterChange,
  isBannerPresent,
  isDesktopSizeViewport,
  resetStories,
  togglePrintSourceFilters,
  isPrintSelected,
  isStoryPrintEnabled,
  isCurrentWindowIsInSlider
}: WorkspaceActionBarProps) => {
  const handleItemClick = (item, onItemClickCallback) => {
    isStoryPrintEnabled && togglePrintSourceFilters(item.source === "print");
    if (item.source === "print") {
      item.source = "all";
    }
    resetStories();
    setStoriesOnFilterChange("source", { source: item.source, categories: [] });
    onItemClickCallback();
  };

  return (
    <React.Fragment>
      {sources.length ? (
        <ActionBar
          isBannerPresent={isBannerPresent}
          isDesktopSizeViewport={isDesktopSizeViewport}
          openOnLoad={!isCurrentWindowIsInSlider}
          renderContents={(onItemClickCallback) => (
            <>
              <Menu<{ source: string | undefined; isSelected: boolean }>
                title={t("workspace.sidebar.source_plural")}
                items={generateSourceItemsForSidebar(sources, currentFilterSet.source)}
                onItemClick={(item) => handleItemClick(item, onItemClickCallback)}
                getItemLabel={(item) => t(`workspace.filter.sources.${item.source}`, item.source) || ""}
                isItemSelected={(item) => !isPrintSelected && item.isSelected}
              />

              {isStoryPrintEnabled && (
                <Menu<{ source: string | undefined; isSelected: boolean }>
                  title={t("mediaLibrary.others")}
                  items={generatePrintItemsForSidebar(isPrintSelected)}
                  onItemClick={(item) => handleItemClick(item, onItemClickCallback)}
                  getItemLabel={(item) => t(`workspace.filter.sources.${item.source}`, item.source) || ""}
                  isItemSelected={(item) => item.isSelected}
                />
              )}
            </>
          )}
        />
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state: PartialAppState, ownProps: WorkspaceActionBarProps) => {
  return {
    isPrintSelected: get(state, ["workspace", "isPrintSelected"], false),
    sources: state.config.sources,
    currentFilterSet: get(state, ["workspace", "currentFilterSet"]),
    isStoryPrintEnabled: get(state, ["features", "isStoryPrintEnabled"], false),
    isCurrentWindowIsInSlider: get(state, ["slider", "currentWindow", "isInSlider"], false)
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>, ownProps: WorkspaceActionBarProps) => {
  return {
    resetStories: () => dispatch({ type: actions.WORKSPACE_RESET_STORIES }),
    togglePrintSourceFilters: (enabled: boolean) => {
      dispatch(togglePrintSourceFilters(enabled));
    },
    setStoriesOnFilterChange: (key: string, value: any) => {
      dispatch({ type: actions.WORKSPACE_UPDATE_FILTER, payload: { key, value } });
      dispatch({ type: actions.WORKSPACE_RESET_STORIES });
      dispatch(fetchWorkspaceData(ownProps.match.params.tabSlug, null));
    }
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(WorkspaceActionBar);

export { WorkspaceActionBar };
