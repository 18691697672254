/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { t } from "i18n";
import Lane from "components/lane/lane";
import PlusCircle from "components/icons/plus-circle";
import { COLLECTIONS_ALL_ITEMS_PATH } from "../../routes";
import { actions } from "../../actions";
import {
  toggleCollectionItemAction,
  expandCollectionCardAction,
  updateSortedItemsAction,
  updateAssociatedMetadataAction
} from "../../async-action-creators";
import { navigateFn } from "../../../../utils/routes.utils";
import { selectIsDesktopSizeViewport } from "store/viewport";
import styles from "./sorted-items.module.css";
import ScrollableList from "../react-beautiful-dnd-components/scrollable-list";
import ScrollableItem from "../react-beautiful-dnd-components/scrollable-item";
import CollectionCard from "../collection-card/collection-card";

const isSelected = (item, sortedItems) => {
  if (sortedItems.find((i) => i.id === item.id)) {
    return true;
  } else {
    return false;
  }
};

function SortedItems({
  items,
  toggleCollectionItem,
  swappedItems,
  updateSortedItems,
  updateSwappedItems,
  changePageViewsThreshold,
  updatePageViewsThreshold,
  updateStagingAssociatedMetadata,
  currentCardExpandedIndex,
  toggleCardExpanded,
  stagingAssociatedMetadata,
  changeAssociatedMetadataLayout,
  cancelCardExpanded,
  updateAssociatedMetadata,
  templateOptions,
  isAssociatedMetadataEnabled,
  navigate,
  threshold,
  expandCollectionCard,
  attributes,
  hasCollectionRankNestedCollectionPermission,
  storyLimit,
  storiesPageViews,
  stagingSelectedCollection,
  openAllItemsInspector,
  isDesktopSizeViewport
}) {
  return (
    <div className={styles["collection-lane"]}>
      <ScrollableList
        items={items}
        updateSwappedItems={updateSwappedItems}
        updateSortedItems={updateSortedItems}
        swappedItems={swappedItems}>
        <Lane
          title={
            t("collections.sorted_items") +
            (isDesktopSizeViewport ? "" : `\u00A0\u00A0\u00A0\u00A0${items.length}/${storyLimit}`)
          }
          attributes={() =>
            isDesktopSizeViewport ? (
              `${items.length}/${storyLimit}`
            ) : (
              <div
                onClick={() => {
                  openAllItemsInspector(stagingSelectedCollection.id);
                }}
                className={styles["open-collection-inspector-button"]}
                data-test-id="open-collection-inspector-button">
                <PlusCircle />
              </div>
            )
          }>
          <div className={styles["collection-item-list"]} data-test-id="collection-items-list-rhs">
            {items &&
              items.map((item, index) => {
                return (
                  <ScrollableItem ScrollableItem id={item.id} draggableId={item.id} index={index}>
                    <CollectionCard
                      key={item.id}
                      item={item}
                      isSelected={isSelected(item, items)}
                      badge={index + 1}
                      toggleCollectionItem={toggleCollectionItem}
                      type="sorted"
                      changePageViewsThreshold={changePageViewsThreshold}
                      updatePageViewsThreshold={updatePageViewsThreshold}
                      index={index}
                      updateStagingAssociatedMetadata={updateStagingAssociatedMetadata}
                      isCardExpanded={currentCardExpandedIndex === index}
                      toggleCardExpanded={toggleCardExpanded}
                      stagingAssociatedMetadata={stagingAssociatedMetadata}
                      changeAssociatedMetadataLayout={changeAssociatedMetadataLayout}
                      cancelCardExpanded={cancelCardExpanded}
                      updateAssociatedMetadata={updateAssociatedMetadata}
                      templateOptions={templateOptions}
                      isAssociatedMetadataEnabled={isAssociatedMetadataEnabled}
                      pageViews={storiesPageViews && storiesPageViews[item.id]}
                      expandCollectionCard={expandCollectionCard}
                      attributes={attributes}
                      hasCollectionRankNestedCollectionPermission={hasCollectionRankNestedCollectionPermission}
                      threshold={threshold}
                      isDragging={false}
                    />
                  </ScrollableItem>
                );
              })}
          </div>
        </Lane>
      </ScrollableList>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  const { collections, features, config } = state;
  return {
    items: collections.collectionPage.selectedCollection.items,
    storiesPageViews: collections.storiesPageViews,
    swappedItems: collections.ui.swappedItems,
    currentCardExpandedIndex: collections.ui.currentCardExpandedIndex,
    stagingAssociatedMetadata: collections.ui.stagingAssociatedMetadata,
    templateOptions: ownProps.templateOptions,
    isAssociatedMetadataEnabled: features.associatedMetadata,
    threshold: collections.ui.threshold,
    attributes: ownProps.attributes,
    hasCollectionRankNestedCollectionPermission: features.hasCollectionRankNestedCollectionPermission,
    storyLimit: config.collectionStoryLimit,
    stagingSelectedCollection: collections.collectionPage.stagingSelectedCollection,
    isDesktopSizeViewport: selectIsDesktopSizeViewport(state)
  };
};

const mapDispatchToProps = (dispatch) => {
  const navigate = navigateFn(dispatch);
  return {
    navigate,
    toggleCollectionItem: (item) => dispatch(toggleCollectionItemAction(item)),
    updateSwappedItems: (items) => dispatch({ type: actions.UPDATE_SWAPPED_ITEMS, payload: { items } }),
    updateSortedItems: (items) => {
      dispatch(updateSortedItemsAction(items));
    },
    changePageViewsThreshold: (data) => dispatch({ type: actions.CHANGE_PAGE_VIEWS, payload: { data } }),
    updatePageViewsThreshold: (index) => dispatch({ type: actions.UPDATE_PAGE_VIEWS, payload: { index } }),
    updateStagingAssociatedMetadata: (name, value) =>
      dispatch({ type: actions.UPDATE_STAGING_ASSOCIATED_METADATA, payload: { key: name, value } }),
    toggleCardExpanded: (index, item) => dispatch(expandCollectionCardAction(item, index)),
    changeAssociatedMetadataLayout: (value, options) =>
      dispatch({ type: actions.CHANGE_COLLECTION_LAYOUT, payload: { value, options } }),
    cancelCardExpanded: (index, key, value) =>
      dispatch({ type: actions.CANCEL_CARD_EXPAND, payload: { index, key, value } }),
    updateAssociatedMetadata: (index, key, value) => {
      dispatch(updateAssociatedMetadataAction(index, key, value));
    },
    expandCollectionCard: (item, index) => dispatch(expandCollectionCardAction(item, index)),
    openAllItemsInspector: (collectionId) => {
      document.body.className = "prevent-normal-pull-to-refresh";
      navigate(COLLECTIONS_ALL_ITEMS_PATH, { collectionId: collectionId || "new" });
    }
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(SortedItems);
