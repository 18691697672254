/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import Billing from "pages/billing/components/billing/billing";

import * as routes from "./routes";

export const Router = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={routes.BANDWIDTH_BILLING_PATH}
          render={(props) => {
            return <Billing {...props} billingType="bandwidth" />;
          }}
        />
        <Route
          path={routes.SAGE_BILLING_PATH}
          render={(props) => {
            return <Billing {...props} billingType="sage-tokens" />;
          }}
        />
      </Switch>
    </>
  );
};
