/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "api/client";
import { StoryId } from "./primitive-types";

interface Metadata {
  "access-token"?: string[] | string;
  "avatar-url"?: string;
  email?: string;
  "parent-account"?: Parentaccount;
  category?: string;
  perms?: string[];
}

interface Parentaccount {
  uid: string;
  name: string;
}

export interface SocialAccount {
  id: number;
  "publisher-id": number;
  "account-id": string;
  "account-name": string;
  provider: string;
  metadata: Metadata;
  "is-active": boolean;
  "updated-at": number;
}

export interface SelectedMedia {
  id: string;
  type: string;
  status?: string;
}

export interface SocialCard {
  isScheduled: boolean;
  scheduleAt: number;
  contentId: string;
  cardId: string;
  postMessage: string;
  selectedMedia: SelectedMedia[] | [];
  isPosted: boolean;
}

export interface ScheduledSocialCard {
  postMessage: string;
  "social-cards": SocialCard[];
}

interface SocialResponse {
  "social-accounts": SocialAccount[] | [];
  "social-cards": SocialCard[] | [];
}

export const getSocialAccountsAndCards = (storyId: StoryId): Promise<SocialResponse> => {
  return client
    .url(`/api/social`)
    .json({ "story-id": storyId, needed: ["social-accounts", "social-cards"] })
    .post()
    .json();
};
