/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "./state";
import wrapPage from "containers/page/page";
import { loadTags, addNewTag, editTag } from "./async-action-creators";
import TagsContainer from "./components/tags-container/tags-container";
import Inspector from "./components/inspector/inspector";
import { RouteComponentProps } from "react-router";
import { TAGS_NEW_PATH, TAG_EDIT_PATH } from "./routes";
import { TagId } from "api/primitive-types";
import "./tags.css";

interface DispatchProps {
  loadTags: () => void;
  addNewTag: () => void;
  editTag: (tagId: string | TagId) => void;
}

interface StateProps {
  title: string;
}

type Props = RouteComponentProps<any> &
  DispatchProps &
  StateProps & {
    title: string;
    isBannerPresent: boolean;
  };

export const Tags: React.SFC<Props> = (props) => (
  <React.Fragment>
    <section className="page-container tags">
      <TagsContainer />
      <Inspector />
    </section>
  </React.Fragment>
);

const mapStateToProps = (): StateProps => {
  return {
    title: "tags"
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    loadTags: () => dispatch(loadTags()),
    addNewTag: () => dispatch(addNewTag()),
    editTag: (tagId: string | TagId) => dispatch(editTag(tagId))
  };
};

export class TagsWithRoutes extends React.Component<Props> {
  async componentDidMount() {
    await this.props.loadTags();
    this.onEnter();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.match.url !== prevProps.match.url) {
      this.onEnter();
    }
  }

  onEnter() {
    switch (this.props.match.path) {
      case TAGS_NEW_PATH:
        this.props.addNewTag();
        break;

      case TAG_EDIT_PATH:
        this.props.editTag(this.props.match.params.id);
        break;

      default:
        break;
    }
  }

  render() {
    return <Tags {...this.props} />;
  }
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  wrapPage()
)(TagsWithRoutes);
