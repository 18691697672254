/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { IntegrationType } from "./state";

const INTEGRATIONS_TYPE = `${IntegrationType.ALL}|${IntegrationType.SOCIAL}|${IntegrationType.PUSH_NOTIFICATION}|${IntegrationType.MEDIA}|${IntegrationType.TASK_MANAGEMENT}|${IntegrationType.ANALYTICS}|${IntegrationType.OTHERS}`;

export const INTEGRATIONS = "/settings/integrations";
export const INTEGRATIONS_LISTING_PATH = `${INTEGRATIONS}/:type(${INTEGRATIONS_TYPE})`;
export const INTEGRATION_NEW_PATH = `${INTEGRATIONS}/:type(${INTEGRATIONS_TYPE})/new`;
export const INTEGRATION_EDIT_PATH = `${INTEGRATIONS}/:type(${INTEGRATIONS_TYPE})/:integration`;
export const INTEGRATION_SETTINGS = `${INTEGRATIONS}/:type(${INTEGRATIONS_TYPE})/settings`;
