/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Twitter = ({ width = 24, height = 24 }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}>
    <defs>
      <clipPath id="clip-path">
        <rect className="cls-1" x="-24.94" y="0.26" width="23.52" height="23.52" rx="1.17" ry="1.17" />
      </clipPath>
      <clipPath id="clip-path-2">
        <path className="cls-1" d="M-27.3-2.09H.93v28.23H-27.3z" />
      </clipPath>
      <clipPath id="clip-path-3">
        <path
          className="cls-2"
          d="M-6.8 11.77a6.26 6.26 0 0 0-.1-1.16h-5.35v2.2h3.06a2.64 2.64 0 0 1-1.14 1.71v1.42h1.84a5.53 5.53 0 0 0 1.69-4.17z"
        />
      </clipPath>
      <clipPath id="clip-path-4">
        <path
          className="cls-2"
          d="M-12.25 17.32a5.42 5.42 0 0 0 3.76-1.38l-1.84-1.42a3.4 3.4 0 0 1-1.92.54 3.39 3.39 0 0 1-3.18-2.34h-1.89v1.47a5.66 5.66 0 0 0 5.07 3.13z"
        />
      </clipPath>
      <clipPath id="clip-path-5">
        <path
          className="cls-2"
          d="M-15.43 12.72a3.49 3.49 0 0 1-.17-1.08 3.54 3.54 0 0 1 .17-1.08V9.09h-1.89a5.64 5.64 0 0 0-.61 2.55 5.61 5.61 0 0 0 .61 2.55z"
        />
      </clipPath>
      <clipPath id="clip-path-6">
        <path
          className="cls-2"
          d="M-12.25 8.22a3.09 3.09 0 0 1 2.17.85l1.63-1.63A5.48 5.48 0 0 0-12.25 6a5.68 5.68 0 0 0-5.07 3.13l1.89 1.47a3.39 3.39 0 0 1 3.18-2.38z"
        />
      </clipPath>
    </defs>
    <g clipPath="url(#clip-path)">
      <g clipPath="url(#clip-path-2)">
        <path fill="#fff" d="M-27.88-2.68H1.53v29.41h-29.41z" />
      </g>
    </g>
    <path
      d="M20.18 9A10.42 10.42 0 0 1 19 13.9a10 10 0 0 1-3.55 4 10.11 10.11 0 0 1-5.73 1.66A10.16 10.16 0 0 1 4 17.87a7.6 7.6 0 0 0 .87 0 7.36 7.36 0 0 0 4.61-1.58 3.43 3.43 0 0 1-2.16-.72A3.75 3.75 0 0 1 6 13.8h.67a4.75 4.75 0 0 0 1-.1 3.7 3.7 0 0 1-2.11-1.3 3.52 3.52 0 0 1-.85-2.32V10a3.53 3.53 0 0 0 1.66.5A4.07 4.07 0 0 1 5.2 9.19a3.56 3.56 0 0 1 .07-3.62 10.07 10.07 0 0 0 3.36 2.74 10.17 10.17 0 0 0 4.23 1.13 6.09 6.09 0 0 1-.07-.85 3.63 3.63 0 0 1 .5-1.84 3.55 3.55 0 0 1 1.33-1.36 3.63 3.63 0 0 1 1.83-.49 3.49 3.49 0 0 1 1.49.32 4.15 4.15 0 0 1 1.22.84 7.17 7.17 0 0 0 2.35-.88 3.66 3.66 0 0 1-1.61 2 7.71 7.71 0 0 0 2.1-.56 8.21 8.21 0 0 1-1.87 1.94 1.66 1.66 0 0 1 .05.44z"
      fill="#1da1f2"
    />
  </svg>
);

export default Twitter;
