/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction, compose } from "redux";
import { match } from "react-router";
import wrapPage from "containers/page/page";
import { showFeatureNotSetupPage, showUnauthorizedPage } from "store/error-page";
import { PartialAppState } from "store/form/settings";
import SettingsContainer from "components/settings/settings-container/settings-container";
import SettingsSection from "components/settings/settings-section/settings-section";
import { t } from "i18n";
import EmailList from "pages/forms/email-list/email-list";
import { EmailListType } from "store/route-data";
import { disableSaveButton, enableSaveButton, updateCurrentMailingList } from "store/form/settings";
import FormsSettingsHeader from "./components/forms-settings-header";
import _ from "lodash";

interface StateProps {
  isFormsEnabled?: boolean;
  canCreateForms?: boolean;
  defaultMailingList: EmailListType;
  currentMailingList: EmailListType;
}

interface DispatchProps {
  showFeatureNotSetupPage: () => void;
  showUnauthorizedPage: () => void;
  enableSaveButton: () => void;
  disableSaveButton: () => void;
  updateCurrentMailingList: (mailingList: EmailListType) => void;
}

interface OwnProps {
  match: match<{ tabSlug: string }>;
}

type FormSettingsProps = StateProps & DispatchProps & OwnProps;

export const FormsSettings: React.FC<FormSettingsProps> = (props) => {
  const {
    isFormsEnabled,
    canCreateForms,
    showFeatureNotSetupPage,
    showUnauthorizedPage,
    enableSaveButton,
    disableSaveButton,
    defaultMailingList,
    currentMailingList,
    updateCurrentMailingList
  } = props;

  useEffect(() => {
    updateCurrentMailingList(defaultMailingList);
  }, [defaultMailingList, updateCurrentMailingList]);

  useEffect(() => {
    disableSaveButton();
  }, [disableSaveButton]);

  if (!isFormsEnabled) {
    showFeatureNotSetupPage();
  } else if (!canCreateForms) {
    showUnauthorizedPage();
  }

  const handleOnChange = ({ all, invalid }) => {
    updateCurrentMailingList(all);
    if (invalid.length > 0) {
      return disableSaveButton();
    }
    if (!_.isEqual(all, defaultMailingList)) {
      enableSaveButton();
    }
  };

  return (
    <SettingsContainer>
      <SettingsSection
        title={t("forms.settings.mailing-list.title")}
        subtitle={t("forms.settings.mailing-list.description")}>
        <EmailList
          label={t("forms.settings.mailing-list.label")}
          value={currentMailingList}
          onChange={handleOnChange}
        />
      </SettingsSection>
    </SettingsContainer>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    isFormsEnabled: state.features.isFormsEnabled,
    canCreateForms: state.features.canCreateForms,
    defaultMailingList: state.config.forms.defaultMailingList,
    currentMailingList: state.forms.settings.currentMailingList
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => ({
  showUnauthorizedPage: () => dispatch(showUnauthorizedPage()),
  showFeatureNotSetupPage: () => dispatch(showFeatureNotSetupPage()),
  enableSaveButton: () => dispatch(enableSaveButton()),
  disableSaveButton: () => dispatch(disableSaveButton()),
  updateCurrentMailingList: (mailingList) => dispatch(updateCurrentMailingList(mailingList))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  wrapPage({ HeaderComponent: FormsSettingsHeader })
)(FormsSettings);
