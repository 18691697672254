/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import styles from "./errors.module.css";

const error = (message) => (
  <div className={styles["story-element-container"]}>
    <div className={styles["story-element-left-pan"]} />
    <div className={styles["story-element-left-pan"]}>{message}</div>
  </div>
);

export const Pending = error("This story element is pending implementation.");
export const Unused = error(
  "This story element is unused. It doesn't have an implementation. This is most likely a data issue."
);
export const Abstract = error(
  "This story element cannot be used on its own. It can only be used through one of its subtypes."
);

export const LocationElementNotAvailable = error("Story Element not supported, please embed the map with JSEmbed");
