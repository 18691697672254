/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Image = ({ width = "24", height = "24", color = "#c6ccd5" }) => (
  <svg width={width} height={height} fill={color}>
    <path
      d="M18.64 3.13H5.42A2.39 2.39 0 0 0 3 5.52v13.21a2.4 2.4 0 0 0 2.39 2.4h13.25a2.4 2.4 0 0 0 2.36-2.4V5.52a2.39 2.39 0 0 0-2.39-2.39M4.75 19.94l3.41-4.39 2.16 1.76.64-.78L8 14.12l-3.9 5a1.76 1.76 0 0 1-.06-.4V5.52a1.38 1.38 0 0 1 1.38-1.38h13.22A1.38 1.38 0 0 1 20 5.52v9.13l-3.41-3.8L9 20.12H5.42a1.4 1.4 0 0 1-.67-.18m13.89.18h-8.36l6.37-7.72L20 16.17v2.56a1.38 1.38 0 0 1-1.38 1.39"
      fill="none"
    />
    <path
      d="M18.64 3.13H5.42A2.39 2.39 0 0 0 3 5.52v13.21a2.4 2.4 0 0 0 2.39 2.4h13.25a2.4 2.4 0 0 0 2.36-2.4V5.52a2.39 2.39 0 0 0-2.39-2.39M4.75 19.94l3.41-4.39 2.16 1.76.64-.78L8 14.12l-3.9 5a1.76 1.76 0 0 1-.06-.4V5.52a1.38 1.38 0 0 1 1.38-1.38h13.22A1.38 1.38 0 0 1 20 5.52v9.13l-3.41-3.8L9 20.12H5.42a1.4 1.4 0 0 1-.67-.18m13.89.18h-8.36l6.37-7.72L20 16.17v2.56a1.38 1.38 0 0 1-1.38 1.39"
      fillRule="evenodd"
    />
  </svg>
);

export default Image;
