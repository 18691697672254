/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AnyAction } from "redux";
import { connect } from "react-redux";
import React from "react";
import { ThunkDispatch } from "redux-thunk";

import BaseInspector from "components/inspector/inspector";
import TextField from "components/text-field/text-field";
import Select from "components/select/select";
import Breadcrumbs from "components/breadcrumbs/breadcrumbs";

import { t } from "i18n";
import {
  PartialAppState,
  setPushNotificationChannel,
  closeInspector,
  savePushNotificationChannel
} from "store/push-notification-channels";

import { Section } from "api/route-data/route-data";
import { AnyChannel } from "api/push-notification-channels";

const channelTypes = ["custom", "section"];

const availableTypes = channelTypes.map((t) => {
  return { value: t };
});

interface StateProps {
  sections: Section[];
}

interface DispatchProps {
  setPushNotificationChannel: (selectedChannel: AnyChannel) => void;
  closeInspector: () => void;
  savePushNotificationChannel: (selectedChannel: AnyChannel) => void;
}

type Props = StateProps &
  DispatchProps & {
    isActive: boolean;
    currentChannel: AnyChannel;
  };

const enableActionButton = (currentChannel: AnyChannel) => {
  return !(
    currentChannel.name &&
    currentChannel.type !== null &&
    (currentChannel.type === "custom" ? true : currentChannel.section !== null)
  );
};

const Inspector: React.SFC<Props> = ({
  isActive,
  sections,
  currentChannel,
  setPushNotificationChannel,
  savePushNotificationChannel,
  closeInspector
}) => {
  return (
    <BaseInspector
      title={t("settings.push_notification_channels.inspector.new_title")}
      onClose={() => closeInspector()}
      isActive={isActive}
      actionButtonLabel={t("settings.push_notification_channels.inspector.add_button_label")}
      onActionButtonClick={() => savePushNotificationChannel(currentChannel)}
      isActionButtonDisabled={enableActionButton(currentChannel)}>
      <TextField
        label={t("settings.push_notification_channels.inspector.name_field_title")}
        onChange={(name) => {
          setPushNotificationChannel({ ...currentChannel, name });
        }}
        value={currentChannel.name!}
      />

      <Select
        options={availableTypes}
        onChange={(channelType: { value: string }) => {
          setPushNotificationChannel({ ...currentChannel, type: channelType && channelType.value });
        }}
        value={{ value: currentChannel.type! }}
        getOptionLabel={(t) => t.value}
        getOptionValue={(t) => t.value}
        label={t("settings.push_notification_channels.inspector.type_field_title")}
      />
      {currentChannel && currentChannel.type === "section" && (
        <Select
          label={t("settings.push_notification_channels.inspector.section_field_title")}
          value={currentChannel.section}
          options={sections}
          onChange={(section: Section) => {
            setPushNotificationChannel({ ...currentChannel, section });
          }}
          getOptionLabel={(section) => section.name}
          getOptionValue={(section) => section.id.toString()}
          formatOptionLabel={(section) => (
            <React.Fragment>
              {section.name}
              <Breadcrumbs id={section.id} crumbs={sections} getCrumbLabel={(section) => section.name} />
            </React.Fragment>
          )}
        />
      )}
    </BaseInspector>
  );
};

function mapStateToProps(state: PartialAppState): StateProps {
  return {
    sections: state.config.sections
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<PartialAppState, any, AnyAction>) {
  return {
    setPushNotificationChannel: (selectedChannel: AnyChannel) =>
      dispatch(setPushNotificationChannel({ selectedChannel })),
    savePushNotificationChannel: (selectedChannel: AnyChannel) =>
      dispatch(savePushNotificationChannel(selectedChannel)),
    closeInspector: () => dispatch(closeInspector())
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Inspector);

export { Inspector };
