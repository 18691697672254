/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useState, useEffect, ReactNode } from "react";
import classnames from "classnames/bind";

import DoubleChevron from "components/icons/double-chevron";
import { setBodyOverflow } from "utils/dom.utils";
import styles from "./action-bar.module.css";
import { connect } from "react-redux";
import { PartialAppState } from "api/workspace";
import { get } from "lodash";

const cx = classnames.bind(styles);

interface OwnProps {
  isBannerPresent?: boolean;
  isDesktopSizeViewport: boolean;
  renderContents(onItemClickCallback: () => void): ReactNode;
  openOnLoad?: boolean;
}

interface DispatchProps {
  isCurrentWindowIsInSlider: boolean;
}

type ActionBarProps = OwnProps & DispatchProps;

export const ActionBar: React.FC<ActionBarProps> = ({
  renderContents,
  isBannerPresent,
  isDesktopSizeViewport,
  openOnLoad = true,
  isCurrentWindowIsInSlider
}) => {
  const [isActive, setActive] = useState(openOnLoad);

  useEffect(() => {
    if (!isDesktopSizeViewport) {
      isActive ? setBodyOverflow("hidden") : setBodyOverflow("auto");
    }
  }, [isDesktopSizeViewport, isActive]);

  const toggleActionBar = () => {
    setActive(!isActive);
  };

  const classes = cx("action-bar", {
    "is-active": isActive,
    "with-banner": isBannerPresent,
    "in-slider": isCurrentWindowIsInSlider
  });

  return (
    <aside>
      <div className={cx("placeholder", { "placeholder--active": isActive })}></div>
      <div className={classes}>
        <div className={styles["action-bar-content"]}>
          {renderContents(() => !isDesktopSizeViewport && setActive(false))}
        </div>
        <div className={styles["toggle-action-bar"]} onClick={toggleActionBar} data-test-id="toggle-action-bar-btn">
          <DoubleChevron />
        </div>
      </div>
    </aside>
  );
};

const mapStateToProps = (state: PartialAppState): DispatchProps => {
  return {
    isCurrentWindowIsInSlider: get(state, ["slider", "currentWindow", "isInSlider"], false)
  };
};

export default connect(mapStateToProps)(ActionBar);
