/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { toDate } from "date-fns";

import Select from "components/select/select";
import { DateRangePicker } from "@quintype/em/components/date-range-picker";
import FieldLabel from "components/field-label/field-label";
import { CurrentFilter, DateRange, Range } from "pages/analytics/state";
import { AnalyticsConfig } from "api/route-data/analytics-route-data";
import { Timestamp } from "api/primitive-types";

import styles from "./date-wrapper.module.css";

function getTimestampInMS(timeStamp) {
  return toDate(timeStamp * 1000).getTime();
}

interface Props {
  currentFilter: CurrentFilter;
  config: AnalyticsConfig;
  onChange: (item: DateRange) => void;
  dateRangeComparison: string;
  onChangeCustomDate: (key: string, startDate: Timestamp, endDate: Timestamp) => void;
  customRange: Range;
  limitCustomInterval?: boolean;
}

const DateWrapper: React.SFC<Props> = ({
  currentFilter,
  config,
  onChange,
  dateRangeComparison,
  onChangeCustomDate,
  customRange,
  limitCustomInterval
}) => {
  let { "start-ts": startTs, "end-ts": endTs } = customRange["range-1"];

  function timeStampInS(timeStampInMS) {
    const timeDateFormat = new Date(timeStampInMS);
    return Math.floor(timeDateFormat.getTime() / 1000);
  }

  return (
    <div className={styles["analytics-container__date-wrapper"]} data-test-id="analytics-container-date-wrapper">
      <Select
        label={t("analytics.select_range")}
        options={config.periods}
        value={currentFilter.dateRange}
        onChange={(item: DateRange) => onChange(item)}
        getOptionLabel={(item) => item["display-name"]}
        getOptionValue={(item) => item.name}
        classname={"analytics-select-container"}
      />
      {currentFilter.dateRange.name === "custom" ? (
        <div className={styles["date-range-picker-container"]}>
          <DateRangePicker
            startTimeStamp={timeStampInS(startTs)}
            endTimeStamp={timeStampInS(endTs)}
            onDateRangeSelect={(startTimeStamp, endTimeStamp) => {
              onChangeCustomDate("range-1", getTimestampInMS(startTimeStamp), getTimestampInMS(endTimeStamp));
            }}
          />
        </div>
      ) : (
        <div className={styles["date-comparison-container"]}>
          <FieldLabel label={t("date-range-picker.comparison")} />
          <span>{dateRangeComparison}</span>
        </div>
      )}
    </div>
  );
};

export default DateWrapper;
