/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import styles from "./context-menu-list.module.css";

interface ContextMenuListProps {
  children: Array<React.ReactNode> | React.ReactNode;
}
const ContextMenuList = ({ children }: ContextMenuListProps) => {
  return (
    <ul className={styles["context-menu-list"]} data-test-id="context-menu-list">
      {children}
    </ul>
  );
};

export { ContextMenuList };
