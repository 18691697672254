/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export * from "./pages/workspace/routes";
export * from "./pages/media-library/routes";
export * from "./pages/manage/routes";
export * from "./pages/advanced-settings/routes";
export * from "./pages/settings/routes";
export * from "./pages/content/routes";
export * from "./pages/user/routes";
export * from "./pages/analytics/routes";
export * from "./pages/media-library/archived_routes";
