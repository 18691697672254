/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface BoldIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const Bold: React.SFC<BoldIconProps> = ({ width = "24", height = "24", color = "currentColor" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 24 24">
    <path
      fillRule="nonzero"
      d="M16.822 11.728c.92.291 1.64.778 2.162 1.46.521.683.782 1.522.782 2.519 0 1.41-.548 2.495-1.644 3.255-1.097.759-2.695 1.138-4.796 1.138H5V4h7.866c1.963 0 3.47.376 4.52 1.127 1.05.751 1.575 1.771 1.575 3.059 0 .782-.188 1.48-.563 2.093a3.947 3.947 0 0 1-1.576 1.449zM8.703 6.806v3.795h3.703c.92 0 1.618-.161 2.093-.483.475-.322.713-.797.713-1.426 0-.629-.238-1.1-.713-1.415-.475-.314-1.173-.471-2.093-.471H8.703zm4.347 10.488c.981 0 1.721-.161 2.22-.483.498-.322.747-.82.747-1.495 0-1.334-.989-2.001-2.967-2.001H8.703v3.979h4.347z"
    />
  </svg>
);

export default Bold;
