/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

/*
TODO: Remove this. It's used as a placeholder for an empty value,
  when null would be much more cleaner to use.
  This function ideally shouldn't exist.
*/

export const isEmptyObject = (obj: Object) => Object.keys(obj).length === 0 && obj.constructor === Object;
