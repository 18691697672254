/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import styles from "./delta.module.css";

const cx = classnames.bind(styles);

const Delta = ({ value, variant }: { value: number | null; variant?: string }) => {
  return (
    <span
      className={cx("delta-container", {
        "delta-container--positive": value && value > 0,
        "delta-container--negative": value && value < 0,
        "delta-container--zero": value === 0,
        "delta-container--new": value === null,
        "delta-container--large": variant === "large",
        "delta-container--medium": variant === "medium",
        "delta-container--small": variant === "small"
      })}>
      <span className={styles["zero-icon"]} />
      {!!value && `${value}%`}
    </span>
  );
};

export default Delta;
