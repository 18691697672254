/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Timestamp } from "api/primitive-types";

export enum CollectionDateFilterTypes {
  Today = "today",
  ThisWeek = "this-week",
  ThisMonth = "this-month",
  Last7Days = "last-7-days",
  Last30Days = "last-30-days",
  Custom = "custom"
}

interface RelativeCollectionDateFilter {
  type:
    | CollectionDateFilterTypes.Today
    | CollectionDateFilterTypes.Last7Days
    | CollectionDateFilterTypes.Last30Days
    | CollectionDateFilterTypes.ThisWeek
    | CollectionDateFilterTypes.ThisMonth;
}

interface CustomDateFilter {
  type: CollectionDateFilterTypes.Custom;
  from: Timestamp;
  to: Timestamp;
}

export type CollectionDateFilter = RelativeCollectionDateFilter | CustomDateFilter;
