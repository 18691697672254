/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { PromotionalMessages, Config } from "api/promotional-messages";

enum actions {
  LOAD_PROMOTIONAL_MESSAGES_INIT = "@@settings/LOAD_PROMOTIONAL_MESSAGES_INIT",
  LOAD_PROMOTIONAL_MESSAGES_SUCCESS = "@@settings/LOAD_PROMOTIONAL_MESSAGES_SUCCESS",
  LOAD_PROMOTIONAL_MESSAGES_FAILURE = "@@settings/LOAD_PROMOTIONAL_MESSAGES_FAILURE",

  UPDATE_PROMOTIONAL_MESSAGE_CONFIG = "@@settings/UPDATE_PROMOTIONAL_MESSAGE_CONFIG",

  SAVE_PROMOTIONAL_MESSAGES_INIT = "@@settings/SAVE_PROMOTIONAL_MESSAGES_INIT"
}

export default actions;

export type LoadPromotionalMessagesRequestAction = {
  type: actions.LOAD_PROMOTIONAL_MESSAGES_INIT;
};
export type LoadPromotionalMessagesSuccessAction = {
  type: actions.LOAD_PROMOTIONAL_MESSAGES_SUCCESS;
  payload: { promotionalMessages: PromotionalMessages };
};
export type LoadPromotionalMessagesFailureAction = {
  type: actions.LOAD_PROMOTIONAL_MESSAGES_FAILURE;
  payload: { error: Error };
};
export type OnConfigChangeAction = {
  type: actions.UPDATE_PROMOTIONAL_MESSAGE_CONFIG;
  payload: { config: Config };
};

export type SavePromotionalMessagesInitAction = {
  type: actions.SAVE_PROMOTIONAL_MESSAGES_INIT;
};

export type ActionTypes =
  | LoadPromotionalMessagesRequestAction
  | LoadPromotionalMessagesSuccessAction
  | LoadPromotionalMessagesFailureAction
  | OnConfigChangeAction
  | SavePromotionalMessagesInitAction;
