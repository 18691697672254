/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect, useState } from "react";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import { Location } from "history";
import { navigate } from "utils/routes.utils";
import { parse } from "query-string";
import { BANDWIDTH_BILLING_PATH, SAGE_BILLING_PATH } from "pages/billing/routes";
import styles from "./billing.module.css";
import { ThunkDispatch } from "redux-thunk";
import wrapPage from "containers/page/page";
import { t } from "i18n";
import { showUnauthorizedPage } from "store/error-page";
import ContentHeader from "pages/settings/components/content-header/content-header";
import MonthPicker from "pages/billing/components/month-picker/month-picker";
import { fetchBandwidthReport, fetchSageTokensReport, PartialAppState } from "store/billing/billing";

interface BillingStateProps {
  title: string;
  canAccessBilling?: boolean;
  content: string;
  currentTime: Date;
  billingType?: string;
}
interface BillingDispatchProps {
  showUnauthorizedPage: () => void;
  loadBandwidthReport: (duration: string) => void;
  loadSageReport: (duration: string) => void;
  fetchBillingReport?: (duration: string) => void;
}

interface OwnProps {
  location?: Location;
}

export function offsetBillingPeriod(param) {
  const currDate = new Date();
  const [queriedYear, queriedMonth] = param.split("-").map((num) => Number(num));
  const isTodayFirstOfCurrentMonth =
    queriedMonth === currDate.getMonth() + 1 && queriedYear === currDate.getFullYear() && currDate.getDate() === 1;
  if (isTodayFirstOfCurrentMonth) {
    if (queriedMonth === 1) {
      param = `${queriedYear - 1}-12`;
    } else {
      param = `${queriedYear}-${queriedMonth < 11 ? "0" : ""}${queriedMonth - 1}`;
    }
  }
  return param;
}

type BillingProps = BillingStateProps & BillingDispatchProps & OwnProps;

const Billing: React.FC<BillingProps> = ({
  showUnauthorizedPage,
  canAccessBilling,
  loadBandwidthReport,
  content,
  location,
  currentTime,
  loadSageReport,
  billingType = "bandwidth"
}) => {
  let maxYear, year, maxMonth, month;
  maxYear = year = currentTime.getFullYear();
  maxMonth = month = currentTime.getMonth() + 1;

  const queryMonth = location && (parse(location.search)["duration"] as string);
  if (queryMonth) {
    year = parseInt(queryMonth.split("-")[0]);
    month = parseInt(queryMonth.split("-")[1]);
  }

  const [duration, setDuration] = useState({ year, month });
  const title = billingType === "bandwidth" ? t("billing.page-title") : t("billing.sage-page-title");

  useEffect(() => {
    const fetchData = async () => {
      const monthParam = duration.month.toString().length === 1 ? "0" + duration.month : duration.month;
      let param = offsetBillingPeriod(`${duration.year}-${monthParam}`);
      if (billingType === "sage-tokens") {
        await loadSageReport(param);
      } else {
        await loadBandwidthReport(param);
      }
    };

    if (!canAccessBilling) {
      showUnauthorizedPage();
    } else {
      fetchData();
    }
  }, [duration, showUnauthorizedPage, canAccessBilling, loadBandwidthReport, loadSageReport, billingType]);

  return (
    <>
      <ContentHeader title={title}>
        <MonthPicker
          years={{ min: { year: 2022, month: 1 }, max: { year: maxYear, month: maxMonth } }}
          value={duration}
          onMonthChange={setDuration}
        />
      </ContentHeader>
      <div className={styles["billing-page-container"]} data-test-id="billing-container">
        <iframe title="billing-iframe" data-test-id="billing-iframe" srcDoc={content} />
      </div>
    </>
  );
};

const mapStateToProps = (state: PartialAppState): BillingStateProps => {
  return {
    canAccessBilling: state.features.canAccessBilling,
    content: state.billing.content,
    title: "billing",
    currentTime: new Date()
  };
};

const createLoadReportAction = (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  path: string,
  fetchBillingReport: any
) => (duration: string) => {
  dispatch(fetchBillingReport(duration));
  dispatch(
    navigate(
      path,
      {},
      {
        duration: duration
      }
    )
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): BillingDispatchProps => {
  return {
    showUnauthorizedPage: () => dispatch(showUnauthorizedPage()),
    loadBandwidthReport: createLoadReportAction(dispatch, BANDWIDTH_BILLING_PATH, fetchBandwidthReport),
    loadSageReport: createLoadReportAction(dispatch, SAGE_BILLING_PATH, fetchSageTokensReport)
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), wrapPage())(Billing);

export { Billing };
