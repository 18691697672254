/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { combineReducers } from "redux";
import editorReducer from "./editor";
import dashboardReducer from "./dashboard";
import settingsReducer from "./settings";
import { EmailListType } from "store/route-data";

export interface Form {
  id: number;
  headline: string;
  subheadline: string | null;
  slug: string;
  status: string;
  formioId: string;
  formioData: any;
  updatedAt: number;
  publishedAt: number;
  createdAt: number;
  mailingList: EmailListType;
}

export type Forms = NormalizedObject<Form>;

export enum Format {
  JSON = "json",
  CSV = "csv"
}

export interface ExportState {
  isExportingJSON: boolean;
  isExportingCSV: boolean;
}

const reducer = combineReducers({ editor: editorReducer, dashboard: dashboardReducer, settings: settingsReducer });

export default reducer;
