/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import DatePicker from "react-datepicker";
import { isAfter } from "date-fns";
import FieldLabel from "components/field-label/field-label";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./date-range-picker.module.css";
import { t } from "i18n";

const cx = classnames.bind(styles);

interface Props {
  startDate: Date | null;
  endDate: Date | null;
  dateFormat?: string;
  onChange: (startDate: Date, endDate: Date) => void;
  label?: string;
  disableRangeEnd?: boolean;
  maxDate?: Date;
  minDate?: Date;
  classname?: string;
}

interface State {
  startDate: Date | null;
  endDate: Date | null;
}

class DateRangePicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      startDate: this.props.startDate || new Date(),
      endDate: this.props.endDate || new Date()
    };
  }

  handleChange = ({ startDate, endDate }: { startDate?: Date; endDate?: Date }) => {
    let startDateToSend = startDate || this.state.startDate;
    let endDateToSend = endDate || this.state.endDate;

    if (startDateToSend && endDateToSend) {
      if (isAfter(startDateToSend, endDateToSend)) {
        endDateToSend = startDateToSend;
      }
      this.props.onChange(startDateToSend, endDateToSend);
      this.setState({ startDate: startDateToSend, endDate: endDateToSend });
    }
  };

  handleChangeStart = (startDate: Date) => this.handleChange({ startDate });

  handleChangeEnd = (endDate: Date) => this.handleChange({ endDate });

  render() {
    const { label, disableRangeEnd, maxDate, minDate, classname = "" } = this.props;
    return (
      <div className={cx("date-picker-container", "date-range-picker", classname)}>
        <div className={styles["date-range-picker__start-date"]} data-test-id="date-range-picker-start-date">
          {label && <FieldLabel label={t("date-range-picker.start-date")} />}
          <DatePicker
            popperModifiers={{
              preventOverflow: {
                enabled: true,
                escapeWithReference: false // force popper to stay in viewport (even when input is scrolled out of view)
              }
            }}
            className={styles["input-container"]}
            selected={this.state.startDate}
            selectsStart
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onChange={(date: Date) => this.handleChangeStart(date)}
            dateFormat={this.props.dateFormat || "dd/MM/yyyy"}
            maxDate={maxDate && maxDate}
            minDate={minDate && minDate}
          />
        </div>
        <div className={styles["date-range-picker__end-date"]} data-test-id="date-range-picker-end-date">
          {label && <FieldLabel label={t("date-range-picker.end-date")} />}
          <DatePicker
            popperModifiers={{
              preventOverflow: {
                enabled: true,
                escapeWithReference: false // force popper to stay in viewport (even when input is scrolled out of view)
              }
            }}
            className={cx("input-container", { "is-disabled": disableRangeEnd })}
            selected={this.state.endDate}
            selectsEnd
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onChange={(date: Date) => this.handleChangeEnd(date)}
            dateFormat={this.props.dateFormat || "dd/MM/yyyy"}
            maxDate={maxDate && maxDate}
            minDate={minDate && minDate}
            disabled={disableRangeEnd}
          />
        </div>
      </div>
    );
  }
}

export default DateRangePicker;
