/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useState } from "react";
import { TextFieldWithCount } from "components/text-field/text-field";
import CategoryTitle from "components/category-title/category-title";
import PushNotificationTargets from "pages/content/push-notification/components/push-notification-targets/push-notification-targets";
import { Target } from "api/push-notification";
import { t } from "i18n";
import { AiGenerateButton } from "pages/story-editor/components/ai-generate-button/ai-generate-button";
import { AllowedStoryFields } from "api/ai";
import styles from "./push-notifications.module.css";
import classnames from "classnames/bind";
import { compose } from "redux";
import { connect } from "react-redux";
import { PartialAppState } from "pages/story-editor/state";
import { get, isEmpty } from "lodash";
import AiSuggestions from "pages/story-editor/components/ai-suggestions/ai-suggestions";
import Checkbox from "components/checkbox/checkbox";
import DatePicker from "components/date-picker/date-picker";
import { PushNotificationStoryFeature } from "api/story";

const cx = classnames.bind(styles);

interface StateProps {
  isGeneratePNTitleEnabled: boolean;
  isGeneratePNMessageEnabled: boolean;
  pushNotificationStoryFeatures: PushNotificationStoryFeature;
}

export const PushNotifications = ({
  story,
  fieldLimits,
  onPushNotificationTextChange,
  onPushNotificationTargetChange,
  onPushNotificationTitleChange,
  onPushNotificationSchedule,
  onPushNotificationScheduleToggle,
  channelsEnabled,
  disabled,
  isGeneratePNTitleEnabled,
  isGeneratePNMessageEnabled,
  pushNotificationStoryFeatures
}) => {
  const [showPNTitleAiSuggestions, setShowPNTitleAiSuggestions] = useState(false);
  const [showPNMessageAiSuggestions, setShowPNMessageAiSuggestions] = useState(false);
  const [generatingPNTitle, setGeneratingPNTitle] = useState(false);
  const [generatingPNMessage, setGeneratingPNMessage] = useState(false);
  const targets: Target[] = story["push-notification-targets"] || [];
  const maxLength = fieldLimits["push-notification"] && fieldLimits["push-notification"].limit;

  const showGeneratePNTitleBtn = !story["is-published"] && isGeneratePNTitleEnabled;
  const showGeneratePNMessageBtn = !story["is-published"] && isGeneratePNMessageEnabled;
  const togglePNTitleDisableBtn = story["is-published"] || disabled;
  const togglePNMessageDisableBtn = story["is-published"] || disabled;
  const isScheduled = get(pushNotificationStoryFeatures, ["is-scheduled"], false);
  const publishAt = get(pushNotificationStoryFeatures, ["publish-at"]) || Date.now();
  const handleDateChange = (selectedPublishAt: string | boolean) => {
    onPushNotificationSchedule("push-notification", { "publish-at": selectedPublishAt });
  };
  return (
    <React.Fragment>
      <CategoryTitle title={t("story-editor.inspector.push-notification")} />
      <div
        className={cx("story-inspector-pn-title", {
          "story-inspector-pn-title--show-ai-generate-btn": showGeneratePNTitleBtn
        })}>
        <AiSuggestions
          isOpen={showPNTitleAiSuggestions}
          onClose={() => setShowPNTitleAiSuggestions(false)}
          storyField={AllowedStoryFields.PUSH_NOTIFICATION_TITLE}
          characterLimit={maxLength}
          onSuggestionSelect={(suggestion: string) => onPushNotificationTitleChange(suggestion)}
          currentFieldValue={story["push-notification-title"]}
          onGeneratingSuggestion={(generatingSuggestion: boolean) => setGeneratingPNTitle(generatingSuggestion)}
          onSuggestionsChange={(suggestions: string[]) => {
            if (isEmpty(story["push-notification-title"]) && suggestions.length > 0) {
              onPushNotificationTitleChange(suggestions[0]);
            }
          }}>
          <TextFieldWithCount
            label={t("story-editor.inspector.push-notification-title")}
            value={story["push-notification-title"]}
            disabled={togglePNTitleDisableBtn}
            maxValue={maxLength}
            onChange={onPushNotificationTitleChange}
          />
          {showGeneratePNTitleBtn && (
            <AiGenerateButton
              classname={cx("story-inspector-pn-title__ai-generate-btn")}
              disabled={disabled}
              loading={generatingPNTitle}
              onClick={() => setShowPNTitleAiSuggestions(!showPNTitleAiSuggestions)}
            />
          )}
        </AiSuggestions>
      </div>
      <div
        className={cx("story-inspector-pn-message", {
          "story-inspector-pn-message--show-ai-generate-btn": showGeneratePNMessageBtn
        })}>
        <AiSuggestions
          isOpen={showPNMessageAiSuggestions}
          onClose={() => setShowPNMessageAiSuggestions(false)}
          storyField={AllowedStoryFields.PUSH_NOTIFICATION_MESSAGE}
          characterLimit={maxLength}
          onSuggestionSelect={(suggestion: string) => onPushNotificationTextChange(suggestion)}
          currentFieldValue={story["push-notification"]}
          onGeneratingSuggestion={(generatingSuggestion: boolean) => setGeneratingPNMessage(generatingSuggestion)}
          onSuggestionsChange={(suggestions: string[]) => {
            if (isEmpty(story["push-notification"]) && suggestions.length > 0) {
              onPushNotificationTextChange(suggestions[0]);
            }
          }}>
          <TextFieldWithCount
            label={t("story-editor.inspector.push-notification-message")}
            value={story["push-notification"]}
            disabled={togglePNMessageDisableBtn}
            maxValue={maxLength}
            onChange={(val) => onPushNotificationTextChange(val)}
          />
          {showGeneratePNMessageBtn && (
            <AiGenerateButton
              classname={cx("story-inspector-pn-message__ai-generate-btn")}
              disabled={disabled}
              loading={generatingPNMessage}
              onClick={() => setShowPNMessageAiSuggestions(!showPNMessageAiSuggestions)}
            />
          )}
        </AiSuggestions>
      </div>
      {!story["is-published"] && (
        <PushNotificationTargets
          targets={targets}
          channelsEnabled={channelsEnabled}
          onUpdate={(targets) => onPushNotificationTargetChange(targets)}
          disabled={disabled}
        />
      )}
      <div className={cx("push-notification-scheduler-container")}>
        <Checkbox
          id={`social-account-${story["notification-id"]}`}
          disabled={togglePNTitleDisableBtn}
          label={t("story-editor.inspector.schedule-push-notification")}
          checked={isScheduled}
          onChange={(checked) => {
            return onPushNotificationScheduleToggle("push-notification", {
              "is-scheduled": checked,
              ...(checked ? { "publish-at": Date.now() } : { "publish-at": null })
            });
          }}
          classname={styles["push-notification-scheduler-checkbox"]}
        />
        {isScheduled && (
          <DatePicker
            dateFormat={"dd/MM/yyyy h:mm a"}
            disabled={togglePNTitleDisableBtn}
            onChange={handleDateChange}
            selectedDate={publishAt}
            showTimeSelect
          />
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    isGeneratePNTitleEnabled: get(
      state.config,
      ["ai-content-generation", "story", AllowedStoryFields.PUSH_NOTIFICATION_TITLE],
      false
    ),
    isGeneratePNMessageEnabled: get(
      state.config,
      ["ai-content-generation", "story", AllowedStoryFields.PUSH_NOTIFICATION_MESSAGE],
      false
    ),
    pushNotificationStoryFeatures: get(state.storyEditor, ["story", "story-features", "push-notification"], {
      "is-scheduled": false,
      "publish-at": Date.now()
    })
  };
};

export default compose(connect(mapStateToProps))(PushNotifications);
