/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import TextField from "components/text-field/text-field";
import { IntegrationConfig } from "api/integrations";
import { capitalize } from "lodash";
import { getErrorMessage } from "../validate";

interface Props {
  integration: IntegrationConfig;
  updateIntegrationField: (key: string, value: Object) => void;
  readOnly?: boolean;
  errors: Error | null;
}

const GenericIntegration: React.SFC<Props> = ({ integration, updateIntegrationField, readOnly, errors }) => {
  const integrationName = integration && integration.key;
  const integrationFields =
    integration && integration.value && Object.keys(integration.value).filter((v) => !["disabled", "type"].includes(v));
  return (
    <React.Fragment>
      {integrationFields &&
        integrationFields.map((v) => {
          const defaultLabel = v && capitalize(v.replace("-", " "));
          return (
            <TextField
              key={v}
              label={t(`integrations.fields.${v}`, { defaultValue: defaultLabel })}
              value={integration.value[v] || ""}
              onChange={(value) => updateIntegrationField(integrationName, { [v]: value })}
              readOnly={readOnly}
              errorMessage={getErrorMessage(integration.key, v, errors)}
            />
          );
        })}
    </React.Fragment>
  );
};

export default GenericIntegration;
