/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Spinner from "components/spinner/spinner";
import { LoaderState } from "behaviors/loader/state";
import { t } from "i18n";

interface LoaderProps {
  loader: LoaderState;
}

const Loader: React.SFC<LoaderProps> = ({ loader: { loading, error } }) => {
  if (loading) {
    return <Spinner message={t("tasks.messages.loading")} />;
  }
  try {
    const errorData = error && JSON.parse(error.message);

    if (errorData.status === 402) {
      return <p>{t("tasks.messages.unassigned_user_error")}</p>;
    }
  } catch (e) {
    return <p>{t("tasks.messages.error")}</p>;
  }
  return <p>{t("tasks.messages.error")}</p>;
};

export default Loader;
