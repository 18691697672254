/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { push } from "connected-react-router";
import { route } from "utils/routes.utils";

import Button from "components/button/button";
import { MetadataFields } from "pages/story-editor/components/metadata-fields";
import { SeoFields } from "pages/story-editor/components/seo-fields";
import StoryAttributes from "pages/story-editor/components/attributes/attributes";
import {
  filterMandatoryFieldsAction,
  getAttributesOfTypeEntityOptionsAction,
  updateAttributeAction,
  updateStoryTagAction
} from "pages/story-editor/async-action-creators";
import { STORY_EDITOR_MANAGE_ADVANCED_METADATA_PATH, STORY_EDITOR_PATH } from "pages/story-editor/routes";
import { navigateFn } from "utils/routes.utils";
import { actions } from "../../actions";
import { AsanaProjectSelect } from "../manage-fields/asana-project-select";
import FieldLabel from "components/field-label/field-label";
import CategoryTitle from "components/category-title/category-title";
import styles from "./mandatory-inspector.module.css";

import { t } from "i18n";
import isEmpty from "lodash/isEmpty";

const getMandatoryStoryAttributes = (attributes) =>
  attributes && attributes.filter((attribute) => attribute["is-mandatory"] && attribute.type === "story");

const MandatoryInspector = ({
  fieldSpecs,
  filterMandatoryFields,
  story,
  onChange,
  onSEOChange,
  onMetadataChange,
  onTagChange,
  sections,
  showAdvancedInspector,
  toggleSponsoredContent,
  showSponsoredContent,
  errors,
  hasTagCreatePermission,
  isTaskPlannerEnabled,
  isUpdateNewStoryCreationEnabled,
  setProject,
  selectedProject,
  attributes,
  isFreeTextAttributesEnabled,
  getAttributesOfTypeEntityOptions,
  onAttributeChange,
  isStoryLocked
}) => {
  const customSlugField = (Component, specPath, storyPath) => (
    <Component
      key={specPath}
      value={story[storyPath]}
      onChange={onChange(storyPath)}
      spec={fieldSpecs[specPath]}
      errors={errors[specPath] || errors[storyPath]}
      data-test-id="custom-slug"
      disabled={story["first-published-at"] || isStoryLocked}
    />
  );

  return (
    <div className={styles["manage-inspector-container"]} data-test-id="manage-inspector-container">
      {filterMandatoryFields(MetadataFields, fieldSpecs, errors).map(({ component: Component, specPath, storyPath }) =>
        storyPath === "custom-slug" ? (
          customSlugField(Component, specPath, storyPath)
        ) : (
          <Component
            story={story}
            key={specPath}
            value={story[storyPath]}
            onChange={onChange(storyPath)}
            onMetadataChange={onMetadataChange}
            onTagChange={onTagChange}
            spec={fieldSpecs[specPath]}
            sections={sections}
            errors={errors[specPath] || errors[storyPath]}
            showSponsoredContent={showSponsoredContent}
            toggleSponsoredContent={toggleSponsoredContent}
            hasTagCreatePermission={hasTagCreatePermission}
            disabled={isStoryLocked}
          />
        )
      )}
      {filterMandatoryFields(SeoFields, fieldSpecs, errors).map(({ component: Component, specPath, storyPath }) => (
        <Component
          key={specPath}
          value={story.seo && story.seo[storyPath]}
          onChange={onSEOChange(storyPath)}
          spec={fieldSpecs[specPath]}
          errors={errors && (errors[specPath] || errors[storyPath])}
          disabled={isStoryLocked}
        />
      ))}

      <StoryAttributes
        attributes={getMandatoryStoryAttributes(attributes)}
        onAttributeChange={onAttributeChange}
        selectedAttributes={!isEmpty(story.metadata) && story.metadata["story-attributes"]}
        isFreeTextAttributesEnabled={isFreeTextAttributesEnabled}
        getAttributesOfTypeEntityOptions={getAttributesOfTypeEntityOptions}
        errors={errors}
        disabled={isStoryLocked}
      />

      {isTaskPlannerEnabled &&
        isUpdateNewStoryCreationEnabled &&
        (!story["task-id"] ? (
          <React.Fragment>
            <CategoryTitle title={t("story-editor.inspector.task-planner")} />
            <AsanaProjectSelect
              onChange={(asanaProject) => setProject(asanaProject)}
              selectedProject={selectedProject}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <CategoryTitle title={t("story-editor.inspector.task-planner")} />
            <FieldLabel label={t("asana-task-id")} />
            <div className={styles["display-text"]}>{story["task-id"]}</div>
          </React.Fragment>
        ))}

      <Button
        type="secondary"
        testId="show-advanced-options"
        onClick={() => showAdvancedInspector(story["story-content-id"])}>
        {t("story-editor.inspector.show-advanced-options")}
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    story: state.storyEditor.story,
    sections: state.config.sections,
    errors: state.storyEditor.ui.errors.inspector,
    showSponsoredContent: state.storyEditor.ui.showSponsoredContent,
    fieldSpecs: state.storyEditor.storyTemplateFields,
    hasTagCreatePermission: state.features.hasTagCreatePermission,
    isTaskPlannerEnabled: state.features.isTaskPlannerEnabled,
    isUpdateNewStoryCreationEnabled: state.features.isUpdateNewStoryCreationEnabled,
    selectedProject: state.storyEditor.app.asanaProject,
    attributes: state.config.storyAttributes,
    isFreeTextAttributesEnabled: state.features.isFreeTextAttributesEnabled,
    isStoryLocked: state.storyEditor.ui.isStoryLocked
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const navigate = navigateFn(dispatch);

  return {
    navigate,
    closeMandatoryInspector: () => navigate(STORY_EDITOR_PATH, { id: ownProps.storyId }),
    onChange: (key) => (value) => dispatch({ type: actions.UPDATE_STORY, payload: { key, value } }),
    onSEOChange: (path) => (value) => dispatch({ type: actions.UPDATE_SEO, payload: { path, value } }),
    onMetadataChange: (key, value) => dispatch({ type: actions.UPDATE_STORY_METADATA, payload: { key, value } }),
    filterMandatoryFields: (fields, fieldSpecs, errors) => filterMandatoryFieldsAction(fields, fieldSpecs, errors),
    showAdvancedInspector: (id) => dispatch(push(route(STORY_EDITOR_MANAGE_ADVANCED_METADATA_PATH, { id }))),
    toggleSponsoredContent: (value) => dispatch({ type: actions.TOGGLE_SPONSORED_CONTENT, payload: { value } }),
    setProject: (value) => dispatch({ type: actions.SET_PROJECT, payload: value }),
    getAttributesOfTypeEntityOptions: (query, attributeValues) =>
      getAttributesOfTypeEntityOptionsAction(query, attributeValues),
    onAttributeChange: (key, value) => dispatch(updateAttributeAction(key, value)),
    onTagChange: (value) => dispatch(updateStoryTagAction(value))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(MandatoryInspector);

export { MandatoryInspector };
