/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Sidebar from "components/sidebar/sidebar";
import { t } from "i18n";
import Menu from "components/menu/menu";
import menuItems from "./menu-items";
import { match } from "react-router";
import { Features } from "api/route-data/route-data";

interface PartialAppState {
  features: Features;
}
interface StateProps {
  enableAdminLinksInSidebar?: boolean;
  enableTagsInSidebar?: boolean;
  enableEntitiesInSidebar?: boolean;
}

type Props = StateProps & {
  match: match;
  isBannerPresent: boolean;
  setActiveSidebar: (type: string) => void;
};

export class ManageSidebar extends React.Component<Props> {
  render() {
    return (
      <Sidebar isBannerPresent={this.props.isBannerPresent}>
        <Menu
          items={menuItems({
            enableAdminLinksInSidebar: this.props.enableAdminLinksInSidebar,
            enableTagsInSidebar: this.props.enableTagsInSidebar,
            enableEntitiesInSidebar: this.props.enableEntitiesInSidebar
          })}
          getItemLabel={(item) => item.label}
          routeTo={(item) => item.path}
          isItemSelected={(item) => item.path === this.props.match.path || this.props.match.path.includes(item.path)}
          title={t("manage.sidebar.title")}
        />
      </Sidebar>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    enableAdminLinksInSidebar: state.features.enableAdminLinksInSidebar,
    enableTagsInSidebar: state.features.enableTagsInSidebar,
    enableEntitiesInSidebar: state.features.enableEntitiesInSidebar
  };
};

export default compose(connect(mapStateToProps))(ManageSidebar);
