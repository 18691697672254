/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { pick } from "lodash";

import TextField from "components/text-field/text-field";
import Select from "components/select/select";
import RenderMultipleRow from "components/render-multiple-row/render-multiple-row";
import { SocialLinks as SocialLinksType } from "api/settings";
import styles from "./social-links.module.css";

export type SocialLinkProvider = { [key: string]: string };

interface Props {
  defaultSocialLinkProviders: Array<SocialLinkProvider>;
  socialLinks: SocialLinksType | null;
  updateSocialLinks: (changes: object) => void;
  labelAdd?: string;
  haveHandleField?: boolean;
}

const getNewLinks = (newProviders: Array<string>, currentLinks: SocialLinksType | null) => {
  return newProviders.reduce((acc, provider) => {
    let url = (currentLinks && currentLinks[provider]) || null;
    return { ...acc, [provider]: url };
  }, {});
};

const setLinkProviders = (socialLinks: SocialLinksType | null, currentProvider: string | null, newProvider: string) => {
  if (!currentProvider) {
    return { ...socialLinks, [newProvider]: null };
  }

  let found = false;

  const selectedProviders = (socialLinks && Object.keys(socialLinks)) || [];

  const results =
    selectedProviders &&
    selectedProviders.map((provider: string) => {
      if (provider === currentProvider) {
        found = true;
        return newProvider;
      } else {
        return provider;
      }
    });

  return found ? getNewLinks(results, socialLinks) : { ...socialLinks, [newProvider]: null } || {};
};

const SocialLinks: React.SFC<Props> = ({
  defaultSocialLinkProviders,
  socialLinks,
  updateSocialLinks,
  haveHandleField,
  labelAdd
}) => {
  const DEFAULT_PROVIDERS: Array<string> = defaultSocialLinkProviders.map((pro) => pro["provider-key"]);
  const selectedProviders: Array<string> | null = socialLinks && Object.keys(socialLinks);

  const formatSocialLinks = (provider: string, type: string, value: string) => {
    if (haveHandleField) {
      return {
        ...socialLinks,
        [provider]: { ...(socialLinks && socialLinks[provider]), [type]: value }
      };
    }
    return {
      ...socialLinks,
      [provider]: value
    };
  };

  const getSocialLinkValue = (provider: string | null, type: string) => {
    if (!socialLinks || !provider || !socialLinks[provider]) return "";
    if (haveHandleField) {
      return socialLinks[provider][type] || "";
    }
    return socialLinks[provider] || "";
  };

  const renderSelectProvider = (index: number) => {
    const selectedProvider = selectedProviders && selectedProviders[index];
    return (
      <Select
        value={[selectedProvider]}
        onChange={(linkProvider: string) => {
          let change = setLinkProviders(socialLinks, selectedProvider, linkProvider);
          updateSocialLinks(change);
        }}
        options={
          selectedProviders
            ? DEFAULT_PROVIDERS.filter((provider: string) => !selectedProviders.includes(provider))
            : DEFAULT_PROVIDERS
        }
        // * Condition for seeing google-plus link if it already exists
        getOptionLabel={(socialLinkProvider) => {
          if (socialLinkProvider === "google-plus-url") {
            defaultSocialLinkProviders = [
              ...defaultSocialLinkProviders,
              { name: "Google Plus", "provider-key": "google-plus-url" }
            ];
          }
          const providerData = defaultSocialLinkProviders.find((pro) => pro["provider-key"] === socialLinkProvider);
          return providerData ? providerData.name : "";
        }}
        getOptionValue={(socialLinkProvider) => socialLinkProvider || ""}
        menuPlacement="top"
        hideError={true}
      />
    );
  };

  const renderTextEntry = (type: string, columnIndex: number) => (index: number) => {
    const selectedProvider = selectedProviders && selectedProviders[index];
    return (
      <TextField
        value={getSocialLinkValue(selectedProvider, type)}
        onChange={(value: string) =>
          selectedProvider && updateSocialLinks(formatSocialLinks(selectedProvider, type, value))
        }
        errorEnabled={false}
        labelId={`column-label-${columnIndex}`}
      />
    );
  };

  return (
    <div
      className={styles["attributes-and-value-container"]}
      data-test-id="social-links-attributes-and-value-container">
      <RenderMultipleRow
        name="social-links"
        labelAdd={labelAdd}
        maxRows={DEFAULT_PROVIDERS.length}
        selectedLHSValues={selectedProviders || []}
        columnLabels={[
          t("settings.socialLinks.provider_label"),
          ...(haveHandleField ? [t("settings.socialLinks.handle_label")] : []),
          t("settings.socialLinks.link_label")
        ]}
        columnRenders={[
          renderSelectProvider,
          ...(haveHandleField ? [renderTextEntry("handle", 1)] : []),
          renderTextEntry("url", 2)
        ]}
        onDelete={(newSelectedProviders: Array<string>) => {
          const newSocialLinks = pick(socialLinks, newSelectedProviders);
          updateSocialLinks(newSocialLinks);
        }}
      />
    </div>
  );
};

export default SocialLinks;
