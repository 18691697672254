/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { createSelector } from "@reduxjs/toolkit";
import { FormDashboardState } from "./dashboard";

export const selectForms = createSelector(
  (state: FormDashboardState) => state.currentForms,
  (forms) => Object.values(forms).sort((a, b) => b.updatedAt - a.updatedAt)
);
