/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";
import {
  listCustomURLsAction,
  listCustomURLsSuccessAction,
  listCustomURLsFailureAction,
  updateCustomUrlsPagination,
  setCustomUrlsSearchTerm,
  setCurrentCustomURL,
  updateCurrentCustomURLAction,
  cancelSaveCurrentCustomURLAction,
  saveCustomURLAction,
  saveCustomURLSuccess,
  saveCustomURLFailure,
  deleteCustomURLAction,
  deleteCustomURLSuccess,
  deleteCustomURLFailure,
  toggleDeleteConfirmationAction
} from "./action-creators";
import {
  getCustomURLsList,
  AnyCustomURL,
  CustomURL,
  saveNewCustomURL,
  UnsavedCustomURL,
  updateCustomURL,
  deleteCustomURL
} from "api/custom-url";
import { navigate } from "utils/routes.utils";
import { PartialAppState, NEW_CUSTOM_URL, CUSTOM_URLS_PAGINATION } from "./state";
import { CUSTOM_URL_NEW_PATH, CUSTOM_URL_INDEX_PATH, CUSTOM_URL_EDIT_PATH } from "./routes";
import { ensureSlashAtStart, isExistingCustomURL } from "./utils";
import { validateCustomURL } from "./validate";
import { toClientValidationError } from "utils/validation.utils";
import { removeKeyFromObject } from "utils";
import { NOTIFICATION_SUCCESS } from "containers/page/actions";
import { CustomURLId } from "api/primitive-types";
import { t } from "i18n";

export const loadCustomURLs = (params: { limit?: number; offset?: number; searchTerm?: string }) => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(listCustomURLsAction());

  try {
    const { searchTerm, limit, offset } = params;
    const response = await getCustomURLsList(searchTerm, limit, offset);
    dispatch(listCustomURLsSuccessAction(response));
  } catch (error) {
    dispatch(listCustomURLsFailureAction(error));
  }
};

export const loadCustomURLsByPage = (pageNumber: number) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const pagination = getState().customURLs.pagination;
  const updatedPagination = {
    ...pagination,
    currentPage: pageNumber,
    offset: (pageNumber - 1) * pagination.itemsPerPage
  };
  dispatch(updateCustomUrlsPagination(updatedPagination));
  dispatch(loadCustomURLs({ offset: updatedPagination.offset }));
};

export const loadCustomURLsBySearchTerm = (searchTerm: string) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  dispatch(setCustomUrlsSearchTerm(searchTerm));
  dispatch(loadCustomURLs({ searchTerm }));
};

export const addNewCustomURL = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(setCurrentCustomURL(NEW_CUSTOM_URL));
  dispatch(navigate(CUSTOM_URL_NEW_PATH));
};

export const updateCurrentCustomURL = (customURL: AnyCustomURL) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(updateCurrentCustomURLAction(customURL));
};

export const cancelSaveCurrentCustomURL = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(cancelSaveCurrentCustomURLAction());
  dispatch(navigate(CUSTOM_URL_INDEX_PATH));
};

export const setCurrentCustomURLForEdit = (customURL: CustomURL) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(setCurrentCustomURL(customURL));
  dispatch(navigate(CUSTOM_URL_EDIT_PATH, { id: customURL.id }));
};

export const saveCurrentCustomURL = (customURL: AnyCustomURL) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(saveCustomURLAction());
  const errors = validateCustomURL(customURL);

  if (errors) {
    dispatch(saveCustomURLFailure(toClientValidationError<AnyCustomURL>(errors)));
    return;
  }

  try {
    const modifiedCustomURL = {
      ...customURL,
      "source-path": ensureSlashAtStart(customURL["source-path"]),
      "destination-path": ensureSlashAtStart(customURL["destination-path"]),
      "status-code": parseInt(customURL["status-code"])
    };

    isExistingCustomURL(modifiedCustomURL)
      ? await updateCustomURL(removeKeyFromObject("created-at", modifiedCustomURL) as CustomURL)
      : await saveNewCustomURL(modifiedCustomURL);

    dispatch(setCustomUrlsSearchTerm(""));
    dispatch(updateCustomUrlsPagination(CUSTOM_URLS_PAGINATION));
    dispatch(saveCustomURLSuccess());

    dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: {
        message: t("customURLs.notifications.save_success")
      }
    });
    dispatch(navigate(CUSTOM_URL_INDEX_PATH));
    await dispatch(loadCustomURLs({}));
  } catch (error) {
    const errorJson = JSON.parse(error.message);

    if (errorJson.status === "422" && errorJson.error.message === "Source path/slug already exists.") {
      dispatch(
        saveCustomURLFailure(
          toClientValidationError<UnsavedCustomURL>({
            "source-path": [t("customURLs.errors.source_path_exists")]
          })
        )
      );
    } else {
      dispatch(saveCustomURLFailure(errorJson));
    }
  }
};

export const editCustomURL = (id: CustomURLId) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const allCustomURLs = getState().customURLs.customURLs;
  /* eslint-disable */
  // Need a double equality check instead of a triple equality check for the find method below because id is a string and item.id is a number
  const customURL = allCustomURLs.find((customURLItem) => id == customURLItem.id);
  /* eslint-enable */
  dispatch(setCurrentCustomURLForEdit(customURL));
};

export const deleteCurrentCustomURL = () => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const customURL = getState().customURLs.app.currentCustomURL as CustomURL;
  const customURLId = customURL && customURL.id;

  dispatch(deleteCustomURLAction());

  try {
    await deleteCustomURL(customURLId);
    dispatch(deleteCustomURLSuccess());
    dispatch(toggleDeleteConfirmationAction());
    dispatch(setCustomUrlsSearchTerm(""));
    dispatch(updateCustomUrlsPagination(CUSTOM_URLS_PAGINATION));
    dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: {
        message: t("customURLs.notifications.delete_success")
      }
    });
    dispatch(navigate(CUSTOM_URL_INDEX_PATH));
    await dispatch(loadCustomURLs({}));
  } catch (error) {
    dispatch(deleteCustomURLFailure(error));
  }
};
