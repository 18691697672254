/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { connect } from "react-redux";

import Button from "../../../../components/button/button";
import { COLLECTIONS_PATH, COLLECTIONS_PREVIEW_PATH, COLLECTIONS_SELECTED_COLLECTION_PATH } from "../../routes";
import { t } from "i18n";
import { maybePublishAction, publishCollectionAction } from "pages/collections/async-action-creators";
import Header from "components/header/header";
import { navigateFn } from "utils/routes.utils";
import PreviewIcon from "../../../../components/icons/preview";
import styles from "./header.module.css";
import { selectIsDesktopSizeViewport } from "store/viewport";
import { notificationError } from "action-creators/notification";

export class CollectionsHeader extends React.Component<any, any> {
  handleToggle = () => {
    if (!this.props.collectionName.trim()) {
      this.props.onError();
      return;
    }
    const { collectionId, previewId, openPreview, closePreview, location } = this.props;
    const isPreviewOpen = location.pathname.includes("/preview");
    if (isPreviewOpen) {
      closePreview(collectionId);
    } else {
      openPreview(collectionId, previewId);
    }
  };

  render() {
    const { hasCollectionEditPermission, location, isDesktopSizeViewport, previewId } = this.props;
    const path = location && location.pathname.replace(/\/$/, "");
    //removes trailing slash
    const shouldShowPublishButton = path !== COLLECTIONS_PATH && hasCollectionEditPermission;

    return (
      <Header>
        {shouldShowPublishButton && (
          <>
            <Button
              classname={previewId ? styles["publish-button"] : ""}
              data-test-id="publish-button"
              type="primary"
              variant="success"
              disabled={!this.props.isCollectionModified}
              onClick={() => {
                this.props.collectionId === "new" || this.props.status === "scheduled"
                  ? this.props.maybePublish(this.props.collectionId)
                  : this.props.publishCollection();
              }}>
              {t("collections.publish")}
            </Button>
            {previewId && isDesktopSizeViewport && (
              <div className={styles["preview-icon"]} onClick={this.handleToggle} data-test-id="preview-icon">
                <PreviewIcon fill={"var(--mono-4)"} />
              </div>
            )}
          </>
        )}
      </Header>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    collectionId:
      state.collections.collectionPage.selectedCollection && state.collections.collectionPage.selectedCollection.id
        ? state.collections.collectionPage.selectedCollection.id
        : "new",
    status:
      state.collections.collectionPage.selectedCollection && state.collections.collectionPage.selectedCollection.status,
    hasCollectionEditPermission: state.features.hasCollectionEditPermission,
    location: ownProps.location,
    isCollectionModified: state.collections.ui.isCollectionModified,
    previewId: state.config["preview-id"],
    isDesktopSizeViewport: selectIsDesktopSizeViewport(state),
    collectionName:
      state.collections.collectionPage.selectedCollection && state.collections.collectionPage.selectedCollection.name
  };
};

const mapDispatchToProps = (dispatch) => {
  const navigate = navigateFn(dispatch);
  return {
    publishCollection: () => dispatch(publishCollectionAction()),
    maybePublish: (collectionId) => dispatch(maybePublishAction(collectionId)),
    openPreview: (collectionId, previewId) => {
      navigate(COLLECTIONS_PREVIEW_PATH, { collectionId: collectionId || "new", previewId: previewId });
    },
    closePreview: (collectionId) => {
      navigate(COLLECTIONS_SELECTED_COLLECTION_PATH, { collectionId: collectionId || "new" });
    },
    onError: () => dispatch(notificationError(t("collections.preview-title-missing")))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionsHeader);
