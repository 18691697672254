/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface H5IconProps {
  width?: string;
  height?: string;
  color?: string;
}

const H5: React.SFC<H5IconProps> = ({ width = "24", height = "24", color = "currentColor" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 24 24">
    <path d="M20.55,12a5.62,5.62,0,0,0-3.65-1h-1l.19-2.22H21V6.65H13.87l-.6,6.58h3a4.13,4.13,0,0,1,2.11.39A1.24,1.24,0,0,1,19,14.75a1.28,1.28,0,0,1-.58,1.12,2.79,2.79,0,0,1-1.6.4A6,6,0,0,1,15,16a5.44,5.44,0,0,1-1.54-.78L12.4,17.29a6.44,6.44,0,0,0,2,.93,9.54,9.54,0,0,0,2.43.32,6.22,6.22,0,0,0,2.7-.52,3.73,3.73,0,0,0,1.63-1.41,3.6,3.6,0,0,0,.53-1.94A3.28,3.28,0,0,0,20.55,12Z" />
    <path d="M7.36,9.22a4.43,4.43,0,0,0-1.61.28,3.51,3.51,0,0,0-1.25.79V6H1.9V18.34H4.5V13.9a2.51,2.51,0,0,1,.59-1.81,2.06,2.06,0,0,1,1.57-.61A1.71,1.71,0,0,1,8,12a2.37,2.37,0,0,1,.47,1.6v4.74h2.6V13.2a4,4,0,0,0-1-3A3.75,3.75,0,0,0,7.36,9.22Z" />
  </svg>
);

export default H5;
