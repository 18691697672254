/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, {
  ListPublisherIntegrationsSuccessAction,
  ListPublisherIntegrationsFailureAction,
  OnChangeOfIntegrationAction,
  ListSupportedIntegrationsSuccessAction,
  ListSupportedIntegrationsFailureAction,
  UpdateIntegrationFieldAction,
  StartLoadingWithNoErrorAction,
  StopLoadingWithNoErrorAction,
  EditIntegrationAction,
  CancelIntegrationAction,
  SetIntegrationSaveErrorsAction,
  UpdatePublisherSettings,
  UpdateIntegrationListByType,
  SetIntegrationType,
  SetIntegrationValidateInProgressAction,
  SetIntegrationValidateResultAction
} from "./actions";
import { Integrations, IntegrationsResponse, SupportedIntegrationsResponse } from "api/integrations";
import { IntegrationListByType, IntegrationType, Publisher } from "./state";

export function listPublisherIntegrationsSuccessAction(
  publisherIntegrationsAPIData: IntegrationsResponse
): ListPublisherIntegrationsSuccessAction {
  return { type: actions.LIST_PUBLISHER_INTEGRATIONS_SUCCESS, payload: { publisherIntegrationsAPIData } };
}

export function listPublisherIntegrationsFailureAction(error: Error): ListPublisherIntegrationsFailureAction {
  return { type: actions.LIST_PUBLISHER_INTEGRATIONS_FAILURE, payload: { error } };
}

export function listSupportedIntegrationsSuccessAction(
  supportedIntegrations: SupportedIntegrationsResponse
): ListSupportedIntegrationsSuccessAction {
  return { type: actions.LIST_SUPPORTED_INTEGRATIONS_SUCCESS, payload: { supportedIntegrations } };
}

export function listSupportedIntegrationsFailureAction(error: Error): ListSupportedIntegrationsFailureAction {
  return { type: actions.LIST_SUPPORTED_INTEGRATIONS_FAILURE, payload: { error } };
}

export function onChangeOfIntegrationAction(selectedIntegrationName: Integrations): OnChangeOfIntegrationAction {
  return { type: actions.SET_SELECTED_INTEGRATION, payload: { selectedIntegrationName } };
}

export function updateIntegrationFieldAction(key: string, changes: Object): UpdateIntegrationFieldAction {
  return { type: actions.UPDATE_SELECTED_INTEGRATION_FIELD, payload: { key, changes } };
}

export function startLoadingWithNoErrorAction(): StartLoadingWithNoErrorAction {
  return { type: actions.START_LOADING_WITH_NO_ERROR };
}

export function stopLoadingWithNoErrorAction(): StopLoadingWithNoErrorAction {
  return { type: actions.STOP_LOADING_WITH_NO_ERROR };
}

export function editIntegrationAction(integrationName: Integrations): EditIntegrationAction {
  return { type: actions.EDIT_INTEGRATION, payload: { integrationName } };
}

export function cancelOrCloseIntegrationAction(): CancelIntegrationAction {
  return { type: actions.CANCEL_INTEGRATION };
}
export function setIntegrationSaveErrorsAction(errors: Error): SetIntegrationSaveErrorsAction {
  return { type: actions.SET_INTEGRATION_SAVE_ERRORS, payload: { errors } };
}

export function setIntegrationValidateResultAction(isValid: boolean): SetIntegrationValidateResultAction {
  return { type: actions.SET_INTEGRATION_VALIDATE_RESULT, payload: { isValid } };
}

export function setIntegrationValidateInProgress(inProgress: boolean): SetIntegrationValidateInProgressAction {
  return { type: actions.SET_INTEGRATION_VALIDATE_IN_PROGRESS, payload: { inProgress } };
}

export function updatePublisherSettingsAction(settings: Partial<Publisher>): UpdatePublisherSettings {
  return { type: actions.UPDATE_PUBLISHER_SETTINGS, payload: settings };
}

export function updateIntegrationListByTypeAction(
  integrationListByType: IntegrationListByType
): UpdateIntegrationListByType {
  return { type: actions.UPDATE_INTEGRATION_LIST_BY_TYPE, payload: integrationListByType };
}

export function setIntegrationTypeAction(type: IntegrationType): SetIntegrationType {
  return { type: actions.SET_INTEGRATION_TYPE, payload: type };
}
