/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, {
  SocialAccountsRequestAction,
  SocialAccountsSuccessAction,
  SocialAccountsFailureAction,
  AddNewConnection,
  CancelAddingConnection,
  OnChangeOFService
} from "./actions";
import { SocialAccount } from "api/social-accounts";

export function socialAccountsRequestAction(): SocialAccountsRequestAction {
  return { type: actions.LIST_SOCIAL_ACCOUNTS_REQUEST };
}

export function socialAccountsSuccessAction(socialAccounts: SocialAccount[]): SocialAccountsSuccessAction {
  return { type: actions.LIST_SOCIAL_ACCOUNTS_SUCCESS, payload: { socialAccounts } };
}

export function socialAccountsFailureAction(error: Error): SocialAccountsFailureAction {
  return { type: actions.LIST_SOCIAL_ACCOUNTS_FAILURE, payload: { error } };
}

export function addNewConnection(): AddNewConnection {
  return { type: actions.ADD_NEW_CONNECTION };
}

export function cancelAddingConnection(): CancelAddingConnection {
  return { type: actions.CANCEL_NEW_CONNECTION };
}
export function onChangeOFService(selectedService: string): OnChangeOFService {
  return { type: actions.SET_SELECTED_SERVICE, payload: { selectedService } };
}
