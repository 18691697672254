/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { get } from "lodash";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { t } from "i18n";
import { produce } from "immer";

import wrapPage from "containers/page/page";
import ContentHeader from "../../../components/content-header/content-header";
import { Switch } from "components/switch/switch";
import { loadPromotionalMessages, savePromotionalMessages } from "./async-action-creators";
import { onConfigChangeAction } from "./action-creators";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "./state";
import { Config as ConfigType } from "api/promotional-messages";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import { LoaderState } from "behaviors/loader/state";
import Loader from "./loader";
import PromotionalMessagesHeader from "./promotional-messages-header";
import ProseMirrorTextArea from "components/prosemirror-text-area/prosemirror-text-area";
import classnames from "classnames/bind";
import styles from "./promotional-messages.module.css";
import FieldLabel from "components/field-label/field-label";

const cx = classnames.bind(styles);
interface ConfigProps {
  config: ConfigType;
  onConfigChange: (config: ConfigType) => void;
  onToggleChange: (config: ConfigType) => void;
}

export const Config: React.SFC<ConfigProps> = ({ config, onConfigChange, onToggleChange }) => {
  return (
    <div className={styles["promotional-messages"]}>
      <FieldLabel label={t("promotional_messages.default_message")} classname="promotional-messages-label" />
      <Switch
        id="default"
        checked={get(config, ["default", "toggle"], false)}
        onChange={(value) =>
          onToggleChange(
            produce(config, (draft) => {
              draft.default.toggle = value;
            })
          )
        }
      />
      <ProseMirrorTextArea
        onChange={(htmlString) => onConfigChange({ ...config, default: { ...config.default, text: htmlString } })}
        value={get(config, ["default", "text"], "")}
        classname="promotional-messages-prosemirror-text-area-editor-wrapper"
      />
      <FieldLabel label={t("promotional_messages.label_syndicated_stories")} classname="promotional-messages-label" />
      <Switch
        id="syndicated"
        checked={get(config, ["sources", "syndicated", "toggle"], false)}
        onChange={(value) =>
          onToggleChange(
            produce(config, (draft) => {
              draft.sources.syndicated.toggle = value;
            })
          )
        }
      />
      <ProseMirrorTextArea
        onChange={(htmlString) =>
          onConfigChange(
            produce(config, (draft) => {
              draft.sources.syndicated.text = htmlString;
            })
          )
        }
        value={get(config, ["sources", "syndicated", "text"], "")}
        classname="promotional-messages-prosemirror-text-area-editor-wrapper"
      />
      <FieldLabel label={t("promotional_messages.label_generated_content")} classname="promotional-messages-label" />
      <Switch
        id="ugc"
        checked={get(config, ["sources", "ugc", "toggle"], false)}
        onChange={(value) =>
          onToggleChange(
            produce(config, (draft) => {
              draft.sources.ugc.toggle = value;
            })
          )
        }
      />
      <ProseMirrorTextArea
        onChange={(htmlString) =>
          onConfigChange(
            produce(config, (draft) => {
              draft.sources.ugc.text = htmlString;
            })
          )
        }
        value={get(config, ["sources", "ugc", "text"], "")}
        classname="promotional-messages-prosemirror-text-area-editor-wrapper"
      />
    </div>
  );
};

interface StateProps {
  promotionalMessagesConfig: ConfigType | null;
  loader: LoaderState;
}

interface DispatchProps {
  loadPromotionalMessages: () => void;
  onConfigChange: (config: ConfigType) => void;
  onToggleChange: (config: ConfigType) => void;
}

type Props = StateProps & DispatchProps & { isBannerPresent: boolean };

class PromotionalMessages extends React.Component<Props> {
  componentDidMount() {
    this.props.loadPromotionalMessages();
  }
  render() {
    const { loader, promotionalMessagesConfig, onConfigChange, onToggleChange } = this.props;
    return (
      <React.Fragment>
        <main className={cx("page-container", "configure-page")}>
          <ContentHeader title={t("promotional_messages.title")} description={t("promotional_messages.description")} />
          <LoaderWrapper component={Loader} loader={loader}>
            {promotionalMessagesConfig && (
              <Config
                config={promotionalMessagesConfig}
                onConfigChange={onConfigChange}
                onToggleChange={onToggleChange}
              />
            )}
          </LoaderWrapper>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    promotionalMessagesConfig: state.promotionalMessages.promotionalMessagesConfig,
    loader: state.promotionalMessages.ui.main
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    loadPromotionalMessages: () => dispatch(loadPromotionalMessages()),
    onConfigChange: (config) => dispatch(onConfigChangeAction(config)),
    onToggleChange: (config) => dispatch(savePromotionalMessages(config))
  };
};

export default compose<any, any, any>(
  connect(mapStateToProps, mapDispatchToProps),
  wrapPage({ HeaderComponent: PromotionalMessagesHeader })
)(PromotionalMessages);

export { PromotionalMessages };
