/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Configuration } from "api/select-features";
import { Template } from "api/content-templates";
import { TemplateFieldErrors } from "./pages/configure/story-templates/shared-types";
import { GeneralSettings, SocialLinks } from "api/settings";
import { SEOMetadata } from "api/seo-metadata";
import { LoaderState, INITIAL_LOADER_STATE } from "behaviors/loader/state";
import { AccessLevelErrors } from "./pages/configure/access-levels/shared-types";
import { Features, Member, Publisher, StorySlugConfig } from "api/route-data/route-data";
import { EventDetails } from "store/webhook/webhook";

export interface PartialAppState {
  settingsPage: State;
  config: {
    publisher: Publisher | {};
    member: Member | {};
    publisherWideBannerMessage: string | null;
    "default-social-link-providers": Array<{ [key: string]: string }>;
    "trigger-events"?: EventDetails;
    "story-slug": StorySlugConfig;
  };
  features: Features;
  ui: Ui;
}
interface Ui {
  isSettingsModified: boolean;
  isAccessLevelConfigModified: boolean;
  errors: Object;
  storyTemplateErrors: TemplateFieldErrors | null;
  accessLevelErrors: AccessLevelErrors | null;
  main: LoaderState;
  uploading: boolean;
  imageError: string;
  isConfirmationModelVisible: boolean;
  selectedAccessLevelValueIndexToBeDeleted: null | number;
  storySlug: {
    isSaveButtonEnabled: boolean;
  };
}

export enum StorySlugTypes {
  Headline = "headline",
  Alphanumeric = "alphanumeric"
}

export interface StorySlugSettingsState {
  type: StorySlugTypes | null;
}

export interface State {
  noOfVisibleCards: number;
  configure: Array<Configuration>;
  accessLevel: Configuration | null;
  accessLevelValue: string;
  features: Array<Configuration>;
  storyElements: Array<Configuration>;
  storyTemplates: Array<Configuration>;
  customStoryTemplates: {};
  selectedStoryTemplate: Template | null;
  newStoryTemplate: Template | null;
  generalSettings: GeneralSettings | null;
  socialLinks: SocialLinks | null;
  seoMetadata: SEOMetadata | null;
  storySlug: StorySlugSettingsState;
  ui: Ui;
}

export const INITIAL_STATE: State = {
  generalSettings: null,
  socialLinks: null,
  seoMetadata: null,
  configure: [],
  features: [],
  storyElements: [],
  storyTemplates: [],
  accessLevel: null,
  accessLevelValue: "",
  noOfVisibleCards: 0,
  customStoryTemplates: {},
  selectedStoryTemplate: null,
  newStoryTemplate: null,
  storySlug: {
    type: null
  },
  ui: {
    isAccessLevelConfigModified: false,
    isSettingsModified: false,
    errors: {},
    storyTemplateErrors: null,
    accessLevelErrors: null,
    main: INITIAL_LOADER_STATE,
    uploading: false,
    imageError: "",
    isConfirmationModelVisible: false,
    selectedAccessLevelValueIndexToBeDeleted: null,
    storySlug: {
      isSaveButtonEnabled: false
    }
  }
};
