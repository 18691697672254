/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose } from "redux";
import { t } from "i18n";

import wrapPage from "containers/page/page";
import Analytics from "./analytics";
import TopSections from "pages/analytics/components/top-sections/top-sections";

export const SectionAnalytics: React.SFC<any> = (props) => (
  <Analytics {...props} title={t("analytics.section_analytics")} Component={TopSections} />
);

export default compose(wrapPage())(SectionAnalytics);
