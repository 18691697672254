/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import { connect } from "react-redux";
import styles from "./banner.module.css";
import { selectIsDesktopSizeViewport, PartialAppState } from "store/viewport";

import Button from "components/button/button";
import Close from "components/icons/close";
import { get } from "lodash";

interface Props {
  message: string | null;
  type?: string;
  closable?: boolean;
  autoClose?: boolean;
  timestamp?: string;
  onClose?: () => void;
  isDesktopSizeViewport: boolean;
  isSliderWindowOpen: boolean;
}

const cx = classnames.bind(styles);

const MAX_BANNER_CHARS_RATIO_WITH_SLIDER_OPEN = 0.7;

class Banner extends React.Component<Props> {
  timer: NodeJS.Timeout | null;

  constructor(props: Props) {
    super(props);
    this.timer = null;
  }

  static defaultProps: Pick<Props, "type" | "closable" | "autoClose"> = {
    type: "info",
    closable: false,
    autoClose: false
  };

  turnAutoCloseOff() {
    this.timer && clearTimeout(this.timer);
  }

  setUpAutoClose() {
    const BANNER_AUTO_CLOSE_TIME = 5000;

    this.turnAutoCloseOff();
    this.timer = setTimeout(() => {
      this.props.onClose && this.props.onClose();
    }, BANNER_AUTO_CLOSE_TIME);
  }

  componentDidUpdate(prevProps: Props, prevState: Props) {
    this.timer && !this.props.autoClose && this.turnAutoCloseOff();

    if (this.props !== prevProps && this.props.autoClose) {
      this.setUpAutoClose();
    }
  }

  componentDidMount() {
    if (this.props.autoClose) {
      this.setUpAutoClose();
    }
  }

  componentWillUnmount() {
    this.turnAutoCloseOff();
  }

  getWindowSize() {
    const { innerWidth } = window;
    if (this.props.isSliderWindowOpen) {
      return innerWidth * MAX_BANNER_CHARS_RATIO_WITH_SLIDER_OPEN;
    } else {
      return innerWidth;
    }
  }

  render() {
    const bannerClass = cx("banner-container", {
      "banner-move": !this.props.closable && this.props.message && this.props.message.length * 9 > this.getWindowSize(),
      "banner-error": this.props.type === "error",
      "banner-info": this.props.type === "info",
      "banner-success": this.props.type === "success",
      "banner-with-slider": this.props.isSliderWindowOpen
    });

    return (
      <div className={bannerClass}>
        <p className={styles["banner-message"]} dangerouslySetInnerHTML={{ __html: this.props.message || "" }} />
        {this.props.isDesktopSizeViewport && this.props.timestamp && (
          <time className={styles["banner-timestamp"]}>Time: {this.props.timestamp}</time>
        )}
        {this.props.closable && (
          <Button type="transparent" onClick={() => this.props.onClose && this.props.onClose()}>
            <Close color="#fff" height="16" width="16" />
          </Button>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: PartialAppState) {
  return {
    isDesktopSizeViewport: selectIsDesktopSizeViewport(state),
    isSliderWindowOpen: get(state, ["slider", "sliderWindow", "open"], false)
  };
}

export default connect(mapStateToProps, () => {})(Banner);

export { Banner };
