/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import wretch from "wretch";

export type CollectionLayoutOptions = {
  name: string;
} & (
  | {
      type: "number";
      default?: number;
    }
  | {
      type: "string";
      default?: string;
    }
  | {
      type: "boolean";
      default?: boolean;
    }
);

export interface CollectionLayout {
  name: string;
  display: string;
  options: Array<CollectionLayoutOptions>;
}

export interface CollectionTemplateOptions {
  "collection-layouts": Array<CollectionLayout>;
}

export const getCollectionTemplates = async (publisher: {
  "website-url": string;
}): Promise<CollectionTemplateOptions> => {
  const websiteUrl = publisher["website-url"];
  // fetch using cors first(for supporting clients), if that fails fallback to sketches proxy
  try {
    return await wretch()
      .url(`${websiteUrl}/template-options.json`)
      .get()
      .json();
  } catch {
    return await wretch()
      .url(`/sketches/template-options.json`)
      .get()
      .json();
  }
};
