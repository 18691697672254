/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const ExternalUrl = ({ width = "24", height = "24", color = "#c6ccd5" }) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={color}
    viewBox="0 0 24 24">
    <path d="M16.38 11a.34.34 0 0 1 .39-.06.35.35 0 0 1 .23.34v7.38a1.5 1.5 0 0 1-1.5 1.5h-11A1.5 1.5 0 0 1 3 18.65v-11a1.5 1.5 0 0 1 1.5-1.5h10.88a.35.35 0 0 1 .34.23.31.31 0 0 1-.1.39l-.24.23a.3.3 0 0 1-.26.13H4.5a.48.48 0 0 0-.5.5v11a.48.48 0 0 0 .5.5h11a.48.48 0 0 0 .5-.5v-7.11a.41.41 0 0 1 .12-.28zm-7.26 4.87l.16.15a.37.37 0 0 0 .28.13.28.28 0 0 0 .25-.15l10.1-10.07v2.84a.33.33 0 0 0 .11.27.36.36 0 0 0 .26.11h.31a.38.38 0 0 0 .41-.38V4.52a.36.36 0 0 0-.11-.26.37.37 0 0 0-.27-.11h-4.24a.37.37 0 0 0-.27.11.36.36 0 0 0-.11.26v.32a.36.36 0 0 0 .11.26.33.33 0 0 0 .27.11h2.81L9.12 15.34a.29.29 0 0 0-.12.25.41.41 0 0 0 .12.28z" />
  </svg>
);

export default ExternalUrl;
