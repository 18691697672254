/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Attachment = ({ width = 24, height = 24, color = "#c6ccd5" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} fill={color}>
    <path d="M9.6 19a2.82 2.82 0 0 1-3.91.35 2.66 2.66 0 0 1-.36-3.81L9.19 11a2.8 2.8 0 0 1 1.88-1 2.85 2.85 0 0 1 2 .63l.68.5.64-.81-.66-.49a3.9 3.9 0 0 0-5.39.48l-3.82 4.57a3.62 3.62 0 0 0-.86 2.73A3.66 3.66 0 0 0 5 20.14a3.85 3.85 0 0 0 2.46.86 3.89 3.89 0 0 0 3-1.34l2.33-2.73-.81-.66z" />
    <path d="M19 3.86a3.89 3.89 0 0 0-5.38.48l-2.48 2.77.79.67L14.4 5a2.82 2.82 0 0 1 3.91-.35 2.66 2.66 0 0 1 .36 3.81L14.81 13a2.84 2.84 0 0 1-3.9.36l-.38-.36-.7.76.4.35a3.91 3.91 0 0 0 5.39-.48l3.86-4.51a3.62 3.62 0 0 0 .86-2.73A3.66 3.66 0 0 0 19 3.86" />
  </svg>
);

export default Attachment;
