/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const TASKS_INDEX_PATH = "/user/tasks";
export const TASK_PATH = "/user/tasks/:id";
export const TASK_NEW_IMAGE_PATH = `${TASK_PATH}/image/:imageId`;
