/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useMemo } from "react";
import t from "i18n";
import { get, isEmpty } from "lodash";
import { Transformers } from "utils";
import Close from "components/icons/close";
import classnames from "classnames/bind";
import styles from "./active-filters.module.css";

const cx = classnames.bind(styles);

interface Props {
  filters: { [key: string]: any };
  resetFilter: (filterKey) => void;
  clearAllFilters?: () => void;
  transformers?: Transformers<{ [key: string]: any }>;
  classname?: string;
}

export const ActiveFilters: React.FC<Props> = ({ filters, resetFilter, clearAllFilters, transformers, classname }) => {
  const activeFilters = useMemo(
    () =>
      Object.entries(filters).reduce((activeFilters, [key, value]) => {
        const transformer = get(transformers, [key], (key, value) => [key, value]);
        const [transformedKey, transformedValue] = transformer(key, value);
        return transformedValue && transformedKey
          ? [...activeFilters, [[key, transformedKey], transformedValue]]
          : activeFilters;
      }, []),
    [filters, transformers]
  );

  if (isEmpty(activeFilters)) return null;

  return (
    <div className={cx("active-filters", classname)}>
      {activeFilters.map(([[key, transformedKey], transformedValue]) => {
        return (
          <div className={styles["filter"]}>
            <div className={styles["filter-key"]}>{`${transformedKey}:`}</div>
            <div className={styles["filter-value"]}>{transformedValue}</div>
            <div className={styles["filter-remove"]} onClick={() => resetFilter(key)}>
              <Close width={"12"} height={"12"} />
            </div>
          </div>
        );
      })}
      {clearAllFilters && (
        <div className={styles["filter-clear-all"]} onClick={clearAllFilters} data-test-id="filter-clear-all">
          {t("common.clear-all")}
        </div>
      )}
    </div>
  );
};

export default ActiveFilters;
