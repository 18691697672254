/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import CustomURL from "./custom-url";
import { CUSTOM_URL_EDIT_PATH, CUSTOM_URL_INDEX_PATH, CUSTOM_URL_NEW_PATH } from "./routes";
import { CustomURLsInspectorType } from "./state";

export const Router = () => (
  <React.Fragment>
    <Switch>
      <Route exact path={CUSTOM_URL_INDEX_PATH} component={CustomURL} />
      <Route
        exact
        path={CUSTOM_URL_NEW_PATH}
        render={(props) => <CustomURL {...props} inspectorType={CustomURLsInspectorType.Create} />}
      />
      <Route
        exact
        path={CUSTOM_URL_EDIT_PATH}
        render={(props) => <CustomURL {...props} inspectorType={CustomURLsInspectorType.Edit} />}
      />
    </Switch>
  </React.Fragment>
);
