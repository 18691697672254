/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";

import Spinner from "components/spinner/spinner";
import { LoaderState } from "behaviors/loader/state";
import { BreakingNewsNotFound } from "../errors";

interface LoaderProps {
  loader: LoaderState;
}

const Loader: React.SFC<LoaderProps> = ({ loader: { loading, error } }) => {
  if (loading) {
    return <Spinner message={t("spinner.loading")} />;
  }
  if (error && error instanceof BreakingNewsNotFound) {
    return (
      <React.Fragment>
        <p>{error.message}</p>
      </React.Fragment>
    );
  }

  return <p>{t("breaking-news.server-error")}</p>;
};

export default Loader;
