/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Reference = ({ width = "24", height = "24", color = "#c6ccd5" }) => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={color}
    viewBox="0 0 24 24">
    <path
      d="M18.6 3H5.4A2.41 2.41 0 0 0 3 5.4v13.2A2.41 2.41 0 0 0 5.4 21h13.2a2.41 2.41 0 0 0 2.4-2.4V5.4A2.41 2.41 0 0 0 18.6 3zm-2.06 1.22v6l-1.69-1.58-1.69 1.57v-6zM20 18.6a1.38 1.38 0 0 1-1.4 1.4H5.4A1.38 1.38 0 0 1 4 18.6V5.4A1.38 1.38 0 0 1 5.4 4h6.74v8.53L14.85 10l2.71 2.52V4h1A1.38 1.38 0 0 1 20 5.4z"
      fillRule="evenodd"
    />
  </svg>
);

export default Reference;
