/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface NumberedListIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const NumberedList: React.SFC<NumberedListIconProps> = ({ width = "24", height = "24", color = "currentColor" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      d="M6.71,20.65a.92.92,0,0,1,.25.66,1.1,1.1,0,0,1-.16.56,1.13,1.13,0,0,1-.49.42,1.94,1.94,0,0,1-.8.15,2.57,2.57,0,0,1-.73-.1,1.8,1.8,0,0,1-.61-.27l.32-.62a1.53,1.53,0,0,0,.46.23,1.91,1.91,0,0,0,.54.08A.85.85,0,0,0,6,21.64a.38.38,0,0,0,.17-.33c0-.29-.22-.43-.65-.43H5.12v-.53l.72-.82H4.35v-.65H6.8v.53l-.79.9A1.16,1.16,0,0,1,6.71,20.65ZM6.29,13a1.94,1.94,0,0,0,.46-.57,1.21,1.21,0,0,0,.12-.53.92.92,0,0,0-.16-.55A1,1,0,0,0,6.25,11a1.59,1.59,0,0,0-.68-.13,1.94,1.94,0,0,0-.82.17,1.5,1.5,0,0,0-.57.48l.59.38a.85.85,0,0,1,.31-.26,1,1,0,0,1,.42-.09.67.67,0,0,1,.41.11.4.4,0,0,1,.15.33.67.67,0,0,1-.08.29,1.32,1.32,0,0,1-.29.35L4.34,13.86v.52H7v-.66H5.48ZM5.42,6.38h.81V2.88H4.72v.65h.7Zm2.76,14.8H19v-1.8H8.18Zm0-7.5H19v-1.8H8.18Zm0-9.3v1.8H19V4.38Z"
    />
  </svg>
);

export default NumberedList;
