/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RouteComponentProps } from "react-router";

import Select from "components/select/select";
import ContentHeader from "pages/settings/components/content-header/content-header";
import Loader from "./loader";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import wrapPage from "containers/page/page";
import { t } from "i18n";

import styles from "./collections-configure.module.css";
import { CONFIGURE_COLLECTIONS_PATH } from "pages/settings/routes";
import { loadCollectionConfig, updateCollectionConfig, resetConfig } from "./async-action-creators";
import { PartialAppState } from "./state";
import { CollectionEntityType } from "api/collection-config";
import CollectionConfigureHeader from "./components/collections-configure-header";
import TextField from "components/text-field/text-field";
interface StateProps {
  enabledEntityTypes: CollectionEntityType[] | null;
  allEntityTypes: CollectionEntityType[];
  storyLimit: number | null;
}

interface DispatchProps {
  loadCollectionConfig: () => void;
  updateCollectionConfig: (config: { enabledEntityTypes?: CollectionEntityType[]; storyLimit?: number }) => void;
  resetConfig: () => void;
}

type CollectionsConfigureProps = {
  activeMenu: string;
  showSubmenu: string;
  isBannerPresent: boolean;
} & StateProps &
  DispatchProps &
  RouteComponentProps<any>;

export class CollectionsConfigure extends React.Component<CollectionsConfigureProps> {
  render() {
    const {
      allEntityTypes,
      enabledEntityTypes,
      updateCollectionConfig,

      storyLimit
    } = this.props;
    return (
      <React.Fragment>
        <main>
          <ContentHeader
            title={t("settings.collections.page-title")}
            description={t("settings.collections.page-description")}
          />
          <LoaderWrapper className="collections-configure-progress-main-area" component={Loader} loader={true}>
            <div className={styles["configure-card"]}>
              <div className={styles["collections-config"]}>
                <div className={styles["collections-config-category-details-container"]}>
                  <h3 className={styles["collections-config-title"]}>{t("settings.collections.entity-type-title")}</h3>
                  <p className={styles["collections-config-description"]}>
                    {t("settings.collections.entity-type-description")}
                  </p>
                </div>
                <div className={styles["collections-config-fields-container"]}>
                  <Select
                    name="Entity Types"
                    value={enabledEntityTypes}
                    options={allEntityTypes}
                    onChange={(enabledEntityTypes: CollectionEntityType[]) =>
                      updateCollectionConfig({ enabledEntityTypes })
                    }
                    getOptionLabel={(entityType) => entityType}
                    getOptionValue={(entityType) => entityType}
                    isMulti={true}
                  />
                </div>
                <div className={styles["collections-config-category-details-container"]}>
                  <h3 className={styles["collections-config-title"]}>{t("settings.collections.story-limit")}</h3>
                  <p className={styles["collections-config-description"]}>
                    {t("settings.collections.story-limit-description")}
                  </p>
                </div>
                <div className={styles["collections-config-fields-container"]}>
                  <TextField
                    label=""
                    value={(storyLimit && storyLimit.toString()) || ""}
                    onChange={(storyLimit: string) => updateCollectionConfig({ storyLimit: parseInt(storyLimit) })}
                  />
                </div>
              </div>
            </div>
          </LoaderWrapper>
        </main>
      </React.Fragment>
    );
  }
}

export class CollectionsConfigureWithRoutes extends React.Component<CollectionsConfigureProps> {
  componentDidMount() {
    this.onEnter();
  }

  onEnter() {
    switch (this.props.match.path) {
      case CONFIGURE_COLLECTIONS_PATH:
        this.props.loadCollectionConfig();
        break;

      default:
        break;
    }
  }

  componentWillUnmount() {
    this.props.resetConfig();
  }

  render() {
    return <CollectionsConfigure {...this.props} />;
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    allEntityTypes: state.collectionsConfig.allEntityTypes,
    enabledEntityTypes: state.collectionsConfig.enabledEntityTypes || null,
    storyLimit: state.collectionsConfig.storyLimit || null
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProps => {
  return {
    loadCollectionConfig: () => dispatch(loadCollectionConfig()),
    updateCollectionConfig: (config: { enabledEntityTypes?: CollectionEntityType[]; storyLimit?: number }) =>
      dispatch(updateCollectionConfig(config)),
    resetConfig: () => dispatch(resetConfig())
  };
};

export default compose<any>(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  wrapPage({ HeaderComponent: CollectionConfigureHeader })
)(CollectionsConfigureWithRoutes);
