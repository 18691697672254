/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AmpConfig } from "api/amp-config";

enum actions {
  LIST_AMP_CONFIG = "@@settings/LIST_AMP_CONFIG",
  LIST_AMP_CONFIG_SUCCESS = "@@settings/LIST_AMP_CONFIG_SUCCESS",
  LIST_AMP_CONFIG_FAILURE = "@@settings/LIST_AMP_CONFIG_FAILURE",

  UPDATE_AMP_CONFIG = "@@settings/UPDATE_AMP_CONFIG",
  SET_ERRORS = "@@settings/SET_ERRORS"
}

export default actions;

export type ListAmpConfigAction = {
  type: actions.LIST_AMP_CONFIG;
};
export type ListAmpConfigSuccessAction = {
  type: actions.LIST_AMP_CONFIG_SUCCESS;
  payload: { ampConfig: AmpConfig };
};
export type ListAmpConfigFailureAction = {
  type: actions.LIST_AMP_CONFIG_FAILURE;
  payload: { error: Error };
};
export type UpdateAmpConfigAction = {
  type: actions.UPDATE_AMP_CONFIG;
  payload: { key: string; changes: Object };
};
export type SetErrorsAction = {
  type: actions.SET_ERRORS;
  payload: { errors: Object };
};

export type ActionTypes =
  | ListAmpConfigAction
  | ListAmpConfigSuccessAction
  | ListAmpConfigFailureAction
  | UpdateAmpConfigAction
  | SetErrorsAction;
