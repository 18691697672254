/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface UnderlineIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const Underline: React.SFC<UnderlineIconProps> = ({ width = "24", height = "24", color = "currentColor" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 24 24">
    <path
      fillRule="nonzero"
      d="M11.912 17.944c-2.176 0-3.872-.624-5.088-1.872C5.608 14.824 5 13.008 5 10.624V4h1.776v6.552c0 1.952.44 3.408 1.32 4.368.88.96 2.152 1.44 3.816 1.44 1.68 0 2.96-.48 3.84-1.44.88-.96 1.32-2.416 1.32-4.368V4H18.8v6.624c0 2.384-.604 4.2-1.812 5.448-1.208 1.248-2.9 1.872-5.076 1.872z"
    />
    <path d="M5 19h13.8v1.8H5z" />
  </svg>
);

export default Underline;
