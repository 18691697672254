/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { PublisherId, Timestamp, MemberId } from "./primitive-types";
import { Integrations } from "api/integrations";
import { StorySlugSettingsState } from "pages/settings/initial-state";

export interface ValidationError {
  [key: string]: {
    code: string;
  };
}

export type ThemeAttributes = { [key: string]: any };

export interface WebsiteLanguage {
  "iso-code": string;
  "ietf-code": string;
  name: string;
  direction: string;
}

export interface EditorLanguage {
  locale: string;
  "locale-display": string;
  dir: string;
}

export interface PushNotification {
  "icon-url": string | null;
  provider: {
    web: Integrations | null;
    mobile: Integrations | null;
  };
}

export interface Publisher {
  id: PublisherId;
  name: string;
  title: string;
  "editor-url": string;
  "website-url": string;
  "alt-website-url": string;
  type: string | null;
  "created-at": Timestamp;
  "created-by": MemberId;
  "demo-end-date": Timestamp;
  "story-slug-format": string;
  "section-url-format": string;
  "website-language": Partial<WebsiteLanguage>;
  "editor-language": Partial<EditorLanguage>;
  "push-notification": PushNotification;
  "story-slug": StorySlugSettingsState;
}

export type UnsavedPublisher = Omit<Publisher, "id" | "created-at" | "created-by" | "demo-end-date">;

export interface SitemapResponse {
  message: string;
}

export const getPublisher = (): Promise<Publisher> => {
  return client
    .url("/api/publisher")
    .get()
    .json()
    .then(({ data }) => data);
};

export const updatePublisher = (data: Partial<UnsavedPublisher>): Promise<Publisher> => {
  return client
    .url("/api/publisher")
    .json(data)
    .patch()
    .json();
};

export const regenerateSitemap = (): Promise<SitemapResponse> => {
  return client
    .url("/api/publisher/regenerate-sitemap")
    .post()
    .json();
};

export const uploadThemeAttributes = (themeAttributes: ThemeAttributes) => {
  return client
    .url("/api/theme-attributes")
    .json({ "theme-attributes": themeAttributes })
    .post()
    .json();
};
