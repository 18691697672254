/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useRef } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import InfiniteScrollList from "components/infinite-scroll-list/infinite-scroll-list";
import Inspector from "components/inspector/inspector";
import Button from "components/button/button";
import Spinner from "components/spinner/spinner";
import SearchBar from "components/search-bar/search-bar";
import { Panel } from "components/tabs/tabs";
import Filter from "components/icons/filter";
import { SelectTabs } from "pages/workspace/components/select-tabs/select-tabs";
import CollectionItemList from "pages/collections/components/collection-item-list/collection-item-list";
import Filters from "pages/collections/components/filters/filters";
import {
  applyFilterAction,
  resetFilterAction,
  toggleCollectionItemAction,
  searchCollectionItemsAction
} from "../../async-action-creators";
import { actions } from "../../actions";
import { PartialAppState, FilterInput, LHSTab, SearchResults, PageViews } from "pages/collections/initial-state";
import { COLLECTIONS_SELECTED_COLLECTION_PATH } from "../../routes";
import { CollectionId } from "api/primitive-types";
import { CollectionItemWrapper, DataSource, AnyCollection } from "api/story-collection";
import { Section } from "api/route-data/route-data";
import { CollectionTemplateOptions } from "api/template-options";
import { Attribute } from "api/story-attributes";
import { isExistingCollection } from "../../utils";
import { navigateFn } from "../../../../utils/routes.utils";
import styles from "./all-items-inspector.module.css";

interface StateProps {
  isAllItemsFiltersExpanded: boolean;
  filters: FilterInput;
  selectedCollection: AnyCollection;
  sections: Section[];
  selectedLaneTab: LHSTab;
  searchResults: SearchResults;
  templateOptions: CollectionTemplateOptions;
  attributes: Attribute[];
  hasCollectionRankNestedCollectionPermission: boolean;
  storiesPageViews: PageViews | null;
  stagingSelectedCollection: AnyCollection;
}

interface DispatchProps {
  closeAllItemsInspector(collectionId: CollectionId | "new"): void;
  toggleAdvancedSearch(): void;
  toggleCollectionItem: (item: CollectionItemWrapper) => void;
  applyFilter: () => void;
  updateFilter: (key: string, value: any) => void;
  resetFilter: () => void;
  switchLaneTab: (tab: LHSTab) => void;
  searchCollectionItems: (offset?: number, isScheduledTab?: boolean, showNotification?: boolean) => void;
}

type Props = StateProps &
  DispatchProps & {
    isActive: boolean;
  };

const AllItemsInspector: React.FC<Props> = ({
  isActive,
  closeAllItemsInspector,
  stagingSelectedCollection,
  isAllItemsFiltersExpanded,
  filters,
  updateFilter,
  selectedCollection,
  toggleAdvancedSearch,
  sections,
  applyFilter,
  selectedLaneTab,
  switchLaneTab,
  toggleCollectionItem,
  searchResults,
  resetFilter,
  searchCollectionItems,
  templateOptions,
  attributes,
  hasCollectionRankNestedCollectionPermission,
  storiesPageViews
}) => {
  const tabState = searchResults[selectedLaneTab];
  const storyOptions = [
    {
      label: t("collections.stories"),
      value: "story",
      badge: searchResults["story"].hits
    },
    {
      label: t("collections.scheduled_stories"),
      value: "draft-story",
      badge: searchResults["draft-story"].hits
    }
  ];

  const inspectorRef = useRef<HTMLDivElement>(null);

  return (
    <Inspector
      title={t("collections.content_list")}
      onClose={() => {
        isExistingCollection(stagingSelectedCollection)
          ? closeAllItemsInspector(stagingSelectedCollection.id)
          : closeAllItemsInspector("new");
      }}
      reference={inspectorRef}
      isActive={isActive}
      actionButtonLabel=""
      onActionButtonClick={() => {}}>
      <React.Fragment>
        <div className={styles["collection-search-actions"]}>
          <div className={styles["collection-search-bar"]} data-test-id="collection-search-bar">
            <SearchBar
              value={filters.term}
              onChange={(value: string) => {
                updateFilter("term", value);
                searchCollectionItems(0, true);
              }}
              placeholder={t("collections.search_placeholder")}
            />
          </div>
          {selectedCollection["data-source"] !== DataSource.Automated && (
            <Button testId="advanced-search" type="transparent" onClick={toggleAdvancedSearch}>
              <Filter />
            </Button>
          )}
        </div>
        <Filters
          filters={filters}
          updateFilter={updateFilter}
          showFilters={isAllItemsFiltersExpanded}
          sections={sections}
          applyFilter={applyFilter}
          resetFilter={resetFilter}
          storyAttributes={attributes.filter((attribute) => attribute.type === "story")}
        />
        <div className={styles["collection-content-list"]}>
          <SelectTabs
            options={
              selectedCollection["template"] === "series" && selectedCollection["data-source"] === "automated"
                ? storyOptions
                : storyOptions.concat([
                    {
                      label: t("collections.collections"),
                      value: "collection",
                      badge: searchResults["collection"].hits
                    }
                  ])
            }
            value={selectedLaneTab}
            onChange={(tab: LHSTab) => switchLaneTab(tab)}
          />
        </div>
        <Panel>
          <div>
            {inspectorRef && inspectorRef.current && (
              <InfiniteScrollList
                hasChildren={true}
                dataLength={tabState.items.length}
                next={() => {
                  searchCollectionItems(tabState.items.length, selectedLaneTab === "draft-story");
                  return null;
                }}
                hasMore={tabState.hits > tabState.items.length}
                pullDownToRefresh={true}
                refreshFunction={() => {
                  searchCollectionItems(0, true, true);
                }}
                scrollableTarget={inspectorRef.current}
                loader={<Spinner message={t("collections.loading-items")} />}>
                <CollectionItemList
                  items={tabState.items}
                  storiesPageViews={storiesPageViews}
                  selectedCollectionItems={selectedCollection.items}
                  toggleCollectionItem={toggleCollectionItem}
                  templateOptions={templateOptions}
                  attributes={attributes}
                  hasCollectionRankNestedCollectionPermission={hasCollectionRankNestedCollectionPermission}
                />
              </InfiniteScrollList>
            )}
          </div>
        </Panel>
      </React.Fragment>
    </Inspector>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    isAllItemsFiltersExpanded: state.collections.ui.isAllItemsFiltersExpanded,
    filters: state.collections.collectionPage.filters,
    sections: state.config.sections,
    selectedLaneTab: state.collections.ui.selectedLaneTab,
    searchResults: state.collections.collectionPage.searchResults,
    selectedCollection: state.collections.collectionPage.selectedCollection!,
    templateOptions: state.collections.collectionPage.templateOptions,
    attributes: state.config.storyAttributes,
    hasCollectionRankNestedCollectionPermission: state.features.hasCollectionRankNestedCollectionPermission,
    storiesPageViews: state.collections.storiesPageViews,
    stagingSelectedCollection: state.collections.collectionPage.stagingSelectedCollection!
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, any, any>): DispatchProps => {
  const navigate = navigateFn(dispatch);
  return {
    closeAllItemsInspector: (id) => {
      document.body.className = "";
      navigate(COLLECTIONS_SELECTED_COLLECTION_PATH, { collectionId: id || "new" });
    },
    toggleCollectionItem: (item) => dispatch(toggleCollectionItemAction(item)),
    updateFilter: (key, value) => dispatch({ type: actions.UPDATE_FILTER, payload: { key, value } }),
    toggleAdvancedSearch: () => dispatch({ type: actions.TOGGLE_ADVANCED_SEARCH }),
    applyFilter: () => dispatch(applyFilterAction()),
    resetFilter: () => dispatch(resetFilterAction()),
    switchLaneTab: (tab) => dispatch({ type: actions.SWITCH_LANE_TAB, payload: { selectedTab: tab } }),
    searchCollectionItems: (offset, isScheduledTab, showNotification) => {
      dispatch(searchCollectionItemsAction(offset, isScheduledTab, showNotification));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllItemsInspector);

export { AllItemsInspector };
