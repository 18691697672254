/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, { LoadCollectionsConfig, UpdateCollectionsConfig, ResetConfig } from "./actions";
import { CollectionsConfig, CollectionEntityType } from "api/collection-config";

export const loadCollectionsConfigActions = (
  allEntityTypes: CollectionEntityType[],
  collectionsConfig: CollectionsConfig
): LoadCollectionsConfig => {
  return {
    type: actions.LOAD_COLLECTIONS_CONFIG,
    payload: {
      allEntityTypes,
      collectionsConfig
    }
  };
};

export const updateCollectionConfigAction = (collectionsConfig: CollectionsConfig): UpdateCollectionsConfig => {
  return {
    type: actions.UPDATE_COLLECTIONS_CONFIG,
    payload: {
      collectionsConfig
    }
  };
};

export const resetConfigAction = (): ResetConfig => {
  return {
    type: actions.RESET_COLLECTIONS_CONFIG
  };
};
