/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { t } from "i18n";
import classnames from "classnames/bind";
import { ExportState, Format } from "store/form/form";
import { ContextMenu, ContextMenuItem, ContextMenuList } from "components/context-menu/context-menu";
import styles from "./actions-menu.module.css";

const cx = classnames.bind(styles);

interface Props {
  classname?: string;
  showRetractButton: boolean;
  showDeleteButton: boolean;
  showExportButton: boolean;
  exportState: Partial<ExportState>;
  onRetractButtonClick: () => void;
  onDeleteButtonClick: () => void;
  onExportButtonClick: (format: Format) => void;
}

const FormActionsMenu: React.FC<Props> = ({
  classname,
  showRetractButton,
  showDeleteButton,
  showExportButton,
  exportState: { isExportingJSON, isExportingCSV },
  onRetractButtonClick,
  onDeleteButtonClick,
  onExportButtonClick
}) => {
  return showRetractButton || showDeleteButton || showExportButton ? (
    <div className={cx("form-card-actions", classname)}>
      <div className={styles["form-more-actions"]}>
        <ContextMenu>
          <ContextMenuList>
            {showRetractButton && <ContextMenuItem label={t("common.retract")} onClick={onRetractButtonClick} />}
            {showDeleteButton && <ContextMenuItem label={t("common.delete")} onClick={onDeleteButtonClick} />}
            {showExportButton && (
              <ContextMenuItem
                label={t("forms.common.export-submissions")}
                onClick={() => onExportButtonClick(Format.CSV)}
                isDisabled={isExportingCSV}
              />
            )}
          </ContextMenuList>
        </ContextMenu>
      </div>
    </div>
  ) : null;
};

export default FormActionsMenu;
