/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

import styles from "./bitgravity-video.module.css";

const BitGravityVideo = ({ storyElement, showPreview }) => {
  if (!showPreview) {
    return null;
  }

  return (
    <div className={styles["story-element-bitgravity-wrapper"]}>
      <video className={styles["story-element-bitgravity"]} width="100%" height="100%" controls>
        <source src={storyElement["url"]} type="video/mp4" />
      </video>
    </div>
  );
};

export default BitGravityVideo;
