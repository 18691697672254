/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { format } from "date-fns";
import { Image } from "api/search-media-image";
import { camelCase, isEmpty, isEqual, isPlainObject, omit, pick, range as _range, reduce } from "lodash";
import { reduxActionLog } from "store";
import { t } from "i18n";

export const formattedDate = (
  timestamp: string | number | null,
  formatString: string = "dd MMM yyyy, HH:mm a"
): string => {
  if (timestamp) {
    return format(typeof timestamp === "number" ? timestamp : parseInt(timestamp, 10), formatString);
  } else {
    return "";
  }
};

export const formatNumberToMetricSystem = (count: number) => {
  if (count > 9999999) {
    return Math.floor(count / 10000000) + "M";
  } else if (count > 999) {
    return Math.floor(count / 1000) + "k";
  } else {
    return count.toString();
  }
};

export const formatBytesToHumanReadable = (bytes?: number | null) => {
  if (!bytes || !Number.isInteger(bytes)) {
    return null;
  }

  const oneKilobyte = 1024;
  const oneMegabyte = oneKilobyte ** 2;
  const oneGigabyte = oneKilobyte ** 3;
  const oneTerabyte = oneKilobyte ** 4;

  if (bytes >= oneTerabyte) {
    return Math.round(bytes / oneTerabyte) + " TB";
  } else if (bytes >= oneGigabyte) {
    return Math.round(bytes / oneGigabyte) + " GB";
  } else if (bytes >= oneMegabyte) {
    return Math.round(bytes / oneMegabyte) + " MB";
  } else if (bytes >= oneKilobyte) {
    return Math.round(bytes / oneKilobyte) + " KB";
  } else {
    return bytes + " Bytes";
  }
};

export const loadScript = (src: string, callback: () => void) => {
  const script = document.createElement("script");
  script.setAttribute("src", src);
  script.async = true;
  script.onload = callback;
  document.body.appendChild(script);
};

export const hasErrors = (errorMessage: string, value: undefined | null | Array<any> | {}) => {
  if (errorMessage.length > 0) {
    if (value && ((value as Array<any>).length || Object.keys(value).length)) {
      return false;
    }
    return true;
  }
  return false;
};

export interface ValidationError {
  id: number;
  image?: boolean;
  caption?: boolean;
  fileName?: boolean;
  message?: string;
}

export const hasValidationErrors = (images: Array<Image>) => {
  let errorsInMedia: Array<ValidationError> = [];
  images.forEach((item, index) => {
    if (item.key === null || item["temp-image-key"] === null) {
      errorsInMedia.push({ id: index, image: true });
    } else if (item.caption && getHtmlWordCount(item.caption) <= 0) {
      errorsInMedia.push({ id: index, caption: true });
    } else if (!item.caption || (item.caption && item.caption.length <= 0)) {
      errorsInMedia.push({ id: index, caption: true });
    } else if (item.metadata["file-name"] && item.metadata["file-name"].length > 500) {
      errorsInMedia.push({ id: index, fileName: true, message: t("mediaLibrary.file_name_error") });
    }
  });

  return errorsInMedia;
};

export const removeKeyFromObject = (key: string, { [key]: _, ...objWithoutKey }) => objWithoutKey;

export const formatDate = (date: Date | number, _format: string, locale: Object) => format(date, _format, { locale });

export const base64Encode = (str: string) => window.btoa(global.unescape(encodeURIComponent(str)));

export const base64Decode = (str: string) => decodeURIComponent(global.escape(window.atob(str)));

export const isBase64Encoded = (str: string) => {
  if (str === "" || str.trim() === "") {
    return false;
  }
  try {
    return base64Encode(base64Decode(str)) === str;
  } catch (err) {
    return false;
  }
};

export function assertNever(x: never): never {
  throw new Error("This should never be reached: " + x);
}

export function isNotNullish<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export const replaceArrayItemByIndex = (arr: any[], index: number, value: any): any[] => [
  ...arr.slice(0, index),
  value,
  ...arr.slice(index + 1)
];

export const deleteArrayitemByIndex = (arr: any[], index: number): any[] => [
  ...arr.slice(0, index),
  ...arr.slice(index + 1)
];

export const extractRange = (option: string) => {
  var values = option.split("-");

  if (values.length !== 2) {
    return [];
  }

  return values
    .map((str: string) => {
      return parseInt(str);
    })
    .filter(function(x) {
      return x === 0 || !!x;
    });
};

export const isRange = (option: string) => {
  return extractRange(option).length === 2;
};

export const isUnicode = (word: string) => {
  const match = word.match(/[^\u0000-\u007F]+/); // eslint-disable-line no-control-regex
  return match && match[0];
};

export const convertRange = (option: string) => {
  var [from, to] = extractRange(option);
  return _range(from, to + 1);
};

export const flatten = (arr: any[]) => {
  return [].concat.apply([], arr);
};

// Get unique items from an array of objects with id keys
export const getUniqueItems = (items: any[]) => {
  const seenIds = new Set();
  return items.filter((el) => {
    const duplicate = seenIds.has(el.id);
    seenIds.add(el.id);
    return !duplicate;
  });
};

export function removeProtocol(s: string) {
  return s.replace(/^(https|http):\/\//, "");
}

export function normalize<T, K extends keyof T>(
  objArray: Array<T>,
  selectFields: Array<K> = [],
  key: K = "id" as K
): NormalizedObject<T> {
  const pickFields = isEmpty(selectFields)
    ? (obj: T, _: Array<K>) => obj
    : (obj: T, fields: Array<K>) => pick(obj, fields);

  return objArray.reduce((normalizedObj: NormalizedObject<Partial<T>>, currObj: T) => {
    if (currObj[key]) {
      const index = (currObj[key] as any).toString();
      normalizedObj[index] = pickFields(currObj, selectFields);
      return normalizedObj;
    }
    return normalizedObj;
  }, {});
}

export function diffObjects(baseObj: { [key: string]: any }, obj: { [key: string]: any }) {
  return reduce(
    obj,
    (result, value, key) => {
      if (isEqual(value, baseObj[key])) return result;
      return { ...result, [key]: obj[key] };
    },
    {}
  );
}

function splitBySpaces(str: string): string[] {
  return str.split(/\s+/);
}

function hasCharacters(str: string): boolean {
  return str.length > 0;
}

function createWrapperForHtml(html: string): HTMLDivElement {
  let wrapper = document.createElement("div");
  wrapper.innerHTML = html;
  return wrapper;
}

function recurGetAllTextInNode(node: Node): string {
  if (node.childNodes.length === 0) {
    return node.textContent || "";
  }
  return Array.prototype.map.call(node.childNodes, recurGetAllTextInNode).join(" ");
}

function getTextFromHtml(html: string): string {
  return recurGetAllTextInNode(createWrapperForHtml(html));
}

function getWordCount(text: string): number {
  return splitBySpaces(text).filter(hasCharacters).length;
}

export function getHtmlWordCount(html: string): number {
  return getWordCount(getTextFromHtml(html));
}

export function getHtmlCharacterCount(html: string): number {
  return getTextFromHtml(html).length;
}

export function isEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function convertObjKeysKebabToCamel(object) {
  if (isPlainObject(object)) {
    for (const key of Object.keys(object)) {
      object[camelCase(key)] = object[key];
      if (key !== camelCase(key)) {
        delete object[key];
      }
      convertObjKeysKebabToCamel(object[camelCase(key)]);
    }
  }
}

export type Transformers<T> = { [K in keyof Partial<T>]: (key: K, value: T[K]) => [string, any] };

export function transform<T extends object>(obj: T, transformers: Transformers<T>): { [key: string]: any } {
  let transformedKeys: string[] = [];
  let transformed = {};

  for (const key in transformers) {
    const transformer = transformers[key];
    const [newKey, newValue] = transformer(key, obj[key]);
    transformed = { ...transformed, [newKey]: newValue };
    key !== newKey && transformedKeys.push(key);
  }

  return { ...omit(obj, transformedKeys), ...transformed };
}

export function getReduxActionLog() {
  return reduxActionLog.getLog().actions.map((item) => item["action"]["type"]);
}
