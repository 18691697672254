/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import styles from "./radio-button.module.css";

interface Props {
  id: string;
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  name?: string;
  isDisabled?: boolean;
  classname?: string;
  isVertical?: boolean;
}

const cx = classnames.bind(styles);

const RadioButton: React.SFC<Props> = ({ id, label, checked, onChange, name, isDisabled, classname, isVertical }) => {
  const radioLabelClass = cx("radio-button-label", classname, { disabled: isDisabled, vertical: isVertical });
  return (
    <React.Fragment>
      <input
        id={id}
        data-test-id="radio-btn"
        type="radio"
        className={styles["radio-button"]}
        name={name}
        onChange={(e) => onChange(e.target.checked)}
        checked={checked}
        disabled={isDisabled}
      />
      <label htmlFor={id} className={radioLabelClass}>
        {label}
      </label>
    </React.Fragment>
  );
};

export default RadioButton;
