/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { clearAccessTokens } from "swallow";
import {
  searchUsers,
  searchUsersV2,
  getUserProfile,
  updateUserProfile,
  createNewUserProfile,
  Member,
  BridgekeeperUser,
  createOrUpdateBridgekeeperMember
} from "api/users";
import { getAuthorProfile, updateAuthorProfile } from "api/author";
import actions from "./actions";
import { navigate } from "utils/routes.utils";
import uploadImage from "pages/media-library/image-upload";
import { USERS_PATH, USERS_NEW_PATH, USERS_EDIT_PATH_ROLES, USERS_EDIT_PATH_TEAMS, USERS_EDIT_PATH } from "./routes";
import { USER_PROFILE_PATH } from "pages/user/routes";
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from "../../../../../containers/page/actions";
import { t } from "i18n";
import { getRoles, RoleTypes } from "api/roles";
import { getTeams } from "api/teams";
import debounce from "p-debounce";
import { PartialAppState, UserTeam, UserRole, SelectedUser, NewUser } from "./state";
import { Team, Role } from "api/users";
import { ThunkDispatch } from "redux-thunk";
import { LoaderError } from "behaviors/loader/state";
import { AnyImage } from "api/search-media-image";
import { AnyAction } from "redux";
import { notificationError } from "action-creators/notification";

import { omitBy, isNil } from "lodash";

const debouncedUserSearch = debounce(searchUsers, 250);
const debouncedUserSearchV2 = debounce(searchUsersV2, 250);

function mapAssignedRolesToRole(role: RoleTypes, assignedRoles: Role[] | null) {
  const selectedRoles = assignedRoles && assignedRoles.find((assignedRole) => assignedRole.id === role.id);
  return selectedRoles ? { ...selectedRoles, checked: true } : { id: role.id, name: role.name, checked: false };
}
function mapAssignedTeamsToTeam(team: Team, assignedTeams: Team[] | null | undefined) {
  const selectedTeams = assignedTeams && assignedTeams.find((assignedTeam) => assignedTeam.id === team.id);
  return selectedTeams ? { ...selectedTeams, checked: true } : { id: team.id, name: team.name, checked: false };
}

//Users listing page
export const fetchUsersData = () => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  getState: () => PartialAppState
) => {
  dispatch({ type: actions.RESET_ADD_NEW_MEMBER });
  const limit = getState().usersAndRoles.usersPage.usersPagination.limit;
  const offset = getState().usersAndRoles.usersPage.usersPagination.offset;
  // explicit false check because on initial page load isBridgekeeperIntegrationEnabled is undefined
  // and we want debouncedUserSearchV2 by default
  const userSearchFn =
    getState().config.isBridgekeeperIntegrationEnabled === false ? debouncedUserSearch : debouncedUserSearchV2;

  userSearchFn("", { limit, offset }).then((data) => {
    dispatch({ type: actions.USERS_UPDATE_CURRENT_PAGE, payload: { pageNumber: 1 } });
    dispatch({ type: actions.LOAD_USERS_DATA, payload: { data } });
  });
};

export const searchUsersData = (searchTerm: string) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  getState: () => PartialAppState
) => {
  dispatch({ type: actions.SET_SEARCH_TERM, payload: { searchTerm } });
  const limit = getState().usersAndRoles.usersPage.usersPagination.limit;
  const userSearchFn = getState().config.isBridgekeeperIntegrationEnabled ? debouncedUserSearchV2 : debouncedUserSearch;

  userSearchFn(searchTerm, { limit, offset: 0 }).then((data) => {
    dispatch({ type: actions.USERS_UPDATE_CURRENT_PAGE, payload: { pageNumber: 1 } });
    dispatch({ type: actions.LOAD_USERS_DATA, payload: { data } });
  });
};

export const getUsersByPageAction = (searchTerm: string, pageNumber: number) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  getState: () => PartialAppState
) => {
  const limit = getState().usersAndRoles.usersPage.usersPagination.limit;
  const usersPerPage = getState().usersAndRoles.usersPage.usersPerPage;

  const offset = (pageNumber - 1) * usersPerPage;

  dispatch({ type: actions.USERS_UPDATE_CURRENT_PAGE, payload: { pageNumber } });
  dispatch({ type: actions.USERS_UPDATE_PAGINATION, payload: { offset } });

  const userSearchFn = getState().config.isBridgekeeperIntegrationEnabled ? debouncedUserSearchV2 : debouncedUserSearch;

  userSearchFn(searchTerm, { limit, offset }).then((data) =>
    dispatch({
      type: actions.LOAD_USERS_DATA,
      payload: { data: { members: data.members, "total-count": data["total-count"] } }
    })
  );
};

//Edit user page
export const showAllRolesWithAssignedRolesSelected = () => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  getState: () => PartialAppState
) => {
  dispatch({ type: actions.LOAD_ALL_ROLES });
  const usersPageState = getState().usersAndRoles.usersPage;
  const assignedUserRoles = usersPageState.selectedUser && usersPageState.selectedUser["roles"];
  getRoles().then(
    (allRoles: RoleTypes[]) => {
      const roles = allRoles.map((role) => mapAssignedRolesToRole(role, assignedUserRoles));
      dispatch({
        type: actions.LOAD_ALL_ROLES_SUCCESS,
        payload: { roles }
      });
    },
    (error: LoaderError) => dispatch({ type: actions.LOAD_ALL_ROLES_FAILURE, payload: { error } })
  );
};

export const showAllTeamsWithAssignedTeamsSelected = () => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  getState: () => PartialAppState
) => {
  dispatch({ type: actions.LOAD_ALL_TEAMS });
  const usersPageState = getState().usersAndRoles.usersPage;
  const assignedUserTeams = usersPageState.selectedUser && usersPageState.selectedUser["teams"];
  getTeams().then(
    (allTeams: Team[]) => {
      const teams = allTeams.map((team) => mapAssignedTeamsToTeam(team, assignedUserTeams));
      dispatch({
        type: actions.LOAD_ALL_TEAMS_SUCCESS,
        payload: { teams }
      });
    },
    (error: LoaderError) => dispatch({ type: actions.LOAD_ALL_TEAMS_FAILURE, payload: { error } })
  );
};
export const loadSelectedUserData = (userId: number, path: string) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  getState: () => PartialAppState
) => {
  const userProfileId = userId ? userId : getState().config.member.id;
  const getProfileData = (id: number | string) =>
    path === USER_PROFILE_PATH ? getAuthorProfile() : getUserProfile(id as string);

  dispatch({ type: actions.LOAD_USER_DATA });
  getProfileData(userProfileId).then(
    (data: SelectedUser) => {
      dispatch({ type: actions.LOAD_USER_DATA_SUCCESS, payload: { data } });
      path === USERS_EDIT_PATH_ROLES && dispatch(showAllRolesWithAssignedRolesSelected());
      path === USERS_EDIT_PATH_TEAMS && dispatch(showAllTeamsWithAssignedTeamsSelected());
    },
    (error: LoaderError) => dispatch({ type: actions.LOAD_USER_DATA_FAILURE, payload: { error } })
  );
};

export const saveAndLogout = (updatedUser: SelectedUser, path?: string) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>
) => {
  const updateProfileData = (updatedUser: SelectedUser) =>
    path === USER_PROFILE_PATH ? updateAuthorProfile(updatedUser) : updateUserProfile(updatedUser);

  updateProfileData(updatedUser).then(
    (res) => {
      clearAccessTokens();
      window.location.href = "/logout";
    },
    (error: LoaderError) => dispatch({ type: actions.SAVE_UPDATED_USER_FAILURE, payload: { error } })
  );
};

export const updateSelectedUserData = (updatedUser: SelectedUser, path: string) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>
) => {
  const updateProfileData = (updatedUser: SelectedUser) =>
    path === USER_PROFILE_PATH ? updateAuthorProfile(updatedUser) : updateUserProfile(updatedUser);

  dispatch({ type: actions.SAVE_UPDATED_USER_REQUEST });
  updateProfileData(updatedUser).then(
    (res) => {
      dispatch({ type: actions.LOAD_USER_DATA_SUCCESS, payload: { data: res } });
      dispatch({ type: actions.RESET_SELECTED_USER });
      dispatch({
        type: NOTIFICATION_SUCCESS,
        payload: {
          message: t("user-page.profile_success_update")
        }
      });
    },
    (error) => {
      error.message
        ? dispatch({ type: actions.SAVE_UPDATED_USER_FAILURE, payload: { error: null } })
        : dispatch({ type: actions.SAVE_UPDATED_USER_FAILURE, payload: { error } });

      error.message === "slug-not-unique"
        ? dispatch({
            type: NOTIFICATION_ERROR,
            payload: {
              message: t("user-page.slug_not_unique_error")
            }
          })
        : dispatch({
            type: NOTIFICATION_ERROR,
            payload: {
              message: error.message
            }
          });
    }
  );
};

export const uploadAvatarForSelectedUser = (files: File[]) => async (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>
) => {
  dispatch({ type: actions.UPDATE_AVATAR_UPLOAD_STATUS, payload: { uploading: true } });
  if (files.length === 0) {
    return;
  }

  const updateImageUploadStatus = (status: { uploading: boolean }) =>
    dispatch({ type: actions.UPDATE_AVATAR_UPLOAD_STATUS, payload: status });
  const setSelectedMedia = (images: AnyImage[]) => {
    dispatch({ type: actions.UPDATE_USER_PROFILE, payload: { key: "avatar-url", changes: images[0].url } });
    dispatch({
      type: actions.UPDATE_USER_PROFILE,
      payload: { key: "temp-s3-key", changes: images[0]["temp-image-key"] }
    });
  };
  uploadImage(files, updateImageUploadStatus, setSelectedMedia, () => {}, {});
};

//Edit and Add user page

export const updateSelectedRoles = (updatedRole: UserRole) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  getState: () => PartialAppState
) => {
  const allSelectedRoles = getState().usersAndRoles.usersPage.ui.allRoles;
  const roles = (allSelectedRoles as UserRole[]).map((role: UserRole) =>
    role.id === updatedRole.id ? updatedRole : role
  );
  dispatch({
    type: actions.LOAD_ALL_ROLES_SUCCESS,
    payload: { roles }
  });
};

export const updateSelectedTeams = (updatedTeam: UserTeam) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  getState: () => PartialAppState
) => {
  const allSelectedTeams = getState().usersAndRoles.usersPage.ui.allTeams;
  const teams = (allSelectedTeams as UserTeam[]).map((team: UserTeam) =>
    team.id === updatedTeam.id ? updatedTeam : team
  );
  dispatch({
    type: actions.LOAD_ALL_TEAMS_SUCCESS,
    payload: { teams }
  });
};

export const setSelectedRolesForSelectedUser = () => (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
  dispatch({ type: actions.SET_ROLES_FOR_SELECTED_USER });
  dispatch({
    type: NOTIFICATION_SUCCESS,
    payload: { message: t("users-and-roles.roles_selected_message") }
  });
};

export const setSelectedTeamsForSelectedUser = () => (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
  dispatch({ type: actions.SET_TEAMS_FOR_SELECTED_USER });
};

//New User
export const setSelectedRolesForNewUser = () => (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
  dispatch({ type: actions.SET_ROLES_FOR_NEW_USER });
  dispatch({
    type: NOTIFICATION_SUCCESS,
    payload: { message: t("users-and-roles.roles_selected_message") }
  });
  dispatch(navigate(USERS_NEW_PATH));
};

export const uploadAvatarForNewUser = (files: File[]) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>
) => {
  dispatch({ type: actions.UPDATE_AVATAR_UPLOAD_STATUS, payload: { uploading: true } });
  if (files.length === 0) {
    return;
  }

  const updateImageUploadStatus = (status: { uploading: boolean }) =>
    dispatch({ type: actions.UPDATE_AVATAR_UPLOAD_STATUS, payload: status });
  const setSelectedMedia = (images: AnyImage[]) => {
    dispatch({ type: actions.SAVE_NEW_USER_DATA, payload: { key: "avatar-url", changes: images[0].url } });
    dispatch({
      type: actions.SAVE_NEW_USER_DATA,
      payload: { key: "temp-s3-key", changes: images[0]["temp-image-key"] }
    });
  };

  uploadImage(files, updateImageUploadStatus, setSelectedMedia, () => {}, {});
};

export const createNewUser = (newUser: NewUser) => (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
  dispatch({ type: actions.SAVING_USER, payload: { saving: true } });
  createNewUserProfile(newUser).then(
    (res) => {
      dispatch(navigate(USERS_PATH));
      dispatch({ type: actions.RESET_ADD_NEW_MEMBER });
    },
    (err) => {
      dispatch({ type: actions.SAVING_USER, payload: { saving: false } });
      err.error
        ? dispatch({ type: NOTIFICATION_ERROR, payload: { message: err.error } })
        : dispatch({ type: NOTIFICATION_ERROR, payload: { message: err.message } });
    }
  );
};

export const showAllRoles = () => (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
  getRoles().then((allRoles) => {
    const roles = allRoles.map((role) => ({ ...role, checked: false }));
    dispatch({ type: actions.LOAD_ALL_ROLES_SUCCESS, payload: { roles } });
  });
};

export const setBanner = (message: string) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  getState: () => PartialAppState
) => {
  const defaultRole = getState().config.defaultRole;
  if (!defaultRole) {
    dispatch({ type: actions.SET_BANNER, payload: { message } });
  }
};

export const closeBanner = () => (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => {
  dispatch({ type: actions.CLOSE_BANNER });
};

function instanceOfMember(object: Member | BridgekeeperUser): object is Member {
  return true;
}

export const editUserProfile = (user: Member | BridgekeeperUser, hasAdminPermission: boolean) => async (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>
) => {
  if (hasAdminPermission) {
    if (user && instanceOfMember(user) && user.id) {
      dispatch(navigate(USERS_EDIT_PATH, { id: user.id }));
    } else {
      try {
        const cleanUser = omitBy(user, isNil);
        const { id } = await createOrUpdateBridgekeeperMember(cleanUser);
        dispatch(navigate(USERS_EDIT_PATH, { id }));
      } catch (error) {
        dispatch(notificationError(t("user-page.does_not_exist")));
      }
    }
  }
};
