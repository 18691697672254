/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Fields, VideoOEmbedFilter } from "api/route-data/story-route-data";
import {
  EditorConfig,
  AnyStory,
  Story,
  StoryElement,
  WorkflowTransitions,
  StoryStatus,
  CompositeStoryElement,
  ChildStoryElement,
  Card,
  StoryTag,
  StoryEntity
} from "api/story";
import { SearchedStory } from "api/search";
import { SocialAccount, SocialCard } from "api/social";
import { CardId, StoryElementId, StoryId, PolltypeId } from "api/primitive-types";
import { Image, ImageOrNewImage } from "api/search-media-image";
import { StoryElementDirection } from "./operations/story-elements/add";
import { InspectorData } from "./components/inspector/inspector-data";
import { AnyContribution, Contribution } from "api/story";
import { Poll, UnsavedPoll } from "api/polls";
import { TimelineEvent } from "api/activity-log";
import { Entity } from "api/entity";
import { Video as PralineVideo } from "api/praline";
import { EditorState } from "prosemirror-state";

export enum actions {
  UPDATE_SEO = "@@story-editor/UPDATE_STORY_SEO",
  ADD_TAGS = "@@story-editor/ADD_TAGS",
  UPDATE_ENTITIES_STORY_ATTRIBUTE = "@@story-editor/UPDATE_ENTITIES_STORY_ATTRIBUTE",
  UPDATE_STORY_METADATA = "@@story-editor/UPDATE_STORY_METADATA",
  UPDATE_STORY_TAG = "@@story-editor/UPDATE_STORY_TAG",
  TOGGLE_SPONSORED_CONTENT = "@@story-editor/TOGGLE_SPONSORED_CONTENT",
  LOAD_RECOMMENDED_TAGS = "@@story-editor/LOAD_RECOMMENDED_TAGS",
  UPDATE_STORY_ATTRIBUTE = "@@story-editor/UPDATE_STORY_ATTRIBUTE",
  UPDATE_TEMPLATE_FIELDS = "@@story-editor/UPDATE_TEMPLATE_FIELDS",
  UPDATE_EDITOR_CONFIG = "@@story-editor/UPDATE_EDITOR_CONFIG",
  UPDATE_STORY_SOCIAL_CARD = "@@story-editor/UPDATE_STORY_SOCIAL_CARD",
  ADD_STORY_SOCIAL_CARD = "@@story-editor/ADD_STORY_SOCIAL_CARD",
  DELETE_STORY_SOCIAL_CARD = "@@story-editor/DELETE_STORY_SOCIAL_CARD",
  UPDATE_STORY_SOCIAL_CARD_ACCOUNT = "@@story-editor/UPDATE_STORY_SOCIAL_CARD_ACCOUNT",
  ADD_SOCIAL_NOTIFICATIONS_HISTORY = "@@story-editor/ADD_SOCIAL_NOTIFICATIONS_HISTORY",
  STORY_EDITOR_IMAGE_UPLOADING = "@@story-editor/STORY_EDITOR_IMAGE_UPLOADING",
  UPDATE_TEMPLATE = "@@story-editor/UPDATE_TEMPLATE",
  LOAD_SOCIAL_ACCOUNTS_AND_CARDS = "@@story-editor/LOAD_SOCIAL_ACCOUNTS_AND_CARDS",
  UPDATE_STORY = "@@story-editor/UPDATE_STORY",
  UPDATE_UI_CONTRIBUTIONS = "@@story-editor/UPDATE_UI_CONTRIBUTIONS",
  LOAD_AUTHOR_CONTRIBUTIONS = "@@story-editor/LOAD_AUTHOR_CONTRIBUTIONS",
  UPDATE_AUTHOR_CONTRIBUTIONS = "@@story-editor/UPDATE_AUTHOR_CONTRIBUTIONS",
  STORY_EDITOR_IMAGE_CONTENT_EDIT_PATH = "@@story-editor/STORY_EDITOR_IMAGE_CONTENT_EDIT_PATH",
  SET_STORY = "@@story-editor/SET_STORY",
  SET_SELECTED_MEDIA = "@@story-editor/SET_SELECTED_MEDIA",
  INIT_EDITOR_STATE = "@@story-editor/INIT_EDITOR_STATE",
  SET_STORY_ELEMENT_ERROR = "@@story-editor/SET_STORY_ELEMENT_ERROR",
  REMOVE_STORY_ELEMENT_ERROR = "@@story-editor/REMOVE_STORY_ELEMENT_ERROR",
  TOGGLE_STORY_ELEMENT_LOADING = "@@story-editor/TOGGLE_STORY_ELEMENT_LOADING",
  TOGGLE_ALTERNATIVE_TITLE = "@@story-editor/TOGGLE_ALTERNATIVE_TITLE",
  UPDATE_ALTERNATIVE = "@@story-editor/UPDATE_ALTERNATIVE",
  DUMMY_PASTE = "@@story-editor/DUMMY_PASTE",
  DUMMY_CUT = "@@story-editor/DUMMY_CUT",
  DUMMY_BACKSPACE = "@@story-editor/DUMMY_BACKSPACE",
  DUMMY_DELETE = "@@story-editor/DUMMY_DELETE",
  DUMMY_UNDO = "@@story-editor/DUMMY_UNDO",
  DUMMY_REDO = "@@story-editor/DUMMY_REDO",
  ADD_NEW_CARD = "@@story-editor/ADD_NEW_CARD",
  ADD_TEMPLATE_CARDS = "@@story-editor/ADD_TEMPLATE_CARDS",
  DELETE_DEFAULT_CARD = "@@story-editor/DELETE_DEFAULT_CARD",
  LOAD_STORY_DATA = "@@story-editor/LOAD_STORY_DATA",
  OPEN_IMPORT_CARD_INSPECTOR = "@@story-editor/OPEN_IMPORT_CARD_INSPECTOR",
  SET_EDITOR_STATE = "@@story-editor/SET_EDITOR_STATE",
  UPDATE_HERO_IMAGE_DATA = "@@story-editor/UPDATE_HERO_IMAGE_DATA",
  DELETE_HERO_IMAGE = "@@story-editor/DELETE_HERO_IMAGE",
  SET_SELECTED_HERO_IMAGE = "@@story-editor/SET_SELECTED_HERO_IMAGE",
  DELETE_STORY_EDITOR_CARD = "@@story-editor/DELETE_CARD",
  MOVE_STORY_EDITOR_CARD_UP = "@@story-editor/MOVE_CARD_UP",
  MOVE_STORY_EDITOR_CARD_DOWN = "@@story-editor/MOVE_CARD_DOWN",
  STORY_EDITOR_PIN_CARD = "@@story-editor/PIN_CARD",
  STORY_EDITOR_ADD_KEY_EVENT = "@@story-editor/ADD_KEY_EVENT",
  TOGGLE_CARD_SHARE = "@@story-editor/TOGGLE_CARD_SHARE",
  CARD_SHARE_UPDATE = "@@story-editor/CARD_SHARE_UPDATE",
  CARD_ATTRIBUTES_UPDATE = "@@story-editor/CARD_ATTRIBUTES_UPDATE",
  UPDATE_STORY_ELEMENT_ENTITIES = "@@story-editor/UPDATE_STORY_ELEMENT_ENTITIES",
  CARD_SHARE_IMAGE_UPLOAD = "@@story-editor/CARD_SHARE_IMAGE_UPLOAD",
  CARD_SHARE_IMAGE_DELETE = "@@story-editor/CARD_SHARE_IMAGE_DELETE",
  UPDATE_STORY_ELEMENT = "@@story-editor/UPDATE_STORY_ELEMENT",
  UPDATE_OEMBED_URL = "@@story-editor/UPDATE_OEMBED_URL",
  SET_OEMBED_URL = "@@story-editor/SET_OEMBED_URL",
  STORY_EDITOR_ADD_STORY_ELEMENT = "@@story-editor/ADD_STORY_ELEMENT",
  STORY_EDITOR_SPLIT_STORY_ELEMENT = "@@story-editor/SPLIT_STORY_ELEMENT",
  STORY_EDITOR_DELETE_STORY_ELEMENT = "@@story-editor/DELETE_STORY_ELEMENT",
  STORY_EDITOR_MOVE_STORY_ELEMENT = "@@story-editor/MOVE_STORY_ELEMENT",
  UPDATE_IMAGE_ELEMENT = "@@story-editor/UPDATE_IMAGE_ELEMENT",
  SET_IS_STORY_MODIFIED_STATE = "@@story-editor/SET_IS_STORY_MODIFIED_STATE",
  SET_STORY_VALIDATION_ERRORS = "@@story-editor/SET_STORY_VALIDATION_ERRORS",
  UPDATE_PUBLISH_INSPECTOR = "@@story-editor/UPDATE_PUBLISH_INSPECTOR",
  UPDATE_STORY_PUBLISH_AT = "@@story-editor/UPDATE_STORY_PUBLISH_AT",
  SET_PUBLISH_INSPECTOR_VALIDATION_ERRORS = "@@story-editor/SET_PUBLISH_INSPECTOR_VALIDATION_ERRORS",
  UPDATE_INSPECTOR_DATA = "@@story-editor/UPDATE_INSPECTOR_DATA",
  SET_IMAGE_FOR_INSPECTOR = "@@story-editor/SET_IMAGE_FOR_INSPECTOR",
  UPDATE_IMAGE_DATA_FOR_INSPECTOR = "@@story-editor/UPDATE_IMAGE_DATA_FOR_INSPECTOR",
  UPDATE_STORY_STATUS = "@@story-editor/UPDATE_STORY_STATUS",
  SET_WORKFLOW_TRANSITIONS = "@@story-editor/SET_WORKFLOW_TRANSITIONS",
  UPDATE_STORY_LOCK = "@@story-editor/UPDATE_STORY_LOCK",
  APPEND_TO_TIMELINE = "@@story-editor/APPEND_TO_TIMELINE",
  TOGGLE_CLAIM_REVIEW = "@@story-editor/TOGGLE_CLAIM_REVIEW",
  SET_TIMELINE_EVENT = "@@story-editor/SET_TIMELINE_EVENT",
  RESET_TIMELINE = "@@story-editor/RESET_TIMELINE",
  OPEN_STORY_RESTORE_MODAL = "@@story-editor/OPEN_STORY_RESTORE_MODAL",
  CLOSE_STORY_RESTORE_MODAL = "@@story-editor/CLOSE_STORY_RESTORE_MODAL",
  FETCH_OPINION_POLL = "@@story-editor/FETCH_OPINION_POLL",
  SET_OPINION_POLL = "@@story-editor/SET_OPINION_POLL",
  SET_OPINION_POLL_ID = "@@story-editor/SET_OPINION_POLL_ID",
  UPDATE_OPINION_POLL = "@@story-editor/UPDATE_OPINION_POLL",
  SET_OPINION_POLL_INIT = "@@story-editor/SET_OPINION_POLL_INIT",
  SAVE_OPINION_POLL = "@@story-editor/SAVE_OPINION_POLL",
  SET_OPINION_POLL_SUCCESS = "@@story-editor/SET_OPINION_POLL_SUCCESS",
  UPDATE_OPINION_POLL_IMAGE_UPLOAD_STATUS = "@@story-editor/UPDATE_OPINION_POLL_IMAGE_UPLOAD_STATUS",
  CLEAR_MEDIA_FOR_MULTI_SELECT = "@@story-editor/CLEAR_MEDIA_FOR_MULTI_SELECT",
  SET_IMAGES_FOR_IMAGE_GALLERY_ELEMENT = "@@story-editor/SET_IMAGES_FOR_IMAGE_GALLERY_ELEMENT",
  STORY_EDITOR_MOVE_STORY_ELEMENT_UP = "@@story-editor/MOVE_STORY_ELEMENT_UP",
  STORY_EDITOR_MOVE_STORY_ELEMENT_DOWN = "@@story-editor/MOVE_STORY_ELEMENT_DOWN",
  OPEN_PHOTO_EDITOR = "@@story-editor/OPEN_PHOTO_EDITOR",
  CLOSE_PHOTO_EDITOR = "@@story-editor/CLOSE_PHOTO_EDITOR",
  UPDATE_STORY_SEARCH_TERM = "@@story-editor/UPDATE_STORY_SEARCH_TERM",
  SET_STORY_LIST = "@@story-editor/SET_STORY_LIST",
  SET_SELECTED_STORY = "@@story-editor/SET_SELECTED_STORY",
  UPDATE_IMPORT_CARD_ID = "@@story-editor/UPDATE_IMPORT_CARD_ID",
  IMPORT_CARD = "@@story-editor/IMPORT_CARD",
  RESET_IMPORT_CARD = "@@story-editor/RESET_IMPORT_CARD",
  RESET_IMPORT_CARD_DISPLAY = "@@story-editor/RESET_IMPORT_CARD_DISPLAY",
  TOGGLE_STORY_CLEAN_UP_CONFIRMATION = "@@story-editor/TOGGLE_STORY_CLEAN_UP_CONFIRMATION",
  TOGGLE_STORY_SEO_CHECKED = "@@story-editor/TOGGLE_STORY_SEO_CHECKED",
  RESET_STORY_EDITOR_STATE = "@@story-editor/RESET_STORY_EDITOR_STATE",
  SET_BANNER = "@@story-editor/SET_BANNER",
  START_SAVING_STORY = "@@story-editor/START_SAVING_STORY",
  STOP_SAVING_STORY = "@@story-editor/STOP_SAVING_STORY",
  STORY_CHANGE_IN_PROGRESS = "@@story-editor/STORY_CHANGE_IN_PROGRESS",
  PUBLISHING_STORY_INIT = "@@story-editor/PUBLISHING_STORY_INIT",
  PUBLISHING_STORY_FAILURE = "@@story-editor/PUBLISHING_STORY_FAILURE",
  LOAD_STORY_TASKS = "@@story-editor/LOAD_STORY_TASKS",
  LOAD_STORY_TASKS_SUCCESS = "@@story-editor/LOAD_STORY_TASKS_SUCCESS",
  LOAD_STORY_TASKS_FAILURE = "@@story-editor/LOAD_STORY_TASKS_FAILURE",
  CLOSE_TASK = "@@story-editor/CLOSE_TASK",
  CLOSE_ALL_TASKS_SUCCESS = "@@story-editor/CLOSE_ALL_TASKS_SUCCESS",
  CLOSE_TASK_SUCCESS = "@@story-editor/CLOSE_TASK_SUCCESS",
  CLOSE_TASK_FAILURE = "@@story-editor/CLOSE_TASK_FAILURE",
  PLAGIARISM_CHECK_INIT = "@@story-editor/PLAGIARISM_CHECK_INIT",
  PLAGIARISM_CHECK_SUCCESS = "@@story-editor/PLAGIARISM_CHECK_SUCCESS",
  PLAGIARISM_CHECK_FAILURE = "@@story-editor/PLAGIARISM_CHECK_FAILURE",
  UPDATE_SELECTED_PLACE = "@@story-editor/UPDATE_SELECTED_PLACE",
  SET_PROJECT = "@@story-editor/SET_PROJECT",
  SET_STORY_TRANSITION_STATUS = "@@story-editor/SET_STORY_TRANSITION_STATUS",
  SET_FORMATTING_TOOLBAR_ACTIVE = "@@story-editor/SET_FORMATTING_TOOLBAR_ACTIVE",
  SET_FORMATTING_TOOLBAR_INACTIVE = "@@story-editor/SET_FORMATTING_TOOLBAR_INACTIVE",
  UPDATE_EDITOR_NOTES = "@@story-editor/UPDATE_EDITOR_NOTES",
  VIDEO_OEMBED_SEARCH_TERM_CHANGE = "@@story-editor/VIDEO_OEMBED_SEARCH_TERM_CHANGE",
  VIDEO_OEMBED_SEARCH_SUCCESS = "@@story-editor/VIDEO_OEMBED_SEARCH_SUCCESS",
  VIDEO_OEMBED_SEARCH_RESET = "@@story-editor/VIDEO_OEMBED_SEARCH_RESET",
  TOGGLE_OEMBED_INSPECTOR_SEARCH_FILTER = "@@story-editor/TOGGLE_OEMBED_INSPECTOR_SEARCH_FILTER",
  VIDEO_OEMBED_SEARCH_FILTERS_CHANGE = "@@story-editor/VIDEO_OEMBED_SEARCH_FILTERS_CHANGE",
  SET_IS_VIEWING_READONLY_VERSION = "@@story-editor/SET_IS_VIEWING_READONLY_VERSION",
  REAPPLY_EDITOR_STATE = "@@story-editor/REAPPLY_EDITOR_STATE",
  UPDATE_EMBARGO = "@@story-editor/UPDATE_EMBARGO",
  SET_TEXT_PARAPHRASING_STATUS = "@@story-editor/SET_TEXT_PARAPHRASING_STATUS",
  UPDATE_STORY_FEATURES = "@@story-editor/UPDATE_STORY_FEATURES",
  IMAGE_UPDATE_ERROR = "@@story-editor/IMAGE_UPDATE_ERROR",
  MEDIA_LIBRARY_IMAGE_SAVE_INIT = "@@story-editor/IMAGE_SAVE_INIT",
  STORY_EDITOR_CLEAR_MEDIA = "@@story-editor/STORY_EDITOR_CLEAR_MEDIA"
}

//Inspector
export type UpdateSeo = { type: actions.UPDATE_SEO };
export type AddTags = { type: actions.ADD_TAGS };
export type UpdateEntitiesStoryAttribute = {
  type: actions.UPDATE_ENTITIES_STORY_ATTRIBUTE;
  payload: { key: string; value: Array<Entity> };
};
export type UpdateStoryMetadata = { type: actions.UPDATE_STORY_METADATA };
export type UpdateStoryTag = {
  type: actions.UPDATE_STORY_TAG;
  payload: {
    tags: Array<StoryTag | StoryEntity>;
    tagEntities: Array<StoryEntity>;
    updatedKeywords: string[] | undefined;
  };
};
export type ToggleSponsoredContent = { type: actions.TOGGLE_SPONSORED_CONTENT };
export type LoadRecommendedTags = { type: actions.LOAD_RECOMMENDED_TAGS; payload: { tags: any } };
export type UpdateStoryAttribute = {
  type: actions.UPDATE_STORY_ATTRIBUTE;
  payload: { key: string; value: Array<string | Entity> };
};
export type UpdateTemplateFields = {
  type: actions.UPDATE_TEMPLATE_FIELDS;
  payload: { storyTemplateFields: Fields };
};
export type UpdateEditorConfig = { type: actions.UPDATE_EDITOR_CONFIG; payload: { editorConfig: EditorConfig } };
export type UpdateStorySocialCard = { type: actions.UPDATE_STORY_SOCIAL_CARD };
export type AddStorySocialCard = { type: actions.ADD_STORY_SOCIAL_CARD };
export type DeleteStorySocialCard = { type: actions.DELETE_STORY_SOCIAL_CARD; payload: { cardId: CardId } };
export type UpdateStorySocialCardAccount = { type: actions.UPDATE_STORY_SOCIAL_CARD_ACCOUNT };

export type AddSocialNotificationsHistory = {
  type: actions.ADD_SOCIAL_NOTIFICATIONS_HISTORY;
  payload: { socialAccounts: SocialAccount[]; socialCards: SocialCard[]; socialCardsHistory: SocialCard[] };
};

export type StoryEditorImageUploading = { type: actions.STORY_EDITOR_IMAGE_UPLOADING };
export type ToggleClaimReview = { type: actions.TOGGLE_CLAIM_REVIEW };

export type UpdateTemplate = { type: actions.UPDATE_TEMPLATE };
export type LoadSocialAccountsAndCards = {
  type: actions.LOAD_SOCIAL_ACCOUNTS_AND_CARDS;
  payload: { socialAccounts: SocialAccount[] | []; socialCards: SocialCard[] | [] };
};
export type UpdateStory = { type: actions.UPDATE_STORY; payload: { key: string; value: any } };
export type UpdateStoryFeatures = {
  type: actions.UPDATE_STORY_FEATURES;
  payload: { feature: string; changes: any };
};
export type UpdateUIContributions = { type: actions.UPDATE_UI_CONTRIBUTIONS; payload: AnyContribution[] };
export type LoadAuthorContributions = { type: actions.LOAD_AUTHOR_CONTRIBUTIONS; payload: Contribution[] };
export type UpdateAuthorContributions = { type: actions.UPDATE_AUTHOR_CONTRIBUTIONS; payload: Contribution[] };
export type StoryEditorImageContentEditPath = { type: actions.STORY_EDITOR_IMAGE_CONTENT_EDIT_PATH };
export type SetStory = { type: actions.SET_STORY; payload: { story: Story; workflowTransitions: WorkflowTransitions } };
export type SetSelectedMedia = { type: actions.SET_SELECTED_MEDIA };

export type InitEditorState = {
  type: actions.INIT_EDITOR_STATE;
  payload: { story: AnyStory; opts: { [key: string]: any } };
};
export type SetStoryElementError = {
  type: actions.SET_STORY_ELEMENT_ERROR;
  payload: { id: StoryElementId; error: string | {} };
};
export type RemoveStoryElementError = { type: actions.REMOVE_STORY_ELEMENT_ERROR; payload: { id: StoryElementId } };
export type ToggleStoryElementLoading = { type: actions.TOGGLE_STORY_ELEMENT_LOADING; payload: { id: StoryElementId } };
export type ToggleAlternativeTitle = { type: actions.TOGGLE_ALTERNATIVE_TITLE };
export type UpdateAlternative = {
  type: actions.UPDATE_ALTERNATIVE;
  payload: { location: string; key: string; value: ImageOrNewImage | string };
};
export type AddNewCard = { type: actions.ADD_NEW_CARD };
export type AddTemplateCards = { type: actions.ADD_TEMPLATE_CARDS };
export type DeleteDefaultCard = { type: actions.DELETE_DEFAULT_CARD };
export type LoadStoryData = { type: actions.LOAD_STORY_DATA; payload: { story: AnyStory } };
export type OpenImportCardInspector = { type: actions.OPEN_IMPORT_CARD_INSPECTOR };
export type SetEditorState = { type: actions.SET_EDITOR_STATE; payload: { editorState: EditorState } };
export type UpdateHeroImageData = { type: actions.UPDATE_HERO_IMAGE_DATA; payload: { image: Image } };
export type DeleteHeroImage = { type: actions.DELETE_HERO_IMAGE };
export type SetSelectedHeroImage = { type: actions.SET_SELECTED_HERO_IMAGE; payload: ImageOrNewImage | Image };
export type ToggleOEmbedInspectorSearchFilter = { type: actions.TOGGLE_OEMBED_INSPECTOR_SEARCH_FILTER };
export type VideoOEmbedSearchFiltersChange = {
  type: actions.VIDEO_OEMBED_SEARCH_FILTERS_CHANGE;
  payload: { [key: string]: VideoOEmbedFilter };
};
export type VideoOEmbedSearchReset = { type: actions.VIDEO_OEMBED_SEARCH_RESET };

//Story cards
export type DeleteStoryEditorCard = { type: actions.DELETE_STORY_EDITOR_CARD; payload: { cardId: CardId } };
export type MoveStoryEditorCardUp = { type: actions.MOVE_STORY_EDITOR_CARD_UP };
export type MoveStoryEditorCardDown = { type: actions.MOVE_STORY_EDITOR_CARD_DOWN };
export type StoryEditorPinCard = { type: actions.STORY_EDITOR_PIN_CARD };
export type StoryEditorAddKeyEvent = { type: actions.STORY_EDITOR_ADD_KEY_EVENT };

//StoryElements
export type UpdateStoryElement = { type: actions.UPDATE_STORY_ELEMENT };
export type UpdateOembedUrl = { type: actions.UPDATE_OEMBED_URL; payload: { id: StoryElementId; url: string } };
export type SetOembedUrl = {
  type: actions.SET_OEMBED_URL;
  payload: { id: StoryElementId; changes: any };
};
export type StoryEditorAddStoryElement = {
  type: actions.STORY_EDITOR_ADD_STORY_ELEMENT;
  payload: {
    currentStoryElement: StoryElement | CompositeStoryElement | null;
    newStoryElement: StoryElement | CompositeStoryElement | ChildStoryElement;
    addNewElementAt: StoryElementDirection;
  };
};
export type StoryEditorSplitStoryElement = {
  type: actions.STORY_EDITOR_SPLIT_STORY_ELEMENT;
  payload: {
    currentStoryElement: StoryElement | CompositeStoryElement;
    newStoryElement: StoryElement | CompositeStoryElement | ChildStoryElement;
    newTextElement: StoryElement | CompositeStoryElement | ChildStoryElement;
    addNewElementAt: number;
  };
};

export type StoryEditorDeleteStoryElement = {
  type: actions.STORY_EDITOR_DELETE_STORY_ELEMENT;
  payload: { storyElement: StoryElement | CompositeStoryElement | ChildStoryElement };
};

export type StoryEditorMoveStoryElement = { type: actions.STORY_EDITOR_MOVE_STORY_ELEMENT };

export type UpdateImageElement = {
  type: actions.UPDATE_IMAGE_ELEMENT;
  payload: { id: StoryElementId; changes: Image };
};

export type UpdateStoryElementEntities = {
  type: actions.UPDATE_STORY_ELEMENT_ENTITIES;
  payload: { storyElementId: StoryElementId; newEntityAttribution: Entity | null };
};

export type VideoOEmbedSearchTermChange = {
  type: actions.VIDEO_OEMBED_SEARCH_TERM_CHANGE;
  payload: { term: string };
};

export type VideoOEmbedSearchSuccess = {
  type: actions.VIDEO_OEMBED_SEARCH_SUCCESS;
  payload: {
    videos: PralineVideo[];
    total: number;
    page?: number;
    token?: {
      prev: string | null;
      next: string | null;
    };
  };
};

//Composite story elements
export type StoryEditorMoveStoryElementUp = {
  type: actions.STORY_EDITOR_MOVE_STORY_ELEMENT_UP;
  payload: { imageStoryElement: StoryElement; parentStoryElement: StoryElement };
};

export type StoryEditorMoveStoryElementDown = {
  type: actions.STORY_EDITOR_MOVE_STORY_ELEMENT_DOWN;
  payload: { imageStoryElement: StoryElement; parentStoryElement: StoryElement };
};

// Timeline
export type AppendToTimeline = { type: actions.APPEND_TO_TIMELINE };
export type ResetTimeline = { type: actions.RESET_TIMELINE };

// UI stuff
export type SetIsStoryModifiedState = {
  type: actions.SET_IS_STORY_MODIFIED_STATE;
  payload: { isStoryModified: boolean };
};
export type SetStoryValidationErrors = { type: actions.SET_STORY_VALIDATION_ERRORS; payload: { errors: any } };
export type UpdatePublishInspector = { type: actions.UPDATE_PUBLISH_INSPECTOR };
export type UpdateStoryPublishAt = { type: actions.UPDATE_STORY_PUBLISH_AT };
export type SetPublishInspectorValidationErrors = {
  type: actions.SET_PUBLISH_INSPECTOR_VALIDATION_ERRORS;
  payload: { message: string };
};
export type UpdateInspectorData = { type: actions.UPDATE_INSPECTOR_DATA; payload: { data: InspectorData } };
export type SetImageForInspector = { type: actions.SET_IMAGE_FOR_INSPECTOR; payload: { image: Image[] | Array<{}> } };
export type UpdateImageDataForInspector = { type: actions.UPDATE_IMAGE_DATA_FOR_INSPECTOR };
export type SetTimelineEvent = { type: actions.SET_TIMELINE_EVENT; payload: { event: TimelineEvent } };
export type OpenStoryRestoreModal = {
  type: actions.OPEN_STORY_RESTORE_MODAL;
  payload: { value: boolean; versionId: StoryId };
};

export type CloseStoryRestoreModal = {
  type: actions.CLOSE_STORY_RESTORE_MODAL;
};

// Workflow stuff
export type UpdateStoryStatus = {
  type: actions.UPDATE_STORY_STATUS;
  payload: { workflowTransitions: WorkflowTransitions; status: StoryStatus; taskId: string };
};
export type SetWorkflowTransitions = {
  type: actions.SET_WORKFLOW_TRANSITIONS;
  payload: { workflowTransitions: WorkflowTransitions };
};
export type UpdateStoryLock = { type: actions.UPDATE_STORY_LOCK };

export type ToggleCardShare = { type: actions.TOGGLE_CARD_SHARE };
export type CardShareUpdate = {
  type: actions.CARD_SHARE_UPDATE;
  payload: { key: string; value: Image | string; cardId: CardId };
};
export type CardAttributesUpdate = { type: actions.CARD_ATTRIBUTES_UPDATE };
export type CardShareImageUpload = { type: actions.CARD_SHARE_IMAGE_UPLOAD };
export type CardShareImageDelete = { type: actions.CARD_SHARE_IMAGE_DELETE; payload: { cardId: CardId } };
export type FetchOpinionPoll = { type: actions.FETCH_OPINION_POLL };
export type SetOpinionPoll = {
  type: actions.SET_OPINION_POLL;
  payload: { storyElementId: StoryElementId; poll: Poll | UnsavedPoll };
};
export type UpdateOpinionPoll = {
  type: actions.UPDATE_OPINION_POLL;
  payload: { storyElementId: StoryElementId; key: string; value: any };
};
export type SaveOpinionPoll = {
  type: actions.SAVE_OPINION_POLL;
  payload: { storyElementId: StoryElementId };
};
export type SetImagesForImageGalleryElement = {
  type: actions.SET_IMAGES_FOR_IMAGE_GALLERY_ELEMENT;
  payload: {
    storyElementId: StoryElementId;
    images: Image[];
  };
};
export type SetOpinionPollId = {
  type: actions.SET_OPINION_POLL_ID;
  payload: { storyElementId: StoryElementId; pollId: PolltypeId };
};

export type SetOpinionPollInit = {
  type: actions.SET_OPINION_POLL_INIT;
  payload: {
    storyElementId: StoryElementId;
  };
};

export type SetOpinionPollSuccess = {
  type: actions.SET_OPINION_POLL_SUCCESS;
  payload: {
    storyElementId: StoryElementId;
  };
};

export type UpdateOpinionPollImageUploadStatus = {
  type: actions.UPDATE_OPINION_POLL_IMAGE_UPLOAD_STATUS;
  payload: {
    uploading: boolean;
  };
};

export type UpdateStorySearchTerm = {
  type: actions.UPDATE_STORY_SEARCH_TERM;
  payload: { term: string };
};
export type SetStoryList = {
  type: actions.SET_STORY_LIST;
  payload: { storyList: SearchedStory[] };
};

export type SetSelectedStory = {
  type: actions.SET_SELECTED_STORY;
  payload: { selectedStory: Story };
};
export type UpdateImportCardId = {
  type: actions.UPDATE_IMPORT_CARD_ID;
  payload: { cardId: CardId };
};
export type ImportCard = {
  type: actions.IMPORT_CARD;
  payload: { card: Card | null; storyElements: Array<StoryElement | CompositeStoryElement | ChildStoryElement> };
};
export type ResetImportCard = {
  type: actions.RESET_IMPORT_CARD;
};
export type ResetImportCardDisplay = {
  type: actions.RESET_IMPORT_CARD_DISPLAY;
};
export type ToggleStoryCleanUpConfirmation = {
  type: actions.TOGGLE_STORY_CLEAN_UP_CONFIRMATION;
  payload: { action: any };
};
export type ToggleStorySeoChecked = {
  type: actions.TOGGLE_STORY_SEO_CHECKED;
};
export type ResetStoryEditorState = {
  type: actions.RESET_STORY_EDITOR_STATE;
};
export type SetBanner = {
  type: actions.SET_BANNER;
  payload: { banner: {} | null };
};
export type StartSavingStory = {
  type: actions.START_SAVING_STORY;
};
export type StopSavingStory = {
  type: actions.STOP_SAVING_STORY;
};

export type StoryChangeInProgress = {
  type: actions.STORY_CHANGE_IN_PROGRESS;
};

export type PublishingStoryInit = {
  type: actions.PUBLISHING_STORY_INIT;
};

export type PublishingStoryFailure = {
  type: actions.PUBLISHING_STORY_FAILURE;
};

export type PlagiarismCheckInit = {
  type: actions.PLAGIARISM_CHECK_INIT;
};

export type PlagiarismCheckSuccess = {
  type: actions.PLAGIARISM_CHECK_SUCCESS;
  payload: { message: string; url: string };
};

export type PlagiarismCheckFailure = {
  type: actions.PLAGIARISM_CHECK_FAILURE;
  payload: { error: Error };
};

export type UpdateSelectedPlace = {
  type: actions.UPDATE_SELECTED_PLACE;
  payload: { place: string };
};

export type SetStoryTransitionInProgress = {
  type: actions.SET_STORY_TRANSITION_STATUS;
  payload: { isTransitionInProgress: boolean };
};

export type SetFormattingToolbarActive = {
  type: actions.SET_FORMATTING_TOOLBAR_ACTIVE;
};

export type SetFormattingToolbarInActive = {
  type: actions.SET_FORMATTING_TOOLBAR_INACTIVE;
};

export type UpdateEditorNotes = {
  type: actions.UPDATE_EDITOR_NOTES;
  payload: { editorNotes: string };
};

export type SetIsViewingReadOnlyVersion = {
  type: actions.SET_IS_VIEWING_READONLY_VERSION;
  payload: { isViewingReadOnlyVersion: boolean };
};

export type ReapplyEditorState = {
  type: actions.REAPPLY_EDITOR_STATE;
};

export type UPDATE_EMBARGO = {
  type: actions.UPDATE_EMBARGO;
  payload: { emabrgoedTill: number | null };
};

export type SetTextParaphrasingStatus = {
  type: actions.SET_TEXT_PARAPHRASING_STATUS;
  payload: boolean;
};
