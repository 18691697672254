/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { actions } from "./actions";
import { mapForCollectionRequest } from "helpers/image-keys-mapping";
import { rulesKeyMapping } from "pages/collections/utils";
import _pick from "lodash/pick";
import _merge from "lodash/merge";
import { removeKeyFromObject, getUniqueItems } from "utils";
import { NEW_COLLECTION, INITIAL_STATE, INITIAL_FILTER_SET } from "./initial-state";
import loaderReducer from "behaviors/loader/reducer";
import reduceReducers from "reduce-reducers";

const buildRulesQuery = (rules, collection) => {
  let defaultRules: any = {
    fields: "author-id,content-type,q,message,story-template,updated-at,id",
    "content-type": "story",
    sort: "updated-at"
  };
  defaultRules = collection.id ? { ...defaultRules, "collection-id": collection.id } : defaultRules;
  return { ...defaultRules, ...rules };
};

const pickSelectedKeys = (collection, newCollection) =>
  collection.id
    ? _pick(collection, [...Object.keys(newCollection), "id"])
    : _pick(collection, Object.keys(newCollection));

export const transformSelectedCollection = (collection) => {
  let selectedCollection = collection;
  if (
    selectedCollection.metadata &&
    selectedCollection.metadata["cover-image"] &&
    selectedCollection.metadata["cover-image"]["key"]
  ) {
    const mappedImages = mapForCollectionRequest(selectedCollection.metadata["cover-image"]);
    selectedCollection = {
      ...selectedCollection,
      metadata: { ...selectedCollection.metadata, "cover-image": mappedImages }
    };
  }

  return selectedCollection["data-source"] === "automated"
    ? {
        ...pickSelectedKeys(selectedCollection, NEW_COLLECTION),
        rules: buildRulesQuery(selectedCollection.rules, selectedCollection)
      }
    : pickSelectedKeys(selectedCollection, NEW_COLLECTION);
};

const formattedRuleValue = (key, value) => {
  switch (key) {
    case "story-attributes": {
      return value.reduce((finalattributes, attribute) => {
        finalattributes[attribute.name] = attribute.values;
        return finalattributes;
      }, {});
    }
    default:
      return Array.isArray(value)
        ? value
            .map((item) => {
              return item.id || item.slug || item;
            })
            .join(",")
        : value;
  }
};

const updateSelectedCollectionRules = (rules, payload) => {
  const { key, value } = payload;
  let finalRules = rules;
  if (rules) {
    const { "sort-by": removeSortBy, ...cleanRules } = rules;
    finalRules = key === "sort-automated-result" ? cleanRules : rules; //bug in bold which introduced extra sort-by key
  }

  let valueString = formattedRuleValue(key, value);
  return { ...finalRules, [rulesKeyMapping(key)]: valueString === "" ? null : valueString };
};

const updateSelectedCollection = (selectedCollection, payload) => {
  let { key, value } = payload;
  if (key === "story-attributes") {
    value = formattedRuleValue(key, value);
  }
  return { ...selectedCollection, [key]: value };
};

const updateFilter = (filters, payload) => {
  const { key, value } = payload;
  return { ...filters, [key]: value };
};

const updateItems = (items, itemIndex, key, value) =>
  items.map((item, index) => (index === itemIndex ? { ...item, [key]: value } : item));

const removeItem = (items, item) => items.filter((i) => item.id !== i.id);

const removeItemIds = (ids, id) => ids.filter((i) => id !== i);

const getDefaultOptions = (options) =>
  options.reduce((result, option) => (option.default ? { ...result, [option.name]: option.default } : result), {});

const updateSelectedCollectionCoverImages = (selectedImages, payload) => {
  const { key, value, imageIndex } = payload;

  return selectedImages.map((item, index) => {
    if (index !== imageIndex) {
      return item;
    }
    return {
      ...item,
      [key]: value
    };
  });
};

const collectionsReducer: any = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.UPDATE_STAGING_SELECTED_COLLECTION: {
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          stagingSelectedCollection: updateSelectedCollection(
            state.collectionPage.stagingSelectedCollection,
            action.payload
          )
        }
      };
    }
    case actions.UPDATE_SELECTED_COLLECTION: {
      const { items, "story-content-ids": storyContentIds } = state.collectionPage.selectedCollection!;
      const selectedCollection = {
        ...state.collectionPage.stagingSelectedCollection,
        items,
        "story-content-ids": storyContentIds
      };
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          selectedCollection: selectedCollection
        }
      };
    }

    case actions.SET_IS_COLLECTION_MODIFIED_STATE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isCollectionModified: action.payload.isCollectionModified
        }
      };
    }

    case actions.RESET_STAGING_SELECTED_COLLECTION: {
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          stagingSelectedCollection: state.collectionPage.selectedCollection
        }
      };
    }

    case actions.UPDATE_STAGING_SELECTED_COLLECTION_METADATA: {
      const stagingSelectedCollection: any = state.collectionPage.stagingSelectedCollection;
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          stagingSelectedCollection: {
            ...stagingSelectedCollection,
            metadata: updateSelectedCollection(stagingSelectedCollection.metadata, action.payload)
          }
        }
      };
    }

    case actions.UPDATE_STAGING_SELECTED_COLLECTION_RULES: {
      const stagingSelectedCollection = state.collectionPage.stagingSelectedCollection;
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          stagingSelectedCollection: {
            ...stagingSelectedCollection,
            "expanded-rules": updateSelectedCollection(
              stagingSelectedCollection && stagingSelectedCollection["expanded-rules"],
              action.payload
            ),
            rules: updateSelectedCollectionRules(
              stagingSelectedCollection && stagingSelectedCollection["rules"],
              action.payload
            )
          }
        }
      };
    }

    case actions.LOAD_LHS_COLLECTION_ITEMS: {
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          searchResults: {
            ...state.collectionPage.searchResults,
            [action.payload.contentType]: action.payload.data
          }
        }
      };
    }

    case actions.SET_LHS_REFRESH_STATE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isRefreshingLHS: action.payload.refreshingLHS
        }
      };
    }

    case actions.LOAD_MORE_LHS_COLLECTION_ITEMS: {
      const { contentType, data } = action.payload;
      const previousSearchResults = state.collectionPage.searchResults[contentType];
      const uniqueItemsLoaded = getUniqueItems([...previousSearchResults.items, ...data.items]);

      const numberOfDuplicateItems = previousSearchResults.items.length + data.items.length - uniqueItemsLoaded.length;
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          searchResults: {
            ...state.collectionPage.searchResults,
            [contentType]: {
              ...data,
              hits: numberOfDuplicateItems
                ? previousSearchResults.hits - numberOfDuplicateItems
                : previousSearchResults.hits,
              items: uniqueItemsLoaded
            }
          }
        }
      };
    }

    case actions.LOAD_TEMPLATE_OPTIONS: {
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          templateOptions: action.payload.templateOptions
        }
      };
    }

    case actions.SWITCH_LANE_TAB: {
      return {
        ...state,
        ui: {
          ...state.ui,
          selectedLaneTab: action.payload.selectedTab
        }
      };
    }

    case actions.ADD_ITEM: {
      const selectedCollection: any = state.collectionPage.selectedCollection;
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          selectedCollection: {
            ...selectedCollection,
            items: [action.payload.item, ...selectedCollection.items],
            "story-content-ids": [
              action.payload.item.id,
              ...(selectedCollection["story-content-ids"] ? selectedCollection["story-content-ids"] : [])
            ]
          }
        },
        ui: {
          ...state.ui,
          currentCardExpandedIndex: null
        }
      };
    }

    case actions.REMOVE_ITEM: {
      const selectedCollection: any = state.collectionPage.selectedCollection;
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          selectedCollection: {
            ...selectedCollection,
            items: removeItem(selectedCollection.items, action.payload.item),
            "story-content-ids": removeItemIds(selectedCollection["story-content-ids"], action.payload.item.id)
          }
        }
      };
    }

    case actions.UPDATE_FILTER: {
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          filters: updateFilter(state.collectionPage.filters, action.payload)
        }
      };
    }

    case actions.TOGGLE_ADVANCED_SEARCH: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isAllItemsFiltersExpanded: !state.ui.isAllItemsFiltersExpanded
        }
      };
    }

    case actions.CLOSE_ADVANCED_SEARCH: {
      return {
        ...state,
        ui: {
          ...state.ui,
          isAllItemsFiltersExpanded: false
        }
      };
    }

    case actions.UPDATE_SWAPPED_ITEMS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          swappedItems: action.payload.items
        }
      };
    }

    case actions.UPDATE_SORTED_ITEMS: {
      const selectedCollection = state.collectionPage.selectedCollection;
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          selectedCollection: {
            ...selectedCollection,
            items: action.payload.items
          }
        },
        ui: {
          ...state.ui,
          swappedItems: null
        }
      };
    }

    case actions.CHANGE_PAGE_VIEWS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          threshold: action.payload.data
        }
      };
    }

    case actions.TOGGLE_CARD_EXPAND: {
      return {
        ...state,
        ui: {
          ...state.ui,
          currentCardExpandedIndex:
            state.ui.currentCardExpandedIndex === action.payload.index ? null : action.payload.index
        }
      };
    }

    case actions.UPDATE_PAGE_VIEWS: {
      const selectedCollection: any = state.collectionPage.selectedCollection;
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          selectedCollection: {
            ...selectedCollection,
            items: updateItems(selectedCollection.items, action.payload.index, "threshold", state.ui["threshold"])
          }
        },
        ui: {
          ...state.ui,
          currentCardExpandedIndex: null,
          threshold: null
        }
      };
    }

    case actions.SET_STAGING_ASSOCIATED_METADATA: {
      return {
        ...state,
        ui: {
          ...state.ui,
          stagingAssociatedMetadata: action.payload.associatedMetadata
        }
      };
    }

    case actions.UPDATE_STAGING_ASSOCIATED_METADATA: {
      const { key, value } = action.payload;
      return {
        ...state,
        ui: {
          ...state.ui,
          stagingAssociatedMetadata: {
            ...state.ui.stagingAssociatedMetadata,
            [key]: value
          }
        }
      };
    }

    case actions.CHANGE_COLLECTION_LAYOUT: {
      const { value, options } = action.payload;

      return {
        ...state,
        ui: {
          ...state.ui,
          stagingAssociatedMetadata: { layout: value, ...getDefaultOptions(options) }
        }
      };
    }

    case actions.CANCEL_CARD_EXPAND: {
      const selectedCollection: any = state.collectionPage.selectedCollection;
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          selectedCollection: {
            ...selectedCollection,
            items: updateItems(selectedCollection.items, action.payload.index, action.payload.key, action.payload.value)
          }
        },
        ui: {
          ...state.ui,
          currentCardExpandedIndex: null,
          threshold: null,
          stagingAssociatedMetadata: {}
        }
      };
    }

    case actions.UPDATE_COLLECTION_CARD: {
      const selectedCollection: any = state.collectionPage.selectedCollection;

      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          selectedCollection: {
            ...selectedCollection,
            items: updateItems(selectedCollection.items, action.payload.index, action.payload.key, action.payload.value)
          }
        },
        ui: {
          ...state.ui,
          currentCardExpandedIndex: null,
          stagingAssociatedMetadata: {}
        }
      };
    }

    case actions.RESET_FILTER: {
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          filters: INITIAL_FILTER_SET
        }
      };
    }

    case actions.PUBLISHING: {
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          selectedCollection: transformSelectedCollection(state.collectionPage.selectedCollection)
        }
      };
    }

    case actions.PUBLISHED: {
      const collection = action.payload.collection,
        collectionWithItems = {
          ...collection,
          items: collection.items || []
        };

      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          selectedCollection: collectionWithItems,
          stagingSelectedCollection: collectionWithItems
        }
      };
    }

    case actions.RESET: {
      return INITIAL_STATE;
    }

    case actions.SET_SELECTED_COLLECTION: {
      const collection = action.payload.collection;

      return {
        ...state,
        ui: {
          ...state.ui,
          selectedLaneTab: "story",
          isAllItemsFiltersExpanded: false,
          currentCardExpandedIndex: null
        },
        collectionPage: {
          ...state.collectionPage,
          selectedCollection: collection,
          stagingSelectedCollection: collection,
          filters: INITIAL_FILTER_SET,
          searchResults: {
            story: { items: [], hits: 0 },
            collection: { items: [], hits: 0 },
            "draft-story": { items: [], hits: 0 }
          }
        }
      };
    }

    case actions.UPDATE_COVER_IMAGE: {
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          stagingSelectedCollection: {
            ...state.collectionPage.stagingSelectedCollection,
            metadata: {
              ...state.collectionPage.stagingSelectedCollection!.metadata,
              "cover-image": state.collectionPage.selectedCollectionCoverImages[0]
            }
          }
        }
      };
    }

    case actions.SET_COVER_IMAGES: {
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          selectedCollectionCoverImages: action.payload.images
        }
      };
    }

    case actions.REMOVE_COVER_IMAGE: {
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          selectedCollectionCoverImages: [],
          stagingSelectedCollection: {
            ...state.collectionPage.stagingSelectedCollection,
            metadata: {
              ...state.collectionPage.stagingSelectedCollection!.metadata,
              "cover-image": {}
            }
          }
        }
      };
    }

    case actions.UPDATE_COVER_IMAGE_DATA: {
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          selectedCollectionCoverImages: updateSelectedCollectionCoverImages(
            state.collectionPage.selectedCollectionCoverImages,
            action.payload
          )
        }
      };
    }
    case actions.UPDATE_EDITED_COVER_IMAGE: {
      return {
        ...state,
        collectionPage: {
          ...state.collectionPage,
          stagingSelectedCollection: {
            ...state.collectionPage.stagingSelectedCollection,
            metadata: {
              ...state.collectionPage.stagingSelectedCollection!.metadata,
              "cover-image": action.payload.image
            }
          }
        }
      };
    }

    case actions.TOGGLE_COVER_IMAGE_UPLOADING: {
      return {
        ...state,
        ui: {
          ...state.ui,
          coverImageUploading: action.payload.status
        }
      };
    }

    case actions.SET_FIELD_EMPTY_ERROR: {
      return {
        ...state,
        ui: {
          ...state.ui,
          errors: {
            ...state.ui.errors,
            [action.payload.field]: true
          }
        }
      };
    }

    case actions.REMOVE_FIELD_ERROR: {
      return {
        ...state,
        ui: {
          ...state.ui,
          errors: removeKeyFromObject(action.payload.field, state.ui.errors)
        }
      };
    }

    case actions.RESET_ERRORS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          errors: {}
        }
      };
    }

    case actions.SET_STORY_CARD_PAGE_VIEWS_SUCCESS: {
      return {
        ...state,
        storiesPageViews: _merge(state.storiesPageViews, action.payload.pageViews),
        collectionPage: {
          ...state.collectionPage,
          searchResults: {
            ...state.collectionPage.searchResults,
            story: {
              ...state.collectionPage.searchResults.story,
              items: action.payload.stories
            }
          }
        }
      };
    }

    case actions.SET_MORE_STORY_CARD_PAGE_VIEWS_SUCCESS: {
      return {
        ...state,
        storiesPageViews: _merge(state.storiesPageViews, action.payload.pageViews),
        collectionPage: {
          ...state.collectionPage,
          searchResults: {
            ...state.collectionPage.searchResults,
            story: {
              ...state.collectionPage.searchResults.story,
              items: getUniqueItems([...state.collectionPage.searchResults.story.items, ...action.payload.stories])
            }
          }
        }
      };
    }

    case actions.OPEN_PHOTO_EDITOR: {
      return {
        ...state,
        app: {
          ...state.app,
          photoEditor: {
            visible: true,
            selectedImage: action.payload.image
          }
        }
      };
    }
    case actions.CLOSE_PHOTO_EDITOR: {
      return {
        ...state,
        app: {
          ...state.app,
          photoEditor: {
            visible: false,
            selectedImage: null
          }
        }
      };
    }
    case actions.PDF_UPLOAD: {
      return {
        ...state,
        ui: {
          ...state.ui,
          showFileUploading: true,
          uploadFileName: action.payload
        }
      };
    }

    case actions.PDF_UPLOAD_SUCCESS: {
      return {
        ...state,
        ui: {
          ...state.ui,
          showFileUploading: false
        }
      };
    }
    case actions.PDF_UPLOAD_FAILURE: {
      return {
        ...state,
        ui: {
          ...state.ui,
          showFileUploading: false
        }
      };
    }
    case actions.SET_SCHEDULE_COLLECTION_DATE_ERROR: {
      return {
        ...state,
        ui: {
          ...state.ui,
          errors: {
            ...state.ui.errors,
            [action.payload.field]: true
          }
        }
      };
    }
    case actions.TOGGLE_POLYGON_SELECTION: {
      return {
        ...state,
        ui: {
          ...state.ui,
          showPolygonSelection: !state.ui.showPolygonSelection
        }
      };
    }

    case actions.UPDATE_GEO_POLYGON: {
      return {
        ...state,
        app: {
          ...state.app,
          geoPolygon: action.payload.geoPolygon
        }
      };
    }

    default:
      return state;
  }
};

const mainLoaderReducer: any = loaderReducer(
  {
    BEGIN: actions.SINGLE_COLLECTION_PAGE_LOADING,
    SUCCESS: actions.SINGLE_COLLECTION_PAGE_LOAD_SUCCESS,
    FAILURE: actions.SINGLE_COLLECTION_PAGE_LOAD_FAILURE
  },
  (state: any) => state.areas.main,
  (state: any, loaderState) => ({
    ...state,
    areas: { ...state.areas, main: { ...state.areas.main, ...loaderState } }
  })
);

export default reduceReducers(collectionsReducer, mainLoaderReducer);
