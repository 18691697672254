/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Story, StoryElement, StoryElementUI } from "api/story";
import { fetchPollDetailsAction, createPollAction, editPollAction } from "pages/story-editor/async-action-creators";
import ImageActionBar, { ImageAction, ImageActionType } from "components/image-action-bar/image-action-bar";
import { HeroImage } from "api/polls";
import { Image } from "api/search-media-image";
import { formattedDate } from "utils";
import classnames from "classnames/bind";
import styles from "./opinion-poll.module.css";
import Plus from "components/icons/plus";
import { t } from "i18n";

const cx = classnames.bind(styles);

interface StateProps {
  story: Story;
  CDNCname: string;
}

interface DispatchProps {
  fetchPollDetails: (storyElementId: string, pollTypeId: number) => void;
  createPoll: (storyId: string) => void;
  editPoll: (storyId: string, image: HeroImage | {}) => void;
}

interface OwnProps {
  storyElement: StoryElement;
  storyElementUI: StoryElementUI;
  isDisabled: boolean;
}

type Props = OwnProps & StateProps & DispatchProps;

export const OpinionPoll: React.FC<Props> = ({
  storyElement,
  fetchPollDetails,
  storyElementUI,
  createPoll,
  editPoll,
  isDisabled,
  story,
  CDNCname
}) => {
  const fetchPollDetailsRef = useRef(fetchPollDetails);
  const pollDetails = storyElementUI && storyElementUI.poll;
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);

  useEffect(() => {
    if (storyElement.id && storyElement["polltype-id"]) {
      fetchPollDetailsRef.current(storyElement.id, Number(storyElement["polltype-id"]));
    }
  }, [storyElement]);

  const addHeroImageUrl = (image) => {
    return {
      ...image,
      url: `${CDNCname}${image["s3-key"]}`
    };
  };

  if (!storyElement["polltype-id"]) {
    return (
      <div
        tabIndex={0}
        className={cx("poll-element-create-new-container", { "is-disabled": isDisabled })}
        onClick={() => createPoll(story["story-content-id"])}>
        <Plus />
        <span className={styles["poll-element-create-new"]} data-test-id="poll-element-create-new">
          {t("story-editor.story-element.create_poll")}
        </span>
      </div>
    );
  } else if (storyElement["polltype-id"] && pollDetails) {
    const showPollElementHeroImage = pollDetails["hero-image"] && pollDetails["hero-image"]["s3-key"];
    const imageActions: ImageAction[] = [new ImageAction(ImageActionType.DownloadImage)];
    return (
      <div
        className={styles["poll-element"]}
        onClick={() => editPoll(story["story-content-id"], pollDetails["hero-image"])}>
        {showPollElementHeroImage && (
          <figure className={styles["poll-element-image-container"]}>
            <img
              className={styles["poll-element-image"]}
              src={`${CDNCname}${pollDetails["hero-image"]["s3-key"]}`}
              alt={pollDetails["hero-image"]["caption"]}
              onLoad={(e) => setIsImageLoading(false)}
            />
            <ImageActionBar
              classname={cx("poll-element-image-action-bar", {
                "poll-element-image-action-bar--hide": isImageLoading
              })}
              data-test-id="image-action-buttons"
              actions={imageActions}
              image={addHeroImageUrl(pollDetails["hero-image"])}
            />
          </figure>
        )}
        <h3 className={styles["poll-element-topic"]}>{pollDetails.topic}</h3>
        <div className={styles["poll-element-desc"]} dangerouslySetInnerHTML={{ __html: pollDetails.description }} />
        <div className={styles["poll-element-meta"]}>
          <time className={styles["poll-element-published-at"]} dateTime={`${pollDetails["updated-at"]}`}>
            {`${t("story-editor.story-element.published_on")}: ${formattedDate(pollDetails["updated-at"])}`}
          </time>
          <span className={styles["poll-element-responses"]}>
            {pollDetails["total-votes"]} {t("story-editor.story-element.responses")}
          </span>
        </div>
        <ul className={styles["poll-element-options-container"]}>
          {pollDetails.opinions.map((opinion) => (
            <li key={`poll-element-option-${opinion.text}`} className={styles["poll-element-option"]}>
              <span className={styles["poll-element-option-text"]}>{opinion.text}</span>
              <span className={styles["poll-element-option-percentage"]}>{opinion["percentage-votes"]}</span>
              <span
                className={styles["poll-element-option-background"]}
                style={{ width: `${opinion["percentage-votes"]}%` }}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state): StateProps => {
  return {
    story: state.storyEditor.story,
    CDNCname: state.config["cdn-cname"]
  };
};
const mapDispatchToProps = (dispatch, ownProps): DispatchProps => {
  return {
    fetchPollDetails: (storyElementId: string, pollTypeId: number) =>
      dispatch(fetchPollDetailsAction(storyElementId, pollTypeId)),
    editPoll: (storyId, image) => dispatch(editPollAction(storyId, ownProps.storyElement.id, image as Image)),
    createPoll: (storyId) => dispatch(createPollAction(storyId, ownProps.storyElement.id))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(OpinionPoll);
