/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { FilterInput, RulesInput } from "./initial-state";
import { CollectionDateFilter, CollectionDateFilterTypes } from "components/date-filter/types";
import { ListCollectionItemsParams } from "api/collection-items";
import { AnyCollection, RulesSortBy, DataSource, ManualCollection } from "api/story-collection";
import { Timestamp } from "api/primitive-types";
import { subDays, subMonths } from "date-fns";
import { Attribute } from "api/story-attributes";

const datesFn = (filter: CollectionDateFilter): [Timestamp, Timestamp] => {
  let today = new Date().setHours(0, 0, 0, 0);
  let now = Date.now();
  switch (filter.type) {
    case CollectionDateFilterTypes.Today:
      return [today, now];
    case CollectionDateFilterTypes.ThisWeek:
      return [subDays(today, 7).getTime(), now];
    case CollectionDateFilterTypes.ThisMonth:
      return [subMonths(today, 1).getTime(), now];
    case CollectionDateFilterTypes.Custom:
      return [filter.from, filter.to];

    default:
      throw new Error("Collections: Unknown date filter type received: " + filter.type);
  }
};

export function buildBreakingNewsParams(selectedCollection: ManualCollection) {
  const storyParams = {};
  const collectionParams = {};

  storyParams["content-type"] = "breaking-news";

  if (selectedCollection.rules && selectedCollection.rules["breaking-news-type"]) {
    storyParams["breaking-news-type"] = selectedCollection.rules["breaking-news-type"];
  }

  return [storyParams, collectionParams];
}

const filterRuleItems = (filterSet: FilterInput | RulesInput, filterKey: string) => {
  return filterSet[filterKey].map((item: any) => item.id).join(",");
};

export function buildFilterParams(filterSet: FilterInput | RulesInput, selectedCollection: AnyCollection | null) {
  let storyParams: ListCollectionItemsParams = {
      fields: "author-id,content-type,q,message,story-template,updated-at,id"
    },
    collectionParams: ListCollectionItemsParams = {
      fields: "author-id,content-type,q,message,story-template,updated-at,id",
      "exclude-template": "author"
    },
    scheduledStoryParams: ListCollectionItemsParams = {
      fields: "author-id,content-type,q,message,story-template,updated-at,id",
      "content-type": "draft-story",
      "publish-at": "null",
      status: "scheduled"
    },
    dataSource: DataSource | null = selectedCollection && selectedCollection["data-source"];

  storyParams["content-type"] = "story";

  collectionParams["content-type"] = "collection";
  collectionParams["exclude-status"] = "scheduled";

  storyParams["limit"] = collectionParams["limit"] = scheduledStoryParams["limit"] = 20;

  if (filterSet.offset) {
    storyParams["offset"] = collectionParams["offset"] = scheduledStoryParams["offset"] = filterSet.offset;
  }

  const term = filterSet.term!.trim();
  if (term !== "") {
    storyParams.q = collectionParams.q = scheduledStoryParams.q = term;
  }

  if (!term) {
    storyParams.sort = collectionParams.sort = RulesSortBy.PublishedAt;
    scheduledStoryParams.sort = RulesSortBy.UpdatedAt;
  }

  if (filterSet["sort-automated-result"]) {
    storyParams["sort-automated-result"] = filterSet["sort-automated-result"];
  }

  if (!selectedCollection) {
    return [storyParams, collectionParams, scheduledStoryParams];
  }

  if (dataSource === DataSource.Manual) {
    switch (selectedCollection.template) {
      case "breaking-news":
        {
          const [BNStoryParams, BNCollectionParams] = buildBreakingNewsParams(selectedCollection as ManualCollection);
          storyParams = { ...storyParams, ...BNStoryParams };
          collectionParams = { ...collectionParams, ...BNCollectionParams };
        }
        break;
      default:
        break;
    }

    if (filterSet.publishedAt) {
      [storyParams["published-after"], storyParams["published-before"]] = datesFn(filterSet.publishedAt);
    }
    if (filterSet.storyTemplates && filterSet.storyTemplates.length) {
      storyParams["story-template"] = filterSet.storyTemplates.map((storyTemplate) => storyTemplate.slug).join(",");
      scheduledStoryParams["story-template"] = filterSet.storyTemplates
        .map((storyTemplate) => storyTemplate.slug)
        .join(",");
    }
  }

  if (filterSet.sections && filterSet.sections.length) {
    storyParams["section-id"] = filterRuleItems(filterSet, "sections");
    scheduledStoryParams["section-id"] = filterRuleItems(filterSet, "sections");
  }

  if (filterSet["exclude-sections"] && filterSet["exclude-sections"].length) {
    storyParams["exclude-section-id"] = filterRuleItems(filterSet, "exclude-sections");
    scheduledStoryParams["exclude-section-id"] = filterRuleItems(filterSet, "exclude-sections");
  }

  if (filterSet.location && filterSet.location["is-enabled"]) {
    storyParams["location"] = filterSet.location;
    scheduledStoryParams["location"] = filterSet.location;
  }

  if (filterSet.authors && filterSet.authors.length) {
    storyParams["author-id"] = filterRuleItems(filterSet, "authors");
    scheduledStoryParams["author-id"] = filterRuleItems(filterSet, "authors");
  }

  if (filterSet.tags && filterSet.tags.length) {
    storyParams["tag-id"] = filterRuleItems(filterSet, "tags");
    scheduledStoryParams["tag-id"] = filterRuleItems(filterSet, "tags");
  }

  if (filterSet.entities && filterSet.entities.length) {
    storyParams["entity-id"] = filterRuleItems(filterSet, "entities");
    scheduledStoryParams["entity-id"] = filterRuleItems(filterSet, "entities");
  }

  if (filterSet.storyAttributes && filterSet.storyAttributes.length > 0) {
    filterSet.storyAttributes.forEach((attribute: Attribute) => {
      storyParams["story-attributes." + attribute.name] = attribute.values.join(",");
      scheduledStoryParams["story-attributes." + attribute.name] = attribute.values.join(",");
    });
  }

  if (filterSet["story-attributes"]) {
    storyParams["story-attributes"] = filterSet["story-attributes"];
    scheduledStoryParams["story-attributes"] = filterSet["story-attributes"];
  }

  if (dataSource === DataSource.Automated) {
    if (filterSet["story-template"] && filterSet["story-template"].length) {
      storyParams["story-template"] = filterSet["story-template"]
        .map((storyTemplate: string) => storyTemplate)
        .join(",");
      scheduledStoryParams["story-template"] = filterSet["story-template"]
        .map((storyTemplate: string) => storyTemplate)
        .join(",");
    }
    if (filterSet["trending-duration-hours"] && !term) {
      storyParams["trending-duration-hours"] = filterSet["trending-duration-hours"];
    }
  }

  return [storyParams, collectionParams, scheduledStoryParams];
}
