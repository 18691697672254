/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import RadioButton from "components/radio-button/radio-button";
import FieldLabel from "components/field-label/field-label";
import "./listicle-title-numbering.module.css";

const BULLETS = "bullets",
  NUMBERS = "123",
  NUMBERS_REVERSE = "321";

const ListicleTitleNumbering = ({ numberingType = BULLETS, onChangeOfListicleTitleNumbering, disabled }) => {
  return (
    <div className="listicle-title-numbering-container">
      <FieldLabel label={t("story-editor.listicle.title-numbering")} classname="listicle-title-numbering-title" />
      <div className="listicle-title-numbering-type-wrapper">
        <RadioButton
          id="listicle-title-numbering-bullets"
          name="listicle-title-numbering"
          label={t("story-editor.listicle.bullets")}
          checked={numberingType === BULLETS}
          onChange={(val) => onChangeOfListicleTitleNumbering(BULLETS)}
          classname="listicle-radio-button"
          isDisabled={disabled}
        />
        <RadioButton
          id="listicle-title-numbering-123"
          name="listicle-title-numbering"
          label="123"
          checked={numberingType === NUMBERS}
          onChange={(val) => onChangeOfListicleTitleNumbering(NUMBERS)}
          classname="listicle-radio-button"
          isDisabled={disabled}
        />
        <RadioButton
          id="listicle-title-numbering-numbers-reverse"
          name="listicle-title-numbering"
          label="321"
          checked={numberingType === NUMBERS_REVERSE}
          onChange={(val) => onChangeOfListicleTitleNumbering(NUMBERS_REVERSE)}
          classname="listicle-radio-button"
          isDisabled={disabled}
        />
      </div>
    </div>
  );
};

export default ListicleTitleNumbering;
