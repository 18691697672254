/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import produce from "immer";
import { State, INITIAL_TAGS_STATE, NEW_TAG, TagPageInspectorType } from "./state";
import actions, { ActionTypes } from "./actions";

function reducer(state: State = INITIAL_TAGS_STATE, action: ActionTypes) {
  return produce(state, (draft) => {
    switch (action.type) {
      case actions.LIST_TAGS:
        draft.ui.main = { loading: true, error: null };
        draft.app.searchTerm = action.payload.searchTerm ? action.payload.searchTerm : "";
        break;

      case actions.LIST_TAGS_SUCCESS:
        draft.ui.main = { loading: false, error: null };
        draft.tags = action.payload.tags;
        draft.pagination = action.payload.pagination;
        break;

      case actions.LIST_TAGS_FAILURE:
        draft.ui.main = { loading: false, error: action.payload.error };
        break;

      case actions.REPLACE_CURRENT_TAG:
        draft.app.currentTag = action.payload.tag;
        break;

      case actions.INIT_NEW_TAG:
        draft.app.currentTag = NEW_TAG;
        draft.ui.inspector = TagPageInspectorType.Create;
        break;

      case actions.EDIT_TAG:
        draft.app.currentTag = action.payload.tag;
        draft.ui.inspector = TagPageInspectorType.Edit;
        break;

      case actions.EDIT_TAG_FAILURE:
        draft.ui.main = { loading: false, error: action.payload.error };
        break;

      case actions.CANCEL_CREATE_OR_EDIT_TAG:
        draft.app.currentTag = null;
        draft.ui.inspector = null;
        draft.ui.save = { loading: false, error: null };
        break;

      case actions.SAVE_TAG:
        draft.ui.save = { loading: true, error: null };
        break;

      case actions.SAVE_TAG_SUCCESS:
        draft.ui.save = { loading: false, error: null };
        draft.app.currentTag = null;
        draft.ui.inspector = null;
        break;

      case actions.SAVE_TAG_FAILURE:
        draft.ui.save = { loading: false, error: action.payload.error };
        break;

      case actions.FETCH_STORY_COUNTS:
        action.payload.tags.forEach((tag) => {
          draft.ui.tags[tag.id] = { loading: true, error: null, storyCount: null };
        });
        break;

      case actions.FETCH_STORY_COUNTS_SUCCESS:
        action.payload.tags.forEach((tag) => {
          draft.ui.tags[tag.id] = {
            loading: false,
            error: null,
            storyCount: action.payload.storyCounts[tag.id] || null
          };
        });
        break;

      case actions.FETCH_STORY_COUNTS_FAILURE:
        const error = action.payload.error;
        action.payload.tags.forEach((tag) => {
          draft.ui.tags[tag.id] = { loading: false, error, storyCount: null };
        });
        break;

      case actions.CHANGE_SPECIFIED_PAGE:
        draft.ui.specifiedPage = action.payload.pageNumber;
        break;

      case actions.SET_GENERATED_SLUG:
        draft.app.currentTag = action.payload.tag;
        break;

      default:
        break;
    }
  });
}

export default reducer;
