/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import {
  IntegrationListByType,
  IntegrationType,
  PartialAppState,
  Publisher
} from "pages/settings/pages/integrations/state";
import { connect } from "react-redux";
import { IntegrationConfig, Integrations as PublisherIntegrations } from "api/integrations";
import { get, toLower } from "lodash";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { navigate } from "utils/routes.utils";
import { INTEGRATION_SETTINGS } from "pages/settings/pages/integrations/routes";
import Integration from "pages/settings/pages/integrations/components/integration/integration";
import styles from "./integration-list.module.css";
import NoContentCard from "components/no-content-card/no-content-card";
import File from "components/icons/file";
import Button from "components/button/button";
import { t } from "i18n";

interface DispatchProps {
  openIntegrationSettings: (type: IntegrationType) => void;
}

interface StateProps {
  integrationListByType: IntegrationListByType;
  integrationType: IntegrationType;
  publisherSettings: Publisher;
}

type Props = DispatchProps & StateProps;

export const getDefaultPNProvidersIntegration = (publisherSettings: Publisher): IntegrationConfig => {
  const webProvider = get(publisherSettings, ["push-notification", "provider", "web"]);
  const mobileProvider = get(publisherSettings, ["push-notification", "provider", "mobile"]);
  return {
    key: PublisherIntegrations.DefaultProviders,
    value: {
      web: webProvider && t(`integrations.providers.${toLower(webProvider)}`, webProvider),
      mobile: mobileProvider && t(`integrations.providers.${toLower(mobileProvider)}`, mobileProvider),
      disabled: true,
      type: IntegrationType.ALL
    }
  };
};

const IntegrationList: React.FC<Props> = ({
  integrationListByType,
  integrationType,
  publisherSettings,
  openIntegrationSettings
}) => {
  const publisherIntegrationsByType: IntegrationConfig[] = get(integrationListByType, [integrationType], []);
  const webProvider = get(publisherSettings, ["push-notification", "provider", "web"]);
  const mobileProvider = get(publisherSettings, ["push-notification", "provider", "mobile"]);
  const showNoDefaultPNProvidersBanner =
    integrationType === IntegrationType.PUSH_NOTIFICATION && !webProvider && !mobileProvider;
  const showDefaultPNProviders =
    integrationType === IntegrationType.PUSH_NOTIFICATION && (!!webProvider || !!mobileProvider);

  return publisherIntegrationsByType && publisherIntegrationsByType.length > 0 ? (
    <>
      {showDefaultPNProviders && (
        <Integration integration={getDefaultPNProvidersIntegration(publisherSettings)} hideContextMenu={true} />
      )}
      {showNoDefaultPNProvidersBanner && (
        <div className={styles["integrations-no-content"]} data-test-id="integrations-configure-default-providers">
          <NoContentCard>
            <File />
            <div className={styles["no-content-message-container"]}>
              <span className={styles["no-content-message"]}>
                {t("integrations.push-notification.no-default-provider")}
              </span>
              <Button onClick={() => openIntegrationSettings(integrationType)} type="link">
                {t("integrations.push-notification.set-default-providers")}
              </Button>
            </div>
          </NoContentCard>
        </div>
      )}
      {publisherIntegrationsByType.map((integration) => (
        <Integration data-test-id="temp5" integration={integration} />
      ))}
    </>
  ) : (
    <NoContentCard>
      <File />
      <div className={styles["no-content-message-container"]} data-test-id={"integrations-no-integration-added"}>
        <span className={styles["no-content-message"]}>{t("integrations.messages.no_integration_added")}</span>
      </div>
    </NoContentCard>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    integrationListByType: state.integrations.app.integrationListByType,
    integrationType: state.integrations.app.integrationType,
    publisherSettings: state.integrations.app.publisher
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    openIntegrationSettings: (type: IntegrationType) => dispatch(navigate(INTEGRATION_SETTINGS, { type }))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationList);

export { IntegrationList };
