/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { get } from "lodash";
import nextId from "react-id-generator";
import { t } from "i18n";
import pDebounce from "p-debounce";
import InfiniteScrollList from "components/infinite-scroll-list/infinite-scroll-list";
import { navigate } from "utils/routes.utils";
import { FORMS_EDITOR_PATH_EDIT } from "pages/forms/routes";
import { showFeatureNotSetupPage, showUnauthorizedPage } from "store/error-page";
import { Form, Format } from "store/form/form";
import {
  PartialAppState,
  FormActionsState,
  setIsLoading,
  fetchForms,
  retractForm,
  deleteForm,
  exportSubmissions
} from "store/form/dashboard";
import { selectForms } from "store/form/selectors";
import wrapPage from "containers/page/page";
import Spinner from "components/spinner/spinner";
import File from "components/icons/file";
import NoContentCard from "components/no-content-card/no-content-card";
import ContentHeader from "pages/settings/components/content-header/content-header";
import SearchBar from "components/search-bar/search-bar";
import FormPanel from "pages/forms/dashboard/panel/panel";
import styles from "./dashboard.module.css";

interface StateProps {
  title: string;
  isFormsEnabled?: boolean;
  canCreateForms?: boolean;
  isLoading: boolean;
  forms: Form[];
  hits: number;
  formActionsState: NormalizedObject<FormActionsState>;
}

interface DispatchProps {
  goToNewFormPath: () => void;
  fetchForms: (limit: number) => void;
  fetchNextForms: (limit: number) => void;
  searchForms: (term: string) => void;
  retractForm: (formId: number) => void;
  deleteForm: (formId: number) => void;
  exportSubmissions: (formId: number, format: Format) => void;
  showFeatureNotSetupPage: () => void;
  showUnauthorizedPage: () => void;
}

type Props = StateProps & DispatchProps;

export const FormsDashboard: React.FC<Props> = ({
  isFormsEnabled,
  canCreateForms,
  isLoading,
  forms,
  hits,
  goToNewFormPath,
  fetchForms,
  fetchNextForms,
  retractForm,
  deleteForm,
  exportSubmissions,
  searchForms,
  showFeatureNotSetupPage,
  showUnauthorizedPage,
  formActionsState
}) => {
  useEffect(() => {
    if (!isFormsEnabled) {
      showFeatureNotSetupPage();
    } else if (!canCreateForms) {
      showUnauthorizedPage();
    } else {
      fetchForms(20);
    }
  }, [canCreateForms, fetchForms, isFormsEnabled, showFeatureNotSetupPage, showUnauthorizedPage]);

  const [searchTerm, setSearchTerm] = useState("");
  const formsCount = forms.length;
  const hasMoreForms = hits > formsCount;
  const getNextForms = () => fetchNextForms(formsCount);
  const onCreateButtonClick = (_) => goToNewFormPath();
  const onSearch = (term: string) => {
    setSearchTerm(term);
    searchForms(term);
  };

  const endMessage =
    formsCount === 0 ? t("forms.dashboard.messages.no-forms") : t("forms.dashboard.messages.no-more-forms");
  const formListUI = forms.map((form) => (
    <FormPanel
      form={form}
      retractForm={retractForm}
      deleteForm={deleteForm}
      exportSubmissions={exportSubmissions}
      exportState={get(formActionsState, [form.id, "export"], {})}
      key={nextId("form-panel-")}
    />
  ));

  return (
    <div>
      <ContentHeader
        title={t("forms.dashboard.title")}
        actionButtonLabel={t("common.add")}
        actionButtonType={"primary"}
        handleActionButtonClick={onCreateButtonClick}
      />
      <SearchBar value={searchTerm} placeholder={t("common.search")} onChange={onSearch} />
      {isLoading ? (
        <Spinner message={t("forms.dashboard.loading")} classname={styles["forms-dashboard-spinner"]} />
      ) : (
        <InfiniteScrollList
          dataLength={formsCount}
          next={getNextForms}
          hasMore={hasMoreForms}
          loader={<Spinner message={t("forms.dashboard.loading")} />}
          endMessage={
            <NoContentCard>
              <File />
              {endMessage}
            </NoContentCard>
          }>
          <>{formListUI}</>
        </InfiniteScrollList>
      )}
    </div>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    title: "form-dashboard",
    isFormsEnabled: state.features.isFormsEnabled,
    canCreateForms: state.features.canCreateForms,
    isLoading: state.forms.dashboard.isLoading,
    forms: selectForms(state.forms.dashboard),
    hits: state.forms.dashboard.hits,
    formActionsState: state.forms.dashboard.formActionsState
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  const debouncedFetchForms = pDebounce(async (term: string) => await dispatch(fetchForms({ q: term })), 1000);
  const withSetLoading = (dispatchAction: Function) => async (...args: any) => {
    dispatch(setIsLoading(true));
    await dispatchAction(...args);
    dispatch(setIsLoading(false));
  };

  return {
    goToNewFormPath: () => dispatch(navigate(FORMS_EDITOR_PATH_EDIT, { formId: "new" })),
    fetchNextForms: (limit) => dispatch(fetchForms({ limit: limit + 20 })),
    fetchForms: withSetLoading((limit: number) => dispatch(fetchForms({ limit: limit }))),
    searchForms: withSetLoading((term: string) => debouncedFetchForms(term)),
    retractForm: withSetLoading((formId: number) => dispatch(retractForm(formId))),
    deleteForm: withSetLoading((formId: number) => dispatch(deleteForm(formId))),
    exportSubmissions: (formId: number, format: Format) => dispatch(exportSubmissions(formId, format)),
    showFeatureNotSetupPage: () => dispatch(showFeatureNotSetupPage()),
    showUnauthorizedPage: () => dispatch(showUnauthorizedPage())
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), wrapPage({}))(FormsDashboard);
