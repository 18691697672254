/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

import TextField from "components/text-field/text-field";
import NumberField from "components/number-field/number-field";
import Checkbox from "components/checkbox/checkbox";
import { AssociatedMetadata } from "api/story-collection";
import { CollectionLayoutOptions } from "api/template-options";
import styles from "./associated-metadata-field.module.css";

interface Props {
  option: CollectionLayoutOptions;
  associatedMetadata: AssociatedMetadata;
  updateStagingAssociatedMetadata: (name: string, val: string | number | boolean) => void;
}

const AssociatedMetadataField: React.SFC<Props> = ({ option, associatedMetadata, updateStagingAssociatedMetadata }) => {
  const { type, name } = option;
  if (type === "string") {
    return (
      <TextField
        label={name}
        value={associatedMetadata[name] as string}
        onChange={(val: string) => updateStagingAssociatedMetadata(name, val)}
      />
    );
  } else if (type === "number") {
    return (
      <NumberField
        label={name}
        value={associatedMetadata[name] ? (associatedMetadata[name] as number) : 0}
        onChange={(val: number) => updateStagingAssociatedMetadata(name, val)}
      />
    );
  } else {
    return (
      <div className={styles["associated-metadata-checkbox-field"]}>
        <Checkbox
          id={`associated-metadata-${name}`}
          label={name}
          checked={associatedMetadata[name] as boolean}
          onChange={(val: boolean) => updateStagingAssociatedMetadata(name, val)}
        />
      </div>
    );
  }
};

export default AssociatedMetadataField;
