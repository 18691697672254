/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Analytics, Top } from "api/analytics";
import { PartialFilterOptions, Range } from "./state";
import { AuthorId } from "api/primitive-types";

enum actions {
  "SET_FILTER_OPTIONS" = "@@analytics/SET_FILTER_OPTIONS",
  "ANALYTICS_FETCH_SUCCESS" = "@@analytics/ANALYTICS_FETCH_SUCCESS",
  "TOGGLE_ANALYTICS_SHARE" = "@@analytics/TOGGLE_ANALYTICS_SHARE",
  "SET_CUSTOM_RANGE" = "@@analytics/SET_CUSTOM_RANGE",
  "OPEN_SUBLIST" = "@@analytics/OPEN_SUBLIST",
  "CLOSE_SUBLIST" = "@@analytics/CLOSE_SUBLIST",
  "AUTHOR_STORIES_FETCH_SUCCESS" = "@@analytics/AUTHOR_STORIES_FETCH_SUCCESS"
}

export type AnalyticsFetchSuccess = {
  type: actions.ANALYTICS_FETCH_SUCCESS;
  payload: {
    analytics: Analytics;
  };
};

export type SetFilterOptions = {
  type: actions.SET_FILTER_OPTIONS;
  payload: {
    filterOptions: PartialFilterOptions;
  };
};

export type ToggleAnalyticsShare = {
  type: actions.TOGGLE_ANALYTICS_SHARE;
};

export type SetCustomRange = {
  type: actions.SET_CUSTOM_RANGE;
  payload: { customRange: Range };
};

export type OpenSublist = {
  type: actions.OPEN_SUBLIST;
  payload: { authorId: AuthorId };
};

export type AuthorStoriesSuccessAction = {
  type: actions.AUTHOR_STORIES_FETCH_SUCCESS;
  payload: { authorStories: Top[] };
};

export type CloseSublist = {
  type: actions.CLOSE_SUBLIST;
};

export type ActionTypes =
  | AnalyticsFetchSuccess
  | SetFilterOptions
  | ToggleAnalyticsShare
  | SetCustomRange
  | OpenSublist
  | AuthorStoriesSuccessAction
  | CloseSublist;

export default actions;
