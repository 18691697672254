/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { EditorState } from "prosemirror-state";
import { Node, Schema } from "prosemirror-model";
import { Selection } from "prosemirror-state";
import { keymap } from "prosemirror-keymap";
import { customKeymapPlugin } from "../plugins/handlekeyDown";
import { baseKeymap } from "prosemirror-commands";
import { storyToPMDoc } from "./story-to-prosemirror";
import { buildKeymap } from "../plugins/keymap";
import { schema, paragraphSchema } from "./schema";
import { DOMParser } from "prosemirror-model";
import textPlaceholder from "../plugins/placeholder";
import linter from "../plugins/linter";
import { toolbar } from "../plugins/toolbar/toolbar";
import { Story } from "api/story";
import { PMElement } from "./types";
import { history } from "prosemirror-history";
import appendTransactions from "../plugins/append-transactions";
import filterTransactions from "../plugins/filter-transactions";
import { safeTextSelection } from "../operations/selection";

const paragraphParser = DOMParser.fromSchema(paragraphSchema);
const schemaParser = DOMParser.fromSchema(schema);

export function makeEditorState(
  story: Story,
  editorState: EditorState<Schema> | {} = {},
  selection: Selection<Schema> | null = null
): EditorState<Schema> {
  const PMStory: PMElement = storyToPMDoc(story, {
    schemaParser,
    paragraphParser,
    editorState
  });

  const PMDoc = Node.fromJSON(schema, PMStory);

  return EditorState.create({
    schema,
    doc: PMDoc,
    selection: selection ? safeTextSelection(PMDoc, selection.anchor, selection.head) : safeTextSelection(PMDoc, 0),
    plugins: [
      history(),
      keymap(buildKeymap(schema)),
      keymap(baseKeymap),
      textPlaceholder(),
      linter(),
      toolbar(),
      filterTransactions(),
      appendTransactions(),
      customKeymapPlugin(schema)
    ]
  });
}
