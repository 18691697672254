/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { INITIAL_LOADER_STATE, LoaderState } from "behaviors/loader/state";
import { SocialAccount } from "api/social-accounts";
import { StoryEditorState } from "pages/story-editor/state";

export interface PartialAppState {
  storyEditor: StoryEditorState;
  socialShare: SocialShareState;
}

export enum InspectorType {
  Add = "add"
}

export interface SocialShareState {
  socialAccounts: SocialAccount[];
  ui: {
    main: LoaderState;
    inspector: InspectorType | null;
    selectedService: string;
  };
}

export const INITIAL_SOCIAL_SHARE_STATE: SocialShareState = {
  socialAccounts: [],
  ui: {
    main: INITIAL_LOADER_STATE,
    inspector: null,
    selectedService: "facebook"
  }
};
