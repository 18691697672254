/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import debounce from "p-debounce";
import { searchAuthors } from "api/author";
import { searchTags } from "api/tag";
import { CollectionMetadataMap } from "api/route-data/collection-route-data";
import { Collection, AnyCollection } from "api/story-collection";
import { getEntities } from "helpers/api";
import { PageViewFields } from "./initial-state";

export const getCollectionTemplateOptions = (metadata: CollectionMetadataMap) => Object.values(metadata);

export const getSelectedTemplate = (template: string, metadata: CollectionMetadataMap) =>
  getCollectionTemplateOptions(metadata).find((t) => t.id === template);

export const getMetadataFields = (template: string, metadata: CollectionMetadataMap) => {
  const collectionTemplateMetadata = getSelectedTemplate(template, metadata);

  return collectionTemplateMetadata && collectionTemplateMetadata["metadata-fields"];
};

export const generateRssFeedURL = (sketchesHost: string, slug: string) =>
  `${sketchesHost}/api/v1/collections/${slug}.rss`;

export const loadTags = debounce(searchTags, 250);

export const loadAuthorsData = debounce(searchAuthors, 250);

export const searchEntities = debounce(getEntities, 250);

export const rulesKeyMapping = (key: string) => {
  switch (key) {
    case "tags":
      return "tag-id";
    case "entities":
      return "entity-id";
    case "sections":
      return "section-id";
    case "exclude-sections":
      return "exclude-section-id";
    case "authors":
      return "author-id";
    default:
      return key;
  }
};

export const mandatoryFields = ["name"];

export function isExistingCollection(collection: AnyCollection): collection is Collection {
  return !!(collection as Collection).id;
}

export const getViewChanges = (pageViewFields: PageViewFields | null | undefined) => {
  if (pageViewFields && pageViewFields.delta && pageViewFields.delta > 0) {
    return "increased";
  } else if (pageViewFields && pageViewFields.delta && pageViewFields.delta < 0) {
    return "decreased";
  } else {
    return "constant";
  }
};

export const getFormattedPageViewsDelta = (pageViewFields: PageViewFields | null | undefined) => {
  if (pageViewFields && pageViewFields.delta !== null && pageViewFields.delta !== 0) {
    const delta =
      pageViewFields.delta && pageViewFields.delta > 0
        ? "+" + pageViewFields.delta
        : pageViewFields && pageViewFields.delta;
    return delta + "%";
  }
  return null;
};
