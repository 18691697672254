/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { remove } from "lodash";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";

import { notificationError, notificationSuccess } from "action-creators/notification";
import {
  getAllWebhooks,
  getWebhook as getWebhookApi,
  deleteWebhook as deleteWebhookApi,
  updateWebhook as updateWebhookApi,
  createWebhook as createWebhookApi,
  getWebhookLogs as getWebhookLogsApi,
  WebhookData,
  UpdatedWebhook,
  WebhookDetails,
  WebhookPayload,
  NewWebhook,
  CreatedWebhook,
  WebhookLogData
} from "api/webhooks";
import { t } from "i18n";
import { navigate } from "utils/routes.utils";
import { WEBHOOKS_ADD_PATH, WEBHOOKS_EDIT_PATH, WEBHOOKS_PATH } from "pages/settings/pages/webhooks/routes";
import { validateWebhookChange } from "pages/settings/pages/webhooks/validate";

export const INITIAL_WEBHOOKS_STATE: State = {
  webhooks: [],
  logData: { logs: [], pagination: { limit: 10, offset: 0, total: 0 } },
  app: { currentWebhook: null, newWebhook: null, error: null, moreWebhooksToLoad: true, loadingLogs: false }
};

export interface EventDetails {
  id: number;
  slug:
    | "story-approve"
    | "story-publish"
    | "collection-publish"
    | "story-submit"
    | "story-reject"
    | "story-retract"
    | "story-create"
    | "story-delete"
    | "story-schedule"
    | "story-schedule-modify"
    | "story-schedule-with-collection"
    | "story-schedule-cancel"
    | "story-print";
}
export interface ValidationError {
  url?: string[];
}

export interface State {
  webhooks: WebhookData;
  logData: WebhookLogData;
  app: {
    currentWebhook: WebhookDetails | null;
    newWebhook: NewWebhook | null;
    error: ValidationError | null;
    moreWebhooksToLoad: boolean;
    loadingLogs: boolean;
  };
}

export interface PartialAppState {
  webhooks: State;
  config: {
    "trigger-events": EventDetails[];
  };
}

interface WebhookDataWithLimit {
  webhooks: WebhookData;
  limit: number;
}

const { reducer, actions } = createSlice({
  initialState: INITIAL_WEBHOOKS_STATE,
  name: "webhooks",
  reducers: {
    getWebhooksSuccess: (state: State, { payload }: PayloadAction<WebhookDataWithLimit>) => {
      const { webhooks, limit } = payload;
      state.webhooks = [...state.webhooks, ...webhooks];
      if (webhooks.length < limit) {
        state.app.moreWebhooksToLoad = false;
      }
    },
    getWebhooksFailure: (state: State) => {
      state.app.moreWebhooksToLoad = false;
    },
    resetWebhookState: (state: State) => {
      state.webhooks = [];
      state.app = { currentWebhook: null, newWebhook: null, error: null, moreWebhooksToLoad: true, loadingLogs: false };
    },
    deleteWebhookSuccess: (state: State, { payload }: PayloadAction<number>) => {
      remove(state.webhooks, (webhook) => webhook.id === payload);
    },
    updateWebhookSuccess: (state: State, { payload }: PayloadAction<UpdatedWebhook>) => {
      let webhookToUpdate = state.webhooks.find((webhook) => webhook.id === payload.id);
      if (webhookToUpdate) {
        webhookToUpdate.url = payload.url;
        webhookToUpdate.active = payload.active;
      }
    },
    loadWebhookSuccess: (state: State, { payload }: PayloadAction<WebhookDetails>) => {
      state.app.currentWebhook = payload;
    },
    setCurrentWebhookData: (state: State, { payload }: PayloadAction<Partial<WebhookPayload>>) => {
      state.app.currentWebhook = { ...state.app.currentWebhook, ...payload } as WebhookDetails;
    },
    setNewWebhookData: (state: State, { payload }: PayloadAction<Partial<NewWebhook>>) => {
      state.app.newWebhook = { ...state.app.newWebhook, ...payload } as NewWebhook;
    },
    createWebhookSuccess: (state: State, { payload }: PayloadAction<CreatedWebhook>) => {
      state.webhooks.unshift(payload);
    },
    setValidationErrors: (state: State, { payload }: PayloadAction<ValidationError>) => {
      state.app.error = { ...state.app.error, ...payload };
    },
    clearValidationErrors: (state: State) => {
      state.app.error = null;
    },
    startLoadingWebhookLogs: (state: State) => {
      state.app.loadingLogs = true;
    },
    getWebhookLogsSuccess: (state: State, { payload }: PayloadAction<WebhookLogData>) => {
      state.logData = payload;
      state.app.loadingLogs = false;
    },
    getWebhookLogsFailure: (state: State) => {
      state.app.loadingLogs = false;
    }
  }
});

const {
  createWebhookSuccess,
  getWebhooksSuccess,
  getWebhooksFailure,
  deleteWebhookSuccess,
  updateWebhookSuccess,
  loadWebhookSuccess,
  setCurrentWebhookData,
  setNewWebhookData,
  setValidationErrors,
  clearValidationErrors,
  resetWebhookState,
  startLoadingWebhookLogs,
  getWebhookLogsSuccess,
  getWebhookLogsFailure
} = actions;

export const resetWebhookData = () => async (dispatch: ThunkDispatch<PartialAppState, any, AnyAction>) => {
  dispatch(resetWebhookState());
};

function getMessageForError(e) {
  switch (e) {
    case "invalid-webhook-data":
      return t("settings.webhooks.messages.invalid_data");
    case "webhook-not-found":
      return t("settings.webhooks.messages.not_found");
    default:
      return t("settings.webhooks.messages.unknown_error");
  }
}

export const getWebhooks = (offset: number) => async (dispatch: ThunkDispatch<PartialAppState, any, AnyAction>) => {
  try {
    const limit = 20;
    const webhooks = await getAllWebhooks({ offset, limit });
    dispatch(getWebhooksSuccess({ webhooks, limit }));
  } catch (e) {
    dispatch(getWebhooksFailure());
    dispatch(notificationError(getMessageForError(e)));
  }
};

export const deleteWebhook = (webhookId: number) => async (
  dispatch: ThunkDispatch<PartialAppState, any, AnyAction>
) => {
  try {
    await deleteWebhookApi(webhookId);
    dispatch(deleteWebhookSuccess(webhookId));
    dispatch(notificationSuccess(t("settings.webhooks.messages.delete_success")));
  } catch (e) {
    dispatch(notificationError(getMessageForError(e)));
  }
};

export const updateWebhookStatus = (webhookId: number, status: boolean) => async (
  dispatch: ThunkDispatch<PartialAppState, any, AnyAction>
) => {
  try {
    const updatedWebhook = await updateWebhookApi(webhookId, { active: status });
    dispatch(updateWebhookSuccess(updatedWebhook));
  } catch (e) {
    dispatch(notificationError(getMessageForError(e)));
  }
};

export const updateWebhook = (webhookId: number, webhookData: Partial<WebhookPayload>) => async (
  dispatch: ThunkDispatch<PartialAppState, any, AnyAction>
) => {
  try {
    const updatedWebhook = await updateWebhookApi(webhookId, { ...webhookData });
    dispatch(updateWebhookSuccess(updatedWebhook));
    dispatch(navigate(WEBHOOKS_PATH));
    dispatch(notificationSuccess(t("settings.webhooks.messages.update_success")));
  } catch (e) {
    dispatch(notificationError(getMessageForError(e)));
  }
};

function validateChange(change: Partial<NewWebhook>, dispatch: ThunkDispatch<PartialAppState, any, AnyAction>) {
  if (Object.keys(change).includes("url")) {
    const validationErrors = validateWebhookChange(change);
    if (validationErrors) {
      dispatch(setValidationErrors(validationErrors));
    } else {
      dispatch(clearValidationErrors());
    }
  }
}

export const setCurrentWebhook = (change: Partial<WebhookPayload>) => async (
  dispatch: ThunkDispatch<PartialAppState, any, AnyAction>
) => {
  validateChange(change, dispatch);
  dispatch(setCurrentWebhookData(change));
};

export const getWebhook = (webhookId: number) => async (dispatch: ThunkDispatch<PartialAppState, any, AnyAction>) => {
  try {
    const webhookData = await getWebhookApi(webhookId);
    dispatch(loadWebhookSuccess(webhookData));
    dispatch(clearValidationErrors());
    dispatch(navigate(WEBHOOKS_EDIT_PATH, { id: webhookId }));
  } catch (e) {
    dispatch(notificationError(getMessageForError(e)));
  }
};

export const openAddWebhookInspector = () => async (dispatch: ThunkDispatch<PartialAppState, any, AnyAction>) => {
  dispatch(setNewWebhookData({ url: "", secret: "", events: [] }));
  dispatch(clearValidationErrors());
  dispatch(navigate(WEBHOOKS_ADD_PATH));
};

export const setNewWebhook = (change: Partial<NewWebhook>) => async (
  dispatch: ThunkDispatch<PartialAppState, any, AnyAction>
) => {
  validateChange(change, dispatch);
  dispatch(setNewWebhookData(change));
};

export const createWebhook = (webhookData: NewWebhook) => async (
  dispatch: ThunkDispatch<PartialAppState, any, AnyAction>
) => {
  try {
    const createdWebhook = await createWebhookApi(webhookData);
    dispatch(createWebhookSuccess(createdWebhook));
    dispatch(navigate(WEBHOOKS_PATH));
    dispatch(notificationSuccess(t("settings.webhooks.messages.create_success")));
  } catch (e) {
    dispatch(notificationError(getMessageForError(e)));
  }
};

export const getWebhookLogs = (webhookId, offset: number) => async (
  dispatch: ThunkDispatch<PartialAppState, any, AnyAction>
) => {
  try {
    dispatch(startLoadingWebhookLogs());
    const limit = 10;
    const logData = await getWebhookLogsApi(webhookId, { offset, limit });
    dispatch(getWebhookLogsSuccess(logData));
  } catch (e) {
    dispatch(getWebhookLogsFailure());
    dispatch(notificationError(getMessageForError(e)));
  }
};

export default reducer;
