/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Spinner from "components/spinner/spinner";
import Plus from "components/icons/plus";
import Button from "components/button/button";
import { LoaderState } from "behaviors/loader/state";
import { TimeoutError } from "api/errors";
import { t } from "i18n";

interface LoaderProps {
  loader: LoaderState;
  loadSettingsPageData: () => void;
}

const Loader: React.SFC<LoaderProps> = ({ loader: { loading, error }, loadSettingsPageData }) => {
  if (loading) {
    return <Spinner message={t("sections.messages.loading-list")} />;
  }
  if (error) {
    if (error instanceof TimeoutError) {
      return (
        <React.Fragment>
          <Plus width="48" height="48" />
          <p>{t("sections.messages.could-not-load-list")}</p>
          <p>
            <Button onClick={loadSettingsPageData}>{t("sections.cta.load-retry")}</Button>
          </p>
        </React.Fragment>
      );
    }
  }

  return <p>{t("sections.messages.unknown-error")}</p>;
};

export default Loader;
