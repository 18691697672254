/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { BreakingNews, ValidationErrors, BreakingNewsValidationFields } from "api/breaking-news";
import actions, {
  UpdateBreakingNews,
  InitializeNewBreakingNews,
  SetLinkType,
  UpdateStoryTemplateFields,
  SaveStoryTemplateFields,
  SaveBreakingNews,
  EnableBreakingNewsPublish,
  BreakingNewsSaveError,
  BreakingNewsSaveSuccess,
  FetchSocialAccounts,
  ShowSocialAccounts,
  UpdateSocialAccounts,
  LoadBreakingNewsStory,
  BreakingNewsStoryLoadSuccess,
  BreakingNewsStoryLoadError,
  CleanUpBreakingNewsState
} from "./actions";
import { LinkType } from "./constants";
import { StoryTemplateFields, PartialStoryTemplateFields, SocialAccount } from "./state";
import { Story } from "api/story";

export const initializeNewBreakingNewsAction = (payload: {
  breakingNews: BreakingNews;
  linkType: LinkType;
}): InitializeNewBreakingNews => {
  return {
    type: actions.INITIALIZE_NEW_BREAKING_NEWS,
    payload
  };
};

export const fetchSocialAccountsAction = (payload: {
  socialAccounts: SocialAccount[];
  isSocialAccountsAvailable: boolean;
}): FetchSocialAccounts => {
  return {
    type: actions.FETCH_SOCIAL_ACCOUNTS,
    payload
  };
};

export const showSocialAccountsAction = (showSocialAccounts: boolean): ShowSocialAccounts => {
  return {
    type: actions.SHOW_SOCIAL_ACCOUNTS,
    payload: {
      showSocialAccounts
    }
  };
};

export const updateSocialAccountsAction = (socialAccounts: SocialAccount[]): UpdateSocialAccounts => {
  return {
    type: actions.UPDATE_SOCIAL_ACCOUNTS,
    payload: {
      socialAccounts
    }
  };
};

export const updateBreakingNewsAction = (breakingNews: BreakingNews): UpdateBreakingNews => {
  return {
    type: actions.UPDATE_BREAKING_NEWS,
    payload: {
      breakingNews: breakingNews
    }
  };
};

export const setLinkTypeAction = (linkType: LinkType, breakingNews: BreakingNews): SetLinkType => {
  return {
    type: actions.SET_LINK_TYPE,
    payload: {
      linkType,
      breakingNews
    }
  };
};

export const updateStoryTemplateFieldsAction = (
  storyTemplateFields: StoryTemplateFields
): UpdateStoryTemplateFields => {
  return {
    type: actions.UPDATE_STORY_TEMPLATE_FIELDS,
    payload: {
      storyTemplateFields
    }
  };
};

export const saveStoryTemplateFieldsAction = (
  storyTemplateFields: PartialStoryTemplateFields
): SaveStoryTemplateFields => {
  return {
    type: actions.SAVE_STORY_TEMPLATE_FIELDS,
    payload: {
      storyTemplateFields: storyTemplateFields
    }
  };
};

export const saveBreakingNewsAction = (): SaveBreakingNews => {
  return {
    type: actions.SAVE_BREAKING_NEWS
  };
};

export const breakingNewsSaveSuccessAction = (): BreakingNewsSaveSuccess => {
  return {
    type: actions.BREAKING_NEWS_SAVE_SUCCESS
  };
};

export const breakingNewsSaveErrorAction = (
  validationErrors: ValidationErrors<BreakingNewsValidationFields>
): BreakingNewsSaveError => {
  return {
    type: actions.BREAKING_NEWS_SAVE_ERROR,
    payload: {
      validationErrors
    }
  };
};

export const enableBreakingNewsPublishAction = (enableBreakingNewsPublish: boolean): EnableBreakingNewsPublish => {
  return {
    type: actions.ENABLE_BREAKING_NEWS_PUBLISH,
    payload: {
      enableBreakingNewsPublish
    }
  };
};

export const loadBreakingNewsStoryAction = (): LoadBreakingNewsStory => {
  return {
    type: actions.LOAD_BREAKING_NEWS_STORY
  };
};

export const breakingNewsStoryLoadSuccessAction = (
  breakingNewsStory: Story,
  breakingNews: BreakingNews
): BreakingNewsStoryLoadSuccess => {
  return {
    type: actions.BREAKING_NEWS_STORY_LOAD_SUCCESS,
    payload: {
      breakingNewsStory,
      breakingNews
    }
  };
};

export const breakingNewsStoryLoadErrorAction = (error: any): BreakingNewsStoryLoadError => {
  return {
    type: actions.BREAKING_NEWS_STORY_LOAD_ERROR,
    payload: {
      error
    }
  };
};

export const cleanUpBreakingNewsStateAction = (): CleanUpBreakingNewsState => {
  return {
    type: actions.CLEAN_UP_BREAKING_NEWS_STATE
  };
};
