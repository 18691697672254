/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import CustomMetadataWithRoutes from "./custom-metadata-with-routes";

import { CUSTOM_METADATA_EDIT_PATH, CUSTOM_METADATA_INDEX_PATH, CUSTOM_METADATA_NEW_PATH } from "./routes";

export const Router = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={CUSTOM_METADATA_INDEX_PATH} component={CustomMetadataWithRoutes} />
        <Route exact path={CUSTOM_METADATA_NEW_PATH} component={CustomMetadataWithRoutes} />
        <Route exact path={CUSTOM_METADATA_EDIT_PATH} component={CustomMetadataWithRoutes} />
      </Switch>
    </React.Fragment>
  );
};
