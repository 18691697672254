/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const CUSTOM_URL_INDEX_PATH = "/manage/custom-urls";
export const CUSTOM_URL_NEW_PATH = "/manage/custom-urls/new";
export const CUSTOM_URL_EDIT_PATH = "/manage/custom-urls/:id";
