/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, { UpdateStorySlugSettingsAction } from "./actions";
import { StorySlugSettingsState } from "pages/settings/initial-state";

export function updateStorySlug(storySlugSettings: StorySlugSettingsState): UpdateStorySlugSettingsAction {
  return { type: actions.UPDATE_STORY_SLUG_SETTINGS, payload: storySlugSettings };
}

export function enableStorySlugSaveButton() {
  return { type: actions.ENABLE_STORY_SLUG_SAVE_BUTTON };
}

export function disableStorySlugSaveButton() {
  return { type: actions.DISABLE_STORY_SLUG_SAVE_BUTTON };
}
