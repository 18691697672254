/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";

import { saveDefaults, getAssociatedStoryDefaults, AssociatedStoryDefaults } from "api/breaking-news-defaults";
import {
  fetchAssociatedStoryDefaultsAction,
  fetchAssociatedStoryDefaultsSuccessAction,
  updateAssociatedStoryDefaultsAction,
  saveAssociatedStoryDefaultsAction,
  associatedStoryDefaultsSaveSuccessAction,
  fetchAssociatedStoryDefaultsErrorAction,
  associatedStoryDefaultsSaveErrorAction
} from "./action-creators";
import { PartialAppState } from "./state";
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from "containers/page/actions";

export const loadBreakingNewsDefaults = () => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(fetchAssociatedStoryDefaultsAction());
  await getAssociatedStoryDefaults()
    .then((associatedStoryFeature) => {
      dispatch(fetchAssociatedStoryDefaultsSuccessAction(associatedStoryFeature));
    })
    .catch(() => {
      dispatch({
        type: NOTIFICATION_ERROR,
        payload: {
          message: t("settings.breaking-news-defaults.server-error")
        }
      });
      dispatch(fetchAssociatedStoryDefaultsErrorAction());
    });
};

export const updateAssociatedStoryDefaults = (updatedAssociatedStoryDefaults: AssociatedStoryDefaults) => (
  dispatch: ThunkDispatch<any, any, any>
) => dispatch(updateAssociatedStoryDefaultsAction(updatedAssociatedStoryDefaults));

export const saveAssociatedStoryDefaults = () => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  dispatch(saveAssociatedStoryDefaultsAction());

  const associatedStoryDefaults = getState().breakingNewsDefaults.associatedStoryDefaults;
  if (associatedStoryDefaults) {
    try {
      await saveDefaults(associatedStoryDefaults);
      dispatch(associatedStoryDefaultsSaveSuccessAction());
      dispatch({
        type: NOTIFICATION_SUCCESS,
        payload: {
          message: t("settings.breaking-news-defaults.save-success")
        }
      });
    } catch (error) {
      if (error.status === 422) {
        const validationErrors = JSON.parse(error.message);
        if (validationErrors) {
          dispatch(associatedStoryDefaultsSaveErrorAction(validationErrors.errors));
        }
      } else {
        dispatch({
          type: NOTIFICATION_ERROR,
          payload: {
            message: t("settings.breaking-news-defaults.server-error")
          }
        });
      }
    }
  }
};
