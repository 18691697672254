/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import styles from "./dropdown.module.css";

interface DropdownProps {
  items: Array<String>;
  getItemLabel: (item: any) => string;
  onItemClick?: (element: any, event: React.MouseEvent) => void;
}

const DropDown = ({ items, onItemClick, getItemLabel }: DropdownProps) => {
  return (
    <React.Fragment>
      <ul className={styles["dropdown"]}>
        {items.map((item: any, index: number) => {
          const list = onItemClick ? (
            <li
              key={`dropdown-item-${index}`}
              onClick={(event: React.MouseEvent) => onItemClick(item, event)}
              className={styles["dropdown-item"]}>
              {getItemLabel(item)}
            </li>
          ) : (
            <li>{getItemLabel(item)}</li>
          );
          return list;
        })}
      </ul>
    </React.Fragment>
  );
};

export default DropDown;
