/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { findCardNP, findElementNP } from "../../find";
import { addIfNotExists } from "utils/array.utils";
import { CardId } from "api/primitive-types";
import { AnyStory, Card, ChildStoryElement, CompositeStoryElement, StoryElement } from "api/story";
import { EditorState } from "prosemirror-state";
import { getDistinctStoryElements } from "pages/story-editor/utils";
import { moveStoryElementToPosition } from "./utils";
import { Direction } from "pages/story-editor/operations/card/move";

function move(arr: Array<any>, from: number, to: number) {
  let array = arr.slice();

  let element = array[from];
  array[from] = array[to];
  array[to] = element;

  return array;
}

function updateUpdatedCard(updatedCards: Array<String>, cardId: CardId) {
  return cardId ? addIfNotExists(updatedCards, cardId) : updatedCards;
}

function moveStoryElementDownWithinACard(story: AnyStory, cardId: CardId, elementIndex: number): AnyStory {
  const updatedCards = updateUpdatedCard(story["updated-cards"], cardId);
  const cardTree = story.cards[cardId].tree;

  return {
    ...story,
    cards: {
      ...story.cards,
      [cardId]: {
        ...story.cards[cardId],
        tree: getDistinctStoryElements(move(cardTree, elementIndex, elementIndex + 1))
      }
    },
    "updated-cards": updatedCards
  };
}

function moveStoryElementDownBetweenCards(
  story: AnyStory,
  currentCardId: CardId,
  nextCardId: CardId,
  currentStoryElement: StoryElement | CompositeStoryElement | ChildStoryElement
): AnyStory {
  const currentStoryElementId = currentStoryElement.id;
  const updatedCards = [currentCardId, nextCardId].reduce(updateUpdatedCard, story["updated-cards"]);
  const newTree = story.cards[currentCardId].tree.slice();
  newTree.splice(-1, 1);

  return {
    ...story,
    cards: {
      ...story.cards,
      [currentCardId]: {
        ...story.cards[currentCardId],
        tree: getDistinctStoryElements(newTree)
      },
      [nextCardId]: {
        ...story.cards[nextCardId],
        tree: getDistinctStoryElements([currentStoryElementId, ...story.cards[nextCardId].tree])
      }
    },
    "story-elements": {
      ...story["story-elements"],
      [currentStoryElementId]: currentStoryElement
    },
    "updated-cards": updatedCards
  };
}

function moveStoryElementDownWithinCardNode(
  editorState: EditorState,
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement,
  nextStoryElement: StoryElement | CompositeStoryElement | ChildStoryElement
): EditorState {
  const tr = editorState.tr;
  const elementNP = findElementNP(editorState, storyElement);
  const nextElementNP = findElementNP(editorState, nextStoryElement);

  if (elementNP && nextElementNP) {
    const insertPosition = nextElementNP.pos + nextElementNP.node.nodeSize;
    const trWithFocus = moveStoryElementToPosition(
      tr,
      editorState,
      Direction.DOWN,
      elementNP,
      insertPosition,
      storyElement
    );
    return editorState.apply(trWithFocus);
  }
  return editorState;
}

function moveStoryElementDownBetweenCardNodes(
  editorState: EditorState,
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement,
  nextCardFirstElement: StoryElement | CompositeStoryElement | ChildStoryElement
): EditorState {
  const tr = editorState.tr;
  const elementNP = findElementNP(editorState, storyElement);
  const nextCardFirstElementNP = findElementNP(editorState, nextCardFirstElement);

  if (elementNP && nextCardFirstElementNP) {
    const trWithFocus = moveStoryElementToPosition(
      tr,
      editorState,
      Direction.DOWN,
      elementNP,
      nextCardFirstElementNP.pos,
      storyElement
    );
    return editorState.apply(trWithFocus);
  }
  return editorState;
}

function moveStoryElementDownToEmptyCard(
  editorState: EditorState,
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement,
  nextCard: Card
): EditorState {
  const tr = editorState.tr;
  const elementNP = findElementNP(editorState, storyElement);
  const emptyCardNP = findCardNP(editorState, nextCard);

  if (elementNP && emptyCardNP) {
    const insertPosition = emptyCardNP.pos + 1;
    const trWithFocus = moveStoryElementToPosition(
      tr,
      editorState,
      Direction.DOWN,
      elementNP,
      insertPosition,
      storyElement
    );
    return editorState.apply(trWithFocus);
  }
  return editorState;
}

function moveStoryElementDown(
  story: AnyStory,
  editorState: EditorState,
  storyElement: StoryElement | CompositeStoryElement | ChildStoryElement
): {
  story: AnyStory;
  editorState: EditorState;
} {
  const storyTreeSize = story.tree.length;

  const currentCardId = storyElement["card-id"];
  const currentCardTree = story.cards[currentCardId].tree;
  const currentCardTreeSize = currentCardTree.length;
  const currentCardIndex = story.tree.findIndex((cardData) => cardData["content-id"] === currentCardId);

  const currentStoryElementIndex = currentCardTree.findIndex((storyElementId) => storyElementId === storyElement.id);

  if (currentStoryElementIndex === currentCardTreeSize - 1) {
    if (currentCardIndex === storyTreeSize - 1) {
      return { story: story, editorState: editorState };
    } else {
      const nextCardId = story.tree[currentCardIndex + 1]["content-id"];
      const nextCardVersion = story.tree[currentCardIndex + 1]["version-id"];
      const nextCardFirstElementId = story.cards[nextCardId].tree[0];
      const nextCardFirstElement = story["story-elements"][nextCardFirstElementId];
      const nextCard = story.cards[nextCardId];
      const nextCardSize = nextCard.tree.length;
      const newStoryElement = { ...storyElement, "card-id": nextCardId, "card-version-id": nextCardVersion };

      const newStory = moveStoryElementDownBetweenCards(story, currentCardId, nextCardId, newStoryElement);

      if (nextCardSize === 0) {
        const newEditorState = moveStoryElementDownToEmptyCard(editorState, storyElement, nextCard);
        return { story: newStory, editorState: newEditorState };
      } else {
        const newEditorState = moveStoryElementDownBetweenCardNodes(editorState, newStoryElement, nextCardFirstElement);
        return { story: newStory, editorState: newEditorState };
      }
    }
  } else {
    const nextStoryElementId = currentCardTree[currentStoryElementIndex + 1];
    const nextStoryElement = story["story-elements"][nextStoryElementId];

    const newStory = moveStoryElementDownWithinACard(story, currentCardId, currentStoryElementIndex);
    const newEditorState = moveStoryElementDownWithinCardNode(editorState, storyElement, nextStoryElement);

    return { story: newStory, editorState: newEditorState };
  }
}

export default moveStoryElementDown;
