/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { INITIAL_LOADER_STATE, LoaderState } from "behaviors/loader/state";
import { PromotionalMessages, Config } from "api/promotional-messages";
import { Features } from "api/route-data/route-data";

export interface PartialAppState {
  promotionalMessages: State;
  config: {
    publisherWideBannerMessage: string | null;
  };
  features: Features;
}

export interface State {
  promotionalMessages: PromotionalMessages | null;
  promotionalMessagesConfig: Config | null;
  ui: {
    main: LoaderState;
    isPromotionalMessagesModified: boolean;
  };
}

export const INITIAL_PROMOTIONAL_MESSAGE_STATE: State = {
  promotionalMessages: null,
  promotionalMessagesConfig: null,
  ui: {
    main: INITIAL_LOADER_STATE,
    isPromotionalMessagesModified: false
  }
};
