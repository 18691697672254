/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import styles from "./analytics.module.css";
import Stats from "../stat/stat";
import Graph from "../graph/graph";
import { xAxisLabelFormatter } from "pages/analytics/utils";
import { Timestamp } from "api/primitive-types";
import * as analyticsAPI from "api/analytics";
import { CurrentFilter } from "pages/analytics/state";
import { t } from "i18n";
import { DIMENSION } from "pages/analytics/constants";

interface Props {
  analytics: analyticsAPI.Analytics | null;
  currentFilter: CurrentFilter;
}

const Analytics: React.SFC<Props> = ({ analytics, currentFilter }) => {
  if (!analytics) {
    return null;
  }

  return (
    <React.Fragment>
      <div className={styles["analytics-container__views-wrapper"]}>
        <Stats
          total={analytics["dim-count"]}
          statChange={analytics["dim-change"]}
          statsLabel={
            currentFilter.dimension === DIMENSION.AUTHOR
              ? t("analytics.stats.author_plural")
              : t("analytics.stats.dimension", { dimension: t(`analytics.stats.${currentFilter.dimension}`) })
          }
          showDelta={currentFilter.dateRange["name"] !== "custom" && currentFilter.dimension !== DIMENSION.AUTHOR}
        />
        <Stats
          total={analytics["metric-count"]}
          statChange={analytics["metric-change"]}
          statsLabel={t("analytics.stats.metrics", { metrics: t(`analytics.${currentFilter.metrics}`) })}
          showDelta={currentFilter.dateRange["name"] !== "custom"}
        />
      </div>
      <Graph
        data={analytics["time-slices"]}
        options={{
          xAxisLabelFormatter: (timeStamp: Timestamp) =>
            xAxisLabelFormatter(
              currentFilter.dateRange.name,
              timeStamp,
              analytics["time-slices"].map((item) => item.timestamp)
            ),
          currentFilter
        }}
      />
    </React.Fragment>
  );
};

export default Analytics;
