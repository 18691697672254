/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "@reduxjs/toolkit";
import { getConfigureData } from "api/select-features";
import { getCustomTemplates, updateTemplate, addTemplate, Template } from "api/content-templates";
import t from "i18n";
import { PartialAppState } from "../../initial-state";
import { Configuration } from "api/select-features";
import { navigateFn } from "utils/routes.utils";
import * as actions from "../../actions";
import { CONFIGURE_STORY_TEMPLATES_PATH, CONFIGURE_ACCESS_LEVELS_PATH } from "../../routes";
import * as FeatureAPI from "api/feature";
import { notificationError } from "containers/page/actions";
import { Feature } from "store/feature/feature";

//Configure Page
export const fetchConfigureData = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch({ type: actions.CLOSE_CONFIRMATION_MODEL });
  dispatch({ type: actions.LOAD_CONFIGURE_DATA_INIT });
  getConfigureData().then(
    (data) => dispatch({ type: actions.LOAD_CONFIGURE_DATA, payload: { data } }),
    (error) => dispatch({ type: actions.LOAD_CONFIGURE_DATA_FAILURE, payload: error })
  );
};

export const updateFeature = (featureId: number, changes: Partial<Pick<Feature, "toggle" | "config">>) => async (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>
) => {
  try {
    await FeatureAPI.update({ id: featureId, changes: changes });
    dispatch(fetchConfigureData());
  } catch (e) {
    dispatch(notificationError(t("settings.features.update-fail")));
  }
};

export const fetchAllStoryTemplates = () => (dispatch: ThunkDispatch<any, any, any>) =>
  getCustomTemplates().then((res) => dispatch({ type: actions.LOAD_CUSTOM_STORY_TEMPLATES, payload: { data: res } }));

export const updateTemplateConfig = (template: Template) => (dispatch: ThunkDispatch<any, any, any>) => {
  const navigate = navigateFn(dispatch);
  updateTemplate(template).then(
    (res) => {
      dispatch(fetchAllStoryTemplates());
      dispatch({ type: actions.RESET_TEMPLATE_ERRORS });
      navigate(CONFIGURE_STORY_TEMPLATES_PATH);
    },
    (error) => window.alert(error)
  );
};

export const addTemplateConfig = (template: Template) => (dispatch: ThunkDispatch<any, any, any>) => {
  const navigate = navigateFn(dispatch);

  addTemplate(template).then(
    (res) => {
      dispatch(fetchAllStoryTemplates());
      dispatch({ type: actions.RESET_TEMPLATE_ERRORS });
      navigate(CONFIGURE_STORY_TEMPLATES_PATH);
    },
    (error) => window.alert(error)
  );
};

export const saveUniqueAccessLevel = (
  accessLevelValue: string,
  accessLevelValues: Array<string>,
  accessLevel: Configuration
) => (dispatch: ThunkDispatch<any, any, any>) => {
  const navigate = navigateFn(dispatch);

  const isSameAccessLevelExist = accessLevelValues.some((item: string) => item === accessLevelValue);
  if (!isSameAccessLevelExist) {
    const config = {
      ...accessLevel.config,
      values: [accessLevelValue, ...accessLevelValues]
    };
    dispatch(updateFeature(accessLevel.id, { config }));
    dispatch({ type: actions.RESET_ACCESS_LEVEL_VALUE });
    navigate(CONFIGURE_ACCESS_LEVELS_PATH);
  } else {
    dispatch({
      type: actions.SET_ACCESS_LEVEL_ERRORS,
      payload: { errors: { accessLevelValue: ["Access level value can't be same"] } }
    });
  }
};

export const saveContentAccessConfig = (noOfVisibleCards: number, accessLevel: Configuration) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  const config = {
    ...accessLevel.config,
    "no-of-visible-cards": noOfVisibleCards
  };
  dispatch(updateFeature(accessLevel.id, { config }));
  dispatch({ type: actions.TOGGLE_SAVE_BUTTON, payload: false });
};

export const deleteSelectedAccessLevel = () => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const accessLevel = getState().settingsPage.accessLevel;
  if (accessLevel) {
    const accessLevelValues = accessLevel && accessLevel.config ? accessLevel.config.values : [];
    const selectedAccessLevelIndex = getState().settingsPage.ui.selectedAccessLevelValueIndexToBeDeleted;
    const newAccessLevelValues =
      accessLevelValues && accessLevelValues.filter((value, i) => selectedAccessLevelIndex !== i);
    const config = {
      ...accessLevel.config,
      values: newAccessLevelValues
    };
    dispatch(updateFeature(accessLevel.id, { config }));
  }
};
