/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";
import { Timestamp, UUID, PolltypeId } from "./primitive-types";
import wretch from "wretch";

interface EditToken {
  "expires-at": Timestamp;
  token: UUID;
}

interface HeroImageMetadata {
  height: number;
  width: number;
}

export interface HeroImage {
  "s3-key": string;
  metadata: HeroImageMetadata;
  "default?": boolean;
}

interface Opinion {
  text: string;
  type: string;
  metadata: any;
  "percentage-votes"?: number;
}

interface Organization {
  id: number;
  name: string;
  "quintype-publisher-id": number;
}

interface Texts {
  "authorise-facebook-msg": string;
  "your-result-msg": string;
  "votes-across-polltype-msg": string;
  "change-vote-msg": string;
  "embed-button": string;
  "share-the-poll-msg": string;
  "vote-received-msg": string;
  "login-msg": string;
  "looking-across-polltype-msg": string;
  "thank-you-msg": string;
  "share-button": string;
  "share-via": string;
  "all-result-msg": string;
  "vote-msg": string;
}

interface Theme {
  font: Font;
  colors: Colors;
}
interface Font {
  family: string;
  google: Google;
  typekit: Typekit;
  custom: CustomFont;
}

interface CustomFont {
  font: string;
  w400: FontType;
  w600: FontType;
  w700: FontType;
}

export interface FontType {
  woff2: string;
  woff: string;
}

export interface Google {
  families: any[];
}

export interface Typekit {
  id: string;
}

interface Colors {
  "brand-primary": string;
  "brand-secondary": string;
  "brand-lighter": string;
}

interface Settings {
  "change-vote?"?: boolean;
  "show-results": string;
  "anonymous-voting?": boolean;
  "embeddable?"?: boolean;
  authentication?: string[];
  "show-default-hero-image-in-embed"?: boolean;
}
export interface PollMetadata {
  draft: Draft;
  locale: string;
  options: Options;
}

export interface Options {
  "show-default-hero-image-in-embed": boolean;
}

export interface Draft {
  opinions: Opinion[];
}

interface Opinion {
  text: string;
  type: string;
  metadata: any;
  "percentage-votes"?: number;
}

export interface Poll {
  description: string;
  "updated-at": number;
  opinions: Opinion[];
  "edit-url": string;
  locale: string;
  texts: Texts;
  settings: Settings;
  "hero-image": HeroImage | {};
  topic: string;
  organization: Organization;
  "published-at": Timestamp;
  theme: Theme;
  status: string;
  "total-votes": number;
  id: number;
  url?: string;
  "wrapper-story-slug"?: null;
  "created-at": Timestamp;
  metadata: PollMetadata;
}

export type UnsavedPoll = Pick<Poll, "topic" | "description" | "opinions" | "settings" | "hero-image">;
interface PollTypeResponse {
  poll: Poll;
  "edit-token": EditToken;
}

export const getPollDetails = (id: number): Promise<PollTypeResponse> => {
  return client
    .url(`/polltype/api/editor/polls/${id}`)
    .get()
    .json();
};

export const savePoll = (id: PolltypeId, poll: any): Promise<any> => {
  return client
    .url(`/polltype/api/editor/polls/${id}`)
    .json(poll)
    .post()
    .json();
};

export const createPoll = (poll: any): Promise<any> => {
  return client
    .url(`/polltype/api/editor/polls`)
    .json(poll)
    .post()
    .json();
};

export const signPollFile = (params: any) => {
  return client
    .url("/polltype/sign")
    .query(params)
    .get()
    .json();
};

export const uploadPollFileDetails = (url: string, formData: {}) => {
  return wretch(url)
    .formData(formData)
    .post()
    .res();
};
