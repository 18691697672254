/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Question = ({ width = "24", height = "24", color = "#c6ccd5" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 24 24">
    <path d="M19 3a1.93 1.93 0 0 1 2 1.92v14.14A1.93 1.93 0 0 1 19 21H4.89A1.91 1.91 0 0 1 3 19.06V4.92A1.91 1.91 0 0 1 4.89 3zm.64 16.07V4.92a.62.62 0 0 0-.18-.46.63.63 0 0 0-.46-.19H4.89a.64.64 0 0 0-.46.19.62.62 0 0 0-.18.46v14.14a.62.62 0 0 0 .18.46.63.63 0 0 0 .46.18H19a.62.62 0 0 0 .64-.64zm-7.35-4.67a.48.48 0 0 0 .48-.48 1.52 1.52 0 0 1 .36-1 5.88 5.88 0 0 1 .93-.68 5.5 5.5 0 0 0 1.24-1 2.55 2.55 0 0 0 .56-1.69 2.81 2.81 0 0 0-1.12-2.33A4.36 4.36 0 0 0 12 6.36a4.6 4.6 0 0 0-2.21.49 5.43 5.43 0 0 0-1.52 1.32.43.43 0 0 0-.08.36.54.54 0 0 0 .2.33l.52.36a.46.46 0 0 0 .36.08.45.45 0 0 0 .29-.16 3.2 3.2 0 0 1 1-.89A3 3 0 0 1 12 8a2.58 2.58 0 0 1 1.72.52 1.34 1.34 0 0 1 .57 1.09 1.26 1.26 0 0 1-.41.92 4.24 4.24 0 0 1-.88.69 6.34 6.34 0 0 0-1.25 1 2.82 2.82 0 0 0-.56 1.77.48.48 0 0 0 .14.34.49.49 0 0 0 .34.14zm-.32 1a1.06 1.06 0 0 1 .8.33 1.16 1.16 0 0 1 0 1.6 1.06 1.06 0 0 1-.8.32 1.08 1.08 0 0 1-.81-.32 1.13 1.13 0 0 1 .84-1.97z" />
  </svg>
);

export default Question;
