/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction, compose } from "redux";
import { t } from "i18n";
import styles from "./general-settings.module.css";
import {
  contributorRolesSlug,
  PartialAppState,
  PublisherSettings,
  Errors,
  loadSettings,
  regenerateSitemap,
  uploadThemeAttributes,
  updatePublisherSettings,
  updateFeatureSettings,
  removePublisherSettings,
  removeFeatureSettings,
  richTextImageFieldsSlug,
  formsSlug
} from "store/secret-mode/secret-mode";
import { Feature, FeatureSettings } from "store/feature/feature";
import { enableSaveButton } from "store/secret-mode/secret-mode";
import Spacer from "components/spacer/spacer";
import FileUpload from "components/file-upload/file-upload";
import Select from "components/select/select";
import TextField from "components/text-field/text-field";
import Button from "components/button/button";
import FieldLabel from "components/field-label/field-label";
import SettingsContainer from "components/settings/settings-container/settings-container";
import SettingsSection from "components/settings/settings-section/settings-section";
import Spinner from "components/spinner/spinner";
import SwitchSetting from "components/switch-setting/switch-setting";

interface Format {
  name: string;
  helpText: string;
}

interface StateProps {
  isLoading: boolean;
  loadingFailed: boolean;
  isRegenerateSitemapButtonEnabled: boolean;
  websiteURL: string;
  storySlugFormat: string;
  sectionURLFormat: string;
  contributorRoles: Feature;
  richTextImageFields: Feature;
  forms: Feature;
  errors: Errors;
}

interface DispatchProps {
  loadGeneralSettings: ({ featureSlugs }: { featureSlugs: string[] }) => void;
  uploadThemeAttributes: (file: File) => void;
  regenerateSitemap: () => void;
  updatePublisherSettings: (settings: PublisherSettings) => void;
  updateFeatureSettings: (setting: FeatureSettings) => void;
  unloadPublisher: (publisher: string[]) => void;
  unloadFeatures: (features: string[]) => void;
}

type GeneralSettingsProps = StateProps & DispatchProps;

const storySlugFormats: Format[] = [
  {
    name: "{{first-published-at|yyyy/MM/dd}}",
    helpText: "my-domain.com/2020/03/21/my-story"
  },
  {
    name: "{{first-published-at|dd/MM/yyyy}}",
    helpText: "my-domain.com/21/03/2020/my-story"
  },
  {
    name: "{{first-published-at|MM/dd/yyyy}}",
    helpText: "my-domain.com/03/21/2020/my-story"
  },
  { name: "{{section-path}}", helpText: "my-domain.com/politics/my-political-story" },

  {
    name: "{{section}}/{{first-published-at|yyyy/MM/dd}}",
    helpText: "my-domain.com/politics/2020/03/21/my-story"
  },
  {
    name: "{{section}}/{{first-published-at|dd/MM/yyyy}}",
    helpText: "my-domain.com/politics/21/03/2020/my-story"
  },
  {
    name: "{{section}}/{{first-published-at|MM/dd/yyyy}}",
    helpText: "my-domain.com/politics/03/21/2020/my-story"
  }
];

const sectionURLFormats: Format[] = [
  { name: "section/{{section}}", helpText: "my-domain.com/section/politics" },
  { name: "{{section-path}}", helpText: "my-domain.com/sports/football" }
];

const getHelpText = (name: string, type: string) => {
  const formatType = type === "story" ? storySlugFormats : sectionURLFormats;
  const format: Format[] = formatType.filter((format: Format) => format.name === name);
  return format && format.length > 0 && format[0].helpText;
};

export const GeneralSettings: React.FC<GeneralSettingsProps> = ({
  isLoading,
  loadingFailed,
  isRegenerateSitemapButtonEnabled,
  regenerateSitemap,
  sectionURLFormat,
  storySlugFormat,
  updatePublisherSettings,
  uploadThemeAttributes,
  websiteURL,
  loadGeneralSettings,
  contributorRoles,
  richTextImageFields,
  forms,
  updateFeatureSettings,
  unloadPublisher,
  unloadFeatures,
  errors
}) => {
  useEffect(() => {
    loadGeneralSettings({ featureSlugs: [contributorRolesSlug, richTextImageFieldsSlug, formsSlug] });
    return () => {
      unloadPublisher(["website-url", "story-slug-format", "section-url-format"]);
      unloadFeatures([contributorRolesSlug, richTextImageFieldsSlug, formsSlug]);
    };
  }, [loadGeneralSettings, unloadFeatures, unloadPublisher]);

  const sectionHelpText = getHelpText(sectionURLFormat, "section");
  const storyHelpText = getHelpText(storySlugFormat, "story");

  if (isLoading) {
    return <Spinner message={t("secret-mode.general.loading")} classname={styles["general-settings-spinner"]} />;
  }

  return loadingFailed ? null : (
    <SettingsContainer>
      <SettingsSection title={t("secret-mode.general.title")}>
        <TextField
          placeholder={t("secret-mode.general.website-url-placeholder")}
          label={t("secret-mode.general.website-url")}
          value={websiteURL}
          onChange={(websiteURL) => updatePublisherSettings({ "website-url": websiteURL })}
          errorMessage={errors["website-url"]}
        />

        <Select
          label={t("secret-mode.general.story-slug-format")}
          options={storySlugFormats}
          getOptionLabel={(format) => format.name}
          getOptionValue={(format) => format.name}
          value={{ name: storySlugFormat || "", helpText: storyHelpText || "" }}
          helpText={storyHelpText || ""}
          onChange={(storySlugFormat: Format) =>
            updatePublisherSettings({ "story-slug-format": storySlugFormat && storySlugFormat.name })
          }
        />

        <Select
          label={t("secret-mode.general.section-url-format")}
          options={sectionURLFormats}
          getOptionLabel={(format) => format.name}
          getOptionValue={(format) => format.name}
          helpText={sectionHelpText || ""}
          value={{ name: sectionURLFormat || "", helpText: sectionHelpText || "" }}
          onChange={(sectionURLFormat: Format) =>
            updatePublisherSettings({ "section-url-format": sectionURLFormat && sectionURLFormat.name })
          }
        />

        <div className={styles["general-settings-sitemap-container"]}>
          <FieldLabel label={t("secret-mode.general.sitemap")} />
          <Button disabled={!isRegenerateSitemapButtonEnabled} onClick={regenerateSitemap}>
            {t("secret-mode.general.regenerate-sitemap")}
          </Button>
        </div>

        <FileUpload
          fieldLabel={t("secret-mode.general.theme-attributes.title")}
          defaultInputLabel={t("secret-mode.general.theme-attributes.label")}
          accepts={["application/json"]}
          buttonLabel={t("secret-mode.general.theme-attributes.button-label")}
          uploadFile={(_: any, file: File) => {
            uploadThemeAttributes(file);
          }}
        />

        {contributorRoles && (
          <SwitchSetting
            label={t("secret-mode.general.contributor-roles-label")}
            toggle={contributorRoles.toggle}
            hint={t("secret-mode.general.contributor-roles-hint")}
            onChange={(checked) => {
              const change = { ...contributorRoles, toggle: checked };
              updateFeatureSettings({ [contributorRolesSlug]: change });
            }}
          />
        )}
        <Spacer token={"l"} align={"vertical"} />
        {richTextImageFields && (
          <SwitchSetting
            label={t("secret-mode.general.rich-text-iage-fields")}
            toggle={richTextImageFields.toggle}
            hint={t("secret-mode.general.rich-text-iage-fields-hint")}
            onChange={(checked) => {
              const change = { ...richTextImageFields, toggle: checked };
              updateFeatureSettings({ [richTextImageFieldsSlug]: change });
            }}
          />
        )}
        <Spacer token={"l"} align={"vertical"} />
        {forms && (
          <SwitchSetting
            label={"Forms"}
            toggle={forms.toggle}
            hint={"Enable forms"}
            onChange={(checked) => {
              const change = { ...forms, toggle: checked };
              updateFeatureSettings({ [formsSlug]: change });
            }}
          />
        )}
      </SettingsSection>
    </SettingsContainer>
  );
};

const mapStateToProps = ({ config: { publisher }, secretMode: { app, ui } }: PartialAppState) => {
  const webSiteURL = app.publisher["website-url"];
  return {
    isLoading: ui.isLoading,
    loadingFailed: ui.loadingFailed,
    isRegenerateSitemapButtonEnabled: ui.isRegenerateSitemapButtonEnabled,
    websiteURL: webSiteURL === undefined ? publisher["website-url"] : webSiteURL,
    storySlugFormat: app.publisher["story-slug-format"] || publisher["story-slug-format"],
    sectionURLFormat: app.publisher["section-url-format"] || publisher["section-url-format"],
    contributorRoles: app.features[contributorRolesSlug],
    richTextImageFields: app.features[richTextImageFieldsSlug],
    forms: app.features[formsSlug],
    errors: app.errors
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => ({
  loadGeneralSettings: (params) => dispatch(loadSettings(params)),
  regenerateSitemap: () => dispatch(regenerateSitemap()),
  uploadThemeAttributes: (file) => dispatch(uploadThemeAttributes(file)),
  updatePublisherSettings: (settings) => {
    dispatch(updatePublisherSettings(settings));
    dispatch(enableSaveButton());
  },
  updateFeatureSettings: (setting) => {
    dispatch(updateFeatureSettings(setting));
    dispatch(enableSaveButton());
  },
  unloadPublisher: (publisher) => dispatch(removePublisherSettings(publisher)),
  unloadFeatures: (features) => dispatch(removeFeatureSettings(features))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(GeneralSettings);
