/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { INITIAL_LOADER_STATE, LoaderState } from "behaviors/loader/state";
import { State as WatermarkImageState } from "store/watermark-images";

export interface State {
  ui: {
    main: LoaderState;
    saving: boolean;
  };
}

export const INITIAL_STATE: State = {
  ui: {
    main: INITIAL_LOADER_STATE,
    saving: false
  }
};

export interface PartialAppState {
  config: { "cdn-cname": string };
  defaultWatermarkImagePage: State;
  watermarkImage: WatermarkImageState;
}
