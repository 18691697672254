/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import jwt from "jsonwebtoken";
import { WretcherOptions } from "wretch";

type NextFn = (url: string, opts: WretcherOptions) => Promise<any>;

type MiddlewareFn = (next: NextFn) => NextFn;

interface AccessToken {
  integrationId: number;
  iat: number;
  exp: number;
}

const accessTokenPrefix = "bkintgrtn-";

function isTokenExpired(token: AccessToken) {
  // consider token expired if it has less than 1 minute remaining validity
  return token.exp - 60 < Date.now() / 1000;
}

function getValidTokenFromLocalStorage(integrationId: number): string | null {
  const tokenKey = `${accessTokenPrefix}${integrationId}`;
  if (localStorage) {
    let token = localStorage.getItem(tokenKey);
    if (token) {
      const decodedToken = jwt.decode(token) as AccessToken | null;
      if (!decodedToken || isTokenExpired(decodedToken)) {
        localStorage.removeItem(tokenKey);
        return null;
      }
      return token;
    }
  }
  return null;
}

export type TokenApiFn = (integrationId: number) => Promise<string | null>;

export const tokenMiddleware = (
  tokenFn: TokenApiFn,
  integrationId: number,
  method?: string,
  query?: string
): MiddlewareFn => (next) => async (url, opts) => {
  let token = getValidTokenFromLocalStorage(integrationId);
  if (!token) token = await tokenFn(integrationId);
  if (token) {
    localStorage && localStorage.setItem(`${accessTokenPrefix}${integrationId}`, token);
    if (method === "get" && query) {
      url = url.concat(`&token=${token}`);
      return next(url, opts);
    }
    opts.body = JSON.stringify(Object.assign(JSON.parse(opts.body as string), { token }));
    return next(url, opts);
  }
  return null;
};

export const timeoutMiddleware = (timeout: number): MiddlewareFn => (next) => (url, opts) => {
  return Promise.race([
    next(url, opts),
    new Promise((_, rej) => {
      setTimeout(rej, timeout);
    })
  ]);
};

export function clearAccessTokens() {
  localStorage &&
    Object.keys(localStorage)
      .filter((key) => key.startsWith(accessTokenPrefix))
      .forEach((key) => localStorage.removeItem(key));
}
