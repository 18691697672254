/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { compose, AnyAction } from "redux";
import { t } from "i18n";
import ConfirmationModal from "components/confirmation-modal/confirmation-modal";
import PlusCircle from "components/icons/plus-circle";
import CardDetails from "./components/card-details";
import Spinner from "components/spinner/spinner";
import Inspector from "./components/inspector";
import styles from "./domain-manager.module.css";
import {
  PartialAppState,
  Domains,
  InspectorStateType,
  ConfirmationModalType,
  CurrentDomain,
  Domain,
  loadDomainManager,
  updateDomain,
  createDomain,
  deleteDomain,
  openInspector,
  closeConfirmationModal,
  replaceCurrentDomain
} from "store/domain/domain";
import SettingsContainer from "components/settings/settings-container/settings-container";
import SettingsSection from "components/settings/settings-section/settings-section";

const TRANSLATION_PATH = "secret-mode.domain-manager.confirmation-modal";

interface StateProps {
  domainManagerFailed: boolean;
  isLoading: boolean;
  showConfirmationModal: ConfirmationModalType | null;
  currentDomain: CurrentDomain;
  domains: Domains;
}

interface DispatchProps {
  openInspector: () => void;
  closeConfirmationModal: () => void;
  clearCurrentDomain: () => void;
  createDomain: (domain: CurrentDomain) => void;
  updateDomain: (domain: Domain) => void;
  deleteDomain: (domain: Domain) => void;
  loadDomainManager: () => void;
}

type DomainManagerProps = StateProps & DispatchProps;

export function AddNewButton({ onClick, text }: { onClick: () => void; text: string }) {
  return (
    <span onClick={onClick} className={styles["add-new-container"]} data-test-id="add-new-item">
      <PlusCircle width="26" height="26" color="var(--brand-3)" />
      <span className={styles["add-new-text"]}>{text}</span>
    </span>
  );
}

export class DomainManager extends React.Component<DomainManagerProps> {
  componentDidMount() {
    this.props.loadDomainManager();
  }
  render() {
    const {
      isLoading,
      domainManagerFailed,
      domains,
      currentDomain,
      showConfirmationModal,
      openInspector,
      updateDomain,
      deleteDomain,
      createDomain,
      clearCurrentDomain,
      closeConfirmationModal
    } = this.props;

    const closeModal = () => {
      if (showConfirmationModal === ConfirmationModalType.Delete) clearCurrentDomain();
      closeConfirmationModal();
    };

    let modalOpts: [string, string, Function];
    switch (showConfirmationModal) {
      case ConfirmationModalType.Create:
        modalOpts = [t(`${TRANSLATION_PATH}.create-header`), t(`${TRANSLATION_PATH}.create-text`), createDomain];
        break;
      case ConfirmationModalType.Edit:
        modalOpts = [t(`${TRANSLATION_PATH}.edit-header`), t(`${TRANSLATION_PATH}.edit-text`), updateDomain];
        break;
      default:
        modalOpts = [t(`${TRANSLATION_PATH}.delete-header`), t(`${TRANSLATION_PATH}.delete-text`), deleteDomain];
    }

    let [modalHeaderText, modalText, onConfirm] = modalOpts;

    const domainList = Object.values(domains).map((domain: Domain) => <CardDetails domain={domain} key={domain.id} />);

    if (isLoading) {
      return (
        <Spinner
          message={t("secret-mode.domain-manager.loading-message")}
          classname={styles["domain-manager-spinner"]}
        />
      );
    }

    return (
      !domainManagerFailed && (
        <SettingsContainer>
          <SettingsSection title={t("secret-mode.domain-manager.title")}>
            {domainList}
            <AddNewButton onClick={openInspector} text={t("secret-mode.domain-manager.add-domain")} />
          </SettingsSection>
          <Inspector />
          <ConfirmationModal
            showConfirmationModal={!!showConfirmationModal}
            headerText={modalHeaderText}
            text={modalText}
            confirmButtonLabel={t(`${TRANSLATION_PATH}.confirm`)}
            cancelButtonLabel={t(`${TRANSLATION_PATH}.cancel`)}
            onConfirm={() => onConfirm(currentDomain)}
            onCancel={closeModal}
            onClose={closeModal}
          />
        </SettingsContainer>
      )
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    isLoading: state.domain.ui.isLoading,
    domainManagerFailed: state.domain.ui.domainManagerFailed,
    domains: state.domain.app.domains,
    showConfirmationModal: state.domain.ui.showConfirmationModalState,
    currentDomain: state.domain.app.currentDomain
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    openInspector: () => dispatch(openInspector(InspectorStateType.Create)),
    closeConfirmationModal: () => dispatch(closeConfirmationModal()),
    clearCurrentDomain: () => dispatch(replaceCurrentDomain({})),
    loadDomainManager: () => dispatch(loadDomainManager()),
    createDomain: (domain: CurrentDomain) => {
      dispatch(closeConfirmationModal());
      dispatch(createDomain(domain));
    },
    updateDomain: (domain: Domain) => {
      dispatch(closeConfirmationModal());
      dispatch(updateDomain(domain));
    },
    deleteDomain: (domain: Domain) => {
      dispatch(closeConfirmationModal());
      dispatch(deleteDomain(domain));
      dispatch(replaceCurrentDomain({}));
    }
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(DomainManager);
