/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Attribute } from "api/story-attributes";
import { validate, ValidationResult } from "utils/validation.utils";
import { t } from "i18n";

export function validateAttribute(
  attribute: Attribute,
  attributes: Attribute[],
  canSkipValuesValidation: boolean | undefined
): ValidationResult<Attribute> {
  return validate(attribute, {
    name: {
      presence: { allowEmpty: false, message: `^${t("attributes.messages.slug-value-blank")}` },
      exclusion: {
        within: attributes.map((_attribute) => _attribute.name),
        message: "^" + t("attributes.messages.duplicate-name")
      }
    },
    "display-name": {
      presence: { allowEmpty: false }
    },
    values: {
      presence: { allowEmpty: !!canSkipValuesValidation }
    }
  });
}
