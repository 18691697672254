/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

interface Icon {
  color?: string;
  width?: string;
  height?: string;
}

const Sync = ({ width = "24", height = "24", color = "var(--brand-3)" }: Icon) => (
  <svg width={width} height={height} fill={color} viewBox="0 0 20 20">
    <path d="M10,5 L10,7.5 L13.3333333,4.16666667 L10,0.833333333 L10,3.33333333 C6.31666667,3.33333333 3.33333333,6.31666667 3.33333333,10 C3.33333333,11.3083333 3.71666667,12.525 4.36666667,13.55 L5.58333333,12.3333333 C5.20833333,11.6416667 5,10.8416667 5,10 C5,7.24166667 7.24166667,5 10,5 Z M15.6333333,6.45 L14.4166667,7.66666667 C14.7833333,8.36666667 15,9.15833333 15,10 C15,12.7583333 12.7583333,15 10,15 L10,12.5 L6.66666667,15.8333333 L10,19.1666667 L10,16.6666667 C13.6833333,16.6666667 16.6666667,13.6833333 16.6666667,10 C16.6666667,8.69166667 16.2833333,7.475 15.6333333,6.45 L15.6333333,6.45 Z"></path>
  </svg>
);

export default Sync;
