/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const DoubleStar = ({ width = 24, height = 24, color = "#c6ccd5" }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={color}
    viewBox="0 0 24 24">
    <defs />
    <path
      className="cls-1"
      d="M22.09 11a1 1 0 0 0-.2-.65.93.93 0 0 0-.6-.35L17 9.39l-1.9-3.86a.89.89 0 0 0-.52-.47 1 1 0 0 0-.67 0 .84.84 0 0 0-.51.47l-1.9 3.86-4.27.61a.93.93 0 0 0-.61.35 1 1 0 0 0-.2.65.87.87 0 0 0 .28.61l3.1 3-.73 4.28a1 1 0 0 0 .13.66.91.91 0 0 0 1.22.3l3.84-2 3.83 2a.91.91 0 0 0 1.22-.3.92.92 0 0 0 .13-.66l-.73-4.28 3.1-3a.87.87 0 0 0 .28-.61zm-4.38 3.31l.82 4.74-4.27-2.25L10 19.06l.79-4.74L7.37 11l4.75-.71 2.14-4.3 2.13 4.3 4.78.71z"
    />
    <path
      className="cls-1"
      d="M5.4 12.29L2 8.92l4.74-.7 2.12-4.31 1.73 3.48.46-1.17L9.71 3.5A.93.93 0 0 0 9.2 3a1.13 1.13 0 0 0-.68 0 .93.93 0 0 0-.52.5L6.11 7.37 1.83 8a.83.83 0 0 0-.6.35A1 1 0 0 0 1 9a.94.94 0 0 0 .28.62l3.11 3-.74 4.28a1 1 0 0 0 .14.66.82.82 0 0 0 .54.4.88.88 0 0 0 .67-.1l1.82-.94.52-1.34L4.61 17z"
    />
  </svg>
);

export default DoubleStar;
