/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useState } from "react";
import { connect } from "react-redux";
import { AnyAction } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { t } from "i18n";
import { ThunkDispatch } from "redux-thunk";
import pDebounce from "p-debounce";
import { COLLECTIONS_PATH } from "pages/collections/routes";
import {
  fetchCollections,
  PartialAppState,
  resetFilters,
  setShowFiltersInspector,
  updateFilters
} from "store/collection/collections-dashboard";
import { Link } from "react-router-dom";
import Filter from "components/icons/filter";
import SearchBar from "components/search-bar/search-bar";
import Button from "components/button/button";
import ButtonWithIcon from "components/button/button-with-icon";
import ContentHeader from "pages/settings/components/content-header/content-header";
import styles from "./header.module.css";
import Plus from "components/icons/plus";
import ActiveFilters from "pages/collections/dashboard/components/active-filters/active-filters";
import { selectIsDesktopSizeViewport } from "store/viewport";

interface DispatchProps {
  openFilterInspector: VoidFunction;
  searchCollections: (q: string | null) => void;
}

interface StateProps {
  isDesktopSizeViewport: boolean;
}

export const Header: React.FC<DispatchProps & StateProps> = ({
  searchCollections,
  isDesktopSizeViewport,
  openFilterInspector
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const onSearch = (q) => {
    setSearchTerm(q);
    searchCollections(q);
  };
  return (
    <>
      <ContentHeader title={t("collections.collections")}>
        <Link className={styles["add-link"]} to={`${COLLECTIONS_PATH}/new/settings/content`}>
          {isDesktopSizeViewport ? (
            <ButtonWithIcon type="primary" icon={Plus} fill="var(--white)" dir="left">
              {t("common.add")}
            </ButtonWithIcon>
          ) : (
            <Button type={"primary"}>
              <Plus width="16" height="16" fill="var(--white)" />
            </Button>
          )}
        </Link>
      </ContentHeader>

      <div className={styles["header"]}>
        <div className={styles["search"]}>
          <SearchBar value={searchTerm} onChange={onSearch} placeholder={t("common.search")} />
        </div>
        <div className={styles["filter"]} onClick={openFilterInspector} data-test-id="filter">
          <Filter />
        </div>

        <ActiveFilters />
      </div>
    </>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    isDesktopSizeViewport: selectIsDesktopSizeViewport(state)
  };
};

function mapDispatchToProps(dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps {
  const debouncedSearchCollections = pDebounce(async (q) => {
    isEmpty(q)
      ? dispatch(resetFilters(["q", "sort"]))
      : dispatch(updateFilters({ q, sort: ["score", "updated-at:desc"] }));
    await dispatch(fetchCollections({ shouldClearCollections: true, showLoader: true }));
  }, 250);
  return {
    openFilterInspector: () => dispatch(setShowFiltersInspector(true)),
    searchCollections: (q) => debouncedSearchCollections(q)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
