/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AnyPushNotification, PushNotification } from "api/push-notification";
import { TabValue } from "./tab-list";
import { WorkspaceResponse } from "api/workspace";

enum actions {
  MAYBE_PUBLISH = "@@push_notification/MAYBE_PUBLISH",
  CANCEL_PUBLISH = "@@push_notification/CANCEL_PUBLISH",
  INIT_PUSH_NOTIFICATION = "@@push_notification/INIT_PUSH_NOTIFICATION",
  LIST_PUSH_NOTIFICATIONS = "@@push_notification/LIST_PUSH_NOTIFICATIONS",
  LIST_PUSH_NOTIFICATIONS_SUCCESS = "@@push_notification/LIST_PUSH_NOTIFICATIONS_SUCCESS",
  LIST_PUSH_NOTIFICATIONS_FAILURE = "@@push_notification/LIST_PUSH_NOTIFICATIONS_FAILURE",
  LOAD_PUSH_NOTIFICATION = "@@push_notification/LOAD_PUSH_NOTIFICATION",
  LOAD_PUSH_NOTIFICATION_FAILURE = "@@push_notification/LOAD_PUSH_NOTIFICATION_FAILURE",
  PUSH_NOTIFICATION_VALIDATION_FAILURE = "@@push_notification/PUSH_NOTIFICATION_VALIDATION_FAILURE",
  SAVE_PUSH_NOTIFICATION = "@@push_notification/SAVE_PUSH_NOTIFICATION",
  SAVE_PUSH_NOTIFICATION_SUCCESS = "@@push_notification/SAVE_PUSH_NOTIFICATION_SUCCESS",
  SAVE_PUSH_NOTIFICATION_FAILURE = "@@push_notification/SAVE_PUSH_NOTIFICATION_FAILURE",
  UPDATE_TAB = "@@push_notification/UPDATE_TAB",
  UPDATE_CURRENT_PUSH_NOTIFICATION = "@@push_notification/UPDATE_CURRENT_PUSH_NOTIFICATION",
  UPDATE_PUBLISH_LATER_STATE = "@@push_notification/UPDATE_PUBLISH_LATER_STATE",
  UPDATE_SEARCH_TERM = "@@push_notification/UPDATE_SEARCH_TERM"
}

export type InitNewPushNotification = { type: actions.INIT_PUSH_NOTIFICATION };
export type UpdateCurrentPushNotification = {
  type: actions.UPDATE_CURRENT_PUSH_NOTIFICATION;
  payload: AnyPushNotification | null;
};
export type SaveNewPushNotification = { type: actions.SAVE_PUSH_NOTIFICATION };
export type SavePushNotificationSuccessAction = { type: actions.SAVE_PUSH_NOTIFICATION_SUCCESS };
export type SavePushNotificationFailureAction = {
  type: actions.SAVE_PUSH_NOTIFICATION_FAILURE;
  payload: Error | null;
};
export type LoadPushNotificationAction = {
  type: actions.LOAD_PUSH_NOTIFICATION;
  payload: {
    pushNotification: PushNotification;
  };
};
export type ListPushNotificationsAction = { type: actions.LIST_PUSH_NOTIFICATIONS };
export type ListPushNotificationsSuccessAction = {
  type: actions.LIST_PUSH_NOTIFICATIONS_SUCCESS;
  payload: WorkspaceResponse;
};
export type ListPushNotificationsFailureAction = {
  type: actions.LIST_PUSH_NOTIFICATIONS_FAILURE;
  payload: { error: Error };
};
export type CancelPublishAction = { type: actions.CANCEL_PUBLISH };
export type MaybePublishAction = { type: actions.MAYBE_PUBLISH };
export type UpdatePublishLaterAction = { type: actions.UPDATE_PUBLISH_LATER_STATE; payload: boolean };
export type LoadPushNotificationFailureAction = { type: actions.LOAD_PUSH_NOTIFICATION_FAILURE; payload: Error };
export type UpdateTabAction = { type: actions.UPDATE_TAB; payload: TabValue };
export type UpdateSearchTermAction = { type: actions.UPDATE_SEARCH_TERM; payload: string };

export type ActionTypes =
  | ListPushNotificationsAction
  | ListPushNotificationsSuccessAction
  | ListPushNotificationsFailureAction
  | SavePushNotificationSuccessAction
  | SavePushNotificationFailureAction
  | LoadPushNotificationAction
  | LoadPushNotificationFailureAction
  | InitNewPushNotification
  | SaveNewPushNotification
  | UpdateCurrentPushNotification
  | UpdatePublishLaterAction
  | CancelPublishAction
  | MaybePublishAction
  | UpdateTabAction
  | UpdateSearchTermAction;

export default actions;
