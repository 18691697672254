/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export default function multiplexer(worker) {
  var pendingJobs: any = [];
  var busy = false;

  var runJob = function runJob() {
    var next: any = pendingJobs.pop();
    if (!next) {
      busy = false;
      return;
    }

    busy = true;
    worker(next[0], function(arg) {
      try {
        return next[1](arg);
      } finally {
        runJob();
      }
    });
  };

  return {
    isBusy: function() {
      return busy;
    },

    queueJob: function(param, callback) {
      pendingJobs.push([param, callback]);

      if (!busy) runJob();
    }
  };
}
