/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const WORKSPACE_ADD_ERRORS = "@@workspace/ADD_ERRORS";
export const WORKSPACE_BULK_SELECT_STORIES = "@@workspace/BULK_SELECT_STORIES";
export const WORKSPACE_DATA_SUCCESS = "@@workspace/DATA_SUCCESS";
export const WORKSPACE_RESET_STORIES = "@@workspace/RESET_STORIES";
export const WORKSPACE_DELETE_STORY = "@@workspace/DELETE_STORY";
export const WORKSPACE_ENABLE_BULK_ACTION = "@@workspace/ENABLE_BULK_ACTION";
export const WORKSPACE_DISABLE_BULK_ACTION = "@@workspace/DISABLE_BULK_ACTION";
export const WORKSPACE_FETCH_DATA = "@@workspace/FETCH_DATA";
export const WORKSPACE_FETCH_SAVED_FILTERS = "@@workspace/FETCH_SAVED_FILTERS";
export const WORKSPACE_FILTER_SAVE = "@@workspace/FILTER_SAVE";
export const WORKSPACE_REPLACE_FILTER = "@@workspace/REPLACE_FILTER";
export const WORKSPACE_RESET_BULK_ACCESS_LEVEL_VALUE = "@@workspace/RESET_BULK_ACCESS_LEVEL_VALUE";
export const WORKSPACE_REMOVE_ERRORS = "@@workspace/REMOVE_ERRORS";
export const WORKSPACE_RESET_FILTER = "@@workspace/RESET_FILTER";
export const WORKSPACE_SELECT_ALL_STORIES = "@@workspace/SELECT_ALL_STORIES";
export const WORKSPACE_SET_SELECTED_SAVED_FILTER = "@@workspace/SET_SELECTED_SAVED_FILTER";
export const WORKSPACE_UPDATE_SELECTED_SAVED_FILTER = "@@workspace/UPDATE_SELECTED_SAVED_FILTER";
export const WORKSPACE_UPDATE_ACCESS_LEVEL_VALUE = "@@workspace/UPDATE_ACCESS_LEVEL_VALUE";
export const WORKSPACE_UPDATE_BULK_SELECTED = "@@workspace/UPDATE_BULK_SELECTED";
export const WORKSPACE_UPDATE_FILTER_NAME = "@@workspace/UPDATE_FILTER_NAME";
export const WORKSPACE_UPDATE_FILTER = "@@workspace/UPDATE_FILTER";
export const WORKSPACE_UPDATE_STAGING_FILTER = "@@workspace/UPDATE_STAGING_FILTER";
export const WORKSPACE_UPDATE_STORY_IDS = "@@workspace/UPDATE_STORY_IDS";
export const WORKSPACE_SET_SAVED_FILTER_LIST = "@@workspace/SET_SAVED_FILTER_LIST";
export const WORKSPACE_SHARE_SAVED_FILTER = "@@workspace/SHARE_SAVED_FILTER";
export const WORKSPACE_UNSHARE_SAVED_FILTER = "@@workspace/UNSHARE_SAVED_FILTER";
export const WORKSPACE_SET_DEFAULT_SAVED_FILTER = "@@workspace/SET_DEFAULT_SAVED_FILTER";
export const WORKSPACE_REMOVE_DEFAULT_SAVED_FILTER = "@@workspace/REMOVE_DEFAULT_SAVED_FILTER";
export const WORKSPACE_DELETE_SAVED_FILTER = "@@workspace/DELETE_SAVED_FILTER";
export const WORKSPACE_REMOVE_LOADER = "@@workspace/REMOVE_LOADER";
export const WORKSPACE_UPDATE_PAGE_REFERER = "@@workspace/UPDATE_PAGE_REFERER";
export const WORKSPACE_PRINT_SOURCE_TOGGLE = "@@workspace/PRINT_UPDATE";
export const UPDATE_STORY_WORKSPACE_REALTIME_UI = "@@workspace/UPDATE_STORY_WORKSPACE_REALTIME_UI";
export const UPDATE_STORY_WORKSPACE_REALTIME_DATA = "@@workspace/UPDATE_STORY_WORKSPACE_REALTIME_DATA";
