/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const LOGIN_PATH = "/login";
export const FORGOT_PASSWORD_PATH = "/forgot-password";
export const RESET_PASSWORD_PATH = "/auth/reset-password";
export const SETUP_TWO_FACTOR_AUTH = "/setup-two-factor-auth";
export const VALIDATE_TWO_FACTOR_AUTH = "/validate-two-factor-auth";
