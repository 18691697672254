/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { DOMParser, Slice } from "prosemirror-model";

function htmlToDomNode(html: string): ChildNode | null {
  const parent = document.createElement("div");
  parent.innerHTML = html;
  return parent.firstChild ? parent.firstChild : null;
}

export function htmlToPMNode(parser: DOMParser, html: string): Slice | null {
  const node: ChildNode | null = htmlToDomNode(html);
  return node ? parser.parseSlice(node) : null;
}
