/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import INITIAL_STATE from "./state";
import actions, { ActionTypes } from "./actions";
import { getDateRangeComparison } from "./utils";

export default function reducer(state = INITIAL_STATE, action: ActionTypes) {
  switch (action.type) {
    case actions.ANALYTICS_FETCH_SUCCESS:
      return {
        ...state,
        analytics: action.payload.analytics,
        ui: {
          ...state.ui,
          main: { loading: false, error: null },
          isAnalyticsLoading: false,
          dateRangeComparison: getDateRangeComparison(state.app.currentFilter.dateRange.name).prevRangeComparison
        }
      };
    case actions.SET_FILTER_OPTIONS:
      return {
        ...state,
        app: {
          ...state.app,
          currentFilter: {
            ...state.app.currentFilter,
            ...action.payload.filterOptions
          }
        },
        ui: {
          ...state.ui,
          isAnalyticsLoading: true
        }
      };
    case actions.TOGGLE_ANALYTICS_SHARE:
      return {
        ...state,
        ui: {
          ...state.ui,
          isAnalyticsShareVisible: !state.ui.isAnalyticsShareVisible
        }
      };
    case actions.SET_CUSTOM_RANGE:
      return {
        ...state,
        app: {
          ...state.app,
          customRange: { ...action.payload.customRange }
        }
      };
    case actions.OPEN_SUBLIST:
      return {
        ...state,
        authorStories: [],
        app: {
          ...state.app,
          authorId: action.payload.authorId
        },
        ui: {
          ...state.ui,
          isSublistOpen: true
        }
      };
    case actions.CLOSE_SUBLIST:
      return {
        ...state,
        ui: {
          ...state.ui,
          isSublistOpen: false
        }
      };
    case actions.AUTHOR_STORIES_FETCH_SUCCESS:
      return {
        ...state,
        authorStories: action.payload.authorStories
      };
    default:
      return state;
  }
}
