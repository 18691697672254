/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import actions, {
  InitNewPushNotification,
  SaveNewPushNotification,
  SavePushNotificationSuccessAction,
  ListPushNotificationsAction,
  ListPushNotificationsSuccessAction,
  ListPushNotificationsFailureAction,
  LoadPushNotificationAction,
  UpdateCurrentPushNotification,
  UpdatePublishLaterAction,
  CancelPublishAction,
  MaybePublishAction,
  SavePushNotificationFailureAction,
  LoadPushNotificationFailureAction,
  UpdateTabAction,
  UpdateSearchTermAction
} from "./actions";
import { AnyPushNotification, PushNotification } from "api/push-notification";
import { TabValue } from "./tab-list";
import { WorkspaceResponse } from "api/workspace";

export function initNewPushNotificationAction(): InitNewPushNotification {
  return { type: actions.INIT_PUSH_NOTIFICATION };
}

export function updateCurrentPushNotification(change: AnyPushNotification | null): UpdateCurrentPushNotification {
  return { type: actions.UPDATE_CURRENT_PUSH_NOTIFICATION, payload: change };
}

export function savePushNotificationAction(): SaveNewPushNotification {
  return { type: actions.SAVE_PUSH_NOTIFICATION };
}

export function savePushNotificationSuccessAction(): SavePushNotificationSuccessAction {
  return { type: actions.SAVE_PUSH_NOTIFICATION_SUCCESS };
}

export function savePushNotificationFailureAction(error: Error): SavePushNotificationFailureAction {
  return { type: actions.SAVE_PUSH_NOTIFICATION_FAILURE, payload: error };
}

export function loadPushNotificationAction(pushNotification: PushNotification): LoadPushNotificationAction {
  return { type: actions.LOAD_PUSH_NOTIFICATION, payload: { pushNotification } };
}

export function updatePublishLaterAction(isScheduled: boolean): UpdatePublishLaterAction {
  return { type: actions.UPDATE_PUBLISH_LATER_STATE, payload: isScheduled };
}

export function listPushNotifications(): ListPushNotificationsAction {
  return { type: actions.LIST_PUSH_NOTIFICATIONS };
}

export function listPushNotificationsSuccess(workspaceResponse: WorkspaceResponse): ListPushNotificationsSuccessAction {
  return {
    type: actions.LIST_PUSH_NOTIFICATIONS_SUCCESS,
    payload: workspaceResponse
  };
}

export function listPushNotificationsFailure(error: Error): ListPushNotificationsFailureAction {
  return {
    type: actions.LIST_PUSH_NOTIFICATIONS_FAILURE,
    payload: { error }
  };
}

export function cancelPublishAction(): CancelPublishAction {
  return { type: actions.CANCEL_PUBLISH };
}

export function maybePublishAction(): MaybePublishAction {
  return { type: actions.MAYBE_PUBLISH };
}

export function loadPushNotificationFailureAction(error: Error): LoadPushNotificationFailureAction {
  return { type: actions.LOAD_PUSH_NOTIFICATION_FAILURE, payload: error };
}

export function updateTabAction(tab: TabValue): UpdateTabAction {
  return { type: actions.UPDATE_TAB, payload: tab };
}

export function updateSearchTermAction(term: string): UpdateSearchTermAction {
  return { type: actions.UPDATE_SEARCH_TERM, payload: term };
}
