/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const SolidPin = ({ width = "24", height = "24", fill = "#5f6978" }) => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    fill={fill}
    width={width}
    height={height}
    viewBox="0 0 24 24">
    <path d="M15.44,11.55a6.19,6.19,0,0,1,2.15,1.62,3.76,3.76,0,0,1,.88,2.39.85.85,0,0,1-.85.85H12.84v3.65a.32.32,0,0,1,0,.14L12,21.89a.25.25,0,0,1-.24.14.26.26,0,0,1-.25-.14l-.84-1.69a.23.23,0,0,1,0-.14V16.41H5.81a.86.86,0,0,1-.6-.25.81.81,0,0,1-.24-.6,3.64,3.64,0,0,1,.88-2.35A5.92,5.92,0,0,1,8,11.55l.42-4.14H6.94a.82.82,0,0,1-.6-.25.79.79,0,0,1-.25-.6V4.87A.85.85,0,0,1,6.94,4H16.5a.82.82,0,0,1,.6.25.81.81,0,0,1,.24.59V6.56a.81.81,0,0,1-.24.6.86.86,0,0,1-.6.25H15Z" />
  </svg>
);

export default SolidPin;
