/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export interface Story {
  headline: string;
  "story-id": string;
}

export interface MediaStats {
  stories: Array<Story>;
  total: number;
}

export const fetchMediaStats = (
  location: string,
  params: { type: string; limit: number; offset: number }
): Promise<MediaStats> => {
  return client
    .url("/api/media/stats")
    .query({ ...params, location: encodeURI(location) })
    .get()
    .json();
};
