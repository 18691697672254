/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import { StoryId } from "api/primitive-types";
import { Story, WorkflowActions } from "api/story";
import { PartialAppState } from "pages/story-editor/state";
import {
  cancelPublishAndSaveAction,
  removeEmptyTextElementAction,
  saveStoryAction,
  toggleStoryCleanUpConfirmationAction,
  updateStatusAction
} from "pages/story-editor/async-action-creators";
import ConfirmationModal from "components/confirmation-modal/confirmation-modal";

interface StateProps {
  showStoryCleanUpModal: boolean;
  story: Story;
}

interface DispatchProps {
  updateStoryStatus(storyId: StoryId, storyVersionId: StoryId, transition: WorkflowActions): void;
  toggleStoryCleanUpConfirmation(action?: AnyAction | null): void;
  removeEmptyTextElements(story: Story, callback: Function): void;
  cancelPublishAndSave(): void;
  saveStory(callback?: Function, action?: AnyAction): void;
}

type Props = StateProps & DispatchProps;

const CleanUpEmptyElementsModal: React.FC<Props> = ({
  showStoryCleanUpModal,
  story,
  updateStoryStatus,
  toggleStoryCleanUpConfirmation,
  removeEmptyTextElements,
  cancelPublishAndSave,
  saveStory
}) => {
  return (
    <ConfirmationModal
      showConfirmationModal={showStoryCleanUpModal}
      headerText={t("story-editor.confirmation-modal.story-cleanup-confirmation")}
      text={t("story-editor.confirmation-modal.story-cleanup-message")}
      confirmButtonLabel={t("story-editor.confirmation-modal.confirm")}
      cancelButtonLabel={t("story-editor.confirmation-modal.cancel")}
      onConfirm={() =>
        removeEmptyTextElements(story, () => {
          if (story["status"] === "scheduled") {
            cancelPublishAndSave();
          } else {
            saveStory(updateStoryStatus);
          }
          toggleStoryCleanUpConfirmation(null);
        })
      }
      onCancel={toggleStoryCleanUpConfirmation}
      onClose={toggleStoryCleanUpConfirmation}
    />
  );
};

export default connect(
  (state: PartialAppState): StateProps => {
    return {
      showStoryCleanUpModal: state.storyEditor.ui.showStoryCleanUpModal,
      story: state.storyEditor.story as Story
    };
  },
  (dispatch: ThunkDispatch<PartialAppState, any, AnyAction>): DispatchProps => {
    return {
      updateStoryStatus: (storyId, storyVersionId, transition) =>
        dispatch(updateStatusAction(storyId, storyVersionId, transition)),
      toggleStoryCleanUpConfirmation: (action) => dispatch(toggleStoryCleanUpConfirmationAction(action)),
      removeEmptyTextElements: (story, callback) => dispatch(removeEmptyTextElementAction(story, callback)),
      cancelPublishAndSave: () => dispatch(cancelPublishAndSaveAction()),
      saveStory: (callback, action) => dispatch(saveStoryAction(callback, action))
    };
  }
)(CleanUpEmptyElementsModal);
