/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";
import ReactDOM from "react-dom";
import "./photo-editor-SDK.css";
import styles from "./photo-editor.module.css";
const w: any = window;
w.React = React;
w.ReactDOM = ReactDOM;

export default class PhotoEditor extends React.Component<any, any> {
  editorContainer: any;
  constructor(props) {
    super(props);
    this.editorContainer = null;
  }

  async componentDidMount() {
    if (this.editorContainer) {
      const image = new Image();
      image.crossOrigin = "anonymous";
      image.src = this.props.imageUrl;
      document.body.classList.add("dialog-open");
      const { PhotoEditorSDKUI, ExportFormat, ImageFormat, UIEvent } = await import("photoeditorsdk");
      const photoEditor = await PhotoEditorSDKUI.init({
        container: this.editorContainer,
        license: this.props.photoEditorConfig.license,
        assetBaseUrl: this.props.photoEditorConfig["assets-base-url"],
        image: image,
        export: {
          image: {
            enableDownload: false,
            exportType: ExportFormat.DATA_URL,
            format: ImageFormat.JPEG
          }
        }
      });
      photoEditor.on(UIEvent.EXPORT, (result) => {
        this.props.onExport(result);
        document.body.classList.remove("dialog-open");
      });
      photoEditor.on(UIEvent.CLOSE, () => {
        this.props.onClose();
        document.body.classList.remove("dialog-open");
      });
    }
  }

  render() {
    return (
      <div className={styles["photoeditor-container"]} data-test-id="photoeditor-container">
        <div
          dir="ltr"
          ref={(ref) => (this.editorContainer = ref)}
          className={styles["photoeditor"]}
          data-test-id="photoeditor"
        />
      </div>
    );
  }
}
