/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { connect } from "react-redux";
import classnames from "classnames/bind";
import { formattedDate } from "utils";
import { selectIsDesktopSizeViewport, PartialAppState } from "store/viewport";
import Badge, { BadgeValue } from "components/badge/badge";
import Clock from "components/icons/clock";
import Chip from "components/chip/chip";
import Star from "components/icons/star";
import User from "components/icons/user";
import Globe from "components/icons/globe";
import Analytics from "components/icons/analytics";
import { StoryWrapper, CollectionWrapper, StoryInsideItem, CollectionInsideItem } from "api/story-collection";
import Authors from "../authors/authors";
import { PageViewFields } from "pages/collections/initial-state";
import { getViewChanges, getFormattedPageViewsDelta } from "pages/collections/utils";
import styles from "./type-card.module.css";

const cx = classnames.bind(styles);

interface AnalyticsProps {
  pageViews?: PageViewFields | null;
}

export const AnalyticsDisplay: React.FC<AnalyticsProps> = ({ pageViews }) => {
  const viewChanges = getViewChanges(pageViews);
  const pageViewsDelta = getFormattedPageViewsDelta(pageViews);
  const analyticsClass = cx("collection-percent", {
    green: viewChanges === "increased",
    red: viewChanges === "decreased"
  });
  return (
    <React.Fragment>
      <div className={styles["analytics-icon"]}>
        <Analytics width="16" height="16" color="#5f6978" />
      </div>
      <div className={styles["analytics-data"]}>
        <span data-test-id="page-views">{pageViews && pageViews.count} </span>
        {pageViewsDelta && (
          <span data-test-id="collection-percent" className={analyticsClass}>
            {pageViewsDelta}
          </span>
        )}
      </div>
    </React.Fragment>
  );
};

interface MetaContentProps<T> {
  item: T;
  isSelected: boolean;
  badge: BadgeValue;
  template: string;
  showStar: boolean;
  selectedAttributes?: string[] | null;
  toggleCollectionItem: (item: T) => void;
}

export function MetaContent<T extends CollectionWrapper | StoryWrapper>({
  item,
  isSelected,
  badge,
  toggleCollectionItem,
  template,
  showStar,
  selectedAttributes
}: MetaContentProps<T>) {
  return (
    <div className={styles["collection-meta"]}>
      <div className={styles["collection-meta-content"]}>
        {badge && (
          <div className={styles["collection-badge"]} data-test-id="collection-badge">
            <Badge value={badge} muted />
          </div>
        )}
        <div>
          <Chip value={template} classname="collection-chips" />
        </div>
        {selectedAttributes &&
          selectedAttributes.map((attribute: string) => (
            <div>
              <Chip value={attribute} classname="collection-chips" data-test-id="collection-chips" />
            </div>
          ))}
      </div>
      {showStar && (
        <div
          className={cx("collection-star", { "is-selected": isSelected })}
          data-test-id={isSelected ? "card-star-selected" : "card-star"}
          onClick={() => toggleCollectionItem(item)}>
          <Star />
        </div>
      )}
    </div>
  );
}

interface HeadlineProps {
  href: string;
  headline: string;
  classname: string;
}

export const Headline: React.FC<HeadlineProps> = ({ href, headline, classname }) => {
  return (
    <span className={styles["headline-container"]} onClick={() => window.open(href)}>
      <h3 className={classname}>{headline}</h3>
    </span>
  );
};

interface BylineProps<T> {
  cardItem: T extends StoryWrapper ? StoryInsideItem : CollectionInsideItem;
  type: CollectionItemLocation;
  showAuthors?: boolean;
  showStatus?: boolean;
  showAnalytics?: boolean;
  showSyndicated?: boolean;
  pageViews?: PageViewFields | null;
  isDesktopSizeViewport: boolean;
}

function isCardItemStory(cardItem: StoryInsideItem | CollectionInsideItem): cardItem is StoryInsideItem {
  return !!(cardItem as StoryInsideItem).authors;
}

export function Byline<T extends CollectionWrapper | StoryWrapper>({
  cardItem,
  showAuthors,
  showStatus,
  showAnalytics,
  showSyndicated,
  type,
  pageViews,
  isDesktopSizeViewport
}: BylineProps<T>) {
  return (
    <div className={styles["collection-byline"]}>
      {showAuthors && isCardItemStory(cardItem) && (
        <div className={styles["collection-authors"]} data-test-id="collection-authors">
          <User />
          <Authors story={cardItem} />
        </div>
      )}
      <div className={styles["collection-time-wrapper"]}>
        <Clock />
        <time className={styles["collection-time"]}>{formattedDate(cardItem["published-at"])}</time>
      </div>
      {showStatus && isCardItemStory(cardItem) && cardItem.status === "scheduled" && !cardItem["publish-at"] && (
        <div className={styles["collection-story-status"]} data-test-id="collection-story-status">
          {t("workspace.story_card.publish_with_collection")}
        </div>
      )}
      {showAnalytics && type !== "sorted" && isDesktopSizeViewport && <AnalyticsDisplay pageViews={pageViews} />}
      {showSyndicated &&
        isCardItemStory(cardItem) &&
        cardItem.metadata &&
        cardItem.metadata["story-attributes"] &&
        cardItem.metadata["story-attributes"].syndicatedfrom && (
          <div className={styles["collection-card-syndicated-from"]} data-test-id="collection-card-syndication">
            <span>Syndicated From: {cardItem.metadata["story-attributes"].syndicatedfrom}</span>
          </div>
        )}
    </div>
  );
}

interface ManageProps<T> {
  item: T;
  isCardExpanded: boolean;
  showAnalytics?: boolean;
  showPublic?: boolean;
  pageViews?: PageViewFields | null;
  onClickManageButton(): void;
  renderManageComponent(): React.ReactNode;
}

export function Manage<T extends CollectionWrapper | StoryWrapper>({
  item,
  isCardExpanded,
  showAnalytics,
  showPublic,
  pageViews,
  onClickManageButton,
  renderManageComponent
}: ManageProps<T>) {
  return (
    <div className={styles["collection-manage-container"]}>
      <div className={styles["collection-options-container"]}>
        <div className={styles["collection-info"]}>
          {showAnalytics && <AnalyticsDisplay pageViews={pageViews} />}
          {showPublic && item["threshold"] && (
            <div className={styles["public-icon"]} data-test-id="public-icon">
              <Globe width="16" height="16" color="#5f6978" />
              <span>{item["threshold"]}</span>
            </div>
          )}
        </div>
        <div
          className={styles["collection-manage-button"]}
          data-test-id="item-manage-button"
          onClick={onClickManageButton}>
          {t("collections.manage")}
        </div>
      </div>
      {isCardExpanded && renderManageComponent()}
    </div>
  );
}

interface TypeCardProps<T> {
  item: T;
  cardItem: T extends StoryWrapper ? StoryInsideItem : CollectionInsideItem;
  isSelected: boolean;
  isDragging?: boolean;
  badge: BadgeValue;
  template: string;
  toggleCollectionItem: (item: T) => void;
  type: CollectionItemLocation;
  isCardExpanded: boolean;
  href: string;
  headline: string;
  headlineClassname: string;
  pageViews?: PageViewFields | null;
  selectedAttributes?: string[] | null;
  onClickManageButton(): void;
  renderManageComponent(): JSX.Element;
  showStar: boolean;
  showManage: boolean;
  showAnalytics?: boolean;
  showAuthors?: boolean;
  showStatus?: boolean;
  showSyndicated?: boolean;
  showPublic?: boolean;
  isDesktopSizeViewport: boolean;
}

function TypeCard<T extends CollectionWrapper | StoryWrapper>({
  item,
  cardItem,
  isSelected,
  isDragging,
  badge,
  template,
  toggleCollectionItem,
  type,
  isCardExpanded,
  href,
  headline,
  headlineClassname,
  pageViews,
  selectedAttributes,
  onClickManageButton,
  renderManageComponent,
  showStar,
  showManage,
  showAnalytics,
  showAuthors,
  showStatus,
  showSyndicated,
  showPublic,
  isDesktopSizeViewport
}: TypeCardProps<T>) {
  return (
    <div className={styles["collection-card"]} data-test-id="item-card">
      <div className={cx("collection-card-content", { "is-dragging": isDragging })}>
        <MetaContent<T>
          item={item}
          isSelected={isSelected}
          badge={badge}
          template={template}
          showStar={showStar}
          toggleCollectionItem={toggleCollectionItem}
          selectedAttributes={selectedAttributes}
        />
        <Headline href={href} headline={headline} classname={headlineClassname} />
        <Byline<T>
          cardItem={cardItem}
          showAuthors={showAuthors}
          showStatus={showStatus}
          showAnalytics={showAnalytics}
          showSyndicated={showSyndicated}
          type={type}
          pageViews={pageViews}
          isDesktopSizeViewport={isDesktopSizeViewport}
        />
        {showManage && isDesktopSizeViewport && (
          <Manage<T>
            item={item}
            isCardExpanded={isCardExpanded}
            showAnalytics={showAnalytics}
            showPublic={showPublic}
            pageViews={pageViews}
            onClickManageButton={onClickManageButton}
            renderManageComponent={renderManageComponent}
          />
        )}
      </div>
    </div>
  );
}

export enum CollectionItemLocation {
  Sorted = "sorted",
  Unsorted = "unsorted"
}

function mapStateToProps(state: PartialAppState) {
  return {
    isDesktopSizeViewport: selectIsDesktopSizeViewport(state)
  };
}

export default function<T extends CollectionWrapper | StoryWrapper>() {
  return connect(mapStateToProps, () => {})(TypeCard as (props: TypeCardProps<T>) => JSX.Element);
}

export { TypeCard };
