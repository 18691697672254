/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { getAsanaProjects, AsanaProject } from "api/asana";
import Select from "components/select/select";

interface Props {
  onChange: (val: AsanaProject) => void;
  selectedProject?: AsanaProject;
}

interface State {
  projects: Array<AsanaProject>;
}

export class AsanaProjectSelect extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      projects: []
    };
  }

  componentDidMount() {
    getAsanaProjects()
      .then((allProjects) => {
        if (allProjects) {
          this.setState({ projects: allProjects });
        }
      })
      .catch((error) => console.error(error));
  }

  render() {
    const { onChange, selectedProject } = this.props;
    return (
      <Select
        label={t("story-editor.inspector.projects")}
        name="projects"
        value={selectedProject}
        options={this.state.projects}
        getOptionLabel={(project) => project.name}
        getOptionValue={(project) => project["project-id"]}
        isMulti={false}
        onChange={(selectedProject: AsanaProject) => onChange(selectedProject)}
      />
    );
  }
}

export default AsanaProjectSelect;
