/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { AnyAction, compose } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";
import classnames from "classnames/bind";

import { IntegrationType, PartialAppState, Publisher } from "pages/settings/pages/integrations/state";
import { IntegrationConfig, Integrations } from "api/integrations";
import { ContextMenu, ContextMenuItem, ContextMenuList } from "components/context-menu/context-menu";
import { mapIntegrationToComponent } from "pages/settings/pages/integrations/map-integration-to-component";
import { deleteIntegration, toggleIntegration } from "pages/settings/pages/integrations/async-action-creators";
import { updateIntegrationFieldAction } from "pages/settings/pages/integrations/action-creators";
import styles from "./integration.module.css";
import { capitalize } from "lodash";
import { navigate } from "utils/routes.utils";
import { INTEGRATION_EDIT_PATH } from "pages/settings/pages/integrations/routes";
import ConfirmationModal from "components/confirmation-modal/confirmation-modal";

const cx = classnames.bind(styles);
interface DispatchProps {
  deleteIntegration: (integrationName: string) => void;
  openEditIntegrationInspector: (integrationName: string, type: IntegrationType) => void;
  toggleIntegration: (integration: IntegrationConfig) => void;
  updateIntegrationField: (key: string, changes: Object) => void;
}

interface StateProps {
  publisherSettings: Publisher;
}

interface OwnProps {
  integration: IntegrationConfig;
  hideContextMenu: boolean;
}

type Props = OwnProps & DispatchProps & StateProps;

enum IntegrationActions {
  DELETE = "delete",
  TOGGLE = "toggle"
}
type IntegrationAction = {
  integration: IntegrationConfig;
  action: IntegrationActions;
  callbackFn: () => void;
};

const Integration: React.SFC<Props> = ({
  deleteIntegration,
  toggleIntegration,
  openEditIntegrationInspector,
  integration,
  updateIntegrationField,
  hideContextMenu,
  publisherSettings
}) => {
  const [integrationAction, setIntegrationAction] = useState<IntegrationAction | null>(null);

  const handleIntegrationAction = (integrationAction: IntegrationAction) => {
    const { action, callbackFn, integration } = integrationAction;
    if (
      publisherSettings &&
      integration.value.type === IntegrationType.PUSH_NOTIFICATION &&
      (action === IntegrationActions.DELETE ||
        (action === IntegrationActions.TOGGLE && integration.value.disabled === false))
    ) {
      const { "push-notification": pushNotification } = publisherSettings;
      if (pushNotification) {
        const { web, mobile } = pushNotification.provider;
        if (web === integration.key || mobile === integration.key) {
          return setIntegrationAction(integrationAction);
        }
      }
    }
    callbackFn();
  };

  const handleConfirmationModalAction = (action: "confirm" | "cancel") => {
    if (action === "confirm" && integrationAction) {
      integrationAction.callbackFn();
    }
    setIntegrationAction(null);
  };

  const hideDeleteMenu =
    integration.key === Integrations.Facebook ||
    integration.key === Integrations.Linkedin ||
    integration.key === Integrations.Twitter ||
    (integration.value && integration.value.type === IntegrationType.ANALYTICS);

  return (
    <section
      data-test-id="integration-list"
      className={cx("integration", { "is-disabled": integration.value.disabled })}>
      {integrationAction && (
        <ConfirmationModal
          showConfirmationModal={true}
          headerText={
            integrationAction.action === IntegrationActions.DELETE
              ? t("integrations.confirmation-modal.delete-integration-header")
              : t("integrations.confirmation-modal.deactivate-integration-header")
          }
          text={
            integrationAction.action === IntegrationActions.DELETE
              ? t("integrations.confirmation-modal.delete-integration-text")
              : t("integrations.confirmation-modal.deactivate-integration-text")
          }
          confirmButtonLabel={
            integrationAction.action === IntegrationActions.DELETE
              ? t("integrations.delete")
              : t("integrations.deactivate")
          }
          cancelButtonLabel={t("common.cancel")}
          onConfirm={() => handleConfirmationModalAction("confirm")}
          onCancel={() => handleConfirmationModalAction("cancel")}
          onClose={() => handleConfirmationModalAction("cancel")}
        />
      )}
      {!hideContextMenu && (
        <div id={`integration-menu-${integration.key}`} className={styles["integration-more-actions"]}>
          <span className={styles["integration-icon-row"]}>
            <span className={styles["integration-more-icon"]}>
              <ContextMenu>
                <div id={`integration-menu-${integration.key}`} className={styles["integration-action-dropdown"]}>
                  <ContextMenuList>
                    {!hideDeleteMenu && (
                      <ContextMenuItem
                        label={t("integrations.delete")}
                        isDanger
                        data-test-id={"delete-integration"}
                        onClick={() =>
                          handleIntegrationAction({
                            action: IntegrationActions.DELETE,
                            integration,
                            callbackFn: () => deleteIntegration(integration.key)
                          })
                        }
                      />
                    )}
                    <ContextMenuItem
                      label={integration.value.disabled ? t("integrations.activate") : t("integrations.deactivate")}
                      data-test-id={"toggle-integration"}
                      onClick={() =>
                        handleIntegrationAction({
                          action: IntegrationActions.TOGGLE,
                          integration,
                          callbackFn: () => toggleIntegration(integration)
                        })
                      }
                    />
                    <ContextMenuItem
                      label={t("integrations.edit")}
                      data-test-id={"edit-integration"}
                      onClick={() => openEditIntegrationInspector(integration.key, integration.value.type)}
                    />
                  </ContextMenuList>
                </div>
              </ContextMenu>
            </span>
          </span>
        </div>
      )}
      <h3 className={styles["integration-title"]} data-test-id="integration-title">
        {t(`integrations.providers.${integration.key}`, capitalize(integration.key))}
      </h3>
      <div>{mapIntegrationToComponent(integration, true, updateIntegrationField, null)}</div>
    </section>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => ({
  publisherSettings: state.integrations.app.publisher
});

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    updateIntegrationField: (key, changes) => dispatch(updateIntegrationFieldAction(key, changes)),
    openEditIntegrationInspector: (integrationName, type) =>
      dispatch(navigate(INTEGRATION_EDIT_PATH, { integration: integrationName, type })),
    toggleIntegration: (integrationName) => dispatch(toggleIntegration(integrationName)),
    deleteIntegration: (integration) => dispatch(deleteIntegration(integration))
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Integration);

export { Integration };
