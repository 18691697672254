/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { compose, AnyAction } from "redux";
import { RouteComponentProps } from "react-router";
import { t } from "i18n";

import wrapPage from "containers/page/page";
import { loadSocialSharePageData } from "./async-action-creators";
import { addNewConnection } from "./action-creators";
import ContentHeader from "../../components/content-header/content-header";
import { PartialAppState } from "./state";
import { SocialAccount } from "api/social-accounts";
import { LoaderState } from "behaviors/loader/state";
import LoaderWrapper from "behaviors/loader/components/loader-wrapper/loader-wrapper";
import Loader from "./loader";
import SocialAccounts from "./components/social-accounts";
import { SOCIAL_ACCOUNTS_ADD_PATH } from "./routes";
import { navigate } from "utils/routes.utils";
import Inspector from "./components/inspector";

import classnames from "classnames/bind";
import styles from "./social-share.module.css";

const cx = classnames.bind(styles);
interface StateProps {
  socialAccounts: SocialAccount[];
  loader: LoaderState;
  title: string;
}

interface DispatchProp {
  loadSocialSharePageData: () => void;
  addNewConnection: () => void;
}

type Props = RouteComponentProps<any> & DispatchProp & StateProps & { isBannerPresent: boolean };

export const SocialShare: React.SFC<Props> = ({ loader, socialAccounts, addNewConnection }) => {
  return (
    <React.Fragment>
      <main className={cx("social-share-page-container", "social-share-page")}>
        <LoaderWrapper component={Loader} loader={loader}>
          <ContentHeader
            title={t("social_share.title")}
            description={t("social_share.description")}
            actionButtonLabel={t("social_share.new_connection_button")}
            handleActionButtonClick={() => addNewConnection()}
          />
          {socialAccounts.length > 0 ? (
            <SocialAccounts />
          ) : (
            <div className={styles["no-social-accounts"]}>{t("social_share.no_accounts_message")}</div>
          )}
        </LoaderWrapper>
        <Inspector />
      </main>
    </React.Fragment>
  );
};

export class SocialShareWithRoutes extends React.Component<Props> {
  async componentDidMount() {
    await this.props.loadSocialSharePageData();
    this.onEnter();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.match.url !== prevProps.match.url) {
      this.onEnter();
    }
  }

  onEnter() {
    switch (this.props.match.path) {
      case SOCIAL_ACCOUNTS_ADD_PATH:
        this.props.addNewConnection();
        break;
      default:
        break;
    }
  }

  render() {
    return <SocialShare {...this.props} />;
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    socialAccounts: state.socialShare.socialAccounts,
    loader: state.socialShare.ui.main,
    title: "social_sharing"
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>): DispatchProp => {
  return {
    loadSocialSharePageData: () => dispatch(loadSocialSharePageData()),
    addNewConnection: () => {
      dispatch(navigate(SOCIAL_ACCOUNTS_ADD_PATH));
      dispatch(addNewConnection());
    }
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  wrapPage()
)(SocialShareWithRoutes);
