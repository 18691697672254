/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import Sidebar from "components/sidebar/sidebar";
import { compose } from "redux";
import { connect } from "react-redux";
import { t } from "i18n";

import Menu from "components/menu/menu";

import { PartialAppState } from "../initial-state";
import { match } from "react-router";
import * as routes from "../routes";
import GoBack from "components/go-back/go-back";
import { isNotNullish } from "utils";

interface MenuType {
  label: string;
  value: string;
  path: string;
}

interface StateProps {
  enableAccessLevelsInSidebar?: boolean;
  enablePromotionsInSidebar?: boolean;
  enableContributorRolesInSidebar?: boolean;
  enableAmpInSidebar?: boolean;
  enableBreakingNewsInSidebar?: boolean;
  enableCollectionsConfigureInSidebar?: boolean;
  enableWatermarkImages?: boolean;
  enablePushNotificationChannels?: boolean;
  isFormsEnabled?: boolean;
  enableStorySlug?: boolean;
}

type Props = StateProps & {
  isBannerPresent: boolean;
  setActiveSidebar: (type: string) => void;
  match: match;
};

class ConfigureSidebar extends React.Component<Props> {
  render() {
    const {
      setActiveSidebar,
      isBannerPresent,
      enableAccessLevelsInSidebar,
      enablePromotionsInSidebar,
      enableContributorRolesInSidebar,
      enableAmpInSidebar,
      enableBreakingNewsInSidebar,
      enableCollectionsConfigureInSidebar,
      enableWatermarkImages,
      enablePushNotificationChannels,
      isFormsEnabled,
      enableStorySlug,
      match
    } = this.props;

    const configurations = {
      features: true,
      story_elements: true,
      story_templates: true,
      story_slug: enableStorySlug,
      access_levels: enableAccessLevelsInSidebar,
      promotional_messages: enablePromotionsInSidebar,
      contributor_roles: enableContributorRolesInSidebar,
      amp: enableAmpInSidebar,
      breaking_news: enableBreakingNewsInSidebar,
      collections: enableCollectionsConfigureInSidebar,
      watermark_images: enableWatermarkImages,
      push_notification_channels: enablePushNotificationChannels,
      forms: isFormsEnabled
    };

    const configureMenu = Object.keys(configurations)
      .map((feature) => {
        if (configurations[feature]) {
          return {
            label: t(`settings.configure.${feature}`),
            value: feature.replace(/_/g, "-"),
            path: routes[`CONFIGURE_${feature.toUpperCase()}_PATH`]
          };
        }
        return null;
      })
      .filter(isNotNullish);

    return (
      <Sidebar isBannerPresent={isBannerPresent}>
        <GoBack clickAction={() => setActiveSidebar("settings")} message={t("navbar.settings")} />
        <Menu
          items={configureMenu}
          getItemLabel={(item: MenuType) => item.label}
          routeTo={(item) => item.path}
          isItemSelected={(item) => !!item && item.path === match.path}
          title={t("settings.configure.title")}
        />
      </Sidebar>
    );
  }
}

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    enableAccessLevelsInSidebar: state.features.enableAccessLevelsInSidebar,
    enablePromotionsInSidebar: state.features.enablePromotionsInSidebar,
    enableContributorRolesInSidebar: state.features.enableContributorRolesInSidebar,
    enableAmpInSidebar: state.features.enableAmpInSidebar,
    enableBreakingNewsInSidebar: state.features.enableBreakingNewsInSidebar,
    enableCollectionsConfigureInSidebar: state.features.enableCollectionsConfigureInSidebar,
    enableWatermarkImages: state.features.enableWatermarkImages,
    enablePushNotificationChannels: state.features.enablePushNotificationChannels,
    isFormsEnabled: state.features.isFormsEnabled,
    enableStorySlug: state.features.canAccessStorySlug
  };
};

export default compose(connect(mapStateToProps))(ConfigureSidebar);

export { ConfigureSidebar };
