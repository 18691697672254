/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { compose } from "redux";
import { t } from "i18n";

import wrapPage from "containers/page/page";
import Analytics from "./analytics";
import TopAuthors from "./components/top-authors/top-authors";

export const AuthorAnalytics: React.SFC<any> = (props) => (
  <Analytics {...props} title={t("analytics.author_analytics")} Component={TopAuthors} />
);

export default compose(wrapPage())(AuthorAnalytics);
