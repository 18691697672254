/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import produce from "immer";
import { State, INITIAL_STATIC_PAGES_STATE } from "./state";
import actions, { ActionTypes } from "./actions";

function reducer(state: State = INITIAL_STATIC_PAGES_STATE, action: ActionTypes) {
  return produce(state, (draft) => {
    switch (action.type) {
      case actions.INIT_LOAD_STATIC_PAGES:
        draft.ui.main = { loading: true, error: null };
        break;
      case actions.LOAD_STATIC_PAGES_SUCCESS:
        draft.staticPages = action.payload.staticPages;
        draft.app.currentStaticPage = null;
        draft.totalCount = action.payload.totalCount;
        draft.ui.main = { loading: false, error: null };
        draft.ui.save = { loading: false, error: null };
        break;
      case actions.LOAD_STATIC_PAGES_FAILURE:
        draft.ui.main = { loading: false, error: action.payload.error };
        break;
      case actions.INIT_LOAD_NEXT_STATIC_PAGES:
        draft.ui.main = { loading: true, error: null };
        break;
      case actions.LOAD_NEXT_STATIC_PAGES_SUCCESS:
        draft.staticPages = draft.staticPages.concat(action.payload.staticPages);
        draft.app.currentStaticPage = null;
        draft.totalCount = action.payload.totalCount;
        draft.ui.main = { loading: false, error: null };
        break;
      case actions.LOAD_NEXT_STATIC_PAGES_FAILURE:
        draft.ui.main = { loading: false, error: action.payload.error };
        break;
      case actions.SET_CURRENT_STATIC_PAGE:
        draft.ui.main = { loading: false, error: null };
        draft.ui.save = { loading: false, error: null };
        draft.app.currentStaticPage = action.payload.staticPage;
        break;
      case actions.SET_CURRENT_STATIC_PAGE_ERROR:
        draft.ui.main = { loading: false, error: action.payload.error };
        break;
      case actions.INIT_SAVE_STATIC_PAGE:
        draft.ui.main = { loading: true, error: null };
        break;
      case actions.SAVE_STATIC_PAGE_SUCCESS:
        draft.ui.main = { loading: false, error: null };
        draft.ui.save = { loading: false, error: null };
        break;
      case actions.SAVE_STATIC_PAGE_FAILURE:
        draft.ui.main = { loading: false, error: null };
        draft.ui.save = { loading: false, error: action.payload.error };
        break;
      case actions.INIT_PUBLISH_STATIC_PAGE:
        draft.ui.main = { loading: true, error: null };
        break;
      case actions.PUBLISH_STATIC_PAGE_SUCCESS:
        draft.ui.main = { loading: false, error: null };
        break;
      case actions.PUBLISH_STATIC_PAGE_FAILURE:
        draft.ui.main = { loading: false, error: null };
        draft.ui.save = { loading: false, error: action.payload.error };
        break;
      case actions.INIT_RETRACT_STATIC_PAGE:
        draft.ui.main = { loading: true, error: null };
        break;
      case actions.RETRACT_STATIC_PAGE_SUCCESS:
        draft.ui.main = { loading: false, error: null };
        break;
      case actions.RETRACT_STATIC_PAGE_FAILURE:
        draft.ui.main = { loading: false, error: null };
        draft.ui.save = { loading: false, error: action.payload.error };
        break;
      case actions.UPDATE_CURRENT_STATIC_PAGE:
        if (draft.app.currentStaticPage) {
          draft.app.currentStaticPage[action.payload.key] = action.payload.value;
        }
        break;
      case actions.OPEN_DELETE_CONFIRMATION:
        draft.ui.showDeleteConfirmationModal = true;
        break;
      case actions.CLOSE_DELETE_CONFIRMATION:
        draft.ui.showDeleteConfirmationModal = false;
        break;
      default:
        break;
    }
  });
}

export default reducer;
