/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import newStoryElement, { NewStoryElement } from "./story-elements";
import get from "lodash/get";
import { getTemplateElements } from "./template-card-and-elements";
import { EditorConfig, StoryTemplate, Card, StoryElementType } from "api/story";

export function storyElementsForTemplate(
  editorConfig: EditorConfig,
  template: StoryTemplate,
  card: Card
): Array<NewStoryElement> {
  const features = (editorConfig && editorConfig["editor-features"]) || {};
  const cards = editorConfig && editorConfig.cards;

  if (template === StoryTemplate.Photo || features["must-have-photo"]) {
    return [newStoryElement(card, StoryElementType.Image)];
  } else if (template === StoryTemplate.Video || features["must-have-video"]) {
    return [newStoryElement(card, StoryElementType.JSEmbed)];
  } else if (template === StoryTemplate.LiveBlog) {
    return [newStoryElement(card, StoryElementType.Title)];
  } else if (template === StoryTemplate.Listicle) {
    return getTemplateElements(card, get(cards, ["new-1", "story-elements"], []));
  } else if (template === StoryTemplate.StoryListicle) {
    return getTemplateElements(card, get(cards, ["new-0", "story-elements"], []));
  } else if (template === StoryTemplate.Interview) {
    return getTemplateElements(card, get(cards, ["new-0", "story-elements"], []));
  } else {
    return [newStoryElement(card, StoryElementType.Text)];
  }
}

export function appendStoryElementToCard(card: Card, editorConfig: EditorConfig, template: StoryTemplate) {
  const storyElements = storyElementsForTemplate(editorConfig, template, card);
  const storyElementIds = storyElements.map((element) => element.id);

  return {
    storyElements,
    card: {
      ...card,
      tree: [...card.tree, ...storyElementIds]
    }
  };
}
