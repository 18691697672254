/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import classnames from "classnames/bind";
import styles from "./image-field.module.css";
import EditImage, { Props as EditImageProps } from "./edit-image";
import { SavedImage } from "./types";
import { CoverImage } from "api/story-collection";
import { Image, AnyImage } from "api/search-media-image";
import FieldLabel from "components/field-label/field-label";
import AddNewMedia from "components/add-new-media/add-new-media";
import { DropzoneMessageVariant } from "components/dropzone/dropzone-message";
import ErrorMessage from "components/error-message/error-message";

const cx = classnames.bind(styles);

type Props = Omit<EditImageProps, "value"> & {
  value: AnyImage | SavedImage | null;
  isPhotoEditor?: boolean;
  openGallery: React.MouseEventHandler;
  openPhotoEditor?: (value: SavedImage | CoverImage) => void;
  label?: string;
  showEditImage?: boolean;
  classname?: string;
  disableReplace?: boolean;
  disableDelete?: boolean;
  setSelectedMedia?: (images: AnyImage[]) => void;
  updateFocusPoint?: (image: Image) => void;
  updateImageDetails?: (image: Image) => void;
  switchToUploadRoute?: (mediaKey: string) => void;
  updateImageUploadStatus?: (status: { uploading: boolean }) => void;
  fieldSpecs?: any;
  error?: string | null;
};

// This function exists because entities and collections use a variety of types to represent a non-existant image, instead of null.
// get rid of this function once they are fixed.
export function properlyTypeImage(
  imageOrSomethingLikeIt: undefined | null | { [key: string]: any } | SavedImage
): SavedImage | null {
  if (!imageOrSomethingLikeIt) {
    // handle undefined and null
    return null;
  }

  if (typeof imageOrSomethingLikeIt === "object" && Object.keys(imageOrSomethingLikeIt).length === 0) {
    // handle empty object
    return null;
  }

  if (!(imageOrSomethingLikeIt.url || imageOrSomethingLikeIt["cover-image-url"])) {
    // handle an object with all keys present but values undefined
    return null;
  }

  return imageOrSomethingLikeIt as SavedImage;
}

const ImageField: React.SFC<Props> = ({
  classname,
  label,
  value,
  openGallery,
  disableReplace = false,
  onReplace,
  disableDelete = false,
  onDelete,
  isPhotoEditor,
  openPhotoEditor,
  updateFocusPoint,
  updateImageDetails,
  setSelectedMedia,
  switchToUploadRoute,
  showEditImage,
  updateImageUploadStatus,
  fieldSpecs,
  error
}) => {
  return (
    <>
      <div className={cx("image-field-container", classname, { "has-error": error })}>
        {label && <FieldLabel label={label} />}
        {value ? (
          <EditImage
            value={value}
            disableReplace={disableReplace}
            onReplace={onReplace}
            disableDelete={disableDelete}
            onDelete={onDelete}
            isPhotoEditor={isPhotoEditor}
            openPhotoEditor={openPhotoEditor}
            updateFocusPoint={updateFocusPoint}
            updateImage={updateImageDetails}
            fieldSpecs={fieldSpecs}
          />
        ) : (
          setSelectedMedia && (
            <AddNewMedia
              classname={styles["image-field-add-new-media"]}
              openGallery={openGallery}
              setSelectedMedia={setSelectedMedia}
              switchToUploadRoute={switchToUploadRoute}
              showEditImage={showEditImage}
              updateImageUploadStatus={updateImageUploadStatus}
              dropzoneMessageSize={DropzoneMessageVariant.Medium}
            />
          )
        )}
      </div>
      {error && <ErrorMessage message={error} />}
    </>
  );
};

export default ImageField;
