/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const MENU_INDEX_PATH = "/manage/menu";
export const MENU_NEW_PATH = "/manage/menu/new";
export const MENU_ITEMS_INDEX_PATH = `${MENU_INDEX_PATH}/:menuId`;
export const MENU_ITEM_NEW_PATH = `${MENU_ITEMS_INDEX_PATH}/items/new`;
export const MENU_ITEM_EDIT_PATH = `${MENU_ITEMS_INDEX_PATH}/items/:itemId`;
