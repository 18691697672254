/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { negate } from "lodash";
import Checkbox from "components/checkbox/checkbox";
import Facebook from "components/icons/facebook";
import Twitter from "components/icons/twitter";
import Linkedin from "components/icons/linkedin";
import CheckCircle from "components/icons/check-circle";
import ExclamationCircle from "components/icons/exclamation-circle";
import Clock from "components/icons/clock";
import Tooltip from "components/tooltip/tooltip";
import styles from "./social-account.module.css";
import { SocialCard, SocialAccount as SocialAccountType, ScheduledSocialCard, SelectedMedia } from "api/social";
import { SocialAccount as BreakingNewsSocialAccount } from "pages/breaking-news/state";

const getSocialShareIcon = (socialShareProvider: string) => {
  switch (socialShareProvider) {
    case "fb-page":
    case "facebook":
      return <Facebook />;
    case "twitter":
      return <Twitter />;
    case "linkedin":
      return <Linkedin />;
    default:
      return;
  }
};

export function isScheduledSocialCard(value: SocialCard | ScheduledSocialCard): value is ScheduledSocialCard {
  return !value.hasOwnProperty("cardId");
}

const getSocialPostStatus = (
  socialCards: Array<SocialCard | ScheduledSocialCard>,
  cardId: String,
  accountId: String
) => {
  socialCards = socialCards.filter(negate(isScheduledSocialCard));
  const card = (socialCards as SocialCard[]).filter((x) => {
    return x.cardId === cardId;
  })[0];
  const socialAccount =
    card &&
    card.selectedMedia.filter((x: SelectedMedia) => {
      return x.id === accountId;
    })[0];
  return socialAccount && socialAccount.status;
};

const getSocialPostStatusIcon = (
  socialCards: Array<SocialCard | ScheduledSocialCard>,
  cardId: String,
  accountId: String
) => {
  const style = getComputedStyle(document.body);
  const status = getSocialPostStatus(socialCards, cardId, accountId);
  switch (status) {
    case "scheduled":
      return (
        <div data-tip data-for="social-notification-status">
          <Clock color={style.getPropertyValue("--warning")} width={"24"} height={"24"} />
          <Tooltip id="social-notification-status" place="bottom" effect="solid" value={status} />
        </div>
      );
    case "success":
      return (
        <div data-tip data-for="social-notification-status">
          <CheckCircle color={style.getPropertyValue("--success")} />
          <Tooltip id="social-notification-status" place="bottom" effect="solid" value={status} />
        </div>
      );
    case "failure":
      return (
        <div data-tip data-for="social-notification-status">
          <ExclamationCircle color={style.getPropertyValue("--danger")} />
          <Tooltip id="social-notification-status" place="bottom" effect="solid" value={status} />
        </div>
      );
    default:
      return;
  }
};

interface SocialAccountCheckboxProps {
  account: SocialAccountType | BreakingNewsSocialAccount;
  id: number | string;
  disabled: boolean;
  checked: boolean;
  onChange(selected?: boolean): void;
}

export const SocialAccountCheckbox = ({ account, id, disabled, checked, onChange }: SocialAccountCheckboxProps) => {
  return (
    <React.Fragment>
      <span className={styles["social-account-provider"]}>{getSocialShareIcon(account.provider)}</span>
      <Checkbox
        id={`social-account-${id}-${account["account-id"]}`}
        label={account["account-name"]}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
    </React.Fragment>
  );
};

interface SocialAccountProps {
  account: SocialAccountType | BreakingNewsSocialAccount;
  isAccountChecked(socialCards: Array<SocialCard | ScheduledSocialCard>, cardId: string, accountId: string): boolean;
  toggleSocialAccount(cardId: string, accountId: string, accountProvider: string): void;
  socialCard: SocialCard;
  socialCards: Array<SocialCard | ScheduledSocialCard>;
  isPosted: boolean;
  isStoryLocked: boolean;
}

const SocialAccount = ({
  account,
  isAccountChecked,
  toggleSocialAccount,
  socialCard,
  socialCards,
  isPosted,
  isStoryLocked
}: SocialAccountProps) => {
  return (
    <React.Fragment>
      <div className={styles["social-account"]}>
        <SocialAccountCheckbox
          account={account}
          id={socialCard.cardId}
          disabled={isPosted || isStoryLocked}
          checked={isAccountChecked(socialCards, socialCard.cardId, account["account-id"])}
          onChange={() => toggleSocialAccount(socialCard.cardId, account["account-id"], account.provider)}
        />
        <span className={styles["social-account-publish-result"]}>
          {getSocialPostStatusIcon(socialCards, socialCard.cardId, account["account-id"])}
        </span>
      </div>
    </React.Fragment>
  );
};

export default SocialAccount;
