/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Checkbox as EmCheckbox } from "@quintype/em/components/checkbox";

interface CheckboxProps {
  /** Label for Checkbox */
  label?: string;
  /** Id for associating label with input checkbox */
  id: string;
  /** Controlled prop to toggle checked behaviour */
  checked: boolean;
  /** To disable the checkbox */
  disabled?: boolean;
  /** OnChange callback  */
  onChange?: (checked: boolean) => void;
  /* Renders label to the left and checkbox to the right when passed true */
  renderIconToRight?: boolean;
  /* Custom classname */
  classname?: string;
}

const Checkbox = (props: CheckboxProps) => <EmCheckbox {...props} />;

export default Checkbox;
export { Checkbox };
