/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { compose, AnyAction } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { PartialAppState } from "store/form/settings";
import { saveSettings } from "store/form/settings";
import Button from "components/button/button";
import Header from "components/header/header";

interface StateProps {
  isSaveButtonEnabled: boolean;
}

interface DispatchProps {
  saveSettings: () => void;
}

type FormsSettingsProps = StateProps & DispatchProps;

export const FormsSettingsHeader: React.FC<FormsSettingsProps> = ({ saveSettings, isSaveButtonEnabled }) => {
  return (
    <Header>
      <Button variant="success" type="primary" disabled={!isSaveButtonEnabled} onClick={saveSettings}>
        {t("save")}
      </Button>
    </Header>
  );
};

const mapStateToProps = (state: PartialAppState) => ({
  isSaveButtonEnabled: state.forms.settings.ui.isSaveButtonEnabled
});

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>) => ({
  saveSettings: () => dispatch(saveSettings())
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(FormsSettingsHeader);
