/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import produce from "immer";

import { State, INITIAL_STATE, InspectorType } from "./state";
import Actions, { ActionTypes } from "./actions";

const reducer = (state: State = INITIAL_STATE, action: ActionTypes) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case Actions.LOAD_CUSTOM_FIELDS:
        draft.ui.main = { loading: true, error: null };
        break;
      case Actions.LOAD_CUSTOM_FIELDS_SUCCESS:
        draft.customFields = action.payload.customFields;
        draft.ui.main = { loading: false, error: null };
        break;
      case Actions.LOAD_CUSTOM_FIELDS_FAILURE:
        draft.ui.main = { loading: false, error: action.payload.error };
        break;
      case Actions.SAVE_CUSTOM_FIELD:
        draft.ui.save = { loading: true, error: null };
        break;
      case Actions.SAVE_CUSTOM_FIELD_SUCCESS:
        draft.ui.save = { loading: false, error: null };
        break;
      case Actions.SAVE_CUSTOM_FIELD_FAILURE:
        draft.ui.save = { loading: false, error: action.payload.error };
        break;
      case Actions.DELETE_CUSTOM_FIELD:
        draft.app.customFieldToBeDeleted = action.payload.customField;
        draft.ui.delete = { loading: true, error: null };
        break;
      case Actions.DELETE_CUSTOM_FIELD_SUCCESS:
        draft.app.customFieldToBeDeleted = null;
        draft.ui.delete = { loading: false, error: null };
        break;
      case Actions.DELETE_CUSTOM_FIELD_FAILURE:
        draft.app.customFieldToBeDeleted = null;
        draft.ui.delete = { loading: false, error: action.payload.error };
        break;
      case Actions.INIT_NEW_CUSTOM_FIELD:
        draft.ui.inspectorType = InspectorType.Create;
        draft.app.currentCustomField = null;
        break;
      case Actions.EDIT_CUSTOM_FIELD:
        draft.app.currentCustomField = action.payload.customField;
        draft.ui.inspectorType = InspectorType.Edit;
        break;
      case Actions.EDIT_CUSTOM_FIELD_FAILURE:
        draft.ui.main = { loading: false, error: action.payload.error };
        break;
      case Actions.CANCEL_CREATE_OR_EDIT_CUSTOM_FIELD:
        draft.app.currentCustomField = null;
        draft.ui.inspectorType = null;
        break;
      case Actions.EDIT_CUSTOM_FIELD_SUCCESS:
        draft.app.currentCustomField = null;
        draft.ui.inspectorType = null;
        break;
    }
  });
};

export default reducer;
