/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useCallback, useEffect, useRef } from "react";
import classnames from "classnames/bind";
import styles from "./realtime-workspace.module.css";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { connect } from "react-redux";
import { PartialAppState, WorkspaceRealtimeUI } from "api/workspace";
import {
  fetchWorkspaceData,
  refreshRealtimeStoryDataAction,
  updateRealtimeUiAction
} from "pages/workspace/action-creators";
import { WorkspaceSliderConfig } from "store/route-data";
import usePrevious from "custom-hooks/previous";
import { StoryStatus } from "api/story";
import { WORKSPACE_TABS_PATH } from "pages/workspace/routes";
import { navigate } from "utils/routes.utils";
import { t } from "i18n";

const cx = classnames.bind(styles);

type OwnProps = {
  workspaceTabSlug: string;
};

type StateProps = {
  realtimeUiConfig: WorkspaceRealtimeUI;
  isCurrentWindowOpenedAsSliderInMainWindow: boolean;
  workspaceSliderConfig: WorkspaceSliderConfig;
};

type DispatchProps = {
  refreshRealtimeData: (workspaceTabSlug: string) => void;
  updateWorkspace: (workspaceTabSlug: string) => void;
  updateRealtimeUiConfig: (realtimeUiConfig: Partial<WorkspaceRealtimeUI>) => void;
  navigateToWorkspaceTab: (workspaceTabSlug: StoryStatus) => void;
};

type Props = OwnProps & StateProps & DispatchProps;

export const RealtimeWorkspace: React.FC<Props> = ({
  workspaceTabSlug,
  refreshRealtimeData,
  updateWorkspace,
  realtimeUiConfig,
  updateRealtimeUiConfig,
  isCurrentWindowOpenedAsSliderInMainWindow,
  workspaceSliderConfig,
  navigateToWorkspaceTab
}) => {
  const pollTimerRef = useRef<NodeJS.Timeout | null>(null);
  const mouseLeaveTimerRef = useRef<NodeJS.Timeout | null>(null);
  const previousWorkspaceTabSlug = usePrevious(workspaceTabSlug);

  const stopRealtimeDataPoll = useCallback(() => {
    if (pollTimerRef.current) {
      clearInterval(pollTimerRef.current);
    }
  }, []);

  const startRealtimeDataPoll = useCallback(() => {
    const pollIntervalInSeconds = workspaceSliderConfig.pollIntervalInSeconds || 10;
    stopRealtimeDataPoll();
    pollTimerRef.current = setTimeout(() => {
      refreshRealtimeData(workspaceTabSlug);
      startRealtimeDataPoll();
    }, pollIntervalInSeconds * 1000);
  }, [workspaceSliderConfig.pollIntervalInSeconds, stopRealtimeDataPoll, refreshRealtimeData, workspaceTabSlug]);

  // Start a poll to query workspace status count
  useEffect(() => {
    if (!pollTimerRef.current && !realtimeUiConfig.isUpdatesPending) {
      startRealtimeDataPoll();
    }
  }, [startRealtimeDataPoll, realtimeUiConfig.isUpdatesPending]);

  // Stop poll when updates are pending and slider is closed
  useEffect(() => {
    if (!isCurrentWindowOpenedAsSliderInMainWindow && realtimeUiConfig.isUpdatesPending) {
      stopRealtimeDataPoll();
    }
  }, [realtimeUiConfig.isUpdatesPending, stopRealtimeDataPoll, isCurrentWindowOpenedAsSliderInMainWindow]);

  // // When workspace tab slug changes, destroy and create poll again
  useEffect(() => {
    if (previousWorkspaceTabSlug && workspaceTabSlug !== previousWorkspaceTabSlug) {
      startRealtimeDataPoll();
    }
  }, [stopRealtimeDataPoll, startRealtimeDataPoll, previousWorkspaceTabSlug, workspaceTabSlug]);

  // Clear poll on component unmount
  useEffect(() => {
    return () => {
      stopRealtimeDataPoll();
    };
  }, [stopRealtimeDataPoll]);

  // Check if the user mouse has not entered the main layout screen
  // for more than 30 seconds and update user active status in redux
  useEffect(() => {
    const userInactivityThresholdInSeconds = workspaceSliderConfig.userInactivityThresholdInSeconds || 10;
    const mainLayout = document.querySelector("#main-layout");
    if (mainLayout) {
      mainLayout.addEventListener("mouseenter", () => {
        updateRealtimeUiConfig({ isUserActiveInScreen: true });
        // Clear the leave timer if the mouse re-enters within 30 seconds
        if (mouseLeaveTimerRef.current) {
          clearTimeout(mouseLeaveTimerRef.current);
          mouseLeaveTimerRef.current = null;
        }
      });

      mainLayout.addEventListener("mouseleave", () => {
        // Start a 30 seconds timer to check if the mouse is still outside
        mouseLeaveTimerRef.current = setTimeout(() => {
          updateRealtimeUiConfig({ isUserActiveInScreen: false });
        }, userInactivityThresholdInSeconds * 1000);
      });
    }
  }, [updateRealtimeUiConfig, workspaceSliderConfig.userInactivityThresholdInSeconds]);

  const updateWorkspaceCallback = useCallback(() => {
    updateWorkspace(workspaceTabSlug);
  }, [updateWorkspace, workspaceTabSlug]);

  // Fetch workspace update if it's pending to apply, when slider is opened again
  useEffect(() => {
    if (realtimeUiConfig.isUpdatesPending && isCurrentWindowOpenedAsSliderInMainWindow) {
      updateWorkspaceCallback();
      updateRealtimeUiConfig({ isUpdatesPending: false });
      startRealtimeDataPoll();
    }
  }, [
    realtimeUiConfig.isUpdatesPending,
    updateRealtimeUiConfig,
    updateWorkspaceCallback,
    isCurrentWindowOpenedAsSliderInMainWindow,
    startRealtimeDataPoll,
    navigateToWorkspaceTab
  ]);

  const handlePopupClick = () => {
    updateRealtimeUiConfig({ showFetchWorkspaceUpdatePopup: false });
    updateWorkspaceCallback();
  };

  return (
    <button
      className={cx("workspace-realtime", {
        "workspace-realtime--show": realtimeUiConfig.showFetchWorkspaceUpdatePopup
      })}
      data-test-id="realtime-workspace-popup"
      onClick={handlePopupClick}>
      <div className={cx("content")}>
        <p>{t("workspace.realtime.view-new-updates")}</p>
      </div>
    </button>
  );
};

const mapStateToProps = (state: PartialAppState): StateProps => {
  return {
    isCurrentWindowOpenedAsSliderInMainWindow: state.slider.currentWindow.isOpenedAsSliderFromMainWindow,
    realtimeUiConfig: state.workspace.realtime.ui,
    workspaceSliderConfig: state.config.workspaceSlider
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<PartialAppState, void, AnyAction>): DispatchProps => {
  return {
    refreshRealtimeData: (workspaceTabSlug: string) => {
      return dispatch(refreshRealtimeStoryDataAction(workspaceTabSlug));
    },
    updateWorkspace: (workspaceTabSlug: string) => {
      return dispatch(fetchWorkspaceData(workspaceTabSlug));
    },
    updateRealtimeUiConfig: (realtimeUiConfig: Partial<WorkspaceRealtimeUI>) => {
      return dispatch(updateRealtimeUiAction(realtimeUiConfig));
    },
    navigateToWorkspaceTab: (workspaceTabSlug: StoryStatus) => {
      return dispatch(navigate(WORKSPACE_TABS_PATH, { tabSlug: workspaceTabSlug }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RealtimeWorkspace);
