/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as api from "api/contributor-roles";
import {
  loadContributorRolesAction,
  maybeAddContributorRoleAction,
  updateCurrentContributorRoleAction,
  cancelUpdateContributorRoleAction,
  maybeEditContributorRoleAction,
  maybeDeleteContributorRoleAction,
  deleteContributorRoleSuccessAction,
  cancelDeleteContributorRoleAction,
  saveContributorRoleFailureAction,
  updateContributorRoleFailureAction,
  cancelSaveContributorRoleAction
} from "./action-creators";

import { navigate } from "utils/routes.utils";
import { AnyContributorRole, UnsavedContributorRole } from "./state";
import { CONFIGURE_CONTRIBUTOR_ROLES_PATH } from "pages/settings/routes";
import { ThunkDispatch } from "redux-thunk";
import { ContributorRoleId } from "api/primitive-types";
import { ContributorRole } from "api/contributor-roles";
import { NOTIFICATION_SUCCESS, NOTIFICATION_ERROR } from "containers/page/actions";
import { t } from "i18n";

export const loadContributorRoles = () => async (dispatch: ThunkDispatch<any, any, any>) => {
  try {
    const contributorRoles = await api.getContributorRoles();
    dispatch(loadContributorRolesAction(contributorRoles));
  } catch (error) {
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: {
        message: t("settings.contributor-roles.messages.server-error")
      }
    });
  }
};

export const maybeAddContributorRole = (shouldLoadList: boolean) => async (dispatch: ThunkDispatch<any, any, any>) => {
  try {
    let allContributorRoles: any = null;
    if (shouldLoadList) {
      allContributorRoles = await api.getContributorRoles();
    }
    dispatch(maybeAddContributorRoleAction(allContributorRoles));
  } catch (error) {
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: {
        message: t("settings.contributor-roles.messages.server-error")
      }
    });
  }
};

export const updateCurrentContributorRole = (contributorRole: AnyContributorRole) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(updateCurrentContributorRoleAction(contributorRole));
};

export const cancelSaveContributorRole = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(navigate(CONFIGURE_CONTRIBUTOR_ROLES_PATH));
  dispatch(cancelSaveContributorRoleAction());
};

export const cancelUpdateContributorRole = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(navigate(CONFIGURE_CONTRIBUTOR_ROLES_PATH));
  dispatch(cancelUpdateContributorRoleAction());
};

export const saveContributorRole = (contributorRole: UnsavedContributorRole) => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  try {
    await api.createContributorRole(contributorRole);
    dispatch(navigate(CONFIGURE_CONTRIBUTOR_ROLES_PATH));
    dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: {
        message: t("settings.contributor-roles.messages.save-success")
      }
    });
  } catch (error) {
    if (error.status && error.status === 422) {
      dispatch(saveContributorRoleFailureAction()); // inline err message
    } else {
      dispatch({
        type: NOTIFICATION_ERROR,
        payload: {
          message: t("settings.contributor-roles.messages.server-error")
        }
      });
    }
  }
};

export const maybeEditContributorRole = (contributorRoleId: ContributorRoleId, shouldLoadList: boolean) => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  try {
    let allContributorRoles: ContributorRole[] | null = null;
    if (shouldLoadList) {
      allContributorRoles = await api.getContributorRoles();
      dispatch(loadContributorRolesAction(allContributorRoles));
    }
    const currentContributorRole = await api.getContributorRole(contributorRoleId);
    dispatch(maybeEditContributorRoleAction(currentContributorRole));
  } catch (error) {
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: {
        message: t("settings.contributor-roles.messages.server-error")
      }
    });
  }
};

export const updateContributorRole = (contributorRole: ContributorRole) => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  try {
    await api.updateContributorRole(contributorRole);
    dispatch(navigate(CONFIGURE_CONTRIBUTOR_ROLES_PATH));
    dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: {
        message: t("settings.contributor-roles.messages.update-success")
      }
    });
  } catch (error) {
    if (error.status && error.status === 422) {
      dispatch(updateContributorRoleFailureAction()); // inline err message
    } else {
      dispatch({
        type: NOTIFICATION_ERROR,
        payload: {
          message: t("settings.contributor-roles.messages.server-error")
        }
      });
    }
  }
};

export const maybeDeleteContributorRole = (contributorRole: ContributorRole) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(maybeDeleteContributorRoleAction(contributorRole));
};

export const deleteContributorRole = (contributorRole: ContributorRole) => async (
  dispatch: ThunkDispatch<any, any, any>
) => {
  try {
    await api.deleteContributorRole(contributorRole.id);
    dispatch(deleteContributorRoleSuccessAction());
    dispatch(loadContributorRoles());
    dispatch({
      type: NOTIFICATION_SUCCESS,
      payload: {
        message: t("settings.contributor-roles.messages.delete-success")
      }
    });
  } catch (error) {
    dispatch({
      type: NOTIFICATION_ERROR,
      payload: {
        message: t("settings.contributor-roles.messages.server-error")
      }
    });
  }
};

export const cancelDeleteContributorRole = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(cancelDeleteContributorRoleAction());
};
