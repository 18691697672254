/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React, { useState } from "react";
import { formattedDate } from "utils";
import { ExportState, Form, Format } from "store/form/form";
import { FORMS_EDITOR_PATH as formEditorPath } from "pages/forms/routes";
import Chip from "components/chip/chip";
import Clock from "components/icons/clock";
import FormActionsMenu from "pages/forms/actions-menu/actions-menu";
import FormModal, { FormModalType } from "pages/forms/modal/modal";
import styles from "./panel.module.css";
import { NavLink } from "react-router-dom";

interface Props {
  form: Form;
  retractForm: (formId: number) => void;
  deleteForm: (formId: number) => void;
  exportSubmissions: (formId: number, format: Format) => void;
  exportState: Partial<ExportState>;
}

const FormPanel: React.FC<Props> = ({
  form: { updatedAt, headline, status, id: formId },
  retractForm,
  deleteForm,
  exportSubmissions,
  exportState
}) => {
  const [modalType, setModalType] = useState<FormModalType | null>(null);
  const isFormOpen = status === "draft";
  const isFormPublished = status === "published";
  const showDeleteButton = isFormOpen || isFormPublished;
  const showRetractButton = isFormPublished;
  const formattedUpdatedAt = formattedDate(updatedAt);
  const closeModal = () => setModalType(null);
  const onRetractButtonClick = () => setModalType(FormModalType.Retract);
  const onDeleteButtonClick = () => setModalType(FormModalType.Delete);
  const onRetractForm = () => retractForm(formId);
  const onDeleteForm = () => deleteForm(formId);

  return (
    <>
      <div className={styles["form-panel"]}>
        <div className={styles["form-panel-content"]}>
          <div className={styles["form-panel-chips"]}>
            <Chip value={status} invert classname={"form-panel-chip"} />
          </div>

          <NavLink
            to={`${formEditorPath}${formId}`}
            className={styles["form-panel-headline"]}
            data-test-id="form-panel-headline">
            {headline}
          </NavLink>

          <div className={styles["form-panel-byline"]}>
            <div className={styles["form-panel-time-wrapper"]}>
              <Clock />
              <time className={styles["form-panel-time"]}>{formattedUpdatedAt}</time>
            </div>
          </div>
        </div>

        <FormActionsMenu
          classname={styles["form-panel-actions"]}
          showRetractButton={showRetractButton}
          showDeleteButton={showDeleteButton}
          showExportButton={isFormPublished}
          exportState={exportState}
          onRetractButtonClick={onRetractButtonClick}
          onDeleteButtonClick={onDeleteButtonClick}
          onExportButtonClick={(format) => exportSubmissions(formId, format)}
        />
      </div>

      <FormModal modalType={modalType} retractForm={onRetractForm} deleteForm={onDeleteForm} closeModal={closeModal} />
    </>
  );
};

export default FormPanel;
