/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import buildSearchQuery from "./build-search-query";
import * as actions from "./actions";
import {
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR,
  notificationSuccess,
  notificationError
} from "containers/page/actions";
import {
  getWorkspaceData,
  deleteStory,
  saveFilters,
  updateSavedFilter,
  translateStory,
  cloneStory,
  getStoryTranslations,
  StoryTranslation,
  cloneStorytoPrint,
  getStoryWorkspaceRealtimeData
} from "helpers/api";
import { modifySavedFilter, modifyForApi } from "./filter-conversion";
import { navigateFn } from "../../utils/routes.utils";
import { WORKSPACE_TABS_PATH } from "./routes";
import pDebounce from "p-debounce";
import { t } from "i18n";
import { AiStoryTranslationConfig, PartialAppState, WorkspaceRealtimeData, WorkspaceRealtimeUI } from "api/workspace";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import copyToClipboard from "helpers/copy-to-clipboard";
import { get } from "lodash";
import { WindowShowUpdatesIndicator } from "containers/app-layout/app-layout";
import {
  isNewStoriesAdded,
  getChangedStoryStatuses,
  scrollToTopOfScreen,
  convertWorkspaceResponseToRealtimeData
} from "pages/workspace/realtime";

const debouncedGetData: any = pDebounce(getWorkspaceData, 250);

const PRINT_SOURCE_ATTRIBUTE = {
  type: "story",
  name: "distribution-medium",
  values: ["print"],
  "display-name": "print",
  "data-type": "multi-valued-strings",
  "is-mandatory": false
};

export const setSavedFilterAction = (filter: any, modifiedFilter: any) => (dispatch: any) => {
  dispatch({ type: actions.WORKSPACE_SET_SELECTED_SAVED_FILTER, payload: { filter } });

  const filterSet = modifiedFilter.filter;
  Object.keys(filterSet).forEach((key) => {
    let value = filterSet[key];
    dispatch({ type: actions.WORKSPACE_UPDATE_STAGING_FILTER, payload: { key, value } });
  });
};

export const addPrintSourceFilter = (filterSet, isPrintSourceSelected: boolean) => {
  let addFilter = isPrintSourceSelected ? "attributes" : "exclude-attributes";
  let removeFilter = addFilter === "attributes" ? "exclude-attributes" : "attributes";

  if (!filterSet[addFilter]) {
    filterSet[addFilter] = [];
    filterSet[addFilter].push(PRINT_SOURCE_ATTRIBUTE);
  } else {
    const updateindex = filterSet[addFilter].length
      ? filterSet[addFilter].findIndex((attribute) => attribute.name === "distribution-medium")
      : -1;
    if (updateindex !== -1) {
      filterSet[addFilter][updateindex] = PRINT_SOURCE_ATTRIBUTE;
    } else {
      filterSet[addFilter].push(PRINT_SOURCE_ATTRIBUTE);
    }
  }

  if (filterSet[removeFilter] && filterSet[removeFilter].length) {
    const removedFilter = filterSet[removeFilter].filter((attribute) => attribute.name !== "distribution-medium");
    filterSet[removeFilter] = removedFilter;
  }
  return filterSet;
};

export const fetchWorkspaceData: any = (selectedTabSlug: any, defaultFilter: any) => (dispatch: any, getState: any) => {
  const modifiedDefaultFilter = defaultFilter ? modifySavedFilter(defaultFilter) : defaultFilter;

  if (modifiedDefaultFilter) {
    dispatch(setSavedFilterAction(defaultFilter, modifiedDefaultFilter));
    dispatch({ type: actions.WORKSPACE_REPLACE_FILTER, payload: { filterSet: modifiedDefaultFilter.filter } });
  }
  const state = getState();
  let filterSet = (modifiedDefaultFilter && modifiedDefaultFilter.filter) || state.workspace.currentFilterSet;
  dispatch({ type: actions.WORKSPACE_FETCH_DATA, payload: { filterSet } });
  if (get(state, ["features", "isStoryPrintEnabled"], false)) {
    filterSet = addPrintSourceFilter(filterSet, get(state, ["workspace", "isPrintSelected"], false));
  }
  const query = buildSearchQuery({ ...filterSet, selectedTabSlug });
  getWorkspaceData(query).then(
    (data) => {
      dispatch({ type: actions.WORKSPACE_DATA_SUCCESS, payload: { data } });
      // There is no offset in workspace query, so using limit to know if its first call of a new filter
      if (query["limit"] === 20) {
        updateRealtimeDataAction(dispatch, getState(), convertWorkspaceResponseToRealtimeData(data));
      }
    },
    () => {
      dispatch({ type: actions.WORKSPACE_ADD_ERRORS, payload: "fetchingStories" });
    }
  );
};

export const togglePrintSourceFilters: any = (enabled: boolean) => (dispatch: any, getState: any) => {
  // Reset filters when going in or out of Print
  if (getState().workspace.isPrintSelected !== enabled) {
    dispatch({
      type: actions.WORKSPACE_RESET_FILTER
    });
  }
  dispatch({
    type: actions.WORKSPACE_PRINT_SOURCE_TOGGLE,
    payload: {
      isPrintSelected: enabled
    }
  });
};

export const fetchWorkspaceSearchData = (selectedTabSlug: any, defaultFilter: any) => (
  dispatch: any,
  getState: any
) => {
  const modifiedDefaultFilter = defaultFilter ? modifySavedFilter(defaultFilter) : defaultFilter;

  if (modifiedDefaultFilter) {
    dispatch(setSavedFilterAction(defaultFilter, modifiedDefaultFilter));
    dispatch({ type: actions.WORKSPACE_REPLACE_FILTER, payload: { filterSet: modifiedDefaultFilter.filter } });
  }
  const state = getState();
  let filterSet = (modifiedDefaultFilter && modifiedDefaultFilter.filter) || state.workspace.currentFilterSet;
  dispatch({ type: actions.WORKSPACE_FETCH_DATA, payload: { filterSet } });
  if (get(state, ["features", "isStoryPrintEnabled"], false)) {
    filterSet = addPrintSourceFilter(filterSet, get(state, ["workspace", "isPrintSelected"], false));
  }
  const query = buildSearchQuery({ ...filterSet, selectedTabSlug });
  debouncedGetData(query).then(
    (data: any) => {
      dispatch({ type: actions.WORKSPACE_DATA_SUCCESS, payload: { data } });
      // There is no offset in workspace query, so using limit to know if its first call of a new filter
      if (query["limit"] === 20) {
        updateRealtimeDataAction(dispatch, getState(), convertWorkspaceResponseToRealtimeData(data));
      }
    },
    () => {
      dispatch({ type: actions.WORKSPACE_ADD_ERRORS, payload: "fetchingStories" });
    }
  );
};

export const deleteWorkspaceStory = (storyId: any, selectedTabSlug: any, onClose: any) => (dispatch: any) => {
  dispatch({ type: actions.WORKSPACE_DELETE_STORY });
  deleteStory(storyId)
    .then(() => onClose(selectedTabSlug))
    .then(() => dispatch(fetchWorkspaceData(selectedTabSlug)));
};

export const saveFiltersAction = (filterSet: any, filterName: any, tabSlug: any) => (dispatch: any) => {
  const navigate = navigateFn(dispatch);
  const filterForApi = modifyForApi({ filter: filterSet });

  dispatch({ type: actions.WORKSPACE_FILTER_SAVE });
  saveFilters(filterForApi.filter, filterName).then(
    (filter) => {
      dispatch({ type: actions.WORKSPACE_SET_SELECTED_SAVED_FILTER, payload: { filter } });
      navigate(WORKSPACE_TABS_PATH, { tabSlug });
      dispatch({
        type: NOTIFICATION_SUCCESS,
        payload: { message: t("workspace.filter-save-success") }
      });
    },
    () => {
      dispatch({ type: actions.WORKSPACE_REMOVE_LOADER });
      dispatch({
        type: NOTIFICATION_ERROR,
        payload: {
          message: t("workspace.filter-save-failed"),
          action: {
            label: "Retry",
            callback: () => dispatch(saveFiltersAction(filterSet, filterName, tabSlug))
          }
        }
      });
    }
  );
};

export const updateFilterAction = (savedFilter: any, filterSet: any) => (dispatch: any) => {
  const filter = { ...savedFilter, filter: filterSet };
  const filterForApi = modifyForApi(filter);

  dispatch({ type: actions.WORKSPACE_UPDATE_SELECTED_SAVED_FILTER });
  updateSavedFilter(savedFilter.id, filterForApi);

  dispatch({ type: actions.WORKSPACE_SET_SELECTED_SAVED_FILTER, payload: { filter: filterForApi } });
};

export const updatePageReferer = (referer: any) => (dispatch: any) => {
  dispatch({ type: actions.WORKSPACE_UPDATE_PAGE_REFERER, payload: { referer } });
};

export const translateStoryAction = (storyId: string, storyTranslationConfig: AiStoryTranslationConfig) => async (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>
): Promise<void> => {
  try {
    dispatch(notificationSuccess(t("workspace.translation.messages.initiated")));
    const response = await translateStory(storyId, storyTranslationConfig);
    const translatedStoryEditorUrl = response["editor-url"];
    copyToClipboard(translatedStoryEditorUrl);
    dispatch(notificationSuccess(t("workspace.translation.messages.success")));
    window.open(translatedStoryEditorUrl, "_blank");
  } catch (_error) {
    dispatch(notificationError(t("workspace.translation.messages.failure")));
  }
};

export const getStoryTranslationsAction = (storyId: string, translationConfig: AiStoryTranslationConfig) => async (
  dispatch,
  getState
): Promise<{ translations: StoryTranslation[]; error?: string }> => {
  try {
    const result = await getStoryTranslations(
      storyId,
      translationConfig["source-language"],
      translationConfig["target-language"],
      get(translationConfig, ["target-publisher", "id"], getState().config.publisher.id)
    );
    return { translations: result.translations };
  } catch (_error) {
    dispatch(notificationError(t("workspace.translation.messages.failure")));
    return { translations: [], error: "Failed to get translations" };
  }
};

export const cloneStoryAction = (storyId: string) => async (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>
): Promise<void> => {
  try {
    dispatch(notificationSuccess(t("workspace.clone.messages.initiated")));
    const response = await cloneStory(storyId);
    const clonedStoryEditorUrl = response["editor-url"];
    copyToClipboard(clonedStoryEditorUrl);
    dispatch(notificationSuccess(t("workspace.clone.messages.success")));
    window.open(clonedStoryEditorUrl, "_blank");
  } catch (_error) {
    dispatch(notificationError(t("workspace.clone.messages.failure")));
  }
};

export const cloneStorytoPrintAction = (storyId: string) => async (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>
): Promise<void> => {
  try {
    dispatch(notificationSuccess(t("workspace.clone_for_print.messages.initiated")));
    const response = await cloneStorytoPrint(storyId);
    const clonedStoryEditorUrl = response["editor-url"];
    copyToClipboard(clonedStoryEditorUrl);
    dispatch(notificationSuccess(t("workspace.clone_for_print.messages.success")));
    window.open(clonedStoryEditorUrl, "_blank");
  } catch (_error) {
    dispatch(notificationError(t("workspace.clone_for_print.messages.failure")));
  }
};

export const updateRealtimeUiAction = (realtimeUiConfig: Partial<WorkspaceRealtimeUI>) => (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>
): void => {
  dispatch({ type: actions.UPDATE_STORY_WORKSPACE_REALTIME_UI, payload: realtimeUiConfig });
};

const updateRealtimeDataAction = (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  state: PartialAppState,
  realtimeData: WorkspaceRealtimeData
) => {
  const isCurrentWindowIsInSlider = state.slider.currentWindow.isInSlider;
  if (isCurrentWindowIsInSlider) {
    // Update realtime aggregations count only when current value is newer than previous values
    const prevComputedAt = state.workspace.realtime.data.computedAt;
    if (realtimeData.computedAt && realtimeData.computedAt > prevComputedAt) {
      dispatch({ type: actions.UPDATE_STORY_WORKSPACE_REALTIME_DATA, payload: { ...realtimeData } });
      // Close apply update popup if its open
      if (state.workspace.realtime.ui.showFetchWorkspaceUpdatePopup) {
        dispatch(updateRealtimeUiAction({ showFetchWorkspaceUpdatePopup: false }));
      }
    }
  }
};

export const refreshRealtimeStoryDataAction = (workspaceTabSlug: string) => async (
  dispatch: ThunkDispatch<PartialAppState, void, AnyAction>,
  getState: () => PartialAppState
): Promise<void> => {
  const state = getState();
  let filterSet = state.workspace.currentFilterSet;
  if (get(state, ["features", "isStoryPrintEnabled"], false)) {
    filterSet = addPrintSourceFilter(filterSet, get(state, ["workspace", "isPrintSelected"], false));
  }
  const query = buildSearchQuery({ ...filterSet, selectedTabSlug: workspaceTabSlug });
  const realtimeData = await getStoryWorkspaceRealtimeData(query);
  const prevComputedAt = getState().workspace.realtime.data.computedAt;
  const currentFilterHash = getState().workspace.realtime.data.filterHash;
  const isRealtimeDataValid = realtimeData.filterHash === currentFilterHash && realtimeData.computedAt > prevComputedAt;

  // Process story aggregations count only if
  // 1) Realtime filter hash is same as the one set by workspace fetch call
  // 2) Data is newer
  if (isRealtimeDataValid) {
    const changedStoryStatuses = getChangedStoryStatuses(getState().workspace.realtime.data, realtimeData);
    const newStoriesAddedInCurrentTab = isNewStoriesAdded(getState().workspace.realtime.data, realtimeData);
    const isSliderWindowOpenInMainWindow = getState().slider.currentWindow.isOpenedAsSliderFromMainWindow;
    // Apply update or show popup when slider window is open
    if (isSliderWindowOpenInMainWindow) {
      const isUserActive = getState().workspace.realtime.ui.isUserActiveInScreen;
      if (isUserActive) {
        // If user is active, then process story status update when there if
        // 1) Change in story count in user's current tab
        // 2) New stories added in current tab based on all applied filters
        if (changedStoryStatuses.changeInCountStatuses.includes(workspaceTabSlug) || newStoriesAddedInCurrentTab) {
          dispatch(updateRealtimeUiAction({ showFetchWorkspaceUpdatePopup: true }));
        } else {
          // Update computed at timestamp, even though there are no change in count
          updateRealtimeDataAction(dispatch, getState(), realtimeData);
        }
      } else {
        // If user is not active, then process story status update when there if
        // 1) Change in story count in user's current tab
        // 2) New stories added in current tab based on all applied filters
        if (changedStoryStatuses.changeInCountStatuses.includes(workspaceTabSlug) || newStoriesAddedInCurrentTab) {
          updateRealtimeDataAction(dispatch, getState(), realtimeData);
          dispatch(fetchWorkspaceData(workspaceTabSlug));
          scrollToTopOfScreen();
        } else {
          // Update computed at timestamp, even though there are no change in count
          updateRealtimeDataAction(dispatch, getState(), realtimeData);
        }
      }
    } else {
      // If slider is closed, then show notification in slider toggle if
      // 1) Increase in story count in current tab
      // 2) New stories added in current tab based on all applied filters
      if (changedStoryStatuses.increaseInCountStatuses.includes(workspaceTabSlug) || newStoriesAddedInCurrentTab) {
        const mainWindowShowUpdatesIndicator: WindowShowUpdatesIndicator = get(window, [
          "parent",
          "showUpdatesIndicator"
        ]);
        if (mainWindowShowUpdatesIndicator) {
          mainWindowShowUpdatesIndicator(true);
        }
        dispatch(
          updateRealtimeUiAction({
            isUpdatesPending: true
          })
        );
      } else {
        // Update computed at timestamp, even though there are no change in count
        updateRealtimeDataAction(dispatch, getState(), realtimeData);
      }
    }
  }
};
