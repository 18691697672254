/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const Title = ({ width = "24", height = "24", color = "#c6ccd5" }) => (
  <svg width={width} height={height} fill={color} viewBox="0 0 24 24">
    <path fillRule="evenodd" className="cls-3" d="M2.98 4.12v1.54h8.04v15.46h1.92V5.66h8.04V4.12h-18z" />
  </svg>
);

export default Title;
