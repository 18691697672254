/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { ContextMenu, ContextMenuList, ContextMenuItem } from "components/context-menu/context-menu";
import { t } from "i18n";
interface MobileCardEditorProps {
  moveCardUp: () => void;
  moveCardDown: () => void;
  deleteCard: () => void;
  shareCard: () => void;
  isSyndicatedReadOnlyCard?: boolean;
}

const MobileCardEditor: React.SFC<MobileCardEditorProps> = ({
  moveCardUp,
  moveCardDown,
  deleteCard,
  shareCard,
  isSyndicatedReadOnlyCard
}) => {
  return (
    <ContextMenu>
      <ContextMenuList>
        <ContextMenuItem label={t("story-editor.inspector.card-settings")} onClick={shareCard} />
        {!isSyndicatedReadOnlyCard && (
          <ContextMenuItem label={t("story-editor.inspector.move-card-up")} onClick={moveCardUp} />
        )}
        {!isSyndicatedReadOnlyCard && (
          <ContextMenuItem label={t("story-editor.inspector.move-card-down")} onClick={moveCardDown} />
        )}
        {!isSyndicatedReadOnlyCard && (
          <ContextMenuItem label={t("story-editor.inspector.delete-card")} onClick={deleteCard} />
        )}
      </ContextMenuList>
    </ContextMenu>
  );
};

export default MobileCardEditor;
