/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";
import {
  listAttributesAction,
  listAttributesSuccessAction,
  listAttributesFailureAction,
  saveAttributeAction,
  initNewAttributeAction,
  cancelCreateOrEditAttributeAction,
  editAttributeAction,
  editAttributeFailureAction,
  saveAttributeFailureAction,
  saveAttributeSuccessAction,
  deleteAttributeSuccessAction,
  deleteAttributeFailureAction,
  deleteAttributeAction,
  listEntityTypesSuccessAction,
  listEntityTypesFailureAction
} from "./action-creators";
import { Attribute } from "api/story-attributes";
import * as api from "api/story-attributes";
import * as entityApi from "api/entity-type";
import { navigate } from "utils/routes.utils";
import { ATTRIBUTES_INDEX_PATH } from "./routes";
import { findAttribute } from "./utils";
import { validateAttribute } from "./validate";
import { toClientValidationError } from "utils/validation.utils";
import { PartialAppState } from "./state";
import { AttributeNotFoundError } from "./errors";
import { t } from "i18n";

export const loadAttributes = () => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(listAttributesAction());

  try {
    const response = await api.listAttributes();
    dispatch(listAttributesSuccessAction(response["story-attributes"]));
  } catch (error) {
    dispatch(listAttributesFailureAction(error));
  }
};

export const loadEntityTypes = () => async (dispatch: ThunkDispatch<any, any, any>) => {
  try {
    const response = await entityApi.listEntityTypes();
    dispatch(listEntityTypesSuccessAction(response["entity-types"].map((entity) => entity.name)));
  } catch (error) {
    dispatch(listEntityTypesFailureAction(error));
  }
};

export const saveNewAttribute = (attribute: Attribute) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  dispatch(saveAttributeAction(attribute));

  const attributes = getState().attributes.attributes;
  const canSaveWithoutValues =
    getState().features.isFreeTextAttributesEnabled && attribute["data-type"] === "multi-valued-strings";
  const errors = validateAttribute(attribute, attributes, canSaveWithoutValues);
  if (errors) {
    dispatch(saveAttributeFailureAction(attribute, toClientValidationError<Attribute>(errors)));
    return;
  }

  const newAttributes = [...attributes, attribute];

  try {
    await api.replaceAttributes(newAttributes);
    dispatch(saveAttributeSuccessAction(attribute));
    dispatch(loadAttributes());
    dispatch(navigate(ATTRIBUTES_INDEX_PATH));
  } catch (error) {
    dispatch(saveAttributeFailureAction(attribute, error));
  }
};

export const updateExistingAttribute = (attribute: Attribute) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  dispatch(saveAttributeAction(attribute));

  const canSaveWithoutValues =
    getState().features.isFreeTextAttributesEnabled && attribute["data-type"] === "multi-valued-strings";
  const attributes = getState().attributes.attributes,
    validateAttributes = attributes.filter((_attribute) => _attribute.name !== attribute.name);

  const errors = validateAttribute(attribute, validateAttributes, canSaveWithoutValues);
  if (errors) {
    dispatch(saveAttributeFailureAction(attribute, toClientValidationError<Attribute>(errors)));
    return;
  }

  const newAttributes = attributes.map((_attribute) => (_attribute.name === attribute.name ? attribute : _attribute));

  try {
    await api.replaceAttributes(newAttributes);
    dispatch(saveAttributeSuccessAction(attribute));
    dispatch(loadAttributes());
    dispatch(navigate(ATTRIBUTES_INDEX_PATH));
  } catch (error) {
    dispatch(saveAttributeFailureAction(attribute, error));
  }
};

export const deleteAttribute = (attribute: Attribute) => async (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  dispatch(deleteAttributeAction(attribute));

  const newAttributes = getState().attributes.attributes.filter((_attribute) => _attribute !== attribute);

  try {
    await api.replaceAttributes(newAttributes);
    dispatch(deleteAttributeSuccessAction(attribute));
    dispatch(loadAttributes());
  } catch (error) {
    dispatch(deleteAttributeFailureAction(attribute, error));
  }
};

export const addNewAttribute = () => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(initNewAttributeAction());
};

export const editAttribute = (attributeName: string) => (
  dispatch: ThunkDispatch<any, any, any>,
  getState: () => PartialAppState
) => {
  const attribute = findAttribute(getState().attributes.attributes, attributeName);

  if (attribute) {
    dispatch(editAttributeAction(attribute));
  } else {
    dispatch(
      editAttributeFailureAction(
        new AttributeNotFoundError(attributeName, t("attributes.messages.not-found", { name: attributeName }))
      )
    );
  }
};

export const cancelSaveAttribute = (attribute: Attribute) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(cancelCreateOrEditAttributeAction(attribute));
  dispatch(navigate(ATTRIBUTES_INDEX_PATH));
};
