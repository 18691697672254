/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import isEmpty from "lodash/isEmpty";
import AsyncSelect from "components/select/async";
import has from "lodash/has";
import Location from "components/location/location";

import { getPublishedStories } from "helpers/api";
import debounce from "p-debounce";

import Select from "components/select/select";
import Attributes from "../attributes/attributes";
import { getErrorMessage } from "pages/story-editor/utils";

import { t } from "i18n";
import CategoryTitle from "components/category-title/category-title";
import styles from "./story-attributes.module.css";

const loadPublishedStories = debounce(
  (headline) =>
    getPublishedStories({
      headline,
      fields: "id,story-content-id,headline"
    }),
  250
);

const viewpointsOptions = () => [
  { label: t("story-editor.inspector.view"), value: "view" },
  { label: t("story-editor.inspector.counter-view"), value: "counter-view" }
];

function getViewPointsValue(selectedValue) {
  return viewpointsOptions().filter((option) => option.value === selectedValue);
}

const StoryAttributes = ({
  attributes,
  onAttributeChange,
  isLocationForStoriesEnabled,
  story,
  isViewCounterViewEnabled,
  isFreeTextAttributesEnabled,
  getAttributesOfTypeEntityOptions,
  errors,
  updateSelectedPlace,
  selectedPlace,
  disabled = false
}) => {
  const storyAttributes = !isEmpty(story.metadata) && story.metadata["story-attributes"];
  const isLinkedStoryVisible = has(storyAttributes, "view-counterview-type");

  return (
    <React.Fragment>
      <CategoryTitle title={t("story-editor.inspector.story-attributes")} />
      {isViewCounterViewEnabled && (
        <div className={styles["view-counterview-linked-story-container"]}>
          <Select
            label={t("story-editor.inspector.viewpoints")}
            options={viewpointsOptions()}
            getOptionLabel={(item) => item.label}
            getOptionValue={(item) => item.value}
            value={storyAttributes && getViewPointsValue(storyAttributes["view-counterview-type"])}
            onChange={(item: any) => onAttributeChange("view-counterview-type", item.value)}
            isDisabled={disabled}
          />
          {isLinkedStoryVisible && (
            <AsyncSelect
              label={t("story-editor.inspector.linked-story")}
              value={storyAttributes && storyAttributes["linked-view-counterview-story"]}
              onChange={(story) => onAttributeChange("linked-view-counterview-story", story)}
              getOptionLabel={(story) => story.headline}
              getOptionValue={(story) => story.id}
              loadOptions={loadPublishedStories}
              defaultOptions={true}
              errorMessage={errors && getErrorMessage(errors["linked-view-counterview-story"])}
              isDisabled={disabled}
            />
          )}
        </div>
      )}
      <Attributes
        attributes={attributes}
        onAttributeChange={onAttributeChange}
        selectedAttributes={storyAttributes}
        isFreeTextAttributesEnabled={isFreeTextAttributesEnabled}
        getAttributesOfTypeEntityOptions={getAttributesOfTypeEntityOptions}
        errors={errors}
        disabled={disabled}
      />

      {isLocationForStoriesEnabled && (
        <Location
          className="attribute-location-container"
          latitude={storyAttributes && storyAttributes["geo-location"] && storyAttributes["geo-location"].lat}
          longitude={storyAttributes && storyAttributes["geo-location"] && storyAttributes["geo-location"].lon}
          onChange={(val) => onAttributeChange("geo-location", val)}
          selectedPlace={selectedPlace}
          updateSelectedPlace={updateSelectedPlace}
          searchBoxLabel={t("common.location")}
        />
      )}
    </React.Fragment>
  );
};

export default StoryAttributes;
