/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { t } from "i18n";
import { get } from "lodash";
import TextField from "components/text-field/text-field";
import { MandrillConfig, Integrations } from "api/integrations";
import { isClientValidationError } from "utils/validation.utils";

interface Props {
  integration: { key: Integrations.Mandrill; value: MandrillConfig };
  updateIntegrationField: (key: string, value: Object) => void;
  readOnly?: boolean;
  errors: Error | null;
}

const Mandrill: React.SFC<Props> = ({ integration, updateIntegrationField, readOnly, errors }) => {
  const integrationName = integration.key;
  return (
    <React.Fragment>
      <TextField
        label={t("integrations.fields.api-key")}
        value={integration.value["api-key"]}
        onChange={(value) => updateIntegrationField(integrationName, { "api-key": value })}
        readOnly={readOnly}
        errorMessage={
          isClientValidationError<MandrillConfig>(errors) ? get(errors, ["errors", "value", "api-key", "0"]) : undefined
        }
      />
      <TextField
        label={t("integrations.fields.contact-publisher-template-name")}
        value={integration.value["contact-publisher-template-name"]}
        onChange={(value) => updateIntegrationField(integrationName, { "contact-publisher-template-name": value })}
        readOnly={readOnly}
        errorMessage={
          isClientValidationError<MandrillConfig>(errors)
            ? get(errors, ["errors", "value", "contact-publisher-template-name", "0"])
            : undefined
        }
      />
      <TextField
        label={t("integrations.fields.forgot-password-template-name")}
        value={integration.value["forgot-password-template-name"]}
        onChange={(value) => updateIntegrationField(integrationName, { "forgot-password-template-name": value })}
        readOnly={readOnly}
        errorMessage={
          isClientValidationError<MandrillConfig>(errors)
            ? get(errors, ["errors", "value", "forgot-password-template-name", "0"])
            : undefined
        }
      />
      <TextField
        label={t("integrations.fields.invite-user-template-name")}
        value={integration.value["invite-user-template-name"]}
        onChange={(value) => updateIntegrationField(integrationName, { "invite-user-template-name": value })}
        readOnly={readOnly}
        errorMessage={
          isClientValidationError<MandrillConfig>(errors)
            ? get(errors, ["errors", "value", "invite-user-template-name", "0"])
            : undefined
        }
      />
      <TextField
        label={t("integrations.fields.welcome-email-template-name")}
        value={integration.value["welcome-email-template-name"]}
        onChange={(value) => updateIntegrationField(integrationName, { "welcome-email-template-name": value })}
        readOnly={readOnly}
        errorMessage={
          isClientValidationError<MandrillConfig>(errors)
            ? get(errors, ["errors", "value", "welcome-email-template-name", "0"])
            : undefined
        }
      />
      <TextField
        label={t("integrations.fields.story-published-template-name")}
        value={integration.value["story-published-template-name"]}
        onChange={(value) => updateIntegrationField(integrationName, { "story-published-template-name": value })}
        readOnly={readOnly}
        errorMessage={
          isClientValidationError<MandrillConfig>(errors)
            ? get(errors, ["errors", "value", "story-published-template-name", "0"])
            : undefined
        }
      />
      <TextField
        label={t("integrations.fields.verification-email-template-name")}
        value={integration.value["verification-email-template-name"]}
        onChange={(value) => updateIntegrationField(integrationName, { "verification-email-template-name": value })}
        readOnly={readOnly}
        errorMessage={
          isClientValidationError<MandrillConfig>(errors)
            ? get(errors, ["errors", "value", "verification-email-template-name", "0"])
            : undefined
        }
      />
    </React.Fragment>
  );
};

export default Mandrill;
