/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { TaskId } from "api/primitive-types";
import { ThunkDispatch } from "redux-thunk";
import * as api from "api/tasks";

import { actions } from "../../actions";

export const loadTaskData = (taskId: TaskId) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch({ type: actions.LOAD_STORY_TASKS });
  try {
    const response = await api.fetchTask(taskId);
    dispatch({ type: actions.LOAD_STORY_TASKS_SUCCESS, payload: response });
  } catch (error) {
    dispatch({ type: actions.LOAD_STORY_TASKS_FAILURE, payload: error });
  }
};

export const closeTask = (taskId: TaskId) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch({ type: actions.CLOSE_TASK });
  try {
    const response = await api.closeTask(taskId);
    dispatch({ type: actions.CLOSE_TASK_SUCCESS, payload: { taskId, task: response } });
  } catch (error) {
    dispatch({ type: actions.CLOSE_TASK_FAILURE });
  }
};

export const closeAllSubtasks = (openSubtasks: api.Task[]) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch({ type: actions.CLOSE_TASK });
  try {
    openSubtasks.map(async (openSubtask, index) => {
      await api.closeTask(openSubtask.id);
      if (index === openSubtasks.length - 1) {
        dispatch({ type: actions.CLOSE_ALL_TASKS_SUCCESS });
      }
    });
  } catch (error) {
    dispatch({ type: actions.CLOSE_TASK_FAILURE });
  }
};
