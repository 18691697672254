/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { client } from "./client";

export const saveFavicon = (favicon: File) => {
  return client
    .url("/api/favicon")
    .formData({ favicon })
    .post()
    .res()
    .catch((err) => {
      return Promise.reject(JSON.parse(err.message));
    });
};
