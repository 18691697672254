/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";
import { Route, Switch } from "react-router";

import Consumers from "pages/consumers-dashboard/components/consumers/consumers";

import * as routes from "./routes";

export const Router = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path={routes.CONSUMERS_PATH} component={Consumers} />
        <Route
          exact
          path={routes.CONSUMERS_FILTERS_PATH}
          render={(props) => {
            return <Consumers {...props} showFiltersInspector={true} />;
          }}
        />
      </Switch>
    </React.Fragment>
  );
};
