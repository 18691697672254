/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { AnyTag, Tag } from "api/tag";
import { TagIdStoryCountMap } from "api/search";
import { Pagination } from "./state";

enum actions {
  LIST_TAGS = "@@tags/LIST_TAGS",
  LIST_TAGS_SUCCESS = "@@tags/LIST_TAGS_SUCCESS",
  LIST_TAGS_FAILURE = "@@tags/LIST_TAGS_FAILURE",
  SET_PAGINATION = "@@tags/SET_PAGINATION",

  REPLACE_CURRENT_TAG = "@@tags/REPLACE_CURRENT_TAG",

  INIT_NEW_TAG = "@@tags/INIT_NEW_TAG",
  EDIT_TAG = "@@tags/EDIT_TAG",
  EDIT_TAG_FAILURE = "@@tags/EDIT_TAG_FAILURE",
  CANCEL_CREATE_OR_EDIT_TAG = "@@tags/CANCEL_CREATE_OR_EDIT_TAG",

  SAVE_TAG = "@@tags/SAVE_TAG",
  SAVE_TAG_SUCCESS = "@@tags/SAVE_TAG_SUCCESS",
  SAVE_TAG_FAILURE = "@@tags/SAVE_TAG_FAILURE",

  FETCH_STORY_COUNTS = "@@tags/FETCH_STORY_COUNTS",
  FETCH_STORY_COUNTS_SUCCESS = "@@tags/FETCH_STORY_COUNTS_SUCCESS",
  FETCH_STORY_COUNTS_FAILURE = "@@tags/FETCH_STORY_COUNTS_FAILURE",

  CHANGE_SPECIFIED_PAGE = "@@tags/CHANGE_SPECIFIED_PAGE",
  SET_GENERATED_SLUG = "@@tags/SET_GENERATED_SLUG"
}

export type ListTagsAction = { type: actions.LIST_TAGS; payload: { searchTerm?: string } };
export type ListTagsSuccessAction = {
  type: actions.LIST_TAGS_SUCCESS;
  payload: { tags: Tag[]; pagination: Pagination };
};
export type ListTagsFailureAction = { type: actions.LIST_TAGS_FAILURE; payload: { error: Error } };

export type ReplaceCurrentTagAction = {
  type: actions.REPLACE_CURRENT_TAG;
  payload: { tag: AnyTag | null };
};
export type InitNewTagAction = { type: actions.INIT_NEW_TAG };
export type EditTagAction = { type: actions.EDIT_TAG; payload: { tag: Tag } };
export type EditTagFailureAction = { type: actions.EDIT_TAG_FAILURE; payload: { error: Error } };
export type CancelCreateOrEditTagAction = {
  type: actions.CANCEL_CREATE_OR_EDIT_TAG;
  payload: { tag: AnyTag };
};

export type SaveTagAction = { type: actions.SAVE_TAG; payload: { tag: AnyTag } };
export type SaveTagSuccessAction = { type: actions.SAVE_TAG_SUCCESS; payload: { tag: Tag } };
export type SaveTagFailureAction = {
  type: actions.SAVE_TAG_FAILURE;
  payload: { tag: AnyTag; error: Error };
};

export type FetchStoryCountsAction = { type: actions.FETCH_STORY_COUNTS; payload: { tags: Tag[] } };
export type FetchStoryCountsSuccessAction = {
  type: actions.FETCH_STORY_COUNTS_SUCCESS;
  payload: { tags: Tag[]; storyCounts: TagIdStoryCountMap };
};
export type FetchStoryCountsFailureAction = {
  type: actions.FETCH_STORY_COUNTS_FAILURE;
  payload: { tags: Tag[]; error: Error };
};

export type ChangeSpecifiedPageAction = {
  type: actions.CHANGE_SPECIFIED_PAGE;
  payload: { pageNumber: number };
};

export type SetGeneratedSlugAction = { type: actions.SET_GENERATED_SLUG; payload: { tag: AnyTag } };

export type ActionTypes =
  | ListTagsAction
  | ListTagsSuccessAction
  | ListTagsFailureAction
  | ReplaceCurrentTagAction
  | InitNewTagAction
  | EditTagAction
  | EditTagFailureAction
  | CancelCreateOrEditTagAction
  | SaveTagAction
  | SaveTagSuccessAction
  | SaveTagFailureAction
  | FetchStoryCountsAction
  | FetchStoryCountsSuccessAction
  | FetchStoryCountsFailureAction
  | ChangeSpecifiedPageAction
  | SetGeneratedSlugAction;

export default actions;
