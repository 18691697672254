/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { ThunkDispatch } from "redux-thunk";
import { t } from "i18n";

import * as api from "api/settings";
import * as faviconAPI from "api/favicon";
import * as seoMetadataAPI from "api/seo-metadata";
import {
  loadSettingsData,
  loadSettingsDataSuccess,
  loadSettingsDataFailure,
  updateGeneralSettings,
  updateSocialLinks,
  updateSeoMetadata,
  imageUploadingStatus,
  imageUploadFailure
} from "./action-creators";
import { SocialLinks, GeneralSettings } from "api/settings";
import { SEOMetadata } from "api/seo-metadata";
import uploadImage from "pages/media-library/image-upload";
import { UnsavedImage } from "api/search-media-image";
import { NOTIFICATION_ERROR } from "containers/page/actions";

export const loadSettingsPageDataAction = () => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(loadSettingsData());

  const settings = await api.getSettingsData();

  try {
    const seoMetadata = await seoMetadataAPI.getSeoMetadata();
    dispatch(loadSettingsDataSuccess(settings, seoMetadata));
  } catch (error) {
    error.status === 404 ? dispatch(loadSettingsDataSuccess(settings, null)) : dispatch(loadSettingsDataFailure(error));
  }
};

export const saveSettingsPageDataAction = (
  generalSettings: GeneralSettings | null,
  socialLinks: SocialLinks | null,
  seoMetadata: SEOMetadata | null
) => async (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(loadSettingsData());

  const settings = { "general-settings": generalSettings, "social-links": socialLinks };

  try {
    const updatedSettings = await api.saveSettingsData(settings);
    const updatedSeoMetadata = seoMetadata && (await seoMetadataAPI.saveSeoMetadata(seoMetadata));
    dispatch(loadSettingsDataSuccess(updatedSettings, updatedSeoMetadata));
  } catch (error) {
    dispatch(loadSettingsDataFailure(error));
  }
};

export const updateGeneralSettingsAction = (key: string, changes?: object | string | null) => (
  dispatch: ThunkDispatch<any, any, any>
) => {
  dispatch(updateGeneralSettings(key, changes));
};

export const updateSocialLinksAction = (socialLinks: object) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(updateSocialLinks(socialLinks));
};

export const updateSeoMetadataAction = (change: object) => (dispatch: ThunkDispatch<any, any, any>) => {
  dispatch(updateSeoMetadata(change));
};

export const addPublisherLogoAction = (file: File) => (dispatch: ThunkDispatch<any, any, any>) => {
  const updateImageUploadStatus = (status: { uploading: boolean }) => dispatch(imageUploadingStatus(status));
  const setSelectedMedia = (images: UnsavedImage[]) => {
    const image = images[0];
    const imageWidth = image.metadata.width;
    const imageHeight = image.metadata.height;
    const imageFileType = image.metadata["mime-type"];

    if (imageWidth < 512 && imageHeight < 512) {
      dispatch(imageUploadFailure({ imageError: t("settings.generalSettings.errors.image_size") }));
    } else if (imageFileType !== "image/png") {
      dispatch(imageUploadFailure({ imageError: t("settings.generalSettings.errors.image_type") }));
    } else {
      dispatch(updateGeneralSettings("publisher-logo", image));
      dispatch(imageUploadFailure({ imageError: "" }));
    }
  };

  dispatch(imageUploadingStatus({ uploading: true }));

  uploadImage([file], updateImageUploadStatus, setSelectedMedia, () => {}, {});
};

export const addPublisherFaviconAction = (file: File) => (dispatch: ThunkDispatch<any, any, any>) => {
  // This block does nothing meaningful, it is just to maintain consistency in UI
  dispatch(updateGeneralSettings("favicon", file));
  // End
  faviconAPI.saveFavicon(file).then(
    (res) => dispatch(loadSettingsPageDataAction()),
    (err) =>
      dispatch({
        type: NOTIFICATION_ERROR,
        payload: { message: t("settings.generalSettings.errors.favicon_save_error") }
      }) // API is not sending proper error message
  );
};
