/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { produce } from "immer";
import { State, INITIAL_BREAKING_NEWS_DEFAULTS } from "./state";
import actions, { ActionTypes } from "./actions";

const breakingNewsDefaultsReducer = (state: State = INITIAL_BREAKING_NEWS_DEFAULTS, action: ActionTypes) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case actions.FETCH_ASSOCIATED_STORY_DEFAULTS:
        draft.ui.main.loading = true;
        break;
      case actions.ASSOCIATED_STORY_DEFAULTS_FETCH_SUCCESS:
        draft.associatedStoryDefaults = action.payload.associatedStoryFeature.config;
        draft.ui.main.loading = false;
        break;
      case actions.ASSOCIATED_STORY_DEFAULTS_FETCH_ERROR:
        draft.ui.main.loading = false;
        break;
      case actions.UPDATE_ASSOCIATED_STORY_DEFAULTS:
        draft.associatedStoryDefaults = action.payload.updatedAssociatedStoryDefaults;
        draft.ui.isAssociatedStoryDefaultsModified = true;
        break;
      case actions.SAVE_ASSOCIATED_STORY_DEFAULTS:
        draft.ui.saving = true;
        draft.ui.errors = null;
        break;
      case actions.ASSOCIATED_STORY_DEFAULTS_SAVE_SUCCESS:
        draft.ui.saving = false;
        draft.ui.isAssociatedStoryDefaultsModified = false;
        break;
      case actions.ASSOCIATED_STORY_DEFAULTS_SAVE_ERROR:
        draft.ui.saving = false;
        draft.ui.errors = action.payload.errors;
        break;
    }
  });
};

export default breakingNewsDefaultsReducer;
