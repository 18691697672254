/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { Integrations, IntegrationsResponse, SupportedIntegrationsResponse } from "api/integrations";
import { IntegrationListByType, IntegrationType, Publisher } from "./state";

enum actions {
  LIST_PUBLISHER_INTEGRATIONS_SUCCESS = "@@settings/LIST_PUBLISHER_INTEGRATIONS_SUCCESS",
  LIST_PUBLISHER_INTEGRATIONS_FAILURE = "@@settings/LIST_PUBLISHER_INTEGRATIONS_FAILURE",

  LIST_SUPPORTED_INTEGRATIONS_SUCCESS = "@@settings/LIST_SUPPORTED_INTEGRATIONS_SUCCESS",
  LIST_SUPPORTED_INTEGRATIONS_FAILURE = "@@settings/LIST_SUPPORTED_INTEGRATIONS_FAILURE",

  SET_SELECTED_INTEGRATION = "@@settings/SET_SELECTED_INTEGRATION",

  UPDATE_SELECTED_INTEGRATION_FIELD = "@@settings/UPDATE_SELECTED_INTEGRATION_FIELD",

  START_LOADING_WITH_NO_ERROR = "@@settings/START_LOADING_WITH_NO_ERROR",
  STOP_LOADING_WITH_NO_ERROR = "@@settings/STOP_LOADING_WITH_NO_ERROR",
  EDIT_INTEGRATION = "@@settings/EDIT_INTEGRATION",
  CANCEL_INTEGRATION = "@@settings/CANCEL_INTEGRATION",

  SET_INTEGRATION_SAVE_ERRORS = "@@settings/SET_INTEGRATION_SAVE_ERRORS",
  SET_INTEGRATION_VALIDATE_IN_PROGRESS = "@@settings/SET_INTEGRATION_VALIDATE_IN_PROGRESS",
  SET_INTEGRATION_VALIDATE_RESULT = "@@settings/SET_INTEGRATION_VALIDATE_RESULT",
  UPDATE_PUBLISHER_SETTINGS = "@@settings/UPDATE_PUBLISHER_SETTINGS",
  UPDATE_INTEGRATION_LIST_BY_TYPE = "@@settings/UPDATE_INTEGRATION_LIST_BY_TYPE",
  SET_INTEGRATION_TYPE = "@@settings/SET_INTEGRATION_TYPE"
}

export default actions;

export type ListPublisherIntegrationsSuccessAction = {
  type: actions.LIST_PUBLISHER_INTEGRATIONS_SUCCESS;
  payload: { publisherIntegrationsAPIData: IntegrationsResponse };
};

export type ListPublisherIntegrationsFailureAction = {
  type: actions.LIST_PUBLISHER_INTEGRATIONS_FAILURE;
  payload: { error: Error };
};

export type ListSupportedIntegrationsSuccessAction = {
  type: actions.LIST_SUPPORTED_INTEGRATIONS_SUCCESS;
  payload: { supportedIntegrations: SupportedIntegrationsResponse };
};

export type ListSupportedIntegrationsFailureAction = {
  type: actions.LIST_SUPPORTED_INTEGRATIONS_FAILURE;
  payload: { error: Error };
};

export type OnChangeOfIntegrationAction = {
  type: actions.SET_SELECTED_INTEGRATION;
  payload: { selectedIntegrationName: Integrations };
};

export type UpdateIntegrationFieldAction = {
  type: actions.UPDATE_SELECTED_INTEGRATION_FIELD;
  payload: { key: string; changes: Object };
};

export type StartLoadingWithNoErrorAction = {
  type: actions.START_LOADING_WITH_NO_ERROR;
};
export type StopLoadingWithNoErrorAction = {
  type: actions.STOP_LOADING_WITH_NO_ERROR;
};

export type EditIntegrationAction = {
  type: actions.EDIT_INTEGRATION;
  payload: { integrationName: string };
};

export type CancelIntegrationAction = {
  type: actions.CANCEL_INTEGRATION;
};

export type SetIntegrationSaveErrorsAction = {
  type: actions.SET_INTEGRATION_SAVE_ERRORS;
  payload: { errors: Error };
};

export type SetIntegrationValidateResultAction = {
  type: actions.SET_INTEGRATION_VALIDATE_RESULT;
  payload: { isValid: boolean };
};

export type SetIntegrationValidateInProgressAction = {
  type: actions.SET_INTEGRATION_VALIDATE_IN_PROGRESS;
  payload: { inProgress: boolean };
};

export type UpdatePublisherSettings = {
  type: actions.UPDATE_PUBLISHER_SETTINGS;
  payload: Publisher;
};

export type UpdateIntegrationListByType = {
  type: actions.UPDATE_INTEGRATION_LIST_BY_TYPE;
  payload: IntegrationListByType;
};

export type SetIntegrationType = {
  type: actions.SET_INTEGRATION_TYPE;
  payload: IntegrationType;
};

export type ActionTypes =
  | ListPublisherIntegrationsSuccessAction
  | ListPublisherIntegrationsFailureAction
  | OnChangeOfIntegrationAction
  | ListSupportedIntegrationsSuccessAction
  | ListSupportedIntegrationsFailureAction
  | UpdateIntegrationFieldAction
  | StartLoadingWithNoErrorAction
  | StopLoadingWithNoErrorAction
  | EditIntegrationAction
  | CancelIntegrationAction
  | SetIntegrationSaveErrorsAction
  | SetIntegrationValidateResultAction
  | SetIntegrationValidateInProgressAction
  | UpdatePublisherSettings
  | UpdateIntegrationListByType
  | SetIntegrationType;
