/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { findElementNP, findCardNP, NodePosition, findFirstEditableCursorPosition } from "../find";
import { addIfNotExists } from "utils/array.utils";
import { schema, paragraphSchema } from "../../prosemirror/schema";
import { Node, Schema, DOMSerializer } from "prosemirror-model";
import { newStoryElementNode } from "../nodes";
import { setTextSelectionFromTransaction, setTextSelectionToDocumentEnd } from "../selection";
import { StoryElement, AnyStory, Card, ChildStoryElement, CompositeStoryElement } from "api/story";
import { EditorState } from "prosemirror-state";
import { fragmentToHTML } from "pages/story-editor/prosemirror/utils";
import { getDistinctStoryElements } from "pages/story-editor/utils";

export enum StoryElementDirection {
  TOP = "TOP",
  BOTTOM = "BOTTOM"
}

function addStoryElementStory(
  story: AnyStory,
  currentStoryElement: StoryElement,
  storyElement: StoryElement | CompositeStoryElement,
  addNewElementAt: StoryElementDirection
): AnyStory {
  const cardId = currentStoryElement ? currentStoryElement["card-id"] : storyElement["card-id"];
  const cardTree = story.cards[cardId].tree;
  const currentStoryElementIndex = currentStoryElement
    ? cardTree.findIndex((storyElementID) => storyElementID === currentStoryElement.id)
    : 0;
  const newCardTree = cardTree.slice();
  if (addNewElementAt === "TOP") {
    newCardTree.splice(currentStoryElementIndex, 0, storyElement.id);
  } else {
    newCardTree.splice(currentStoryElementIndex + 1, 0, storyElement.id);
  }
  const updatedCards = cardId ? addIfNotExists(story["updated-cards"], cardId) : story["updated-cards"];

  return {
    ...story,
    cards: {
      ...story.cards,
      [cardId]: {
        ...story.cards[cardId],
        tree: getDistinctStoryElements(newCardTree)
      }
    },
    "story-elements": {
      ...story["story-elements"],
      [storyElement.id]: { ...storyElement }
    },
    "updated-cards": updatedCards
  };
}

function addChildStoryElement(
  story: AnyStory,
  parentStoryElement: CompositeStoryElement,
  childStoryElement: ChildStoryElement
): AnyStory {
  const cardId = parentStoryElement["card-id"];
  const updatedCards = cardId ? addIfNotExists(story["updated-cards"], cardId) : story["updated-cards"];
  const parentStoryElementId = parentStoryElement.id;
  const childStoryElementId = childStoryElement.id;

  return {
    ...story,
    "story-elements": {
      ...story["story-elements"],
      [parentStoryElementId]: { ...parentStoryElement },
      [childStoryElementId]: { ...childStoryElement }
    },
    "updated-cards": updatedCards
  };
}

function addElementToCard(
  editorState: EditorState,
  currentStoryElement: StoryElement,
  storyElement: StoryElement,
  addNewElementAt: StoryElementDirection
): EditorState {
  const tr = editorState.tr;
  const elementNP = findElementNP(editorState, currentStoryElement);
  const SENode = Node.fromJSON(schema, newStoryElementNode(storyElement));
  let trWithFocus;

  if (elementNP) {
    // Add element to a card and apply transaction to editor state
    if (addNewElementAt === "TOP") {
      tr.insert(elementNP.pos, SENode);
    } else {
      tr.insert(elementNP.pos + elementNP.node.nodeSize, SENode);
    }
    const updatedEditorState = editorState.apply(tr);

    // Set cursor to document end
    trWithFocus = setTextSelectionToDocumentEnd(updatedEditorState.tr, updatedEditorState);
    return updatedEditorState.apply(trWithFocus);
  }
  return editorState;
}

interface SplitElements {
  editorState: EditorState<Schema>;
  newSENode: NodePosition;
  splitSENode: NodePosition;
  originalSENode: NodePosition;
}

function splitElementInCard(
  editorState: EditorState,
  currentStoryElement: StoryElement,
  storyElement: StoryElement,
  newTextElement: StoryElement,
  addNewElementAt: number
): SplitElements {
  const tr = editorState.tr;
  const addNewElementAtPos = editorState.doc.resolve(addNewElementAt);
  const newTextSENode = Node.fromJSON(schema, newStoryElementNode(newTextElement));

  if (addNewElementAtPos && addNewElementAtPos.parent.type.name === "paragraph") {
    const splitDepth = addNewElementAtPos.depth - 1;
    tr.split(addNewElementAt, splitDepth, [{ type: newTextSENode.type, attrs: newTextSENode.attrs }]);
  }
  const splitSENode = findElementNP(tr, newTextElement);
  const newCursorPosition = findFirstEditableCursorPosition(splitSENode) || addNewElementAt + 4;
  return {
    editorState: editorState.apply(setTextSelectionFromTransaction(tr, newCursorPosition)),
    newSENode: findElementNP(tr, storyElement),
    splitSENode,
    originalSENode: findElementNP(tr, currentStoryElement)
  };
}

function splitElementFromEditorState(
  editorState: EditorState,
  cardDetails: Card,
  currentStoryElement: StoryElement,
  storyElement: StoryElement,
  newTextElement: StoryElement,
  addNewElementAt: number
): SplitElements {
  return splitElementInCard(editorState, currentStoryElement, storyElement, newTextElement, addNewElementAt);
}

function addElementToEmptyCard(editorState: EditorState, card: Card, storyElement: StoryElement): EditorState {
  const tr = editorState.tr;
  const SENode = Node.fromJSON(schema, newStoryElementNode(storyElement));
  const emptyCardNP = findCardNP(editorState, card);
  if (emptyCardNP) {
    // Add element to a empty card and apply transaction
    tr.insert(emptyCardNP.pos + 1, SENode);
    const updatedEditorState = editorState.apply(tr);

    // Set cursor to document end
    const trWithFocus = setTextSelectionToDocumentEnd(updatedEditorState.tr, updatedEditorState);
    return updatedEditorState.apply(trWithFocus);
  }
  return editorState;
}

function addElementFromEditorState(
  editorState: EditorState,
  cardDetails: Card,
  currentStoryElement: StoryElement,
  storyElement: StoryElement,
  addNewElementAt: StoryElementDirection
): EditorState {
  return currentStoryElement
    ? addElementToCard(editorState, currentStoryElement, storyElement, addNewElementAt)
    : addElementToEmptyCard(editorState, cardDetails, storyElement);
}

function addStoryElement(
  story: AnyStory,
  editorState: EditorState,
  currentStoryElement: StoryElement | CompositeStoryElement,
  storyElement: StoryElement | ChildStoryElement | CompositeStoryElement,
  addNewElementAt: StoryElementDirection
): {
  story: AnyStory;
  editorState: EditorState;
} {
  const cardId = currentStoryElement ? currentStoryElement["card-id"] : storyElement["card-id"];
  const cardDetails = story.cards[cardId];

  const newStory = storyElement["composite-element-id"]
    ? addChildStoryElement(story, currentStoryElement as CompositeStoryElement, storyElement as ChildStoryElement)
    : addStoryElementStory(
        story,
        currentStoryElement as StoryElement | CompositeStoryElement,
        storyElement as StoryElement | CompositeStoryElement,
        addNewElementAt
      );
  const newEditorState = storyElement["composite-element-id"]
    ? editorState
    : addElementFromEditorState(
        editorState,
        cardDetails,
        currentStoryElement,
        storyElement as StoryElement | CompositeStoryElement,
        addNewElementAt
      );

  return {
    story: newStory,
    editorState: newEditorState
  };
}

function splitStoryElement(
  story: AnyStory,
  editorState: EditorState,
  currentStoryElement: StoryElement | CompositeStoryElement,
  storyElement: StoryElement | ChildStoryElement | CompositeStoryElement,
  newTextElement: StoryElement | ChildStoryElement | CompositeStoryElement,
  addNewElementAt: number
): {
  story: AnyStory;
  editorState: EditorState;
} {
  const cardId = currentStoryElement ? currentStoryElement["card-id"] : storyElement["card-id"];
  const cardDetails = story.cards[cardId];
  const splitElements = splitElementFromEditorState(
    editorState,
    cardDetails,
    currentStoryElement,
    storyElement as StoryElement | CompositeStoryElement,
    newTextElement as StoryElement | CompositeStoryElement,
    addNewElementAt
  );
  const serializer = DOMSerializer.fromSchema(paragraphSchema);
  const newEditorState = splitElements.editorState;

  // const storyWithTextElement = storyElement["composite-element-id"]
  //   ? addChildStoryElement(story, currentStoryElement as CompositeStoryElement, storyElement as ChildStoryElement)
  //   : addStoryElementStory(
  //       story,
  //       currentStoryElement as StoryElement | CompositeStoryElement,
  //       storyElement as StoryElement | CompositeStoryElement,
  //       StoryElementDirection.BOTTOM
  //     );
  const storyWithSplitElement = addStoryElementStory(
    story,
    currentStoryElement as StoryElement | CompositeStoryElement,
    {
      ...newTextElement,
      text: splitElements.splitSENode && fragmentToHTML(serializer, splitElements.splitSENode.node.content)
    } as StoryElement | CompositeStoryElement,
    StoryElementDirection.BOTTOM
  );

  return {
    story: {
      ...storyWithSplitElement,
      "story-elements": {
        ...storyWithSplitElement["story-elements"],
        [currentStoryElement.id]: {
          ...storyWithSplitElement["story-elements"][currentStoryElement.id],
          text:
            (splitElements.originalSENode && fragmentToHTML(serializer, splitElements.originalSENode.node.content)) ||
            storyWithSplitElement["story-elements"][currentStoryElement.id]["text"]
        }
      }
    },
    editorState: newEditorState
  };
}

export { splitStoryElement };
export default addStoryElement;
