/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import { EditorState, NodeSelection, TextSelection, Transaction } from "prosemirror-state";
import { ItsmanWindow } from "containers/page/page";
import { get } from "lodash";
import { getReduxActionLog } from "utils";
import { findParentNodeClosestToPosByAttrs } from "./find";

const w = (window as unknown) as ItsmanWindow;

export function safeTextSelection(doc, anchor, head?): TextSelection {
  try {
    return TextSelection.create(doc, anchor, head ? head : anchor);
  } catch (e) {
    const contentSize = get(doc, ["content", "size"]);
    w.newrelic &&
      w.newrelic.noticeError(e, {
        errorType: "invalid_text_selection",
        errorDescription: "The text selection is out of range",
        errorInfo: JSON.stringify({ anchor, head, contentSize }),
        reduxActionLog: JSON.stringify({ actions: getReduxActionLog() })
      });
    return TextSelection.create(doc, contentSize || 0);
  }
}

export function setTextSelection(tr: Transaction, editorState: EditorState, pos: number): Transaction {
  return tr.setSelection(safeTextSelection(editorState.doc, pos));
}

export function setTextSelectionToDocumentEnd(tr: Transaction, editorState: EditorState): Transaction {
  const docSize = get(editorState.doc, ["content", "size"]);
  return tr.setSelection(safeTextSelection(editorState.doc, docSize));
}

export function setTextSelectionFromTransaction(tr: Transaction, from: number, to: number = from): Transaction {
  return tr.setSelection(safeTextSelection(tr.doc, from, to));
}

export function setNodeSelection(tr: Transaction, editorState: EditorState, pos: number): Transaction {
  tr.setSelection(NodeSelection.create(editorState.doc, pos));
  return tr;
}

export function selectElementText(state: EditorState) {
  // textElement may contain stale docs and selections.
  const textElement = findParentNodeClosestToPosByAttrs(state, "text"),
    latestNode = textElement && textElement.start && state.doc && state.doc.nodeAt(textElement.start - 1),
    endOfTextElement = latestNode && latestNode.content.size;

  // Elements that are not TextBlock, like story_text_element are selected just after the
  // tag <story_text_element>. Accordingly, EndPos should also be 1 less, hence nodeStartPos + endOfTextElement - 2
  let nodeStartPos = textElement && textElement.start && textElement.start + 1,
    nodeEndPos = endOfTextElement && nodeStartPos && nodeStartPos + endOfTextElement - 2;

  //TextBlock elements like <quote> are selected with the <quote> tag.
  if (nodeStartPos && nodeEndPos && latestNode && latestNode.isTextblock) {
    nodeStartPos = nodeStartPos - 1;
    nodeEndPos = nodeEndPos + 1;
  }

  return nodeStartPos && nodeEndPos ? setTextSelectionFromTransaction(state.tr, nodeStartPos, nodeEndPos) : state.tr;
}
