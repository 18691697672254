/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import * as React from "react";

const WAnalytics = ({ width = "24", height = "24", color = "currentColor" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height}>
    <defs>
      <path
        id="a"
        d="M1.176 0v17.076H19.96v1.192H0V0h1.176zm16.201 3.955l.89.78-5.289 6.194-4.056-2.755-4.615 5.173-.871-.8L8.732 6.61l4.037 2.742 4.608-5.396z"></path>
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(2 3)">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a"></use>
      </mask>
      <use fill="#5F6978" xlinkHref="#a"></use>
      <path d="M-2-3h24v24H-2z" mask="url(#b)"></path>
      <g fill="#F9F9F9" mask="url(#b)">
        <path d="M-2-3h24v24H-2z"></path>
      </g>
    </g>
  </svg>
);

export default WAnalytics;
