/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const MEDIA_LIBRARY_DATA_SUCCESS = "@@media-library/DATA_SUCCESS";
export const MEDIA_LIBRARY_NEXT_DATA_SUCCESS = "@@media-library/NEXT_DATA_SUCCESS";
export const MEDIA_LIBRARY_SET_PROVIDER = "@@media-library/SET_PROVIDER";
export const MEDIA_LIBRARY_UPDATE_SEARCH_TERM = "@@media-library/UPDATE_SEARCH_TERM";
export const MEDIA_LIBRARY_SET_SELECTED_MEDIA = "@@media-library/SET_SELECTED_MEDIA";
export const MEDIA_LIBRARY_UPDATE_IMAGE_UPLOAD_STATUS = "@@media-library/UPDATE_IMAGE_UPLOAD_STATUS";
export const MEDIA_LIBRARY_LOADING_STATUS = "@@media-library/MEDIA_LIBRARY_LOADING_STATUS";
export const MEDIA_LIBRARY_UPDATE_SELECTED_MEDIA_DATA = "@@media-library/UPDATE_SELECTED_MEDIA_DATA";
export const MEDIA_LIBRARY_UPDATE_MEDIA = "@@media-library/UPDATE_MEDIA";
export const MEDIA_LIBRARY_UPDATE_ERROR = "@@media-library/UPDATE_ERROR";
export const MEDIA_LIBRARY_REMOVE_ERROR = "@@media-library/REMOVE_ERROR";
export const MEDIA_LIBRARY_RESET_DATA = "@@media-library/RESET_DATA";
export const MEDIA_LIBRARY_UPDATE_MEDIA_FOR_MULTI_SELECT = "@@media-library/UPDATE_MEDIA_FOR_MULTI_SELECT";
export const MEDIA_LIBRARY_CLEAR_MEDIA_FOR_MULTI_SELECT = "@@media-library/CLEAR_MEDIA_FOR_MULTI_SELECT";
export const MEDIA_LIBRARY_CLEAR_SELECTED_MEDIA = "@@media-library/CLEAR_SELECTED_MEDIA";
export const MEDIA_LIBRARY_IMAGE_SAVE_INIT = "@@media-library/IMAGE_SAVE_INIT";
export const MEDIA_LIBRARY_IMAGE_SAVE_SUCCESS = "@@media-library/IMAGE_SAVE_SUCCESS";
export const MEDIA_LIBRARY_IMAGE_SAVE_FAILURE = "@@media-library/IMAGE_SAVE_FAILURE";
export const MEDIA_LIBRARY_UPDATE_SELECTED_TAB = "@@media-library/UPDATE_SELECTED_TAB";
export const MEDIA_LIBRARY_UPDATE_MEDIA_STATS = "@@media-library/UPDATE_MEDIA_STATS";
export const MEDIA_LIBRARY_UPDATE_STORY_LIST_LOADER_STATUS = "@@media-library/UPDATE_STORY_LIST_LOADER_STATUS";
export const MEDIA_LIBRARY_TOGGLE_SHOW_ADVANCED_SEARCH = "@@media-library/TOGGLE_SHOW_ADVANCED_SEARCH";
export const MEDIA_LIBRARY_UPDATE_ADVANCED_SEARCH_OPTIONS = "@@media-library/UPDATE_ADVANCED_SEARCH_OPTIONS";
