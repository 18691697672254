/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

import React from "react";

import { t } from "i18n";
import Plus from "components/icons/plus";
import Chevron from "components/icons/chevron";
import { AdvancedContextMenu } from "components/advanced-context-menu/advanced-context-menu";
import styles from "./add-new-header-button.module.css";

const AddNewHeaderButtonBase = (props) => {
  const { children, iconComponent, title } = props;
  return (
    <div className={styles["add-new-button-container"]}>
      <AdvancedContextMenu
        data-test-id="add-new-button"
        menuButton={
          <button data-test-id="add-new-button" className={styles["add-new-button"]}>
            <div className={styles["button-content-container"]}>
              <div className={styles["label"]} data-test-id="add-new-button-label">
                {title}
              </div>
              {iconComponent}
            </div>
          </button>
        }>
        <div data-test-id="add-new-button-box" className={styles["add-new-button-box"]}>
          {children}
        </div>
      </AdvancedContextMenu>
    </div>
  );
};

const AddNewButtonMobile = (props) => {
  return (
    <AddNewHeaderButtonBase {...props} iconComponent={<Plus fill="#fff" />}>
      {props.children}
    </AddNewHeaderButtonBase>
  );
};

const AddNewHeaderButtonDesktop = (props) => {
  return (
    <AddNewHeaderButtonBase {...props} title={t("new")} iconComponent={<Chevron variant="down" fill="#fff" />}>
      {props.children}
    </AddNewHeaderButtonBase>
  );
};

export { AddNewButtonMobile, AddNewHeaderButtonDesktop };
