/*
 ************************************************************************
 *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  All Rights Reserved.
 *************************************************************************
 */

export const STATIC_PAGES_INDEX_PATH = "/content/static-pages";
export const STATIC_PAGES_EDIT_PATH = `${STATIC_PAGES_INDEX_PATH}/:id`;
export const STATIC_PAGES_EDIT_MANAGE_PATH = `${STATIC_PAGES_EDIT_PATH}/manage`;
export const STATIC_PAGES_NEW_PATH = `${STATIC_PAGES_INDEX_PATH}/new`;
